<template>
  <v-dialog
    :value="dialog"
    max-width="400px"
    persistent
    @keydown.esc="reset">
    <v-card>
      <v-toolbar
        style="background-color: rgb(8, 72, 69)"
        flat
        dark>
        <div class="headline">
          Send Us Your Thoughts
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="reset()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <FeedbackForm
          ref="feedbackForm"
          project-id="credit-app"
          issue-type="internal"
          accepted-files=".jpeg,.jpg,.png,.img,.bmp,.gif,.txt,.pdf,.doc,.docx,.odt,.wpd"
          :attempt-auto-focus="dialog"
          :upload-uri="uploadUri"
          :upload-headers="uploadHeaders"
          app-name="Credit App"
          :delete-attachment="deleteAttachment"
          @validated="feedbackValidated"
          @message="feedbackMessage">
        </FeedbackForm>
        <v-alert
          class="mb-2"
          :value="dropzoneAlertMessage.message.length > 0"
          :color="dropzoneAlertMessage.status"
          transition="slide-y-transition">
          {{ dropzoneAlertMessage.message }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="reset()">
          Close
        </v-btn>
        <v-btn
          style="background-color: rgb(8, 72, 69); color: white;"
          :disabled="!feedbackFormValid"
          @click="sendFeedback()">
          Send Feedback
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios, { determineApiEndpoint } from 'ca-http-service'
import { mapState } from 'vuex'
import FeedbackForm from './feedback/FeedbackForm.vue'

export default {
  name: 'FeedbackModal',
  components: {
    FeedbackForm
  },
  props: {
    dialog: {
      type: Boolean
    }
  },
  data () {
    return {
      feedbackFormValid: false,
      uploadUri: new URL('/api/feedback/attachment/upload', determineApiEndpoint()).href, // Relative URLs don't seem to work here, possibly since dropzone uses XmlHttpRequest rather than axios/fetch
      feedbackSubmitting: false,
      dropzoneErrorMessage: { message: '', status: '' }
    }
  },
  computed: {
    ...mapState(['accessToken', 'identity']),
    uploadHeaders () {
      return {
        Authorization: `Bearer ${this.accessToken}`
      }
    },
    dropzoneAlertMessage () {
      return this.dropzoneErrorMessage
    }
  },
  methods: {
    reset (feedbackSubmitted = false) {
      this.$emit('update:dialog', false)
      // Clear uploaded attachments if feedback hasn't been submitted
      if (!feedbackSubmitted) {
        this.$refs.feedbackForm.files.forEach((file) => {
          const token = this.$refs.feedbackForm.fileTokens[file.name]
          if (token) {
            this.deleteAttachment(token, { silent: true })
          }
        })
      }
      this.$refs.feedbackForm.clear()
      this.dropzoneErrorMessage = { message: '', status: '' }
    },
    feedbackValidated (valid) {
      this.feedbackFormValid = valid
    },
    feedbackMessage (dropzoneMessage) {
      this.dropzoneErrorMessage = { message: dropzoneMessage.message ?? '', status: dropzoneMessage.status ?? '' }
    },
    async deleteAttachment (token, params = { silent: false }) {
      const response = await axios.request({
        url: '/api/feedback/attachment/delete',
        method: 'POST',
        params: { fileToken: token }
      })
      if (response.status !== 200) {
        if (!params.silent) {
          this.dropzoneErrorMessage = { message: 'Unexpected error removing file', status: 'error' }
        }
      }
    },
    async sendFeedback () {
      if (!this.feedbackFormValid || this.feedbackSubmitting) return
      this.feedbackSubmitting = true
      const feedback = this.$refs.feedbackForm.feedback
      const fileNames = this.$refs.feedbackForm.files.map((x) => x.name)
      const data = {
        ...feedback,
        fullName: (this.identity && this.identity.name) || null,
        email: (this.identity && this.identity.email) || null,
        uploadFileNames: fileNames
      }

      await axios
        .post('/api/feedback/submit', data)
        .then((res) => {
          palert({
            title: 'Thanks for your feedback!',
            type: 'success'
          })
          this.reset(true)
        })
        .catch((err) => {
          console.error(err)
          palert({
            title: 'Error submitting feedback. Please try again in a few minutes.',
            type: 'error'
          })
        })
        .finally(() => {
          this.feedbackSubmitting = false
        })
    }
  }
}
</script>
