<template>
  <div
    v-cloak
    v-if="appObj.businessInfo"
    id="credit-review-detail">
    <!-- modals -->
    <CommentModal
      ref="commentModal"
      :app="appObj"></CommentModal>
    <EditDnbAddressModal
      ref="dnbAddressModal"
      @getCustomerMatches="refetchCustomerMatches = true"
      :app="appObj"></EditDnbAddressModal>
    <CompanyModal
      ref="companyModal"
      :app="appObj"></CompanyModal>
    <ApprovalModal
      ref="approvalModal"
      :app="appObj"
      @approveCreditReview_="approveCreditReview"></ApprovalModal>
    <PendingModal
      ref="pendingModal"
      :app.sync="appObj"
      @pendingCreditReview_="pendingCreditReview"></PendingModal>
    <DenialModal
      ref="denialModal"
      :app="appObj"
      @denyCreditReview_="denyCreditReview"></DenialModal>
    <UploadModal
      ref="uploadModal"
      @saveDocument_="saveDocument"></UploadModal>
    <ReportModal
      ref="reportModal"
      :credit-report-response="creditReportResponse"
      @selectCompanyFromList_="selectCompanyFromList"></ReportModal>

    <!-- loading spinner -->
    <div
      v-if="loading"
      class="spinner show-spinner"></div>

    <section
      v-if="!loading"
      class="review-header">
      <div class="row">
        <div class="col">
          <h4>
            <a
              href="#"
              class="go-back-arrow mr-3"
              @click="goBack()">
              <i class="fa fa-arrow-left"></i>
            </a>
            <span>{{ appObj.businessInfo.companyName }}</span>
          </h4>
        </div>
        <div
          v-if="!loading"
          class="review-status col">
          <app-status
            :can-edit="identity.permissions.editApplications"
            :status="appStatus.status"
            :bg="appStatus.bg"
            :color="appStatus.color"
            :app="appObj"
            @openModal_="openModal"></app-status>
          <ActionsList
            :can-edit="identity.permissions.editApplications"
            :app="appObj"
            :global-customer-id="globalCustomerId"
            :credit-report-btn="creditReportBtn"
            @runCreditGuarantor_="runCreditGuarantor"
            @runCreditBusiness_="runCreditBusiness"
            @requestGuarantee_="requestGuarantee"
            @undoRequestGuarantee_="undoRequestGuarantee"></ActionsList>
          <!-- application status -->
        </div>
      </div>
    </section>
    <section
      v-if="!loading"
      id="application-message">
      <div
        class="card"
        :style="getMessageStyle()">
        <div>
          <template v-if="appObj.status === 'Pending'">
            This application is
          </template>
          <template v-else>
            This application was
          </template>
          <span class="status">
            {{ appObj.status }}
          </span>
          <span v-if="appObj.dateReviewed">
            on {{ appObj.dateReviewed | shortDate }}
          </span>
          <span v-if="appObj.reviewedBy">
            by {{ appObj.reviewedBy.firstName }} {{ appObj.reviewedBy.lastName }}
          </span>
          <span
            v-if="appObj.status == 'Approved' && appObj.creditAmount">
            for {{ appObj.creditAmount | currency }}
          </span>
          <span v-if="appObj.isCreatedFromStorefront">
            from Storefront
          </span>
          <span v-if="appObj.dateEmailed">
            from employee email {{ emailedByEmailAddress || 'unknown' }}
          </span>
        </div>
      </div>
    </section>

    <section
      v-if="!loading"
      class="review-body">
      <!-- Not Visible to Customer -->
      <NotVisibleToCustomer
        v-if="appObj.dateEmailed"
        :app="appObj">
      </NotVisibleToCustomer>

      <!-- Branch Information -->
      <section
        v-if="appObj.dateEmailed">
        <h5
          class="section-title mb-3">
          Branch Information
        </h5>
        <branch-selector
          v-if="branch"
          class="my-3"
          :read-only="true"
          :passed-branch="branch">
        </branch-selector>
        <div v-else>
          No Branch Assigned
        </div>
      </section>

      <!-- D&B Information -->
      <DnbInformation
        :business-info="appObj.businessInfo"
        :status="appObj.status"
        :application-id="appObj.guid"
        :can-edit="identity.permissions.editApplications"
        @update-detail="getData(appObj.guid)"
        @editDnbAddressInfo_="editDnbAddressInfo"
        :refetchCustomerMatches.sync="refetchCustomerMatches">
      </DnbInformation>

      <!-- Company Info -->
      <CompanySection
        :app="appObj"
        :identity="identity"
        :edit-company-info="editCompanyInfo"></CompanySection>

      <!-- Signatories -->
      <CreditReviewSignatories :app="appObj"></CreditReviewSignatories>

      <!--Application Comments -->
      <section
        v-if="appObj.customerComments"
        id="application-comments">
        <h5
          id="sectionTitle"
          class="section-title mb-3">
          Customer Comments
        </h5>
        <div>{{ appObj.customerComments }}</div>
      </section>

      <!-- Documents -->
      <CreditReviewDocuments
        :app="appObj"
        :can-edit="identity.permissions.editApplications"
        @uploadDocument_="uploadDocument"
        @downloadDoc_="downloadDoc"
        @downloadAllDocs_="downloadAllDocs"
        @removeDocument_="removeDocument"></CreditReviewDocuments>
      <!-- Comments -->
      <CreditReviewComments
        :app="appObj"
        @newComment_="newComment"></CreditReviewComments>
      <!-- Previous Orders -->
      <PreviousOrders
        ref="previousOrders"
        :orders="orders"></PreviousOrders>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'ca-http-service'
import AppTag from './components/detail/appStatusTag.vue'
import CompanySection from './components/detail/CompanySection.vue'
import ActionsList from './components/detail/ActionsList.vue'
import ReportModal from './components/detail/ReportModal.vue'
import PreviousOrders from './components/detail/PreviousOrders.vue'
import CreditReviewSignatories from './components/detail/Signatories.vue'
import CreditReviewDocuments from './components/detail/Documents.vue'
import CreditReviewComments from './components/detail/Comments.vue'
import ApprovalModal from './components/detail/ApprovalModal.vue'
import DenialModal from './components/detail/DenialModal.vue'
import PendingModal from './components/detail/PendingModal.vue'
import CommentModal from './components/detail/CommentModal.vue'
import CompanyModal from './components/detail/EditCompanyModal.vue'
import EditDnbAddressModal from './components/detail/EditDnbAddressModal.vue'
import UploadModal from './components/detail/UploadModal.vue'
import DnbInformation from './components/detail/DnbInformation.vue'
import BannerMixin from 'mixins/banner.mixin'
import BranchSelector from '@/components/BranchSelector.vue'
import NotVisibleToCustomer from './components/detail/NotVisibleToCustomer.vue'
import { getBranchesAsync } from '@/api/company/branch-info'
import TenantsMixin from 'mixins/tenants.mixin'

export default {
  name: 'ReviewDetail',
  components: {
    'app-status': AppTag,
    CompanySection,
    ActionsList,
    ApprovalModal,
    DenialModal,
    PendingModal,
    CommentModal,
    CompanyModal,
    EditDnbAddressModal,
    ReportModal,
    UploadModal,
    DnbInformation,
    CreditReviewComments,
    CreditReviewDocuments,
    PreviousOrders,
    CreditReviewSignatories,
    BranchSelector,
    NotVisibleToCustomer
  },
  mixins: [
    BannerMixin,
    TenantsMixin
  ],
  data () {
    return {
      loading: true,
      showModal: false,
      uploadModalState: false,
      denialModalState: false,
      pendingModalState: false,
      creditReportBtn: true,
      // permissions: {
      //   editApplications: false,
      //   editUsers: false,
      //   viewUsers: false
      // },
      fileInputTxt: '',
      fileInputVal: [],
      currentAppCreditAmount: 0,
      appObj: {
        adminDocuments: [],
        approved: null,
        documents: [],
        comments: [],
        company: {
          otherDocuments: [],
          taxDocuments: []
        },
        creditAmount: null,
        creditAppId: 0,
        creditRep: null,
        creditReport: null,
        dateReviewed: null,
        dateSubmitted: null,
        hasGuaranty: 0,
        previousOrders: null,
        signatories: null,
        signatoriesJson: null,
        tenantId: 0,
        reviewedBy: null,
        customerComments: ''
      },
      orders: null,
      creditReportResponse: {
        data: []
      },
      id: null,
      validFileTypes: [
        'pdf',
        'doc',
        'docx',
        'html',
        'jpeg',
        'jpg',
        'png',
        'odt',
        'wpd',
        'img',
        'bmp'
      ],
      globalCustomerId: null,
      branch: null,
      emailedByEmailAddress: null,
      erpTransactions: [],
      erpErrors: [],
      refetchCustomerMatches: false
    }
  },
  computed: {
    ...mapState([
      'identity'
    ]),
    appStatus () {
      const tag = {
        status: null,
        color: null,
        bg: null
      }
      if (this.appObj.status === 'Approved') {
        tag.status = 'approved'
        tag.color = '#005a2c'
        tag.bg = 'tag-default'
      } else if (this.appObj.status === 'Pending') {
        tag.status = 'pending'
        tag.color = '#2d5a87'
        tag.bg = 'tag-default'
      } else {
        tag.status = 'denied'
        tag.color = '#b40000'
        tag.bg = 'tag-danger'
      }
      return tag
    },
    formatedAddress () {
      let result = ''
      result += this.appObj.company.street
      if (this.appObj.company.unit && this.appObj.company.unit !== '') {
        result += ' Unit: ' + this.appObj.company.unit
      }
      result +=
        '\n' + this.appObj.company.city + ', ' + this.appObj.company.state
      result +=
        '\n' + this.appObj.company.countryCode + ' ' + this.appObj.company.zip
      return result
    }
  },
  watch: {
    selectedBanner () {
      // comes from banner mixin
      this.goBack()
    }
  },
  async mounted () {
    await this.getData(this.$route.params.id)
  },
  methods: {
    goBack () {
      this.$router.push({
        name: 'creditReview'
      })
    },
    editCompanyInfo () {
      this.$refs.companyModal.newAccountNum = this.appObj.businessInfo.accountNumber
      this.$refs.companyModal.newBusinessName = this.appObj.businessInfo.companyName

      const newBillingAddress = {
        streetAddress: this.appObj.billingInfo.billingAddress.streetAddress,
        unitNumber: this.appObj.billingInfo.billingAddress.addressUnitNumber,
        city: this.appObj.billingInfo.billingAddress.city,
        state: this.appObj.billingInfo.billingAddress.state,
        postalCode: this.appObj.billingInfo.billingAddress.postalCode,
        country: this.appObj.billingInfo.billingAddress.country
      }

      this.$refs.companyModal.newBillingAddress = newBillingAddress

      this.$refs.companyModal.modalState = true
    },
    editDnbAddressInfo () {
      this.$refs.dnbAddressModal.newBusinessName = this.appObj.businessInfo.companyName

      const newBillingAddress = {
        streetAddress: this.appObj.billingInfo.billingAddress.streetAddress,
        unitNumber: this.appObj.billingInfo.billingAddress.addressUnitNumber,
        city: this.appObj.billingInfo.billingAddress.city,
        state: this.appObj.billingInfo.billingAddress.state,
        postalCode: this.appObj.billingInfo.billingAddress.postalCode,
        country: this.appObj.billingInfo.billingAddress.country
      }

      this.$refs.dnbAddressModal.newBillingAddress = newBillingAddress

      this.$refs.dnbAddressModal.modalState = true
    },
    newComment () {
      if (this.appObj.comments === null) {
        this.appObj.comments = []
      }
      this.$refs.commentModal.modalState = true
    },
    uploadDocument () {
      this.$refs.uploadModal.modalState = true
    },
    getTenant (tenantId) {
      // hardcoded: tenants unavailable on mount
      return tenantId === 1
        ? 'platt'
        : tenantId === 2
          ? 'rexel'
          : tenantId === 3
            ? 'gexpro'
            : tenantId === 4
              ? 'rexelenergy'
              : null
    },
    async getData (appID) {
      this.orders = null
      this.loading = true
      const user = this.$store.getters.user
      // if (user != null) {
      //   this.permissions = user.permissions
      // }

      const tenant =
        this.company.name != null
          ? this.company.name.toLowerCase()
          : this.getTenant(user.tenantId)

      const applicationId = appID

      await axios
        .get(`/api/CreditReview/getDetailViewModel/${tenant}/${applicationId}`)
        .then(async res => {
          if (res.status === 200) {
            this.appObj = res.data.appObj || res.data
            if (res.data.emailedByEmailAddress) {
              this.emailedByEmailAddress = res.data.emailedByEmailAddress
            }
            this.getOrderHistory(appID) // TODO is this Platt Only?
            this.getGlobalCustomerId(
              this.appObj.bannerId,
              this.appObj.businessInfo.accountNumber
            )

            // // Get Branch Information
            let branches = []
            const bannerId = Number(this.getBannerIdFromName(this.appObj.bannerId))
            if (bannerId === 1) {
              branches = await getBranchesAsync(bannerId)
            } else {
              branches = [...await getBranchesAsync(2), ...await getBranchesAsync(3)]
            }
            if (this.appObj.branchNumber) {
              this.branch = branches.find(branch => branch.branchNumber === this.appObj.branchNumber.toString())
            }
          } else {
            palert({ type: 'error', title: res.data.message })
            this.goBack()
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })

      const bannerId = Number(this.getBannerIdFromName(this.appObj.bannerId))

      await axios
        .get(`/api/erpLog/transactions/${bannerId}/${applicationId}`)
        .then(async res => {
          this.erpTransactions = res.data
          console.log('ERP Transactions: ', this.erpTransactions)
        })
        .catch(err => {
          console.error(err)
        })

      await axios
        .get(`/api/erpLog/errors/${bannerId}/${applicationId}`)
        .then(async res => {
          this.erpErrors = res.data
          console.log('ERP Errors: ', this.erpErrors)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getGlobalCustomerId (tenant, customerNumber) {
      axios
        .get('/api/CreditReview/getCustomerId', {
          params: {
            tenant,
            customerAccountId: customerNumber
          }
        })
        .then(res => {
          if (res.data) {
            this.globalCustomerId = res.data
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    getOrderHistory (id) {
      const creditAppId = id
      axios
        .get('/api/CreditReview/getOrderHistory', {
          params: {
            creditAppId
          }
        })
        .then(res => {
          this.appObj.previousOrders = res.data
          this.orders = res.data
        })
        .catch(err => {
          console.error(err)
        })
    },
    runCreditBusiness (app) {
      this.creditReportBtn = false
      axios
        .post('/api/CreditCheck/business', {
          applicationId: app.guid,
          tenantId: app.bannerId
        })
        .then(res => {
          if (res.data.isSuccess) {
            this.processCreditCheckResult(res.data)
          } else {
            palert({ type: 'error', title: res.data.message })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.creditReportBtn = true
        })
    },
    runCreditGuarantor (g) {
      this.creditReportBtn = false
      axios
        .post('/api/CreditCheck/guarantor/', g)
        .then(res => {
          if (res.data.isSuccess) {
            switch (res.data.type) {
              case 'OWNER': {
                // var data = res.data.data
                const fileName = res.data.data.fileItem.name
                const fullPath = res.data.data.fileItem.fullPath
                let documentExists = false

                this.appObj.documents.forEach((key, value) => {
                  if (key.name.indexOf(fileName) > -1) {
                    documentExists = true
                  }
                })

                if (!documentExists) {
                  this.appObj.documents.unshift({
                    name: fileName,
                    fullPath
                  })
                }
                palert({
                  title: 'Match Found',
                  type: 'success'
                })
                break
              }
              default: {
                palert('No match found for ' + g.firstName + ' ' + g.lastName)
                break
              }
            }
          } else {
            palert({ type: 'error', title: res.data.message })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.creditReportBtn = true
        })
    },
    getTodayDate () {
      const today = new Date()
      let dd = today.getDate()
      let mm = today.getMonth() + 1

      const yyyy = today.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      const formattedToday = mm + '/' + dd + '/' + yyyy
      return formattedToday
    },
    selectCompanyFromList (ExperianFileNumber) {
      this.creditReportBtn = false
      const company = this.creditReportResponse.data
      let similarNumber = null
      let similarSequenceNumber = null

      company.listOfSimilars.forEach((key, value) => {
        if (key.ExperianFileNumber === ExperianFileNumber) {
          similarNumber = key.ExperianFileNumber
          similarSequenceNumber = key.RecordSequenceNumber
        }
      })
      axios
        .get(
          `/api/CreditCheck/selectFromListOfSimilars/${this.appObj.creditAppId}/${this.creditReportResponse.data.transactionNumber}/${similarNumber}/${similarSequenceNumber}`
        )
        .then(res => {
          this.processCreditCheckResult(res.data)
        })
        .catch(err => {
          console.error(err)
          palert('Something went wrong. Could not process your request.')
        })
        .finally(() => {
          this.creditReportBtn = true
        })
    },
    processCreditCheckResult (result) {
      if (!result.isSuccess) {
        palert('Something went wrong. Could not process your request.')
      } else {
        switch (result.type) {
          case 'LIST': {
            this.creditReportResponse = result
            this.$refs.reportModal.modalState = true
            break
          }
          case 'BUSP': {
            this.appObj.creditReport = result.data.creditReport
            const fileName = result.data.fileItem.name
            const fullPath = result.data.fileItem.fullPath
            let documentExists = false

            this.appObj.documents.forEach((key, value) => {
              if (key.name.indexOf(fileName) > -1) {
                documentExists = true
              }
            })

            if (!documentExists) {
              this.appObj.documents.unshift({
                name: fileName,
                fullPath
              })
            }

            palert({
              title: 'Match Found',
              type: 'success'
            })

            this.creditReportBtn = true

            break
          }
          default: {
            palert('No match found for ' + this.appObj.company.companyName)
            this.creditReportBtn = true
            break
          }
        }
      }
    },
    saveDocument (file) {
      // let fileExt = this.getFileExtension(file[0].name)
      const fd = new FormData()
      fd.append('file', file[0])
      const applicationId = this.$route.params.id

      axios
        .post(`/api/CreditReview/upload/${this.appObj.bannerId}/${applicationId}`, fd)
        .then(res => {
          if (res.data.isSuccess) {
            const fileName = res.data.data.fileItem.name
            const fullPath = res.data.data.fileItem.fullPath
            let documentExists = false

            this.appObj.adminDocuments.forEach((key, value) => {
              if (key.name.indexOf(fileName) > -1) {
                documentExists = true
              }
            })

            if (!documentExists) {
              this.appObj.adminDocuments.unshift({
                name: fileName,
                fullPath
              })
            }

            this.$refs.uploadModal.modalState = false
          } else {
            this.$refs.uploadModal.modalState = false
            palert({
              title: 'Error',
              text: 'Something went wrong. Could not upload the document.',
              type: 'error'
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    requestGuarantee () {
      const tenant = this.appObj.bannerId
      const applicationId = this.appObj.guid
      axios
        .get(
          `/api/CreditReview/requestPersonalGuarantee/${tenant}/${applicationId}`,
          {
            params: {
              applicationId
            }
          }
        )
        .then(res => {
          if (res.data.isSuccess) {
            palert({ type: 'success', title: res.data.message })
            this.appObj.comments.unshift(res.data.data)
          } else {
            palert({ type: 'error', title: res.data.message })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    undoRequestGuarantee () {
      const tenant = this.appObj.bannerId
      const applicationId = this.appObj.guid
      axios
        .get(
          `/api/CreditReview/undoRequestPersonalGuarantee/${tenant}/${applicationId}`,
          {
            params: {
              applicationId
            }
          }
        )
        .then(res => {
          if (res.data.isSuccess) {
            palert({ type: 'success', title: res.data.message })
            this.appObj.comments.unshift(res.data.data)
          } else {
            palert({ type: 'error', title: res.data.message })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    openModal (obj) {
      let isIncompleteApproval = false
      if (obj === 'approvalModal' && this.appObj.erpReconciliation) {
        isIncompleteApproval = this.appObj.erpReconciliation.checkComparisonIncomplete()
      }
      if (!isIncompleteApproval) {
        this.$refs[obj].modalState = true
        if (this.$refs[obj].approve) {
          this.$refs[obj].approve.creditApprovalObj.accountId = this.appObj.businessInfo.accountNumber
        } else if (this.$refs[obj].deny) {
          this.$refs[obj].deny.creditPendingObj.accountId = this.appObj.businessInfo.accountNumber
        }
      } else {
        if (this.appObj.erpReconciliation.firstIncomplete !== '') {
          const firstIncomplete = document.getElementById(this.appObj.erpReconciliation.firstIncomplete)
          if (firstIncomplete) {
            const scrollPosition = firstIncomplete.getBoundingClientRect().top - 80
            if (scrollPosition > 0) {
              window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
              })
            }
          }
        }
      }
    },
    approveCreditReview (creditAppId, details) {
      this.loading = true
      const data = {
        tenantId: this.appObj.bannerId,
        creditAppId,
        accountId: details.accountId,
        creditAmount: details.creditAmount,
        sendLetter: details.letter,
        creditRepId: details.rep,
        branchNumber: details.branchNumber,
        erpUserLoginId: this.$store.getters.currentUserInfo.eclipseUserLogin,
        erpReconciliation: this.appObj.erpReconciliation,
        uploadAttachments: details.uploadAttachments
      }

      const self = this // save reference to mutate this in promise
      axios
        .post('/api/CreditReview/approve/', data)
        .then(res => {
          if (res.data.isSuccess) {
            self.appObj.dateReviewed = self.getTodayDate()
            self.items = res.data.data
            self.appObj.approved = true
            self.appObj.reviewedBy = {
              firstName: self.$store.getters.currentUserInfo.firstName,
              lastName: self.$store.getters.currentUserInfo.lastName
            }
            if (details.creditAmount > 0) {
              self.appObj.creditAmount = parseFloat(details.creditAmount)
            }
          } else {
            palert({ type: 'error', title: res.data.message })
          }
        })
        .catch(function (err) {
          palert({ type: 'error', title: err.response.data.message })
        })
        .finally(function () {
          self.loading = false
          self.$refs.approvalModal.modalState = false
          self.$refs.approvalModal.approve.creditApprovalObj.accountId = null
          self.$refs.approvalModal.approve.creditApprovalObj.creditAmount = null
          self.$refs.approvalModal.approve.creditApprovalObj.creditReps = null
          self.$refs.approvalModal.approve.creditApprovalObj.rep = null
          self.$refs.approvalModal.approve.creditApprovalObj.branches = null
          self.$refs.approvalModal.approve.creditApprovalObj.branchNumber = null
          self.$refs.approvalModal.approve.creditApprovalObj.letter = true
        })
    },
    denyCreditReview (creditAppId, details) {
      this.loading = true
      const data = {
        tenantId: this.appObj.bannerId,
        creditAppId: this.appObj.guid,
        reasonType: details.reason,
        reasonText: details.reasonText,
        sendLetter: details.letter,
        accountId: details.accountId,
        uploadAttachments: details.uploadAttachments
      }
      axios
        .post('/api/CreditReview/deny/', data)
        .then(res => {
          this.appObj.dateReviewed = this.getTodayDate()
          this.items = res.data.data
          this.appObj.approved = false
          this.appObj.status = 'Denied'
          this.appObj.reasonTypeID = details.reason
          this.appObj.reviewedBy = {
            firstName: this.$store.getters.currentUserInfo.firstName,
            lastName: this.$store.getters.currentUserInfo.lastName
          }
          this.$refs.denialModal.modalState = false
          this.$refs.denialModal.deny.creditPendingObj.reason = ''
          this.$refs.denialModal.deny.creditPendingObj.letter = true
        })
        .catch(function (err) {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    pendingCreditReview (creditAppId, details) {
      this.loading = true
      const data = {
        tenantId: this.appObj.bannerId,
        creditAppId: this.appObj.guid,
        reasonType: details.reason
      }
      axios
        .post('/api/CreditReview/pending', data)
        .then(res => {
          this.appObj.dateReviewed = this.getTodayDate()
          this.items = res.data.data
          this.appObj.approved = null
          this.appObj.status = 'Pending'
          this.appObj.reasonTypeID = details.reason
          this.appObj.reviewedBy = {
            firstName: this.$store.getters.currentUserInfo.firstName,
            lastName: this.$store.getters.currentUserInfo.lastName
          }
          this.$refs.pendingModal.modalState = false
          this.appObj.reasonTypeID = details.reason
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    removeDocument (doc) {
      axios
        .post(`/api/CreditReview/removeDocument?fullPath=${doc.fullPath}&creditAppId=${this.appObj.guid}`
        )
        .then(res => {
          if (res.data.isSuccess) {
            this.appObj.adminDocuments.splice(this.appObj.adminDocuments.indexOf(doc), 1)
          } else {
            palert({
              title: 'Error',
              text: 'Something went wrong. Could not remove the document.',
              type: 'error'
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    // getFileExtension (filename) {
    //   let fileAry = filename.split('.')
    //   let len = fileAry.length
    //   return fileAry[len - 1].toLowerCase()
    // },
    async downloadDoc (fullPath, useThisFileNameInstead = null) {
      axios({
        url: `/api/CreditReview/Download?fullPath=${fullPath}&creditAppId=${this.appObj.guid}`,
        method: 'GET',
        responseType: 'blob'
      }).then((res) => {
        const bytes = res.data
        const file = new Blob([bytes], { type: res.headers['content-type'] })
        const fileURL = window.URL.createObjectURL(file)
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        if (useThisFileNameInstead) {
          fileLink.download = useThisFileNameInstead
        } else {
          const [, contentDisposition] = res.headers['content-disposition'].split('filename=')
          fileLink.download = contentDisposition.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, '')
        }
        document.body.appendChild(fileLink)
        fileLink.click()
      })
        .catch((err) => {
          console.error(err)
          this.loading = false
          palert({ type: 'error', title: 'Unable to download Document' })
        })
    },
    downloadAllDocs (creditAppId) {
      axios({
        url: '/api/CreditReview/DownloadAll?creditAppId=' + creditAppId,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
        const link = document.createElement('a')
        link.setAttribute('target', '_blank')
        link.href = url
        link.setAttribute('download', 'CreditApplication_Full.zip')
        document.body.appendChild(link)
        link.click()
      })
        .catch(err => {
          console.error(err)
          this.loading = false
          palert({ type: 'error', title: 'Unable to download All Documents' })
        })
    },
    getMessageStyle () {
      if (this.appObj.isCreatedFromStorefront) {
        return 'background-color:#13BBB6;color:white;'
      } else if (this.appObj.dateEmailed) {
        return 'background-color:#6332B3;color:white;'
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss">
#credit-review-detail {
  a + .tool-tip {
    opacity: 0;
    font-size: 12px;
    background: #ddd;
    display: none;
    padding: 0.5rem;
    position: relative;
    margin-left: -10px;
    transition: 0.2s all ease;
    &:before {
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: -8px;
      position: absolute;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 8px solid #ddd;
    }
    @media screen and (min-width: 768px) {
      display: inline-block;
    }
  }
  a:hover + .tool-tip {
    opacity: 1;
    margin-left: 5px;
  }
  h4 {
    margin-bottom: 0;
    @media (max-width: 442px) {
      font-size: 1.25rem;
      a.mr-3 {
        margin-right: 0.25rem !important;
      }
    }
  }
  a,
  button {
    &:hover {
      cursor: pointer;
    }
  }
  .review-header {
    margin-bottom: 2rem;
    .row {
      justify-content: center;
      @media (max-width: 456px) {
        .dropdown-credit-btn,
        .credit-status-btn {
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }
      @media (min-width: 768px) {
        justify-content: start;
      }
      .col {
        @media (max-width: 992px) {
          flex-basis: unset;
        }
      }
    }
    @media (max-width: 992px) {
      .review-status {
        margin: 1rem 0 0;
      }
      #credit-status-btn {
        float: left;
      }
    }
  }

  .btn-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .dropdown-credit-btn {
    position: relative;
    display: inline-block;
    &::after {
      content: "";
      height: 100%;
      display: block;
      clear: both;
    }
    button {
      font-size: 0.875rem;
      font-weight: 600;
    }
    .split-rounded {
      position: absolute;
      top: 0;
      right: 0;
      border-left: white 1px solid;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }

    .dropdown-menu {
      min-width: 250px;
      position: absolute;
      z-index: 100;
      padding: 10px;
      top: 37px;
      right: 0px;
      left: unset;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      font-size: 0.875rem;
      font-weight: 700;
      color: #797979;
      width: auto;
      &::before,
      &::after {
        content: "";
        position: absolute;
        background: #fff;
      }
      &:before {
        transform: rotate(45deg);
        width: 12px;
        height: 12px;
        right: 19px;
        top: -5px;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
      }
      &::after {
        width: 35px;
        height: 20px;
        right: 3px;
        top: 0px;
        box-shadow: none;
      }
      li a {
        font-family: "PT Sans";
        font-size: 0.875rem;
        font-weight: 700;
        color: #797979;
        position: relative;
        z-index: 200;
        display: block;
        padding: 5px 20px;
        &:hover {
          background: #f2f2f2;
          color: #000;
        }
      }
    }
    .status-button {
      .dropdown-menu {
        @media (max-width: 992px) {
          left: 0px;
          right: unset;
        }
        &:before {
          transform: rotate(45deg);
          width: 12px;
          height: 12px;
          right: 92px;
          top: -5px;
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
        }
        &::after {
          width: 35px;
          height: 20px;
          right: 87px;
          top: 0px;
          box-shadow: none;
        }
      }
    }
  }

  .section-title {
    font-size: 1.125rem;
    margin: 1rem 0px;
    color: #333333;
    @media (max-width: 442px) {
      display: block;
      width: 100%;
    }
  }

  .row {
    align-items: center;
  }

  section {
    &::after {
      content: "";
      height: 100%;
      display: block;
      clear: both;
    }
  }

  .review-status {
    position: relative;
    text-align: right;
  }
  #application-message {
    width: 100%;
    margin-bottom: 1.5rem;
    clear: both;

    .status {
      font-weight: 700;
    }

    .card {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 0.85rem;
      background-color: #eee;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      #approve-btn {
        text-align: center;

        @media (min-width: 768px) {
          text-align: right;
        }
      }
      > div {
        width: 100%;
        text-align: center;

        @media (min-width: 768px) {
          text-align: left;
        }
      }
    }
  }
  .review-body {
    clear: both;
  }
  .padding-right-20 {
    padding-right: 20px;
  }
  @media (max-width: 443px) {
    .btns,
    .btns > button {
      width: 100%;
    }
  }
}
</style>
