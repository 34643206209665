<template>
  <v-btn
    ref="button"
    :disabled="disabled"
    :loading="addressValidationLoading"
    tile
    class="form-address-validation-button"
    color="#099c98"
    @click="setValidatedAddress">
    Validate Address
    <portal
      v-if="portalTarget !== 'defaultModalTarget'"
      :to="portalTarget">
      <form-address-validation-modal
        :is-dialog-open="showModal"
        :validated-address="validatedAddress"
        :form-data-address="addressFormDataLocal"
        :use-validated="useValidated"
        :overlay-color="overlayColor"
        :should-use-entered="shouldUseEntered"
        @useEntered="useEntered"
        @updateAddress="updateFormData"
        @updateUseValidated="updateUseValidated"
        @close="closeModal"
        @editAddress="editAddress">
      </form-address-validation-modal>
    </portal>
    <form-address-validation-modal
      v-else
      :is-dialog-open="showModal"
      :validated-address="validatedAddress"
      :form-data-address="addressFormDataLocal"
      :use-validated="useValidated"
      :overlay-color="overlayColor"
      :should-use-entered="shouldUseEntered"
      @useEntered="useEntered"
      @updateAddress="updateFormData"
      @updateUseValidated="updateUseValidated"
      @close="closeModal"
      @editAddress="editAddress">
    </form-address-validation-modal>
  </v-btn>
</template>

<script>
import FormAddressValidationModal from './FormAddressValidationModal.vue'
import axios from 'ca-http-service'

export default {
  name: 'FormAddressValidation',
  components: {
    FormAddressValidationModal
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    overlayColor: {
      type: String,
      required: false,
      default: undefined
    },
    vuelidate: {
      type: [Object, Boolean],
      required: false,
      default: () => false
    },
    portalTarget: {
      type: String,
      required: false,
      default: 'defaultModalTarget'
    },
    shouldUseEntered: {
      type: Boolean,
      required: false,
      default: () => false
    },
    closeModalFromOutside: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      uniqueId: null,
      addressValidationLoading: false,
      validatedAddress: {},
      showModal: false,
      useValidated: true,
      addressParamsReplace: {
        streetAddress1: 'street1',
        streetAddress2: 'street2',
        zipCode: 'zip',
        postalCode: 'zip'
      },
      addressResponseReplace: {
        street2: 'streetAddress1', // <--- Response from endpoint reverses street variables
        street1: 'streetAddress2',
        zip: 'zipCode'
      }
    }
  },
  computed: {
    portalTargetName () {
      return `${this.portalTarget}-${this._uid}`
    },
    addressFormDataLocal () {
      return this.extractAddressVariables(this.formData)
    },
    vuelidateLocal () {
      if (!this.vuelidate) {
        return this.buildFakeVuelidate()
      }
      return this.extractAddressVariables(this.vuelidate)
    },
    disabled () {
      const keys = Object.keys(this.vuelidateLocal)
      const found = keys.filter((k) => {
        return this.vuelidateLocal[k].$invalid === true
      })
      return found.length > 0
    }
  },
  watch: {
    closeModalFromOutside (newVal) {
      if (newVal === true) {
        this.closeModal()
      }
    }
  },
  mounted () {
    this.uniqueId = this._uid
  },
  methods: {
    useEntered () {
      this.$emit('useEntered')
    },
    closeModal () {
      this.showModal = false
      this.$emit('close')
    },
    setValidatedAddress () {
      this.addressValidationLoading = true
      this.validatedAddress = {}

      const params = this.buildAddressParams(this.addressFormDataLocal, this.addressParamsReplace)

      axios.get('https://api.platt.com/api/AddressVerification/GetSuggestedAddress/', { params })
        .then((res) => {
          const responseParams = this.buildAddressParams(res.data, this.addressResponseReplace)
          const validatedAddress = this.extractAddressVariables(responseParams)
          setTimeout(() => {
            this.setModalComplete(validatedAddress)
            this.$emit('click')
          }, 500)
        })
        .catch((err) => {
          setTimeout(() => {
            this.setModalComplete({})
            this.$emit('click')
          }, 500)
          console.error(err)
        })
    },
    setModalComplete (validatedAddress) {
      this.validatedAddress = validatedAddress
      this.addressValidationLoading = false
      this.useValidated = Object.keys(validatedAddress).length > 0
      this.showModal = true
    },
    extractAddressVariables (obj) {
      const addressValidationObj = (({ streetAddress1, streetAddress2, city, zipCode, postalCode, state }) => ({ streetAddress1, streetAddress2, city, zipCode, postalCode, state }))(obj)
      Object.keys(addressValidationObj).map((k) => {
        if (addressValidationObj[k] === undefined) delete addressValidationObj[k]
        return k
      })
      return addressValidationObj
    },
    buildFakeVuelidate () {
      const localFormData = this.addressFormDataLocal
      const keys = Object.keys(localFormData)
      const fakeVulidate = {}
      keys.map((k) => {
        if (k === 'streetAddress2') return k
        fakeVulidate[k] = { $invalid: localFormData[k].trim() === '' }
        return k
      })
      return fakeVulidate
    },
    buildAddressParams (object, replaceObj) {
      const clone = { ...object }
      const keys = Object.keys(clone)
      keys.map((k) => {
        const newKey = replaceObj[k]
        if (typeof newKey !== 'undefined') {
          clone[newKey] = clone[k] === null ? '' : clone[k]
          delete clone[k]
          return k
        }
        return k
      })
      return clone
    },
    updateFormData () {
      if (this.useValidated) {
        this.$emit('updateFormData', this.validatedAddress)
      }
    },
    updateUseValidated (value) {
      this.useValidated = value
    },
    editAddress () {
      const button = this.$refs.button.$el
      this.$emit('editAddress', button)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-address-validation-button {
  &.v-btn {
    text-transform: none;
    color: #FFFFFF;
  }
}
</style>
