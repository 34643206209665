<template>
  <div
    id="signatory-info-component"
    class="component-container">
    <div class="card">
      <div class="card-block">
        <div class="form-group">
          <label>Full Name:</label>
          <p>
            {{ s.firstName }} {{ s.middleInitial }} {{ s.lastName }}<span v-if="s.suffix">
              , {{ s.suffix }}
            </span>
          </p>
        </div>
        <div
          v-if="s.jobTitle"
          class="form-group">
          <label>Title:</label>
          <p>{{ s.jobTitle }}</p>
        </div>
        <div
          v-if="s.addressInfo"
          class="form-group">
          <label>Primary Address:</label>
          <p>{{ s.addressInfo.streetAddress }}</p>
          <p v-if="s.addressInfo.addressUnitNumber && s.addressInfo.addressUnitNumber != ''">
            Unit: {{ s.addressInfo.addressUnitNumber }}
          </p>
          <p>{{ s.addressInfo.city }}, {{ s.addressInfo.state }} {{ s.addressInfo.postalCode }}</p>
          <p>{{ s.addressInfo.country }} </p>
        </div>
      </div>
      <div class="card-block">
        <div
          v-if="s.phone"
          class="form-group">
          <label>Phone:</label>
          <p>
            {{ s.phone }}
            <span v-if="s.phoneExt">
              Ext. {{ s.phoneExt }}
            </span>
          </p>
        </div>
        <div class="form-group">
          <label>Email:</label>
          <p>{{ s.email }}</p>
        </div>
        <div class="form-group">
          <label>Guaranty of Indebtedness:</label>
          <p>
            {{ s.guarantor && s.hasSigned ? 'Yes' : 'No' }}
            <span
              v-if="!s.hasSigned"
              class="badge badge-danger signature-status">
              Unsigned
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignatoryInfo',
  props: {
    s: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
#signatory-info-component {
  margin-bottom: 1rem;
  .card {
    @media (min-width: 768px) {
      flex-direction: row;
    }

    label,
    p{
      margin-bottom: .25rem;
    }

    label{
      color: #999;
      font-size: .8em;
    }

    p {
      font-size: .875rem;
      font-weight: 700;
      word-break: break-word;
    }

    .signature-status{
        font-size:85%;
        margin-left:.5em;
    }

    &-block {
        display: flex;
        flex-wrap: wrap;
        padding: .5rem;
        position: relative;
        .form-group {
        padding: .5rem;
        margin-bottom: 1rem;

        @media (min-width: 414px){
          width: 50%;
        }
      }
      @media (min-width: 768px) {
        width: 50%;
        &:nth-of-type(1) {
          &::after {
            content: '';
            width: 1px;
            height: calc(100% - 2rem);
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            background-color: #ccc;
          }
        }
      }
      @media (max-width: 768px) {
        padding: 0 .5rem;
        margin: 0;
        .form-group {
          padding: 0 .5rem;
          margin: 0;
        }
      }
    }
    @media (max-width: 768px) {
      padding: 1rem 0;
    }
    @media (max-width: 442px) {
      .form-group {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
}
</style>
