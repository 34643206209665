<template>
  <div>
    <div
      v-if="mode !== 'stepped'"
      :class="headerCssClasses"
      style="cursor: default"
      :style="isSubStep ? 'margin-left: 2em;' : ''">
      <!-- DRY THIS -->
      <span
        :hidden="hideCircle || isSubStep"
        class="step-number circle"
        :class="setStepNumberStatusStyle()">
        <span v-if="!invalid && dirty">
          <i class="validated fa fa-check"></i>
        </span>
        <span v-else-if="invalid && dirty">
          <i class="invalidated fa fa-times"></i>
        </span>
        <span v-else>
          {{ displayOrder }}
        </span>
      </span>
      <span>
        {{ title }}
      </span>
      <span>
        <a
          v-if="sectionHeaderToolTip"
          data-toggle="tooltip"
          style="color: #5bc0de;"
          class="fa fa-info-circle"
          role="tooltip"
          :title="sectionHeaderToolTip"></a>
      </span>
      <!-- END DRY-->
    </div>
    <button
      v-else
      :class="setStepHeaderStatusStyle() + ' ' + headerCssClasses"
      :disabled="disableStepButton()"
      :data-target="`#collapseOne${order}`"
      :aria-controls="`collapseOne${order}`"
      aria-expanded="true"
      @click="clickHandler(true, order)">
      <!-- DRY THIS -->
      <span
        :hidden="hideCircle || isSubStep"
        class="step-number circle"
        :class="setStepNumberStatusStyle()">
        <span v-if="!invalid && dirty">
          <i class="validated fa fa-check"></i>
        </span>
        <span v-else-if="invalid && dirty">
          <i class="invalidated fa fa-times"></i>
        </span>
        <span v-else>
          {{ order }}
        </span>
      </span>
      <span>
        {{ title }}
      </span>
      <span>
        <a
          v-if="sectionHeaderToolTip"
          data-toggle="tooltip"
          style="color: #5bc0de;"
          class="fa fa-info-circle"
          role="tooltip"
          :title="sectionHeaderToolTip"></a>
      </span>
      <!-- END DRY-->
    </button>
    <div
      v-if="$store.getters.showDevFormValidationState === true"
      style="opacity: .7; font-size: .7em; margin-left: 3.6em; background-color: rgba(215,215,255); border-radius: .6em;">
      Step Header validations
      $dirty: <span :style="dirty === false ? 'color: #b40000' : 'color: green'">
        {{ dirty }}
      </span> -
      $error: <span :style="error === false ? 'color: #b40000' : 'color: green'">
        {{ error }}
      </span> -
      $invalid: <span :style="invalid === false ? 'color: #b40000' : 'color: green'">
        {{ invalid }}
      </span>
    </div>
  </div>
</template>

<script>
// NOTE: This component will not display as valid/invalid if there are no vuelidate rules configured for the form in this section.
export default {
  name: 'FormStepHeader',
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    clickHandler: {
      type: Function,
      required: true
    },
    error: {
      type: Boolean,
      required: true
    },
    pending: {
      type: Boolean,
      required: true
    },
    invalid: {
      type: Boolean,
      required: true
    },
    dirty: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    order: {
      type: Number,
      required: true
    },
    displayOrder: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    sectionHeaderToolTip: {
      type: String,
      required: false,
      default: ''
    },
    hideCircle: {
      type: Boolean,
      required: false,
      default: false
    },
    isSubStep: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      headerCssClasses: 'text-left w-100 font-weight-bold btn btn-link mb-2'
    }
  },
  methods: {
    isValidAndDirty () {
      return !this.invalid && this.dirty
    },
    isInvalidAndDirty () {
      return this.invalid && this.dirty
    },
    setStepNumberStatusStyle () {
      if (this.isValidAndDirty()) {
        return 'validated-step'
      } else if (this.isInvalidAndDirty()) {
        return 'invalidated-step'
      } else if (this.active) {
        return 'active-step'
      }
    },
    disableStepButton () {
      if (this.mode === 'full') {
        return false
      } else {
        return !(this.active ||
          this.isValidAndDirty() ||
          this.isInvalidAndDirty())
      }
    },
    setStepHeaderStatusStyle () {
      if (this.mode === 'full') {
        return this.active ||
          this.isValidAndDirty()
          ? 'step-button'
          : ''
      } else {
        return this.active ||
          this.isValidAndDirty() ||
          this.isInvalidAndDirty()
          ? 'step-button'
          : 'disabled'
      }
    }
  }
}
</script>

<style scoped>
.circle {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  line-height: 1.7rem;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
}

.active-step {
  background-color: #4285f4 !important;
}

.validated-step {
  background-color: #099c98;
}

.invalidated-step {
  background-color: #a10000;
}

.validated {
  padding-top: 3px;
  margin: 0;
  font-size: 20px;
  -webkit-text-stroke: 1px #099c98;
}

.invalidated {
  padding-top: 3px;
  margin: 0;
  font-size: 20px;
  -webkit-text-stroke: 1px #a10000;
}

.step-button:hover:enabled {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

button.disabled {
  cursor: auto;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
  box-shadow: none;
}

.fa-info-circle {
    color: #5bc0de;
    font-size: 16px;
  }
</style>
