<template>
  <form-group
    v-if="vuelidate"
    :validator="vuelidate"
    :name="inputLabel">
    <v-text-field
      v-if="isInternal"
      v-model="inputValue"
      v-mask="getInputMask"
      filled
      dense
      :label="inputLabel"
      class="rounded-0"
      hide-details="auto"
      :single-line="!placeholder && !persistLabel"
      v-bind="$attrs"
      :type="filteredType"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      :counter="counter"
      :max-length="maxLength"
      :error-messages="errorMessages"
      :rules="[ value => vuelidate.required && value.trim().length === 0 ? false : true]"
      @blur="validateInput">
      <template #label>
        {{ inputLabel }} <span v-if="isRequired">
          *
        </span>
      </template>
      <template
        v-if="toolTip"
        v-slot:prepend-inner>
        <v-tooltip
          bottom>
          <template #activator="{ on }">
            <v-icon
              color="primary"
              dark
              v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span class="white--text">
            {{ toolTip.message }}
          </span>
        </v-tooltip>
      </template>
    </v-text-field>
    <v-text-field
      v-else
      v-model="inputValue"
      v-mask="getInputMask"
      outlined
      dense
      class=""
      hide-details="auto"
      v-bind="$attrs"
      :type="filteredType"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      :counter="counter"
      :max-length="maxLength"
      :error-messages="errorMessages"
      :rules="[ value => vuelidate.required && value.trim().length === 0 ? false : true]"
      @blur="validateInput">
      <template #label>
        <span class="external-label">
          {{ inputLabel }}
        </span> <span v-if="isRequired">
          *
        </span>
      </template>
      <template
        v-if="toolTip"
        v-slot:prepend-inner>
        <v-tooltip
          bottom>
          <template #activator="{ on }">
            <v-icon
              color="primary"
              dark
              v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span class="white--text">
            {{ toolTip.message }}
          </span>
        </v-tooltip>
      </template>
    </v-text-field>
  </form-group>
</template>

<script>
import FormGroup from 'components/form/FormGroup.vue'
import { mask } from 'vue-the-mask'

export default {
  name: 'FormTextInput',
  components: {
    FormGroup
  },
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return
      mask(el, binding)
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    persistLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    toolTip: {
      type: Object,
      required: false,
      default: null
    },
    counter: {
      type: [Boolean, Number, String],
      required: false,
      default: false
    },
    maxLength: {
      required: false,
      type: String,
      default: null
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        return [
          'ssn',
          'ein',
          'text',
          'tel',
          'email'
        ].indexOf(value) !== -1
      }
    },
    errorMessages: {
      type: Array,
      required: false,
      default: null
    },
    vuelidate: {
      type: [Object, null],
      required: false,
      default: () => {}
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.updateValue(newValue)
      }
    },
    isRequired () {
      return (this.vuelidate.required != null && this.vuelidate.$invalid === true)
    },
    isInternal () {
      return this.$route.name === 'adminApplication'
    },
    getInputMask: function () {
      switch (this.inputType) {
        case 'ssn':
          return '###-##-####'
        case 'ein':
          return '##-#######'
        case 'tel':
          return '(###) ###-####'
        default:
          return ''
      }
    },
    filteredType () {
      if (['ssn', 'ein'].includes(this.inputType)) {
        return 'text'
      } else {
        return this.inputType
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
    validateInput () {
      if (this.inputValue.trim().length === 0) {
        this.$emit('input', '')
        return
      }
      if (this.inputType === 'email') {
        this.inputValue = this.inputValue.toLowerCase()
      }
      if (this.vuelidate !== null) {
        this.vuelidate.$touch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  color: red;
}
.v-label--active {
  .external-label {
    color: rgba(0,0,0,.6);
  }
}
.external-label {
  color: rgba(0,0,0,.6);
}
</style>
