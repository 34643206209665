<template>
  <v-dialog
    v-model="show"
    max-width="600px"
    persistent
    class="adjust">
    <v-container class="p-0 upload-modal">
      <v-card class="rounded-0 p-2">
        <v-row
          no-gutters
          class="mb-12">
          <v-col
            cols="9"
            sm="8"
            class="d-flex justify-end">
            <v-card-title class="p-0">
              {{ title }}
            </v-card-title>
          </v-col>
          <v-col
            cols="3"
            sm="4"
            class="d-flex justify-end">
            <v-card-actions class="ml-auto">
              <v-btn
                color="gray"
                icon
                class="mr-0"
                @click.stop="show = false">
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <FormFileUploader
          value=""
          placeholder="Select File"
          button-color="#000"
          button-text="BROWSE"
          multiple
          class="no-gutters pl-6 rounded-0"
          @input="updateSelected"></FormFileUploader>
        <v-row
          v-show="numOfFiles > 5"
          no-gutters>
          <v-col cols="12">
            <p class="red--text pl-6 mb-0">
              *Max of 5 documents per section
            </p>
          </v-col>
        </v-row>
        <v-card-actions class="flex-row-reverse mt-5">
          <v-btn
            tile
            color="#0A287D"
            class="ml-4 py-6 px-6 white--text"
            :disabled="numOfFiles > 5"
            @click.stop="uploadDocuments(selectedDocuments)">
            Upload {{ multipleFiles ? 'Documents' : 'Document' }}
          </v-btn>
          <v-btn
            text
            class="font-weight-bold"
            @click.stop="cancelUpload">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script type="text/javascript">
import FormFileUploader from '@/components/form/FormFileUploaderV3.vue'

export default {
  name: 'FileUploadModal',
  components: {
    FormFileUploader
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    previousUploadedDocuments: {
      type: Array,
      required: true,
      default: () => []
    },
    applicationId: {
      type: String,
      required: true,
      default: ''
    },
    modalType: {
      type: String,
      required: true,
      default: ''
    },
    modalTitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      selectedDocuments: null,
      isLoading: false,
      isUploaded: false,
      uploadedDocuments: []
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    title () {
      if (this.modalTitle) {
        return this.modalTitle
      } else if (this.modalType === 'tax') {
        return 'Upload Tax Documents'
      } else {
        return 'Upload Other Documents'
      }
    },
    numOfFiles () {
      if (this.selectedDocuments) {
        return this.selectedDocuments.getAll('files').length + this.previousUploadedDocuments.length
      } else {
        return this.previousUploadedDocuments.length
      }
    },
    multipleFiles () {
      return (this.selectedDocuments && this.selectedDocuments.getAll('files').length > 1)
    }
  },
  methods: {
    updateSelected (documents) {
      if (documents) {
        this.selectedDocuments = documents
      } else {
        this.selectedDocuments = null
      }
    },
    uploadDocuments (documents) {
      this.isLoading = true

      const tenant = this.$route.params.tenant

      // let docType= '';

      // if (this.modalType === 'tax') {
      //   docType = '/tax'
      // } else {
      //   docType = '/tax'
      // }

      const endpointUrl = `/api/CreditApplication/upload/${tenant}/${this.applicationId}/${this.modalType}`

      // let applicationId = this.applicationId;

      // if (applicationId != null) {
      //   endpointUrl += `/${applicationId}`
      // }
      this.$http
        .post(
          endpointUrl,
          documents,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(res => {
          // const storageGuid = res.data.data.applicationId
          const storageGuid = this.applicationId

          // NOTE: This Repeated Logic could be DRYer
          const uploadedFiles = res.data.data.fileList

          if (this.previousUploadedDocuments.length > 0) {
            const newlyUploadedFiles = [...this.previousUploadedDocuments]
            uploadedFiles.forEach(u => {
              const i = this.previousUploadedDocuments.findIndex(x => x.name === u.name)
              if (i <= -1) {
                newlyUploadedFiles.push(u)
              }
            })

            this.uploadedDocuments = [...newlyUploadedFiles]
          } else {
            this.uploadedDocuments = uploadedFiles
          }
          this.isUploaded = true
          const docType = this.modalType
          this.$emit('uploaded', { files: this.uploadedDocuments, storageGuid, docType })
        })
        .catch(err => {
          console.error(err)
        })
      this.show = false
      this.isLoading = false
    },
    cancelUpload () {
      this.selectedDocuments = null
      this.show = false
    }
  }
}

</script>

<style lang="scss" scoped>
.v-dialog {
  border-radius: 0;
}
.upload-modal {
  border-top: 3px solid #00416a;
  .v-card {
    overflow-x: hidden;
  }
  .v-card__title {
    color: rgba(0,0,0,0.87);
    font-family: Rubik, sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }
  i {
    margin-right: 0;
  }
}

</style>
