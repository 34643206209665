<template>
  <fieldset>
    <span v-if="isPlatt()">
      <div
        class="form-control-label"
        style="font-size:12px;color:#4285f4;">
        Materials for the job must be delivered directly to the jobsite. No tools should be charged to a job account.
      </div>
    </span>
    <div class="row mt-1">
      <div class="col-md-4">
        <FormInputNumberInput
          v-model="userFormInput.requestedJobLimit"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.requestedJobLimit"
          :input-label="formLabels.requestedJobLimit"></FormInputNumberInput>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <FormInputCheckboxes
          v-model="userFormInput.materialsNeeded"
          :disabled="isStepDisabled"
          :input-label="formLabels.materialsNeeded"
          :checkboxes="materialsNeededAvailable"></FormInputCheckboxes>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.otherMaterials"
          :disabled="isStepDisabled"
          :input-label="formLabels.otherMaterials"
          input-type="text"></FormInputTextInput>
      </div>
    </div>
  </fieldset>
</template>

<script>
import TenantsMixin from 'mixins/tenants.mixin'
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputCheckboxes from '@/components/form/FormInputCheckboxes.vue'
import FormInputNumberInput from '@/components/form/FormInputNumberInput.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'

export default {
  name: 'JobAccountFormStepProjectMaterial',
  components: {
    FormInputCheckboxes,
    FormInputTextInput,
    FormInputNumberInput
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      // Platt.com Available Materials Needed
      materialsNeededAvailable: [
        {
          value: 'Gear',
          key: 'gear'
        },
        {
          value: 'Lighting',
          key: 'lighting'
        },
        {
          value: 'Wire',
          key: 'wire'
        },
        {
          value: 'Conduit',
          key: 'conduit'
        },
        {
          value: 'Boxes',
          key: 'boxes'
        },
        {
          value: 'Ballasts',
          key: 'ballasts'
        },
        {
          value: 'Trim',
          key: 'trim'
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.tenant !== 'platt') {
      // Add these if not Platt
      const additionalMaterialsNeededAvailable = [
        {
          value: 'Fixtures',
          key: 'fixtures'
        },
        {
          value: 'Rough-in',
          key: 'rough-in'
        },
        {
          value: 'Connectivity',
          key: 'connectivity'
        },
        {
          value: 'Cable',
          key: 'cable'
        },
        {
          value: 'Solar',
          key: 'solar'
        }
      ]
      this.materialsNeededAvailable.push(...additionalMaterialsNeededAvailable)
    }

    // KEEP: When Dev Mode "Overwrite Form Data with Test Data" doesn't work
    // this.userFormInput.requestedJobLimit = 1500
  }
}
</script>
