<template>
  <fieldset>
    <div
      class="row"
      style="padding: 0 0 .8em .4em;">
      <div style="font-weight: 600;">
        A Cash Account has been created and the Instant Credit Application has been submitted.
      </div>
      Customer now has a line of credit available for immediate purchase. A welcome email has been sent to the below address.
    </div>
    <div
      class="row"
      style="
        padding: 2em 1em;
        margin: 0 0 1.2em -9px !important;
        border: #D9D9D9 solid 1px;
      ">
      <div class="row">
        <div class="col-md-3">
          <div class="complete-label">
            Company:
          </div>
          <div class="complete-value">
            {{ submittedData.companyName }}
          </div>
        </div>
        <div class="col-md-3">
          <div class="complete-label">
            Instant Credit Amount:
          </div>
          <div class="complete-value">
            <!-- HARDCODED VALUE, should be sufficient. can get actual value from submit response. -->
            $2,500.00
          </div>
        </div>
        <div class="col-md-6">
          <div class="complete-label">
            Contact:
          </div>
          <div class="complete-value">
            {{ contact }}
          </div>
        </div>

        <div class="col-md-3">
          <div class="complete-label">
            Customer Segment:
          </div>
          <div class="complete-value">
            {{ submittedData.customerSegment }}
          </div>
        </div>
        <div class="col-md-3">
          <div class="complete-label">
            Price Class:
          </div>
          <div class="complete-value">
            {{ submittedData.priceClass }}
          </div>
        </div>
        <div class="col-md-3">
          <div class="complete-label">
            Assigned Branch:
          </div>
          <div class="complete-value">
            {{ submittedData.assignedBranch }}
          </div>
        </div>
        <div class="col-md-3">
          <div class="complete-label">
            New Account Number:
          </div>
          <div class="complete-value">
            {{ submittedData.accountNumber }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button
        class="btn nav-button text-uppercase"
        @click="onDone()">
        Done
      </button>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'InstantCreditFormComplete',
  props: {
    submittedData: {
      type: Object,
      required: true
    }
  },
  computed: {
    contact () {
      return this.submittedData.contactEmail !== ''
        ? `${this.submittedData.contactName} (${this.submittedData.contactEmail})`
        : this.submittedData.contactName
    }
  },
  methods: {
    onDone () {
      this.$router.push({ name: 'dashboard', params: { tenant: this.$route.params.tenant } })
    }
  }
}
</script>

<style lang="scss" scoped>
/* Could DRY up the nav-button by making separate component, reused in FormStep */
.nav-button {
  background-color: #099c98;
  font-size: 0.64rem;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.complete-label {
  color: #777;
  font-size: 12px;
}

.complete-value {
  height: 1.4em;
  font-weight: 600;
}

.gray-box {
  // DRY ME, could maybe be a component? repeated.. here's a towel
  font-size: 14px;
  width: 100%;
  padding: 1em;
  margin: 1.2em 0;
  background-color: #eeeeee;
}
</style>
