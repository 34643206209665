<template>
  <v-row
    dense
    class="ma-0 px-4 py-1"
    justify="end">
    <v-col
      v-if="order !== 1 && order > minimumStepNumberAllowed && !$store.state.applicationForm.isFormCompleted"
      cols="auto">
      <v-btn
        tile
        class="previous"
        :small="$vuetify.breakpoint.smAndDown"
        @click="previousStep">
        Previous
      </v-btn>
    </v-col>

    <v-col
      v-if="!$store.state.applicationForm.isFormCompleted"
      cols="auto">
      <v-btn
        tile
        :small="$vuetify.breakpoint.smAndDown"
        :disabled="isSaveStepInValid || loading"
        @click="onSave(nextStepCallback, order, addressesToValidate)">
        <span v-if="isInternal && order === 4">
          Save
        </span>
        <span v-else-if="!isLastStep">
          Save and Next
        </span>
        <span v-else>
          Save and Submit
        </span>
      </v-btn>
    </v-col>

    <v-col
      v-if="isInternal"
      cols="auto">
      <v-btn
        tile
        :small="$vuetify.breakpoint.smAndDown"
        :disabled="isEmailStepInValid || loading"
        @click="onEmail(null, order, addressesToValidate)">
        <span v-if="emailSent">
          Save and Resend to Customer
        </span>
        <span v-else>
          Save and Email to Customer
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormActions',
  props: {
    order: {
      type: Number,
      required: true
    },
    onSave: {
      type: Function,
      required: false,
      default: null
    },
    onEmail: {
      type: Function,
      required: false,
      default: null
    },
    onSubmit: {
      type: Function,
      required: true
    },
    emailSent: {
      type: Boolean,
      required: false,
      default: false
    },
    minimumStepNumberAllowed: {
      type: Number,
      required: false,
      default: 1
    },
    isLastStep: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Object,
      required: false,
      default: null
    },
    sections: {
      type: Array,
      required: false,
      default: null
    },
    isInternal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    addressesToValidate () {
      const isRouteWithAddressFields = this.title === 'Detail Information' && (this.$route.name === 'application' || this.$route.name === 'adminApplication')
      if (isRouteWithAddressFields) {
        return { shipping: this.isShippingAddressValid, billing: this.isBillingAddressValid }
      }
      return false
    },
    isBillingAddressValid () {
      const billingInfo = this.validations.billingInfo
      const streetAddress = billingInfo.streetAddress.$model.length && !billingInfo.streetAddress.$invalid
      const city = billingInfo.city.$model.length && !billingInfo.city.$invalid
      const state = billingInfo.state.$model.length && !billingInfo.state.$invalid
      const postalCode = billingInfo.postalCode.$model.length && !billingInfo.postalCode.$invalid
      const country = billingInfo.country.$model.length && !billingInfo.country.$invalid

      return !!(streetAddress && city && state && postalCode && country)
    },
    isShippingAddressValid () {
      const billingInfo = this.validations.billingInfo
      const streetAddress = billingInfo.shippingStreetAddress.$model.length && !billingInfo.shippingStreetAddress.$invalid
      const city = billingInfo.shippingCity.$model.length && !billingInfo.shippingCity.$invalid
      const state = billingInfo.shippingState.$model.length && !billingInfo.shippingState.$invalid
      const postalCode = billingInfo.shippingPostalCode.$model.length && !billingInfo.shippingPostalCode.$invalid
      const country = billingInfo.shippingCountry.$model.length && !billingInfo.shippingCountry.$invalid

      const isSameAsBilling = !!parseInt(billingInfo.isBillingAddressSameAsShipping.$model)

      return !!(streetAddress && city && state && postalCode && country && !isSameAsBilling)
    },
    isEmailStepInValid () {
      let invalid = false
      if (this.sections[0] === 'businessInfo') {
        const hasAccountInvalid = this.validations.businessInfo.hasAccount.$invalid
        const internalCompanyNameInvalid = this.validations.businessInfo.internalCompanyName.$invalid
        const emailAddressInvalid = this.validations.businessInfo.email.$invalid
        const insideSalesPersonInvalid = this.validations.businessInfo.insideSalesPerson.$invalid
        const customerTypeCategoryInvalid = this.validations.businessInfo.customerTypeCategory.$invalid
        if (hasAccountInvalid || internalCompanyNameInvalid || emailAddressInvalid || insideSalesPersonInvalid || customerTypeCategoryInvalid) {
          invalid = true
        }
      }
      return invalid
    },
    isSaveStepInValid () {
      const validations = this.validations
      const multipleSignatories = validations.signatories.$model.signatories.length > 1

      if (this.sections[0] === 'signatories') {
        if (validations.signatories.signatory.$invalid) {
          return true
        } else if (validations.signatories.signatories.$model[1] && (validations.signatories.signatories.$model[0].email === validations.signatories.signatories.$model[1].email)) {
          return true
        } else if (validations.billingInfo.businessType.$model === 'Partnership' && validations.signatoryTwo.$invalid) {
          return true
        } else if (!validations.signatoryTwo.$invalid) {
          return false
        } else {
          return multipleSignatories
        }
      }
      return this.sections.some(function (section) {
        return validations[section].$invalid
      })
    },
    nextStepCallback () {
      return !this.isLastStep ? this.nextStep : null
    }
  },
  methods: {
    stepStatusHandler (isActive, order) {
      this.$emit('is-active', {
        isActive,
        order
      })
    },
    nextStep () {
      this.stepStatusHandler()
      this.$emit('next-step')
    },
    previousStep () {
      this.stepStatusHandler(true, this.order - 1)
      this.$emit('previous-step')
    }

  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
        color: #fff !important;
        background-color: #004168 !important;
        font-size: 1rem !important;
        letter-spacing: normal !important;
        border-color: #004168;
        font-family: "PT Sans", sans-serif;
        font-weight: 400;
        text-align: center;
        text-transform: none;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        padding: .5rem 1rem;
        margin-left: 10px;
        transition: all .2s ease-in-out;
        &.previous  {
        margin-right: 10px;
        color: black !important;
        background-color: #D8D8D8 !important;
        }
        &:hover{
        background-color: #002135 !important;
        border-color: #001b2b;
        }
    }
</style>
