<template>
  <span>
    <span
      v-if="data.status === 'Approved'"
      class="legend-approved">
      <v-icon color=#005a2c>
          fa-check-circle
      </v-icon>
    </span>
    <span
      v-else-if="data.status === 'Denied'"
      class="legend-denied">
      <v-icon color=#b40000>
        fa-times-circle
      </v-icon>
    </span>
    <span
      v-else
      class="legend-pending">
      <v-icon color=#004168>
        fa-minus-circle
      </v-icon>
    </span>
    <span class="pull-right">
      <v-icon
        small
        color="black">
        fa-arrow-right
      </v-icon>
    </span>
  </span>
</template>
<script type="text/javascript">
const approvedTagComponent = {
  props: ['data'],
  name: 'status'
}
export default approvedTagComponent
</script>
