<template>
  <fieldset>
    <div class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.principal1FirstName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.principal1FirstName"
          :input-label="formLabels.principal1FirstName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.principal1LastName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.principal1LastName"
          :input-label="formLabels.principal1LastName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.principal1Title"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.principal1Title"
          :input-label="formLabels.principal1Title"
          input-type="text"></FormInputTextInput>
      </div>
    </div>

    <div>
<FormInputRadioOptions
        v-model="userFormInput.use2ndPrincipal"
        :disabled="isStepDisabled"
        :input-label="formLabels.use2ndPrincipal"
        default-value='0'
        :options="[
          {
            key: 'Yes',
            value: '1',
          },
          {
            key: 'No',
            value: '0',
          }
        ]"></FormInputRadioOptions>
</div>
    <div class="row" v-if="userFormInput.use2ndPrincipal === '1'">
<div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.principal2FirstName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.principal2FirstName"
          :input-label="formLabels.principal2FirstName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.principal2LastName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.principal2LastName"
          :input-label="formLabels.principal2LastName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.principal2Title"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.principal2Title"
          :input-label="formLabels.principal2Title"
          input-type="text"></FormInputTextInput>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptions.vue'

export default {
  name: 'InstantCreditFormStepPrincipalInformation',
  components: {
    FormInputTextInput,
    FormInputRadioOptions
  },
  mixins: [
    FormStepImplementationMixin
  ]
}
</script>
