<template>
  <div>
    <div class="row">
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.firstName"
        :value="item.firstName"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.lastName"
        :value="item.lastName"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.contactNumber"
        :value="item.contactNumber"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.emailAddress"
        :value="item.emailAddress"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.jobTitle"
        :value="item.jobTitle"></FormItemCollectionItemLabelAndValue>
    </div>
  </div>
</template>

<script>
import FormItemCollectionItemLabelAndValue from '@/components/form/FormItemCollectionItemLabelAndValue.vue'

export default {
  name: 'JobAccountFormStepProjectContactsFormItem',
  components: {
    FormItemCollectionItemLabelAndValue
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    }
  }
}
</script>
