<template>
  <v-tooltip top>
    <template
      v-slot:activator="{ on }">
      <div
        class="truncateCustomerAccount"
        v-on="on">
        {{ data.customer_Account }}
      </div>
    </template>
    <span>{{ data.customer_Account }}</span>
  </v-tooltip>
</template>
<script type="text/javascript">
const CustomerAccountTagComponent = {
  props: ['data'],
  name: 'customer_Account'
}
export default CustomerAccountTagComponent
</script>

<style lang="scss">
.truncateCustomerAccount {
  width: 275px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
