import axios from 'ca-http-service'
const baseEndpoint = '/Generic/'

export const isEmailValid = async (emailAddress) => {
  return await axios
    .get(
      `${baseEndpoint}validateEmailAddress/${encodeURIComponent(emailAddress)}`)
    .then((res) => {
      console.log(`isEmailValid returned ${JSON.stringify(res.data.isSuccess)}`)
      return res.data.isSuccess
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}
