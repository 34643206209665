<template>
  <form-group
    v-if="vuelidate"
    :validator="vuelidate"
    :name="inputLabel">
    <v-text-field
      v-if="isInternal"
      v-model.lazy="inputValue"
      filled
      dense
      class="rounded-0"
      hide-details="auto"
      v-bind="$attrs"
      prefix="$"
      type="number"
      :label="inputLabel"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      :counter="counter"
      :max-length="maxLength"
      @focusout="removeDecimals"
      @blur="vuelidate ? vuelidate.$touch() : null">
      <template #label>
        {{ inputLabel }} <span v-if="isRequired">
          *
        </span>
      </template>
    </v-text-field>
    <v-text-field
      v-else
      v-model.lazy="inputValue"
      outlined
      dense
      class=""
      hide-details="auto"
      v-bind="$attrs"
      prefix="$"
      type="number"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      :counter="counter"
      :max-length="maxLength"
      @focusout="removeDecimals"
      @blur="vuelidate ? vuelidate.$touch() : null">
      <template #label>
        <span class="external-label">
          {{ inputLabel }}
        </span> <span v-if="isRequired">
          *
        </span>
      </template>
    </v-text-field>
  </form-group>
</template>

<script>
import FormGroup from 'components/form/FormGroup.vue'

export default {
  name: 'FormCurrencyInput',
  components: {
    FormGroup
  },
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    counter: {
      type: [Boolean, Number, String],
      required: false,
      default: false
    },
    maxLength: {
      required: false,
      type: Number,
      default: null
    },
    inputFunction: {
      type: Function,
      required: false,
      default: null
    },
    vuelidate: {
      type: [Object, null],
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      inputValue: null
    }
  },
  computed: {
    isRequired () {
      return (this.vuelidate.required != null && this.vuelidate.$invalid === true)
    },
    isInternal () {
      return this.$route.name === 'adminApplication'
    }
  },
  watch: {
    value: {
      handler (val) {
        const stringifiedRoundedVal = String(Math.trunc(Number(val)))
        if (this.inputValue !== stringifiedRoundedVal) {
          this.inputValue = String(Math.trunc(Number(this.value)))
        }
      }
    },
    inputValue: {
      handler (val) {
        this.updateValue(val)
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', String(value))
    },
    removeDecimals: function () {
      this.inputValue = Math.trunc(this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  color: red;
}
.v-label--active {
  .external-label {
    color: rgba(0,0,0,.6);
  }
}
.external-label {
  color: rgba(0,0,0,.6);
}
</style>
