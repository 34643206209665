var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","margin-top":"56px"}},[_c('div',{staticClass:"sticky-top"},[_c('div',{staticClass:"navbar",staticStyle:{"height":"56px","flex-direction":"row","background-color":"#032D2C"}},[_c('button',{staticClass:"nav-frame-menu-button navbar-toggler",attrs:{"type":"button"},on:{"click":function($event){_vm.openNav = !_vm.openNav}}},[_c('i',{staticClass:"fa fa-bars",staticStyle:{"color":"white"}})]),_c('img',{staticClass:"img-fluid",staticStyle:{"width":"148px","max-height":"48px"},attrs:{"src":require("../../assets/images/app-credit-central-stacked.svg")}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.openFeedbackModal()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-message-alert ")]),_vm._v(" Feedback ")],1),_c('div',[_c('div',{staticClass:"dropdown"},[_vm._m(0),_c('div',{staticClass:"dropdown-menu dropdown-menu-right active",attrs:{"aria-labelledby":"user-actions"}},[(_vm.identity.permissions.viewUsers)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                name: 'usersList'
              },"exact":""}},[_c('span',[_vm._v("Manage Users")])]):_vm._e(),(_vm.identity.permissions.editEmailTemplate)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                name: 'editEmailTemplate',
                params: {
                  tenant: _vm.company.name.toLowerCase()
                }
              },"exact":""}},[_c('span',[_vm._v("Email Template")])]):_vm._e(),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                name: 'whatsNew'
              },"exact":""}},[_c('span',[_vm._v("What's New")])]),(_vm.identity.permissions.viewUsers)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                name: 'instantCreditPermissions'
              },"exact":""}},[_c('span',[_vm._v("Instant Credit Permissions")])]):_vm._e(),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.logoutUser()}}},[_vm._v("Sign Out")]),(_vm.isDevelopmentEnvironment)?_c('v-switch',{staticClass:"pl-5",attrs:{"dense":"","inset":"","prepend-icon":"mdi-dev-to","v-model":!_vm.showDevControls},on:{"change":function($event){return _vm.$store.dispatch('toggleDevControls')}}}):_vm._e()],1)])])],1)]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"height":"100%"},attrs:{"id":"navAndContentwrapper"}},[_c('nav-layout',{attrs:{"open-nav":_vm.openNav,"top-position":_vm.sidebarTopPosition,"tenant-name":_vm.currentCompanyBanner},on:{"toggleSidebar":function($event){_vm.openNav = _vm.openNav ? !_vm.openNav : _vm.openNav}}}),_c('div',{staticClass:"content-container",attrs:{"id":"page-content"}},[_c('div',{staticClass:"container-fluid content",attrs:{"id":"currentComponent"}},[_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"content-header pl-0 ma-3"},[_vm._v(" "+_vm._s(_vm.adminHeaderTitle)+" ")])]),_c('router-view')],1),_c('feedback',{attrs:{"dialog":_vm.feedbackDialog},on:{"update:dialog":function($event){_vm.feedbackDialog=$event}}}),_c('v-spacer')],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler nav-item",staticStyle:{"margin-top":"5px","border":"0 !important","outline":"0 !important"},attrs:{"data-toggle":"dropdown","type":"button","aria-label":"Toggle navigation"}},[_c('i',{staticClass:"fa fa-ellipsis-v",staticStyle:{"color":"white"}})])
}]

export { render, staticRenderFns }