<template>
  <router-link
    class="app-btn"
    :to="{ name: '', params: { id: data.certificateId }}">
    <span
      v-if="data.status == 2"
      class="legend-approved">
      <i class="fa fa-check-circle"></i>
    </span>
    <span
      v-if="data.status == 3"
      class="legend-denied">
      <i class="fa fa-times-circle"></i>
    </span>
    <span
      v-if="data.status == 1"
      class="legend-pending">
      <i class="fa fa-minus-circle"></i>
    </span>
    <span class="pull-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </router-link>
</template>
<script type="text/javascript">
export default {
  name: 'ApprovedTag',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}

</script>
