<template>
  <fieldset>
    <div
      v-if="isInternal()"
      class="row">
      <div class="col-md-8">
        <JobAccountComboboxDialog
          v-model="userFormInput.billToAccount"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.billToAccount"
          :input-label="formLabels.billToAccount"
          :async-search-handler="searchForCustomerAccountInfoFromSales"
          :items-mapper="mapCustomerAccountSearchResults"
          @input="handleAccountSelected"></JobAccountComboboxDialog>
      </div>
      <div
        v-if="doesBillToHaveNoShipToAccounts()"
        class="col-md-12 mx-2 pt-0"
        style="color: #b40000; font-size: 14px;">
        {{ `There are no Ship To accounts associated with ${billTo.companyName}.` }} <br /><div class="mt-1">
          Please select a different Bill To account.
        </div>
      </div>
    </div>
    <template v-if="billToLoaded">
      <div class="row">
        <div class="col-md-6">
          <label
            v-if="!isInternal()"
            style="font-size: 12px;"
            class="form-control-label">
            Bill To Account:
          </label>
          <v-card
            class="mx-auto"
            max-width="700"
            outlined>
            <v-card-text>
              <v-container class="py-0">
                <v-row>
                  <span class="font-weight-bold">
                    {{ billTo.companyName }}
                  </span>
                </v-row>
                <v-row>
                  <span>{{ billToAddress }}</span>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div
        v-if="billToLoaded"
        class="row">
        <div class="col-md-10">
          <v-card
            class="ma-0 pa-0"
            max-width="1000"
            outlined>
            <v-card-text>
              <v-container class="pa-0">
                <v-row>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Branch:
                    </div>
                    <div>{{ billTo.branchName }} - {{ billTo.branchNumber }}</div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Branch Manager:
                    </div>
                    <a
                      v-if="billTo.branchManagerEmail"
                      :href="`mailto:${billTo.branchManagerEmail}`">
                      {{ billTo.branchManagerErpUserId }} - {{ billTo.branchManager }}
                    </a>
                    <div v-else>
                      {{ billTo.branchManagerErpUserId }} - {{ billTo.branchManager }}
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Credit Manager:
                    </div>
                    <a
                      v-if="billTo.creditManagerEmail"
                      :href="`mailto:${billTo.creditManagerEmail}`">
                      {{ billTo.creditRepErpUserId }} - {{ billTo.creditRep }}
                    </a>
                    <div v-else>
                      {{ billTo.creditRepErpUserId }} - {{ billTo.creditRep }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Inside Salesperson:
                    </div>
                    <a
                      v-if="billTo.inSideSalesPerson && billTo.inSideSalesPerson.emailAddress"
                      :href="`mailto:${billTo.inSideSalesPerson.emailAddress}`">
                      {{ billTo.inSideSalesPerson.eclipseUserLogin }} - {{ billTo.inSideSalesPerson.displayName }}
                    </a>
                    <div v-else-if="billTo.inSideSalesPerson !== null">
                      {{ billTo.inSideSalesPerson.eclipseUserLogin }} - {{ billTo.inSideSalesPerson.displayName }}
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Outside Salesperson:
                    </div>
                    <a
                      v-if="billTo.outSideSalesPerson && billTo.outSideSalesPerson.emailAddress"
                      :href="`mailto:${billTo.outSideSalesPerson.emailAddress}`">
                      {{ billTo.outSideSalesPerson.eclipseUserLogin }} - {{ billTo.outSideSalesPerson.displayName }}
                    </a>
                    <div v-else-if="billTo.outSideSalesPerson !== null">
                      {{ billTo.outSideSalesPerson.eclipseUserLogin }} - {{ billTo.outSideSalesPerson.displayName }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </template>
    <div
      v-else-if="!billToLoaded && loading"
      class="spinner show-spinner">
    </div>
    <template v-if="shipToLoaded && shipToOptions.length > 0">
      <div
        v-if="isInternal() || (!isInternal() && shipToOptions.length > 1)"
        class="row">
        <div
          class="col-md-8"
          style="font-size: 12px;">
          <label
            style="font-size: 12px;"
            class="form-control-label required">
            Ship To Account:
          </label>
          <v-select
            v-if="!loading"
            v-model="userFormInput.shipToAccount"
            class="ship-to-select"
            style="font-size: 15px; font-family: PT Sans"
            :disabled="isStepDisabled"
            :items="shipToOptions"
            item-value="key"
            item-text="value"
            placeholder="Select Ship To Account"
            outlined></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <label
            v-if="!isInternal()"
            style="font-size: 12px;"
            class="form-control-label">
            Ship To Account:
          </label>
          <v-card
            class="mx-auto"
            max-width="700"
            outlined>
            <v-card-text>
              <v-container class="py-0">
                <v-row>
                  <div class="font-weight-bold">
                    {{ shipTo.customerName }} ({{ shipTo.customerId }})
                  </div>
                </v-row>
                <v-row>
                  <div>{{ shipToAddress }}</div>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div
        v-if="shipToLoaded"
        class="row">
        <div class="col-md-10">
          <v-card
            class="ma-0 pa-0"
            max-width="1000"
            outlined>
            <v-card-text>
              <v-container class="pa-0">
                <v-row>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Branch:
                    </div>
                    <div>{{ shipTo.branchName }} - {{ shipTo.branchNumber }}</div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Branch Manager:
                    </div>
                    <a
                      v-if="shipTo.branchManagerEmail"
                      :href="`mailto:${shipTo.branchManagerEmail}`">
                      {{ shipTo.branchManagerErpUserId }} - {{ shipTo.branchManagerName }}
                    </a>
                    <div v-else>
                      {{ shipTo.branchManagerErpUserId }} - {{ shipTo.branchManagerName }}
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Credit Manager:
                    </div>
                    <a
                      v-if="shipTo.creditManagerEmail"
                      :href="`mailto:${shipTo.creditManagerEmail}`">
                      {{ shipTo.creditRepERPUserId }} - {{ shipTo.creditRep }}
                    </a>
                    <div v-else>
                      {{ shipTo.creditRepERPUserId }} - {{ shipTo.creditRep }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Inside Salesperson:
                    </div> <a
                      v-if="shipTo.inSideSalesPerson && shipTo.inSideSalesPerson.emailAddress"
                      :href="`mailto:${shipTo.inSideSalesPerson.emailAddress}`">
                      {{ shipTo.inSideSalesPerson.eclipseUserLogin }} - {{ shipTo.inSideSalesPerson.displayName }}
                    </a>
                    <div v-else-if="shipTo.inSideSalesPerson !== null">
                      {{ shipTo.inSideSalesPerson.eclipseUserLogin }} - {{ shipTo.inSideSalesPerson.displayName }}
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Outside Salesperson:
                    </div>
                    <a
                      v-if="shipTo.outSideSalesPerson && shipTo.outSideSalesPerson.emailAddress"
                      :href="`mailto:${shipTo.outSideSalesPerson.emailAddress}`">
                      {{ shipTo.outSideSalesPerson.eclipseUserLogin }} - {{ shipTo.outSideSalesPerson.displayName }}
                    </a>
                    <div v-else-if="shipTo.outSideSalesPerson !== null">
                      {{ shipTo.outSideSalesPerson.eclipseUserLogin }} - {{ shipTo.outSideSalesPerson.displayName }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </template>

    <div
      v-if="!loading && showError"
      style="font-size: 14px; margin: .7em 0; color: #b40000;">
      Error(s) encountered processing request.
    </div>
  </fieldset>
</template>

<script>
import { mapState } from 'vuex'
import JobAccountComboboxDialog from './JobAccountComboboxDialog.vue'
import lodash from 'lodash'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import TenantsMixin from 'mixins/tenants.mixin'

import {
  getCustomerAccountInfo
} from '@/api/company/account-info'

export default {
  name: 'JobAccountFormStepAccountInformation',
  components: {
    JobAccountComboboxDialog
  },
  // mixin contains userFormInput
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      bannerId: null,
      billTo: [],

      shipToOptions: [],
      shipToAccounts: [],
      shipTo: {},

      loading: false,
      billToLoaded: false,
      shipToLoaded: false,
      showError: false

    }
  },
  computed: {
    ...mapState([
      'appConfig'
    ]),
    billToAddress () {
      return `${this.billTo.streetAddress} ${this.billTo.city}, ${this.billTo.state} ${this.billTo.postalCode}`
    },
    shipToAddress () {
      return `${this.shipTo.streetAddress} ${this.shipTo.city}, ${this.shipTo.state} ${this.shipTo.postalCode}`
    }
  },
  watch: {
    'userFormInput.billToAccount': {
      async handler (newSearch, oldSearch) {
        if (!this.isInternal() && newSearch) {
          await this.getAccountNumberInfo(this.userFormInput.billTo.accountNumber)
          return
        }

        this.showError = false

        if (newSearch == null || newSearch.value == null) {
          this.shipTo = {}
          this.billToLoaded = false
          this.shipToLoaded = false
          this.shipToOptions.length = 0
          this.userFormInput.shipToAccount = ''
          this.userFormInput.billTo.accountNumber = ''
          this.loading = false

          return
        }

        const searchValue = newSearch.value.toString()

        this.loading = true
        this.shipToLoaded = false
        this.userFormInput.shipToAccount = ''
        this.shipToOptions.length = 0

        if (searchValue.length > 0) {
          const results = await this.searchForCustomerAccountInfoFromSales(searchValue)

          // update validation state
          if (results != null && results.length > 0) {
            this.vuelidate.billToAccount.$touch()
          }
        }

        this.loading = false
      }
    },
    'userFormInput.shipToAccount': {
      async handler (newVal, oldVal) {
        this.showError = false

        if (newVal === '' || newVal === null) {
          this.shipTo = null
          this.shipToOptions.length = 0
          this.shipToLoaded = false
          this.loading = false
          this.userFormInput.shipToAccount = ''
          return null
        }

        const filteredAccount = this.shipToAccounts.filter(x => x.customerId === newVal)

        this.shipTo = filteredAccount.reduce((a, b) => ({ ...a, ...b }), Object.create(null))

        if (this.shipTo != null) {
          this.formData.shipTo = this.shipTo
          this.formData.shipToAccount = this.shipTo.customerId
          this.vuelidate.shipToAccount.$touch()
        }
      }
    }
  },
  async mounted () {
    this.loading = false
    this.bannerId = this.getBannerIdFromName()
    const accountNumber = this.userFormInput.billToAccount

    // This handles loading data on assignment
    if (accountNumber !== null && accountNumber !== '') {
      await this.getAccountNumberInfo(accountNumber.value)
    }
  },
  methods: {
    doesBillToHaveNoShipToAccounts () {
      return this.billToLoaded && this.shipToLoaded && this.shipToOptions.length === 0
    },
    mapCustomerAccountSearchResults (items) {
      const mappedItems = items
        .filter(x => x.companyId === this.bannerId)
        .map((item) => ({
          value: item.customerNumber,
          text: `${item.customerName} (${item.customerNumber})   |   Branch: ${item.branchNumber} - ${item.branchName}`
        }
        ))

      return mappedItems
    },
    // converts input to stringthat is passed from @input watcher that is emitted in JobAccountComboboxDialog
    async handleAccountSelected (selectedItem) {
      if (!selectedItem || !selectedItem.value) {
        return
      }
      const accountNumber = selectedItem.value.toString()
      await this.getAccountNumberInfo(accountNumber)
    },

    async searchForCustomerAccountInfoFromSales (selectedItem) {
      const urlTenant = this.$route.params.tenant

      // get queryParams ready using searchActionObj
      const queryParams = new URLSearchParams()

      // When searching for Bill-To account numbers we should should NOT return results for ship-to's only (unless they are also a bill to)
      const searchActionObj = {
        banner: urlTenant,
        source: 'all',
        term: selectedItem,
        filters: [
          'billto' // filtering on billto also returns accounts that are billto/shipto combos
        ],
        orderBy: 'best match',
        page: 1
      }
      const {
        ...params
      } = searchActionObj

      for (const key in params) {
        if (params[key]) queryParams.append(key, params[key])
      }
      const basePlattForceRootUrl = this.appConfig.plattforceUrl
      return this.$http
        .get(`${basePlattForceRootUrl}/api/v1/customers/search`, { params: queryParams })
        .then(res => {
          // Filter out Cash and AutoDelete Accounts
          const filteredResults = res.data.value.filter(acct => acct.cashOrCredit !== 'C' && !acct.autoDeleteFlag)
          return filteredResults
        })
        .catch(err => {
          console.log(err)
          return null
        })
        .finally(() => {
        })
    },

    async getAccountNumberInfo (accountId) {
      this.loading = true
      const tenant = this.$route.params.tenant === 'platt' ? 'platt' : 'rexel'
      return await getCustomerAccountInfo(accountId, tenant, this.$route.query.sftoken)
        .then(res => {
          const tempShipToOptions = []
          const results = res

          if (results != null) {
            this.billTo = lodash.pick(results, [
              'accountNumber',
              'branchName',
              'branchId',
              'branchNumber',
              'branchManager',
              'branchManagerEmail',
              'branchManagerErpUserId',
              'billToCustomerNumber',
              'companyName',
              'streetAddress',
              'city',
              'state',
              'postalCode',
              'creditRep',
              'creditRepErpUserId',
              'creditManagerEmail',
              'inSideSalesPerson',
              'outSideSalesPerson',
              'email'
            ])
            this.billToLoaded = true
            this.formData.billTo = this.billTo

            if (res.companyName !== undefined) {
              this.formData.billToAccountName = res.companyName
            }
            if (results.shipTo.length > 0) {
              this.shipToAccounts = [...results.shipTo]
              results.shipTo.forEach(s => {
                if (s == null) return
                tempShipToOptions.push({
                  key: s.customerId,
                  value: `${s.customerName} - ${s.city} (${s.customerId})`
                })
              })
            }

            const billToAccountNumber = this.userFormInput.billToAccount.value ? this.userFormInput.billToAccount.value.toString() : this.userFormInput.billToAccount
            if (results.accountNumber === billToAccountNumber) {
              this.shipToOptions.length = 0
              this.shipToOptions = [...tempShipToOptions]
              this.shipToLoaded = true
              if (this.shipToOptions.length > 0) {
                this.formData.shipToAccount = this.shipToOptions[0].key
              }
            }

            return res
          }
        })
        .catch(err => {
          console.error(err)
          this.showError = true
          return null
        })
        .finally(() => {
          this.loading = false
        })
    },
    isInternal () {
      return this.$route.meta.isInternalForm
    }
  }
}
</script>
<style scoped>
  .ship-to-select{
    height: 50px;
  }
  [v-cloak] {
    display:none;
  }

  .v-list--dense .v-list-item .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list-item--dense .v-list-item__title{
    white-space: pre !important;
  }
  .nav-button {
  background-color: #099c98;
  font-size: 0.70rem;
  font-weight: bold;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
  .nav-button-clear {
  font-size: 0.70rem;
  font-weight: bold;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  border: 0;
}
.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
</style>
