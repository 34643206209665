<template>
<div>
  <v-row
    class="mt-3"
    justify="center">
    <img src="../assets/images/error-image.png"/>
  </v-row>
  <v-row
    class="mb-4"
    justify="center">
    <span class="sorry">
      Sorry...
    </span>
  </v-row>
  <v-row
    class="mb-4"
    justify="center">
    <span class="unavailable">
      This page is currently unavailable.
    </span>
  </v-row>
  <v-row
    class="mt-4"
    justify="center">
    <span class="please-come-back">
      Please come back and try again.
    </span>
  </v-row>
  <v-row
     class="mb-3"
     align="center"
     justify="center">
        Go back to
        <v-btn
          class="please-come-back pb-1"
          plain
          text
          style="text-decoration:underline; color:blue; font-size:16px; padding-left:4px; text-transform:unset !important;"
          large
          :href=bannerLink.url>
          {{ bannerLink.title }}
        </v-btn>
  </v-row>
  <v-row
    class="mt-4"
    justify="center">
    <span class="please-come-back">
      If problem persists please contact Customer Service: {{ bannerLink.phone}}
    </span>
  </v-row>
</div>
</template>

<script type="text/javascript">

export default {
  name: 'PageUnavailable',
  data () {
    return {
      bannerLink: {
        url: null,
        title: null,
        phone: null
      }
    }
  },
  mounted () {
    if (this.$route.params.tenant === 'platt') {
      this.bannerLink.url = 'https://www.platt.com/'
      this.bannerLink.title = 'Platt.com'
      this.bannerLink.phone = '1-800-257-5288'
    } else {
      this.bannerLink.url = 'https://www.rexelusa.com/'
      this.bannerLink.title = 'Rexelusa.com'
      this.bannerLink.phone = '1-888-739-3577'
    }
  }
}
</script>

<style lang="scss" scoped>
  .sorry {
    font-family: Rubik;
    font-weight: 100;
    font-size: 48px;
  }
  .unavailable {
    font-family: Rubik;
    font-weight: medium;
    font-size: 18px;
  }
  .please-come-back {
    font-family: Rubik;
    font-weight: medium;
    font-size: 16px;
  }
</style>
