<template>
  <div>
    <!-- Section: Banking Information -->
    <h6 class="card-header">
      Banking Information
    </h6>
    <div class="card">
      <div class="card-block cols-five">
        <div class="form-group">
          <label>Bank Name:</label>
          <p>{{ banking.bankName }}</p>
        </div>
        <div class="form-group">
          <label>Account Type:</label>
          <p>{{ banking.accountType }}</p>
        </div>
        <div class="form-group">
          <label>Officer Name:</label>
          <p>{{ banking.officerName }}</p>
        </div>
        <div class="form-group">
          <label>Phone Number:</label>
          <p>
            {{ banking.phone }}
            <span v-if="banking.phoneExt">
              Ext. {{ banking.phoneExt }}</span>
          </p>
        </div>
        <div class="form-group">
          <label>Country:</label>
          <p>{{ banking.countryCode }}</p>
        </div>
      </div>
      <div class="card-block cols-five">
        <div class="form-group">
          <label>Banking Address:</label>
          <p>{{ banking.street }}</p>
        </div>
        <div class="form-group">
          <label>Unit:</label>
          <p>{{ banking.unit }}</p>
        </div>
        <div class="form-group">
          <label>City:</label>
          <p>{{ banking.city }}</p>
        </div>
        <div class="form-group">
          <label>State:</label>
          <p>{{ banking.state }}</p>
        </div>
        <div class="form-group">
          <label>Postal Code:</label>
          <p>{{ banking.zip }}</p>
        </div>
      </div>
    </div>
    <!-- card -->
    <!-- Section: Trade References -->
    <template v-for="(reference, index) in tradeReferences">
      <h6
        :key="reference.referenceId"
        class="card-header">
        Trade Reference <span v-if="tradeReferences.length > 1">
          #{{ index + 1 }}
        </span>
      </h6>
      <div
        :key="index"
        class="card">
        <div class="card-block cols-five">
          <div class="form-group">
            <label>Name of Business:</label>
            <p>{{ reference.name }}</p>
          </div>
          <div class="form-group">
            <label>Account Number:</label>
            <p>{{ reference.referenceAccountNum }}</p>
          </div>
          <div class="form-group">
            <label>Contact Fax Number:</label>
            <p>{{ reference.fax }}</p>
          </div>
          <div class="form-group">
            <label>Phone Number:</label>
            <p>{{ reference.phone }}</p>
          </div>
          <div class="form-group">
            <label>Contact Email Address:</label>
            <p>{{ reference.email }}</p>
          </div>
          <div class="form-group">
            <label>Country:</label>
            <p>{{ reference.countryCode }}</p>
          </div>
          <div class="form-group">
            <label>Business Address:</label>
            <p>{{ reference.street }}</p>
          </div>
          <div class="form-group">
            <label>Unit:</label>
            <p>{{ reference.unit }}</p>
          </div>
          <div class="form-group">
            <label>City:</label>
            <p>{{ reference.city }}</p>
          </div>
          <div class="form-group">
            <label>State:</label>
            <p>{{ reference.state }}</p>
          </div>
          <div class="form-group">
            <label>Postal Code:</label>
            <p>{{ reference.zip }}</p>
          </div>
        </div>
      </div>
    </template>

    <!-- Section: Bonding Company

  -->
    <template v-if="bondingCompany.bondingCompanyId !== 0 && bondingCompany.bondingCompanyId !== null">
      <h6 class="card-header">
        Bonding Company
      </h6>
      <div class="card">
        <div class="card-block cols-four">
          <div class="form-group">
            <label>Name:</label>
            <p>{{ bondingCompany.name }}</p>
          </div>
          <div class="form-group">
            <label>Country:</label>
            <p>{{ bondingCompany.countryCode }}</p>
          </div>
          <div class="form-group">
            <label>Business Address:</label>
            <p>{{ bondingCompany.street }}</p>
          </div>
          <div class="form-group">
            <label>Unit:</label>
            <p>{{ bondingCompany.unit }}</p>
          </div>
          <div class="form-group">
            <label>City:</label>
            <p>{{ bondingCompany.city }}</p>
          </div>
          <div class="form-group">
            <label>State:</label>
            <p>{{ bondingCompany.state }}</p>
          </div>
          <div class="form-group">
            <label>Postal Code:</label>
            <p>{{ bondingCompany.zip }}</p>
          </div>
        </div>
      </div>
      <h6 class="card-header">
        Bonding Agent
      </h6>
      <div class="card">
        <div class="card-block cols-four">
          <div class="form-group">
            <label>Name:</label>
            <p>{{ bondingCompany.agentName }}</p>
          </div>
          <template v-if="bondingCompany.agentIsDifferent">
            <div class="form-group">
              <label>Country:</label>
              <p>{{ bondingCompany.agentCountryCode }}</p>
            </div>
            <div class="form-group">
              <label>Business Address:</label>
              <p>{{ bondingCompany.agentStreet }}</p>
            </div>
            <div class="form-group">
              <label>Unit:</label>
              <p>{{ bondingCompany.agentUnit }}</p>
            </div>
            <div class="form-group">
              <label>City:</label>
              <p>{{ bondingCompany.agentCity }}</p>
            </div>
            <div class="form-group">
              <label>State:</label>
              <p>{{ bondingCompany.agentState }}</p>
            </div>
            <div class="form-group">
              <label>Postal Code:</label>
              <p>{{ bondingCompany.agentZip }}</p>
            </div>
          </template>
          <div
            v-else
            class="form-group">
            <label>Agent Address:</label>
            <p>Same as Bonding Company Location</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'ThirdPartyInformation',
  props: {
    creditApp: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      banking: {},
      bondingCompany: {},
      company: {},
      tradeReferences: []
    }
  },
  created () {
    this.company = this.$nullToDefault(this.creditApp.company)
    this.banking = this.$nullToDefault(this.creditApp.banking)
    this.tradeReferences = this.$nullToDefault(this.creditApp.tradeReferences)
    this.bondingCompany = this.$nullToDefault(this.creditApp.bondingCompany)
  }
}
</script>
<style lang="scss">

</style>
