<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="deny-modal"
    @close="modalState = false"
    @closeModal="modalState = false">
    <template slot="header">
      Deny Application
    </template>
    <div slot="body">
      <!-- deny -->
      <div
        id="deny-credit-line"
        ref="deny-credit-line"
        class="tab">
        <label>Reason:</label>
        <div class="input-group">
          <select
            v-model="deny.creditPendingObj.reason"
            class="form-control">
            <option
              v-for="r in filterReasons"
              :key="`${r.key}-reasons`"
              :value="r.key">
              {{ r.value }}
            </option>
          </select>
        </div>
        <div
          v-if="isEclipseBanner && app.businessInfo.hasAccount"
          class="clearfix mt-4">
          <div class="pull-left mr-3">
            <input
              v-model="deny.creditPendingObj.uploadAttachments"
              type="checkbox"
              :checked="deny.creditPendingObj.uploadAttachments" />
          </div>
          Send Uploaded Attachments to Eclipse
        </div>
        <div
          v-if="true == false"
          class="checkbox-group">
          <div class="input-group">
            <div class="form-control">
              Send Denial Letter:
            </div>
            <span class="input-group-addon">
              <input
                v-model="deny.creditPendingObj.letter"
                type="checkbox" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- deny -->
    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="modalState = false">
        Cancel
      </a>
      <button
        v-if="deny.creditPendingObj.reason"
        type="button"
        class="btn btn-rounded btn-primary"
        @click="denyCreditReview">
        Continue
      </button>
      <button
        v-else
        type="button"
        class="btn btn-rounded btn-primary btn-disabled"
        disabled="true">
        Continue
      </button>
    </div>
  </GenericModal>
</template>

<script>
import axios from 'ca-http-service'
import GenericModal from '../../../../components/GenericModal.vue'

export default {
  name: 'DenialModal',
  components: {
    GenericModal
  },
  props: {
    app: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      modalState: false,
      filterReasons: [],
      deny: {
        creditPendingObj: {
          reason: '',
          letter: true,
          reasonText: null,
          accountId: null,
          uploadAttachments: true
        }
      }
    }
  },
  computed: {
    isEclipseBanner () {
      return this.app.bannerId === 'Rexel' || this.app.bannerId === 'Gexpro'
    }
  },
  watch: {
    modalState: function (value) {
      if (value) {
        this.$nextTick(() => {
          this.deny.creditPendingObj.reason = this.app.reasonTypeID || ''
          this.deny.creditPendingObj.letter = true
          this.deny.creditPendingObj.accountId = this.app.businessInfo.accountNumber
        })
      }
    }
  },
  beforeMount () {
    this.getReasonTypes()
  },
  methods: {
    denyCreditReview: function () {
      if (!isNaN(this.deny.creditPendingObj.reason)) {
        this.deny.creditPendingObj.reasonText = this.$store.getters.filterReasons.find(reason => reason.key === this.deny.creditPendingObj.reason).value
      }
      this.$emit('denyCreditReview_', this.app.creditAppId, this.deny.creditPendingObj)
    },
    getReasonTypes () {
      if (this.$store.getters.filterReasons.length > 0) {
        this.filterReasons = this.$store.getters.filterReasons
      } else {
        axios.get('/generic/getReasonTypesList')
          .then((res) => {
            this.filterReasons = res.data
            this.$store.dispatch('setFilterReasons', res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      }
    }
  }
}
</script>

<style lang="scss">
#deny-modal {
  .hide {
    display: none;
  }
  .checkbox-group {
    max-width: 225px;
  }
}
</style>
