<template>
  <div>
    <div
      v-if="validationSuccessful"
      class="mb-2">
      {{ title }}
    </div>
    <v-card outlined>
      <v-row no-gutters>
        <v-col sm="12">
          <v-card
            flat
            class="address-card">
            <v-card-text class="address-card__text">
              <div>
                {{ streetAddressLine }}
              </div>
              <div>
                {{ cityStateLine }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'FormAddressValidationModalAddress',
  props: {
    addressObj: {
      type: Object,
      required: true
    },
    isValidated: {
      type: Boolean,
      required: false,
      default: () => false
    },
    validationSuccessful: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  computed: {
    title () {
      return this.isValidated ? 'Validated address' : 'Address entered'
    },
    streetAddressLine () {
      const streetAddress1 = this.addressObj.streetAddress1
      const streetAddress2 = this.addressObj.streetAddress2

      if (streetAddress2 === null) {
        return streetAddress1
      }

      return `${streetAddress1} ${streetAddress2}`
    },
    cityStateLine () {
      const city = this.addressObj.city
      const state = this.addressObj.state
      const zip = typeof this.addressObj.zipCode !== 'undefined'
        ? this.addressObj.zipCode
        : this.addressObj.postalCode

      return `${city}, ${state} ${zip}`
    }
  }
}
</script>

<style lang="scss" scoped>
.address-card {
  height: 100%;
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
  }
}
</style>
