<template>
  <div
    class="date-picker"
    style="display: grid; margin-bottom: 0.6em;"
    :class="{ 'has-danger': $isErrorState() }">
    <label
      class="form-control-label"
      :class="$isRequired() && !disabled ? 'required' : ''"
      style="font-size: 12px;">{{ inputLabel }}</label>
    <Datepicker
      ref="datepicker"
      input-class="full-width"
      :format="customFormatter"
      :disabled-dates="disabledDates"
      :disabled="disabled"
      :use-utc="true"
      :value="value"
      :class="{ 'has-danger': $isErrorState() }"
      @input="updateValue"></Datepicker>
    <FormFieldErrorMessages :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker' // https://www.npmjs.com/package/vuejs-datepicker
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from './FormInput.mixin'

export default {
  name: 'FormInputDatePicker',
  components: {
    Datepicker,
    FormFieldErrorMessages
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    disabledDates: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  mounted () {
    const datepicker = this.$refs.datepicker.$el
    const input = datepicker.getElementsByTagName('input')[0]
    if (typeof input === 'undefined') {
      return
    }
    input.style.width = '100%'
    input.style.outline = 'none'
  },
  methods: {
    customFormatter (date) {
      return moment.utc(date).format('YYYY-MM-DD')
    },
    updateValue: function (value) {
      this.$emit('input', moment.utc(value).format('YYYY-MM-DD').toString())
      this.$touch()
    }
  }
}
</script>

<style lang="scss" scoped>
/* this ugly rule is to set the label to the same alignment as the select input component */
.form-input-datepicker .form-control-label {
  left: -0.3em;
}

.vdp-datepicker {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: .5rem;
}
</style>
