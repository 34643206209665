<template>
  <ClassicModal
    v-if="modalState"
    v-cloak
    id="report-modal"
    :tabs="[{title: 'Credit Results', tabId: 'credit-results', display: true}]"
    @close="modalState = false"
    @closeModal="modalState = false">
    <div slot="body">
      <div class="alert alert-info">
        {{ creditReportResponse.message }}:
      </div>
      <div :class="{ 'modal-scrollbox': creditReportResponse.data.listOfSimilars.length > 3 }">
        <ul class="list-group">
          <li
            v-for="(c, index) in creditReportResponse.data.listOfSimilars"
            :key="index"
            class="list-group-item">
            <div>
              <label class="custom-control custom-radio">
                <input
                  v-model="selectedCompany"
                  name="match"
                  :value="c.ExperianFileNumber"
                  type="radio"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
&nbsp;
                </span>
              </label>
            </div>
            <div>
              <div>
                <strong>{{ c.BusinessName }}</strong>
              </div>
              <label>{{ c.StreetAddress }}, {{ c.City }}, {{ c.State }}, {{ c.Zip }}</label>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div slot="footer">
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        :class="{'disabled': selectedCompany == null}"
        @click="selectCompanyFromList()">
        Select Company
      </button>
      <a
        class="btn btn-rounded btn-danger"
        @click="modalState = false">
        Cancel
      </a>
    </div>
  </ClassicModal>
</template>

<script>
import ClassicModal from '../../../../components/ClassicModal.vue'

export default {
  name: 'ReportModal',
  components: {
    ClassicModal
  },
  props: {
    creditReportResponse: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      modalState: false,
      selectedCompany: null
    }
  },
  methods: {
    selectCompanyFromList: function () {
      this.$emit('selectCompanyFromList_', this.selectedCompany)
      this.modalState = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-scrollbox {
    max-height: 250px;
    overflow-y: scroll;
  }
</style>
