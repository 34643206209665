var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('FormInputRadioOptions',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.addEmail,"input-label":_vm.formLabels.addEmail,"default-value":"0","options":[
          {
            key: 'Yes',
            value: '1',
          },
          {
            key: 'No',
            value: '0'
          }
        ]},model:{value:(_vm.userFormInput.addEmail),callback:function ($$v) {_vm.$set(_vm.userFormInput, "addEmail", $$v)},expression:"userFormInput.addEmail"}})],1)]),(_vm.userFormInput.addEmail === '1')?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('FormInputTextInput',{ref:"addEmail",attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.email,"input-label":_vm.formLabels.email,"input-type":"text"},model:{value:(_vm.userFormInput.email),callback:function ($$v) {_vm.$set(_vm.userFormInput, "email", $$v)},expression:"userFormInput.email"}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }