<template>
  <fieldset>
    <div class="row">
      <div class="col-md-12 pt-0">
        <FormInputDropdown
          v-model="userFormInput[fieldNames.state]"
          :vuelidate="vuelidate[fieldNames.state]"
          :input-label="formLabels[fieldNames.state]"
          :options="states"
          :disabled="disabled"></FormInputDropdown>
      </div>
    </div>
  </fieldset>
</template>

<script>
import axios from 'ca-http-service'
import FormInputDropdown from '@/components/form/FormInputDropdown.vue'

export default {
  name: 'FormInputDropdownStates',
  components: {
    FormInputDropdown
  },
  props: {
    vuelidate: {
      type: Object,
      required: true
    },
    // NOTE: make sure to pass the formData reference, instead of the copy of the userFormInput object to this prop
    formData: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    },
    fieldNames: {
      type: Object,
      required: false,
      default: () => ({
        state: 'state'
      })
    },
    col: {
      type: String,
      required: false,
      default: 'col-md-4'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      userFormInput: this.getFormClass(),
      states: []
    }
  },
  async mounted () {
    this.userFormInput = this.formData
    this.states = await this.getStates()
  },
  methods: {
    getFormClass () {
      return {
        [this.fieldNames.state]: ''
      }
    },
    async getStates (country = 'US') {
      return axios.get('/generic/getStateList', {
        params: {
          countryCode: country
        }
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return []
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
</script>
