import axios from 'ca-http-service'

export const submitJobAccountForm = async (banner, applicationKey, sftoken, requestObject) => {
  const params = new URLSearchParams()
  if (sftoken) {
    params.append('sftoken', sftoken)
  }
  return await axios
    .post(
      `/api/JobAccount/${banner}/${applicationKey}/submit`,
      requestObject,
      {
        headers: {
          'Content-Type': undefined
        },
        params
      }
    )
}

export const submitInstantCreditForm = async (banner, applicationKey, requestObject) => {
  const params = new URLSearchParams()

  return await axios.post(
    `/api/InstantCredit/${banner}/${applicationKey}/submit`,
    requestObject, {
      headers: {
        'Content-Type': undefined
      },
      params
    }
  )
}
