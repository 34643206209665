<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="pending-modal"
    @close="modalState = false"
    @closeModal="modalState = false">
    <template slot="header">
      Pending Application
    </template>
    <div slot="body">
      <div
        id="pending-credit-line"
        ref="pending-credit-line"
        class="tab">
        <label>Reason:</label>
        <select
          v-model="pending.creditPendingObj.reason"
          class="form-control">
          <option value="">
            - Select Option -
          </option>
          <option
            v-for="r in $store.getters.filterReasons"
            :key="`${r.key}`"
            :value="r.key">
            {{ r.value }}
          </option>
        </select>
      </div>
    </div>

    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="modalState = false">
        Cancel
      </a>
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        @click="pendingCreditReview(app.creditAppId, false)">
        Continue
      </button>
    </div>
  </GenericModal>
</template>

<script>
import axios from 'ca-http-service'
import GenericModal from '../../../../components/GenericModal.vue'

export default {
  name: 'PendingModal',
  components: {
    GenericModal
  },
  props: {
    app: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      modalState: false,
      filterReasons: [],
      pending: {
        creditPendingObj: {
          reason: ''
        }
      }
    }
  },
  watch: {
    modalState (value) {
      if (value) {
        this.$nextTick(() => {
          this.pending.creditPendingObj.reason = this.app.reasonTypeID || ''
        })
      }
    }
  },
  beforeMount () {
    this.getReasonTypes()
  },
  methods: {
    getReasonTypes () {
      if (this.$store.getters.filterReasons.length > 0) {
        this.filterReasons = this.$store.getters.filterReasons
      } else {
        axios.get('/generic/getReasonTypesList')
          .then((res) => {
            this.filterReasons = res.data
            this.$store.dispatch('setFilterReasons', res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    pendingCreditReview (creditAppId, isApproved) {
      this.$emit('pendingCreditReview_', this.app.creditAppId, this.pending.creditPendingObj)
    }
  }
}
</script>
