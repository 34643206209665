<template>
  <div
    class="form-input-text"
    style="
    display: grid;
    margin-bottom: .6em;
  "
    :class="{'has-danger': $isErrorState()}">
    <span>
      <label
        v-if="inputLabel.length > 0"
        class="form-control-label"
        :class="$isRequired() && !disabled ? 'required' : ''"
        style="font-size: 12px;">
        {{ inputLabel }}
      </label>
      <div
        v-if="toolTip"
        data-toggle="tooltip"
        class="fa fa-info-circle"
        :title="toolTip">
      </div>
    </span>
    <v-text-field
      tabindex="-1"
      :disabled="disabled"
      :value="formattedValue"
      :class="showFormattedValue ? '' : 'hide'"
      :placeholder="placeholder"
      prefix="$"
      outlined
      dense
      @mousedown="showFormattedValue = false"
      @focus="showFormattedValue = false"></v-text-field>
    <div class="inputWrapper">
      <v-text-field
        ref="rawValue"
        name="ignore"
        type="number"
        autocomplete="autocomplete_off_hack"
        v-model="inputValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="`${$isErrorState() ? 'has-danger' : ''} ${showFormattedValue ? 'hide' : ''}`"
        prefix="$"
        outlined
        dense
        @blur="showFormattedValue = true"
        @focus="showFormattedValue = false"
        @keydown="onKeyInput($event)"
        @keyup="onKeyInput($event)"
        @input="updateValue($event)"></v-text-field>
    </div>

    <FormFieldErrorMessages :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from './FormInput.mixin'

export default {
  name: 'FormInputNumberInput',
  components: {
    FormFieldErrorMessages
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: '0'
    },
    toolTip: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      inputValue: this.value,
      showFormattedValue: true,
      formattedValue: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        if (isNaN(newVal)) {
          this.formattedValue = '0'
        }

        // handle values on mount (ex: test values/saved form values)
        if ((this.formattedValue == null || this.formattedValue === '0') && parseInt(newVal, 10) > 0) {
          this.updateValue(newVal)
        }

        this.inputValue = newVal
      }
    },
    showFormattedValue: {
      handler (newVal) {
        if (newVal === false) {
          this.$refs.rawValue.focus()
        }
      }
    },
    inputValue: {
      handler (newVal) {
        const cleanedVal = this.currentToRawValue(newVal)
        if (cleanedVal !== newVal) {
          this.inputValue = cleanedVal
        }
      }
    }
  },
  methods: {
    onKeyInput (evt) {
      const restrictedKeys = [
        190, // .
        69, // e
        107, // +
        109, // -
        187, // +
        189 // -
      ]
      const keyCode = evt.keyCode
      if (restrictedKeys.includes(keyCode)) {
        evt.preventDefault()
        return false
      }
      this.updateValue(evt.target.value)
    },
    updateValue (value) {
      const emitValue = this.currentToRawValue(value)
      this.$emit('input', emitValue)
      this.formattedValue = value === '' ? '0' : this.currencyFilteredValue(value)
    },
    currentToRawValue (currencyValue) {
      return currencyValue.toString().split('.')[0].replace(/\D/g, '').trim()
    },
    currencyFilteredValue (value) {
      return this.$options.filters.currency(parseInt(value, 10)).split('.')[0].substring(1)
    }
  }
}
</script>

<style scoped>
.hide {
  /* hide offscreen so focus still works */
  left: -999em;
  position: absolute;
}

.form-input-text .form-control-label {
  left: 0;
}

.fa-info-circle {
  color: #5bc0de;
  cursor: pointer;
  font-size: 16px;
}
</style>
