<template>
  <div
    id="email-template-component"
    class="component-container">
    <div
      v-cloak
      id="edit-email-options">
      <section class="email-template-header">
        <div class="row justify-content-between">
          <div class="btns">
            <button
              type="button"
              class="btn btn-rounded"
              @click="sendTestEmail()">
              <i class="fa fa-envelope-o"></i>
              <span>Send Sample Email</span>
            </button>
          </div>
        </div>
        <h6 class="sub-heading">
          Signature(s)
        </h6>
        <p>
          <strong>
            You can have up to two (2) signatures attached to your company welcome email that will be sent to approve credit customers
          </strong>
        </p>
      </section>

      <!-- Signatures -->
      <div class="signatures clearfix">
        <div
          v-if="loading"
          class="spinner show-spinner"></div>
        <div v-if="signatures.length > 0">
          <div
            v-for="(signature, index) in signatures"
            :key="index"
            class="signature">
            <div
              v-if="signatures.length > 1"
              class="delete-signature clearfix"
              @click="deleteModalState = true; signatureId = index">
              <i class="fa fa-close"></i>
            </div>
            <ul class="signature-info">
              <li>
                <span>Name:</span>
                {{ signature.name }}
              </li>
              <li>
                <span>Job Title:</span>
                {{ signature.title }}
              </li>
              <li>
                <span>Email:</span>
                {{ signature.email }}
              </li>
              <li>
                <span>Phone:</span>
                {{ signature.phone }}
              </li>
              <li v-if="signature.signatureUrl">
                <img
                  class="w-100"
                  :src="signature.signatureUrl"
                  alt="" />
              </li>
              <li v-else>
                <i>No signature image uploaded</i>
              </li>
            </ul>
            <button
              type="button"
              class="btn btn-rounded btn-edit-signature"
              @click="toggleSignatureModal(true, true, index)">
              <i class="fa fa-pencil"></i>
              <span>Edit</span>
            </button>
          </div>
          <!-- <button v-if="!loading && signatures.length < 2"
                      type="button"
                      v-on:click="toggleSignatureModal(true)"
                      class="btn btn-rounded btn-primary">
                  <span>Add Signature</span>
              </button> -->
        </div>
        <div v-else>
          You have not setup any signatures, please add at least one signature.
        </div>
      </div>

      <!-- Add Signature Modal -->
      <modal
        v-if="signatureModalState === true"
        @close="toggleSignatureModal(false)">
        <template slot="header">
          {{ modalTitle }}
        </template>
        <template slot="subheader">
          Signature
        </template>
        <template slot="body">
          <div class="form-add-signature clearfix">
            <div
              class="form-group group col-1-2"
              :class="{'has-danger': $v.newSignature.name.$error}">
              <label
                class="required"
                for="signatureName">
                Name
                <small>
                  <em>(first &amp; last)</em>
                </small>
              </label>
              <input
                id="signatureName"
                v-model="newSignature.name"
                v-focus
                type="text"
                :class="{'form-control-danger': $v.newSignature.name.$error}" />
            </div>
            <div
              class="group col-1-2"
              :class="{'has-danger': $v.newSignature.title.$error}">
              <label
                class="required"
                for="signatureTitle">Job Title</label>
              <input
                id="signatureTitle"
                v-model="newSignature.title"
                type="text"
                :class="{'form-control-danger': $v.newSignature.title.$error}" />
            </div>
            <div
              class="group col-1-2"
              :class="{'has-danger': $v.newSignature.email.$error}">
              <label
                class="required"
                for="signatureEmail">Email Address</label>
              <input
                id="signatureEmail"
                v-model="newSignature.email"
                type="text"
                :class="{'form-control-danger': $v.newSignature.email.$error}" />
            </div>
            <div
              class="group col-1-2"
              :class="{'has-danger': $v.newSignature.phone.$error}">
              <label for="signaturePhone">
                Phone Number
              </label>
              <input
                id="signaturePhone"
                v-model="newSignature.phone"
                v-mask="'(###) ###-####'"
                type="text"
                :class="{'form-control-danger': $v.newSignature.phone.$error}" />
            </div>
            <div class="group">
              <div
                v-if="showUploadField && signaturePreview === null"
                class="clearfix">
                <label
                  class="required"
                  for="signatureImage">Upload Signature</label>
                <input
                  v-if="showUploadField"
                  id="signatureImage"
                  type="file"
                  name="uploadSignature"
                  accept="image/*"
                  @change="previewSignatureImage($event.target)" />
              </div>
              <div v-else>
                <label>Current Signature</label>
                <div class="current-signature-image">
                  <img
                    :src="signaturePreview"
                    alt="" />
                  <!-- <a
                    href="#"
                    v-on:click.prevent="removePreview()"
                  >
                    <i class="fa fa-close"></i>
                  </a> -->
                </div>
              </div>
              <div
                v-if="$v.newSignature.$error || errorMessages.length"
                class="error-messages alert alert-danger"
                role="alert">
                <ul>
                  <li v-if="!$v.newSignature.name.required">
                    Name is required.
                  </li>
                  <li v-if="!$v.newSignature.title.required">
                    Title is required.
                  </li>
                  <li v-if="!$v.newSignature.email.email">
                    Invalid email address.
                  </li>
                  <li v-if="!$v.newSignature.email.required">
                    Email is required.
                  </li>
                  <li v-if="$v.newSignature.phone.$error">
                    Phone number is invalid.
                  </li>
                  <li v-if="signaturePreview === null">
                    Signature image is required.
                  </li>
                  <li
                    v-for="message in errorMessages"
                    :key="message">
                    {{ message }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <button
            type="button"
            class="btn btn-rounded btn-link-danger"
            @click="toggleSignatureModal(false)">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-rounded btn-primary"
            :disabled="$v.newSignature.$error"
            @click="updateSignatures()">
            Save Signature
          </button>
        </template>
      </modal>
      <!-- <modal
        v-if="deleteModalState"
        :show-header="false"
        :is-error="true"
        v-on:close="deleteModalState = false"
      >
        <template slot="header"></template>
        <template slot="body">
          Permanently Delete <strong>Signature {{ signatureId + 1 }}</strong>?
        </template>
        <template slot="footer">
          <button
            type="button"
            class="btn btn-rounded btn-default"
            v-on:click="deleteModalState = false"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-rounded btn-danger"
            v-on:click="removeSignature(signatureId)"
          >
            Delete
          </button>
        </template>
      </modal> -->
      <p v-if="lastUpdateEmail !== ''">
        <strong>
          This email template was last updated by {{ lastUpdateEmail }} on {{ lastUpdateDate }}
        </strong>
      </p>
    </div>
  </div>
</template>
<script>
import axios from 'ca-http-service'
import GenericModal from 'components/GenericModal.vue'
import { mapGetters } from 'vuex'
import { required, email, minLength, requiredIf } from 'vuelidate/lib/validators'
import BannerMixin from 'mixins/banner.mixin'

export default {
  name: 'EditEmailTemplate',
  components: {
    modal: GenericModal
  },
  mixins: [BannerMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const urlTenant = to.params.tenant

      const tenantId =
        urlTenant === 'platt'
          ? 1
          : urlTenant === 'rexel'
            ? 2
            : urlTenant === 'gexpro'
              ? 3
              : urlTenant === 'rexelenergy'
                ? 4
                : null

      vm.getEmailTemplate(tenantId)
    })
  },
  data () {
    return {
      loading: true,
      signatures: [],
      newSignature: {
        name: '',
        title: '',
        email: '',
        phone: null,
        signatureUrl: ''
      },
      signatureId: null,
      showUploadField: true,
      signaturePreview: null,
      signatureImageFile: null,
      modalTitle: 'Add Signature',
      signatureModalState: false,
      deleteModalState: false,
      errorMessages: [],
      tenantId: null,
      lastUpdateEmail: '',
      lastUpdateDate: ''
    }
  },
  computed: {
    ...mapGetters([
      'company',
      'identity'
    ]),
    tenant () {
      return this.company.name.toLowerCase()
    }
  },
  watch: {
    selectedBanner (newTenant) { // comes from banner mixin
      if (newTenant !== this.tenantId) {
        this.getEmailTemplate(newTenant) // If tenant changes get template for specific tenant
      }
    }
  },
  mounted () {
    this.$store.dispatch('setAdminHeaderTitle', 'Email Template')
  },
  methods: {
    resetModal () {
      this.newSignature.name = ''
      this.newSignature.title = ''
      this.newSignature.email = ''
      this.newSignature.phone = null
      this.newSignature.signatureUrl = ''
      this.signatureId = null
      this.errorMessages = []
      this.$v.$reset()
      this.removePreview()
    },
    sendTestEmail () {
      axios
        .get(
          '/admin/sendTestEmail',
          {
            params: {
              tenantId: this.selectedBanner
            }
          })
        .then(res => {
          if (res.status === 200) {
            palert({
              title: 'Mail Sent',
              type: 'success',
              text: 'Check your inbox for the sample email. Please be aware that the email may take a couple of minutes to appear.'
            })
          } else {
            palert({
              title: res.data.statusCode + ' Error',
              type: 'warning',
              text: 'We are unable to send a sample email at this time, please contact your system administrator if the issue persists.'
            })
          }
        })
        .catch(() => {
          palert({
            title: 'Error',
            type: 'error',
            text: 'Something has gone wrong, please refresh the page and try again. Contact your system administrator if the issue persists.'
          })
        })
    },
    toggleSignatureModal (state, isEdit, signatureId) {
      signatureId = signatureId || 0
      if (isEdit && state) {
        this.signatureId = signatureId
        const newItem = Object.assign({}, this.signatures[signatureId])
        this.newSignature = newItem
        this.signaturePreview = this.newSignature.signatureUrl
      }
      if ((state && !isEdit) || !state) {
        this.resetModal()
      }
      this.signatureModalState = state
    },
    removePreview () {
      this.signaturePreview = this.signatureImageFile = this.newSignature.signatureUrl = null
    },
    goBack () {
      this.$router.push({
        name: 'dashboard'
      })
    },
    resetUpload () {
      this.showUploadField = false
      this.$nextTick(() => {
        this.showUploadField = true
      })
    },
    // removeSignature (signature) {
    //   if (!isNaN(signature)) {
    //     this.deleteModalState = false
    //     this.$delete(this.signatures, signature)
    //     this.updateSignatures(true)
    //     this.resetModal()
    //   } else {
    //     console.error('Signature does not exist!')
    //   }
    // },
    previewSignatureImage (evt) {
      const files = evt.files
      const signatureImage = files[0]
      const errorMessages = []
      const self = this
      if (signatureImage) {
        const reader = new FileReader()
        reader.onload = e => {
          const image = new Image()
          image.src = e.target.result
          image.onload = function () {
            const height = this.height
            const width = this.width
            if (height > 200 && width > 500) {
              errorMessages.push('Image must be smaller than 500px wide by 200px high')
              self.resetUpload()
            } else if (height > 200) {
              errorMessages.push('Image height must not exceed 200px')
              self.resetUpload()
            } else if (width > 500) {
              errorMessages.push('Image width must not exceed 500px')
              self.resetUpload()
            } else {
              self.signaturePreview = e.target.result
              self.errorMessages = []
            }
          }
        }
        this.errorMessages = errorMessages
        reader.readAsDataURL(signatureImage)
        this.signatureImageFile = evt
      }
    },
    // addSignatureImage () {
    //   this.$v.newSignature.$reset()
    //   if (this.signatureImageFile !== null) {
    //     let fd = new FormData()
    //     fd.append('file', this.signatureImageFile.files[0])
    //     axios
    //       .post(
    //         '/admin/uploadImage/' + this.selectedBanner + '/' + this.newSignature.email,
    //         fd
    //       )
    //       .then(res => {
    //         this.newSignature.signatureUrl = res.data
    //         this.updateSignatures()
    //       })
    //       .catch(err => {
    //         console.error(err)
    //       })
    //   } else {
    //     this.updateSignatures()
    //   }
    // },
    updateSignatures (remove) {
      if (this.newSignature.phone === '' || this.newSignature.length < 7) {
        this.newSignature.phone = null
      }
      const newSignature = Object.assign({}, this.newSignature)
      if (!remove) {
        if (this.signatureId !== null) {
          this.signatures[this.signatureId] = newSignature
        } else {
          this.signatures.push(newSignature)
        }
      }
      const data = {
        signatories: this.signatures,
        tenantId: this.tenantId,
        sso: this.identity.sso
      }
      axios
        .post(
          '/admin/saveEmailSignatories',
          data
        )
        .then(res => {
          this.removePreview()
          this.getEmailTemplate(this.tenantId)
        })
        .catch(err => {
          console.error(err)
        })

      this.toggleSignatureModal(false)
    },
    // addNewSignature () {
    //   this.$v.newSignature.$touch()
    //   if (!this.$v.newSignature.$error) {
    //     this.addSignatureImage()
    //   }
    // },
    getEmailTemplate (id) {
      // validate permissions

      if (!this.identity.permissions.editEmailTemplate) {
        // the user doen't have the correct permissions so bump them back to the dashboard page
        this.$router.push({ name: 'dashboard', params: { tenant: this.tenant } })
      } else {
        // get data
        this.loading = true
        axios
          .get(
            '/admin/getPreferences',
            {
              params: {
                tenantId: id
              }
            })
          .then(res => {
            this.signatures = res.data.emailSignatories || []
            this.loading = false
            this.tenantId = id
            this.lastUpdateDate = res.data.lastUpdatedDate
            this.lastUpdateEmail = res.data.lastUpdatedByEmail
          })
          .catch(err => {
            this.loading = false
            console.error(err)
          })
      }
    }
  },
  validations () {
    const hasImage = this.signaturePreview === null
    const hasPhone = this.newSignature.phone !== null
    return {
      newSignature: {
        name: {
          required
        },
        title: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          required: requiredIf(hasPhone),
          minLength: minLength(14)
        },
        signatureUrl: hasImage
          ? { required }
          : {}
      }
    }
  }
}
</script>
<style lang="scss">
#email-template-component {
  .btn-restore {
    position: relative;
    top: -10px;
  }
  .error-messages {
    margin-top: 1rem;
    padding: 0.75rem 1rem;
    ul {
      margin-bottom: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  .has-danger {
    input {
      border-color: #a10000 !important;
    }
  }
  .current-signature-image {
    position: relative;
    display: inline-block;
    border: 1px solid #c9c9c9;
    overflow: hidden;
    a {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 11px;
      text-decoration: none;
      transition: color 0.2s ease-out;
      color: #c00;
      padding: 1px 3px;
      background: rgba(255, 255, 255, 0.9);
      &:hover {
        color: #900;
      }
      i {
        font-size: 14px;
      }
      span {
        transition: opacity 0.3s ease-out;
        opacity: 0;
      }
    }
  }
  .btn-link-danger {
    color: #c00;
    background: transparent;
    &:hover {
      background: #c00;
      color: #fff;
    }
  }
  h6.sub-heading {
    padding: 1em 0 0.5em;
    border-bottom: 1px solid #000;
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  p {
    font-size: 0.9em;
  }
  .form {
    &-add-signature {
      .group {
        margin-bottom: 0.75em;
      }
      label {
        display: block;
        font-size: 14px;
        margin-bottom: 3px;
      }
      input[type="text"] {
        display: block;
        width: 100%;
        padding: 7px;
        border: 1px solid #ccc;
      }
      .col-1-2 {
        float: left;
        width: 49%;
        &:nth-of-type(odd) {
          margin-right: 2%;
        }
      }
    }
  }
  .signatures {
    .signature {
      position: relative;
      box-sizing: border-box;
      border: 1px solid #ccc;
      width: 47%;
      padding: 0.5em 1em 1em;
      float: left;
      min-height: 350px;
      &:nth-of-type(odd) {
        margin-right: 6%;
      }
      &:nth-of-type(n + 3) {
        display: none;
      }
      ul.signature-info {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;
        li {
          line-height: 2em;
          word-wrap: break-word;
        }
        span {
          font-weight: bold;
        }
      }
      .btn-edit-signature {
        position: absolute;
        right: 1em;
        bottom: 1em;
      }
    }
  }
  .delete-signature {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    z-index: 10;
    color: #d00;
    transition: color 0.2s ease;
    &:hover {
      color: #800;
    }
  }
  label.required {
    &:after {
      content: "*";
      color: #c00;
      padding-left: 2px;
    }
  }
  .w-100 {
    width: 100%;
  }
}
</style>
