<template>
  <div>
    <div class="mb-5">
      <h5
        class="section-title">
        {{ title }}
      </h5>
    </div>
    <div v-if="!loadingMatches">
      <div
        v-if="businessInfo.dnbMatchesFound === 1"
        class="component-container my-8">
        <v-card>
          <v-row class="ml-2">
            <v-col cols="4">
              <label>Status:</label>
              <p>{{ businessInfo.dnbStatus.toUpperCase() }}</p>
            </v-col>
            <v-col
              cols="2">
              <label>Score:</label>
              <p>{{ businessInfo.dnbCreditScore || '_' }}</p>
            </v-col>
            <v-col cols="3">
              <label>Credit Limit:</label>
              <p>{{ formattedCreditLimit(businessInfo.dnbCreditLimit) }}</p>
            </v-col>
            <v-col
              cols="3">
              <label>DUNS Number:</label>
              <p>{{ businessInfo.dnbDunsNumber || '_' }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-2">
            <v-col cols="4">
              <div class="form-group">
                <label>D&B Listed Address</label>

                <p class="dnbAddressLine">
                  {{ businessInfo.dnbCompanyName }}
                </p>
                <p class="dnbAddressLine">
                  {{ businessInfo.dnbStreet }}
                </p>
                <p class="dnbAddressLine">
                  {{ businessInfo.dnbCity }}, {{ businessInfo.dnbState }} {{ formattedZipPlus4(businessInfo.dnbZipCode) }}
                </p>
                <p class="dnbAddressLine">
                  {{ businessInfo.dnbCountry }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="2"
              align-self="start">
              <label>Data Depth Indicator:</label>
              <p
                :style="['K', 'M', 'J', 'L'].includes(businessInfo.dnbDataDepthIndicator) ? 'color:red;' : ''">
                {{ dataDepthIndicator }}
              </p>
            </v-col>
            <v-col
              cols="3"
              align-self="start">
              <label>Date:</label>
              <p>{{ businessInfo.dnbDecisionDate | shortDate }}</p>
            </v-col>
            <v-col
              cols="3"
              align-self="start">
              <label>SIC Code:</label>
              <p>{{ businessInfo.dnbSicCode || '_' }}</p>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div
        v-else-if="businessInfo.dnbMatchesFound === 0"
        class="component-container my-8">
        <v-card>
          <v-row>
            <v-col class="ml-3 my-3">
              No Match Found

              <button
                v-if="canEdit"
                type="button"
                class="btn btn-sm btn-link"
                @click="editDnbAddressInfo()">
                TRY AGAIN
              </button>

              <!--<v-btn tile dark color="#032D2C" @click="getCustomerMatches()">
                TRY AGAIN
              </v-btn>-->
            </v-col>
            <v-col class="ml-6 my-6">
</v-col>
          </v-row>
        </v-card>
      </div>
      <div
        v-else
        class="component-container my-8">
        <v-card>
          <v-row>
            <v-col class="ml-3 my-3">
              <v-btn
                v-if="status === 'Pending'"
                tile
                dark
                color="#032D2C"
                @click="getCustomerMatches()">
                SELECT MATCH
              </v-btn>
              <v-btn
                v-else
                tile
                :disabled="true"
                @click="getCustomerMatches()">
                SELECT MATCH
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <div v-else>
      <v-card>
        <div class="text-center py-3">
          <v-progress-circular
            :size="32"
            color="primary"
            indeterminate>
          </v-progress-circular>
        </div>
      </v-card>
    </div>
    <v-dialog
      v-model="showCustomerMatches"
      max-width="800">
      <v-card>
        <v-toolbar
          style="background-color: #006330"
          flat
          dark>
          <div class="headline">
            Select D&B Match
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="resetMatch">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="mt-3">
          <v-divider></v-divider>
          <v-row class="mt-3">
            <v-card
              v-for="(match, index) in customerMatches"
              :key="index"
              tile
              outlined
              grow
              class="ml-3"
              width="100%">
              <v-container py-0>
                <v-row>
                  <v-col
                    align-self="center"
                    cols="1"
                    class="ml-2">
                    <v-radio-group v-model="selectedCustomerMatch">
                      <v-radio
                        :value="match">
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    v-if="match.address"
                    align-self="center">
                    <span class="font-weight-bold">
                      {{ match.businessName }}
                    </span><br />
                    {{ match.address.street }}, {{ match.address.city }}, {{ match.address.state }}, {{ match.address.country }}, {{ formattedZipPlus4(match.address.zipCode) }}
                  </v-col>
                  <v-col
                    v-else
                    align-self="center">
                    <span class="font-weight-bold">
                      No Match
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="text-sm-right">
              <v-btn @click="resetMatch">
                Cancel
              </v-btn>
              <v-btn
                color="#006330"
                class="ml-2 white--text"
                :disabled="!selectedCustomerMatch"
                @click="selectMatch()">
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'ca-http-service'

export default {
  name: 'DnbInformation',
  components: {
  },
  mixins: [
  ],
  props: {
    applicationId: {
      type: String,
      required: true
    },
    businessInfo: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    canEdit: {
      required: true,
      type: Boolean
    },
    refetchCustomerMatches: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loadingMatches: false,
      showCustomerMatches: false,
      customerMatches: [],
      customerMatchesMessage: null,
      selectedCustomerMatch: null
    }
  },
  computed: {
    title () {
      if (!this.businessInfo.dnbMatchesFound || this.businessInfo.dnbMatchesFound === 1) {
        return 'D&B Information'
      } else {
        return 'Multiple potential customers were returned from D&B:'
      }
    },
    dataDepthIndicator () {
      if (!this.businessInfo.dnbDataDepthIndicator) {
        return '_'
      }
      switch (this.businessInfo.dnbDataDepthIndicator) {
        case 'K':
          return 'Fraud History Detected by D&B - Investigate further'
        case 'M':
          return 'Severe risk - Material deterioration detected'
        case 'J':
          return 'Active Bankruptcy in court'
        case 'L':
          return 'Customer had to ask for DUNS – review manually'
        default:
          return this.businessInfo.dnbDataDepthIndicator
      }
    }
  },
  async mounted () {
  },
  watch: {
    refetchCustomerMatches (newVal) {
      if (newVal) {
        this.getCustomerMatches()
        this.$emit('update:refetchCustomerMatches', false)
      }
    }
  },
  methods: {
    async getCustomerMatches () {
      this.loadingMatches = true
      this.customerMatchesMessage = null
      axios.get(`/api/creditreview/getCustomerMatches/${this.applicationId}`, {
        params: {
          applicationId: this.applicationId
        }
      }).then((res) => {
        console.log(`res.data=${JSON.stringify(res.data)}`)
        if (!res.data.isSuccess) {
          palert({
            type: 'error',
            title: 'Error',
            text: res.data.message
          })
          return
        }
        if (res.data.data.length === 0) {
          // eslint-disable-next-line vue/no-mutating-props
          this.businessInfo.dnbMatchesFound = 0
          return
        }
        this.customerMatches = res.data.data
        if (this.customerMatches) {
          this.customerMatchesMessage = 'Multiple potential customers were returned as possible matches from D&B:'
          // if (this.customerMatches.length > 1) {
          this.customerMatches.push({
            address: null
          })
          // }
          this.showCustomerMatches = true
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.loadingMatches = false
      })
    },
    async selectMatch () {
      this.showCustomerMatches = false
      this.loadingMatches = true
      axios.post(`/api/creditreview/setCustomerMatch/${this.applicationId}`, this.selectedCustomerMatch)
        .then((res) => {
          console.log(`res.data=${JSON.stringify(res.data)}`)
          if (res.data.isSuccess) {
            this.$emit('update-detail')
          } else {
            palert({
              type: 'error',
              title: 'Error',
              text: res.data.message
            })
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.loadingMatches = false
          this.selectedCustomerMatch = null
        })
    },
    resetMatch () {
      this.showCustomerMatches = false
      this.selectedCustomerMatch = null
      this.loadingMatches = false
    },
    formattedZipPlus4 (zipCode) {
      if (!zipCode || zipCode.length !== 9) {
        return zipCode
      }
      return `${zipCode.substring(0, 5)}-${zipCode.substring(5, 9)}`
    },
    formattedCreditLimit (creditLimit) {
      if (isNaN(creditLimit)) {
        return '_'
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      })
      return formatter.format(creditLimit)
    },
    editDnbAddressInfo: function () {
      console.log('edit info')
      this.$emit('editDnbAddressInfo_')
      // console.log("get matches")
      // getCustomerMatches()

      // first: emit editDnbAddressInfo

      // then: getCustomerMatches

      // this.$emit('editDnbAddressInfo_')
    }
  }
}
</script>

<style scoped lang="scss">
  .inactive-data {
    color: #c0c0c0;
  }
  .dnbAddressLine {
    margin-bottom: 0.25rem;
  }

  label,
    p{
      margin: 0;
    }

    label{
      color: #999;
      font-size: .8em;
    }

    p {
      font-size: .875rem;
      font-weight: 700;
      word-break: break-word;
    }
</style>
