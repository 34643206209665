<template>
  <div
    style="padding-bottom: .4em;"
    class="col-md-4">
    <span class="form-label">
      {{ label }}
    </span>
    <br />
    <span class="form-value">
      {{ value || '--' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'FormItemCollectionItemLabelAndValue',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>
.form-label {
  color: #777;
  padding-right: .6em;
}

.form-value{
  color: #333;
  font-size: 13px;
}
</style>
