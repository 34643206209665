<template>
  <v-card class="mx-2 pb-3 pl-2">
    <v-row class="pa-4">
      <v-col
        v-for="(label ,index) in labelList"
        :key="index"
        cols="4"
        sm="auto"
        class="pa-2">
        <p
          class="text-subtitle-2 font-weight-bold ml-7"
          :style="{color: label.color }">
          {{ label.data }}
        </p>
        <v-progress-linear
          class="px-10 pb-3 mt-n3 ml-5"
          value="60"
          :color="label.color"
          background-opacity="0"></v-progress-linear>
        <p class="text-subtitle-2 font-weight-bold text-no-wrap">
          {{ label.name }}
        </p>
      </v-col>
    </v-row>
    <submitted-chart
      id="submittedChart"
      :chart-data="mappedChartData"
      :options="options"
      class="ma-3"
      style="position: relative; height:250px;"
      :style="$vuetify.breakpoint.mdAndUp ? 'width:950px' : 'width: 300px'"></submitted-chart>
  </v-card>
</template>

<script>
import SubmittedChart from './Submitted'

export default {
  name: 'SubmittedCard',
  components: {
    'submitted-chart': SubmittedChart
  },
  props: {
    chartData: {
      required: false,
      type: Array,
      default: () => null
    }
  },
  data () {
    return {
      mappedChartData: null,
      options: null,
      labelList: null
    }
  },
  created () {
    this.mappedChartData = {
      labels: this.chartData.map(i => i.month.substr(0, 3)),
      datasets: [
        {
          label: 'Total Received',
          borderColor: '#0084D8',
          pointBackgroundColor: '#ffffff',
          pointBorderWidth: 2,
          pointRadius: 6,
          fill: false,
          data: this.chartData.map(i => i.total)
        },
        {
          label: 'Total Approved',
          borderColor: '#00A14D',
          pointBackgroundColor: '#ffffff',
          pointBorderWidth: 2,
          pointRadius: 6,
          fill: false,
          data: this.chartData.map(i => i.approved)
        },
        {
          label: 'Total Denied',
          borderColor: '#A10000',
          pointBackgroundColor: '#ffffff',
          pointBorderWidth: 2,
          pointRadius: 6,
          fill: false,
          data: this.chartData.map(i => i.denied)
        }
      ]
    }
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      tooltips: {
        custom: function (tooltip) {
          if (!tooltip) return

          // disable displaying the color box;
          tooltip.displayColors = false
        }
      }
    }
    this.labelList = this.mappedChartData.datasets.map((label, i) => {
      return {
        name: label.label,
        color: label.borderColor,
        data: label.data.reduce(function (a, b) {
          return a + b
        }, 0)
      }
    })
  }

}
</script>

<style lang="scss">
#dashboard-submitted-component {
  margin-bottom: 1rem;

  .card {
    h6 {
      font-weight: bold;
      text-transform: capitalize;
    }

    .card-block {
      display: inline-flex;

      div.dashboard-chart-main {
        height: 20em;
        width: 80%;
      }
    }
  }
}

.v-progress-linear{

  width: 30%  !important;
  }
</style>
