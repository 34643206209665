import CashAccountStep1CompanyInformation from './CashAccountStep1CompanyInformation.vue'
import CashAccountStep2UserAccount from './CashAccountStep2UserAccount.vue'

export const COMPANY_INFORMATION = 'companyInformation'
export const USER_ACCOUNT = 'userAccount'

export const formTitle = 'Cash Account'

export const formDefinitions = {
  [COMPANY_INFORMATION]: {
    order: 1,
    hideCircle: true,
    title: 'Company Information',
    component: CashAccountStep1CompanyInformation,
    dataClass: class CompanyInformation {
      constructor () {
        this.firstName = ''
        this.lastName = ''
        this.emailAddress = ''
        this.companyName = ''
        this.phoneNumber = ''
        this.streetAddress1 = ''
        this.streetAddress2 = ''
        this.city = ''
        this.state = ''
        this.postalCode = ''
        this.branchNumber = '' // Needed for External because [USER_ACCOUNT].branchNumber is not present for External
      }
    },
    testData: {
      firstName: 'Bill',
      lastName: 'Bob',
      emailAddress: 'webdevtesters@platt.com',
      companyName: 'Stoner Electric',
      phoneNumber: '5555555555',
      streetAddress1: '2985 ENTERPRISE RD',
      city: 'Portland',
      state: 'OR',
      postalCode: '97214',
      branchNumber: ''
    },
    labels: {
      firstName: 'First Name:',
      lastName: 'Last Name:',
      emailAddress: 'Email Address:',
      companyName: 'Company Name:',
      phoneNumber: 'Phone Number:',
      streetAddress1: 'Street Address:',
      streetAddress2: 'Street Address 2:',
      city: 'City:',
      state: 'State:',
      postalCode: 'Postal Code:',
      branchNumber: ''
    }
  },
  [USER_ACCOUNT]: {
    order: 2,
    hideCircle: true,
    access: [
      'internal'
    ],
    title: 'Sales Information',
    component: CashAccountStep2UserAccount,
    dataClass: class UserAccount {
      constructor () {
        this.acquiredAccountFrom = ''
        this.customerTypeCategory = ''
        this.customerType = ''
        this.customerPriceClass = ''
        this.assignedBranch = '' // holds object w/ branch info
        this.branchNumber = '' // branch data actually used on submit
        this.notes = ''
        this.eclipsePriceClass = { title: '' }
        this.insideSalesPerson = ''
        this.outsideSalesPerson = ''
      }
    },
    testData: {
      acquiredAccountFrom: 'STD',
      customerTypeCategory: '',
      customerType: '',
      customerPriceClass: 'RIML300',
      eclipsePriceClass: { title: 'Industrial MRO Large' },
      assignedBranch: { text: '3164 - Alexandria', value: '3164' },
      isStepDataReady: 'true',
      insideSalesPerson: { value: 'AHUNTLEY', text: 'AHUNTLEY - Alan Huntley', bannerCode: 'rexel', employeeSSO: null, eclipseUserLogin: 'AHUNTLEY', displayName: 'Alan Huntley', emailAddress: 'alan.huntley@rexelusa.com' },
      notes: ''
    },
    labels: {
      acquiredAccountFrom: 'Acquired Account From:',
      customerTypeCategory: 'PIC Code Category:',
      customerType: 'PIC Code:',
      assignedBranch: 'Assigned Branch:',
      notes: 'Notes:',
      insideSalesPerson: 'Inside Salesperson:',
      outsideSalesPerson: 'Outside Salesperson:'
    }
  }
}
