<template>
  <fieldset>
    <div class="row">
      <div class="col-md-6">
        <FormInputTextInput
          v-model="userFormInput.name"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.name"
          :input-label="formLabels.name"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-md-6">
        <FormInputTextInput
          v-model="userFormInput.contactNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.contactNumber"
          :input-label="formLabels.contactNumber"
          input-type="tel"
          placeholder="(xxx) xxx-xxxx"></FormInputTextInput>
      </div>
    </div>
    <div style="margin-left: 1.4em; margin-top: .4em;">
      <div class="form-check">
        <input
          id="propertyOwnerInformationIsSameAsProjectInformation"
          v-model="userFormInput.isSameAsProjectInformation"
          :disabled="isStepDisabled"
          class="form-check-input"
          type="checkbox"
          name="propertyOwnerInformationIsSameAsProjectInformation"
          value="true"
          checked />
        <label
          :style="isStepDisabled ? 'cursor: initial;' : ''"
          style="padding-left: 0.2em !important; font-size: 14px;"
          class="form-check-label"
          for="propertyOwnerInformationIsSameAsProjectInformation">
          Same as Job Address?
        </label>
      </div>
    </div>
    <FormInputGroupAddressInfo
      ref="groupAddressInfo"
      v-model="userFormInput"
      :disabled="isStepDisabled"
      :vuelidate="vuelidate"
      :form-labels="formLabels"
      :form-data="formData"
      :field-names="{
        streetAddress: 'streetAddress1',
        addressUnitNumber: 'streetAddress2',
        city: 'city',
        state: 'state',
        postalCode: 'postalCode'
      }"></FormInputGroupAddressInfo>

    <!-- lien agent -->
    <FormInputRadioOptions
      v-if="!isPlatt()"
      v-model="userFormInput.doesNotHaveNCOnly"
      :disabled="isStepDisabled"
      :input-label="formLabels.doesNotHaveNCOnly"
      default-value="0"
      :options="[
        {
          key: 'Yes',
          value: '1',
        },
        {
          key: 'No',
          value: '0',
        }
      ]"></FormInputRadioOptions>
    <div
      v-if="!isPlatt() && userFormInput.doesNotHaveNCOnly == '1'"
      class="col">
      <div class="row">
        <div class="col-md-6">
          <FormInputTextInput
            v-model="userFormInput.lienAgentName"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.lienAgentName"
            :input-label="formLabels.lienAgentName"
            input-type="text"></FormInputTextInput>
        </div>
        <div class="col-md-6">
          <FormInputTextInput
            v-model="userFormInput.lienAgentContactNumber"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.lienAgentContactNumber"
            :input-label="formLabels.lienAgentContactNumber"
            input-type="tel"
            placeholder="(xxx) xxx-xxxx"></FormInputTextInput>
        </div>
      </div>
      <FormInputGroupAddressInfo
        v-model="userFormInput"
        :disabled="isStepDisabled"
        :vuelidate="vuelidate"
        :form-labels="formLabels"
        :form-data="formData"
        :field-names="{
          streetAddress: 'lienAgentStreetAddress1',
          addressUnitNumber: 'lienAgentStreetAddress2',
          city: 'lienAgentCity',
          state: 'lienAgentState',
          postalCode: 'lienAgentPostalCode'
        }"></FormInputGroupAddressInfo>
    </div>
  </fieldset>
</template>

<script>
import TenantsMixin from 'mixins/tenants.mixin'
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputGroupAddressInfo from '@/components/form/FormInputGroupAddressInfo.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptions.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'

export default {
  name: 'JobAccountFormStepPropertyOwnerInformation',
  components: {
    FormInputTextInput,
    FormInputGroupAddressInfo,
    FormInputRadioOptions
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  mounted () {
    this.$root.$on('updatePropertyOwner', (payload) => {
      const addressFields = { ...payload }
      addressFields.streetAddress = payload.streetAddress1
      addressFields.addressUnitNumber = payload.streetAddress2
      delete addressFields.streetAddress1
      delete addressFields.streetAddress2

      if (this.$refs.groupAddressInfo != null) {
        this.$refs.groupAddressInfo.updateFields(addressFields)
      }
    })

    // trigger vuelidate $dirty flag for optional/hidden/default data
    this.vuelidate.lienAgentName.$touch()
    this.vuelidate.lienAgentContactNumber.$touch()
    this.vuelidate.lienAgentStreetAddress1.$touch()
    this.vuelidate.lienAgentCity.$touch()
    this.vuelidate.lienAgentPostalCode.$touch()
  }
  // updated () {
  //   // KEEP: When Dev Mode "Overwrite Form Data with Test Data" doesn't work
  //   this.$nextTick(function () {
  //     this.userFormInput.contactNumber = '(503) 867-5309'
  //     this.userFormInput.isSameAsProjectInformation = true
  //     this.userFormInput.postalCode = '97006'
  //     this.userFormInput.city = 'BEAVERTON'
  //     this.userFormInput.state = 'OR'
  //     this.userFormInput.name = 'Test Property Owner Name'
  //   })
  // }
}
</script>
