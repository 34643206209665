<template>
  <fieldset>
    <FormInputTextarea
      v-model="userFormInput.userNotes"
      :disabled="isStepDisabled"
      :input-label="formLabels.userNotes"></FormInputTextarea>
  </fieldset>
</template>

<script>
import FormInputTextarea from '@/components/form/FormInputTextarea.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'

export default {
  name: 'InstantCreditFormStepNotes',
  components: {
    FormInputTextarea
  },
  mixins: [
    FormStepImplementationMixin
  ]
}
</script>
