<template>
  <div
    id="previous-order-component"
    class="component-container">
    <div v-if="orders">
      <div
        v-if="orders.length != 0"
        class="btn-title">
        <h5 class="section-title">
          Previous Orders
        </h5>
      </div>
      <div v-if="orders.length != 0">
        <table class="table">
          <thead>
            <tr>
              <th>Order</th>
              <th>PO Number</th>
              <th>Order Date</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in orders"
              :key="index">
              <td>{{ order.orderNumber }}</td>
              <td>{{ order.purchaseOrderNumber }}</td>
              <td>{{ order.orderDate }}</td>
              <td>{{ order.total | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="spinner show-spinner orders-spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviousOrders',
  props: {
    orders: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
#credit-review-detail {
  .orders-spinner {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 25px 0px 50px;
  }
  table {
    width: 100%;
    border: 1px solid rgba(26,26,26,.125);

    th {
      border-top: 1px solid rgba(26,26,26,.125);
    }
    td {
      padding: .5rem;
      font-size: .875rem;
    }
  }
}
</style>
