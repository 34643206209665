<template>
  <div>
    <v-list class="pt-4 shrink transparent">
      <v-list-item>
        <v-list-item-avatar>
          <v-menu
            v-model="showEditMyProfileModal"
            :close-on-content-click="false"
            disabled
            offset-x>
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on">
                <v-avatar
                  :size="40"
                  class="elevation-1">
                  <img
                    :src="identity.picture"
                    :alt="identity.name" />
                </v-avatar>
              </v-btn>
            </template>
            <v-card
              v-if="showEditMyProfileModal"
              class="pa-0">
              <v-card-text>
                Edit your Profile?
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="toggleEditProfileModal">
                  cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="openMyProfileModal">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-list-item-avatar>
        <v-flex class="white--text">
          <div class="user-branch">
            {{ branchName }}
          </div>
          <div
            class="user-name">
            {{ identity.displayName || identity.name }}
          </div>
        </v-flex>
      </v-list-item>
    </v-list>
    <edit-my-profile
      v-if="showEditMyProfileModal"
      :open-iframe="openMyProfileIframe"
      :iframe-url="iframeUrl"
      @close-modal="closeMyProfileModal">
    </edit-my-profile>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EditMyProfile from './EditMyProfile.vue'

export default {
  name: 'UserAvatar',
  components: {
    EditMyProfile
  },
  props: {
    identity: {
      type: Object,
      default: () => {}
    },
    defaultBranch: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showEditMyProfileModal: false,
      openMyProfileIframe: false
    }
  },
  computed: {
    ...mapState([
      'appConfig'
    ]),
    branchName () {
      const branch = this.defaultBranch

      if (!branch || !branch.displayBranchName) return this.identity.branchId

      return branch.displayBranchName.includes(branch.branchNumber)
        ? branch.displayBranchName
        : `${branch.displayBranchName} - ${branch.bannerBranchNumber}`
    },
    iframeUrl () {
      return this.appConfig.environment === 'Production' ? 'https://myprofile.rexelapps.com' : 'https://testmyprofile.rexelapps.com'
    }
  },
  methods: {
    toggleEditProfileModal () {
      this.showEditProfileModal = !this.showEditProfileModal
    },
    openMyProfileModal () {
      this.openMyProfileIframe = true
      document.body.setAttribute('style', 'overflow-y: hidden !important; height: 100vh;')
      this.showEditProfileModal = false
    },
    closeMyProfileModal () {
      this.openMyProfileIframe = false
      document.body.setAttribute('style', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.user-branch {
  font-size: 11px;
}
// remove cursor:pointer until we add my profile edit functionality
.v-btn {
  cursor: default;
}

.user-name {
  font-size: 14px;
}
</style>
