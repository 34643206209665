<template>
  <fieldset>
    <v-checkbox
      v-model="userFormInput.sameAsPayableContact"
      :disabled="isStepDisabled"
      color="#004168"
      class="same-as-checkbox">
      <span
        slot="label"
        class="checkbox-label">
        {{ formLabels.sameAsPayableContact }}
      </span>
    </v-checkbox>

    <div
      v-show="!userFormInput.sameAsPayableContact"
      class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.firstName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.firstName"
          :input-label="formLabels.firstName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.lastName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.lastName"
          :input-label="formLabels.lastName"
          input-type="text"></FormInputTextInput>
      </div>
    </div>
    <div
      v-show="!userFormInput.sameAsPayableContact"
      class="row">
      <div class="col-sm-4">
        <FormInputDropdownContactTitles
            v-if="isRexelOrGexpro()"
            v-model="userFormInput.jobTitle"
            default-value="Owner/President"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.jobTitle"
            :input-label="formLabels.jobTitle"
            :form-labels="formLabels"
            :form-data="formData"
            :field-names="{
              jobTitle: 'jobTitle'
            }"></FormInputDropdownContactTitles>
        <FormInputTextInput
          v-else
          v-model="userFormInput.jobTitle"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.jobTitle"
          :input-label="formLabels.jobTitle"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.emailAddress"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.emailAddress"
          :input-label="formLabels.emailAddress"
          input-type="email"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.phoneNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.phoneNumber"
          :input-label="formLabels.phoneNumber"
          input-type="tel"></FormInputTextInput>
      </div>
    </div>
  </fieldset>
</template>

<style scoped>
  .checkbox-label {
    margin-top:10px;
  }
</style>

<script>
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputDropdownContactTitles from '@/components/form/FormInputDropdownContactTitles.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import TenantsMixin from 'mixins/tenants.mixin'

export default {
  name: 'InstantCreditFormStepSignatoryInformation',
  components: {
    FormInputTextInput,
    FormInputDropdownContactTitles
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ]
}
</script>
