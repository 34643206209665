<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="comment-modal"
    @close="modalState = false"
    @closeModal="modalState = false">
    <template slot="header">
      New Comment
    </template>
    <div slot="body">
      <textarea
        v-model="comment"
        v-focus
        class="form-control"
        rows="6"
        placeholder="Enter Comment..."></textarea>
    </div>
    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="modalState = false">
        Cancel
      </a>
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        :disabled="!comment"
        @click="addComment()">
        Save Comment
      </button>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '../../../../components/GenericModal.vue'
import axios from 'ca-http-service'

export default {
  name: 'CommentModal',
  components: {
    GenericModal
  },
  props: {
    app: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      modalState: false,
      appItem: this.app,
      comment: ''
    }
  },
  methods: {
    addComment: function () {
      const tenant = this.appItem.bannerId // TODO: test that this value is populated where expected
      const applicationId = this.appItem.guid

      axios.post(`/api/CreditReview/addComment/${tenant}/${applicationId}`, { comment: this.comment })
        .then((res) => {
          if (this.appItem.comments) {
            this.appItem.comments.unshift(res.data.data)
          }
          this.comment = null
          this.modalState = false
        })
        .catch(function (err) {
          console.error(err)
          this.comment = ''
          this.modalState = false
        })
    }
  }
}
</script>
