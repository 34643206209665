export default {
  computed: {
    parsedSubAccounts () {
      return Object.keys(this.subAccounts).map((account) => {
        return {
          text: `[${account}] ${this.subAccounts[account]}`,
          value: Number(account)
        }
      })
    },
    parsedStates () {
      return this.states.map((state) => {
        return {
          text: state.value,
          value: state.key
        }
      })
    }
  }
}
