<template>
  <v-text-field
    v-if="!values.length"
    :v-required="required"
    :rules="getRules(required)"
    :label="label"
    :placeholder="placeholder"
    @change="valueChanged">
  </v-text-field>
  <v-select
    v-else
    :key="clearedReRenderKey"
    :v-required="required"
    :rules="getRules(required)"
    :items="valueOptions"
    :label="label"
    :placeholder="placeholder"
    @change="valueChanged">
  </v-select>
</template>

<script>
export default {
  name: 'CustomField',
  props: {
    id: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    values: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      value: null,
      cleared: false,
      rules: {
        required: (value) => !!value || 'This field is required'
      }
    }
  },
  computed: {
    /**
     * Vue component key for the v-select component above.
     *
     * When the form is cleared this custom field's value is reset to undefined which causes
     * this key to change and force the v-select to re-render from scratch so that its
     * selection is cleared.
     */
    clearedReRenderKey () {
      return this.cleared ? `${this.id}:cleared` : this.id
    },
    valueOptions () {
      return this.values.map((x) => ({
        text: x.label,
        value: x.value
      }))
    }
  },
  watch: {
    value (newValue) {
      this.cleared = !newValue
    }
  },
  updated () {
    this.cleared = false
  },
  methods: {
    getRules (required) {
      return required ? [this.rules.required] : []
    },
    valueChanged (value) {
      this.$emit('change', this.id, this.value = value)
    },
    clear () {
      this.value = undefined
    }
  }
}
</script>
