<template>
  <div class="pt-6">
    <v-form
      ref="feedbackForm"
      v-model="isFormValid">
      <v-textarea
        v-if="attemptAutoFocus"
        v-model="feedbackForm.comments"
        :rules="[rules.required]"
        required
        :autofocus="attemptAutoFocus"
        :label="commentsFieldLabel"
        filled>
      </v-textarea>
      <template v-for="{ id, required, label, placeholder, values } in customFields">
        <custom-field
          :id="id"
          :ref="id"
          :key="id"
          :required="required"
          :label="label"
          :placeholder="placeholder"
          :values="values"
          @change="customFieldChanged">
        </custom-field>
      </template>
    </v-form>
    <v-expansion-panels style="background-color: rgb(238, 238, 238);">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="h6 font-weight-black">
            Attached Files ({{ files.length }})
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            flat
            style="background-color: transparent">
            <v-card-text class="pa-0 mt-1 pb-1">
              <vue-dropzone
                id="dropzone"
                ref="Dropzone"
                :duplicate-check="true"
                :destroy-dropzone="true"
                :class="{'mb-3' : files.length}"
                :options="dropzoneOptions"
                :include-styling="false"
                :use-custom-slot="true"
                grow
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-error="fileUploadError"
                @vdropzone-success="fileUploadSuccess"
                @vdropzone-duplicate-file="fileDuplicate">
                <v-card
                  style="background-color: transparent"
                  flat>
                  <v-card-text class="text-center">
                    <div class="h5 font-weight-bold">
                      Drag &amp; Drop Files
                    </div>
                    <div>or</div>
                    <v-btn
                      color="primary"
                      outlined>
                      Select
                    </v-btn>
                  </v-card-text>
                </v-card>
              </vue-dropzone>
              <v-alert
                class="mb-2"
                :value="hasSuccessfulFiles"
                color="success"
                transition="slide-y-transition">
                {{ alertSuccessMessage }}
              </v-alert>
              <v-alert
                class="mb-3"
                :value="hasFailedFiles"
                color="error"
                transition="slide-y-transition">
                {{ alertErrorMessage }}
              </v-alert>
              <file-list
                :files="files"
                @remove="removeFile($event)">
              </file-list>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import * as _dropzone from 'dropzone'
import { fileMatches } from './utils'
import CustomField from './CustomField.vue'
import FileList from './FileList.vue'

export default {
  name: 'FeedbackForm',
  components: {
    'vue-dropzone': vue2Dropzone,
    CustomField,
    FileList
  },
  props: {
    // v-if the Comments textarea so that it gets recreated in a forced re-render
    // Based on solution in https://github.com/vuetifyjs/vuetify/issues/1870
    attemptAutoFocus: {
      type: Boolean,
      required: false,
      default: true
    },
    uploadUri: {
      type: String,
      required: true
    },
    uploadHeaders: {
      type: Object,
      required: true
    },
    acceptedFiles: {
      type: String,
      required: false,
      default: '*'
    },
    appName: {
      type: String,
      required: true
    },
    customFields: {
      type: Array,
      required: false,
      default: () => []
    },
    deleteAttachment: {
      type: Function,
      required: true
    },
    commentsFieldLabel: {
      type: String,
      required: false,
      default: 'Questions/Comments'
    }
  },
  data () {
    return {
      isFormValid: false,
      feedbackForm: {
        comments: null,
        customFields: {}
      },
      files: [],
      fileTokens: {},
      failedFiles: [],
      rules: {
        required: (value) => !!value || 'This field is required'
      }
    }
  },
  computed: {
    dropzoneOptions () {
      return {
        url: this.uploadUri,
        maxFileSize: 5,
        previewTemplate: '<div></div>',
        withCredentials: false, // Must be false to prevent CORS issues
        headers: this.uploadHeaders,
        paramName: 'file', // The body parameter name that will be used to transfer the file
        addRemoveLinks: false, // We implement a fancier interface that does the same thing
        accept: (file, done) => {
          if (file.size === 0) {
            const message = `${file.name} is empty`
            this.$emit('message', {
              message,
              status: 'error'
            })
            done(message)
          }
          if (file.size > 7000000) {
            const message = `${file.name} exceeds 7MB in size`
            this.$emit('message', {
              message,
              status: 'error'
            })
            done(message)
          }
          if (this.files.reduce((total, _file) => total + _file.size, 0) > 10000000) {
            const message = 'Total size of all files exceeds 10MB'
            this.$emit('message', {
              message,
              status: 'error'
            })
            done(message)
          }
          const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase()
          if (!(this.acceptedFiles.split(',') || []).includes(fileExtension)) {
            const message = 'Allowed file types: ' + this.acceptedFiles.replace(/,/g, ', ')
            this.$emit('message', {
              message,
              status: 'error'
            })
            done(message)
          }
          if (this.files.length > 5) {
            const message = 'Up to 5 files may be attached'
            this.$emit('message', {
              message,
              status: 'error'
            })
            done(message)
          }
          // Accept the file
          done()
        }
      }
    },
    hasFailedFiles () {
      return this.failedFiles.length > 0
    },
    hasSuccessfulFiles () {
      return this.files.reduce((hasFiles, file) => hasFiles || file.status === _dropzone.Dropzone.SUCCESS, false)
    },
    alertSuccessMessage () {
      const succeededFilesLength = this.files.reduce(function (succeededLength, file) {
        return file.status === _dropzone.Dropzone.SUCCESS
          ? succeededLength + 1
          : succeededLength
      }, 0)
      return `${succeededFilesLength}
        file${succeededFilesLength > 1 ? 's were' : ' was'}
        uploaded successfully`
    },
    alertErrorMessage () {
      const failedFileNames = this.failedFiles.join(', ')
      return `${this.failedFiles.length}
        file${this.failedFiles.length > 1 ? 's' : ''}
        did not upload (${failedFileNames})`
    },
    feedback () {
      const fileUploads = this.files.filter((file) => file.status === _dropzone.Dropzone.SUCCESS)
      return {
        subject: `${this.appName} Feedback`,
        body: this.feedbackForm.comments,
        url: window.location.href,
        customFields: Object.keys(this.feedbackForm.customFields).map((x) => ({
          id: x,
          value: this.feedbackForm.customFields[x]
        })),
        // Associate accepted file uploads that have Zendesk file tokens
        uploads: fileUploads.reduce((uploads, file) => {
          const token = this.fileTokens[file.name]
          if (token) {
            uploads.push(token)
          }
          return uploads
        }, [])
      }
    }
  },
  watch: {
    isFormValid (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('validated', newValue)
      }
    }
  },
  methods: {
    customFieldChanged (id, value) {
      this.feedbackForm.customFields[id] = value
    },
    fileAdded (file) {
      this.files.push(file)
    },
    fileRemoved (file) {
      const listedFileIndex = this.files.findIndex(fileMatches(file))
      this.files.splice(listedFileIndex, 1)
      if (this.fileTokens[file.name]) {
        delete this.fileTokens[file.name]
      }
    },
    fileUploadError (file) {
      // Select the matching errored file in case there's duplicates,
      //  i.e. user tried to upload a duplicate
      const matchingFiles = this.files.filter(fileMatches(file))
      const listedFileIndex = this.files
        .findIndex((file) => matchingFiles.includes(file) && file.status === _dropzone.Dropzone.ERROR)
      this.files.splice(listedFileIndex, 1)
      // If there wre multiple matching files, this was a rejected file and not a hard failure
      if (matchingFiles.length === 1) {
        this.failedFiles.push(file.name)
      }
    },
    fileUploadSuccess (file, uploadResponse) {
      const listedFileIndex = this.files.findIndex(fileMatches(file))
      this.files.splice(listedFileIndex, 1, file)

      if (uploadResponse.name === file.name) {
        this.fileTokens[file.name] = uploadResponse.token
      }
      this.$emit('message', { message: '', status: '' })
    },
    fileDuplicate (file) {
      const message = `${file.name} has already been attached`
      this.$emit('message', {
        message,
        status: 'error'
      })
    },
    removeFile (file) {
      // Try to delete file if it has already been uploaded
      const token = this.fileTokens[file.name]
      if (file.status === _dropzone.Dropzone.SUCCESS && token) {
        this.deleteAttachment(token).then(() => {
          // Remove the file after it's successfully deleted
          if (this.$refs.Dropzone) {
            this.$refs.Dropzone.removeFile(file)
          }
        })
      } else {
        if (this.$refs.Dropzone) {
          this.$refs.Dropzone.removeFile(file)
        }
      }
      if (this.files.length === 0) {
        this.clear()
      } else {
        this.$emit('message', { message: '', status: '' })
      }
    },
    clear () {
      this.feedbackForm.comments = null
      this.feedbackForm.customFields = {}
      this.customFields.map((x) => this.$refs[x.id][0])
        .forEach((fieldRef) => fieldRef.clear())
      if (this.$refs.Dropzone) {
        this.$refs.Dropzone.removeAllFiles(true)
      }
      this.fileTokens = {}
      this.$emit('message', { message: '', status: '' })
    }
  }
}
</script>

<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
    background-color: rgb(238, 238, 238);
}

#dropzone .v-card {
  /* $mediumGray color from Sales */
  border: 2px dashed #666666;
}
</style>
