<template>
  <action-button
    outlined
    :click-action="open"
    :disabled="disabled">
    <v-icon>mdi-plus-circle</v-icon>
    Add Contact
    <v-dialog
      v-model="dialogIsOpen"
      class="job-account-add-contact-modal"
      max-width="800">
      <v-card>
        <v-card-title class="headline">
          Add Job Contact
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="contactData.firstName"
                class="job-account-add-contact-modal_text-field"
                outlined
                :label="firstNameLabel"
                :error="$v.contactData.firstName.$error"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="contactData.lastName"
                outlined
                :label="lastNameLabel"
                :error="$v.contactData.lastName.$error"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="displayContactNumber"
                v-mask="'(###) ###-####'"
                outlined
                :label="contactNumberLabel"
                :error="$v.contactData.contactNumber.$error"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="contactData.emailAddress"
                outlined
                :label="formLabels.emailAddress"
                :error="$v.contactData.emailAddress.$error"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="contactData.jobTitle"
                outlined
                :label="formLabels.jobTitle"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <action-button :click-action="close">
            Cancel
          </action-button>
          <action-button
            is-primary
            :click-action="submit">
            Add
          </action-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </action-button>
</template>

<script>
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
import ActionButton from '@/components/ActionButton.vue'

export default {
  name: 'JobAccountAddContactModal',
  components: {
    ActionButton
  },
  props: {
    formLabels: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      dialogIsOpen: false,
      displayContactNumber: '',
      contactData: {
        firstName: '',
        lastName: '',
        contactNumber: '',
        emailAddress: '',
        jobTitle: ''
      }
    }
  },
  computed: {
    firstNameLabel () {
      return this.addRequiredAsterisk(this.formLabels.firstName)
    },
    lastNameLabel () {
      return this.addRequiredAsterisk(this.formLabels.lastName)
    },
    contactNumberLabel () {
      return this.addRequiredAsterisk(this.formLabels.contactNumber)
    }
  },
  watch: {
    displayContactNumber: {
      handler (newValue) {
        this.contactData.contactNumber = newValue.replace(/\D/g, '')
      }
    },
    dialogIsOpen (value) {
      if (value) {
        this.$v.contactData.$reset()
        this.resetInputs()
      }
    }
  },
  methods: {
    open () {
      this.dialogIsOpen = true
    },
    close () {
      this.dialogIsOpen = false
      this.$v.contactData.$reset()
    },
    submit () {
      this.$v.contactData.$touch()
      if (this.$v.contactData.$invalid) {
        return
      }
      const clone = { ...this.contactData }
      clone.contactNumber = this.displayContactNumber
      this.$emit('updateContacts', clone)
      this.close()
    },
    addRequiredAsterisk (value) {
      return `${value} *`
    },
    resetInputs () {
      const keys = Object.keys(this.contactData)
      this.displayContactNumber = ''
      keys.map(k => {
        this.contactData[k] = ''
        return k
      })
    }
  },
  validations () {
    return {
      contactData: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        contactNumber: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10)
        },
        emailAddress: {
          email
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
