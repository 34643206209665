<template>
  <fieldset>
    <h5 class="alt-ship-title mt-10">
      Alternate Ship-To Address Questions
    </h5>
    <v-row no-gutters>
      <v-col>
        <FormInputRadioOptions
          v-model="shippingForm.isShipToShop"
          :disabled="isStepDisabled"
          :input-label="formLabels.isShipToShop"
          default-value="1"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </v-col>
    </v-row>
    <v-row
      v-if="shippingForm.isShipToShop === '0'"
      no-gutters>
      <v-col>
        <FormInputTextarea
          v-model="shippingForm.whereIsItShipping"
          :disabled="isStepDisabled"
          :input-label="formLabels.whereIsItShipping"
          dense
          :rows="1"
          auto-grow></FormInputTextarea>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <FormInputTextarea
          v-model="shippingForm.whyShipToAlt"
          :disabled="isStepDisabled"
          :input-label="formLabels.whyShipToAlt"
          dense
          :rows="3"></FormInputTextarea>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <FormInputRadioOptions
          v-model="shippingForm.isSingleOrder"
          :disabled="isStepDisabled"
          :input-label="formLabels.isSingleOrder"
          default-value="1"
          :options="[
            {
              key: 'Single Order',
              value: '1'
            },
            {
              key: 'Total Job',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </v-col>
    </v-row>
    <v-row
      v-if="shippingForm.isSingleOrder === '1'"
      no-gutters>
      <v-col>
        <FormInputNumberInput
          v-model="shippingForm.orderAmount"
          :disabled="isStepDisabled"
          :input-label="formLabels.orderAmount"
          dense></FormInputNumberInput>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <FormInputRadioOptions
          v-model="shippingForm.isNewCustomer"
          :disabled="isStepDisabled"
          :input-label="formLabels.isNewCustomer"
          default-value="1"
          :options="[
            {
              key: 'New',
              value: '1'
            },
            {
              key: 'Existing',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <FormInputRadioOptions
          v-model="shippingForm.isFirstJob"
          :disabled="isStepDisabled"
          :input-label="formLabels.isFirstJob"
          default-value="1"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <FormInputRadioOptions
          v-model="shippingForm.isThereAnyCollectionIssues"
          :disabled="isStepDisabled"
          :input-label="formLabels.isThereAnyCollectionIssues"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </v-col>
    </v-row>
    <v-row
      v-if="shippingForm.isThereAnyCollectionIssues === '1'"
      no-gutters>
      <v-col>
        <FormInputTextarea
          v-model="shippingForm.issuesAndComments"
          :disabled="isStepDisabled"
          :input-label="formLabels.issuesAndComments"
          :rows="3"
          auto-grow></FormInputTextarea>
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
import FormInputRadioOptions from '@/components/form/FormInputRadioOptionsV2.vue'
import FormInputTextarea from '@/components/form/FormInputTextarea.vue'
import FormInputNumberInput from '@/components/form/FormInputNumberInput.vue'

export default {
  name: 'JobAccountAltShipQuestions',
  components: {
    FormInputRadioOptions,
    FormInputTextarea,
    FormInputNumberInput
  },
  props: {
    userFormInput: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    },
    isStepDisabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      shippingForm: this.userFormInput
    }
  },
  watch: {
    'shippingForm.isThereAnyCollectionIssues': {
      handler (newValue, oldValue) {
        this.shippingForm.isThereAnyCollectionIssues = newValue
      }
    }
  }
}
</script>
<style lang="scss" scoped>
fieldset {
  font-size: 12px;
}
.alt-ship-title {
  color: #000000;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  line-height: 36px;
}
</style>
