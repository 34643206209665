export default {
  methods: {
    isPlatt (tenantId) {
      return tenantId === 1
    },
    isRexcel (tenantId) {
      return tenantId === 2
    },
    isGexpro (tenantId) {
      return tenantId === 3
    },
    isRexelEnergySolutions (tenantId) {
      return tenantId === 4
    }
  }
}
