<template>
  <v-form>
    <fieldset>
      <v-row>
        <v-col cols="4">
          <v-text-field
            ref="firstInput"
            v-model="streetAddress"
            dense
            :label="formLabels[fieldNames.streetAddress]"
            :error="vuelidate[fieldNames.streetAddress].$error"
            :outlined="outlined"
            @input="vuelidate[fieldNames.streetAddress].$touch()"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="addressUnitNumber"
            dense
            :label="formLabels[fieldNames.addressUnitNumber]"
            :error="vuelidate[fieldNames.addressUnitNumber].$error"
            :outlined="outlined"
            @input="vuelidate[fieldNames.addressUnitNumber].$touch()"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="postalCode"
            dense
            :label="formLabels[fieldNames.postalCode]"
            :error="vuelidate[fieldNames.postalCode].$error"
            :outlined="outlined"
            @input="[onPostalCodeChange($event), vuelidate[fieldNames.postalCode].$touch()]"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="city"
            dense
            :label="formLabels[fieldNames.city]"
            :error="vuelidate[fieldNames.city].$error"
            :outlined="outlined"
            @input="vuelidate[fieldNames.city].$touch()"></v-text-field>
        </v-col>
        <v-col>
          <v-select
            v-model="state"
            outlined
            dense
            :items="states"
            :label="formLabels[fieldNames.state]"
            :error="vuelidate[fieldNames.state].$error"
            :item-value="state"
            @input="vuelidate[fieldNames.state].$touch()"></v-select>
        </v-col>
      </v-row>
    </fieldset>
  </v-form>
</template>

<script>
import axios from 'ca-http-service'
export default {
  name: 'FormGroupAddressInfoV2',
  props: {
    value: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    },
    fieldNames: {
      type: Object,
      required: false,
      default: () => ({
        country: 'country',
        streetAddress: 'streetAddress',
        addressUnitNumber: 'addressUnitNumber',
        city: 'city',
        state: 'state',
        postalCode: 'postalCode'
      })
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true
    },
    updateByPostalCode: {
      type: Boolean,
      required: false,
      default: true
    },
    vuelidate: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      states: []
    }
  },
  computed: {
    streetAddress: {
      get () {
        return this.value[this.fieldNames.streetAddress]
      },
      set (value) {
        this.updateData(this.fieldNames.streetAddress, value)
      }
    },
    addressUnitNumber: {
      get () {
        return this.value[this.fieldNames.addressUnitNumber]
      },
      set (value) {
        this.updateData(this.fieldNames.addressUnitNumber, value)
      }
    },
    city: {
      get () {
        return this.value[this.fieldNames.city]
      },
      set (value) {
        this.updateData(this.fieldNames.city, value)
      }
    },
    state: {
      get () {
        return this.value[this.fieldNames.state]
      },
      set (value) {
        this.updateData(this.fieldNames.state, value)
      }
    },
    postalCode: {
      get () {
        return this.value[this.fieldNames.postalCode]
      },
      set (value) {
        this.updateData(this.fieldNames.postalCode, value)
      }
    }
  },
  async mounted () {
    const statesData = await this.getStates(this.value[this.fieldNames.country])
    this.states = statesData.map(o => o.key)
  },
  methods: {
    updateData (key, value) {
      const newData = { ...this.value, [key]: value }
      this.$emit('input', newData)
    },
    async getStates (country) {
      return axios.get('/generic/getStateList', {
        params: {
          countryCode: country
        }
      })
        .then((res) => {
          return res.status === 200 ? res.data : []
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async onPostalCodeChange (event) {
      if (!this.updateByPostalCode || event.length !== 5) return
      const results = await this.getCityStateByPostalCode(event)
      const { city, state } = results

      if (typeof city === 'undefined' || typeof state === 'undefined') return
      const newData = { ...this.value, city, state }
      this.$emit('input', newData)
    },
    async getCityStateByPostalCode (postalCode) {
      return axios.get('/generic/getCityStateByZip', { params: { postalCode } })
        .then((res) => {
          return res.status === 200 ? res.data : null
        })
        .catch((err) => {
          const errorMessage = err.response.data
          console.error(errorMessage)
          this.modelState[this.fieldNames.postalCode] = errorMessage
        })
    }
  }
}
</script>

<style scoped>

</style>
