<template>
  <div class="form-input-checkbox">
    <label
      v-if="inputLabel.length > 0"
      class="form-control-label"
      :class="$isRequired() ? 'required' : ''"
      style="font-size: 12px;">
      {{ inputLabel }}
    </label>
    <v-container
      class="px-0"
      fluid>
      <v-row>
        <v-col
          v-for="checkbox in checkboxes"
          :key="checkbox.key"
          class="checkbox-item"
          :class="{'has-danger': $isErrorState()}"
          cols="12"
          :sm="getCheckboxCols('sm')"
          :md="getCheckboxCols('md')"
          :lg="getCheckboxCols('lg')">
          <v-checkbox
            v-model="checked"
            style="padding: 0; margin: 0;"
            :disabled="disabled"
            :label="checkbox.value"
            :value="checkbox.key"
            hide-details
            @change="updateCheckedValues"></v-checkbox>
          <span v-if="toolTip">
            <a
              style="color: #5bc0de;"
              data-toggle="tooltip"
              class="fa fa-info-circle"
              role="tooltip"
              :title="toolTip"></a>
          </span>
        </v-col>
      </v-row>
    </v-container>

    <FormFieldErrorMessages :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from './FormInput.mixin'

export default {
  name: 'FormInputCheckboxes',
  components: {
    FormFieldErrorMessages
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: String,
      required: true
    },
    labelSize: {
      type: String,
      required: false,
      default: 'short'
    },
    inputLabel: {
      type: String,
      required: true
    },
    checkboxes: {
      type: Array,
      required: true
    },
    toolTip: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      checked: []
    }
  },
  mounted () {
    // if a value exists on mount, set it
    this.$nextTick(() => { // value dne until $nextTick
      if (this.value != null && this.value.length > 0) {
        const checks = this.value.split(',')

        checks.forEach(check => {
          const isInCheckedArray = this.checked.find((c) => {
            return c === check
          }) != null

          if (!isInCheckedArray) {
            this.updateValue(true, check)
          }
        })
      }
    })
  },
  methods: {
    getCheckboxCols (mediaWidth) {
      const labelSize = this.labelSize
      const checkboxColumnSized = {
        sm: {
          short: 4,
          long: 12
        },
        md: {
          short: 3,
          long: 12
        },
        lg: {
          short: 2,
          long: 6
        }
      }
      return checkboxColumnSized[mediaWidth][labelSize]
    },
    updateCheckedValues (value) {
      this.$emit('input', value.toString())
    },
    updateValue: function (isChecked, value) {
      if (isChecked) {
        this.checked.push(value)
      } else {
        const index = this.checked.indexOf(value)
        this.checked.splice(index, 1)
      }
      this.$emit('input', this.checked.toString())
      this.$touch()
    }
  }
}
</script>

<style scoped>
.fa-info-circle {
    color: #5bc0de;
    cursor: pointer;
    font-size: 16px;
  }
</style>
