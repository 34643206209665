export default {
  name: 'BaseModalMixin',
  props: {},
  data () {
    return {
      name: null,
      loading: false
    }
  },
  computed: {
    $isActive () {
      return this.$store.getters['modals/active'] === this.name
    },
    $isOpen () {
      return this.$store.getters['modals/allOpen'].includes(this.name)
    }
  },
  beforeDestroy () {
    if (this.$isOpen) this.$close()
  },
  methods: {
    $close () {
      this.$emit('on-close')
      this.$store.dispatch('modals/close', this.name)
    }
  }
}
