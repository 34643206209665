import axios from 'ca-http-service'

export default {
  async setIdentity (context, obj) {
    context.commit('setIdentity', obj)

    // on setting identity, we also want to set the current banner to its default
    const bannerName = context.getters.getDefaultBannerName
    context.commit('setCurrentBanner', bannerName)

    await context.dispatch('getGlobalBranches')
    context.dispatch('setDefaultBranch')
  },
  setDefaultBranch ({ state, commit }) {
    // map region branchids to actual branch locations for platt users
    const regionBranchIdToBranchId = {
      // California => Sacramento
      5083: '5034',
      // Western Washington => Seattle
      5095: '5002',
      // Northern Oregon => BSC
      5096: '5001',
      // Intermountain => Salt Lake City
      5097: '5060',
      // Northern Mountain => Spokane
      5098: '5009'
    }
    if (state.identity && state.branches && state.branches.length) {
      const branchId = regionBranchIdToBranchId[state.identity.branchId] || state.identity.branchId

      // does their assigned branch exist in branch, if so use that
      let result = state.branches.find(b => b.branchNumber === branchId)

      if (!result) {
        const localStorageBranchId = window.localStorage.getItem('defaultBranchId')
        result = state.branches.find(b => b.branchNumber === localStorageBranchId)
      }

      // user has no valid branch, let them choose and store that in local storage
      // leaving this here now for future ticket to allow user to select branch
      if (!result) {
        commit('setDefaultBranch', { available: false })
      } else {
        commit('setDefaultBranch', { ...result, available: true })
      }
    }
  },
  async getGlobalBranches ({ state, commit, dispatch }) {
    let apiUrl = 'https://branch.rexelapps.com/api/branches/v2?includeNonAuth=true&includeClosed=true'
    if (state.appConfig.environment !== 'Production') {
      apiUrl = 'https://testbranch.rexelapps.info/api/branches/v2?includeNonAuth=true&includeClosed=true'
    }
    return axios.get(apiUrl).then(res => {
      commit('setBranches', res.data.branches)
      dispatch('setDefaultBranch')
    }).catch(err => {
      console.error(`Error fetching branches: ${err.message}`)
    })
  },

  setAccessToken ({ commit }, accessToken) {
    commit('setAccessToken', accessToken)
  },

  setBanners (context, array) {
    context.commit('setBanners', array)
  },
  setBranches (context, array) {
    context.commit('setBranches', array)
  },
  setCurrentBanner (context, bannerName) {
    context.commit('setCurrentBanner', bannerName)
  },
  setAppConfig (context, obj) {
    context.commit('setAppConfig', obj)
  },
  setPlattForceUrl (context, url) {
    context.commit('setPlattForceUrl', url)
  },
  setCountries (context, array) {
    context.commit('setCountries', array)
  },
  setFilterReasons (context, array) {
    context.commit('setFilterReasons', array)
  },
  setStatesUS (context, array) {
    context.commit('setStatesUS', array)
  },
  setCustomer (context, obj) {
    context.commit('setCustomer', obj)
  },
  setUserRoles (context, obj) {
    context.commit('setUserRoles', obj)
  },
  setRegions (context, obj) {
    context.commit('setRegions', obj)
  },
  setAdminHeaderTitle (context, string) {
    context.commit('setAdminHeaderTitle', string)
  },
  toggleDevControls (context) {
    if (context.getters.isDevelopmentEnvironment) {
      if (context.getters.showDevControls) {
        context.commit('setDevControls', false)

        // ensure that other dev controls are set to false
        context.commit('setFormValidationHelper', false)
        context.commit('setOverwriteFormWithTestData', false)
      } else {
        context.commit('setDevControls', true)
      }
    } else {
      context.commit('setDevControls', false)

      // ensure that other dev controls are set to false
      context.commit('setFormValidationHelper', false)
      context.commit('setOverwriteFormWithTestData', false)
    }
  },
  toggleFormValidations (context) {
    if (context.getters.isDevelopmentEnvironment) {
      context.commit('setFormValidationHelper', !context.getters.showDevFormValidationState)
    } else {
      context.commit('setFormValidationHelper', false)
    }
  },
  toggleLoadWithTestData (context) {
    if (context.getters.isDevelopmentEnvironment) {
      context.commit('setOverwriteFormWithTestData', !context.getters.overwriteFormWithTestData)
    } else {
      context.commit('setOverwriteFormWithTestData', false)
    }
  },
  setStepsVisible (context, array) {
    context.commit('setStepsVisible', array)
  },
  setDashboardFormHistoryFilter (context, filter) {
    context.commit('setDashboardFormHistoryFilter', filter)
  },
  setWebUser (context, obj) {
    context.commit('setWebUser', obj)
  },
  setCreditReviewFilters (context, filters) {
    context.commit('setCreditReviewFilters', filters)
  }
}
