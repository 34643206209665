<template>
  <fieldset>
    <v-row>
      <v-col cols="4">
        <FormInputTextInput
          v-model="userFormInput.firstName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.firstName"
          :input-label="formLabels.firstName"
          input-type="text"></FormInputTextInput>
      </v-col>
      <v-col cols="4">
        <FormInputTextInput
          v-model="userFormInput.lastName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.lastName"
          :input-label="formLabels.lastName"
          input-type="text"></FormInputTextInput>
      </v-col>
      <v-col cols="4">
        <FormInputTextInput
          v-model="userFormInput.contactNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.contactNumber"
          :input-label="formLabels.contactNumber"
          input-type="tel"
          placeholder="(xxx) xxx-xxxx"></FormInputTextInput>
      </v-col>
    </v-row>
    <v-row v-if="!isStepDisabled">
      <v-col cols="4">
        <FormInputTextInput
          v-model="userFormInput.emailAddress"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.emailAddress"
          :input-label="formLabels.emailAddress"
          input-type="email"
          :on-email-validate="checkEmailAddress"></FormInputTextInput>
          <span
            v-if="!emailIsValid"
            class="red--text">
            Invalid Email Address
          </span>
      </v-col>
      <v-col cols="4">
        <FormInputDropdownContactTitles
            v-if="isRexelOrGexpro()"
            v-model="userFormInput.jobTitle"
            default-value="Foreman"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.jobTitle"
            :label="formLabels.jobTitle"
            :form-labels="formLabels"
            :form-data="formData"
            :field-names="{
              jobTitle: 'jobTitle'
            }"></FormInputDropdownContactTitles>
        <FormInputTextInput
          v-else
          v-model="userFormInput.jobTitle"
          :disabled="isStepDisabled"
          :input-label="formLabels.jobTitle"
          input-type="text"></FormInputTextInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <FormContactCards
          :disabled="isStepDisabled"
          :contacts="formattedContacts"
          @removeContact="removeContact"></FormContactCards>
      </v-col>
    </v-row>
    <v-row v-if="!isStepDisabled">
      <v-col cols="12">
        <job-account-add-contact-modal
          :form-labels="formLabels"
          :disabled="disabledButton || isStepDisabled"
          @updateContacts="updateContacts"></job-account-add-contact-modal>
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
import JobAccountFormStepProjectContactsFormItem from '@/pages/job-account/JobAccountFormStepProjectContactsFormItem.vue'
import FormInputDropdownContactTitles from '@/components/form/FormInputDropdownContactTitles.vue'
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormContactCards from '@/components/form/FormContactCards.vue'
import JobAccountAddContactModal from './JobAccountAddContactModal.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import TenantsMixin from 'mixins/tenants.mixin'
import { isEmailValid } from '@/api/forms/form-validation'

export default {
  name: 'JobAccountFormStepProjectContacts',
  components: {
    FormContactCards,
    FormInputTextInput,
    FormInputDropdownContactTitles,
    JobAccountAddContactModal
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      formItemComponent: JobAccountFormStepProjectContactsFormItem,
      newProjectContacts: [],
      dialogIsOpen: false,
      mainCardId: null,
      emailIsValid: true
    }
  },
  computed: {
    formattedContacts () {
      return this.newProjectContacts.map(c => {
        return {
          name: `${c.firstName} ${c.lastName}`,
          contactNumber: c.contactNumber,
          emailAddress: c.emailAddress,
          jobTitle: c.jobTitle,
          id: c.id
        }
      })
    },
    disabledButton () {
      const mainContact = this.newProjectContacts.filter(c => c.id === this.mainCardId)
      const additionalContacts = this.newProjectContacts.filter(c => c.id !== this.mainCardId)
      return additionalContacts.length >= 2 || mainContact.length === 0
    }
  },
  watch: {
    'userFormInput.firstName': {
      handler () {
        this.userFormInputWatcherHandler()
      }
    },
    'userFormInput.lastName': {
      handler () {
        this.userFormInputWatcherHandler()
      }
    },
    'userFormInput.contactNumber': {
      handler () {
        this.userFormInputWatcherHandler()
      }
    },
    'userFormInput.emailAddress': {
      handler () {
        this.userFormInputWatcherHandler()
      }
    },
    'userFormInput.jobTitle': {
      handler () {
        this.userFormInputWatcherHandler()
      }
    },
    newProjectContacts: {
      handler (newVal) {
        this.$set(this.userFormInput, 'projectContacts', this.newProjectContacts)
      }
    }
  },
  mounted () {
    const contacts = this.formData.projectContacts
    if (contacts.length > 0) {
      this.newProjectContacts.length = 0

      contacts.forEach(contact => {
        this.updateContacts(contact)
      })
      this.newProjectContacts.shift()
    }
  },
  methods: {
    userFormInputWatcherHandler () {
      const isValid = !this.vuelidate.$invalid && this.userFormInput.contactNumber.length === 14
      if (!isValid && this.mainCardId === null) return

      const newContact = this.setContactObjectWithId(this.userFormInput)

      if (isValid && this.mainCardId === null) {
        this.mainCardId = newContact.id
        this.newProjectContacts.unshift(newContact)
        return
      }

      newContact.id = this.mainCardId
      const index = this.newProjectContacts.findIndex(a => a.id === this.mainCardId)
      this.newProjectContacts.splice(index, 1, newContact)
    },
    resetFormInputs () {
      // on adding, reset the form using $set
      const keys = Object.keys(this.userFormInput)
      for (const key of keys) {
        if (key !== 'projectContacts') {
          this.$set(this.userFormInput, key, '')
        }
      }
    },
    setContactObjectWithId (data) {
      return {
        ...{
          contactNumber: data.contactNumber,
          emailAddress: data.emailAddress,
          firstName: data.firstName,
          lastName: data.lastName,
          jobTitle: data.jobTitle
        },
        id: new Date().getTime()
      }
    },
    updateContacts (data) {
      const newContact = this.setContactObjectWithId(data)
      this.newProjectContacts.push(newContact)
    },
    removeContact (id) {
      const index = this.newProjectContacts.findIndex(c => c.id === id)
      this.newProjectContacts.splice(index, 1)
      if (id === this.mainCardId) {
        this.resetFormInputs()
        this.mainCardId = null
      }
    },
    async checkEmailAddress () {
      this.emailIsValid = true
      if (this.userFormInput.emailAddress) {
        this.emailIsValid = await isEmailValid(this.userFormInput.emailAddress)
      }
    }
  }
}
</script>
