<template>
  <v-container class="mt-10 pa-0">
    <v-dialog
      v-model="openModal"
      max-width="100%"
      fullscreen>
      <v-card :style="`height 100vh;`">
        <v-toolbar
          :dense="isMobile"
          flat
          color="transparent"
          class="pr-3"
          :style="`border: none; position: fixed;`">
          <v-spacer></v-spacer>
          <v-btn
            class="close iframe-close"
            icon
            dark
            @click="$emit('close-modal', false)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="pa-0 ma-0"
          :style="`height: 100vh;`">
          <iframe
            :style="`width: 100%; height: 100vh; border: none;`"
            :src="iframeUrl"
            class="ma-0"></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: 'EditMyProfile',
  props: {
    openIframe: {
      type: Boolean,
      default: false
    },
    iframeUrl: {
      type: String,
      default: 'https://testmyprofile.rexelapps.com'
    }
  },
  computed: {
    openModal: {
      get () {
        return this.openIframe
      },
      set (newValue) {
        this.$emit('closeModal', false)
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },
    isTablet () {
      return this.$vuetify.breakpoint.smOnly
    }
  }
}
</script>
