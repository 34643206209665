<template>
  <v-navigation-drawer
    v-model="open"
    dark
    persistent
    touchless
    fixed
    clipped
    color="#032D2C"
    width="240"
    :class="['nav-menu', 'pb-0']"
    class="sidenav"
    app
    :style="{ top: topPosition + 'px' }">
    <user-avatar
      :identity="identity"
      :default-branch="defaultBranch">
    </user-avatar>
    <banner-switcher>
    </banner-switcher>
    <ul class="container navbar-nav">
      <li
        id="dashboard"
        :class="{ active:selectedComponent === 'dashboard' }"
        class="row nav-item"
        @click="navigateTo('dashboard', tenantParams)">
        <div class="col sidebar-link-title">
          <i
            class="pr-3 fa fa-line-chart"
            aria-hidden="true"></i>
          Dashboard
        </div>
      </li>
      <li
        v-if="identity.permissions.editApplications"
        id="creditReview"
        :class="{ active:selectedComponent === 'creditReview' }"
        class="row nav-item"
        @click="navigateTo('creditReview', tenantParams)">
        <div class="col sidebar-link-title">
          <i
            class="pr-3 fa fa-pencil-square-o"
            aria-hidden="true"></i>
          Review Credit Apps
        </div>
      </li>
      <li
        v-if="showCashAccount()"
        id="cashAccount"
        :class="{ active:selectedComponent === 'adminCashAccount' }"
        class="row nav-item"
        @click="navigateTo('adminCashAccount', tenantParams)">
        <div class="col sidebar-link-title">
          <i
            class="pr-3 fa fa-pencil-square-o"
            aria-hidden="true"></i>
          Cash Account
        </div>
      </li>
      <li
        v-if="tenantName !== 'platt' && identity.permissions.isInstantCreditLinkViewer && !identity.permissions.isResUser && tenantName !== 'rexelenergy'"
        id="instantCredit"
        :class="{ active:selectedComponent === 'adminInstantCredit' }"
        class="row nav-item"
        @click="navigateTo('adminInstantCredit', tenantParams)">
        <div class="col sidebar-link-title">
          <i
            class="pr-3 fa fa-pencil-square-o"
            aria-hidden="true"></i>
          Instant Credit
        </div>
      </li>
      <li
        v-if="identity != null && isRexelOrGexpro"
        id="creditApplication"
        :class="{ active:selectedComponent === 'adminApplication' }"
        class="row nav-item"
        @click="navigateTo('adminApplication', tenantParams)">
        <div class="col sidebar-link-title">
          <i
            class="pr-3 fa fa-pencil-square-o"
            aria-hidden="true"></i>
          Email Credit App
        </div>
      </li>
      <li
        v-if="showJobAccount()"
        id="jobAccount"
        :class="{ active:selectedComponent === 'adminJobAccount' }"
        class="row nav-item"
        @click="navigateTo('adminJobAccount', tenantParams)">
        <div class="col sidebar-link-title">
          <i
            class="pr-3 fa fa-pencil-square-o"
            aria-hidden="true"></i>
          Job Account
        </div>
      </li>
      <li
        v-if="tenantName === 'platt' && identity.permissions.editApplications"
        id="taxCertList"
        :class="{ active:selectedComponent === 'taxCertList', tenantParams }"
        class="row nav-item"
        @click="navigateTo('taxCertList')">
        <div class="col sidebar-link-title">
          <i
            class="pr-3 fa fa-pencil-square-o"
            aria-hidden="true"></i>
          Tax Certificates
        </div>
      </li>
    </ul>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import UserAvatar from './UserAvatar.vue'
import BannerSwitcher from './BannerSwitcher.vue'

export default {
  name: 'NavLayout',
  components: {
    BannerSwitcher,
    UserAvatar
  },
  props: {
    tenantName: {
      type: String,
      required: true
    },
    openNav: {
      type: Boolean,
      required: true,
      default: false
    },
    topPosition: {
      type: Number,
      required: true,
      default: 56
    }
  },
  data () {
    return {
      open: this.$vuetify.breakpoint.mdAndUp
    }
  },
  computed: {
    ...mapState([
      'identity',
      'defaultBranch',
      'appConfig'
    ]),
    ...mapState('applicationForm', ['isFormCompleted']),
    ...mapGetters([
      'banners',
      'isProductionEnvironment'
    ]),
    selectedComponent () {
      return this.$route.name
    },
    tenantParams () {
      return this.tenantName ? { tenant: this.tenantName } : undefined
    },
    isRexelOrGexpro () {
      return this.tenantName === 'rexel' || this.tenantName === 'gexpro'
    }
  },
  watch: {
    openNav: {
      immediate: true,
      handler (val) {
        this.open = val
      }
    },
    open (val) {
      if (!val) this.$emit('toggleSidebar')
    }
  },
  methods: {
    navigateTo (componentName, params = {}) {
      if (this.selectedComponent !== componentName) {
        this.$router.push({ name: componentName, params })
      } else if (this.isFormCompleted) {
        this.$router.go()
      }
    },
    showJobAccount () {
      // Rexel Energy users don't get Job Account for their banner
      if (this.identity.permissions.isResUser && this.tenantName === 'rexelenergy') {
        return false
      }

      return true
    },
    showCashAccount () {
      // Only visible to Rexel/Gexpro
      if (this.identity.permissions.isResUser || ['rexelenergy', 'platt'].includes(this.tenantName)) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
:root {
  --side-bar-transition-time: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.sidenav {
  z-index: 11;
  ul {
    padding: 0;
  }
  .row {
    color: white;
    font-family: "Roboto", sans-serif;
    line-height: 1.5;
    align-items: center;
    padding: 0 16px 0 24px;
    height: 48px;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .nav-item {
    &:hover {
      background-color: #084845;
      cursor: pointer;
    }
  }
  .active {
    background-color: #084845;
  }
}
.sidebar-link-title {
  font-size:12px;
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-container {
  transition: var(--side-bar-transition-time);
}

</style>
