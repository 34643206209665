<template>
  <span>
    <span v-if="data.matches === null || data.matches === 0">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            color="black"
            v-on="on">
            mdi-minus
          </v-icon>
        </template>
        <span v-if="data.matches === null">
          Match not yet determined
        </span>
        <span v-else>
          No match found
        </span>
      </v-tooltip>
    </span>
    <span v-else-if="data.matches === 1">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            color="green"
            v-on="on">
            mdi-check
          </v-icon>
        </template>
        <span>A direct match was found</span>
      </v-tooltip>
    </span>
    <span v-else-if="data.matches > 1">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            color="orange"
            v-on="on">
            mdi-alert
          </v-icon>
        </template>
        <span>Multiple matches were returned</span>
      </v-tooltip>
    </span>
  </span>
</template>
<script type="text/javascript">
const RapidCreditTagComponent = {
  props: ['data'],
  name: 'matches'
}
export default RapidCreditTagComponent
</script>
