<template>
  <div>
    <div class="row">
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.name"
        :value="item.name"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.account"
        :value="item.account"></FormItemCollectionItemLabelAndValue>
    </div>
    <div class="row">
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.fax"
        :value="item.fax"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.email"
        :value="item.email"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.phone"
        :value="item.phone"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.streetAddress"
        :value="item.streetAddress"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.addressUnitNumber"
        :value="item.addressUnitNumber"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.city"
        :value="item.city"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.country"
        :value="item.country"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.state"
        :value="item.state"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.postalCode"
        :value="item.postalCode"></FormItemCollectionItemLabelAndValue>
    </div>
  </div>
</template>

<script>
import FormItemCollectionItemLabelAndValue from '@/components/form/FormItemCollectionItemLabelAndValue.vue'

export default {
  name: 'FormItemTradeReference',
  components: {
    FormItemCollectionItemLabelAndValue
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    }
  }
}
</script>
