<template>
  <div>
    <fieldset>
      <FormSectionCard title="Account Information">
        <v-row>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            :lg="isInternal() ? '10' : '4'">
            <FormDropdown
              v-model="businessInfoInput.hasAccount"
              persist-label
              :options="hasAccountOptions"
              :vuelidate="validations.businessInfo.hasAccount"
              :show-value="true"
              :disabled="createdFromInternal || sfTokenPresent"
              :input-label="hasAccountLabel"></FormDropdown>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="6">
            <!-- Rexel accountNumber 29326 has branch 2813 -->
            <!-- Platt accountNumber 919160 has branch 141 -->
            <FormTextInput
              v-if="businessInfoInput.hasAccount === 'true'"
              v-model="businessInfoInput.accountNumber"
              persist-label
              :vuelidate="validations.businessInfo.accountNumber"
              :input-label="businessInfo.labels.accountNumber"
              :disabled="sfTokenPresent"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>
      </FormSectionCard>
      <FormSectionCard
        v-if="showBranchSelector()"
        title="Branch Information">
        <branch-selector
          class="mt-3"
          :passed-branch="branch"
          :read-only="(!isInternal() && createdFromInternal) || (branch !== null && businessInfoInput.hasAccount === 'true')"
          :is-required="isInternal()"
          @select="setBranch">
        </branch-selector>
      </FormSectionCard>

      <!-- Not Visible to Customer - Platt -->
      <FormSectionCard
        v-if="bannerId === 1 && isInternal()"
        title="Not Visible to Customer">
        <!-- FE TODO: When FormInputDropdown is disabled there are errors on the console.
             When these values are read onoy should they not be in these controls? -->
        <v-row>
          <div
            v-if="businessInfoInput.hasAccount !== 'true'"
            class="col-md-6">
            <FormTextInput
              v-model="businessInfoInput.internalCompanyName"
              persist-label
              :vuelidate="isInternal() ? validations.businessInfo.internalCompanyName : null"
              :input-label="businessInfo.labels.internalCompanyName"
              input-type="text"></FormTextInput>
          </div>
        </v-row>
        <v-row>
          <div
            v-if="businessInfoInput.hasAccount !== 'true'"
            class="col-md-3 mr-2">
            <FormInputDropdown
              v-model="businessInfoInput.acquiredAccountFrom"
              filled
              inline
              :disabled="businessInfoInput.hasAccount === 'true'"
              :input-label="businessInfo.labels.acquiredAccountFrom"
              :options="acquiredAccountFromOptions"
              :show-value="true">
            </FormInputDropdown>
          </div>
          <div
            class="col-md-4 mr-2">
            <FormInputDropdown
              v-model="businessInfoInput.picCodeCategory"
              filled
              inline
              :disabled="businessInfoInput.hasAccount === 'true'"
              :input-label="businessInfo.labels.picCodeCategory"
              :options="customerTypePICCodeCategories"
              :show-value="true">
            </FormInputDropdown>
          </div>
          <div
            class="col-md-4">
            <FormInputDropdown
              v-model="businessInfoInput.picCode"
              filled
              inline
              :disabled="businessInfoInput.hasAccount === 'true'"
              :input-label="businessInfo.labels.picCode"
              :options="customerTypePICCodes"
              :show-value="true">
            </FormInputDropdown>
          </div>
        </v-row>
      </FormSectionCard>

      <!-- Not Visible to Customer -  Rexel/Gexpro -->
      <FormSectionCard
        v-if="bannerId !== 1 && isInternal()"
        title="Not Visible to Customer">
        <!-- FE TODO: When FormInputCombobox or FormInputDropdown are disabled there are errors on the console.
             When these values are read onoy should they not be in these controls? -->
        <v-row>
          <div class="col-md-6">
            <FormTextInput
              v-model="businessInfoInput.internalCompanyName"
              :vuelidate="isInternal() ? validations.businessInfo.internalCompanyName : null"
              persist-label
              :input-label="businessInfo.labels.internalCompanyName"
              input-type="text"></FormTextInput>
          </div>
        </v-row>
        <v-row v-if="isInternal() && bannerId !== 1">
          <div class="col-md-8">
            <span>Salespeople </span><em>(at least one is required)</em><span
              class="ml-1"
              style="color:red;">
              *
            </span>
            <div>
              <v-divider class="my-0"></v-divider>
            </div>
          </div>
        </v-row>
        <v-row>
          <div class="col-md-4">
            <FormInputCombobox
              v-model="businessInfoInput.insideSalesPerson"
              filled
              inline
              :hide-required="true"
              :vuelidate="isInternal() && bannerId !== 1 ? validations.businessInfo.insideSalesPerson : null"
              :input-label="businessInfo.labels.insideSalesPersonEmail"
              :async-search-handler="getSalesPersons"
              :items-mapper="mapSalesPersonsResultsToComboboxOptions">
            </FormInputCombobox>
          </div>
          <div class="col-md-4">
            <FormInputCombobox
              v-model="businessInfoInput.outsideSalesPerson"
              filled
              inline
              :hide-required="true"
              :vuelidate="isInternal() && bannerId !== 1 ? validations.businessInfo.outsideSalesPerson : null"
              :input-label="businessInfo.labels.outsideSalesPersonEmail"
              :async-search-handler="getSalesPersons"
              :items-mapper="mapSalesPersonsResultsToComboboxOptions">
            </FormInputCombobox>
          </div>
        </v-row>
        <v-row>
          <div
            v-if="businessInfoInput.hasAccount !== 'true'"
            class="col-md-3 mr-2">
            <FormInputDropdown
              v-model="businessInfoInput.acquiredAccountFrom"
              filled
              inline
              :disabled="businessInfoInput.hasAccount === 'true'"
              :input-label="businessInfo.labels.acquiredAccountFrom"
              :options="acquiredAccountFromOptions"
              :show-value="true">
            </FormInputDropdown>
          </div>
          <div class="col-md-4">
            <FormDropdown
              v-model="businessInfoInput.customerTypeCategory"
              :vuelidate="isInternal() && bannerId !== 1 ? validations.businessInfo.customerTypeCategory : null"
              :input-label="businessInfo.labels.customerTypeCategory"
              persist-label
              :options="rexelCustomerSegmentOptions"
              :show-value="true">
            </FormDropdown>
          </div>
          <div class="col-md-3">
            <FormInputDropdown
              v-model="businessInfoInput.customerPriceClass"
              :vuelidate="isInternal() && bannerId !== 1 ? validations.businessInfo.customerPriceClass : null"
              inline
              filled
              :input-label="businessInfo.labels.customerPriceClass"
              :options="customerPriceClassOptions"
              :show-value="true">
            </FormInputDropdown>
          </div>
        </v-row>
      </FormSectionCard>

      <FormSectionCard title="Business Information">
        <v-row>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-if="renderStep"
              v-model="businessInfoInput.companyName"
              persist-label
              :vuelidate="validations.businessInfo.companyName"
              :input-label="businessInfo.labels.companyName"
              :error-messages="companyNameMaxLengthErrorMessage"
              input-type="text"></FormTextInput>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-model="businessInfoInput.parentCompanyName"
              persist-label
              :vuelidate="validations.businessInfo.parentCompanyName"
              :input-label="businessInfo.labels.parentCompanyName"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            class="pb-4">
            <FormTextInput
              v-model="businessInfoInput.email"
              persist-label
              :vuelidate="validations.businessInfo.email"
              :input-label="businessInfo.labels.email"
              input-type="email"
              :error-messages="isInternal() && !validations.businessInfo.email.bannerEmailRule ? [`Emails with our company's domain are not allowed`] : null">
            </FormTextInput>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            class="pb-4">
            <FormTextInput
              v-model="businessInfoInput.phone"
              persist-label
              :vuelidate="validations.businessInfo.phone"
              :input-label="businessInfo.labels.phone"
              input-type="tel"></FormTextInput>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            class="pb-4">
            <FormTextInput
              v-model="businessInfoInput.fax"
              persist-label
              :vuelidate="validations.businessInfo.fax"
              :input-label="businessInfo.labels.fax"
              input-type="tel"></FormTextInput>
          </v-col>
        </v-row>
      </FormSectionCard>
      <FormSectionCard :title="verbiageObject.headerTitle">
        <FormGroupAddressInfo
          v-model="billingInfoInput"
          persist-label
          :vuelidate="validations.billingInfo"
          :form-labels="verbiageObject.billingInfo"
          :form-data="billingInfo.data"
          :country="billingInfoInput.country"
          @address-input="updateShipping">
          <template v-slot:AddressDropDown>
            <FormDropdown
              v-model="billingInfoInput.country"
              persist-label
              :vuelidate="validations.billingInfo.country"
              :input-label="billingInfo.labels.country"
              :options="$store.getters.countries"
              :show-value="true"
              :on-change="getStates"></FormDropdown>
          </template>
        </FormGroupAddressInfo>
      </FormSectionCard>
      <FormSectionCard :title="verbiageObject.addressTitle">
        <v-checkbox
          v-model="localShippingSameAsBilling"
          color="#004168"
          class="billing-checkbox">
          <span
            slot="label"
            class="checkbox--label">
            {{ bannerId === 4 ? 'Same as Business' : 'Same as Billing' }}
          </span>
        </v-checkbox>
        <FormGroupAddressInfo
          v-show="!billingSameAsShipping"
          v-model="billingInfoInput"
          persist-label
          :vuelidate="validations.billingInfo"
          :form-labels="verbiageObject.billingInfo"
          :form-data="billingInfo.data"
          :country="billingInfoInput.shippingCountry"
          :field-names="{
            country: 'shippingCountry',
            streetAddress: 'shippingStreetAddress',
            addressUnitNumber: 'shippingAddressUnitNumber',
            city: 'shippingCity',
            state: 'shippingState',
            postalCode: 'shippingPostalCode'
          }">
          <template v-slot:AddressDropDown>
            <FormDropdown
              v-model="billingInfoInput.shippingCountry"
              persist-label
              :vuelidate="validations.billingInfo.shippingCountry"
              :input-label="billingInfo.labels.shippingCountry"
              :options="$store.getters.countries"
              :show-value="true"
              :on-change="getStates"></FormDropdown>
          </template>
        </FormGroupAddressInfo>
      </FormSectionCard>
      <FormSectionCard>
        <v-row>
          <v-col class="pb-0">
            <FormInputRadioOptions
              v-model="billingInfoInput.businessType"
              :vuelidate="validations.billingInfo.businessType"
              :input-label="billingInfo.labels.businessType"
              :options="[
                {
                  key: 'Proprietorship',
                  value: 'Proprietorship',
                },
                {
                  key: 'Partnership',
                  value: 'Partnership',
                },
                {
                  key: 'Corporation',
                  value: 'Corporation',
                },
                {
                  key: 'Government',
                  value: 'Government',
                },
                {
                  key: 'Other',
                  value: 'Other',
                }
              ]"></FormInputRadioOptions>
          </v-col>
        </v-row>
        <v-row v-show="billingInfoInput.businessType === 'Other'">
          <v-col
            cols="12"
            lg="8"
            class="pb-4">
            <FormTextInput
              v-model="billingInfoInput.businessTypeLabel"
              :vuelidate="validations.billingInfo.businessTypeLabel"
              :input-label="billingInfo.labels.businessTypeLabel"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>
      </FormSectionCard>
      <FormSectionCard>
        <v-row>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-model="billingInfoInput.contractorLicenceNumber"
              persist-label
              :vuelidate="validations.billingInfo.contractorLicenceNumber"
              :input-label="billingInfo.labels.contractorLicenceNumber"
              input-type="text"></FormTextInput>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-model="billingInfoInput.dunsNumber"
              persist-label
              :vuelidate="validations.billingInfo.dunsNumber"
              :input-label="billingInfo.labels.dunsNumber"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="!rexelEnergy"
            cols="12"
            lg="6"
            class="pb-4">
            <FormCurrencyInput
              v-model="billingInfoInput.monthlyIncomeEstimate"
              :vuelidate="validations.billingInfo.monthlyIncomeEstimate"
              :input-label="billingInfo.labels.monthlyIncomeEstimate"></FormCurrencyInput>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormCurrencyInput
              v-model="billingInfoInput.monthlyPurchasesEstimate"
              :vuelidate="validations.billingInfo.monthlyPurchasesEstimate"
              :input-label="verbiageObject.estimatedMonthlyPurchases"></FormCurrencyInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormDropdown
              v-model="billingInfoInput.incorporatedCountry"
              persist-label
              :vuelidate="validations.billingInfo.incorporatedCountry"
              :input-label="billingInfo.labels.incorporatedCountry"
              :show-value="true"
              :options="$store.getters.countries"
              :on-change="getStates"
              @input="onCountryChange"></FormDropdown>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormDropdown
              v-if="billingInfoInput.incorporatedCountry === 'US'"
              v-model="billingInfoInput.incorporatedState"
              persist-label
              :vuelidate="validations.billingInfo.incorporatedState"
              :input-label="billingInfo.labels.incorporatedState"
              :options="states"
              :show-value="true"></FormDropdown>
            <FormTextInput
              v-else
              v-model="billingInfoInput.incorporatedState"
              persist-label
              :vuelidate="validations.billingInfo.incorporatedState"
              :input-label="billingInfo.labels.incorporatedState"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-model="billingInfoInput.yearEstablished"
              persist-label
              :vuelidate="validations.billingInfo.yearEstablished"
              :input-label="billingInfo.labels.yearEstablished"
              max-length="4"
              input-type="text"></FormTextInput>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-model="billingInfoInput.preferredSalesPerson"
              persist-label
              :vuelidate="validations.billingInfo.preferredSalesPerson"
              :input-label="billingInfo.labels.preferredSalesPerson"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>

        <v-row v-if="bannerId !== 1 && isCustomerPriceEmpty && isCustomerTypeEmpty">
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormDropdown
              v-model="billingInfoInput.sectorType"
              persist-label
              :vuelidate="validations.billingInfo.sectorType"
              :input-label="billingInfo.labels.sectorType"
              :options="sectorTypeOptions"
              :show-value="true"></FormDropdown>
          </v-col>

          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormDropdown
              v-model="billingInfoInput.employeeCount"
              persist-label
              :vuelidate="validations.billingInfo.employeeCount"
              :input-label="billingInfo.labels.employeeCount"
              :options="employeeCountOptions"
              :show-value="true"></FormDropdown>
          </v-col>
        </v-row>
         <v-row v-else>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-model="billingInfoInput.businessIndustry"
              persist-label
              :vuelidate="validations.billingInfo.businessIndustry"
              :input-label="verbiageObject.businessIndustry"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormInputRadioOptions
              v-model="billingInfoInput.isHaveFederalTaxId"
              :vuelidate="validations.billingInfo.isHaveFederalTaxId"
              :input-label="billingInfo.labels.isHaveFederalTaxId"
              :options="[
                {
                  key: 'Yes',
                  value: 'Yes',
                },
                {
                  key: 'No',
                  value: 'No',
                },
              ]"></FormInputRadioOptions>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pb-4">
            <FormTextInput
              v-if="billingInfoInput.isHaveFederalTaxId === 'Yes'"
              v-model="billingInfoInput.federalTaxId"
              :vuelidate="validations.billingInfo.federalTaxId"
              :input-label="billingInfo.labels.federalTaxId"
              input-type="text"></FormTextInput>
          </v-col>
        </v-row>
      </FormSectionCard>
    </fieldset>
  </div>
</template>

<script>
import axios from 'ca-http-service'
import FormSectionCard from '@/components/form/FormSectionCard.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptionsV2.vue'
import FormGroupAddressInfo from '@/components/form/FormGroupAddressInfo.vue'
import FormDropdown from '@/components/form/FormDropdown.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormCurrencyInput from '@/components/form/FormCurrencyInput.vue'
import FormInputCombobox from '@/components/form/FormInputCombobox.vue'
import FormInputDropdown from '@/components/form/FormInputDropdown.vue'

import {
  getCustomerTypeOptions,
  getCustomerTypeOptionsForRexelGexpro,
  getCustomerClassAndPriceClassCrossReferenceForRexelGexpro,
  getAcquiredAccountFromOptions
} from '@/api/company/customer-type'

import {
  getSalesPersonsAsync,
  getSalesPersonByEmailAsync,
  mapSalesPersonsResultsToComboboxOptions,
  displaySelectedSalesPersonInCombobox
} from '@/api/company/sales-person-search'

import {
  getCustomerAccountInfo
} from '@/api/company/account-info'

import { getBranchesAsync } from '@/api/company/branch-info'
import BranchSelector from '@/components/BranchSelector.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Step1Details',
  components: {
    FormSectionCard,
    FormDropdown,
    FormInputRadioOptions,
    FormGroupAddressInfo,
    FormTextInput,
    FormCurrencyInput,
    FormInputDropdown,
    FormInputCombobox,
    BranchSelector
  },
  props: {
    groupData: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      states: [],
      businessInfoInput: new this.groupData.businessInfo.dataClass(), // eslint-disable-line new-cap
      billingInfoInput: new this.groupData.billingInfo.dataClass(), // eslint-disable-line new-cap
      businessInfo: this.groupData.businessInfo,
      billingInfo: this.groupData.billingInfo,
      renderStep: false,
      bannerId: null,
      acquiredAccountFromOptions: [],
      customerTypePICCodeCategories: [],
      customerTypePICCodes: [],
      customerTypeOptions: [],
      customerPriceClassOptions: [],
      typeOptions: [],
      rexelCustomerSegmentOptions: [],
      mapSalesPersonsResultsToComboboxOptions,
      allRexelCustomerClassPriceClasses: [],
      branch: null,
      localShippingSameAsBilling: false,
      hasAccountOptionsInternal: [
        { key: 'true', value: 'Yes, customer has a COD account and would like to convert to a terms accounts' },
        { key: 'false', value: 'No, customer has never had an account with us before' }
      ],
      hasAccountOptionsExternal: [
        { key: 'true', value: 'Yes' },
        { key: 'false', value: 'No' }
      ],
      sectorTypeOptions: [
        'OEM (Original Equipment Mfr)',
        'MRO (Maintenance/Repair/Operations)',
        'Automation (Non-MRO/OEM)',
        'Commercial Contractor',
        'Residential Contractor',
        'Industrial Contractor',
        'Specialty Contractor (e.g. Solar)',
        'Commercial End User (e.g. Hospital/Building Maint)',
        'Public Sector (e.g. Municipality)',
        'Miscellaneous and Others (e.g. Retail Customer)'
      ],
      employeeCountOptions: ['1 to 5', '6 to 20', '> 20']
    }
  },
  computed: {
    ...mapGetters([
      'company',
      'getBannerByName',
      'webUser'
    ]),
    ...mapState('applicationForm', ['createdFromInternal']),
    rexelEnergy () {
      return this.company === 'RexelEnergy' || this.$route.params.tenant === 'rexelenergy'
    },
    verbiageObject () {
      if (this.rexelEnergy) {
        return {
          headerTitle: 'Business Address',
          checkboxText: 'Business Address is same from mailing address',
          addressTitle: 'Mailing Address',
          estimatedMonthlyPurchases: 'Estimated Monthly Purchases',
          businessIndustry: 'Type of Business',
          billingInfo: {
            ...this.billingInfo.labels,
            streetAddress: 'Business Address',
            shippingStreetAddress: ' Mailing Address'
          }
        }
      } else {
        return {
          headerTitle: 'Billing Address',
          checkboxText: 'Billing Address is same from shipping address',
          addressTitle: 'Shipping Address',
          estimatedMonthlyPurchases: 'Anticipated Monthly Purchases',
          businessIndustry: 'Business Industry',
          billingInfo: {
            ...this.billingInfo.labels
          }
        }
      }
    },
    hasAccountLabel () {
      const banner = this.getBannerByName(this.$route.params.tenant)
      const displayName = banner.name === 'Platt' ? banner.name : banner.displayName
      return `Do you have a ${displayName} account?`
    },
    billingSameAsShipping () {
      return this.billingInfoInput.isBillingAddressSameAsShipping === '1'
    },
    sfTokenPresent () {
      return this.$route.query.sftoken !== undefined && this.$route.query.sftoken.length > 0
    },
    hasAccountOptions () {
      if (this.isInternal()) {
        return this.hasAccountOptionsInternal
      } else {
        return this.hasAccountOptionsExternal
      }
    },
    companyNameMaxLengthErrorMessage () {
      return this.businessInfoInput.companyName.length > this.validations.businessInfo.companyName.$params.maxLength.max
        ? [`Name of Company is limited to ${this.validations.businessInfo.companyName.$params.maxLength.max} characters`]
        : null
    },
    isCustomerPriceEmpty () {
      return this.businessInfoInput.customerPriceClass === null || this.businessInfoInput.customerPriceClass === ''
    },
    isCustomerTypeEmpty () {
      return this.businessInfoInput.customerTypeCategory === null || this.businessInfoInput.customerTypeCategory === ''
    }
  },
  watch: {
    'businessInfoInput.hasAccount': {
      deep: true,
      handler: function (newV, oldV) {
        // console.log(`Step1 watch hasAccount newV=${newV}, oldV=${oldV}`)

        // If user changes their mind: hasAccount Yes -> No then reset these fields
        if (newV === 'false' && oldV === 'true') {
          this.branch = null

          this.businessInfoInput.branchNumber = ''
          this.businessInfoInput.accountNumber = ''
          this.businessInfoInput.companyName = ''
          this.businessInfoInput.email = ''
          this.businessInfoInput.fax = ''
          this.businessInfoInput.phone = ''
          this.businessInfoInput.acquiredAccountFrom = ''
          this.businessInfoInput.picCodeCategory = ''
          this.businessInfoInput.customerTypeCategory = ''
          this.businessInfoInput.customerPriceClass = ''
          this.billingInfoInput.streetAddress = ''
          this.billingInfoInput.state = ''
          this.billingInfoInput.country = ''
          this.billingInfoInput.postalCode = ''
          this.billingInfoInput.city = ''
          this.billingInfoInput.addressUnitNumber = ''
          this.billingInfoInput.shippingStreetAddress = ''
          this.billingInfoInput.shippingAddressUnitNumber = ''
          this.billingInfoInput.shippingCity = ''
          this.billingInfoInput.shippingState = ''
          this.billingInfoInput.shippingPostalCode = ''
          this.billingInfoInput.shippingCountry = ''
          this.billingInfoInput.isBillingAddressSameAsShipping = '0'
        }
        if (newV === 'true' && oldV === 'false') {
          this.businessInfoInput.acquiredAccountFrom = ''
          this.businessInfoInput.picCodeCategory = ''
          this.businessInfoInput.customerTypeCategory = ''
          this.businessInfoInput.customerPriceClass = ''
        }
        // This allows subsequent steps (2->) to know about hasAccount
        this.$store.dispatch('applicationForm/hasAccount', newV)
      }
    },
    'businessInfoInput.picCodeCategory': {
      async handler (newVal) {
        await this.updatePICCodeOptions(newVal)
      }
    },
    'businessInfoInput.customerTypeCategory': {
      async handler (newVal, oldVal) {
        if (newVal) {
          if (this.businessInfoInput.hasAccount !== 'true' && this.isInternal()) {
            this.businessInfoInput.customerPriceClass = '' // Customer Segment was changed - reset Price Class
          }
          await this.updateRexelCustomerPriceClassOptions(newVal)
        }
      }
    },
    'businessInfoInput.branchNumber': {
      async handler (newVal, oldVal) {
        if (newVal) {
          this.branch = null
          // console.log(`businessInfoInput.branchNumber newVal=${JSON.stringify(newVal)}, oldVal=${JSON.stringify(oldVal)}`)
          let branches = []
          if (this.bannerId === 1) {
            branches = await getBranchesAsync(this.bannerId)
          } else {
            branches = [...await getBranchesAsync(2), ...await getBranchesAsync(3)]
          }
          this.branch = branches.find(branch => branch.branchNumber === newVal)
        }
      }
    },
    'billingInfoInput.isBillingAddressSameAsShipping': {
      handler (newVal, oldVal) {
        this.localShippingSameAsBilling = newVal === '1'
      }
    },
    'businessInfoInput.insideSalesPerson': {
      handler (newVal) {
        if (newVal) {
          if (typeof newVal === 'object') {
            this.businessInfoInput.insideSalesPerson = displaySelectedSalesPersonInCombobox(newVal)
          }
        }
      }
    },
    'businessInfoInput.outsideSalesPerson': {
      handler (newVal) {
        if (newVal) {
          if (typeof newVal === 'object') {
            this.businessInfoInput.outsideSalesPerson = displaySelectedSalesPersonInCombobox(newVal)
          }
        }
      }
    },
    localShippingSameAsBilling: {
      handler (newV, oldV) {
        if (newV) {
          this.billingInfoInput.isBillingAddressSameAsShipping = '1'
        } else {
          this.billingInfoInput.isBillingAddressSameAsShipping = '0'
        }
      }
    },
    webUser: {
      async handler (newVal) {
        // This handler is for the case when a sftoken is supplied for an external app
        // TODO When WebUser.customerNumber is null get Customer Account Info by WebUser.webUserId
        if (newVal && !this.createdFromInternal && this.$route.query.sftoken && newVal.customerNumber) {
          this.businessInfoInput.accountNumber = newVal.customerNumber
          await getCustomerAccountInfo(newVal.customerNumber, this.$route.params.tenant, this.$route.query.sftoken)
            .then(businessInfo => {
              this.businessInfoInput.companyName = businessInfo.companyName || ''
              this.businessInfoInput.email = businessInfo.email || ''
              this.businessInfoInput.phone = businessInfo.phone || ''
              this.businessInfoInput.fax = businessInfo.fax || ''
              if (businessInfo.country) {
                this.billingInfoInput.country = businessInfo.country || ''
              }
              this.billingInfoInput.streetAddress = businessInfo.streetAddress || ''
              this.billingInfoInput.addressUnitNumber = businessInfo.addressUnitNumber || ''
              this.billingInfoInput.postalCode = businessInfo.postalCode || ''
              this.billingInfoInput.city = businessInfo.city || ''
              this.billingInfoInput.state = businessInfo.state || ''
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
            })
        }
      }
    }
  },
  async created () {
    this.states = await this.getStates(this.billingInfoInput.incorporatedCountry)
    this.businessInfoInput = this.businessInfo.data
    this.billingInfoInput = this.billingInfo.data
    this.localShippingSameAsBilling = this.billingInfoInput.isBillingAddressSameAsShipping === '1'
  },
  async mounted () {
    this.renderStep = true
    const bannerName = this.$route.params.tenant
    this.tenant = bannerName
    this.bannerId = this.$store.getters.getBannerIdByName(bannerName)
    if (this.rexelEnergy) {
      this.updateToRexelEnergyLabels()
    }

    this.acquiredAccountFromOptions = getAcquiredAccountFromOptions()

    if (this.isInternal()) {
      await this.initializeCustomerTypeLists() // Only call this if internal. -- since CashAccountOptions/getCustomerTypeOptions is allowanonymous, and should be authorize
      if (this.bannerId === 1) {
        // on load, we need to make sure that customer type lists are initialized before selecting a pic code option
        await this.updatePICCodeOptions(this.businessInfoInput.picCodeCategory)
      }
    }
    if (this.$route.query.sftoken) {
      this.businessInfoInput.hasAccount = 'true'
    }
  },
  methods: {
    showBranchSelector () {
      // Account Numbers: 32807, 29326(Rexel) | 919160(Platt)
      if (this.rexelEnergy) {
        return false
      }
      if (this.isInternal()) {
        if (!this.businessInfoInput.hasAccount || this.businessInfoInput.hasAccount === '') {
          return false
        } else if (this.businessInfoInput.hasAccount === 'false') {
          return true
        } else if (this.businessInfoInput.hasAccount === 'true' && !this.businessInfoInput.companyName) {
          return false
        } else {
          return true
        }
      } else {
        return this.businessInfoInput.hasAccount === 'false'
      }
    },
    async onCountryChange () {
      this.states = await this.getStates(this.billingInfoInput.incorporatedCountry)
    },
    updateShipping () {
      if (this.billingSameAsShipping) {
        this.billingInfoInput
          .shippingStreetAddress = this.billingInfoInput.streetAddress
        this.billingInfoInput
          .shippingAddressUnitNumber = this.billingInfoInput.addressUnitNumber
        this.billingInfoInput
          .shippingCity = this.billingInfoInput.city
        this.billingInfoInput
          .shippingCountry = this.billingInfoInput.country
        this.billingInfoInput
          .shippingState = this.billingInfoInput.state
        this.billingInfoInput
          .shippingPostalCode = this.billingInfoInput.postalCode
      }
    },
    async getStates (country) {
      return axios.get('/generic/getStateList', {
        params: {
          countryCode: country
        }
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return []
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    setBranch (branchNumber) {
      this.businessInfoInput.branchNumber = branchNumber || ''
    },
    isInternal () {
      return this.$route.meta.isInternalForm === true
    },

    updateToRexelEnergyLabels () {
      this.billingInfo.labels.streetAddress = 'Business Address'
      this.billingInfo.labels.shippingStreetAddress = 'Mailing Address'
    },
    async updatePICCodeOptions (category) {
      // console.log(`updatePICCodeOptions() category=${category}`)
      const remappedPICCodes = []
      const picCodes = [...this.customerTypeOptions.filter(o => {
        if (this.bannerId === 1) { // platt
          // get all options where currently selected category matches first char of key
          if (o.key !== category) {
            return o.key[0] === category
          } else {
            return false
          }
        } else { // for non-platt
          // get all options where currently selected category is same as value in options
          return o.value === category
        }
      }
      )]

      picCodes.forEach(o => {
        // for platt we need the value, non-platt we just need the key
        remappedPICCodes.push({ key: o.key, value: this.bannerId === 1 ? o.value : o.key })
      })

      this.customerTypePICCodes.length = 0 // empty array before adding new options
      this.customerTypePICCodes = [...remappedPICCodes]
    },
    updateRexelCustomerPriceClassOptions (customerSegmentKey) {
      if (!customerSegmentKey || customerSegmentKey === '' || this.allRexelCustomerClassPriceClasses.length === 0) {
        return
      }
      const custClassPriceClasses = this.allRexelCustomerClassPriceClasses.find(custClass => custClass.custClassCode === customerSegmentKey).priceClasses
      const priceClasses = []
      custClassPriceClasses.forEach(pc => {
        priceClasses.push({
          key: pc,
          value: pc
        })
      })
      this.customerPriceClassOptions = priceClasses
    },
    async initializeCustomerTypeLists () {
      // reset options before populating w/ retrieved data
      this.customerTypePICCodeCategories.length = 0
      this.customerTypePICCodes.length = 0

      const categories = []
      if (this.bannerId === 1) { // platt banner
        this.customerTypeOptions = await getCustomerTypeOptions(this.bannerId)
        const options = this.customerTypeOptions
        options.forEach(o => {
          // for platt data: categories are mixed in w/ pic codes, and will only have one char
          if (o.key.length === 1) {
            categories.push(o)
          }
        })
        this.customerTypePICCodeCategories = [...categories]
      } else {
        // Rexel, Gexpro: Customer Segment Options for New Customer with default custClass(customerType)
        const customerPriceClassRexel = await getCustomerTypeOptionsForRexelGexpro()
        this.rexelCustomerSegmentOptions = customerPriceClassRexel
          .map(option => {
            return {
              key: option.customerType,
              value: option.title
            }
          })

        //  Rexel, Gexpro: Used for looking up existing Customer Account Customer Class and Price Classes
        this.allRexelCustomerClassPriceClasses = await getCustomerClassAndPriceClassCrossReferenceForRexelGexpro()
      }
    },
    async getSalesPersons (value) {
      // only used in not visible to customer searcion.
      if (this.isInternal()) {
        return await getSalesPersonsAsync(value, this.tenant)
      }
    },
    async getSalesPersonByEmail (value) {
      if (this.isInternal()) {
        return await getSalesPersonByEmailAsync(value)
      }
    },
    getCustClassDescByCode (custClassCode) {
      if (!custClassCode || !this.allRexelCustomerClassPriceClasses) {
        return ''
      } else {
        const custClass = this.allRexelCustomerClassPriceClasses.find(code => code.custClassCode === custClassCode)
        if (custClass && custClass.priceClassDescription) {
          return custClass.priceClassDescription
        }
        return ''
      }
    },
    getSalespersonForDisplay (salesPerson) {
      if (!salesPerson) {
        return ''
      }
      if (typeof salesPerson === 'string') {
        return salesPerson
      }
      let personForDisplay = ''
      if (salesPerson.eclipseUserLogin) {
        personForDisplay += salesPerson.eclipseUserLogin
      }
      if (salesPerson.displayName) {
        personForDisplay += `- ${salesPerson.displayName || ''}`
      }
      return personForDisplay
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox--label {
  color: rgba(0,0,0,0.87);
}
</style>
