<template>
  <div>
    <slot v-if="showAddFields"></slot>
    <div
      v-if="errorMessage"
      class="error-message d-flex flex-row-reverse">
      {{ errorMessage }}
    </div>
    <div class="d-flex flex-row-reverse py-4">
      <v-btn
        v-if="showAddFields"
        tile
        :disabled="isItemInvalid"
        @click="onAdd">
        {{ btnText }}
      </v-btn>
    </div>
    <ul
      style="
        margin-left: 0;
        padding-left: 0;
      ">
      <li
        v-for="(item, index) in userFormDataCollection"
        :key="item.id">
        <v-row
          class="card"
          style="
            background-color: #EEE;
            border: 0;
            padding: 1em;
            font-size: 12px;
            margin: .4em 0;
          ">
          <div class="d-flex justify-space-between">
            <div class="item-title">
              {{ title }} {{ index + 1 }}
            </div>
            <button
              style="
                background-color: #EEE;
              "
              class="btn remove-item"
              @click="onRemove(item)">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <component
            :is="itemComponent"
            :item="item"
            :form-labels="formLabels"></component>
        </v-row>
      </li>
    </ul>
  </div>
</template>

<script>
// NOTE: couldn't get vuelidate $each collection validation working, there are some ugly hacks around this component using the collectionInfo object
export default {
  name: 'FormItemCollection',
  props: {
    isItemInvalid: {
      type: Boolean,
      required: false,
      default: false
    },
    itemComponent: {
      type: Object,
      required: true
    },
    currentUserInput: {
      type: Object,
      required: true
    },
    formDataCollection: {
      type: Array,
      required: true
    },
    formDataInstance: {
      type: Object,
      required: true
    },
    itemClass: {
      type: Function,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    btnText: {
      type: String,
      required: false,
      default: 'Add'
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    addCb: {
      type: Function,
      required: false,
      default: () => {
        console.warn('no cb passed')
      }
    },
    showAddFields: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      userFormDataCollection: this.formDataCollection
    }
  },
  methods: {
    onAdd () {
      this.userFormDataCollection.push({
        ...this.formDataInstance,
        id: new Date().getTime()
      })
      this.$emit('form-collection-add')

      this.addCb()
    },
    onRemove (item) {
      const index = this.userFormDataCollection.findIndex(i => i.id === item.id)
      this.userFormDataCollection.splice(index, 1)
      this.$emit('form-collection-remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  background-color: white;
  color: #0469a3;
  outline: 1px inset black;
  font-size: 0.64rem;
  font-weight: 600;
  border-radius: 0.125rem;
  margin: 0.375rem;
  margin-bottom: 1.6rem;
  padding: 0.5rem 1.6rem;
  top: 10px;
}
.v-btn {
  color: #fff !important;
  background-color: #004168 !important;
  font-size: 1rem !important;
  letter-spacing: normal !important;
  border-color: #004168;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: .5rem 1rem;
  transition: all .2s ease-in-out;
  &.previous {
    margin-right: 10px;
    color: black !important;
    background-color: #D8D8D8 !important;
  }
  &:hover {
    background-color: #002135 !important;
    border-color: #001b2b;
  }
}
.remove-item {
  font-size: 20px;
  -webkit-text-stroke: 2px #eee;
  width: 1em;
  margin: 0;
  padding: 0;
}
.item-title {
  font-weight: bold;
  font-size: 1.5em;
  padding-left: 2px;
}
.remove-item:hover {
  color: #999;
}
.error-message {
 color: #b40000;
}
</style>
