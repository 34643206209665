var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('FormInputGroupAddressInfo',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate,"form-labels":_vm.formLabels,"form-data":_vm.formData,"field-names":{
      country: 'country',
      streetAddress: 'streetAddress1',
      addressUnitNumber: 'streetAddress2',
      city: 'city',
      state: 'state',
      postalCode: 'zipCode'
    }},model:{value:(_vm.userFormInput),callback:function ($$v) {_vm.userFormInput=$$v},expression:"userFormInput"}}),(!_vm.isStepDisabled)?_c('form-address-validation',{attrs:{"form-data":_vm.formData,"vuelidate":_vm.vuelidate,"close-modal-from-outside":_vm.closeModal},on:{"close":function($event){_vm.closeModal = false},"updateFormData":_vm.updateAddress,"editAddress":_vm.editAddress}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }