<template>
  <div
    :style="formGroupStyles"
    class="pb-3">
    <div
      v-if="inputLabel.length > 0"
      class="form-control-label radio-lbl"
      :class="isRequired ? 'required' : ''">
      {{ inputLabel }}
    </div>
    <label
      v-for="option in options"
      :key="option.key"
      class="custom-control custom-radio">
      <input
        :disabled="disabled"
        type="radio"
        :value="option.value"
        :checked="currentSelection===option.value"
        class="custom-control-input"
        @input="updateValue($event.target.value)" />
      <span
        class="custom-control-indicator"
        :class="{isDisabled: disabled}">
      </span>
      <span
        class="custom-control-description">
        {{ option.key }}
      </span>
    </label>
  </div>
</template>

<script>
import FormInputMixin from './FormInput.mixin'

export default {
  name: 'FormInputRadioOptions',
  components: { },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    vuelidate: {
      type: [Object, null],
      required: false,
      default: null
    },
    formGroupStyles: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      currentSelection: null
    }
  },
  computed: {
    isRequired () {
      if (this.vuelidate) {
        return (this.vuelidate.required != null && this.vuelidate.$invalid === true)
      } else {
        return false
      }
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        this.currentSelection = this.value
      }
    }
  },
  mounted () {
    this.currentSelection = this.value
  },
  methods: {
    updateValue: function (value) {
      this.currentSelection = value
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.isDisabled {
  background-color: rgba(0,0,0,.26) !important
}

</style>
