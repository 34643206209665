<template>
  <div
    style="
      display: grid;
    "
    class="form-input-dropdown"
    :class="{'has-danger' : $isErrorState()}">
    <span>
      <label
        v-if="!inline"
        :class="$isRequired() && !disabled ? 'required' : ''"
        style="font-size: 12px;"
        class="form-control-label">
        {{ inputLabel }} <span v-if="loading">
          loading...
        </span>
      </label>
    </span>
    <v-select
      v-model="inputValue"
      :outlined="!filled"
      :filled="filled"
      dense
      :disabled="disabled"
      :items="options"
      :label="inline ? inputLabel : null"
      :item-text="propertyToShow"
      item-value="key"
      @change="onChange(inputValue)"
      @input="vuelidate != null ? vuelidate.$touch() : null"
      @blur="vuelidate != null ? vuelidate.$touch() : null">
      <template #label>
        <template v-if="inline">
        <span class="external-label">
          {{ inputLabel }}
        </span> <span
          v-if="$isRequired()"
          class="required-symbol">
          *
        </span>
        </template>
      </template>
    </v-select>
    <FormFieldErrorMessages :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from './FormInput.mixin'

export default {
  name: 'FormInputDropdown',
  components: {
    FormFieldErrorMessages
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    onChange: {
      type: Function,
      required: false,
      default: function () {
        // do nothing...
      }
    },
    inline: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    showDefaultOption: {
      type: Boolean,
      required: false,
      default: false
    },
    showValue: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.updateValue(newValue)
      }
    },
    propertyToShow () {
      return this.showValue ? 'value' : 'key'
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.required-symbol {
  color: red;
}
</style>
