<template>
  <div>
    <!-- Section: Business Information -->
    <template v-for="(signatory, index) in signatories">
      <h6
        :key="index"
        class="card-header">
        Signatory <span v-if="signatories.length > 1">
          #{{ index + 1 }}
        </span>
      </h6>
      <div
        :key="signatory.signatoryId"
        class="card">
        <div class="card-block cols-four">
          <div class="form-group">
            <label>First Name:</label>
            <p>{{ signatory.firstName }}</p>
          </div>
          <div class="form-group">
            <label>Last Name:</label>
            <p>{{ signatory.lastName }}</p>
          </div>
          <div class="form-group">
            <label>Suffix:</label>
            <p>{{ signatory.suffix }}</p>
          </div>
          <div class="form-group">
            <label>Title:</label>
            <p>{{ signatory.title }}</p>
          </div>
          <div class="form-group">
            <label>SSN/EIN</label>
            <p>{{ signatory.ssn }}</p>
          </div>
          <div class="form-group">
            <label>Email:</label>
            <p>{{ signatory.email }}</p>
          </div>
          <div class="form-group">
            <label>Phone Number:</label>
            <p>
              {{ signatory.phone }}
              <span v-if="signatory.phoneExt">
                Ext. {{ signatory.phoneExt }}
              </span>
            </p>
          </div>
          <div class="form-group">
          </div>
          <div class="form-group">
            <label>Country:</label>
            <p>{{ signatory.countryCode }}</p>
          </div>
          <div class="form-group">
            <label>Home Address:</label>
            <p>{{ signatory.street }}</p>
          </div>
          <div class="form-group">
            <label>Unit:</label>
            <p>{{ signatory.unit }}</p>
          </div>
          <div class="form-group">
            <label>City:</label>
            <p>{{ signatory.city }}</p>
          </div>
          <div class="form-group">
            <label>State:</label>
            <p>{{ signatory.state }}</p>
          </div>
          <div class="form-group">
            <label>Postal Code:</label>
            <p>{{ signatory.zip }}</p>
          </div>
          <div class="form-group">
            <label>Application Signed:</label>
            <p>{{ isSigned(signatory.guarantor) }}</p>
          </div>
        </div>
      </div>
    </template>

    <template
      v-if="creditApp.customerComments">
      <h6 class="card-header">
        Customer Comments
      </h6>
      <div class="card">
        <div class="card-block">
          <div class="form-group">
            <p>{{ creditApp.customerComments }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'SignatoryInformation',
  props: {
    creditApp: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      signatories: {}
    }
  },
  created () {
    this.signatories = this.$nullToDefault(this.creditApp.signatories)
  },
  methods: {
    isSigned (bool) {
      return bool ? 'Yes' : 'No'
    }
  }
}
</script>
<style lang="scss">

</style>
