<template>
  <div
    style="display: grid;"
    :class="{'has-danger': $isErrorState()}">
    <span>
      <label
        v-if="!hideLabel"
        class="form-control-label"
        :class="$isRequired() && !disabled && !hideRequired ? 'required' : ''"
        style="font-size: 12px;">
        {{ inputLabel }}
      </label>
    </span>
    <v-combobox
      v-model="select"
      outlined
      dense
      :loading="loading"
      :disabled="disabled"
      autocomplete="autocomplete_off_hack"
      :items="items"
      :search-input.sync="search"
      :class="{'has-danger': $isErrorState()}"
      hide-no-data
      hide-selected
      :label="label"
      @input="vuelidate != null ? vuelidate.$touch() : null"
      @blur="vuelidate != null ? vuelidate.$touch() : null"
      @change="dialogDecider">
    </v-combobox>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="500">
      <v-card>
        <v-toolbar
          color="#032D2C"
          flat
          class="mb-5">
          <v-card-title class="text-h5 text-white">
            Information may be lost
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="clearAccountSelected(clearSelection)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="font-weight-bold mb-5">
          If you change the {{ $route.params.tenant=='platt' ? 'Customer Account' : 'Bill to Account' }}, some information on this form may need to be updated such as the general contractor section. <br />
          <br />Do you wish to proceed?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            class="btn nav-button-clear text-uppercase"
            text
            @click="clearAccountSelected(clearSelection)">
            Cancel
          </button>
          <button
            class="btn nav-button text-uppercase"
            text
            @click="confirmSelection(clearSelection)">
            Proceed
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FormFieldErrorMessages
      v-if="!hideRequired"
      :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from '@/components/form/FormInput.mixin'
import _ from 'lodash'

/**
 * * populating items list: This component can handle both static and dynamic options
 *
 *    * For static list, pass array to the `options` param
 *
 *    * For a dynamic list, pass args to asyncSearchHandler
 *
 *  * Emits 'input' on selection - implement handler in parent component if needed
 */

export default {
  name: 'JobAccountComboboxDialog',
  components: {
    FormFieldErrorMessages
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    // NOTE should use mustBeObject (or mustBeObjectOrEmpty if not required) custom validation to fields using this component
    value: {
      type: [String, Object],
      required: false,
      default: () => ({
        value: null,
        text: null
      })
    },
    // used to handle static lists
    options: {
      /**
       * the expected object = {
       *  value, // the key
       *  text // visible label
       * }
       */
      type: Array,
      required: false,
      default: null
    },
    // used to handle dynamically populated lists
    asyncSearchHandler: {
      // must be an async method that returns a promise
      type: Function,
      required: false,
      default: null
    },
    // only called if asyncSearchHandler is implemented
    // use to map search result objects to  structure expected by this.options
    itemsMapper: {
      type: Function,
      required: false,
      // just pass items along by default
      default: function (items) {
        return items
      }
    },
    inputLabel: {
      type: String,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    hideRequired: {
      type: Boolean,
      required: false,
      default: false
    }

  },
  data () {
    return {
      loading: false,
      search: null,
      label: null,
      select: null,
      items: [],
      showDialog: false,
      clearSelection: null,
      intialSelection: true,
      searchHandler: null
    }
  },
  watch: {
    options: {
      handler (newVal) {
        if (newVal != null) {
          // populate items with static options list
          this.items = [...newVal]

          // update field w/ value on mount
          const itemEntry = this.items.find((i) => {
            return i.value === this.select
          })
          if (itemEntry != null) {
            this.select = itemEntry
          }
        }
      }

    },
    value: {
      handler (newVal) {
        if (this.select == null) {
          this.select = newVal
        }
      }
    },
    search: {
      handler (newVal, oldVal) {
        const isSearchAndSelectMatch = newVal &&
          newVal !== this.select

        if (isSearchAndSelectMatch &&
          this.areOptionsDynamicallyLoaded()) {
          this.querySelections(newVal)
        }
      }
    },
    // watches dropdown and captures old value to use to revert if dialog canceled. checks to see if this is first run
    select (newValue, oldValue) {
      this.clearSelection = oldValue
      if (this.intialSelection) {
        this.$emit('input', newValue)
        this.intialSelection = false
      }
    }
  },
  mounted () {
    this.handleLoadingSelectedDataForDynamicItemsOnMount()
    /* Funnel all searches through a single debounce instance */
    this.searchHandler = _.debounce(async function (v) {
      this.loading = true
      await this.asyncSearchHandler(v)
        .then((res) => {
          this.items = this.itemsMapper(res)
          return res
        })
        .catch((err) => {
          return err
        })
        .finally(() => {
          this.loading = false
        })
    }, 500)
  },
  methods: {
    // Handles Loading data into field w/ dynamic items list, by using the saved object to populate items
    handleLoadingSelectedDataForDynamicItemsOnMount () {
      if (this.options == null && this.value != null && this.value.value != null) {
        this.items.push(this.value)
        this.select = this.value
      }
    },
    areOptionsDynamicallyLoaded () {
      // checking if this is null should suffice
      return this.asyncSearchHandler != null
    },
    querySelections (v) {
      if (typeof this.searchHandler === 'function') {
        this.searchHandler(v)
      }
    },
    // called by v-on change
    dialogDecider () {
      if (!this.intialSelection) {
        this.showDialog = true
        this.intialSelection = false
      }
    },
    // gets clearSelection from watcher passed in via cancel button in prompt
    clearAccountSelected (value) {
      this.select = value?.text ?? null
      this.showDialog = false
    },
    confirmSelection (value) {
      this.intialSelection = false
      this.$emit('input', this.select)
      this.showDialog = false
    }
  }
}
</script>
<style scoped>
  .nav-button {
  background-color: #099c98;
  font-size: 0.70rem;
  font-weight: bold;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
  .nav-button-clear {
  font-size: 0.70rem;
  font-weight: bold;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  border: 0;
}
.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
</style>
