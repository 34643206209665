<template>
  <div class="row customer-type-row">
    <div
      v-if="tenantId === 1"
      class="col-md-6">
      <FormInputDropdown
        v-model="userFormInput.customerTypeCategory"
        class="platt-customer-type-category"
        :disabled="disabled"
        :vuelidate="vuelidate.customerTypeCategory"
        :input-label="tenantId === 1 ? 'PIC Code Category:' : 'Customer Segment:'"
        :options="customerTypePICCodeCategories"
        :show-value="true"></FormInputDropdown>
    </div>

    <div
      v-else
      class="col-md-6">
      <!-- NOTE: Forms using this component must initialize eclipsePriceClass.title in dataClass -->
      <FormInputDropdown
        v-model="userFormInput.eclipsePriceClass.title"
        style="margin-bottom: .6em; padding-right:.8em;"
        :disabled="disabled"
        :input-label="tenantId === 1 ? 'PIC Code Category:' : 'Customer Segment:'"
        :vuelidate="vuelidate.customerTypeCategory"
        :options="typeOptions.map((o) => o.title)">
      </FormInputDropdown>
    </div>

    <div
      v-if="tenantId === 1 && userFormInput.customerTypeCategory"
      class="col-md-6">
      <FormInputDropdown
        v-model="userFormInput.customerType"
        class="platt-customer-type"
        :disabled="disabled"
        :vuelidate="vuelidate.customerType"
        :input-label="tenantId === 1 ? 'PIC Code:' : 'SIC Code:'"
        :options="customerTypePICCodes"
        :show-value="true"></FormInputDropdown>
    </div>
  </div>
</template>

<script>
import FormInputDropdown from '@/components/form/FormInputDropdown.vue'
import FormInputMixin from './FormInput.mixin'
import axios from 'ca-http-service'

export default {
  name: 'FormInputDropdownCustomerType',
  components: {
    FormInputDropdown
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    vuelidate: {
      type: Object,
      required: true
    },
    // NOTE: make sure to pass the formData reference, instead of the copy of the userFormInput object to this prop
    formData: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tenant: '',
      tenantId: '',
      userFormInput: this.formData,
      customerTypePICCodeCategories: [],
      customerTypePICCodes: [],
      typeOptions: []
    }
  },
  watch: {
    'userFormInput.customerTypeCategory': {
      async handler (newVal) {
        await this.updatePICCodeOptions(newVal)
      }
    },
    // title property on this object is the v-model, so watching to handle updates
    'formData.eclipsePriceClass.title': {
      handler (newVal) {
        if (newVal != null) {
          const eclipsePriceClassTitle = this.formData.eclipsePriceClass.title

          const eclipsePriceClass = this.typeOptions.find(e => e.title === eclipsePriceClassTitle)

          if (eclipsePriceClassTitle != null) {
            this.userFormInput.customerTypeCategory = eclipsePriceClass.title
            this.userFormInput.customerPriceClass = eclipsePriceClass.priceClass
            this.userFormInput.customerType = eclipsePriceClass.customerType
          }
        }
      }
    }
  },
  async mounted () {
    this.tenant = this.$route.params.tenant
    this.tenantId = this.getTenantByName()
    await this.initializeCustomerTypeLists()

    if (this.tenantId === 1) {
      // on load, we need to make sure that customer type lists are initialized before selecting a pic code option
      await this.updatePICCodeOptions(this.userFormInput.customerTypeCategory)
    }

    this.vuelidate.$reset()
  },
  methods: {
    // extract as store getter, reused over and over in other places...
    getTenantByName () {
      const urlTenant = this.$route.params.tenant
      return urlTenant === 'platt'
        ? 1
        : urlTenant === 'rexel'
          ? 2
          : urlTenant === 'gexpro'
            ? 3
            : urlTenant === 'rexelenergy'
              ? 4
              : null
    },
    async initializeCustomerTypeLists () {
      // reset options before populating w/ retrieved data
      this.customerTypePICCodeCategories.length = 0
      this.customerTypePICCodes.length = 0

      const categories = []
      if (this.tenantId === 1) { // platt banner
        this.customerTypeOptions = await this.getCustomerTypeOptions()
        const options = this.customerTypeOptions
        options.forEach(o => {
          // for platt data: categories are mixed in w/ pic codes, and will only have one char
          if (o.key.length === 1) {
            categories.push(o)
          }
        })
        this.customerTypePICCodeCategories = [...categories]
      } else {
        this.customerTypeOptions = await this.getCustomerTypeOptionsForRexelGexpro()

        // we are only using rexel price classes now
        this.typeOptions = this.customerTypeOptions
      }
    },
    // on selecting a new PIC Code Category, we need to update PIC Code options
    async updatePICCodeOptions (category) {
      const remappedPICCodes = []
      const picCodes = [...this.customerTypeOptions.filter(o => {
        if (this.tenantId === 1) { // platt
          // get all options where currently selected category matches first char of key
          if (o.key !== category) {
            return o.key[0] === category
          } else {
            return false
          }
        } else { // for non-platt
          // get all options where currently selected category is same as value in options
          return o.value === category
        }
      }
      )]

      picCodes.forEach(o => {
        // for platt we need the value, non-platt we just need the key
        remappedPICCodes.push({ key: o.key, value: this.tenantId === 1 ? o.value : o.key })
      })

      this.customerTypePICCodes.length = 0 // empty array before adding new options
      this.customerTypePICCodes = [...remappedPICCodes]
    },
    async getCustomerTypeOptions () {
      return await axios.get(
        `/api/CashAccount/options/customer-type?banner=${this.tenantId}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.err(err)
        })
    },
    async getCustomerTypeOptionsForRexelGexpro () {
      return await axios.get(
        '/api/CashAccount/options/customer-price-class-rexel-gexpro')
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.err(err)
        })
    }
  }
}
</script>

<style scoped>
.customer-type-row {
  margin: 0;
}
.customer-type-row .col-md-6 {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .platt-customer-type-category {
    padding-right: 1em;
  }
  .platt-customer-type {
    padding-left: 1em;
  }
}
</style>
