import Step1Details from './steps/Step1Details.vue'
import Step2ContactInformation from './steps/Step2ContactInformation.vue'
import Step3ThirdParty from './steps/Step3ThirdParty.vue'
import Step4Signatory from './steps/Step4Signatory.vue'
import Step5ESign from './steps/Step5ESign.vue'

export const BUSINESS_INFO = 'businessInfo'
export const BILLING_INFO = 'billingInfo'
export const ACCOUNT_PAYABLE = 'accountPayable'
export const PURCHASING_CONTACT = 'purchasingContact'
export const PREFERENCES = 'preferences'
export const BANKING_INFORMATION = 'bankingInformation'
export const TRADE_REFERENCES = 'tradeReferences'
export const TRADE_REFERENCE = 'tradeReference'
export const BONDING_COMPANY = 'bondingCompany'
export const SIGNATORIES = 'signatories'
export const SIGNATORY = 'signatory'
export const SIGNATORYTWO = 'signatoryTwo'
export const UPLOAD_APPLICATION = 'application'
export const E_SIGN = 'eSign'

export const LOAD_WITH_TEST_DATA = false

/* NOTE: testData property for each step can be used to quickly fill out the form.

  Currently values below tests just one scenario.

  TODO: Additional cases that we will want to test:

  * All Signatories and Banner combinations to test hellosign
  * The minimum input needed
  * All fields
  *

  TODO: Enum values need work. Determine original conventions and use those...

*/
export const formTitle = 'Credit Application'
export const formDefinitions = {
  [BUSINESS_INFO]: {
    order: 1,
    group: 1,
    title: 'Detail Information',
    component: Step1Details,
    dataClass: class BusinessInfo {
      constructor () {
        this.hasAccount = ''
        this.accountNumber = ''
        this.branchNumber = ''
        this.companyName = ''
        this.parentCompanyName = ''
        this.internalCompanyName = ''
        this.phone = ''
        this.email = ''
        this.fax = ''
        this.acquiredAccountFrom = ''
        this.picCodeCategory = ''
        this.picCode = ''
        this.customerTypeCategory = ''
        this.customerPriceClass = ''
        this.insideSalesPerson = ''
        this.outsideSalesPerson = ''
        this.webUserId = null
      }
    },
    testData: {
      hasAccount: 'true',
      accountNumber: '54321',
      branchNumber: '5055',
      companyName: 'Test Company',
      parentCompanyName: 'Parent Company',
      internalCompanyName: 'Internal Company',
      phone: '(555) 123-1234',
      email: 'test@test.com',
      fax: '(555) 867-5309',
      acquiredAccountFrom: '',
      picCodeCategory: '',
      picCode: '',
      customerTypeCategory: '',
      customerPriceClass: '',
      insideSalesPerson: '',
      outsideSalesPerson: '',
      webUserId: 82587
    },
    labels: {
      accountNumber: 'Account number (If known):',
      branchNumber: '',
      companyName: 'Name of Company (Include DBA):',
      parentCompanyName: 'Subsidiary/Division of:',
      internalCompanyName: 'Customer Name:',
      phone: 'Company Phone Number:',
      email: 'Email Address:',
      fax: 'Company Fax Number:',
      acquiredAccountFrom: 'Acquired Account From:',
      picCodeCategory: 'PIC Code Category:',
      picCode: 'PIC Code:',
      customerTypeCategory: 'Customer Segment:',
      customerPriceClass: 'Customer Pricing Class',
      insideSalesPersonEmail: 'Inside Salesperson:',
      outsideSalesPersonEmail: 'Outside Salesperson:',
      webUserId: 'Web User Id:'
    }
  },
  [BILLING_INFO]: {
    order: 2,
    group: 1,
    title: 'Billing Information',
    dataClass: class BillingInfo {
      constructor () {
        this.streetAddress = ''
        this.addressUnitNumber = ''
        this.city = ''
        this.country = 'US'
        this.state = ''
        this.postalCode = ''
        this.businessType = 'Proprietorship'
        this.businessTypeLabel = ''
        this.contractorLicenceNumber = ''
        this.dunsNumber = ''
        this.monthlyIncomeEstimate = ''
        this.monthlyPurchasesEstimate = ''
        this.incorporatedCountry = 'US'
        this.incorporatedState = ''
        this.yearEstablished = ''
        this.preferredSalesPerson = ''
        this.businessIndustry = ''
        this.sectorType = ''
        this.employeeCount = ''
        this.isHaveFederalTaxId = 'No'
        this.federalTaxId = ''
        this.isBillingAddressSameAsShipping = '0'
        this.shippingStreetAddress = ''
        this.shippingAddressUnitNumber = ''
        this.shippingCity = ''
        this.shippingCountry = ''
        this.shippingState = ''
        this.shippingPostalCode = ''
      }
    },
    testData: {
      streetAddress: '123 Test St',
      addressUnitNumber: '',
      city: 'Portland',
      country: 'US',
      state: 'OR',
      postalCode: '97205',
      businessType: 'Proprietorship',
      businessTypeLabel: '',
      contractorLicenceNumber: '12345',
      dunsNumber: '2112',
      monthlyIncomeEstimate: '3100',
      monthlyPurchasesEstimate: '40',
      incorporatedCountry: 'US',
      incorporatedState: 'AK',
      yearEstablished: '1963',
      preferredSalesPerson: '',
      businessIndustry: 'Software',
      sectorType: 'Commercial Contractor',
      employeeCount: '1 to 5',
      isHaveFederalTaxId: 'Yes',
      federalTaxId: '123',
      isBillingAddressSameAsShipping: '1',
      shippingStreetAddress: '',
      shippingAddressUnitNumber: '',
      shippingCity: '',
      shippingCountry: '',
      shippingState: '',
      shippingPostalCode: ''
    },
    labels: {
      country: 'Country:',
      streetAddress: 'Billing Address:',
      addressUnitNumber: 'Unit:',
      city: 'City:',
      state: 'State:',
      postalCode: 'Postal Code:',
      businessType: 'Please select one of the following:',
      businessTypeLabel: 'Business Type:',
      contractorLicenceNumber: 'Contractor license number:',
      dunsNumber: 'DUNS number:',
      monthlyIncomeEstimate: 'Estimated monthly income:',
      monthlyPurchasesEstimate: 'Anticipated monthly purchases:',
      incorporatedCountry: 'Incorporated Country:',
      incorporatedState: 'Incorporated State:',
      yearEstablished: 'Year business was established:',
      preferredSalesPerson: 'Preferred Sales Person:',
      businessIndustry: 'Business Industry:',
      sectorType: 'Sector Type:',
      employeeCount: 'Number of Employees:',
      isHaveFederalTaxId: 'Have Federal Tax ID?',
      federalTaxId: 'Federal Tax ID:',
      isBillingAddressSameAsShipping: 'Billing address is same as shipping',
      shippingStreetAddress: 'Shipping Address:',
      shippingAddressUnitNumber: 'Unit:',
      shippingCity: 'City:',
      shippingCountry: 'Country:',
      shippingState: 'State:',
      shippingPostalCode: 'Postal Code:'
    }
  },
  [ACCOUNT_PAYABLE]: {
    order: 3,
    group: 2,
    title: 'Accounts Payable',
    component: Step2ContactInformation,
    dataClass: class AccountPayable {
      constructor () {
        this.firstName = ''
        this.lastName = ''
        this.phone = ''
        this.phoneExt = ''
        this.fax = ''
        this.email = ''
      }
    },
    testData: {
      firstName: 'Test',
      lastName: 'Tester',
      phone: '(808) 808-8080',
      phoneExt: '1234',
      fax: '(408) 867-5309',
      email: 'jenny@tutone.fake'
    },
    labels: {
      firstName: 'First name:',
      lastName: 'Last name:',
      phone: 'Contact phone number:',
      phoneExt: 'Extension:',
      fax: 'Contact fax number:',
      email: 'Contact email address:'
    }
  },
  [PURCHASING_CONTACT]: {
    order: 4,
    group: 2,
    title: 'Purchasing Contact',
    dataClass: class PurchasingContact {
      constructor () {
        this.firstName = ''
        this.lastName = ''
        this.phone = ''
        this.phoneExt = ''
        this.fax = ''
        this.email = ''
      }
    },
    testData: {
      firstName: 'Rupert',
      lastName: 'Pupkin',
      phone: '(555) 867-5309',
      phoneExt: '1234',
      fax: '(555) 867-5309',
      email: 'rupert@fake.com'
    },
    labels: {
      firstName: 'First name:',
      lastName: 'Last name:',
      phone: 'Contact phone number:',
      phoneExt: 'Extension:',
      fax: 'Contact fax number:',
      email: 'Contact email address:'
    }
  },
  [PREFERENCES]: {
    order: 5,
    group: 2,
    title: 'Preferences',
    dataClass: class Preference {
      constructor () {
        this.storageGuid = ''
        this.selectedDocuments = ''
        this.uploadedDocuments = []
        this.isUploaded = ''
        this.statementPreferredCommunicationType = '1'
        this.statementPreferredEmailAddress = ''
        this.statementPreferredFax = ''
        this.invoicePreferredCommunicationType = '1'
        this.invoicePreferredEmailAddress = ''
        this.invoicePreferredFax = ''
        this.isPurchaseOrderRequired = 'No'
        this.isTaxable = 'Yes'
        this.statementPreferredStreetAddress = ''
        this.statementPreferredAddressUnitNumber = ''
        this.statementPreferredCity = ''
        this.statementPreferredCountry = ''
        this.statementPreferredState = ''
        this.statementPreferredPostalCode = ''
        this.invoicePreferredStreetAddress = ''
        this.invoicePreferredAddressUnitNumber = ''
        this.invoicePreferredCity = ''
        this.invoicePreferredCountry = ''
        this.invoicePreferredState = ''
        this.invoicePreferredPostalCode = ''
      }
    },
    testData: {
      statementPreferredCommunicationType: '2',
      statementPreferredEmailAddress: '',
      statementPreferredFax: '(555) 808-8080',
      invoicePreferredCommunicationType: '1',
      invoicePreferredEmailAddress: 'ok@ok.com',
      invoicePreferredFax: '',
      isPurchaseOrderRequired: 'No',
      isTaxable: 'Yes',
      statementPreferredStreetAddress: '',
      statementPreferredAddressUnitNumber: '',
      statementPreferredCity: '',
      statementPreferredCountry: '',
      statementPreferredState: '',
      statementPreferredPostalCode: '',
      invoicePreferredStreetAddress: '',
      invoicePreferredAddressUnitNumber: '',
      invoicePreferredCity: '',
      invoicePreferredCountry: '',
      invoicePreferredState: '',
      invoicePreferredPostalCode: ''
    },
    labels: {
      selectedDocuments: 'Select files to add',
      uploadedDocuments: 'Uploaded files',
      storageGuid: 'This should not be displayed',
      statementPreferredCommunicationType: 'Preferred Communication Type:',
      statementPreferredEmailAddress: 'Contact Email Address:',
      statementPreferredFax: 'Contact Fax:',
      statementPreferredStreetAddress: 'Street Address',
      statementPreferredAddressUnitNumber: 'Unit',
      statementPreferredCity: 'City',
      statementPreferredCountry: 'Country',
      statementPreferredState: 'State',
      statementPreferredPostalCode: 'Postal Code',
      invoicePreferredStreetAddress: 'Street Address',
      invoicePreferredAddressUnitNumber: 'Unit',
      invoicePreferredCity: 'City',
      invoicePreferredCountry: 'Country',
      invoicePreferredState: 'State',
      invoicePreferredPostalCode: 'Postal Code',
      invoicePreferredCommunicationType: 'Preferred Communication Type:',
      invoicePreferredEmailAddress: 'Contact Email Address:',
      invoicePreferredFax: 'Contact Fax:',
      isPurchaseOrderRequired: 'Purchase order required?',
      isTaxable: 'Taxable?'
    }
  },
  [BANKING_INFORMATION]: {
    order: 6,
    group: 3,
    title: 'Banking Information',
    dataClass: class BankingInformation {
      constructor () {
        this.bankName = ''
        this.accountType = '1'
        this.officerName = ''
        this.phoneNumber = ''
        this.phoneNumberExt = ''
        this.streetAddress = ''
        this.addressUnitNumber = ''
        this.city = ''
        this.country = ''
        this.state = ''
        this.postalCode = ''
      }
    },
    testData: {
      bankName: 'First Big Money',
      accountType: '1',
      officerName: 'Rupert Pupkin',
      phoneNumber: '(555) 867-5309',
      phoneNumberExt: '1234',
      streetAddress: '123 Monopoly way',
      addressUnitNumber: '222',
      city: 'Big City',
      country: 'US',
      state: 'OR',
      postalCode: '97223'
    },
    labels: {
      country: 'Country:',
      streetAddress: 'Banking Address:',
      addressUnitNumber: 'Unit:',
      city: 'City:',
      state: 'State:',
      postalCode: 'Postal Code:',
      accountType: 'Account Type:',
      bankName: 'Bank name:',
      officerName: 'Officer name:',
      phoneNumber: 'Phone number:',
      phoneNumberExt: 'Extension:'
    }
  },
  [TRADE_REFERENCES]: {
    collectionInfo: {
      required: false,
      collection: TRADE_REFERENCES,
      instance: TRADE_REFERENCE
    },
    order: 7,
    group: 3,
    title: 'Trade References',
    // this step should be able to handle a collection of Trade References
    dataClass: class TradeReference {
      constructor () {
        this.name = ''
        this.account = ''
        this.streetAddress = ''
        this.addressUnitNumber = ''
        this.city = ''
        this.country = ''
        this.state = ''
        this.postalCode = ''
        this.fax = ''
        this.email = ''
        this.phone = ''
        this.referenceId = 0
      }
    },
    testData: {
      name: 'Moneybags 2',
      account: '123',
      country: 'US',
      streetAddress: '123 Gold rd.',
      addressUnitNumber: 'Room 222',
      city: 'Some City',
      state: 'OR',
      postalCode: '96766',
      fax: '(808) 808-8080',
      email: 'ok@ok.com',
      phone: '(808) 808-8080',
      referenceId: 0
    },
    testDataCollection: [
      {
        name: 'Moneybags',
        account: '123',
        country: 'US',
        streetAddress: '123 Gold rd.',
        addressUnitNumber: '',
        city: 'Some City',
        state: 'OR',
        postalCode: '96766',
        fax: '(808) 808-8080',
        email: 'ok@ok.com',
        phone: '(808) 808-8080',
        referenceId: 0
      }
    ],
    labels: {
      name: 'Name of Business:',
      account: 'Account:',
      country: 'Country:',
      streetAddress: 'Business Address:',
      addressUnitNumber: 'Unit:',
      city: 'City:',
      state: 'State:',
      postalCode: 'Postal Code:',
      fax: 'Contact Fax',
      email: 'Contact Email',
      phone: 'Phone Number'
    }
  },
  [BONDING_COMPANY]: {
    order: 8,
    group: 3,
    title: 'Bonding Company',
    dataClass: class BondingCompany {
      constructor () {
        this.streetAddress = ''
        this.addressUnitNumber = ''
        this.city = ''
        this.country = ''
        this.state = ''
        this.postalCode = ''
        this.bondingName = ''
        this.bondingAgentName = ''
        this.isAddressSameAsBondingCompany = '1'
        this.bondingAgentStreetAddress = ''
        this.bondingAgentAddressUnitNumber = ''
        this.bondingAgentCity = ''
        this.bondingAgentCountry = ''
        this.bondingAgentState = ''
        this.bondingAgentPostalCode = ''
      }
    },
    testData: {
      streetAddress: '123 Some rd.',
      addressUnitNumber: '',
      city: 'Portland',
      country: 'US',
      state: 'OR',
      postalCode: '97223',
      bondingName: 'Bondo',
      bondingAgentName: 'Rupert Pupkin',
      isAddressSameAsBondingCompany: '',
      bondingAgentStreetAddress: '',
      bondingAgentAddressUnitNumber: '',
      bondingAgentCity: '',
      bondingAgentCountry: '',
      bondingAgentState: '',
      bondingAgentPostalCode: ''
    },
    labels: {
      country: 'Country:',
      streetAddress: 'Business Address:',
      addressUnitNumber: 'Unit:',
      city: 'City:',
      state: 'State:',
      postalCode: 'Postal Code:',
      bondingName: 'Name:',
      bondingAgentName: 'Agent name:',
      isAddressSameAsBondingCompany: 'Address same as Bonding Company:',
      bondingAgentCountry: 'Country:',
      bondingAgentStreetAddress: 'Agent Address:',
      bondingAgentAddressUnitNumber: 'Unit:',
      bondingAgentCity: 'City:',
      bondingAgentState: 'State:',
      bondingAgentPostalCode: 'Postal Code:'
    }
  },
  [SIGNATORIES]: {
    collectionInfo: {
      minLength: 1,
      collection: SIGNATORIES,
      instance: SIGNATORY
    },
    order: 9,
    group: 4,
    title: 'Signatory',
    sectionHeaderToolTip: 'The individual(s) signing this document.',
    hasMultipleSignatories: false,
    dataClass: class Signatories {
      constructor () {
        this.hasMultipleSignatories = false
        this.signatoryUrl = ''
        this.signature = ''
        this.firstName = ''
        this.lastName = ''
        this.middleInitial = ''
        this.suffix = ''
        this.jobTitle = ''
        this.email = ''
        this.phone = ''
        this.phoneExt = ''
        this.isSsnOrEin = ''
        this.ssn = ''
        this.guarantor = ''
        this.streetAddress = ''
        this.addressUnitNumber = ''
        this.city = ''
        this.country = ''
        this.state = ''
        this.postalCode = ''
        this.hasSigned = ''
        this.errorMessage = '' // hack: used on BE to pass collection validation message to FE
        this.signatoryId = 0
      }
    },
    testData: {
      signatoryId: 0,
      signatoryUrl: '',
      signature: '',
      firstName: 'A',
      lastName: 'AA',
      middleInitial: '',
      suffix: '',
      jobTitle: 'Full Stack Dev',
      email: 'test@test.com',
      phone: '(555) 555-5309',
      phoneExt: '1234',
      isSsnOrEin: '',
      ssn: '555-45-6789',
      guarantor: '',
      streetAddress: '123',
      addressUnitNumber: '',
      city: 'Portland',
      country: 'US',
      state: 'OR',
      postalCode: '97201',
      hasSigned: ''
    },
    testDataCollection: [
      {
        signatoryId: 0,
        signatoryUrl: '',
        signature: '',
        firstName: 'B',
        lastName: 'BB',
        middleInitial: '',
        suffix: '',
        jobTitle: '',
        email: 'b@b.com',
        phone: '',
        phoneExt: '',
        isSsnOrEin: '',
        ssn: '123-12-1234',
        guarantor: '',
        streetAddress: '123',
        addressUnitNumber: '',
        city: 'Portland',
        country: 'US',
        state: 'OR',
        postalCode: '97201',
        hasSigned: ''
      }
    ],
    labels: {
      firstName: 'First Name:',
      lastName: 'Last Name:',
      middleInitial: 'Middle Initial:',
      suffix: 'Suffix:',
      jobTitle: 'Job Title:',
      email: 'Email:',
      phone: 'Phone:',
      phoneExt: 'Extension:',
      country: 'Country:',
      streetAddress: 'Home Address:',
      addressUnitNumber: 'Unit:',
      city: 'City:',
      state: 'State:',
      guarantor: '',
      isSsnOrEin: '',
      ssn: 'SSN / EIN',
      postalCode: 'Postal Code:'
    }
  },
  [SIGNATORYTWO]: {
    order: 10,
    group: 4,
    title: 'SignatoryTwo',
    sectionHeaderToolTip: 'The individual(s) signing this document.',
    dataClass: class SignatoryTwo {
      constructor () {
        this.signatoryUrl = ''
        this.signature = ''
        this.firstName = ''
        this.lastName = ''
        this.middleInitial = ''
        this.suffix = ''
        this.jobTitle = ''
        this.email = ''
        this.phone = ''
        this.phoneExt = ''
        this.isSsnOrEin = ''
        this.ssn = ''
        this.guarantor = ''
        this.streetAddress = ''
        this.addressUnitNumber = ''
        this.city = ''
        this.country = ''
        this.state = ''
        this.postalCode = ''
        this.hasSigned = ''
        this.errorMessage = '' // hack: used on BE to pass collection validation message to FE
        this.signatoryId = 0
      }
    },
    testData: {
      signatoryId: 0,
      signatoryUrl: '',
      signature: '',
      firstName: 'A',
      lastName: 'AA',
      middleInitial: '',
      suffix: '',
      jobTitle: 'Full Stack Dev',
      email: 'test@test.com',
      phone: '(555) 555-5309',
      phoneExt: '1234',
      isSsnOrEin: '',
      ssn: '555-45-6789',
      guarantor: '',
      streetAddress: '123',
      addressUnitNumber: '',
      city: 'Portland',
      country: 'US',
      state: 'OR',
      postalCode: '97201',
      hasSigned: ''
    },
    testDataCollection: [
      {
        signatoryId: 0,
        signatoryUrl: '',
        signature: '',
        firstName: 'B',
        lastName: 'BB',
        middleInitial: '',
        suffix: '',
        jobTitle: '',
        email: 'b@b.com',
        phone: '',
        phonExt: '',
        isSsnOrEin: '',
        ssn: '123-12-1234',
        guarantor: '',
        streetAddress: '123',
        addressUnitNumber: '',
        city: 'Portland',
        country: 'US',
        state: 'OR',
        postalCode: '97201',
        hasSigned: ''
      }
    ],
    labels: {
      firstName: 'First Name:',
      lastName: 'Last Name:',
      middleInitial: 'Middle Initial:',
      suffix: 'Suffix:',
      jobTitle: 'Job Title:',
      email: 'Email:',
      phone: 'Phone:',
      phoneExt: 'Extension:',
      country: 'Country:',
      streetAddress: 'Home Address:',
      addressUnitNumber: 'Unit:',
      city: 'City:',
      state: 'State:',
      guarantor: '',
      isSsnOrEin: '',
      ssn: 'SSN / EIN',
      postalCode: 'Postal Code:'
    }
  },
  [UPLOAD_APPLICATION]: {
    order: 11,
    group: 5,
    title: 'Upload Documents',
    dataClass: class UploadApplication {
      constructor () {
        this.storageGuid = ''
        this.isUploaded = ''
        this.selectedDocuments = ''
        this.uploadedDocuments = ''
      }
    },
    testData: {
      storageGuid: '',
      selectedDocuments: ''
    },
    labels: {
      selectedDocuments: 'Select files to add',
      uploadedDocuments: 'Uploaded files',
      storageGuid: 'This should not be displayed'
    }
  },
  [E_SIGN]: {
    order: 12,
    group: 5,
    title: 'E-Sign',
    dataClass: class ESign {
      constructor () {
        this.applicationId = ''
        this.signatories = []
        this.businessType = ''
        this.comments = ''
        this.allSignatoriesHaveSigned = ''
      }
    },
    testData: {
      applicationId: '',
      signatories: [],
      businessType: '',
      comments: ''
    },
    labels: {
      comments: 'Comments:'
    }
  }
}
export const formGroupInfo = [
  {
    order: 1,
    group: 1,
    title: 'Detail Information',
    tabTitle: 'Details',
    component: Step1Details,
    sectionNames: [BUSINESS_INFO, BILLING_INFO],
    sectionData: {}
  },
  {
    order: 2,
    group: 2,
    title: 'Contact Information',
    tabTitle: 'Contact',
    component: Step2ContactInformation,
    sectionNames: [
      ACCOUNT_PAYABLE,
      PURCHASING_CONTACT,
      PREFERENCES,
      UPLOAD_APPLICATION
    ],
    sectionData: {}
  },
  {
    order: 3,
    group: 3,
    title: '3rd Party',
    tabTitle: '3rd Party',
    component: Step3ThirdParty,
    sectionNames: [
      BANKING_INFORMATION,
      TRADE_REFERENCES,
      BONDING_COMPANY,
      UPLOAD_APPLICATION
    ],
    sectionData: {}
  },
  {
    order: 4,
    group: 4,
    title: 'Signatory',
    tabTitle: 'Signatory',
    component: Step4Signatory,
    sectionNames: [
      SIGNATORIES,
      SIGNATORYTWO
    ],
    sectionData: {},
    hasMultipleSignatories: false,
    sectionHeaderToolTip: 'The individual(s) signing this document.'
  },
  {
    order: 5,
    group: 5,
    title: 'E-Sign Document',
    tabTitle: 'E-Sign',
    component: Step5ESign,
    sectionNames: [
      E_SIGN
    ],
    sectionData: {}
  }
]
