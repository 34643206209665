<template>
  <v-card
    flat
    tile
    outlined
    class="section-card">
    <v-card-title v-if="title">
      {{ title }}
    </v-card-title>
    <v-container>
      <slot></slot>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: 'FormSectionCard',
  components: {
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
    }
  }
}
</script>
