<template>
  <fieldset>
    <div style="margin-left: 1.4em;">
      <div
        class="form-check">
        <input
          id="generalContractorSameAsCompanyInformation"
          v-model="userFormInput.sameAsCompanyInformation"
          :disabled="isStepDisabled"
          class="form-check-input"
          type="checkbox"
          name="generalContractorSameAsCompanyInformation"
          value="true"
          checked />
        <label
          :style="isStepDisabled ? 'cursor: initial;' : ''"
          style="padding-left: 0.2em !important; font-size: 14px;"
          class="form-check-label"
          for="generalContractorSameAsCompanyInformation">
          Same as Company Information?
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <FormInputTextInput
          ref="generalContractorName"
          v-model="userFormInput.generalContractorName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.generalContractorName"
          :input-label="formLabels.generalContractorName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-md-6">
        <FormInputTextInput
          ref="generalContractorContactNumber"
          v-model="userFormInput.generalContractorContactNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.generalContractorContactNumber"
          :input-label="formLabels.generalContractorContactNumber"
          input-type="tel"
          placeholder="(xxx) xxx-xxxx"></FormInputTextInput>
      </div>
    </div>
    <FormInputGroupAddressInfo
      ref="generalContractorFormInputGroupAddressInfo"
      v-model="userFormInput"
      :disabled="isStepDisabled"
      :vuelidate="vuelidate"
      :form-labels="formLabels"
      :form-data="formData"
      :update-input-method="updateInput"
      :field-names="{
        country: 'generalContractorCountry',
        streetAddress: 'generalContractorStreetAddress1',
        addressUnitNumber: 'generalContractorStreetAddress2',
        city: 'generalContractorCity',
        state: 'generalContractorState',
        postalCode: 'generalContractorPostalCode',
      }"></FormInputGroupAddressInfo>

    <!--Subcontractors -->
    <div class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.addSubContractor"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.addSubContractor"
          :input-label="formLabels.addSubContractor"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1',
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>

    <div v-if="userFormInput.addSubContractor === '1'">
      <div style="margin-left: 1.4em;">
        <div class="form-check">
          <input
            id="subContractorSameAsCompanyInformation"
            v-model="userFormInput.subSameAsCompanyInformation"
            :disabled="isStepDisabled"
            class="form-check-input"
            type="checkbox"
            name="subContractorSameAsCompanyInformation"
            checked />
          <label
            style="padding-left: 0.2em !important; font-size: 14px;"
            class="form-check-label"
            for="subContractorSameAsCompanyInformation">
            Same as Company Information?
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <FormInputTextInput
            ref="subContractorName"
            v-model="userFormInput.subContractorName"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.subContractorName"
            :input-label="formLabels.subContractorName"
            input-type="text"></FormInputTextInput>
        </div>
        <div class="col-md-6">
          <FormInputTextInput
            ref="subContractorContactNumber"
            v-model="userFormInput.subContractorContactNumber"
            :vuelidate="vuelidate.subContractorContactNumber"
            :input-label="formLabels.subContractorContactNumber"
            input-type="tel"
            placeholder="(xxx) xxx-xxxx"></FormInputTextInput>
        </div>
      </div>
      <FormInputGroupAddressInfo
        ref="subContractorFormInputGroupAddressInfo"
        v-model="userFormInput"
        :disabled="isStepDisabled"
        :vuelidate="vuelidate"
        :form-labels="formLabels"
        :form-data="formData"
        :update-input-method="updateInput"
        :field-names="{
          country: 'subContractorCountry',
          streetAddress: 'subContractorStreetAddress1',
          addressUnitNumber: 'subContractorStreetAddress2',
          city: 'subContractorCity',
          state: 'subContractorState',
          postalCode: 'subContractorPostalCode'
        }"></FormInputGroupAddressInfo>
    </div>
  </fieldset>
</template>

<script>
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptions.vue'
import FormInputGroupAddressInfo from '@/components/form/FormInputGroupAddressInfo.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'

export default {
  name: 'JobAccountFormStepGeneralContractorInformation',
  components: {
    FormInputTextInput,
    FormInputRadioOptions,
    FormInputGroupAddressInfo
  },
  mixins: [
    FormStepImplementationMixin
  ],
  data () {
    return {
      states: [],
      selectedState: ''
    }
  },
  async mounted () {
    this.$root.$on('updateGeneralContractor', (payload) => {
      this.updateContractorAddressFields('general', payload)
    })
    this.$root.$on('updateSubContractor', (payload) => {
      this.updateContractorAddressFields('sub', payload)
    })
  },
  // updated () {
  //   // KEEP: When Dev Mode "Overwrite Form Data with Test Data" doesn't work
  //   this.$nextTick(function () {
  //     this.userFormInput.sameAsCompanyInformation = true
  //   })
  // },
  methods: {
    updateContractorAddressFields (type, payload) {
      if (this.$refs == null || this.$refs[`${type}ContractorName`] == null) { return }

      const {
        city,
        companyName,
        contactNumber,
        postalCode,
        streetAddress1,
        streetAddress2,
        state
      } = payload

      if (this.$refs[`${type}ContractorName`] !== null) {
        this.updateInput(this.$refs[`${type}ContractorName`].$el, companyName)
      }

      if (this.$refs[`${type}ContractorName`] !== null) {
        this.updateInput(this.$refs[`${type}ContractorContactNumber`].$el, contactNumber)
      }

      if (this.$refs[`${type}ContractorFormInputGroupAddressInfo`] !== null) {
        this.$refs[`${type}ContractorFormInputGroupAddressInfo`].updateFields({
          streetAddress: streetAddress1,
          addressUnitNumber: streetAddress2,
          city,
          state,
          postalCode
        })
      }
    },
    updateInput (inputParentElm, value) {
      const inputs = inputParentElm.getElementsByTagName('INPUT')
      const inputsArray = Array.prototype.slice.call(inputs)
      inputsArray.map((i) => {
        i.value = value
        return i
      })
    }
  }
}
</script>
