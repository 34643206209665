<template>
  <fieldset>
    <div class="row">
      <div class="col-md-12 pt-0">
        <FormInputDropdown
          v-model="userFormInput[fieldNames.jobTitle]"
          :vuelidate="vuelidate[fieldNames.jobTitle]"
          :input-label="formLabels == null ? null : formLabels[fieldNames.jobTitle]"
          :inline="inline"
          :options="contactTitles"
          :disabled="disabled"></FormInputDropdown>
      </div>
    </div>
  </fieldset>
</template>

<script>
import axios from 'ca-http-service'
import FormInputDropdown from '@/components/form/FormInputDropdown.vue'
import TenantsMixin from 'mixins/tenants.mixin'

export default {
  name: 'FormInputDropdownContactTitles',
  mixins: [TenantsMixin],
  components: {
    FormInputDropdown
  },
  props: {
    vuelidate: {
      type: Object,
      required: false
    },
    // NOTE: make sure to pass the formData reference, instead of the copy of the userFormInput object to this prop
    formData: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: false
    },
    fieldNames: {
      type: Object,
      required: false,
      default: () => ({
        state: 'state'
      })
    },
    col: {
      type: String,
      required: false,
      default: 'col-md-4'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultValue: {
      type: String,
      required: false
    },
    inline: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      userFormInput: this.getFormClass(),
      contactTitles: []
    }
  },
  watch: {
    userFormInput: function (newVal) {
      this.$emit('value', newVal)
    }
  },
  async mounted () {
    this.userFormInput = this.formData
    if (this.defaultValue && !this.userFormInput[this.fieldNames.jobTitle]) {
      this.userFormInput[this.fieldNames.jobTitle] = this.defaultValue
    }
    const bannerId = Number(this.getBannerIdFromName())
    this.getContactTitles(bannerId)

    this.vuelidate.$reset()
  },
  methods: {
    getFormClass () {
      return {
        [this.fieldNames.jobTitle]: ''
      }
    },
    async getContactTitles (companyId) {
      axios.get('/generic/getContactTitles', {
        params: {
          companyID: companyId
        }
      })
        .then((res) => {
          if (res.status === 200) {
            const allTitles = res.data?.map(x => x.contactTitle)
            this.contactTitles = allTitles
          } else {
            this.contactTitles = []
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
</script>
