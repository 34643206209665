<template>
  <v-row class="form-file-uploaded-v3">
    <v-col cols="12">
      <v-file-input
        ref="fileInput"
        v-model="localValue"
        dense
        :accept="allowedFileTypes"
        prepend-icon=""
        :placeholder="placeholder"
        :multiple="multiple"
        :label="inputLabel"
        :error-messages="errors"
        outlined
        class="pr-3">
        <template slot="append-outer">
          <v-btn
            tile
            block
            :color="buttonColor"
            class="form-file-uploaded-v2__button white--text py-6 px-6"
            :disabled="uploadButtonDisabled"
            @click="clickButton">
            {{ buttonText }}
          </v-btn>
        </template>
      </v-file-input>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormFileUploaderV3',
  props: {
    value: {
      type: [String, FormData, Array, File], // is a String onMount
      required: true,
      default: ''
    },
    inputLabel: {
      type: String,
      required: false,
      default: ''
    },
    multiple: {
      type: Boolean,
      required: false,
      default: () => false
    },
    maxMbSize: {
      type: Number,
      required: false,
      default: 60
    },
    returnFileData: {
      type: Boolean,
      required: false,
      default: () => false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select a file & add'
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'teal'
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Add'
    }
  },
  data () {
    return {
      rules: [
        (value) => {
          const megabytes = this.convertKbToMb(value.size)
          return !value || megabytes < 15 || 'Attachments cannot be larger than 15MB'
        }
      ],
      errors: [],
      allowedFileExtensions: ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'pdf', 'bmp', 'jpg', 'png']
    }
  },
  computed: {
    allowedFileTypes () {
      return [
        'application/msword',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpointtd',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/bmp',
        'image/jpg',
        'image/png',
        'text/plain'
      ].sort().join(',')
    },
    uploadButtonDisabled () {
      return typeof this.value !== 'string'
    },
    localValue: {
      get () {
        if (this.value instanceof File) {
          return this.value
        }
        return null
      },
      set (files) {
        this.errors = []

        const filesArray = files instanceof Array ? files : [files]

        const filteredFiles = filesArray.filter((file) => {
          return file instanceof File
        })

        const tooBig = this.getTotalUploadSizeInMB(filteredFiles) > this.maxMbSize

        const badFiletype = filteredFiles.filter(file => {
          return !this.allowedFileExtensions.includes(file.name.split('.').pop().toLowerCase())
        }).length

        if (filteredFiles.length <= 0 || tooBig) {
          this.$emit('input', '')
          if (tooBig) {
            this.errors.push(`Maximum total attachments must be ${this.maxMbSize}MB or less`)
          }
          return
        }

        if (badFiletype) {
          this.errors.push('This file type is not accepted. Please choose a different file or convert this to another format to upload.')
          return
        }
        const formData = new FormData()

        filteredFiles.map((file) => {
          const fileErrors = this.rules
            .map(rule => rule(file))
            .filter(result => typeof result === 'string')

          if (fileErrors.length <= 0) {
            formData.append('files', file, files.name)
            return file
          }
          fileErrors.map(e => {
            if (this.errors.indexOf(e) < 0) this.errors.push(e)
            return e
          })
          return file
        })

        if (this.errors.length > 0 && !(this.errors.length === 1 && badFiletype)) {
          this.$emit('input', '')
          return
        }

        if (this.returnFileData) {
          const fileData = this.multiple ? filteredFiles : filteredFiles[0]
          this.$emit('input', fileData)
          return
        }

        this.$emit('input', formData)
      }
    }
  },
  methods: {
    clickButton () {
      const input = this.$refs.fileInput.$el.getElementsByTagName('input')[0]
      input.click()
    },
    getTotalUploadSizeInMB (files) {
      if (files.length <= 0) return 0
      const sum = files
        .map(file => typeof file.size !== 'undefined' ? file.size : 0)
        .reduce((total, num) => total + num)
      return this.convertKbToMb(sum)
    },
    convertKbToMb (kilobytes) {
      return (kilobytes / (1024 * 1024).toFixed(2))
    }
  }
}
</script>

<style lang="scss" scoped>
.form-file-uploaded-v3 {
  &__button {
    &.v-btn {
      text-transform: none;
    }
  }
}
</style>
