import axios from 'ca-http-service'

export const getWebUserFromToken = async (sftoken) => {
  return await axios
    .get('/api/creditapplication/storefront-token', {
      params: {
        sftoken
      }
    }).then((res) => {
      return res.data
    }).catch((err) => {
      console.error('error retrieving storefront logged in user', err)
    })
}
