// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js' // Chart is used implicitly by chart.piecelabel.js

import 'chart.piecelabel.js'
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  name: 'ReviewedByType',
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
