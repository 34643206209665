<template>
  <div
    id="documents-component"
    class="component-container">
    <div class="btn-title">
      <h5 class="section-title">
        Documents
      </h5>
      <div class="btns">
        <button
          v-if="app.taxDocuments.length > 0 || app.otherDocuments.length > 0 || app.adminDocuments.length > 0 || app.documents.length > 0"
          type="button"
          class="btn btn-rounded"
          @click="downloadAllDocs(app.guid)">
          <i class="fa fa-cloud-download"></i>
          <span>Download All</span>
        </button>
        <button
          v-if="canEdit"
          type="button"
          class="btn btn-rounded"
          @click="uploadDocument">
          <i class="fa fa-cloud-upload"></i>
          <span>Upload Document</span>
        </button>
      </div>
    </div>

    <!-- application document -->
    <div
      v-if="app.documents.length > 0"
      class="documents-list">
      <ul class="list-group">
        <li
          v-for="(doc, index) in app.documents"
          :key="index"
          class="list-group-item ml-n6">
          <div>{{ filterFilename(doc.name) }}</div>
          <button
            type="button"
            class="download-link"
            @click="downloadDoc(doc.fullPath, filterFilename(doc.name))">
            <span>Download</span>
            <i class="fa fa-download"></i>
          </button>
        </li>
      </ul>
    </div>

    <!-- tax documents -->
    <div
      v-if="app.taxDocuments.length > 0"
      class="documents-list">
      <h5 class="section-title">
        Tax Documents
      </h5>
      <ul class="list-group">
        <li
          v-for="(doc, index) in app.taxDocuments"
          :key="index"
          class="list-group-item ml-n6">
          <div>{{ filterFilename(doc.name) }}</div>
          <button
            type="button"
            class="download-link"
            @click="downloadDoc(doc.fullPath, filterFilename(doc.name))">
            <span>Download</span>
            <i class="fa fa-download"></i>
          </button>
        </li>
      </ul>
    </div>

    <!-- other documents -->
    <div
      v-if="app.otherDocuments.length > 0"
      class="documents-list">
      <h5 class="section-title">
        Other Documents
      </h5>
      <ul class="list-group">
        <li
          v-for="(doc, index) in app.otherDocuments"
          :key="index"
          class="list-group-item ml-n6">
          <div>{{ filterFilename(doc.name) }}</div>
          <button
            type="button"
            class="download-link"
            @click="downloadDoc(doc.fullPath, filterFilename(doc.name))">
            <span>Download</span>
            <i class="fa fa-download"></i>
          </button>
        </li>
      </ul>
    </div>

    <!-- admin documents -->
    <div
      v-if="app.adminDocuments.length > 0"
      class="admin-documents documents-list">
      <h5 class="section-title">
        Admin Documents
      </h5>
      <ul class="list-group">
        <li
          v-for="(doc, index) in app.adminDocuments"
          :key="index"
          class="list-group-item ml-n6">
          <div>{{ filterFilename(doc.name) }}</div>
          <button
            type="button"
            class="download-link"
            @click="downloadDoc(doc.fullPath, filterFilename(doc.name))">
            <span>Download</span>
            <i class="fa fa-download"></i>
          </button>
          <i
            v-if="canEdit"
            class="fa fa-close close admin-delete-doc"
            @click="removeDocument(doc)"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CreditReviewDocuments',
  props: {
    app: {
      required: true,
      type: Object
    },
    canEdit: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapState([
      'appConfig'
    ])
  },
  methods: {
    downloadDoc: function (fullPath, fileName) {
      this.$emit('downloadDoc_', fullPath, fileName)
    },
    downloadAllDocs: function (creditAppId) {
      this.$emit('downloadAllDocs_', creditAppId)
    },
    removeDocument: function (doc) {
      this.$emit('removeDocument_', doc)
    },
    uploadDocument: function () {
      this.$emit('uploadDocument_')
    },
    filterFilename (fileName) {
      const lastIndexOfSlash = fileName.lastIndexOf('/')
      if (lastIndexOfSlash < 0) {
        return fileName
      }
      const justFileName = fileName.substring(lastIndexOfSlash + 1)
      return justFileName.replace(/%20/g, ' ')
    }
  }
}
</script>

<style lang="scss">
#documents-component {
  label.tag {
    margin-bottom: 10px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .documents-list {
    margin-bottom: 1rem;
    &.admin-documents {
      .admin-delete-doc {
        position: absolute;
        right: 10px;
      }
      .download-link {
        margin-right: 40px;
      }
    }
    .section-title {
      margin-bottom: 1rem;
    }
    ul {
      li {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: .5rem;

        .download-link {
          font-size: .875rem;
          color: #004168;
          &:hover {
            text-decoration: none;
          }
          span {
            margin-block-start: 3px;
            &:hover {
              text-decoration: underline
            }
          }
        }
      }
    }
  }
}
</style>
