<template>
  <span></span>
</template>
<script>
import { initAuth, manager } from '@/auth'

export default {
  name: 'AuthCallback',
  async mounted () {
    if (manager == null) {
      await initAuth()
    } else {
      manager.signinRedirectCallback()
        .then(() => manager.getUser().then(user => console.log(user)))
        .then(() => this.$router.push({ path: '/' }))
        .catch((err) => {
          console.error(err)
          window.sessionStorage.clear()
          window.localStorage.clear()
          manager.signinRedirect()
        })
    }
  }
}
</script>
