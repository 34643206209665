<template>
  <v-dialog
    v-model="localIsDialogOpen"
    class="form-address-validation-modal"
    max-width="400px"
    :overlay-color="overlayColor"
    @close="close">
    <v-card>
      <v-toolbar
        flat
        color="grey lighten-3"
        class="grey--text text--lighten-1">
        <v-toolbar-title class="form-address-validation-modal__title">
          Validate Address
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-1"
          icon
          @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-row v-if="!validationSuccessful">
                <v-col cols="12">
                  Unable to validate address
                </v-col>
              </v-row>
              <form-address-validation-modal-address
                class="mb-3"
                :validation-successful="validationSuccessful"
                :address-obj="formDataAddress"></form-address-validation-modal-address>
              <form-address-validation-modal-address
                v-if="validationSuccessful"
                :address-obj="validatedAddress"
                is-validated></form-address-validation-modal-address>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="form-address-validation-modal__options">
        <v-btn
          class="form-address-validation-modal__button"
          tile
          text
          @click="clickContinueWithEntered">
          Continue with Entered
        </v-btn>
        <v-btn
          v-if="validationSuccessful"
          class="form-address-validation-modal__button form-address-validation-modal__button--done"
          tile
          color="#099c98"
          @click="updateAddress">
          Use Validated
        </v-btn>
        <v-btn
          v-else
          class="form-address-validation-modal__button form-address-validation-modal__button--done"
          tile
          color="#099c98"
          @click="editAddress">
          Edit Address
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormAddressValidationModalAddress from './FormAddressValidationModalAddress.vue'
export default {
  name: 'FormAddressValidationModal',
  components: { FormAddressValidationModalAddress },
  component: {
    FormAddressValidationModalAddress
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true
    },
    formDataAddress: {
      type: Object,
      required: true
    },
    validatedAddress: {
      type: Object,
      required: true
    },
    useValidated: {
      type: Boolean,
      required: true
    },
    overlayColor: {
      type: String,
      required: false,
      default: undefined
    },
    shouldUseEntered: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    validationSuccessful () {
      return Object.keys(this.validatedAddress).length > 0
    },
    localIsDialogOpen: {
      get () {
        return this.isDialogOpen
      },
      set () {
        this.close()
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clickContinueWithEntered () {
      if (this.shouldUseEntered) {
        this.$emit('useEntered')
      }
      this.$emit('close')
    },
    updateAddress () {
      this.close()
      this.$emit('updateAddress')
    },
    editAddress () {
      this.$emit('editAddress')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-address-validation-modal {
  &__title,
  &__button {
    margin: 0 0 0 1rem;
  }
  &__options {
    justify-content: flex-end;
    padding: 0 2rem 2rem 2rem;
  }
  &__button {
    &.v-btn {
      text-transform: none;
    }
    &--done {
      &.v-btn {
        color: #FFFFFF;
      }
    }
  }
}
</style>
