<template>
  <fieldset>
    <div class="row">
      <div class="gray-box">
        Job Account application has been submitted and will be reviewed by the Credit Department.
      </div>
      <div class="col-md-12">
        <div class="complete-label">
          Company:
        </div>
        <div class="complete-value">
          {{ submittedData.company }}
        </div>
      </div>
      <div class="col-md-12">
        <div class="complete-label">
          Company Account Number:
        </div>
        <div class="complete-value">
          {{ submittedData.companyAccountNumber }}
        </div>
      </div>
      <div v-if="$route.params.tenant !== 'platt'">
        <div class="col-md-12">
          <div class="complete-label">
            Pending Job Ship To AccountNumber:
          </div>
          <div class="complete-value">
            {{ submittedData.pendingJobAccountNumber }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="complete-label">
          Job Name:
        </div>
        <div class="complete-value">
          {{ submittedData.jobName }}
        </div>
      </div>
      <div class="col-md-12">
        <div class="complete-label">
          Job Limit Requested:
        </div>
        <div class="complete-value">
          {{ submittedData.jobLimitRequested }}
        </div>
      </div>
      <div class="col-md-12">
        <div class="complete-label">
          Assigned Branch:
        </div>
        <div class="complete-value">
          {{ assignedBranch }}
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'JobAccountFormComplete',
  props: {
    submittedData: {
      type: Object,
      required: true
    }
  },
  computed: {
    assignedBranch () {
      return this.$route.params.tenant === 'platt' ? `${this.submittedData.assignedBranch} - ${this.submittedData.assignedBranchNumber}` : this.submittedData.assignedBranch
    }
  }
}
</script>

<style lang="scss" scoped>
.complete-label {
  color: #777;
  font-size: 12px;
}

.complete-value {
  height: 1.4em;
}

.gray-box {
  // DRY ME, could maybe be a component? repeated.. here's a towel
  font-size: 14px;
  width: 100%;
  padding: 1em;
  margin: 1.2em 0;
  background-color: #eeeeee;
}
</style>
