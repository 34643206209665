<template>
  <div id="accordion">
    <div v-if="showDevControls">
      <pre>overwriteFormWithTestData: {{ overwriteFormWithTestData }} </pre>
      <DevSwitch
        :value="overwriteFormWithTestData"
        :input-label="overwriteFormWithTestData ? 'Reload Form' : 'Overwrite Form with Test Data'"
        store-action="toggleLoadWithTestData"></DevSwitch>
      <pre>showDevFormValidationState: {{ showDevFormValidationState }} </pre>
      <DevSwitch
        :value="showDevFormValidationState"
        input-label="Current Validations"
        store-action="toggleFormValidations"></DevSwitch>
    </div>

    <div
      v-if="currentOrder > 0"
      class="px-4 pt-3">
      <FormActions
        :order="currentOrder"
        :is-internal="isInternal"
        :validations="validations"
        :on-submit="onSubmit"
        :on-save="onSave"
        :on-email="onEmail"
        :email-sent="emailSent"
        :is-last-step="isLastStep"
        :sections="currentSectionNames"
        :title="currentTitle"
        :minimum-step-number-allowed="minimumStepNumberAllowed"
        @is-active="setActiveStep"
        @next-step="currentStep++"
        @previous-step="--currentStep"></FormActions>
    </div>

    <div v-if="!isSubmittedSuccessfully">
      <v-stepper
        v-model="currentStep"
        alt-labels
        class="elevation-0">
        <!-- v-if="minimumStepNumberAllowed === 1" -->
        <v-stepper-header

          class="elevation-0">
          <v-stepper-step
            :complete="currentStep > 1"
            :complete-icon="''"
            color="#1D4168"
            step="1"
            @click="goToStep(1)">
            <span class="text-body-2 text-sm-body-1">
              Details
            </span>
          </v-stepper-step>

          <v-flex :class="{completed: currentStep > 1}"></v-flex>

          <v-stepper-step
            :complete="currentStep > 2"
            :complete-icon="''"
            step="2"
            color="#1D4168"
            @click="goToStep(2)">
            <span class="text-body-2 text-sm-body-1">
              Contact
            </span>
          </v-stepper-step>

          <v-flex :class="{completed: currentStep > 2}"></v-flex>

          <v-stepper-step
            :complete="currentStep > 3"
            :complete-icon="''"
            step="3"
            color="#1D4168"
            @click="goToStep(3)">
            <span class="text-body-2 text-sm-body-1">
              3rd Party
            </span>
          </v-stepper-step>

          <v-flex :class="{completed: currentStep > 3}"></v-flex>

          <v-stepper-step
            :complete="currentStep > 4"
            :complete-icon="''"
            step="4"
            color="#1D4168"
            @click="goToStep(4)">
            <span class="text-body-2 text-sm-body-1">
              {{ signatoryTitle }}
            </span>
          </v-stepper-step>

          <v-flex
            v-if="!isInternal"
            :class="{completed: currentStep > 4}"></v-flex>

          <v-stepper-step
            v-if="!isInternal"
            color="#1D4168"
            step="5">
            <span class="text-body-2 text-sm-body-1">
              E-Sign
            </span>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="(stepObj, stepNumber) in stepsStatus"
            :key="stepNumber + 1"
            :step="stepNumber + 1"
            class="pt-3">
            <horizontal-step
              :id="`formStep${stepObj.order}`"
              :key="stepObj.order"
              :order="stepObj.order"
              :title="stepObj.title"
              :section-header-tool-tip="stepObj.sectionHeaderToolTip"
              :is-last-step="stepObj.order === steps.length"
              :on-save="onSave"
              :on-email="onEmail"
              :on-submit="onSubmit"
              :loading="loading"
              :sections="stepObj.sectionNames"
              :validations="validations"
              :is-collection-valid="isCollectionValid(stepObj)"
              :minimum-step-number-allowed="minimumStepNumberAllowed"
              :current-step="currentStep"
              :is-internal="isInternal"
              :email-sent="emailSent"
              @isSaveValid="isSaveValid"
              @is-active="setActiveStep"
              @next-step="currentStep++"
              @previous-step="--currentStep">
              <!--
              .definition files shows convention expected by dynamic component below

              FormMixin processes definition files to create the expected data structure
            -->

              <component
                :is="stepObj.component"
                v-if="currentStep >= stepObj.order"
                :group-data="stepObj.sectionData"
                :all-data="stepsStatus"
                :current-step="currentStep"
                :validations="validations"
                @on-comment="currentStep === steps.length ? bubbleEventUp($event, 'on-comment') : null"
                @on-completed="currentStep === steps.length ? bubbleEventUp($event, 'on-completed') : null"></component>
              <FormResponseBlock
                :success-message="successMessage"
                :error-message="errorMessage"
                :validation-failures="validationFailures"></FormResponseBlock>
            </horizontal-step>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import DevSwitch from './DevSwitch.vue'
import { mapGetters } from 'vuex'
import HorizontalStep from './HorizontalStep.vue'
import FormResponseBlock from './FormResponseBlockV2.vue'
import FormActions from './FormActions.vue'

export default {
  name: 'HorizontalStepper',
  components: {
    DevSwitch,
    HorizontalStep,
    FormResponseBlock,
    FormActions
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: function () {
        return 'stepped'
      },
      validator: function (value) {
        return [
          'stepped',
          'full'
        ].indexOf(value) !== -1
      }
    },
    steps: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    onSave: {
      type: Function,
      required: false,
      default: null
    },
    onEmail: {
      type: Function,
      required: false,
      default: null
    },
    onSubmit: {
      type: Function,
      required: true
    },
    emailSent: {
      type: Boolean,
      required: false,
      default: false
    },
    successMessage: {
      type: String,
      required: false,
      default: ''
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    validations: {
      type: Object,
      required: false,
      default: null
    },
    validationFailures: {
      type: Array,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    isSubmittedSuccessfully: {
      type: Boolean,
      required: true
    },
    submitLabel: {
      type: String,
      required: false,
      default: 'Submit'
    },
    startAtStep: {
      type: Number,
      required: false,
      default: null
    },
    cancelstartAtStep: {
      type: Function,
      required: false,
      default: null
    },
    isInternal: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      stepsStatus: [],
      currentStep: 0,
      customerNumber: null,
      form: { banner: 'platt' },
      saving: false,
      minimumStepNumberAllowed: 1
    }
  },
  computed: {
    ...mapGetters([
      'showDevFormValidationState',
      'overwriteFormWithTestData',
      'showDevControls',
      'company'
    ]),
    rexelEnergy () {
      return this.company === 'RexelEnergy' || this.$route.params.tenant === 'rexelenergy'
    },
    signatoryTitle () {
      if (this.rexelEnergy) {
        return 'Owner/Officers'
      } else {
        return 'Signatory'
      }
    },
    isLastStep () {
      if (this.currentStep > 0) {
        const currentStepOrder = this.steps[this.currentStep - 1].order
        if (currentStepOrder === this.steps.length) {
          return true
        }
      }
      return false
    },
    currentOrder () {
      let currentStepOrder = null
      if (this.currentStep > 0) {
        currentStepOrder = this.steps[this.currentStep - 1].order
      }
      return currentStepOrder
    },
    currentSectionNames () {
      let currentStepSectionNames = null
      if (this.currentStep > 0) {
        currentStepSectionNames = this.steps[this.currentStep - 1].sectionNames
      }
      return currentStepSectionNames
    },
    currentTitle () {
      let currentStepTitle = null
      if (this.currentStep > 0) {
        currentStepTitle = this.steps[this.currentStep - 1].title
      }
      return currentStepTitle
    },
    isStepInValid () {
      const validations = this.validations
      const multipleSignatories = validations.signatories.$model.signatories.length > 1
      let currentStepSectionNames = null
      if (this.currentStep > 0) {
        currentStepSectionNames = this.steps[this.currentStep - 1].sectionNames

        if (currentStepSectionNames[0] === 'signatories') {
          if (validations.signatories.signatory.$invalid) {
            return true
          } else if (validations.signatories.signatories.$model[1] && (validations.signatories.signatories.$model[0].email === validations.signatories.signatories.$model[1].email)) {
            return true
          } else if (validations.billingInfo.businessType.$model === 'Partnership' && validations.signatoryTwo.$invalid) {
            return true
          } else if (!validations.signatoryTwo.$invalid) {
            return false
          } else {
            return multipleSignatories
          }
        }

        return currentStepSectionNames.some(function (section) {
          return validations[section].$invalid
        })
      }
      return currentStepSectionNames
    }
  },
  watch: {
    currentStep () {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    steps: {
      handler (newVal) {
        const tenant = this.$route.params.tenant
        this.steps.forEach(step => {
          if (this.isStepForTenant(step, tenant)) {
            this.stepsStatus.push({
              ...step,
              isActive: this.isStepActive(step)
            })
          }
        })
      }
    },
    mode: {
      handler: function (newVal, oldVal) {
        if (this.mode === 'full') {
          // set all steps to active
          for (let i = 0; i < this.stepsStatus.length; i++) {
            this.stepsStatus[i].isActive = true
          }
        } else {
          // set just the first step to active
          for (let i = 0; i < this.stepsStatus.length; i++) {
            if (this.stepsStatus[i].order === 1) {
              this.stepsStatus[i].isActive = true
            } else {
              this.stepsStatus[i].isActive = false
            }
          }
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.currentStep = 1
      setInterval(() => {
        if (this.startAtStep) {
          this.currentStep = this.startAtStep
          this.minimumStepNumberAllowed = this.startAtStep
          this.goToStep(this.startAtStep)
          this.setActiveStep(this.stepsStatus[this.startAtStep - 1])
          this.$emit('cancelStartAtStep')
        }
      }, 0)
    })
  },
  methods: {
    // used to assign a property in steps directly to the component to maintain reactivity w/ steps array
    getStep (step) {
      const projectAddressStepIndex = this.steps.findIndex(s => s.stepKey === step.stepKey)
      return this.steps[projectAddressStepIndex]
    },
    goToStep (step) {
      //  && step > this.minimumStepNumberAllowed
      if (step < this.currentStep && step >= this.minimumStepNumberAllowed) {
        this.currentStep = step
      }
    },
    isStepActive (step) {
      return this.mode === 'full' ? true : step.order === 1
    },
    isStepForTenant (step, tenant) {
      return step.tenants == null || step.tenants.includes(tenant)
    },
    // NOTE: ugly hack to handle minLength on collections...
    isCollectionValid (step) {
      if (step.collectionInfo != null) {
        if (step.collectionInfo.required === false) {
          return null
        }

        const name = step.collectionInfo.collection
        const stepCollectionValidator = step.vuelidate[name]

        const isStepCollectionValid = stepCollectionValidator.$invalid && stepCollectionValidator.$dirty

        const isCollectionMinLength = step.data[step.collectionInfo.collection].length >= step.collectionInfo.minLength

        if (isCollectionMinLength) {
          step.vuelidate.$touch()
        }
        return isStepCollectionValid || isCollectionMinLength
      } else {
        return null
      }
    },
    setActiveStep (event) {
      this.stepsStatus.forEach(step => {
        if (event.isActive) {
          if (step.order === event.order) {
            step.isActive = event.isActive
          } else {
            step.isActive = !event.isActive
          }
        }
      })
    },
    stepStatusHandler (isActive, order) {
      this.stepsStatus.forEach(step => {
        if (isActive) {
          if (step.order === order) {
            step.isActive = isActive
          } else {
            step.isActive = !isActive
          }
        }
      })
    },
    nextStep () {
      this.stepStatusHandler()
      this.currentStep++
    },
    previousStep () {
      this.stepStatusHandler(true, this.order - 1)
      this.currentStep--
    },
    isSaveValid (event) {
      this.$emit('isSaveValid', event)
    },
    bubbleEventUp (event, functionName) {
      this.$emit(functionName, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.save-submit-buttons {
  margin: 3.2em 1.2em;
}

/* Could DRY up the nav-button by making separate component, reused in FormStep */
.nav-button {
  background-color: #099c98;
  font-size: 0.64rem;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

li {
  list-style-type: none;
}
.button-row {
  display: flex;
  flex-flow: row-reverse;
  padding-top: 28px;
  padding-right: 49px;
}
.v-btn {
  color: #fff !important;
  background-color: #004168 !important;
  font-size: 1rem !important;
  letter-spacing: normal !important;
  border-color: #004168;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: .5rem 1rem;
  margin-left: 10px;
  transition: all .2s ease-in-out;
  &.previous  {
    margin-right: 10px;
    color: black !important;
    background-color: #D8D8D8 !important;
  }
  &:hover{
    background-color: #002135 !important;
    border-color: #001b2b;
  }
}
</style>
