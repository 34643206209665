<template>
  <ul id="accordion">
    <div v-if="showDevControls">
      <pre>overwriteFormWithTestData: {{ overwriteFormWithTestData }} </pre>
      <DevSwitch
        :value="overwriteFormWithTestData"
        :input-label="overwriteFormWithTestData ? 'Reload Form' : 'Overwrite Form with Test Data'"
        store-action="toggleLoadWithTestData"></DevSwitch>
      <pre>showDevFormValidationState: {{ showDevFormValidationState }} </pre>
      <DevSwitch
        :value="showDevFormValidationState"
        input-label="Current Validations"
        store-action="toggleFormValidations"></DevSwitch>
    </div>

    <div
      v-if="isFormComplete"
      class="p-4 mb-4"
      style="background-color: #90C8AC; color: white;">
      Submitted by {{ submittedBy }}
      <span v-if="submittedOnDate != null">
        on {{ formatCompletedOnDate() }}
      </span>
    </div>

    <div v-if="steps != null">
      <div v-if="!isSubmittedSuccessfully">
        <FormStep
          v-for="(step, stepNumber) in stepsStatus"
          :id="`formStep${step.order}`"
          :key="step.order"
          :show="getStep(step).show"
          :mode="mode"
          :vuelidate="step.vuelidate"
          :order="step.order"
          :display-order="getDisplayOrder(stepNumber, step.isSubStep)"
          :title="step.title"
          :hide-circle="step.hideCircle"
          :is-sub-step="step.isSubStep"
          :active="step.isActive"
          :section-header-tool-tip="step.sectionHeaderToolTip"
          :is-last-step="step.order === steps.length"
          :on-save="onSave"
          :on-submit="onSubmit"

          :loading="loading"
          :is-collection-valid="isCollectionValid(step)"
          @is-active="setActiveStep">
          <!--
          .definition files shows convention expected by dynamic component below

          FormMixin processes definition files to create the expected data structure

          dynamic component expected prop details:

          * vuelidate - defines validation rules
          * formData - holds data from the form
          * formClass - defines the form schema
          * formLabels - holds field labels
        -->

          <component
            :is="step.component"
            v-if="getStep(step).show"
            :vuelidate="step.vuelidate"
            :form-data="step.data"
            :form-class="step.dataClass"
            :form-labels="step.labels"
            :form-state="step.formState"></component>
          <FormResponseBlock
            v-if="mode === 'stepped'"
            :success-message="successMessage"
            :error-message="errorMessage"
            :validation-failures="validationFailures"></FormResponseBlock>
        </FormStep>
        <v-app-bar
          :app="isInternal()"
          :bottom="isInternal()"
          :fixed="isInternal()"
          :flat="!isInternal()"
          color="#fff">
          <FormResponseBlock
                  v-if="mode === 'full'"
                  :success-message="successMessage"
                  :error-message="errorMessage"
                  :validation-failures="validationFailures">
          </FormResponseBlock>
          <v-spacer></v-spacer>
          <div v-if="!isFormComplete">
            <div
              v-if="mode === 'full' && onSave != null"
              class="save-submit-buttons">
              <button
                v-if="isInternal() && !hideSave"
                :disabled="loading || !isSaveValid"
                class="btn nav-button text-uppercase"
                @click="onSave()">
                Save
              </button>

              <button
                v-if="thirdButton"
                :disabled="loading || !isSubmitValid"
                class="btn nav-button text-uppercase"
                @click="thirdButtonAction">
                {{ thirdButton }}
              </button>

              <button
                v-if="!hideSubmit"
                :disabled="loading"
                class="btn nav-button text-uppercase"
                @click="checkValidations">
                {{ submitLabel }}
              </button>
            </div>

            <div
              v-if="mode === 'full' && onSave == null"
              class="save-submit-buttons">
              <button
                v-if="!hideSubmit"
                :disabled="loading"
                class="btn nav-button text-uppercase"
                @click="checkValidations">
                {{ submitLabel }}
              </button>
            </div>
          </div>
        </v-app-bar>
      </div>
      <div
        v-else
        style="margin-left: 1em;">
        <slot></slot>
      </div>
      <FormSaveNotification></FormSaveNotification>
      <PageSpinner :loading="loading"></PageSpinner>
    </div>
  </ul>
</template>

<script>
import DevSwitch from './DevSwitch.vue'
import { mapGetters } from 'vuex'
import FormStep from './FormStep.vue'
import FormResponseBlock from './FormResponseBlock.vue'
import FormSaveNotification from '@/components/form/FormSaveNotification.vue'
import PageSpinner from '@/components/PageSpinner.vue'

let displayOrderStep = 0

export default {
  name: 'FormStepper',
  components: {
    DevSwitch,
    FormStep,
    FormResponseBlock,
    FormSaveNotification,
    PageSpinner
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: function () {
        return 'stepped'
      },
      validator: function (value) {
        return [
          'stepped',
          'full'
        ].indexOf(value) !== -1
      }
    },
    invalid: {
      type: Boolean,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    onSave: {
      type: Function,
      required: false,
      default: null
    },
    isSaveValid: {
      type: Boolean,
      required: false,
      default: true
    },
    isSubmitValid: {
      type: Boolean,
      required: false,
      default: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    successMessage: {
      type: String,
      required: false,
      default: ''
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    validationFailures: {
      type: Array,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    isSubmittedSuccessfully: {
      type: Boolean,
      required: true
    },
    submitLabel: {
      type: String,
      required: false,
      default: 'Submit'
    },
    isFormComplete: {
      type: Boolean,
      required: false,
      default: false
    },
    submittedOnDate: {
      type: String,
      required: false,
      default: null
    },
    submittedBy: {
      type: String,
      required: false,
      default: null
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSave: {
      type: Boolean,
      required: false,
      default: false
    },
    thirdButton: {
      type: String,
      required: false,
      default: null
    },
    thirdButtonAction: {
      type: Function,
      required: false,
      default: null
    }
  },
  data () {
    return {
      stepsStatus: []
    }
  },
  computed: {
    ...mapGetters([
      'showDevFormValidationState',
      'overwriteFormWithTestData',
      'showDevControls',
      'stepsVisible'
    ])
  },
  watch: {
    steps: {
      handler (newVal) {
        const tenant = this.$route.params.tenant
        this.steps.forEach(step => {
          if (this.isStepForTenant(step, tenant)) {
            this.stepsStatus.push({
              ...step,
              isActive: this.isStepActive(step)
            })
          }
        })
      }
    },
    stepsVisible () {
      const tenant = this.$route.params.tenant
      const newSteps = []
      this.steps.forEach(step => {
        if (this.isStepForTenant(step, tenant) && this.isStepVisible(step)) {
          newSteps.push({
            ...step,
            isActive: this.isStepActive(step)
          })
        }
      })
      this.stepsStatus = newSteps
    },
    mode: {
      handler: function (newVal, oldVal) {
        if (this.mode === 'full') {
          // set all steps to active
          for (let i = 0; i < this.stepsStatus.length; i++) {
            this.stepsStatus[i].isActive = true
          }
        } else {
          // set just the first step to active
          for (let i = 0; i < this.stepsStatus.length; i++) {
            if (this.stepsStatus[i].order === 1) {
              this.stepsStatus[i].isActive = true
            } else {
              this.stepsStatus[i].isActive = false
            }
          }
        }
      }
    }
  },
  methods: {
    formatCompletedOnDate () {
      if (this.submittedOnDate != null) {
        // Format: 9/26/20 at 4:45 PM
        const date = new Date(this.submittedOnDate).toLocaleString()
        const segments = date.split(',')
        return segments[0] + ' at ' + segments[1]
      }
    },
    // used to assign a property in steps directly to the component to maintain reactivity w/ steps array
    getStep (step) {
      // If the form explicitly set which steps should be visible . . .
      if (this.$store.getters.stepsVisible && this.$store.getters.stepsVisible.length > 0) {
        const stepsVisibleIndex = this.$store.getters.stepsVisible.findIndex(s => s.stepKey === step.stepKey)
        return {
          show: this.$store.getters.stepsVisible[stepsVisibleIndex].visible
        }
      } else {
        // . . . otherwise to default behavior
        const projectAddressStepIndex = this.steps.findIndex(s => s.stepKey === step.stepKey)
        return this.steps[projectAddressStepIndex]
      }
    },
    incrementDisplayOrderNumber () {
      displayOrderStep = displayOrderStep + 1
    },
    getDisplayOrder (stepNumber, isSubStep) {
      if (stepNumber === 0) {
        displayOrderStep = 0
      }
      if (isSubStep !== true) {
        this.incrementDisplayOrderNumber()
      }
      return displayOrderStep
    },
    isStepActive (step) {
      return this.mode === 'full' ? true : step.order === 1
    },
    isStepForTenant (step, tenant) {
      return step.tenants == null || step.tenants.includes(tenant)
    },
    isStepVisible (step) {
      if (this.stepsVisible) {
        const correspondingBinding = this.stepsVisible.find(i => i.stepKey === step.stepKey)
        return correspondingBinding && correspondingBinding.visible
      }
      // Steps are visible by default
      return true
    },
    // NOTE: ugly hack to handle minLength on collections...
    isCollectionValid (step) {
      if (step.collectionInfo != null) {
        if (step.collectionInfo.required === false) {
          return null
        }

        const name = step.collectionInfo.collection
        const stepCollectionValidator = step.vuelidate[name]

        const isStepCollectionValid = stepCollectionValidator.$invalid && stepCollectionValidator.$dirty

        const isCollectionMinLength = step.data[step.collectionInfo.collection].length >= step.collectionInfo.minLength

        if (isCollectionMinLength) {
          step.vuelidate.$touch()
        }
        return isStepCollectionValid || isCollectionMinLength
      } else {
        return null
      }
    },
    setActiveStep (event) {
      // set the step that emits 'is-active' to active
      this.stepsStatus.forEach(step => {
        if (event.isActive) {
          if (step.order === event.order) {
            step.isActive = event.isActive
          } else {
            step.isActive = !event.isActive
          }
        }
      })
    },
    checkValidations () {
      if (this.invalid) {
        this.$emit('check-validations')
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } else {
        this.onSubmit()
      }
    },
    isInternal () {
      return this.$route.meta.isInternalForm === true
    }
  }
}
</script>

<style scoped>
.save-submit-buttons {
  margin: 3.2em 1.2em;
}

/* Could DRY up the nav-button by making separate component, reused in FormStep */
.nav-button {
  background-color: #099c98;
  font-size: 0.64rem;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

li {
  list-style-type: none;
}
</style>
