<template>
  <fieldset>
    <div class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.companyName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.companyName"
          :input-label="formLabels.companyName"
          input-type="text"></FormInputTextInput>
      </div>
    </div>
    <div class="row">
    </div>

    <div class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.companyTelephoneNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.companyTelephoneNumber"
          :input-label="formLabels.companyTelephoneNumber"
          input-type="tel"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.companyEmailAddress"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.companyEmailAddress"
          :input-label="formLabels.companyEmailAddress"
          input-type="email"></FormInputTextInput>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.federalTaxIdNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.federalTaxIdNumber"
          :input-label="formLabels.federalTaxIdNumber"
          input-type="ein"></FormInputTextInput>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'

export default {
  name: 'InstantCreditFormStepCompanyInformation',
  components: {
    FormInputTextInput
  },
  mixins: [
    FormStepImplementationMixin
  ]
}
</script>
