<template>
  <v-dialog
    v-model="dialogOpen"
    max-width="600px">
    <v-card>
      <v-toolbar
        flat
        color="#006330"
        class="white--text text--lighten-1">
        <v-toolbar-title class="ml-2">
          Information may be lost
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-1"
          icon
          @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="subtitle-1 text--primary my-4">
              <v-row>
                If you leave this page, any unsaved information will be lost
              </v-row>
              <v-row>
                Do you wish to save or proceed without saving?
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-4 pb-4 justify-end">
        <v-btn
          class="ml-1 form-navigation-confirmation-modal__button"
          tile
          text
          @click="close">
          Cancel
        </v-btn>
        <v-btn
          v-if="isSaveValid"
          class="form-navigation-confirmation-modal__button"
          tile
          dark
          color="#032D2C"
          @click="saveAndNavigate">
          Save
        </v-btn>
        <v-btn
          class="form-navigation-confirmation-modal__button"
          tile
          dark
          color="#032D2C"
          @click="discardAndNavigate">
          Proceed without saving
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormNavigationConfirmationModal',
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true
    },
    isSaveValid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dialogOpen: {
      get () {
        return this.isDialogOpen
      },
      set (newVal) {
        if (!newVal) {
          this.close()
        }
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    saveAndNavigate () {
      this.close()
      this.$emit('saveAndNavigate')
    },
    discardAndNavigate () {
      this.close()
      this.$emit('discardAndNavigate')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-navigation-confirmation-modal {
  &__button {
    &.v-btn {
      text-transform: none;
    }
    &--done {
      &.v-btn {
        color: #FFFFFF;
      }
    }
  }
}
</style>
