<template>
  <GenericModal
    v-if="modalState === true"
    v-cloak
    id="approve-modal"
    @close="modalState = false"
    @closeModal="modalState = false">
    <template slot="header">
      Approve Credit Application
    </template>
    <div slot="body">
      <!-- approve -->
      <div
        v-if="approve.creditApprovalObj.creditReps"
        id="approve-credit-line"
        ref="approve-credit-line"
        class="tab">
        <div :class="{'has-danger': $v.approve.creditApprovalObj.accountId.$error }">
          <div v-if="app.erpEntityInfo">
            <label>Account #:</label>
            <strong>{{ approve.creditApprovalObj.accountId }}</strong>
          </div>
          <div v-else>
            <label>Account #:</label>
            <div class="input-group">
              <span class="input-group-addon">
                #
              </span>
              <input
                v-model="approve.creditApprovalObj.accountId"
                v-mask="'######################'"
                type="text"
                class="form-control form-control-row"
                :class="{'form-control-danger': $v.approve.creditApprovalObj.accountId.$error }" />
            </div>
          </div>
        </div>
        <div :class="{'has-danger': $v.approve.creditApprovalObj.creditAmount.$error }">
          <label>Credit Line Amount:</label>
          <div class="input-group">
            <span class="input-group-addon">
              $
            </span>
            <input
              v-model.number="approve.creditApprovalObj.creditAmount"
              type="number"
              class="form-control form-control-row"
              min="100"
              step="1"
              pattern="^\d+$"
              :class="{'form-control-danger': $v.approve.creditApprovalObj.creditAmount.$error }"
              @keydown.16.69.107.109.110.190.187.189.prevent />
          </div>
          <div>
            <span
              v-if="$v.approve.creditApprovalObj.creditAmount.$error"
              class="error-msg">
              Credit amount must be greater than 100
            </span>
          </div>
        </div>
        <div :class="{'has-danger': $v.approve.creditApprovalObj.branchNumber.$error }">
          <label>Branch:</label>
          <strong v-if="originalBranchNumberMessage">
            {{ originalBranchNumberMessage }}
          </strong>
          <div class="input-group">
            <select
              type="text"
              class="form-control rep-list"
              :value="approve.creditApprovalObj.branchNumber"
              :class="{'form-control-danger': $v.approve.creditApprovalObj.branchNumber.$error}"
              @blur="$v.approve.creditApprovalObj.branchNumber.$touch()"
              @change="selectBranch($event)">
              <option
                v-for="b in approve.creditApprovalObj.branches"
                :key="b.branchNumber"
                :value="b.branchNumber"
                :selected="b.branchNumber == approve.creditApprovalObj.branchNumber">
                <span v-if="b.branchNumber === 0">
                  {{ b.name }}
                </span>
                <span v-else>
                  {{ b.branchNumber }}  - {{ b.name }}
                </span>
                <span v-if="b.distance > 0">
                  - {{ b.distance | round('1') }}mi
                </span>
              </option>
            </select>
          </div>
        </div>
        <div :class="{'has-danger': $v.approve.creditApprovalObj.rep.$error }">
          <label>Credit Representative:</label>
          <div class="input-group">
            <select
              type="text"
              class="form-control rep-list"
              :value="approve.creditApprovalObj.rep"
              :class="{'form-control-danger': $v.approve.creditApprovalObj.rep.$error}"
              @blur="$v.approve.creditApprovalObj.rep.$touch()"
              @change="selectRep($event)">
              <option
                v-for="rep in approve.creditApprovalObj.creditReps"
                :key="rep.userId"
                :value="rep.userId">
                {{ rep.fullName }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="isEclipseBanner && app.businessInfo.hasAccount"
          class="clearfix mt-4">
          <div class="pull-left mr-3">
            <input
              v-model="approve.creditApprovalObj.uploadAttachments"
              type="checkbox"
              :checked="approve.creditApprovalObj.uploadAttachments" />
          </div>
          Send Uploaded Attachments to Eclipse
        </div>
        <div class="clearfix mt-6">
          <div class="pull-left mr-3">
            <input
              v-model="approve.creditApprovalObj.letter"
              type="checkbox"
              :checked="approve.creditApprovalObj.letter" />
          </div>
          Send Approval Letter
        </div>
      </div>
    </div>
    <!-- approve -->
    <template slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="modalState = false">
        Cancel
      </a>
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        :disabled="disableApprove"
        @click="approveCreditReview()">
        Continue
      </button>
    </template>
  </GenericModal>
</template>

<script>
import axios from 'ca-http-service'
import GenericModal from '../../../../components/GenericModal.vue'
import { required, numeric, minLength } from 'vuelidate/lib/validators'
import {
  getCustomerAccountInfo
} from '@/api/company/account-info'

export default {
  name: 'ApprovalModal',
  components: {
    GenericModal
  },
  props: {
    app: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      modalState: false,
      approve: {
        creditApprovalObj: {
          accountId: null,
          creditAmount: null,
          creditReps: null,
          rep: null,
          branches: null,
          branchNumber: null,
          letter: true,
          uploadAttachments: true
        },
        creditPendingObj: {
          reason: '',
          letter: true
        }
      },
      originalBranchNumberMessage: null,
      loadingBranches: true,
      businessInfo: null
    }
  },
  computed: {
    isEclipseBanner () {
      return this.app.bannerId === 'Rexel' || this.app.bannerId === 'Gexpro'
    },
    disableApprove () {
      return this.loadingBranches || this.approve.creditApprovalObj.branchNumber === 0
    }
  },
  watch: {
    modalState: function () {
      if (this.modalState === true) {
        this.getRep()
        this.getBranches()
      } else {
        this.approve.creditApprovalObj.creditAmount = null
      }
    },
    'app.tenantId': {
      handler (newVal) {
        if (newVal > 0) {
          this.getRep()
          this.getBranches()
        }
      }
    }
  },
  async mounted () {
    await this.getBusinessInfo()
  },
  methods: {
    approveCreditReview: function () {
      this.$v.approve.$touch()
      if (!this.$v.approve.$invalid) {
        this.$emit('approveCreditReview_', this.app.guid, this.approve.creditApprovalObj)
      }
    },
    getRep: function () {
      axios.get('/api/CreditReview/getAvailableCreditReps', {
        params: {
          tenantId: this.app.bannerId
        }
      })
        .then((res) => {
          this.approve.creditApprovalObj.creditReps = res.data
          this.approve.creditApprovalObj.creditReps.unshift({
            active: true,
            bannerId: 0,
            fullName: '- Select Credit Representative -',
            userId: null
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getBranches: function () {
      const self = this
      if (self.app.creditAppId === 0) { return }
      const tenant = self.app.bannerId
      const applicationId = self.app.guid

      this.loadingBranches = true
      axios
        .get(`/api/CreditReview/getBranchList/${tenant}/${applicationId}`)
        .then((res) => {
          if (res.data.length > 0) {
            self.approve.creditApprovalObj.branches = res.data
          } else {
            self.approve.creditApprovalObj.branches = self.$store.getters.branches
          }
          self.approve.creditApprovalObj.branches.unshift({
            branchNumber: 0,
            name: '- Select Branch -',
            address: null
          })

          if (self.app.branchNumber !== null && self.app.branchNumber > 0) {
            // Application has a non-zero branchNumber
            self.approve.creditApprovalObj.branchNumber = self.app.branchNumber

            // Present message to Credit Team if Applicant specified branchNumber
            const branch = self.approve.creditApprovalObj.branches.find(branch => branch.branchNumber === this.app.branchNumber.toString())
            if (branch) {
              this.originalBranchNumberMessage = `(${this.app.branchNumber} - ${branch.name} was selected by the applicant)`
            }
          } else if (this.businessInfo && this.businessInfo.branchNumber && this.businessInfo.branchNumber > 0) {
            // Application has a zero branchNumber but businessInfo has a non-zero branchNumber
            self.approve.creditApprovalObj.branchNumber = this.businessInfo.branchNumber

            // Present message to Credit Team if Home Branch
            const branch = self.approve.creditApprovalObj.branches.find(branch => branch.branchNumber === this.businessInfo.branchNumber.toString())
            if (branch) {
              this.originalBranchNumberMessage = `(${this.businessInfo.branchNumber} - ${branch.name} is the home branch of account # ${this.approve.creditApprovalObj.accountId})`
            }
          } else {
            // branchNumber can not be determined - show 'Select Branch'
            self.approve.creditApprovalObj.branchNumber = self.approve.creditApprovalObj.branches[0].branchNumber
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.loadingBranches = false
        })
    },
    selectRep: function (event) {
      this.approve.creditApprovalObj.rep = event.target.value
    },
    selectBranch: function (event) {
      this.approve.creditApprovalObj.branchNumber = event.target.value
    },
    async getBusinessInfo () {
      const tenant = this.$route.params.tenant === 'platt' ? 'platt' : 'rexel'
      this.businessInfo = await getCustomerAccountInfo(this.app.businessInfo.accountNumber, tenant)
    }
  },
  validations () {
    return {
      approve: {
        creditApprovalObj: {
          accountId: {
            required,
            minLength: minLength(1),
            numeric
          },
          creditAmount: {
            required,
            minLength: minLength(3),
            numeric
          },
          rep: {
            required,
            numeric
          },
          branchNumber: {
            required
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
#approve-modal {
  .hide {
    display: none;
  }
  .checkbox-group {
    margin: 1.75rem 0 1rem;
  }
  label {
    margin-bottom: 0;
    margin-top: 1rem
  }
  .modal-body {
    padding-bottom: 2rem;
  }
  .error-msg {
    float: none;
    font-size: 14px;
    color: #a00
  }
  .rep-list, .rep-list option {
    text-transform: capitalize;
  }
}
</style>
