import JobAccountFormStepAccountInformation from './JobAccountFormStepAccountInformation.vue'
import JobAccountFormStepCompanyInformation from './JobAccountFormStepCompanyInformation.vue'
import JobAccountFormStepProjectInformation from './JobAccountFormStepProjectInformation.vue'
import JobAccountFormStepProjectAddress from './JobAccountFormStepProjectAddress.vue'
import JobAccountFormStepProjectMaterial from './JobAccountFormStepProjectMaterial.vue'
import JobAccountFormStepProjectContacts from './JobAccountFormStepProjectContacts.vue'
import JobAccountFormStepBranchInformation from './JobAccountFormStepBranchInformation.vue'
import JobAccountFormStepGeneralContractorInformation from './JobAccountFormStepGeneralContractorInformation.vue'
import JobAccountFormStepPropertyOwnerInformation from './JobAccountFormStepPropertyOwnerInformation.vue'
import JobAccountFormStepDocuments from './JobAccountFormStepDocuments.vue'
import JobAccountFormStepEmail from './JobAccountFormStepEmail.vue'

export const ACCOUNT_INFORMATION = 'accountInformation'
export const COMPANY_INFORMATION = 'companyInformation'
export const PROJECT_INFORMATION = 'projectInformation'
export const PROJECT_ADDRESS = 'projectAddress'
export const PROJECT_MATERIAL = 'projectMaterial'
export const PROJECT_CONTACTS = 'projectContacts'
export const PROJECT_CONTACT = 'projectContact'
export const BRANCH_INFORMATION = 'branchInformation'
export const GENERAL_CONTRACTOR = 'generalContractor'
export const PROPERTY_OWNER_INFORMATION = 'propertyOwnerInformation'
export const DOCUMENTS = 'documents'
export const EMAIL = 'email'

export const formTitle = 'Job Account'

export const formDefinitions = {
  [ACCOUNT_INFORMATION]: {
    order: 1,
    tenants: [
      'rexel',
      'gexpro',
      'rexelenergy'
    ],
    title: 'Account Information',
    component: JobAccountFormStepAccountInformation,
    dataClass: class AccountInformation {
      constructor () {
        this.billToAccount = ''
        this.shipToAccount = ''
        this.shipTo = {}
        this.billTo = {}
      }
    },
    testData: {
      billToAccount: '329316', // rexel - has multiple shipTos
      // billToAccount: '786839', // rexel - has no shipTo // shows error message for no ship to under dropdown component
      // billToAccount: '200782', // rexel - account flagged as bill to only // triggers an eclipse-side error
      shipToAccount: '300156'
    },
    labels: {
      billToAccount: 'Bill To Account:',
      shipToAccount: 'Ship To Account:'
    }
  },
  [COMPANY_INFORMATION]: {
    order: 2,
    tenants: [
      'platt'
    ],
    title: 'Customer Information',
    component: JobAccountFormStepCompanyInformation,
    dataClass: class CompanyInformation {
      constructor () {
        this.accountNumber = ''
        this.accountName = ''
        this.contactNumber = ''
        this.emailAddress = ''
        this.streetAddress = ''
        this.streetAddress2 = ''
        this.addressUnitNumber = ''
        this.city = ''
        this.country = ''
        this.state = ''
        this.postalCode = ''
        this.plattBranch = '' // TODO: not sure if needed, investigating usage on BE.
      }
    },
    testData: {
      accountNumber: {
        value: 49660,
        text: 'Test Account 49660'
      }
      // accountNumber: '49660',
      // accountName: "Test Account",
      // contactNumber: "1234567890",
      // emailAddress: "test@nowhere.com",
      // streetAddress1: "405 SE 30th Pl",
      // city: "Portland",
      // state: "OR",
      // postalCode: "97214",
      // country: 'US',
      // plattBranch: "1"
    },
    labels: {
      accountNumber: 'Customer Account:',
      accountName: 'Account Name:',
      contactNumber: 'Contact Number:',
      emailAddress: 'Email Address:',
      streetAddress: 'Street Address:',
      streetAddress2: 'Street Address 2:',
      addressUnitNumber: 'Unit:',
      city: 'City:',
      country: 'Country:',
      state: 'State:',
      postalCode: 'Postal Code:',
      plattBranch: ''
    }
  },
  [BRANCH_INFORMATION]: {
    order: 3,
    tenants: [
    ],
    title: 'Branch Information',
    component: JobAccountFormStepBranchInformation,
    dataClass: class BranchInformation {
      constructor () {
        this.branchId = ''
        this.branchName = ''
        this.errorMessage = ''
      }
    },
    labels: {
      branchId: 'Branch:',
      branchName: 'Branch Name:'
    }
  },
  [PROJECT_INFORMATION]: {
    order: 4,
    title: 'Job Information',
    component: JobAccountFormStepProjectInformation,
    dataClass: class ProjectInformation {
      constructor () {
        this.name = ''
        this.jobType = ''
        this.jobDescription = ''
        this.additionalDescription = ''
        this.purchaseOrderNumber = ''
        this.contractNumber = '' // This field is know as JobID for Platt
        this.startDate = ''
        this.endDate = ''
        this.natureOfProject = ''
        this.natureOfProjectDescription = ''
        this.isEdiCustomer = ''
        this.ediType = ''
        this.nuclearProject = ''
        this.isJobBonded = ''
        this.isMbeOrDbe = ''
        this.isTaxExempt = ''
        this.isWantingDualPartyCheckArrangement = ''
        this.isExportJob = ''
        this.storageGuid = ''
        this.selectedDocuments = []
        this.bondedDocuments = ''
        this.uploadedDocuments = ''
        this.bondCompanyName = ''
        this.bondCompanyContactNumber = ''
        this.bondCompanyCountry = ''
        this.bondCompanyStreetAddress1 = ''
        this.bondCompanyStreetAddress2 = ''
        this.bondCompanyCity = ''
        this.bondCompanyState = ''
        this.bondCompanyPostalCode = ''
        this.confirmationEmail = ''
        this.plattTaxExemptCertificateAlreadyOnFile = false // value not saved in db
      }
    },
    testData: {
      name: 'test Project',
      natureOfProject: 'Addition to Structure',
      natureOfProjectDescription: 'Guest house Hot Tub',
      jobType: 'Public',
      jobDescription: 'Commercial',
      additionalDescription: 'Adding new wing to commercial structure',
      purchaseOrderNumber: 'PO-552125',
      contractNumber: '12345',
      contactNumber: '9822545525-001',
      startDate: '05/09/1999',
      endDate: '05/09/2020',
      nuclearProject: '0',
      isJobBonded: '1',
      bondCompanyName: 'SuperBond, Inc.',
      bondCompanyContactNumber: '9999999999',
      bondCompanyCountry: 'US',
      bondCompanyStreetAddress1: '555 Insustrial Dr.',
      bondCompanyCity: 'Anywhere',
      bondCompanyState: 'CA',
      bondCompanyPostalCode: '97214',
      isMbeOrDbe: '0',
      isTaxExempt: '0',
      isWantingDualPartyCheckArrangement: '0',
      isExportJob: '0',
      confirmationEmail: 'webdevtesters@platt.com'
    },
    labels: {
      bondedDocuments: 'Select files to add',
      selectedDocuments: 'Select file to add',
      uploadedDocuments: '',
      storageGuid: 'This should not be displayed',
      name: 'Job Name:',
      jobType: 'Job Type:',
      jobDescription: 'Job Description',
      additionalDescription: 'Additional Description',
      purchaseOrderNumber: 'Purchase Order Number:',
      contractNumber: 'Contract Number:',
      startDate: 'Start Date:',
      endDate: 'End Date:',
      natureOfProject: 'Nature of Project:',
      natureOfProjectDescription: 'Other:',
      isEdiCustomer: 'EDI Customer:',
      ediType: 'EDI Type:',
      nuclearProject: 'Nuclear Project:',
      isJobBonded: 'Is this Job bonded?:',
      bondCompanyName: 'Bond Company:',
      bondCompanyContactNumber: 'Contact Number:',
      bondCompanyCountry: 'Country:',
      bondCompanyStreetAddress1: 'Street Address 1:',
      bondCompanyStreetAddress2: 'Street Address 2:',
      bondCompanyCity: 'City:',
      bondCompanyState: 'State:',
      bondCompanyPostalCode: 'Postal Code:',
      isMbeOrDbe: 'Is this customer a Minority Business Enterprise (MBE) or a Disadvantaged Business Enterprise (DBE)?:',
      isTaxExempt: 'Tax Exempt:',
      isWantingDualPartyCheckArrangement: 'Do you wish to setup a dual-party check arrangement?:',
      isExportJob: 'Export Job:  This should be Yes if any order or return will be shipped outside the USA.',
      confirmationEmail: 'Confirmation Email:',
      plattTaxExemptCertificateAlreadyOnFile: 'Our Tax Exempt Certificate is already on file with Platt'
    }
  },
  [PROJECT_ADDRESS]: {
    order: 5,
    title: 'Job Address',
    component: JobAccountFormStepProjectAddress,
    dataClass: class ProjectAddress {
      constructor () {
        this.streetAddress1 = ''
        this.streetAddress2 = ''
        this.city = ''
        this.state = ''
        this.postalCode = ''
        this.alternateShipTos = []
        this.isShipToShop = ''
        this.whyShipToAlt = ''
        this.whereIsItShipping = ''
        this.isSingleOrder = ''
        this.orderAmount = ''
        this.isNewCustomer = ''
        this.isFirstJob = ''
        this.isThereAnyCollectionIssues = ''
        this.issuesAndComments = ''
      }
    },
    testData: {
      streetAddress1: '1 Workstie Cr.',
      city: 'Portland',
      state: 'OR',
      postalCode: '97214'
    },
    labels: {
      streetAddress1: 'Street Address:',
      streetAddress2: 'Street Address 2:',
      city: 'City:',
      state: 'State:',
      postalCode: 'Postal Code:',
      isShipToShop: 'Is the product shipping to shop?',
      whyShipToAlt: 'Why is the product shipping to an alternate address?',
      whereIsItShipping: 'Where is it shipping?',
      isSingleOrder: 'Single Order or Total Job?',
      orderAmount: 'What is the amount of the order?',
      isNewCustomer: 'New or Existing Customer?',
      isFirstJob: 'Is this first job we have done?',
      isThereAnyCollectionIssues: 'Any collection issues you are aware of?',
      issuesAndComments: 'Issues/Comments'
    }
  },
  [PROJECT_MATERIAL]: {
    order: 6,
    title: 'Job Material',
    component: JobAccountFormStepProjectMaterial,
    dataClass: class ProjectMaterial {
      constructor () {
        this.requestedJobLimit = ''
        this.materialsNeeded = ''
        this.otherMaterials = ''
      }
    },
    testData: {
      requestedJobLimit: '1',
      materialsNeeded: '',
      otherMaterials: ''
    },
    labels: {
      requestedJobLimit: 'Requested Job Limit (Estimated Cost of Materials)',
      materialsNeeded: 'Materials Needed (Check all that apply):',
      otherMaterials: 'Other Materials:'
    }
  },
  [PROJECT_CONTACTS]: {
    order: 7,
    tenants: [
      'rexel',
      'gexpro',
      'rexelenergy'
    ],
    title: 'Job Contacts',
    component: JobAccountFormStepProjectContacts,
    dataClass: class ProjectContact {
      constructor () {
        this.firstName = ''
        this.lastName = ''
        this.contactNumber = ''
        this.emailAddress = ''
        this.jobTitle = ''
        this.errorMessage = ''
        this.projectContacts = []
      }
    },
    testData: {
      firstName: 'Test',
      lastName: 'Testerson',
      contactNumber: '(408) 867-5309',
      emailAddress: 'test.testerson@nowhere.com',
      jobTitle: 'Test Contact'
    },
    labels: {
      firstName: 'First Name:',
      lastName: 'Last Name:',
      contactNumber: 'Contact Number:',
      emailAddress: 'Email Address:',
      jobTitle: 'Job Title:'
    }
  },
  [GENERAL_CONTRACTOR]: {
    order: 8,
    title: 'General Contractor Information',
    component: JobAccountFormStepGeneralContractorInformation,
    dataClass: class GeneralContractorInformation {
      constructor () {
        this.errorMessage = ''
        this.sameAsCompanyInformation = ''
        this.generalContractorName = ''
        this.generalContractorContactNumber = ''
        this.generalContractorStreetAddress1 = ''
        this.generalContractorStreetAddress2 = ''
        this.generalContractorCity = ''
        this.generalContractorState = ''
        this.generalContractorPostalCode = ''

        // subcontractor
        this.addSubContractor = ''
        this.subSameAsCompanyInformation = ''
        this.subContractorName = ''
        this.subContractorContactNumber = ''
        this.subContractorStreetAddress1 = ''
        this.subContractorStreetAddress2 = ''
        this.subContractorCity = ''
        this.subContractorState = ''
        this.subContractorPostalCode = ''
      }
    },
    testData: {
      generalContractorName: 'XYZ Contracting',
      generalContractorContactNumber: '(408) 867-5309',
      generalContractorStreetAddress1: '1337 Leet Rd.',
      generalContractorCity: 'Somewhere',
      generalContractorState: 'OR',
      generalContractorPostalCode: '97214',

      // sub contractor
      subContractorName: 'H&H Contracting',
      subContractorContactNumber: '(408) 867-5309',
      subContractorStreetAddress1: '2080 Nvidia Ln.',
      subContractorStreetAddress2: 'Suite Ti',
      subContractorCity: 'Anywhere',
      subContractorState: 'CA'
    },
    labels: {
      generalContractorName: 'Name:',
      generalContractorContactNumber: 'Contact Number:',
      generalContractorStreetAddress1: 'Street Address 1:',
      generalContractorStreetAddress2: 'Street Address 2:',
      generalContractorCity: 'City:',
      generalContractorState: 'State:',
      generalContractorPostalCode: 'Postal Code:',

      // sub contractor
      addSubContractor: 'Would you like to add a Sub-Contractor?',
      subContractorName: 'Name:',
      subContractorContactNumber: 'Contact Number:',
      subContractorStreetAddress1: 'Street Address 1:',
      subContractorStreetAddress2: 'Street Address 2:',
      subContractorCity: 'City:',
      subContractorState: 'State:',
      subContractorPostalCode: 'Postal Code:'
    }
  },
  [PROPERTY_OWNER_INFORMATION]: {
    order: 9,
    title: 'Property Owner Information',
    component: JobAccountFormStepPropertyOwnerInformation,
    dataClass: class PropertyOwnerInformation {
      constructor () {
        this.isSameAsProjectInformation = ''
        this.name = ''
        this.contactNumber = ''
        this.streetAddress1 = ''
        this.streetAddress2 = ''
        this.city = ''
        this.state = ''
        this.postalCode = ''

        // lien agents
        this.doesNotHaveNCOnly = ''
        this.lienAgentName = ''
        this.lienAgentContactNumber = ''
        this.lienAgentStreetAddress1 = ''
        this.lienAgentStreetAddress2 = ''
        this.lienAgentCity = ''
        this.lienAgentState = ''
        this.lienAgentPostalCode = ''
      }
    },
    testData: {
      name: 'Snappy Title & Loan',
      contactNumber: '(408) 867-5309',
      streetAddress1: '557 Business Ave.',
      city: 'Somewhere',
      state: 'OR',
      postalCode: '97214',
      isSameAsProjectInformation: true,

      // lien agents
      doesNotHaveNCOnly: '1',
      lienAgentName: 'Bob Tester',
      lienAgentContactNumber: '(666) 666-6666',
      lienAgentStreetAddress1: 'Test Avenue',
      lienAgentStreetAddress2: '',
      lienAgentCity: 'Brooklyn',
      lienAgentState: 'NY',
      lienAgentPostalCode: '97214'
    },
    labels: {
      isSameAsProjectInformation: 'Same as Job Information and Job Address?:',
      name: 'Name:',
      contactNumber: 'Contact Number:',
      streetAddress1: 'Street Address:',
      streetAddress2: 'Street Address 2:',
      city: 'City:',
      state: 'State:',
      postalCode: 'Postal Code:',

      // lien agents
      doesNotHaveNCOnly:
      'Is there a lien agent? (North Carolina Customers only):',
      lienAgentName: 'Name:',
      lienAgentContactNumber: 'Contact Number:',
      lienAgentStreetAddress1: 'Street Address:',
      lienAgentStreetAddress2: 'Street Address 2:',
      lienAgentCity: 'City:',
      lienAgentState: 'State:',
      lienAgentPostalCode: 'Postal Code:'
    }
  },
  [DOCUMENTS]: {
    order: 10,
    title: 'Documents',
    component: JobAccountFormStepDocuments,
    dataClass: class UploadApplication {
      constructor () {
        this.storageGuid = ''
        this.selectedDocuments = []
        this.uploadedDocuments = ''
        this.isUploaded = ''
        this.notes = ''
      }
    },
    labels: {
      selectedDocuments: 'Select files to add',
      uploadedDocuments: '',
      storageGuid: 'This should not be displayed',
      notes: 'Notes:'
    },
    testData: {
      storageGuid: '',
      selectedDocuments: [],
      notes: ''
    }
  },
  [EMAIL]: {
    order: 11,
    tenants: [
      'platt'
    ],
    title: 'Email',
    component: JobAccountFormStepEmail,
    dataClass: class Email {
      constructor () {
        this.email = ''
        this.addEmail = ''
      }
    },
    labels: {
      email: 'Email:',
      addEmail: 'Send customer an email notification after submission?'
    }
  }
}
