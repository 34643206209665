import axios from 'axios'
export default axios.create({
  baseURL: determineApiEndpoint(),
  headers: { Pragma: 'no-cache' } // prevents IE from caching data so we can always call endpoint
})
export function determineApiEndpoint () {
  const location = window.location
  let host = location.host
  if (host.endsWith(':5000')) {
    host = host.replace(':5000', ':5001')
  }
  return 'https://' + host
}
