<template>
  <div
    class="form-input-textarea"
    style="
    display: grid;
    margin-bottom: .6em;
  ">
    <label
      v-if="!hideLabel"
      class="form-control-label"
      :class="$isRequired() && !disabled ? 'required' : ''"
      style="font-size: 12px;">
      {{ inputLabel }}
    </label>
    <v-textarea
      v-model="localValue"
      :disabled="disabled"
      :rows="rows"
      outlined>
    </v-textarea>
  </div>
</template>

<script>

import FormInputMixin from './FormInput.mixin'

export default {
  name: 'FormInputTextarea',
  components: { },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    rows: {
      type: Number,
      required: false,
      default: 5
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.fa-info-circle {
  color: #5bc0de;
  cursor: pointer;
  font-size: 16px;
}
</style>
