<template>
  <fieldset>
    <div class="row">
      <div class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.name"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.name"
          :input-label="formLabels.name"
          input-type="text"></FormInputTextInput>
      </div>
      <div v-if="!isInternal()"
        class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.confirmationEmail"
          persist-label
          :vuelidate="vuelidate.confirmationEmail"
          :input-label="formLabels.confirmationEmail"
          input-type="email">
        </FormInputTextInput>
      </div>
      <div class="col-md-3">
        <FormInputDropdown
          v-if="isPlatt() && isInternal()"
          v-model="userFormInput.jobType"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.jobType"
          :input-label="formLabels.jobType"
          :options="jobTypeOptions"></FormInputDropdown>
        <FormInputRadioOptions
          v-else
          v-model="userFormInput.jobType"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.jobType"
          :input-label="formLabels.jobType"
          :options="jobTypeOptions"></FormInputRadioOptions>
      </div>
      <div class="col-md-3">
        <FormInputDropdown
          v-if="isPlatt() && isInternal()"
          v-model="userFormInput.jobDescription"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.jobDescription"
          :input-label="formLabels.jobDescription"
          :options="jobDescriptionOptions"></FormInputDropdown>
      </div>
    </div>
    <div v-if="isPlatt() && !isInternal()"
      class="row">
      <div class="col-md-3">
        <FormInputDropdown
          v-if="isPlatt()"
          v-model="userFormInput.jobDescription"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.jobDescription"
          :input-label="formLabels.jobDescription"
          :options="jobDescriptionOptions"></FormInputDropdown>
      </div>
    </div>
    <div
      v-if="isPlatt()"
      class="row">
      <div class="col-md-12">
        <FormInputTextInput
          v-model="userFormInput.additionalDescription"
          :disabled="isStepDisabled"
          :input-label="formLabels.additionalDescription"
          :vuelidate="vuelidate.additionalDescription"
          input-type="text"></FormInputTextInput>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.purchaseOrderNumber"
          :disabled="isStepDisabled"
          :input-label="formLabels.purchaseOrderNumber"
          input-type="text"></FormInputTextInput>
      </div>
      <div
        class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.contractNumber"
          :disabled="isStepDisabled"
          :vuelidate="$route.params.tenant !== 'platt' ? null : vuelidate.contractNumber"
          :input-label="$route.params.tenant !== 'platt' ? formLabels.contractNumber : 'Job ID:'"
          input-type="text"></FormInputTextInput>
        <div v-if="vuelidate.contractNumber.isUnique === false" class="field-validation-error mt-n2 ml-2">
          Job ID already exists for this Customer
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputDatePicker
          v-model="userFormInput.startDate"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.startDate"
          :input-label="formLabels.startDate"
          :disabled-dates="{ from: new Date(userFormInput.endDate) }"
          input-type="text"></FormInputDatePicker>
      </div>
      <div class="col-md-4">
        <FormInputDatePicker
          v-model="userFormInput.endDate"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.endDate"
          :input-label="formLabels.endDate"
          :disabled-dates="{ to: new Date(userFormInput.startDate) }"
          input-type="text"></FormInputDatePicker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputDropdown
          v-model="userFormInput.natureOfProject"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.natureOfProject"
          :input-label="formLabels.natureOfProject"
          :options="[
            {
              key: 'Multi-Use - Office/Multifamily',
              value: 'Multi-Use - Office/Multifamily'
            },
            {
              key: 'Multi-Family Apartments',
              value: 'Multi-Family Apartments'
            },
            {
              key: 'Multi-Family Condos',
              value: 'Multi-Family Condos'
            },
            {
              key: 'Office Space',
              value: 'Office Space'
            },
            {
              key: 'Distribution Center/Warehouse',
              value: 'Distribution Center/Warehouse'
            },
            {
              key: 'Tech Manufacturing',
              value: 'Tech Manufacturing'
            },
            {
              key: 'Other Manufacturing',
              value: 'Other Manufacturing'
            },
            {
              key: 'Amusement and Recreation',
              value: 'Amusement and Recreation'
            },
            {
              key: 'Data Center',
              value: 'Data Center'
            },
            {
              key: 'Water/Wastewater',
              value: 'Water/Wastewater'
            },
            {
              key: 'Hospital/Healthcare',
              value: 'Hospital/Healthcare'
            },
            {
              key: 'Hotel/Lodging',
              value: 'Hotel/Lodging'
            },
            {
              key: 'Retail',
              value: 'Retail'
            },
            {
              key: 'School/Education',
              value: 'School/Education'
            },
            {
              key: 'Highway or Street',
              value: 'Highway or Street'
            },
            {
              key: 'Airport/Aviation/Aerospace',
              value: 'Airport/Aviation/Aerospace'
            },
            {
              key: 'Power/Energy',
              value: 'Power/Energy'
            },
            {
              key: 'Federal Government or Municipal',
              value: 'Federal Government or Municipal'
            },
            {
              key: 'Food and Beverage',
              value: 'Food and Beverage'
            },
            {
              key: 'Single Family Home',
              value: 'Single Family Home'
            }
          ]"></FormInputDropdown>
      </div>
      <div
        v-if="isPlatt() && userFormInput.natureOfProject === 'Other'"
        class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.natureOfProjectDescription"
          :disabled="isStepDisabled"
          :vuelidate="$route.params.tenant !== 'platt' ? null : vuelidate.natureOfProjectDescription"
          :input-label="formLabels.natureOfProjectDescription"
          input-type="text"></FormInputTextInput>
      </div>
    </div>
    <div
      v-if="!isPlatt()"
      class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.isEdiCustomer"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.isEdiCustomer"
          :input-label="formLabels.isEdiCustomer"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>
    <div
      v-if="!isPlatt()"
      class="row">
      <div
        v-if="userFormInput.isEdiCustomer === '1'"
        class="col-md-12">
        <FormInputCheckboxes
          v-model="userFormInput.ediType"
          :disabled="isStepDisabled"
          :input-label="formLabels.ediType"
          label-size="long"
          :checkboxes="[
            {
              value: 'Advance Ship Notice, ASN (856)',
              key: '856'
            },
            {
              value: 'Invoice (810)',
              key: '810'
            },
            {
              value: 'PO Acknowledgment (855)',
              key: '855'
            },
            {
              value: 'PO Change (860)',
              key: '860'
            },
            {
              value: 'PO Change Acknowledgment (865)',
              key: '865'
            },
            {
              value: 'Purchase Order (850)',
              key: '850'
            }
          ]"></FormInputCheckboxes>
      </div>
    </div>
    <div
      v-if="!isPlatt()"
      class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.nuclearProject"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.nuclearProject"
          :input-label="formLabels.nuclearProject"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.isJobBonded"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.isJobBonded"
          :input-label="formLabels.isJobBonded"
          :input-sublabel="jobBondedSublabelText"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>
    <div class="row">
      <form-expansion-panel v-model="showBonded">
        <v-row>
          <v-col cols="4">
            <FormInputTextInput
              v-model="userFormInput.bondCompanyName"
              :disabled="isStepDisabled"
              :input-label="formLabels.bondCompanyName"
              input-type="text"></FormInputTextInput>
          </v-col>
          <v-col cols="4">
            <FormInputTextInput
              v-model="userFormInput.bondCompanyContactNumber"
              :disabled="isStepDisabled"
              :input-label="formLabels.bondCompanyContactNumber"
              input-type="tel"
              placeholder="(xxx) xxx-xxxx"></FormInputTextInput>
          </v-col>
        </v-row>
        <FormInputGroupAddressInfo
          v-model="userFormInput"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate"
          :form-labels="formLabels"
          :form-data="formData"
          :field-names="{
            streetAddress: 'bondCompanyStreetAddress1',
            addressUnitNumber: 'bondCompanyStreetAddress2',
            city: 'bondCompanyCity',
            state: 'bondCompanyState',
            postalCode: 'bondCompanyPostalCode'
          }"></FormInputGroupAddressInfo>
        <div
          v-if="isPlatt()"
          class="form-control-label required"
          style="font-size: 12px;">
          Submit Performance Bond document below
        </div>
        <form-file-uploader-v2
          v-model="userFormInput.bondedDocuments"
          :disabled="isStepDisabled"
          return-file-data></form-file-uploader-v2>
      </form-expansion-panel>
    </div>
    <div
      v-if="!isPlatt()"
      class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.isMbeOrDbe"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.isMbeOrDbe"
          :input-label="formLabels.isMbeOrDbe"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <FormInputRadioOptions
          v-model="userFormInput.isWantingDualPartyCheckArrangement"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.isWantingDualPartyCheckArrangement"
          :input-label="formLabels.isWantingDualPartyCheckArrangement"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>
    <div
      v-if="isPlatt() && userFormInput.isWantingDualPartyCheckArrangement === '1'"
      class="row form-control-label ml-1 mb-1"
      style="font-size:12px;color:#4285f4;">
      Upload filled out Agreement Form under Documents section at end of form.
    </div>
    <div
      v-if="isPlatt() && userFormInput.isWantingDualPartyCheckArrangement === '1'"
      class="row mb-2 ml-1">
      <v-btn
        download
        :href="plattJointCheckAgreementPdf"
        color="#099c98"
        target="_blank"
        class="white--text">
        <i class="fa fa-download"></i>DOWNLOAD AGREEMENT FORM
      </v-btn>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.isTaxExempt"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.isTaxExempt"
          :input-label="formLabels.isTaxExempt"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
      <hr
        v-if="userFormInput.isTaxExempt === '1'"
        style="width: 100%; margin-left: 15px;" />
      <div
        v-if="userFormInput.isTaxExempt === '1'"
        class="col-md-12"
        style="margin-bottom: 1em;">
        Upload Tax Documents
      </div>
      <div
        v-if="userFormInput.isTaxExempt === '1'"
        class="col-sm-12">
        <div
          class="form-control-label mb-4"
          style="font-size:12px;color:#4285f4;">
          Please consider uploading an updated sales tax certificate.
        </div>
        <div v-if="isPlatt() && !this.isInternal()"
          class="pb-4">
          <v-checkbox
            v-model="userFormInput.plattTaxExemptCertificateAlreadyOnFile"
            :label="formLabels.plattTaxExemptCertificateAlreadyOnFile"
            hide-details>
          </v-checkbox>
        </div>
        <div v-if="showTaxDocsFileUploader">
          <FormFileUploaderV2
            v-model="userFormInput.selectedDocuments"
            :disabled="isStepDisabled"
            :input-label="formLabels.selectedDocuments"
            return-file-data>
          </FormFileUploaderV2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.isExportJob"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.isExportJob"
          :input-label="formLabels.isExportJob"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>
  </fieldset>
</template>

<script>
import TenantsMixin from 'mixins/tenants.mixin'
import FormInputCheckboxes from '@/components/form/FormInputCheckboxes.vue'
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputDropdown from '@/components/form/FormInputDropdown.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptions.vue'
import FormInputDatePicker from '@/components/form/FormInputDatePicker.vue'
import FormExpansionPanel from '@/components/form/FormExpansionPanel/index.vue'
import FormInputGroupAddressInfo from '@/components/form/FormInputGroupAddressInfo.vue'
import FormFileUploaderV2 from '@/components/form/FormFileUploaderV2.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import plattJointCheckAgreementPdf from '@/assets/downloads/PlattJointCheckAgreement.pdf'

export default {
  name: 'JobAccountFormStepProjectInformation',
  components: {
    FormFileUploaderV2,
    FormInputCheckboxes,
    FormInputDatePicker,
    FormInputRadioOptions,
    FormInputDropdown,
    FormInputTextInput,
    FormExpansionPanel,
    FormInputGroupAddressInfo
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      isLoading: false,
      isUploaded: false, // NOTE: $v validation is setup on userFormInput to require non-empty value when selectedDocuments need to be uploaded. false is technically a non-empty value so will not invalidate. should be kept in sync w/ userFormInput, set to true when true, and false when ''
      jobDescriptionOptions: [
        {
          key: 'Commercial',
          value: 'Commercial'
        },
        {
          key: 'Residential Single',
          value: 'Residential Single'
        },
        {
          key: 'Residential Multi-Family',
          value: 'Residential Multi-Family'
        },
        {
          key: 'Government',
          value: 'Government'
        },
        {
          key: 'Healthcare',
          value: 'Healthcare'
        },
        {
          key: 'Education',
          value: 'Education'
        }
      ],
      plattJointCheckAgreementPdf
    }
  },
  computed: {
    showBonded () {
      return parseInt(this.userFormInput.isJobBonded) === 1
    },
    jobTypeOptions () {
      const jobTypeOptions = [
        {
          key: 'Public',
          value: 'Public'
        },
        {
          key: 'Private',
          value: 'Private'
        }
      ]
      if (this.isPlatt() && this.isInternal()) {
        jobTypeOptions.push({
          key: 'Billing Purposes Only',
          value: 'Billing Purposes Only'
        })
      }
      return jobTypeOptions
    },
    jobBondedSublabelText () {
      if (this.isPlatt() && this.isInternal() && this.userFormInput.jobType === 'Public') {
        return 'A Public job cannot be approved by Credit without a copy of the bond'
      }
      return ''
    },
    showTaxDocsFileUploader () {
      if (this.isInternal() || !this.isPlatt()) {
        return true
      } else {
        if (this.isPlatt() && !this.userFormInput.plattTaxExemptCertificateAlreadyOnFile) {
          return true
        }
        return false
      }
    }
  },
  watch: {
    'userFormInput.selectedDocuments': {
      handler (newValue, oldValue) {
        this.isUploaded = false
      }
    },
    'userFormInput.jobType': {
      handler (newValue, oldValue) {
        if (newValue === 'Public') {
          this.userFormInput.isJobBonded = '1'
        }
      }
    },
    'userFormInput.isTaxExempt': {
      handler (newValue, oldValue) {
        // If user selected Tax Exempt 'No' then set this false
        if (this.isPlatt() && newValue === '0' && !this.isInternal()) {
          this.userFormInput.plattTaxExemptCertificateAlreadyOnFile = false
        }
      }
    }
  },
  mounted () {
    // KEEP: When Dev Mode "Overwrite Form Data with Test Data" doesn't work
    // this.userFormInput.contractNumber = '123-456'
    // this.userFormInput.name = 'Test Job Name'
    // this.userFormInput.jobType = 'Public'
    // this.userFormInput.jobDescription = 'Commercial'
    // this.userFormInput.startDate = '2022/06/01'
    // this.userFormInput.endDate = '2022/06/29'
    // this.userFormInput.natureOfProject = 'Solar'

    // trigger vuelidate $dirty flag for optional/hidden/default data
    this.vuelidate.isExportJob.$touch()
    this.vuelidate.storageGuid.$touch()

    if (this.isPlatt()) {
      this.vuelidate.isEdiCustomer.$touch()
      this.vuelidate.isMbeOrDbe.$touch()
      this.vuelidate.nuclearProject.$touch()
    } else {
      this.vuelidate.contractNumber.$touch()
      this.vuelidate.jobDescription.$touch()
    }
  },
  methods: {
    isInternal () {
      return this.$route.meta.isInternalForm
    }
  }
}
</script>
