<template>
  <div
    id="application-component"
    class="card component-container credit-application">
    <FormStepper
      :steps="formSteps"
      :mode="mode"
      :on-save="$isFormInternal() === true ? $onSave : null"
      :is-save-valid="isSaveValid"
      :on-submit="$onSubmit"
      :success-message="successMessage"
      :error-message="errorMessage"
      :submitted-on-date="submittedOnDate"
      :submitted-by="submittedBy"
      :validation-failures="validationFailures"
      :loading="loading"
      :invalid="$v.$invalid"
      :is-submitted-successfully="isSubmittedSuccessfully"
      :is-form-complete="isFormComplete"
      @check-validations="$v.$touch()">
      <CashAccountFormComplete
        :submitted-data="getCompleteData"></CashAccountFormComplete>
    </FormStepper>
    <v-dialog
      v-model="showCustomerMatchesDialog"
      persistent
      max-width="500">
      <v-card>
        <v-toolbar
          color="#032D2C"
          flat
          class="mb-5">
          <v-card-title class="text-h5 text-white">
            Possible Matches
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="showCustomerMatchesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="font-weight-bold mb-5">
          <p>Based on the information you have entered we have found some possible matches</p>
          <ul class="px-12 mb-4">
            <li>If none of these area match, you may press "Continue" to proceed with creation of a new account</li>
            <li>Click on any of the possible matches below to view more information in Sales Central</li>
          </ul>
          <v-divider class="mb-1">
          </v-divider>
          <v-list>
            <template v-for="(customerCandidate, index) in customerMatches">
              <v-list-item
                :key="index"
                @click="openSalesCentralCustomer(customerCandidate.customerId)">
                <v-list-item-content class="py-2">
                  <v-list-item-title class="font-weight-medium">
                    {{ customerCandidate.customerName }} ({{ customerCandidate.customerNumber }})
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div v-if="customerCandidate.address">
                      <span v-if="customerCandidate.address.address1">
                        {{ customerCandidate.address.address1 }}
                      </span>
                      <span v-if="customerCandidate.address.address2">
                        {{ customerCandidate.address.address2 }},
                      </span>
                      <span v-if="customerCandidate.address.address3">
                        {{ customerCandidate.address.address3 }},
                      </span>
                      <span v-if="customerCandidate.address.city || customerCandidate.address.state || customerCandidate.address.zip">
                        <span v-if="customerCandidate.address.city">
                          {{ customerCandidate.address.city }},
                        </span>
                        {{ customerCandidate.address.state }} {{ customerCandidate.address.zip }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index + 1 < customerMatches.length"
                :key="`divider_${index}`">
              </v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            @click="showCustomerMatchesDialog = false">
            Cancel
          </v-btn>
          <v-btn
            tile
            color="primary"
            @click="newAccount()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <form-navigation-confirmation-modal
      :is-dialog-open="displayNavWarning"
      :is-save-valid="isSaveValid"
      @saveAndNavigate="saveAndNavigate"
      @discardAndNavigate="discardAndNavigate"
      @close="displayNavWarning = false">
    </form-navigation-confirmation-modal>
  </div>
</template>

<script type="text/javascript">
import { mapState } from 'vuex'
import {
  maxLength,
  required,
  requiredIf,
  email
} from 'vuelidate/lib/validators'
import {
  mustBeObject,
  mustBeObjectOrEmpty
} from '@/components/form/FormValidationsCustom'

import TenantsMixin from 'mixins/tenants.mixin'
import FormMixin from '@/components/form/Form.mixin'
import FormStepper from '@/components/form/FormStepper.vue'
import FormNavigationConfirmationModal from '@/components/FormNavigationConfirmationModal.vue'

import {
  formTitle,
  formDefinitions,
  COMPANY_INFORMATION,
  USER_ACCOUNT
} from './CashAccountForm.definitions'

import CashAccountFormComplete from './CashAccountFormComplete.vue'
const CASH_ACCOUNT_FORM_KEY = 'cash-account'

export default {
  name: 'CashAccountForm',
  components: {
    FormStepper,
    CashAccountFormComplete,
    FormNavigationConfirmationModal
  },
  mixins: [
    TenantsMixin,
    FormMixin
  ],
  data () {
    return {
      /// ///////////////////////////
      // FormStepper data
      /// ///////////////////////////
      formTitle,
      formDefinitions,
      formSteps: [],
      // formData should hold data needed to make a POST to api
      formData: this.$initFormData(formDefinitions),
      completed: {
        // properties to pass to the completed page should go here
        companyName: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        customerType: '',
        assignedBranch: ''
      },
      /// ///////////////////////////
      // Form usage-specific data
      /// ///////////////////////////
      accountNumber: null,
      customerMatches: [],
      lookForCustomerMatches: true,
      showCustomerMatchesDialog: false
    }
  },
  computed: {
    ...mapState([
      'appConfig'
    ]),
    isSaveValid () {
      // // set logic for is save valid here
      const companyName = this.formData[COMPANY_INFORMATION].companyName
      return companyName != null ? companyName.length > 0 : false
    },
    getCompleteData () {
      return {
        ...this.completed,
        accountNumber: this.accountNumber
      }
    }
  },
  watch: {
    'formData.companyInformation.companyName': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.companyName = newVal
        this.lookForCustomerMatches = true
      }
    },
    'formData.companyInformation.firstName': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.firstName = newVal
      }
    },
    'formData.companyInformation.lastName': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.lastName = newVal
      }
    },
    'formData.companyInformation.emailAddress': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.emailAddress = newVal
      }
    },
    'formData.userAccount.customerTypeCategory': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.customerTypeCategory = newVal
      }
    },
    'formData.userAccount.customerPriceClass': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.customerPriceClass = newVal
      }
    },
    'formData.userAccount.branchNumber': {
      immediate: true,
      handler (newVal) {
        if (newVal != null) {
          this.completed.assignedBranch = newVal
        }
      }
    },
    'formData.userAccount.assignedBranch': {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal != null) {
          if (newVal.text != null) {
            this.completed.assignedBranch = newVal.value
          } else if (newVal === '') {
            this.completed.assignedBranch = ''
          }
        }
      }
    },
    'formData.companyInformation.streetAddress1': {
      immediate: true,
      handler (newVal, oldVal) {
        this.lookForCustomerMatches = true
      }
    }
  },
  mounted () {
    if (!this.$isFormInProgress()) {
      const guid = this.$createGuid()
      this.attachmentId = guid
    }
  },
  methods: {
    /// ///////////////////////////
    // FORM EVENT HANDLERs
    /// ///////////////////////////

    // Cash Account's Submit implementation differs from IC and JA form which both use the FormData js object for the payload
    // which the Form Save process is also dependent on
    // b/c of this, CA has this method specifically for saving, and a build form data method for submitting form data.
    buildFormDataToSave (formObject, isSave) {
      /// ///////////////////////////////////////////////////////////////
      const attachmentId = this.attachmentId
      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''
      const bannerName = this.$route.params.tenant
      const bannerId = this.$store.getters.getBannerIdByName(bannerName)
      const accountName = this.formData.companyInformation.companyName
      const assignedBranch = this.completed.assignedBranch

      // This value will only be available in this form in the response to a successful form submit.
      const accountNumber = this.accountNumber

      const finalData = {

        appType: CASH_ACCOUNT_FORM_KEY,
        bannerName,
        accountName,
        accountNumber,
        projectName: '',
        hasAttachedFiles: 0,
        bannerId,
        assignedBranchID: assignedBranch,

        // NOTE: Cash Account has a different approach than IC and Job Account Submit
        //  when building form data
        currentUser,
        attachmentId,
        isInternal: this.$route.meta.isInternalForm,
        ...formObject // merge generated data w/ finalData
      }

      /// ///////////////////////////////////////////////////////////////

      const formData = new FormData()

      this.$populateFormDataObject(formData, finalData)

      return formData
    },

    getAdditionalFormData () {
      const isInternal = this.$userIsEmployee()
      return {
        isInternal // NOTE: redundant
      }
    },

    async save (state = null) {
      this.loading = true
      const isCompleted = state === this.FORM_IS_COMPLETED

      const formObject = this.$buildFormObject(CASH_ACCOUNT_FORM_KEY, this.getAdditionalFormData())

      const saveInProgressFormRequestObject = this.$buildSaveInProgressDataRequest(formObject, isCompleted)

      const inProgressFormDataStorageRequest = this.buildFormDataToSave(saveInProgressFormRequestObject, true)

      let isSuccess = false

      return await this.$saveFormData(inProgressFormDataStorageRequest)
        .then((response) => {
          if (response.status === 200) {
            isSuccess = true
            return response.data.data
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.error(error.response)
          }
          return null
        })
        .finally(() => {
          if (state == null) {
            this.$store.dispatch('modals/open', {
              name: 'form-save-modal',
              data: { isSuccess }
            })
          }
          this.loading = false
        })
    },

    // TODO:: Update Cash Account Submit endpoint to use FormData, similarly to IC and JA form endpoints
    //  so that we can get rid of buildFormData and instead use buildFormDataToSave above
    buildFormData () {
      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''
      const isInternal = this.$userIsEmployee()
      const bannerName = this.$route.params.tenant
      const bannerId = this.$store.getters.getBannerIdByName(bannerName)
      const accountName = this.formData.companyInformation.companyName

      let assignedBranch = null
      if (isInternal) {
        assignedBranch = this.formData.userAccount != null ? this.formData.userAccount.branchNumber : null
      } else {
        assignedBranch = this.formData.companyInformation.branchNumber
      }

      const application = {

        appType: CASH_ACCOUNT_FORM_KEY,
        bannerName,
        accountName,
        projectName: '',
        hasAttachedFiles: 0,
        bannerId,
        assignedBranchID: assignedBranch,

        ...this.$getFormData(formDefinitions),
        currentUser,
        isInternal
      }

      return application
    },

    // TODO:: decompose below into a buildFormData method to be also used by save() // see JA form
    async submit (applicationKey = null) {
      this.loading = true
      const formObject = this.$buildFormObject(CASH_ACCOUNT_FORM_KEY, this.getAdditionalFormData())
      const formData = this.buildFormData(formObject, true)

      if (this.lookForCustomerMatches) {
        await this.getMatches(formData).then((matches) => {
          if (matches && matches.length > 0) {
            this.customerMatches = matches
            this.showCustomerMatchesDialog = true
          } else {
            this.customerMatches = []
            this.lookForCustomerMatches = false
          }
        }).finally(() => {
          this.loading = false
        })
        if (this.showCustomerMatchesDialog) {
          return null // Just check for matches this time, not submitting to BE yet
        }
      }

      // map assignedBranch.value to branchNumber before submit
      if (this.formData.userAccount != null) {
        // If there's a userAccount it's internally created, use branch from CashAccountStep2UserAccount.FormInputCombobox
        const assignedBranch = this.formData.userAccount.assignedBranch.value
        this.formData.userAccount.branchNumber = assignedBranch
      }

      this.loading = true
      return await this.$http
        .post(
          `/api/CashAccount/submit/${this.$route.params.tenant}/${applicationKey}`,
          formData
        )
        .then(res => {
          this.accountNumber = res.data
          return true
        })
        .catch((err) => {
          this.$handleFormErrors(
            err.response.data,
            formDefinitions
          )
          return null
        })
        .finally(() => {
          this.loading = false
          this.showCustomerMatchesDialog = false
          this.lookForCustomerMatches = true
        })
    },
    newAccount () {
      this.showCustomerMatchesDialog = false
      this.lookForCustomerMatches = false
      this.$onSubmit()
    },
    openSalesCentralCustomer (customerNumber) {
      const basePlattForceRootUrl = this.appConfig.plattforceUrl
      const url = `${basePlattForceRootUrl}/${this.$route.params.tenant}/customers/${customerNumber}`
      window.open(url, '_blank')
    },
    async searchForCustomerAccountInfoFromSales (selectedItem) {
      const urlTenant = this.$route.params.tenant

      // get queryParams ready using searchActionObj
      const queryParams = new URLSearchParams()

      // When searching for Bill-To account numbers we should should NOT return results for ship-to's only (unless they are also a bill to)
      const searchActionObj = {
        banner: urlTenant,
        source: 'all',
        term: selectedItem,
        filters: [
          'billto' // filtering on billto also returns accounts that are billto/shipto combos
        ],
        orderBy: 'best match',
        page: 1
      }
      const {
        ...params
      } = searchActionObj

      for (const key in params) {
        if (params[key]) queryParams.append(key, params[key])
      }
      const basePlattForceRootUrl = this.appConfig.plattforceUrl
      this.customerMatches = []
      return this.$http
        .get(`${basePlattForceRootUrl}/api/v1/customers/search`, { params: queryParams })
        .then(res => {
          // Filter out Cash and AutoDelete Accounts
          const filteredResults = res.data.value.filter(acct => acct.cashOrCredit !== 'C' && !acct.autoDeleteFlag)
          return filteredResults
        })
        .catch(err => {
          console.log(err)
          return null
        })
        .finally(() => {
        })
    },
    async getMatches (formData) {
      let companyNameMatches = []
      let streetAddressMatches = []
      let phoneNumberMatches = []

      const getCompanyNameMatches = await this.searchForCustomerAccountInfoFromSales(formData.companyInformation.companyName)
        .then((res) => {
          companyNameMatches = res
        })
      const getStreetAddressMatches = await this.searchForCustomerAccountInfoFromSales(formData.companyInformation.streetAddress1)
        .then((res) => {
          streetAddressMatches = res
        })
      const getPhoneNumberMatches = await this.searchForCustomerAccountInfoFromSales(formData.companyInformation.phoneNumber)
        .then((res) => {
          phoneNumberMatches = res
        })

      await Promise.all([getCompanyNameMatches, getStreetAddressMatches, getPhoneNumberMatches])

      return [...new Set([...companyNameMatches, ...streetAddressMatches, ...phoneNumberMatches])]
    }
  },
  validations () {
    if (this.$route.meta.isInternalForm) {
      return {
        formData: {
          [COMPANY_INFORMATION]: {
            firstName: {
              required
            },
            lastName: {
              required
            },
            emailAddress: {
              required,
              email
            },
            companyName: {
              maxLength: this.formData[COMPANY_INFORMATION].companyName.length > 35 && maxLength(35),
              required
            },
            phoneNumber: {
              required,
              minLength: 14
            },
            streetAddress1: {
              maxLength: this.formData[COMPANY_INFORMATION].streetAddress1.length > 35 && maxLength(35),
              required
            },
            streetAddress2: {
              maxLength: this.formData[COMPANY_INFORMATION].streetAddress2.length > 35 && maxLength(35)
            },
            city: {
              required
            },
            state: {
              required
            },
            postalCode: {
              required
            }
          },
          [USER_ACCOUNT]: {
            customerTypeCategory: {
              required
            },
            customerType: {
              required
            },
            assignedBranch: {
              required,
              mustBeObject
            },
            acquiredAccountFrom: {
              required
            },
            insideSalesPerson: {
              mustBeObjectOrEmpty,
              required: requiredIf(function () {
                return this.$route.params.tenant === 'platt' ? false : !this.formData[USER_ACCOUNT].insideSalesPerson && !this.formData[USER_ACCOUNT].outsideSalesPerson
              })
            },
            outsideSalesPerson: {
              mustBeObjectOrEmpty,
              required: requiredIf(function () {
                return this.$route.params.tenant === 'platt' ? false : !this.formData[USER_ACCOUNT].insideSalesPerson && !this.formData[USER_ACCOUNT].outsideSalesPerson
              })
            }
          }
        }
      }
    } else {
      return {
        formData: {
          [COMPANY_INFORMATION]: {
            firstName: {
              required
            },
            lastName: {
              required
            },
            emailAddress: {
              required,
              email
            },
            companyName: {
              maxLength: this.formData[COMPANY_INFORMATION].companyName.length > 35 && maxLength(35),
              required
            },
            phoneNumber: {
              required,
              minLength: 14
            },
            streetAddress1: {
              maxLength: this.formData[COMPANY_INFORMATION].streetAddress1.length > 35 && maxLength(35),
              required
            },
            streetAddress2: {
              maxLength: this.formData[COMPANY_INFORMATION].streetAddress2.length > 35 && maxLength(35)
            },
            city: {
              required
            },
            state: {
              required
            },
            postalCode: {
              required
            },
            branchNumber: {
              required
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins/_flexbox.scss";
#application-component {
  padding: 1em;
  padding-right: 3em;
}

#accordion {
  padding-inline-start: 0;
}

li {
  list-style-type: disc !important;
}
</style>
