<template>
  <div>
    <v-alert
      style="
        position:fixed;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 100%);
      "
      class="mb-2 mx-auto"
      :value="isOpen()"
      dark
      :color="isSuccess ? '#00a14d' : '#e7423c'"
      transition="slide-y-transition">
      {{ isSuccess ? 'Your progress on this form has been saved' : 'Something went wrong. Your current progress is unsaved.' }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'FormSaveNotification',
  data () {
    return {
      name: 'form-save-modal'
    }
  },
  computed: {
    isSuccess () {
      const modalData = this.$store.getters['modals/modalData']
      return modalData != null ? modalData.isSuccess : false
    }
  },
  methods: {
    close () {
      this.$store.dispatch('modals/close', this.name)
    },
    isOpen () {
      setTimeout(() => {
        this.close()
      }, 7000)

      return this.$store.getters['modals/allOpen'].includes(this.name)
    }
  }
}
</script>
