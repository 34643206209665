import axios from 'ca-http-service'
import _lodash from 'lodash'

export const getCustomerTypeOptions = async (bannerId) => {
  return await axios
    .get(`/api/CashAccount/options/customer-type?banner=${bannerId}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.err(err)
    })
}

export const getCustomerTypeOptionsForRexelGexpro = async () => {
  return await axios
    .get(
      '/api/CashAccount/options/customer-price-class-rexel-gexpro')
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.err(err)
    })
}

export const getCustomerClassAndPriceClassCrossReferenceForRexelGexpro = async () => {
  return await axios
    .get(
      '/api/CashAccount/options/customer-price-class-cross-reference-rexel-gexpro')
    .then((res) => {
      return _lodash.chain(res.data)
        .groupBy('custClassCode')
        .map((value, key) => {
          return {
            custClassCode: key,
            classDesc: value[0].classDesc,
            priceClassDescription: value[0].priceClassDescription,
            priceClasses: value.map(i => i.priceClass)
          }
        })
        .value()
    })
    .catch((err) => {
      console.err(err)
    })
}

export const getAcquiredAccountFromOptions = () => {
  return [
    {
      key: 'BLITZ',
      value: 'Blitz'
    },
    {
      key: 'STD',
      value: 'Super Tool Day'
    },
    {
      key: 'WALK_IN',
      value: 'Walk In'
    },
    {
      key: 'COLD_CALL',
      value: 'Cold Call'
    },
    {
      key: 'OTHER',
      value: 'Other'
    }
  ]
}

// export const getCustomerPriceClasses = () => {
//   return [
//     {
//       key: 'Contractor Residential Large',
//       value: 'RCRL300'
//     },
//     {
//       key: 'Contractor Residential Small Medium',
//       value: 'RCRM100'
//     },
//     {
//       key: 'Contractor Residential Small Medium',
//       value: 'RCRM200'
//     },
//     {
//       key: 'Contractor Residential Small Medium',
//       value: 'RCRM300'
//     },
//     {
//       key: 'Industrial MRO Large',
//       value: 'RIML100'
//     },
//     {
//       key: 'Industrial MRO Large',
//       value: 'RIML200'
//     },
//     {
//       key: 'Industrial MRO Large',
//       value: 'RIML300'
//     },
//   ]
// }
