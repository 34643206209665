<template>
  <div
    id="home-component"
    class="component-container">
    <div
      v-cloak
      id="credit-navigation">
      <h6>Select Company</h6>
      <template v-if="banners != null ? banners.length > 0 : false">
        <div id="banners">
          <a
            v-for="(b, i) in banners"
            :key="b + i"
            :class="b.name.toLowerCase()"
            @click.prevent="setCompanyObject(b)">
            <img
              class="card-img"
              :src="require('assets/images/logo-' + b.name.toLowerCase() + '.svg')"
              :alt="b.name" />
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script type="text/javascript">
import TenantsMixin from '@/mixins/tenants.mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'HomePage',
  components: { },
  mixins: [TenantsMixin],
  beforeRouteEnter: (to, from, next) => {
    next()
  },
  computed: {
    ...mapGetters([
      'banners',
      'isProductionEnvironment'
    ])
  },
  watch: { },
  mounted () {
    this.$store.dispatch('setAdminHeaderTitle', 'Credit Application')
  },
  methods: {
    setCompanyObject: function (banner) {
      const bannerName = banner.name.toLowerCase()
      this.$store.dispatch('setCurrentBanner', bannerName)
      this.$router.push({
        name: 'application',
        params: {
          tenant: bannerName
        }
      })
    }
  }
}

</script>

<style lang="scss">
#home-component {
  border: 1px solid rgba(26, 26, 26, .125);

  h6 {
    padding: 10px;
    border-bottom: 1px solid rgba(26, 26, 26, .125);
    font-size: 1rem;
    margin: 0;
    text-align: center;
  }
}

#banners {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 130px;
  a {
    display: flex;
    width: 25%;
    float: left;
    position: relative;
    transition: all 200ms;
    padding: 25px;
    border-right: 1px solid rgba(26, 26, 26, .125);
    height: 100%;
    align-items: center;
    cursor: pointer;
    &:nth-of-type(4) {
      border-right: 0;
    }
    &:hover {
      background: rgba(0, 0, 0, .03)
    }
    img {
      transition: .2s ease-out;
      width: 100%;
    }
  }
}
</style>
