<template>
  <div
    class="form-input-text"
    style="
        display: grid;
        margin-bottom: .6em;
      "
    :class="{'has-danger': $isErrorState()}">
    <span>
      <label
        v-if="inputLabel.length > 0"
        class="form-control-label"
        :class="$isRequired() && !disabled ? 'required' : ''"
        style="font-size: 12px;">
        {{ inputLabel }}
      </label>
      <div
        v-if="toolTip"
        data-toggle="tooltip"
        class="fa fa-info-circle"
        :title="toolTip">
      </div>
    </span>
    <v-text-field
      v-model="inputValue"
      v-mask="getInputMask"
      name="ignore"
      autocomplete="autocomplete_off_hack"
      outlined
      dense
      :disabled="disabled"
      :required="$isRequired"
      :type="filteredType"
      :max-length="$getMaxLength()"
      :placeholder="placeholder"
      :class="{'has-danger': $isErrorState()}"
      @input="vuelidate != null ? vuelidate.$touch() : null"
      @blur="validateInput">
    </v-text-field>

    <FormFieldErrorMessages :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from './FormInput.mixin'
import { mask } from 'vue-the-mask'

export default {
  name: 'FormInputTextInput',
  components: {
    FormFieldErrorMessages
  },
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return
      mask(el, binding)
    }
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        return [
          'ssn',
          'ein',
          'text',
          'tel',
          'email'
        ].indexOf(value) !== -1
      }
    },
    toolTip: {
      type: String,
      required: false,
      default: ''
    },
    onEmailValidate: {
      type: Function,
      required: false,
      default: function () {
        // do nothing...
      }
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.updateValue(newValue)
      }
    },
    getInputMask: function () {
      switch (this.inputType) {
        case 'ssn':
          return '###-##-####'
        case 'ein':
          return '##-#######'
        case 'tel':
          return '(###) ###-####'
        default:
          return ''
      }
    },
    filteredType () {
      if (['ssn', 'ein'].includes(this.inputType)) {
        return 'text'
      } else {
        return this.inputType
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
    validateInput () {
      if (this.inputType === 'email') {
        this.inputValue = this.inputValue.toLowerCase()
        if (typeof this.onEmailValidate === 'function') {
          this.onEmailValidate()
        }
      }
      if (this.vuelidate != null) {
        this.vuelidate.$touch()
      }
    }
    // TODO: v-mask usage broke w/ update from input to v-textfield
  }
}
</script>

<style scoped>

.fa-info-circle {
  color: #5bc0de;
  cursor: pointer;
  font-size: 16px;
}
</style>
