<template>
  <div class="footer-wrapper">
    <hr />
    <div class="footer-section">
      <div>Credit Application, &copy; {{ company.name }}, {{ new Date().getFullYear() }}</div>
      <div v-if="showDownloadableApplication">
        Download PDF Application:
        <a
          v-for="l in links"
          :key="l.url"
          :href="pdf.download"
          :title="l.description"
          class="pdf-links"
          target="_blank"
          :download="pdf.name">&nbsp;
          <i class="fa fa-file-pdf-o"></i>
          <span>{{ l.name }}</span>
        </a>
      </div>
      <div>
        Information on this site is being securely transmitted. To view our Privacy Policy please
        <a
          :href="company.privacyPolicy"
          target="_blank">
          click here
        </a>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import PlattPdf from '@/assets/downloads/PlattCreditApplication.pdf' // eslint-disable-line import/extensions
import RexelPdf from '@/assets/downloads/RexelCreditApplication.pdf' // eslint-disable-line import/extensions
import RexelEnergyPdf from '@/assets/downloads/RexelEnergyCreditApplication.pdf' // eslint-disable-line import/extensions
import GexproPdf from '@/assets/downloads/GexproCreditApplication.pdf' // eslint-disable-line import/extensions

export default {
  name: 'FooterBar',
  props: {
    showDownloadableApplication: {
      required: false,
      type: Boolean,
      default: false
    },
    company: {
      required: true,
      type: Object,
      default: () => ({
        email: 'credit@platt.com',
        name: 'Platt',
        phone: '(503) 526-2326',
        privacyPolicy: 'https://rexel-cdn.com/assets/storefront/privacy-policy.pdf',
        tenantId: 1,
        pdfLinks: []
      })
    }
  },
  computed: {
    links () {
      return this.company.pdfLinks
    },
    pdf () {
      const bannerName = this.company.name.toLowerCase()
      switch (bannerName) {
        case 'platt':
          return {
            download: PlattPdf,
            name: 'PlattCreditApplication.pdf'
          }
        case 'rexel':
          return {
            download: RexelPdf,
            name: 'RexelCreditApplication.pdf'
          }
        case 'rexelenergy':
          return {
            download: RexelEnergyPdf,
            name: 'RexelEnergyCreditApplication.pdf'
          }
        case 'gexpro':
          return {
            download: GexproPdf,
            name: 'GexproCreditApplication.pdf'
          }
        default:
          return {
            download: RexelPdf,
            name: 'RexelCreditApplication.pdf'
          }
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.footer-wrapper {
  margin: 0 50px;
}
.footer-section {
  padding: .25rem 0 1.5rem;
  font-size: .875rem;
  font-family: "PT Sans", sans-serif;
  color: #777;
  >div {
    text-align: center;
  }
}

.pdf-links {
  color: #777;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    span {
      color: #333;
      text-decoration: underline;
    }
  }
}
</style>
