<template>
  <fieldset>
    <div>
      <div class="row">
        <div class="col-md-4">
          <FormInputDropdown
            v-model="userFormInput.acquiredAccountFrom"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.acquiredAccountFrom"
            :input-label="formLabels.acquiredAccountFrom"
            :options="acquiredAccountFromOptions"
            :show-value="true"></FormInputDropdown>
        </div>

        <div class="col-md-8">
          <FormInputDropdownCustomerType
            v-model="userFormInput"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate"
            :form-labels="formLabels"
            :form-data="formData"
            :form-state="formState"></FormInputDropdownCustomerType>
        </div>
      </div>
      <div class="row">
        <div
          v-if="isInternalUser()"
          class="col-md-4"
          style="min-height: 7.8em;">
          <FormInputCombobox
            v-model="userFormInput.assignedBranch"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.assignedBranch"
            :input-label="formLabels.assignedBranch"
            :options="branchesList"></FormInputCombobox>
        </div>
      </div>
    </div>
    <div v-if="shouldShowSalesPersonInputs(tenantId)">
      <span>Salespeople </span><em>(at least one is required)</em><span
        class="ml-1"
        style="color:red;">
        *
      </span>
      <div>
        <v-divider class="my-0"></v-divider>
      </div>
    </div>
    <div
      v-if="isInternalUser() && shouldShowSalesPersonInputs(tenantId)"
      class="row">
      <div class="col-md-4">
        <FormInputCombobox
          v-model="userFormInput.insideSalesPerson"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.insideSalesPerson"
          :input-label="formLabels.insideSalesPerson"
          :async-search-handler="getSalesPersons"
          :items-mapper="mapSalesPersonsResultsToComboboxOptions"
          :hide-required="true"></FormInputCombobox>
      </div>
      <div class="col-md-4">
        <FormInputCombobox
          v-model="userFormInput.outsideSalesPerson"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.outsideSalesPerson"
          :input-label="formLabels.outsideSalesPerson"
          :async-search-handler="getSalesPersons"
          :items-mapper="mapSalesPersonsResultsToComboboxOptions"
          :hide-required="true"></FormInputCombobox>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <FormInputTextarea
          v-model="userFormInput.notes"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.notes"
          :input-label="formLabels.notes"></FormInputTextarea>
      </div>
    </div>
    <div
      v-if="tenant != 'platt'"
      style="color: #4285f4; font-size: 14px; margin: .7em 0;">
      For customer exemption setup: Send Tracker TAX-URG (Open items), REX-TAX (Request for account to be coded)
    </div>
  </fieldset>
</template>

<script>
import FormInputDropdown from '@/components/form/FormInputDropdown.vue'
import FormInputDropdownCustomerType from '@/components/form/FormInputDropdownCustomerType.vue'
import FormInputTextarea from '@/components/form/FormInputTextarea.vue'
import FormInputCombobox from '@/components/form/FormInputCombobox.vue'
import { getBranchesAsync } from '@/api/company/branch-info'
import {
  getSalesPersonsAsync,
  mapSalesPersonsResultsToComboboxOptions,
  shouldShowSalesPersonInputs
} from '@/api/company/sales-person-search'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import { getAcquiredAccountFromOptions } from '@/api/company/customer-type'

export default {
  name: 'CashAccountStep2UserAccount',
  components: {
    FormInputCombobox,
    FormInputTextarea,
    FormInputDropdown,
    FormInputDropdownCustomerType
  },
  mixins: [
    FormStepImplementationMixin
  ],
  data () {
    return {
      mapSalesPersonsResultsToComboboxOptions,
      shouldShowSalesPersonInputs,
      acquiredAccountFromOptions: [],
      branchesList: [],
      tenant: '',
      tenantId: '',
      creditAppUrl: ''
    }
  },
  async mounted () {
    const bannerName = this.$route.params.tenant
    this.tenant = bannerName
    this.tenantId = this.$store.getters.getBannerIdByName(bannerName)

    this.acquiredAccountFromOptions = getAcquiredAccountFromOptions()
    await this.initializeBranchesList()
  },
  methods: {
    async getSalesPersons (value) {
      return await getSalesPersonsAsync(value, this.tenant)
    },
    isInternalUser () {
      // NOTE: This is currently checking hardcoded data (meta.auth), which is not actually telling us whether the User is an authenticated Internal non-Admin User
      return this.$route.meta.auth === true
    },
    async initializeBranchesList () {
      const branches = await this.getBranches()

      if (branches == null) {
        return null
      }

      this.branchesList.length = 0

      branches.forEach(branch => {
        this.branchesList.push({
          text: `${branch.branchNumber} - ${branch.name}`,
          value: branch.branchNumber
        })
      })
    },
    async getBranches () {
      return await getBranchesAsync(this.tenantId)
    }
  }
}
</script>
