<template>
  <div>
    <!-- Section: Map Information -->
    <h6 class="card-header">
      Maps
    </h6>
    <div class="card">
      <!-- Billing/Shipping Info -->
      <div class="card-block cols-two">
        <div class="form-group">
          <label>Billing Address</label>
          <p>{{ company.street }} {{ company.unit }}</p>
          <p>{{ company.city }}, {{ company.state }} {{ company.countryCode }}</p>
          <img
            v-if="maps.billingUrl"
            class="map-square img-fluid"
            v-bind="{ src: maps.billingUrl }" />
        </div>
        <div class="form-group">
          <label>Shipping Address</label>
          <div v-if="company.mailingIsDifferent">
            <p>{{ company.mailingStreet }} {{ company.mailingUnit }}</p>
            <p>{{ company.mailingCity }}, {{ company.mailingState }} {{ company.mailingCountryCode }}</p>
          </div>
          <div v-else>
            <p>{{ company.street }} {{ company.unit }}</p>
            <p>{{ company.city }}, {{ company.state }} {{ company.countryCode }}</p>
          </div>
          <img
            v-if="maps.shippingUrl"
            class="map-square img-fluid"
            v-bind="{ src: maps.shippingUrl }" />
        </div>
      </div>

      <!-- Route Info -->
      <div class="card-block cols-one sub-headings">
        <div class="form-group">
          <h6 class="sub-heading bold-font">
            Route
          </h6>
        </div>
      </div>
      <div class="card-block cols-one">
        <div class="form-group">
          <p v-if="!company.mailingIsDifferent">
            The billing and shipping addresses are the same
          </p>

          <img
            v-else-if="maps.routeUrl"
            class="map-route img-fluid"
            v-bind="{ src: maps.routeUrl }" />

          <p v-else>
            Route data is currently unavailable
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'ca-http-service'

export default {
  name: 'MapInformation',
  props: {
    creditApp: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      company: {},
      maps: {
        billingUrl: '',
        shippingUrl: '',
        routeUrl: ''
      }
    }
  },
  created () {
    this.company = this.creditApp.company
    this.getMaps()
  },
  methods: {
    getMaps () {
      axios.get(`/api/CreditReview/getMaps/${this.creditApp.creditAppKey}`)
        .then((res) => {
          this.maps = res.data
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  img {
    width: 100%;
    padding: 0;
  }

  .bold-font {
    font-weight: bold;
  }
</style>
