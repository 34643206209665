<template>
  <div
    v-cloak
    id="manage-user-detail">
    <section
      v-if="isCurrentUser || identity.permissions.editUsers"
      class="users-body">
      <div class="mb-4 card-group user-info">
        <div class="card full-flex-container">
          <div class="card-block">
            <div class="row">
              <div class="col">
                <div
                  class="form-group"
                  :class="{'has-danger': $v.userObj.firstName.$error}">
                  <label class="form-control-label">
                    First Name:
                  </label>
                  <input
                    v-model="userObj.firstName"
                    class="form-control"
                    :disabled="!canEdit" />
                  <span
                    v-if="!$v.userObj.firstName.maxLength"
                    class="field-validation-error">
                    Field must be less than {{ $v.userObj.firstName.$params.maxLength.max }} letters.
                  </span>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label
                    class="form-control-label"
                    :class="{'has-danger': $v.userObj.lastName.$error}">Last Name:</label>
                  <input
                    v-model="userObj.lastName"
                    class="form-control"
                    :disabled="!canEdit" />
                  <span
                    v-if="!$v.userObj.lastName.maxLength"
                    class="field-validation-error">
                    Field must be less than {{ $v.userObj.lastName.$params.maxLength.max }} letters.
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="canEdit"
              class="row">
              <div class="col-6">
                <div
                  class="form-group"
                  :class="{'has-danger': $v.userObj.initials.$error}">
                  <label class="form-control-label">
                    Initials:
                  </label>
                  <input
                    v-model="userObj.initials"
                    class="form-control" />
                  <span
                    v-if="!$v.userObj.initials.maxLength"
                    class="field-validation-error">
                    Field must be less than {{ $v.userObj.initials.$params.maxLength.max }} letters.
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-control-label">
                    Email:
                  </label>
                  <input
                    v-model="userObj.email"
                    class="form-control"
                    :disabled="userObj.userId > 0" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-control-label">
                    Role:
                  </label>
                  <select
                    v-model="userObj.roleId"
                    class="form-control"
                    :disabled="!canEdit || isCurrentUser">
                    <option
                      v-for="r in filteredRoles"
                      :key="r.key"
                      :value="r.key">
                      {{ r.value }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6" v-if="userObj.roleId == 5">
                  <div class="form-group">
                    <label class="form-control-label">
                      Region:
                    </label>
                    <select
                      v-model="userObj.regionCode"
                      class="form-control">
                      <option
                        v-for="r in filteredRegions"
                        :key="r.bannerRegionCode"
                        :value="r.bannerRegionDesc">
                        {{ r.bannerRegionCode }} - {{ r.bannerRegionDesc }}
                      </option>
                    </select>
                  </div>
                </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="form-control-label">
                    Banner:
                  </label>
                  <div class="form-check">
                    <label
                      v-for="all in allBanners"
                      :key="all.id"
                      class="custom-control custom-radio">
                      <input
                        id="all.id"
                        v-model="userObj.tenants"
                        type="checkbox"
                        class="custom-control-input"
                        :value="all.id"
                        :disabled="disableBanner(all.id)" />
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description">
                        {{ all.name }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row
        dense
        class="ma-0">
        <v-col cols="auto">
          <button
            v-if="!isCurrentUser"
            type="button"
            class="btn btn-default"
            @click="goBack()">
            <i class="fa fa-arrow-left"></i>
            Back
          </button>
        </v-col>
        <v-spacer></v-spacer>
        <template v-if="canEdit">
          <v-col cols="auto">
            <button
              v-if="!isCurrentUser"
              type="button"
              class="btn btn-danger"
              @click="deleteUser()">
              Delete User
            </button>
          </v-col>
          <v-col cols="auto">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveUserChanges()">
              Save Changes
            </button>
          </v-col>
        </template>
      </v-row>
    </section>
  </div>
</template>
<script type="text/javascript">
import { maxLength } from 'vuelidate/lib/validators'
import axios from 'ca-http-service'
import BannerMixin from 'mixins/banner.mixin'
import TenantsMixin from 'mixins/tenants.mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'UserDetail',
  components: {},
  mixins: [BannerMixin, TenantsMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getData()
      vm.getRoles()
      vm.getRegions()
      vm.getTenants()
        .then((res) => {
          vm.allBanners = res.map((t) => {
            return { id: t.tenantId, name: t.name }
          })
        })
    })
  },
  data () {
    return {
      roles: [],
      regions: [],
      allBanners: [],
      userObj: {
        tenantId: 0,
        userId: 0,
        firstName: null,
        lastName: null,
        initials: null,
        userName: null,
        email: null,
        roleId: null,
        active: 1,
        tenants: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'identity',
      'company'
    ]),
    tenant () {
      return this.company.name.toLowerCase()
    },
    isCurrentUser () { return this.userObj.email === this.identity.email },
    currentUser () { return this.identity },
    canEdit () {
      // only a SystemAdmin (roleId: 4) can modify another SystemAdmin
      const me = this.identity.permissions
      return (me.editUsers && (this.userObj.roleId !== 4 || me.editTenant))
    },
    filteredRoles () {
      const isSysAdmin = this.identity.permissions.editTenant
      return this.roles.filter((r) => { return !this.canEdit || isSysAdmin || r.key !== 4 })
    },
    filteredRegions () {
      return this.regions
    }
  },
  watch: {
    '$route.params.detail': {
      handler: function () {
        if (this.$route.path.indexOf('detail') > -1) { this.getData(this.$route.params.id) }
      },
      deep: true,
      immediate: true
    },
    selectedBanner () { // comes from banner mixin
      this.goBack()
    }
  },
  mounted () {
    const userTitle = !this.isCurrentUser ? 'User Details' : 'My Profile'
    this.$store.dispatch('setAdminHeaderTitle', userTitle)
    if (!this.identity.permissions.editUsers) {
      const tenant = this.identity.banner
      this.$router.push({ name: 'dashboard', tenant })
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'usersList' })
    },
    async getData () {
      const userId = this.$route.params.id
      let parameters = {}

      if (userId === this.identity.banner) {
        parameters = { bannerId: this.identity.bannerId, email: this.identity.email }
      } else {
        parameters = { userId, bannerId: this.identity.bannerId, email: this.identity.email }
      }

      const response = await axios
        .get('/api/user/getDetailViewModel', { params: parameters })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return {}
          }
        })
        .catch((err) => {
          console.error(err)
        })

      if (response != null) {
        this.userObj = response
      }
    },
    async getRoles () {
      if (this.$store.getters.userRoles.length > 0) {
        this.roles = this.$store.getters.userRoles
      } else {
        const response = await axios
          .get('/api/user/getRoleList')
          .then((res) => {
            if (res.status === 200) {
              return res.data
            } else {
              return []
            }
          })
          .catch((err) => {
            console.error(err)
          })

        if (response != null) {
          this.$store.dispatch('setUserRoles', response)
          this.roles = response
        }
      }
    },
    async getRegions () {
      if (this.$store.getters.regions.length > 0) {
        this.regions = this.$store.getters.regions
      } else {
        const response = await axios
          .get('/api/user/getRegionList/2')
          .then((res) => {
            if (res.status === 200) {
              return res.data
            } else {
              return []
            }
          })
          .catch((err) => {
            console.error(err)
          })

        if (response != null) {
          this.$store.dispatch('setRegions', response)
          this.regions = response
        }
      }
    },
    deleteUser () {
      const self = this
      palert({
        title: 'Delete User',
        type: 'warning',
        text: 'Are you sure you want to delete this user?',
        showCancelButton: true,
        preConfirm: function (text) {
          return new Promise(function (resolve, reject) {
            const data = {
              id: self.userObj.userId
            }
            axios
              .get('/api/user/delete?id=' + data.id)
              .then((response) => {
                console.log('userDelete response', response)

                const results = response.data
                if (results.isSuccess) {
                  resolve()
                  self.$router.push({ name: 'usersList' })
                } else {
                  reject(results.message)
                }
              })
          })
        }
      })
    },
    saveUserChanges () {
      axios
        .post('/api/user/save', this.userObj)
        .then((response) => {
          const results = response.data
          if (!results.isSuccess) {
            palert({
              title: 'Error',
              type: 'error',
              text: results.message
            })
          } else {
            palert({
              title: 'Success',
              type: 'success',
              text: results.message
            })
          }
        })
    },
    disableBanner (bannerId) {
      return bannerId === this.userObj.tenantId ||
        this.identity.banners.indexOf(this.identity.banner) === -1
    }
  },
  validations () {
    return {
      userObj: {
        firstName: {
          maxLength: maxLength(100)
        },
        lastName: {
          maxLength: maxLength(100)
        },
        initials: {
          maxLength: maxLength(3)
        }
      }
    }
  }
}

</script>
<style lang="scss" scoped>
@import "../../assets/scss/mixins/flexbox.scss";

ul.error-messages {
  padding: 0;
  margin-bottom: 0;
  li {
    color: #b40000;
    list-style-position: inside;
  }
}

// .card-group .card {
//   @include flex(1, 1, auto);
// }

.card-block {
  max-width: 100%;
}

.users-header {
  margin-bottom: 1rem;
}

.form-control-label {
  margin-bottom: 0.125rem;
  color: #999;

  font-size: 90%;
}

.switch-group {
  height: 1.5rem;

  @include flexbox;
  @include align-items(center);
  .switch-label {
    margin: 0 1rem 0 0;
    color: #999;

    font-size: 1.125rem;
    line-height: 1.75;

    @include align-self(center);
  }
}

.form-control {
  &.readonly {
    background: #f7f7f7;
  }
}
</style>
