<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="taxcert-update-modal"
    class="tax-cert-modal"
    @close="modalState = false">
    <!-- HEADER -->
    <template
      slot="header">
      Tax Document Approval
    </template>

    <!-- BODY -->
    <div
      slot="body"
      class="container-fluid">
      <div class="row">
        <div class="col-sm-4">
          <div class="tax-cert-info">
            <label>Account #</label>
            <span>{{ taxCert.accountNumber }}</span>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="tax-cert-info">
            <label>Company</label>
            <span>{{ taxCert.accountName }}</span>
          </div>
        </div>
        <div
          v-if="taxCert.submittedByEmail"
          class="col-12">
          <div class="tax-cert-info submitted-by-info">
            <label>Submitted by: </label>
            <span>{{ taxCert.submittedByEmail }}</span>
          </div>
        </div>
      </div>

      <h5
        id="submittedSectionTitle"
        class="section-title mb-3">
        Certificate Information
      </h5>

      <!-- SUB ACCOUNTS IF ENTERPRISE NUMBER FOUND -->
      <div class="tax-cert-update-item">
        <div
          v-if="taxCert.isEnterprise && parsedSubAccounts.length > 0"
          class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">
                Select Subsidiary Account:
              </label>
              <div class="input-group">
                <checkbox-multi-select
                  :options="parsedSubAccounts"
                  :selected="taxCert.subAccounts"
                  :placeholder="'- Select Subsidiaries -'"
                  :selected-container-class="'col-sm-12'"
                  :disabled="!canEdit"></checkbox-multi-select>
              </div>
            </div>
          </div>
        </div>

        <!-- EFFECTIVE AND EXPIRATION DATE -->
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{'has-danger': $v.taxCert.effectiveDate.$error}">
              <label class="form-control-label required">
                Effective Date:
              </label>
              <div class="input-group">
                <input
                  ref="effectiveDate"
                  v-model="taxCert.effectiveDate"
                  type="date"
                  class="form-control form-control-row"
                  :class="{'form-control-danger': $v.taxCert.effectiveDate.$error }"
                  prefix="effectiveDate"
                  :disabled="!canEdit" />
                <span
                  class="input-group-addon"
                  @click="$refs.effectiveDate.focus()">
                  <i
                    class="fa fa-calendar-o"
                    aria-hidden="true"></i>
                </span>
              </div>
              <span
                v-if="!$v.taxCert.effectiveDate.isDate"
                class="field-validation-error">
                Please enter a valid date (YYYY-MM-DD)
              </span>
              <span
                v-if="$v.taxCert.effectiveDate.isDate && !$v.taxCert.effectiveDate.maxValue"
                class="field-validation-error">
                Effective date must be less than the expiration date
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{'has-danger': $v.taxCert.expirationDate.$error}">
              <label class="form-control-label">
                Expiration Date:
              </label>
              <div class="input-group">
                <input
                  ref="expirationDate"
                  v-model="taxCert.expirationDate"
                  type="date"
                  class="form-control form-control-row"
                  :class="{'form-control-danger': $v.taxCert.expirationDate.$error }"
                  prefix="expirationDate"
                  :disabled="!canEdit" />
                <span
                  class="input-group-addon"
                  @click="$refs.expirationDate.focus()">
                  <i
                    class="fa fa-calendar-o"
                    aria-hidden="true"></i>
                </span>
              </div>

              <span
                v-if="!$v.taxCert.expirationDate.isDate"
                class="field-validation-error">
                Please enter a valid date (YYYY-MM-DD)
              </span>
              <span
                v-if="$v.taxCert.expirationDate.isDate && !$v.taxCert.expirationDate.minValue"
                class="field-validation-error">
                Expiration date must be greater than today's date
              </span>
            </div>
          </div>
        </div>

        <!-- MULTIPLE STATES SELECTION -->
        <div class="row">
          <div class="col-md-12">
            <div
              class="form-group"
              :class="{'has-danger': $v.taxCert.$dirty && $v.taxCert.states.$error}">
              <label class="form-control-label required">
                Certificate State(s):
              </label>
              <div class="input-group">
                <checkbox-multi-select
                  :options="parsedStates"
                  :selected="taxCert.states"
                  :placeholder="'- Select Applicable States -'"
                  :error="$v.taxCert.$dirty && $v.taxCert.states.$error"
                  :disabled="!canEdit"></checkbox-multi-select>
              </div>
            </div>
          </div>
        </div>

        <!-- DOWNLOAD TAX CERTIFICATE -->
        <div class="row">
          <div class="col-md-12">
            <div
              v-if="taxCert.taxCertificate"
              class="form-group file-row">
              {{ formattedFilename }}
              <button
                type="button"
                class="download-link"
                @click="download(taxCert.taxCertificate.fullPath)">
                <span>Download</span>
                <i class="fa fa-download"></i>
              </button>
            </div>
          </div>
        </div>

        <h5
          id="approvalSectionTitle"
          class="section-title mb-3">
          Approval Section
        </h5>
        <!-- TAX CERT STATUS -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="input-group">
                <select
                  v-model="taxCert.statusCode"
                  class="form-control"
                  :disabled="!canEdit">
                  <option
                    v-for="s in statusCodes"
                    :key="s.key"
                    :value="s.key">
                    {{ s.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF BODY OF MODAL -->

    <!-- FOOTER -->
    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="modalState = false">
        Cancel
      </a>
      <button
        v-if="canEdit"
        type="button"
        class="btn btn-rounded btn-primary"
        :class="{'disabled': !canEdit || loading }"
        @click="save()">
        <span v-if="loading">
          <img
            src="../../../assets/images/puff.svg"
            class="loading-img-btn" />
        </span>
        Save
      </button>
    </div>
  </GenericModal>
</template>

<script>
import axios from 'ca-http-service'
import GenericModal from '../../../components/GenericModal.vue'
import { required, minLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import CheckboxMultiSelect from '../../../components/CheckboxMultiSelect.vue'
import { TaxCert } from '../TaxCert.model'
import TaxCertModalMixin from './TaxCertModalMixin'

export default {
  name: 'UploadModal',
  components: {
    GenericModal,
    CheckboxMultiSelect
  },
  mixins: [TaxCertModalMixin],
  props: {
    canEdit: {
      required: true,
      type: Boolean
    },
    states: {
      required: true,
      type: Array
    },
    subAccounts: {
      required: true,
      type: Array
    },
    statusCodes: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      modalState: false,
      loading: false,
      taxCert: new TaxCert()
    }
  },
  computed: {
    formattedFilename () {
      return decodeURIComponent(this.taxCert.taxCertificate.name).split('/').at(-1)
    }
  },
  methods: {
    loaderStarted () {
      this.loading = true
    },
    save () {
      this.$v.taxCert.$touch()
      if (!this.$v.taxCert.$invalid) {
        this.$emit('saveTaxCertReview', this.taxCert)
      }
    },
    async download (fullPath, useThisFileNameInstead = null) {
      axios.get('/api/taxReview/Download',
        {
          params: { fullPath: decodeURIComponent(fullPath) },
          responseType: 'blob'
        }).then((res) => {
        const bytes = res.data
        const file = new Blob([bytes], { type: res.headers['content-type'] })
        const fileURL = window.URL.createObjectURL(file)
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        const [, contentDisposition] = res.headers['content-disposition'].split('filename=')
        if (useThisFileNameInstead) {
          fileLink.download = useThisFileNameInstead
        } else {
          const returnedFileName = contentDisposition.substring(0, contentDisposition.indexOf(';'))
          fileLink.download = returnedFileName
        }
        document.body.appendChild(fileLink)
        fileLink.click()
      })
        .catch((err) => {
          console.error(err)
          this.loading = false
          palert({ type: 'error', title: 'Unable to download Tax Certificate' })
        })
    }
  },
  validations () {
    return {
      taxCert: {
        states: {
          required,
          minLength: minLength(1)
        },
        effectiveDate: {
          required,
          isDate (value) {
            return !value || moment(value).isValid()
          },
          maxValue (value) {
            return !value || !this.taxCert.expirationDate || value < this.taxCert.expirationDate
          }
        },
        expirationDate: {
          isDate (value) {
            return !value || moment(value).isValid()
          },
          minValue (value) {
            return !value || !this.taxCert.expirationDate ||
                new Date(this.taxCert.expirationDate) >= new Date()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import './tax-cert-modal.scss';
  @import '../../../assets/scss/mixins/_flexbox.scss';

  #taxcert-update-modal {

    .tax-cert-update-item {
      width: 100%;
    }

    .submitted-by-info {
      margin-bottom: 0;

      label {
        font-weight: bold;
        margin-right: 5px;
        margin-bottom: 0;
      }

      span {
        font-weight: normal;
        display: inline-block;
      }
    }

    .section-title {
      font-size: 1.125rem;
      text-transform: uppercase;
      margin: 1rem 0px;
      color: #808080;

      @media (max-width: 442px) {
        display: block; width: 100%;
      }
    }

    .file-row {
      background-color: #d0d0d0;
      padding: .5rem;
      @include flexbox;
      @include justify-content(space-between);

      .download-link {
        white-space: nowrap;
      }
    }

    input[type=date] {

      &::-webkit-clear-button,
      &::-webkit-calendar-picker-indicator,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
      }

    }

  }

</style>
