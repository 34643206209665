<template>
  <v-row class="form-file-uploaded-v2">
    <v-col
      cols="5"
      :class="{'has-danger': $isErrorState()}">
      <v-file-input
        v-if="!disabled"
        ref="fileInput"
        v-model="localValue"
        :small-chips="smallChips"
        :accept="allowedFileTypes"
        prepend-icon=""
        placeholder="Select a file & add"
        :multiple="multiple"
        :label="inputLabel"
        :error-messages="errors"
        outlined>
      </v-file-input>
      <v-chip
        v-else-if="Array.isArray(value) && value.length > 0"
        v-for="(val, index) in value"
        :key="index"
        disabled
        label
        outlined
        class="py-0 font-weight-bold"
        style="font-size: 12px; background-color: #eeeeee !important; border-radius: 0; margin-right: 8px">
        {{ val.name }}
      </v-chip>
      <v-chip
        v-else-if="value && value.name"
        disabled
        label
        outlined
        class="py-0 font-weight-bold"
        style="font-size: 12px; background-color: #eeeeee !important; border-radius: 0">
        {{ value.name }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import FormInputMixin from './FormInput.mixin'
export default {
  name: 'FormFileUploaderV2',
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: [String, FormData, Array, File, Object], // is a String onMount
      required: true,
      default: ''
    },
    inputLabel: {
      type: String,
      required: false,
      default: ''
    },
    multiple: {
      type: Boolean,
      required: false,
      default: () => false
    },
    maxMbSize: {
      type: Number,
      required: false,
      default: 60
    },
    returnFileData: {
      type: Boolean,
      required: false,
      default: () => false
    },
    vuelidate: {
      type: Object,
      required: false,
      default: null
    },
    smallChips: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      rules: [
        (value) => {
          const megabytes = this.convertKbToMb(value.size)
          return !value || megabytes < 15 || 'Attachments cannot be larger than 15MB'
        }
      ],
      errors: [],
      allowedFileExtensions: ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'pdf', 'bmp', 'jpg', 'png']
    }
  },
  computed: {
    allowedFileTypes () {
      return [
        'application/msword',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpointtd',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/bmp',
        'image/jpg',
        'image/png',
        'text/plain'
      ].sort().join(',')
    },
    localValue: {
      get () {
        if (this.value instanceof File) {
          return this.value
        } else if (this.multiple && this.value instanceof Array) {
          return this.value
        }
        return null
      },
      set (files) {
        this.errors = []

        const filesArray = files instanceof Array ? files : [files]

        const filteredFiles = filesArray.filter((file) => {
          return file instanceof File
        })

        const tooBig = this.getTotalUploadSizeInMB(filteredFiles) > this.maxMbSize

        const badFiletype = filteredFiles.filter(file => {
          return !this.allowedFileExtensions.includes(file.name.split('.').pop().toLowerCase())
        }).length

        if (filteredFiles.length <= 0 || tooBig) {
          this.$emit('input', '')
          if (tooBig) {
            this.errors.push(`Maximum total attachments must be ${this.maxMbSize}MB or less`)
          }
          return
        }

        if (badFiletype) {
          this.errors.push('This file type is not accepted. Please choose a different file or convert this to another format to upload.')
        }

        const formData = new FormData()

        filteredFiles.map((file) => {
          const fileErrors = this.rules
            .map(rule => rule(file))
            .filter(result => typeof result === 'string')

          if (fileErrors.length <= 0) {
            formData.append('files', file, files.name)
            return file
          }
          fileErrors.map(e => {
            if (this.errors.indexOf(e) < 0) this.errors.push(e)
            return e
          })
          return file
        })

        if (this.errors.length > 0 && !(this.errors.length === 1 && badFiletype)) {
          this.$emit('input', '')
          return
        }

        if (this.returnFileData) {
          const fileData = this.multiple ? filteredFiles : filteredFiles[0]
          this.$emit('input', fileData)
          return
        }

        this.$emit('input', formData)
      }
    }
  },
  mounted () {
    if (this.value != null) {
      this.localValue = this.value
    }
  },
  methods: {
    getTotalUploadSizeInMB (files) {
      if (files.length <= 0) return 0
      const sum = files
        .map(file => typeof file.size !== 'undefined' ? file.size : 0)
        .reduce((total, num) => total + num)
      return this.convertKbToMb(sum)
    },
    convertKbToMb (kilobytes) {
      return (kilobytes / (1024 * 1024).toFixed(2))
    }
  }
}
</script>

<style lang="scss" scoped>
.form-file-uploaded-v2 {
  &__button {
    &.v-btn {
      text-transform: none;
    }
  }
}
</style>
