<template>
  <fieldset>
    <div class="row">
      <div class="col-md-4">
        <v-card
          class="mx-auto"
          max-width="500"
          outlined>
          <v-card-text>
            <v-container class="py-0">
              <v-row>
                <span class="font-weight-bold">
                  {{ branchInfoName }}
                </span>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'

// This component is currently used by PlattOnly
export default {
  name: 'JobAccountFormStepBranchInformation',
  mixins: [
    FormStepImplementationMixin
  ],
  data () {
    return {
      branchInfoName: ''
    }
  },
  watch: {
    'formData.branchId': {
      immediate: true,
      handler (newVal, oldVal) {
        const branchName = this.formData.branchName
        const branchId = this.formData.branchId

        // Handles setting field value on loading an In Progress form - watcher must be run immediately
        if (branchId.length && branchName.length > 0) {
          this.branchInfoName = `${branchName} - ${branchId}`
          this.vuelidate.$touch()
        } else {
          this.branchInfoName = ''
        }
      }
    }
  }
}
</script>
