<template>
  <div v-if="vuelidate != null">
    <ul>
      <!-- TODO add additional messages on vuelidate errors here -->
      <li v-if="vuelidate.$params.maxLength && vuelidate.$dirty && vuelidate.$model.length > vuelidate.$params.maxLength.max">
        <span
          class="field-validation-error">
          Field must be less than {{ vuelidate.$params.maxLength.max }} characters.
        </span>
      </li>
    </ul>
    <div
      v-if="$store.getters.showDevFormValidationState === true"
      style="opacity: .7; font-size: .8em; background-color: rgba(215,215,255); border-radius: .6em;">
      $dirty: <span :style="vuelidate.$dirty === false ? 'color: #b40000' : 'color: green'">
        {{ vuelidate.$dirty }}
      </span> -
      $error: <span :style="vuelidate.$error === false ? 'color: #b40000' : 'color: green'">
        {{ vuelidate.$error }}
      </span><br />
      $invalid: <span :style="vuelidate.$invalid === false ? 'color: #b40000' : 'color: green'">
        {{ vuelidate.$invalid }}
      </span> -
      {{ vuelidate.required != null ? 'required:' : '~not required~' }} <span :style="vuelidate.required === false ? 'color: #b40000' : 'color: green'">
        {{ vuelidate.required }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormFieldErrorMessages',
  props: {
    vuelidate: { // should be the vuelidate object scoped to the field's property
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>

<style scope>
ul {
  padding: 0
}
li {
  list-style: none
}
</style>
