export default {
  name: 'FormInputMixin',
  props: {
    vuelidate: { // should be the vuelidate object scoped to the field's property
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this._handleValidatingDirectlyAssignedValues(newVal, oldVal)
      }
    }
  },
  methods: {
    // when field values are assigned directly, vuelidate does not evaluate,
    // (ex: this happens when values are assigned on mount)
    _handleValidatingDirectlyAssignedValues (newVal, oldVal) {
      if (this.vuelidate == null) {
        return null
      }
      if (oldVal === '' && newVal != null) {
        this.vuelidate.$touch()
      }
    },
    // handles case where a non-required field might not be interacted w/ but
    // we still want to trigger $dirty flag to evaluate validation for this step
    // (ex: this happens when no fields in a form are required)
    _handleNonRequiredFieldValidation () {
      if (this.vuelidate == null) {
        return null
      }
      if (this.vuelidate.required === undefined) {
        this.vuelidate.$touch()
      }
    },

    // public input mixin methods, intended to be used to evaluate vuelidate state if vuelidate exists

    $isErrorState () {
      if (this.vuelidate == null) {
        return false
      } else {
        return this.vuelidate.$error
      }
    },
    $isRequired () {
      if (this.vuelidate == null) {
        return false
      } else {
        return this.vuelidate.required != null
      }
    },
    $getMaxLength: function () {
      if (this.vuelidate == null) {
        return null
      } else {
        return this.vuelidate.$params.maxLength ? this.vuelidate.$params.maxLength.max : null
      }
    },
    $touch () {
      if (this.vuelidate != null) {
        this.vuelidate.$touch()
      }
    }
  }
}
