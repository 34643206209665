import axios from 'ca-http-service'

export const getBranchesAsync = async function (bannerId) {
  return await axios
    .get(`/api/branch-info/branches/${bannerId}`)
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const getExternalBranchesAsync = async function (bannerId) {
  return await axios
    .get(`/api/creditapplication/branches/${bannerId}`)
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.error(err)
      return null
    })
}
