<template>
  <fieldset>
    <div class="alert alert-info">
      Once signatures are complete a copy of the application will be sent to your email.
    </div>
    <div class="card-deck-wrapper mb-6">
      <div class="card-deck">
        <template v-for="(s, i) in eSign.data.signatories">
          <div
            v-if="s.signatoryUrl !== ''"
            :key="i"
            class="card card-outline-primary">
            <div class="card-block">
              <h4 class="card-title">
                {{ s.firstName }} {{ s.middleInitial }} {{ s.lastName }} {{ s.suffix }}
                <small>{{ s.jobTitle }}</small>
              </h4>
              <div class="card-text">
                <p v-if="s.email != '' || s.email != null">
                  Email: {{ s.email }}
                </p>
                <p v-if="s.phone != '' || s.phone != null">
                  Phone: {{ s.phone }}
                </p>
                <address v-if="eSign.data.businessType !== 'Government' || s.guarantor">
                  {{ s.streetAddress }}
                  <span v-if="s.addressUnitNumber != '' && s.addressUnitNumber != null">
                    Unit: {{ s.addressUnitNumber }}
                  </span>
                  <br />{{ s.city }}, {{ s.state }} {{ s.zip }}
                </address>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-block btn-primary"
              :disabled="s.hasSigned === 'true'"
              @click="signDocument(s.signature, i)">
              Sign{{ s.hasSigned === "true" ? 'ed' : '' }}
            </button>
            <!-- <button type="button" class="btn btn-primary pull-right ml-3" @click="testHelloSignCallBack()">
              Test HS Callback
            </button> -->
          </div>
        </template>
      </div>
    </div>
    <v-divider
      class="my-8">
    </v-divider>
    <FormSectionCard
      :title="'Share additional information or comments about your application with '+ this.company.displayName +'\'s Credit Team below:'">
      <v-row>
        <v-col
          class="pb-0">
          <v-textarea
            v-model="customerComments"
            :hide-label="true"
            :rows="4"
            :disabled="savingComment"
            outlined
            @blur="savecustomerComments">
          </v-textarea>
          <span>
            {{ commentStatus }}
          </span>
        </v-col>
      </v-row>
    </FormSectionCard>
  </fieldset>
</template>

<script>
import FormSectionCard from '@/components/form/FormSectionCard.vue'

import { mapState, mapGetters } from 'vuex'
import axios from 'ca-http-service'

export default {
  name: 'Step5ESign',
  components: {
    FormSectionCard
  },
  props: {
    groupData: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      eSignInput: new this.groupData.eSign.dataClass(), // eslint-disable-line new-cap
      eSign: this.groupData.eSign,
      isLoading: false,
      commentStatus: '',
      customerComments: this.groupData.eSign.customerComments,
      savingComment: false,
      editingComment: false
    }
  },
  computed: {
    ...mapGetters({
      isDevelopmentEnvironment: 'isDevelopmentEnvironment',
      company: 'company'
    }),
    ...mapState('applicationForm', ['hasAccount'])
  },
  mounted () {
    this.eSign = this.groupData.eSign
    this.eSignInput = this.eSign.data
  },
  methods: {
    signDocument (signature, index) {
      this.isLoading = true
      const tenant = this.$route.params.tenant
      const applicationId = this.eSign.data.applicationId

      axios.get('/api/HelloSign/getSignatureUrl/' + tenant + '/' + applicationId + '/', {
        params: {
          signature
        }
      })
        .then((res) => {
          const self = this
          this.isLoading = false
          if (res.data.isSuccess) {
            const url = res.data.data.url
            const key = res.data.data.key

            // https://app.hellosign.com/api/embeddedSigningWalkthroughV1
            HelloSign.init(key)
            HelloSign.open({
              url,
              uxVersion: 2,
              allowCancel: true,
              skipDomainVerification: this.isDevelopmentEnvironment,
              // redirectUrl: 'https://localhost:5100/hellosign/eventhandler',
              debug: false,
              messageListener (eventData) {
                if (eventData.event === HelloSign.EVENT_SIGNED) {
                  console.info('EVENT_SIGNED')
                  self.eSign.data.signatories[index].hasSigned = 'true'
                  self.eSign.data.signatories[index].signature = eventData.signature_id

                  self.eSignComplete(
                    tenant,
                    applicationId,
                    self.eSign.data.signatories,
                    index
                  )
                } else if (eventData.event === HelloSign.EVENT_ERROR) {
                  console.info('EVENT_ERROR')
                  HelloSign.close()

                  palert({
                    title: 'Your session has timed out, please try again.',
                    type: 'error'
                  })
                }
              }
            })
          } else {
            palert({
              title: res.data.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    eSignComplete (tenant, applicationId, signatories, index) {
      this.isLoading = true

      let hasAccount = 0
      let completedData = null
      if (this.hasAccount === 'true') {
        hasAccount = 1
      }

      axios.post(
        `/api/HelloSign/SignComplete/${tenant}/${applicationId}/${hasAccount}`,
        signatories
      )
        .then((res) => {
          if (res.data.isSuccess && res.data.data != null) {
            const signatories = this.eSignInput.signatories

            const hasAllSignatoriesSigned = signatories.every(s => s.hasSigned === 'true')

            if (hasAllSignatoriesSigned === true) {
              this.eSignInput.allSignatoriesHaveSigned = true
              completedData = {
                applicationId,
                email: this.eSign.data.signatories[0] ? this.eSign.data.signatories[0].email : null,
                fullName: this.eSign.data.signatories[0] ? `${this.eSign.data.signatories[0].firstName} ${this.eSign.data.signatories[0].lastName}` : null,
                redirectUrl: res.data.data
              }
            }
          }

          this.isLoading = false
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          if (this.eSignInput.allSignatoriesHaveSigned) {
            this.$emit('on-completed', completedData)
          }
        })
    },
    testHelloSignCallBack () {
      axios.post(`/api/HelloSignCallback/eventhandler/${this.eSign.data.applicationId}`)
        .then((res) => {
          console.log(`testHelloSignCallBack() res.data=${JSON.stringify(res.data)}`)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    savecustomerComments () {
      if (!this.customerComments || !this.eSignInput.applicationId) {
        return
      }
      this.commentStatus = 'Saving...'
      const comment = this.customerComments
      this.eSign.customerComments = comment
      this.savingComment = true
      axios.post(`/api/CreditApplication/updateCustomerComment/${this.eSignInput.applicationId}`, { comment })
        .then((res) => {
          if (!res.data.isSuccess) {
            this.commentStatus = 'An error occurred while trying to save your comment.'
          } else {
            this.commentStatus = 'Your comment was saved successfully.'
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.$emit('on-comment', comment)
          this.savingComment = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.cancel-button {
  background-color: white;
  color: #0469a3;
  /* outline: 1px inset black; */
  font-size: 0.64rem;
  font-weight: 600;
  border-radius: 0.125rem;
  margin: 1.2em 0 2.6em 0;
  padding: 0.5rem 1.6rem;
}

.slide-down-enter-active {
  transition: all 0.5s ease;
}
.slide-down-leave-active {
  transition: all 0.1s ease;
}
.slide-down-enter {
  transform: translateY(-50px);
  opacity: 0;
}
.slide-down-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}

@media all and (max-width: 992px) {
  .card-deck .card {
    flex: 1 1 auto;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
