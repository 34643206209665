<template>
  <fieldset>
    <div
      v-if="isInternal()"
      class="row">
      <div class="col-md-8 py-0">
        <JobAccountComboboxDialog
          v-model="customerAccountNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.accountNumber"
          :input-label="formLabels.accountNumber"
          :async-search-handler="searchForCustomerAccountInfoFromSales"
          :items-mapper="mapCustomerAccountSearchResults"
          @input="handleAccountSelected"></JobAccountComboboxDialog>
      </div>
    </div>
    <div v-if="customerAccountFetched">
      <div class="row">
        <div class="col-md-6 py-0">
          <v-card
            class="mx-auto"
            max-width="500"
            outlined>
            <v-card-text>
              <v-container class="py-0">
                <v-row>
                  <span class="font-weight-bold">
                    {{ userFormInput.companyName }} ({{ userFormInput.accountNumber }})
                  </span>
                </v-row>
                <v-row>
                  <span>{{ customerAddress }}</span>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <v-card
            class="ma-0 pa-0"
            max-width="1000"
            outlined>
            <v-card-text>
              <v-container class="pa-0">
                <v-row>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Branch:
                    </div>
                    <div>{{ userFormInput.branchName }} ({{ userFormInput.branchNumber }})</div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Branch Manager:
                    </div>
                    <a
                      v-if="userFormInput.branchManager && userFormInput.branchManagerEmail"
                      :href="`mailto:${userFormInput.branchManagerEmail}`">
                      {{ userFormInput.branchManager }}
                    </a>
                    <div v-else>
                      {{ userFormInput.branchManager ? userFormInput.branchManager : '-' }}
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Credit Manager:
                    </div>
                    <a
                      v-if="userFormInput.creditRep && userFormInput.creditManagerEmail"
                      :href="`mailto:${userFormInput.creditManagerEmail}`">
                      {{ userFormInput.creditRep }}
                    </a>
                    <div v-else>
                      {{ userFormInput.creditRep ? userFormInput.creditRep : '-' }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Inside Salesperson:
                    </div>
                    <template v-if="insideSales">
                      <template v-for="(insidePerson, insideIndex) of insideSales">
                        <a
                          v-if="insidePerson.hasEmail"
                          :key="insidePerson.name"
                          :href="`mailto:${insidePerson.email}`">
                          {{ insidePerson.name }}
                        </a>
                        <span
                          v-else
                          :key="`${insidePerson.name}-noemail`">
                          {{ insidePerson.name }}
                        </span>
                        <span
                          v-if="insideIndex < insideSales.length-1"
                          :key="`${insidePerson.name}-comma`">
                          ,
                        </span>
                      </template>
                    </template>
                    <div v-else>
                      -
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="font-weight-bold">
                      Outside Salesperson:
                    </div>

                    <template v-if="outsideSales">
                      <template v-for="(outsidePerson, outsideIndex) of outsideSales">
                        <a
                          v-if="outsidePerson.hasEmail"
                          :key="outsidePerson.name"
                          :href="`mailto:${outsidePerson.email}`">
                          {{ outsidePerson.name }}
                        </a>
                        <span
                          v-else
                          :key="`${outsidePerson.name}-noemail`">
                          {{ outsidePerson.name }}
                        </span>
                        <span
                          v-if="outsideIndex < outsideSales.length-1"
                          :key="`${outsidePerson.name}-comma`">
                          ,
                        </span>
                      </template>
                    </template>
                    <div v-else>
                      -
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div
      v-else-if="!customerAccountFetched && loading"
      class="spinner show-spinner">
    </div>
    <div
      v-if="!loading && showError"
      style="font-size: 14px; margin: .7em 0; color: #b40000;">
      Error(s) encountered processing request.
    </div>
  </fieldset>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import JobAccountComboboxDialog from './JobAccountComboboxDialog.vue'
import TenantsMixin from 'mixins/tenants.mixin'
import lodash from 'lodash'

import {
  getCustomerAccountInfo
} from '@/api/company/account-info'

// This is currently a Platt Only component
export default {
  name: 'JobAccountFormStepCompanyInformation',
  components: {
    JobAccountComboboxDialog
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      bannerId: null,
      loading: false,
      customerAccountFetched: false,
      showError: false,
      customerAccountNumber: {
        value: null,
        text: null
      }
    }
  },
  computed: {
    ...mapState([
      'appConfig'
    ]),
    ...mapGetters([
      'showDevControls',
      'overwriteFormWithTestData'
    ]),
    customerAddress () {
      return `${this.userFormInput.streetAddress} ${this.userFormInput.streetAddress2 || ''} ${this.userFormInput.addressUnitNumber || ''} ${this.userFormInput.city}, ${this.userFormInput.state} ${this.userFormInput.postalCode}`
    },
    insideSales () {
      if (this.userFormInput && this.userFormInput.inSideSalesPeople) {
        return this.userFormInput.inSideSalesPeople.split(';').map((person) => {
          const splitPerson = person.split(':')
          const hasEmail = splitPerson[2].length
          return { name: splitPerson[0], hasEmail, email: splitPerson[2] }
        })
      }
      return false
    },
    outsideSales () {
      if (this.userFormInput && this.userFormInput.outSideSalesPeople) {
        return this.userFormInput.outSideSalesPeople.split(';').map((person) => {
          const splitPerson = person.split(':')
          const hasEmail = splitPerson[2].length
          return { name: splitPerson[0], hasEmail, email: splitPerson[2] }
        })
      }
      return false
    }
  },
  watch: {
    customerAccountNumber: {
      handler (newVal, oldVal) {
        if (!newVal) {
          this.customerAccountFetched = false
        }
      }
    },
    'userFormInput.accountNumber': {
      handler (newVal, oldVal) {
        if (!this.isInternal() && newVal && !oldVal) {
          this.getAccountNumberInfo(newVal)
        }
      }
    },
    'formData.accountName': {
      handler () {
        // trigger vuelidate $dirty flag for optional/hidden/default data
        this.vuelidate.accountName.$touch()
      }
    }
  },
  async mounted () {
    this.bannerId = this.getBannerIdFromName()
    const accountNumber = this.userFormInput.accountNumber
    if (accountNumber) {
      const customerAccount = await this.searchForCustomerAccountInfoFromSales(accountNumber)
      if (customerAccount && customerAccount.length > 0) {
        const longAccountNumber = `${customerAccount[0].customerName} (${customerAccount[0].customerNumber})   |   Branch: ${customerAccount[0].branchNumber} - ${customerAccount[0].branchName}`
        this.customerAccountNumber.value = accountNumber
        this.customerAccountNumber.text = longAccountNumber
        await this.handleAccountSelected(this.customerAccountNumber)
      }
    }
  },
  methods: {
    mapCustomerAccountSearchResults (items) {
      const mappedItems = items
        .filter(x => x.companyId === this.bannerId)
        .map((item) => ({
          value: item.customerNumber,
          text: `${item.customerName} (${item.customerNumber})   |   Branch: ${item.branchNumber} - ${item.branchName}`
        }
        ))
      return mappedItems
    },
    // converts input to stringthat is passed from @input watcher that is emitted in JobAccountComboboxDialog
    async handleAccountSelected (selectedItem) {
      if (!selectedItem || !selectedItem.value) {
        this.formData.accountNumber = ''
        return
      }
      const accountNumber = selectedItem.value.toString()
      await this.getAccountNumberInfo(accountNumber)
    },

    async searchForCustomerAccountInfoFromSales (selectedItem) {
      const urlTenant = this.$route.params.tenant

      // get queryParams ready using searchActionObj
      const queryParams = new URLSearchParams()

      // When searching for Bill-To account numbers we should should NOT return results for ship-to's only (unless they are also a bill to)
      const searchActionObj = {
        banner: urlTenant,
        source: 'all',
        term: selectedItem,
        filters: [
          'billto' // filtering on billto also returns accounts that are billto/shipto combos
        ],
        orderBy: 'best match',
        page: 1
      }
      const {
        ...params
      } = searchActionObj

      for (const key in params) {
        if (params[key]) queryParams.append(key, params[key])
      }

      const basePlattForceRootUrl = this.appConfig.plattforceUrl
      return this.$http
        .get(`${basePlattForceRootUrl}/api/v1/customers/search`, { params: queryParams })
        .then(res => {
          // Filter out Cash Accounts
          const filteredResults = res.data.value.filter(acct => acct.cashOrCredit !== 'C' && !acct.isEnterprise)
          return filteredResults
        })
        .catch(err => {
          console.log(err)
          return null
        })
        .finally(() => {
        })
    },
    async getAccountNumberInfo (accountId) {
      this.loading = true
      this.customerAccountFetched = false
      const tenant = this.$route.params.tenant === 'platt' ? 'platt' : 'rexel'
      return await getCustomerAccountInfo(accountId, tenant, this.$route.query.sftoken)
        .then(res => {
          const results = res
          if (results.companyName != null) {
            this.userFormInput = lodash.pick(results, [
              'accountNumber',
              'branchName',
              'branchNumber',
              'branchManager',
              'branchManagerEmail',
              'branchManagerErpUserId',
              'companyName',
              'streetAddress',
              'streetAddress2',
              'addressUnitNumber',
              'city',
              'state',
              'postalCode',
              'creditRep',
              'creditRepErpUserId',
              'creditManagerEmail',
              'inSideSalesPerson',
              'outSideSalesPerson',
              'inSideSalesPeople',
              'outSideSalesPeople',
              'email',
              'phone'
            ])
            // Only need these two fields to store to db
            this.formData.accountNumber = this.userFormInput.accountNumber
            this.formData.accountName = this.userFormInput.companyName
            this.formData.streetAddress1 = this.userFormInput.streetAddress || null
            this.formData.streetAddress2 = this.userFormInput.streetAddress2 || ''
            this.formData.city = this.userFormInput.city || null
            this.formData.state = this.userFormInput.state || null
            this.formData.postalCode = this.userFormInput.postalCode || null
            this.formData.contactNumber = this.userFormInput.phone
            this.formData.emailAddress = this.userFormInput.email
            this.customerAccountFetched = true

            return res
          }
        })
        .catch(err => {
          console.error(err)
          this.showError = true
          this.customerAccountFetched = false
          return null
        })
        .finally(() => {
          this.loading = false
        })
    },
    isInternal () {
      return this.$route.meta.isInternalForm
    }
  }
}
</script>
<style scoped>
  .nav-button {
  background-color: #099c98;
  font-size: 0.70rem;
  font-weight: bold;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
  .nav-button-clear {
  font-size: 0.70rem;
  font-weight: bold;
  border-radius: 0.125rem;
  margin: 0.375rem;
  padding: 0.5rem 1.6rem;
  border: 0;
}
.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
</style>
