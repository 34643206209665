<template>
  <fieldset>
    <div class="row">
      <!--   Internal completed card  -->
      <template v-if="$route.meta.isInternalForm">
        <div class="gray-box">
          <p>
            This cash account has been created in {{ bannerServiceName }}. A welcome email has been sent to the below
            address.
          </p>
        </div>
        <div class="col-md-12">
          <div class="complete-label">
            Company:
          </div>
          <div class="complete-value">
            {{ submittedData.companyName }}
          </div>
        </div>

        <div class="col-md-12">
          <div class="complete-label">
            Contact:
          </div>
          <div class="complete-value">
            {{ submittedData.firstName }} {{ submittedData.lastName }} <span
              style="margin-left: 1em;">{{ submittedData.emailAddress }}</span>
          </div>
        </div>

        <div v-if="$route.params.tenant !== 'platt' && identity != null">
          <div class="col-md-12">
            <div class="complete-label">
              Customer Segment:
            </div>
            <div class="complete-value">
              {{ submittedData.customerTypeCategory }}
            </div>
          </div>
          <div class="col-md-12">
            <div class="complete-label">
              Price Class:
            </div>
            <div class="complete-value">
              {{ submittedData.customerPriceClass }}
            </div>
          </div>

          <div class="col-md-12">
            <div class="complete-label">
              Assigned Branch:
            </div>
            <div class="complete-value">
              {{ submittedData.assignedBranch }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="complete-label">
            New Account Number:
          </div>
          <div class="complete-value">
            {{ submittedData.accountNumber }}
          </div>
        </div>
        <div>
          <a
            :href="creditAppHref"
            target="_top"
            style="color:blue">
            Send Credit Application Link
          </a>
        </div>
      </template>

      <!--   External completed card   -->
      <template v-else>
        <div class="font-weight-bold col-md-12">
          Thank you for creating a cash account with {{ formattedTenant }}!
        </div>

        <div class="col-md-12">
          We look forward to serving you. Please check your email inbox for instructions to complete your online access.
        </div>

        <div class="col-md-12 d-flex justify-start flex-wrap external-gap">
          <div class="mw-100">
            <div class="complete-label">
              Customer Name:
            </div>
            <div class="complete-value text-uppercase">
              {{ submittedData.companyName }}
            </div>
          </div>

          <div class="mw-100">
            <div class="complete-label">
              Contact:
            </div>
            <div class="complete-value text-uppercase">
              {{ submittedData.firstName }} {{ submittedData.lastName }}
            </div>
          </div>

          <div class="mw-100">
            <div class="complete-label">
              Contact Email:
            </div>
            <div class="complete-value text-uppercase">
              {{ submittedData.emailAddress }}
            </div>
          </div>

          <div class="mw-100">
            <div class="complete-label">
              New Account Number:
            </div>
            <div class="complete-value text-uppercase">
              {{ submittedData.accountNumber }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </fieldset>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CashAccountFormComplete',
  props: {
    submittedData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      creditAppUrl: '',
      tenant: ''
    }
  },
  computed: {
    ...mapGetters([
      'identity',
      'company'
    ]),
    creditAppHref () {
      const tenant = this.tenant.toUpperCase()
      return `mailto:${this.submittedData.emailAddress}?Subject=Link%20To%20Credit%20Application%20-%20${tenant}&Body=${this.creditAppUrl}`
    },
    shortenedUrl () {
      return this.company.url.slice(4)
    },
    bannerServiceName () {
      return this.tenant === 'platt' ? 'AS400' : 'Eclipse'
    },
    formattedTenant () {
      return this.tenant.charAt(0).toUpperCase() + this.tenant.slice(1)
    }
  },
  async mounted () {
    this.tenant = this.$route.params.tenant
    this.creditAppUrl = await this.getCreditApplicationUrl()
  },
  methods: {
    async getCreditApplicationUrl () {
      return this.$http.get(`/api/CashAccount/credit-application-url/${this.tenant}/${this.submittedData.accountNumber}`)
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.err(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.complete-label {
  color: #777;
  font-size: 12px;
}

.complete-value {
  height: 1.4em;
  // Overflow ellipsis
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.gray-box {
  // DRY ME, could maybe be a component? repeated
  font-size: 14px;
  width: 100%;
  padding: 1em;
  margin: 1.2em 0;
  background-color: #eeeeee;
}

.external-gap {
  gap: 18px 48px
}
</style>
