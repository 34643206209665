<template>
  <form-group
    v-if="vuelidate"
    :validator="vuelidate"
    :name="inputLabel">
    <v-select
      v-if="isInternal"
      v-model="inputValue"
      filled
      dense
      class="rounded-0"
      hide-details="auto"
      :single-line="!placeholder && !persistLabel"
      :label="inputLabel"
      v-bind="$attrs"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      :items="options"
      :item-text="propertyToShow"
      item-value="key"
      @change="onChange(inputValue)"
      @blur="vuelidate != null ? vuelidate.$touch() : null">
      <template #label>
        {{ inputLabel }} <span v-if="isRequired">
          *
        </span>
      </template>
    </v-select>
    <v-select
      v-else
      v-model="inputValue"
      outlined
      dense
      class=""
      hide-details="auto"
      v-bind="$attrs"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="disabled"
      :items="options"
      :item-text="propertyToShow"
      item-value="key"
      @change="onChange(inputValue)"
      @blur="vuelidate != null ? vuelidate.$touch() : null">
      <template #label>
        <span class="external-label">
          {{ inputLabel }}
        </span> <span v-if="isRequired">
          *
        </span>
      </template>
    </v-select>
  </form-group>
</template>

<script>
import FormGroup from 'components/form/FormGroup.vue'
export default {
  name: 'FormDropdown',
  components: {
    FormGroup
  },
  props: {
    value: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    persistLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    onChange: {
      type: Function,
      required: false,
      default: function () {
        // do nothing...
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    showDefaultOption: {
      type: Boolean,
      required: false,
      default: false
    },
    showValue: {
      type: Boolean,
      required: false,
      default: false
    },
    vuelidate: {
      type: [Object, null],
      required: false,
      default: null
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.updateValue(newValue)
      }
    },
    propertyToShow () {
      return this.showValue ? 'value' : 'key'
    },
    isRequired () {
      return (this.vuelidate.required != null && this.vuelidate.$invalid === true)
    },
    isInternal () {
      return this.$route.name === 'adminApplication'
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  color: red;
}
.v-label--active {
  .external-label {
    color: rgba(0,0,0,.6);
  }
}
.external-label {
  color: rgba(0,0,0,.6);
}
</style>
