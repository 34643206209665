<template>
  <span class="file-icon">
    <img
      v-if="iconSrc"
      :src="iconSrc" />
  </span>
</template>

<script>
const fileTypes = {
  img: ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  csv: ['csv', 'xls', 'xlsx'],
  word: ['doc', 'docx', 'docm', 'dotx'],
  pdf: ['pdf'],
  txt: ['txt']
}

export default {
  name: 'FileIcon',
  props: {
    filename: {
      type: String,
      required: true
    }
  },
  computed: {
    extension () {
      const split = this.filename.split('.')
      return split[split.length - 1]
    },
    iconSrc () {
      let iconSrc = null
      Object.keys(fileTypes).some((fileKey) => {
        if (fileTypes[fileKey].includes(this.extension)) {
          iconSrc = require(`@/assets/icons/file-types/${fileKey}.svg`) // eslint-disable-line import/extensions
          return true
        }
        return false
      })
      return iconSrc || require('@/assets/icons/file-types/any_file.svg') // eslint-disable-line import/extensions
    }
  }
}
</script>

<style scoped>
.file-icon {
  display: inline-block;
  width: 36px;
  height: 42px;
}

.file-icon img {
  width: 36px;
}
</style>
