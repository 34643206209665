var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('FormInputGroupAddressInfo',{ref:"groupAddress",attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate,"form-labels":_vm.formLabels,"form-data":_vm.formData,"field-names":{
      streetAddress: 'streetAddress1',
      addressUnitNumber: 'streetAddress2',
      city: 'city',
      state: 'state',
      postalCode: 'postalCode'
    }},model:{value:(_vm.userFormInput),callback:function ($$v) {_vm.userFormInput=$$v},expression:"userFormInput"}}),(!_vm.isStepDisabled)?_c('v-row',[_c('v-col',[_c('form-address-validation',{attrs:{"form-data":_vm.formData,"vuelidate":_vm.vuelidate,"close-modal-from-outside":_vm.closeModal},on:{"close":function($event){_vm.closeModal = false},"updateFormData":_vm.updateAddress,"editAddress":_vm.editAddress}})],1)],1):_vm._e(),_c('form-contact-cards',{attrs:{"disabled":_vm.isStepDisabled,"card-title":"Alt-shipping Address","contacts":_vm.shipTosAddressesFormatted,"main-card-title":"Primary Address","main-card-id":_vm.addressId},on:{"removeContact":_vm.warningCheck}}),(!_vm.isStepDisabled)?_c('v-row',[_c('v-col',[_c('job-account-add-alternate-job-address',{attrs:{"form-labels":_vm.formLabels,"disabled":_vm.addAddressIsDisabled},on:{"updateAlternateAddress":_vm.updateAlternateAddress}})],1)],1):_vm._e(),(!_vm.isPlatt() && _vm.userFormInput.alternateShipTos.length > 0)?_c('job-account-alt-ship-questions',{attrs:{"form-labels":_vm.formLabels,"user-form-input":_vm.userFormInput,"is-step-disabled":_vm.isStepDisabled}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.showAltWarning),callback:function ($$v) {_vm.showAltWarning=$$v},expression:"showAltWarning"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Warning! ")]),_c('v-card-text',[_vm._v(" Removing this alternate shipping address will cause your answers to the alternate shipping address questions below to be lost ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.showAltWarning = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.removeContact}},[_vm._v(" Remove ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }