<template>
  <div
    id="credit-status-btn"
    class="component-container dropdown-credit-btn">
    <div class="status-button">
      <button
        class="btn btn-rounded btn-default get-status-btn"
        :class="{'btn-dropdown': !isApproved && canEdit}"
        data-toggle="dropdown">
        <i
          class="fa"
          :style="{'color': color }"
          :class="{'fa-check-circle': status == 'approved', 'fa-minus-circle': status == 'pending', 'fa-times-circle': status == 'denied'}"></i>
        <span class="has-dropdown">
          {{ status }}
        </span>
      </button>
      <ul
        v-if="!isApproved && canEdit"
        class="dropdown-menu approval-status-dropdown-menu margin-top-minus-15"
        role="menu">
        <li>
          <a
            href="#"
            @click="openModal('approvalModal')">
            Approve
          </a>
        </li>
        <li v-if="!isPending">
          <a
            href="#"
            @click="openModal('pendingModal')">
            Pending
          </a>
        </li>
        <li v-if="!isDenied">
          <a
            href="#"
            @click="openModal('denialModal')">
            Deny
          </a>
        </li>
        <li v-if="isPending">
          <a
            href="#"
            @click="openModal('pendingModal')">Edit Pending
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script type="text/javascript" >
export default {
  name: 'AppTag',
  props: {
    app: {
      required: true,
      type: Object
    },
    canEdit: {
      required: true,
      type: Boolean
    },
    color: {
      required: true,
      type: String
    },
    bg: {
      required: true,
      type: String
    },
    status: {
      required: true,
      type: String
    }
  },
  computed: {
    isApproved () {
      return this.status === 'approved'
    },
    isDenied () {
      return this.status === 'denied'
    },
    isPending () {
      return this.status === 'pending'
    }
  },
  methods: {
    openModal: function (obj) {
      this.$emit('openModal_', obj)
    }
  },
  template: '#tag-tmpl'
}
</script>
