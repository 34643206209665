const CLIENT_ID = 'credit.frontend'
export const REDIRECT_URI = 'auth-callback'
export const SILENT_REDIRECT_URI = 'silent-auth-callback'

export const OIDC_DEFAULT_SETTINGS = {
  client_id: CLIENT_ID,
  redirect_uri: `${window.location.origin}/${REDIRECT_URI}`,
  scope: 'openid profile api',
  post_logout_redirect_uri: `${window.location.origin}`,
  response_type: 'id_token token',
  silent_redirect_uri: `${window.location.origin}/${SILENT_REDIRECT_URI}`,
  acr_values: ['idp:keycloak']
}

export const OIDC_DEFAULT_SESSION_SETTINGS = {
  monitorSession: true,
  automaticSilentRenew: true,
  staleStateAge: 300, // set to 300 secs as in the Sales app
  loadUserInfo: true
}
