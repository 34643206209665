<template>
  <div>
    <v-switch
      :disabled="disabled"
      :input-value="inputValue"
      class="mx-2"
      :label="inputLabel"
      @change="$store.dispatch(storeAction)">
    </v-switch>
  </div>
</template>

<script>

export default {
  name: 'DevSwitch',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    storeAction: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.updateValue(newValue)
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>
