<template>
  <v-container class="pa-0 h-100">
    <v-card
      class="card ma-0 h-100">
      <div class="card-block">
        <h6>Denial Reasons</h6>
        <denied-chart
          :chart-data="mappedChartData"
          :options="options"
          class="doughnut-chart"></denied-chart>

        <div class="mt-8">
          <v-layout
            v-for="(label, index) in labelList"
            :key="index"
            class="mb-2 mx-0 text-subtitle-2"
            row>
            <v-flex
              xs2
              :style="{backgroundColor: label.color, color: 'white'}">
              <div class="h-100 d-flex justify-center align-center">
                {{ label.data }}
              </div>
            </v-flex>
            <v-flex xs1></v-flex>
            <v-flex
              class="font-weight-bold"
              xs9>
              {{ label.name }}
            </v-flex>
          </v-layout>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import DeniedChart from './Denied'

export default {
  name: 'DeniedCard',
  components: {
    'denied-chart': DeniedChart
  },
  props: {
    chartData: {
      required: false,
      type: Array,
      default: () => null
    }
  },
  data () {
    return {
      mappedChartData: null,
      options: null,
      labelList: null,
      backgroundColor: [
        '#00A14D',
        '#999999',
        '#0084D8',
        '#A10000',
        '#6800a0',
        '#aaf',
        '#dad',
        '#FF8200',
        '#E7423C',
        '#A10301',
        '#007EDA',
        '#13BBB6',
        '#9C27B0'
      ]
    }
  },
  created () {
    this.labelList = this.chartData.map((label, i) => {
      return {
        color: this.backgroundColor[i],
        data: label.value,
        name: label.label
      }
    })
  },
  mounted () {
    this.mappedChartData = {
      labels: this.chartData.map(i => i.label),
      datasets: [
        {
          label: 'Denial Reasons',
          backgroundColor: this.backgroundColor,
          data: this.chartData.map(i => i.value)
        }
      ]
    }

    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      tooltips: {
        custom: function (tooltip) {
          if (!tooltip) return
          tooltip.displayColors = false
        },
        callbacks: {
          title: function (tooltipItem, data) {
            return 'Total:'
          },
          label: function (tooltipItem, data) {
            return data.datasets[0].data[tooltipItem.index]
          }
        }
      },
      pieceLabel: {
        mode: 'percentage',
        precision: 0,
        showZero: true,
        fontSize: 12,
        fontColor: '#fff',
        position: 'default'
      }
    }
  }
}
</script>
