export default {
  computed: {
    usesAlternateAddress () {
      return typeof this.formData.alternateShipTo !== 'undefined'
    },
    formDataLocal () {
      return this.usesAlternateAddress ? this.formData.alternateShipTo : this.formData
    },
    formDataUsesPostalCode () {
      return typeof this.formDataLocal.postalCode !== 'undefined'
    }
  },
  methods: {
    updateAddress (validatedAddress) {
      const keys = Object.keys(validatedAddress)
      if (this.formDataUsesPostalCode) {
        const zipCodeIndex = keys.indexOf('zipCode')
        keys[zipCodeIndex] = 'postalCode'
      }
      keys.map((k) => {
        const setValue = k === 'postalCode' ? validatedAddress.zipCode : validatedAddress[k]
        this.formDataLocal[k] = setValue === null ? '' : setValue
        return k
      })
    },
    editAddress (button) {
      const labels = button.closest('fieldset').querySelectorAll('label')
      const foundLabels = Array.prototype.slice.call(labels).filter((l) => {
        return l.innerHTML.trim() === 'Street Address:'
      })
      if (foundLabels.length <= 0) return
      const l = foundLabels[foundLabels.length - 1]
      const input = l.parentElement.closest('.form-input-text').querySelector('input')
      setTimeout(() => {
        input.focus()
      }, 0)
    }
  }
}
