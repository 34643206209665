<template>
  <div>
    <div
      v-if="successMessage.length > 0"
      class="on-success">
      {{ successMessage }}
    </div>
    <div
      v-if="errorMessage.length > 0"
      class="on-error">
      {{ errorMessage }}

      <FormValidationFailuresSummary
        v-if="validationFailures != null"
        :messages="validationFailures"></FormValidationFailuresSummary>
    </div>
  </div>
</template>

<script>
import FormValidationFailuresSummary from './FormValidationFailuresSummary.vue'

export default {
  name: 'FormResponseBlock',
  components: {
    FormValidationFailuresSummary
  },
  props: {
    successMessage: {
      type: String,
      required: false,
      default: ''
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    validationFailures: {
      type: Array,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>
li {
  list-style-type: none;
}
.on-success {
  font-size: 12px;
  margin-left: 1em;
  color: #4285f4;
}
.on-error {
  font-size: 12px;
  margin-left: 1em;
  color: #b40000;
}
</style>
