<template>
  <div
    v-if="!app.approved"
    class="component-container dropdown-credit-btn">
    <div class="align-with-header">
      <button
        v-if="app.Approved == null || !app.Approved"
        type="button"
        :disabled="!creditReportBtn"
        class="btn btn-rounded btn-dropdown btn-primary run-credit-report"
        data-toggle="dropdown">
        <span
          v-if="creditReportBtn"
          class="has-dropdown">
          Actions
        </span>
        <span
          v-if="!creditReportBtn"
          class="has-dropdown">
          <img
            src="../../../../assets/images/puff.svg"
            class="loading-img-btn" /> Loading...
        </span>
      </button>
      <ul
        class="dropdown-menu credit-report-dropdown-menu margin-top-minus-15"
        role="menu">
        <li v-if="canEdit">
          <a
            href="#"
            @click="runCreditBusiness(app)">
            Run Credit - Business
          </a>
        </li>
        <li
          v-for="g in filteredSignatories"
          :key="g.signatoryId">
          <a
            href="#"
            @click="runCreditGuarantor(g)">
            Run Credit - {{ g.firstName }} {{ g.middleInitial }} {{ g.lastName }}
          </a>
        </li>
        <li v-if="showRequestGurantee && canEdit">
          <a
            href="#"
            @click="requestGuarantee()">
            Request Guaranty
          </a>
        </li>
        <li v-if="showUndoRequestGuarantee && canEdit">
          <a
            href="#"
            @click="undoRequestGuarantee()">
            Undo Request Guaranty
          </a>
        </li>
        <li>
          <router-link :to="{ name: 'creditAppDetails', params: {id: app.guid, tenant: app.bannerId} }">
            Application Details
          </router-link>
        </li>
        <li v-if="globalCustomerId">
          <a
            target="_blank"
            :href="`${$store.getters.plattforceCustomerRootUrl}/${$route.params.tenant}/customers/${globalCustomerId}`">
            View on Sales Central
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CompanyMixin from 'mixins/company.mixin'

export default {
  name: 'ActionsList',
  mixins: [CompanyMixin],
  props: {
    app: {
      required: true,
      type: Object
    },
    globalCustomerId: {
      required: false,
      type: Number,
      default: () => null
    },
    creditReportBtn: {
      required: true,
      type: Boolean
    },
    canEdit: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    showRequestGurantee () {
      const signatories = this.app.signatories
      for (const i in signatories) {
        if (!signatories[i].guarantor || !signatories[i].hasSigned) return true
      }
      return false
    },
    showUndoRequestGuarantee () {
      const signatories = this.app.signatories
      for (const i in signatories) {
        if (signatories[i].guarantor && !signatories[i].hasSigned) return true
      }
      return false
    },
    filteredSignatories () {
      if (!this.canEdit) {
        return []
      }
      return this.app.signatories.filter(s => s.guarantor && s.hasSigned)
    }
  },
  methods: {
    runCreditGuarantor: function (guarantor) {
      this.$emit('runCreditGuarantor_', guarantor)
    },
    runCreditBusiness: function (app) {
      this.$emit('runCreditBusiness_', app)
    },
    requestGuarantee: function () {
      this.$emit('requestGuarantee_')
    },
    undoRequestGuarantee: function () {
      this.$emit('undoRequestGuarantee_')
    }
  }
}
</script>
<style lang="scss">
.dropdown-credit-btn {
  width: 100%;
  font-size: .875rem;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  .btn {
    &:focus {
      box-shadow: none;
    }
  }
  @media(max-width: 992px) {
    margin-top: 10px;
    width: auto;
  }
  @media(min-width: 768px) {
    width: auto;
  }
}
</style>
