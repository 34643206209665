import axios from 'ca-http-service'
const baseEndpoint = '/api/FormDataStorage/'

export const saveInProgressForm = async (request, isInternal, sftoken = null) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
  const endPoint = isInternal ? `${baseEndpoint}save-in-progress-form` : `${baseEndpoint}save-in-progress-form-external`
  const config = isInternal
    ? {
        headers
      }
    : {
        headers,
        params: new URLSearchParams({ sftoken })
      }
  return axios
    .post(
      endPoint,
      request,
      config
    )
}

export const deleteInProgressForm = async (request) => {
  return axios
    .post(
      `${baseEndpoint}delete-in-progress-form`,
      request
    )
}

export const getFormHistory = async (query) => {
  return axios
    .get(
      `${baseEndpoint}form-history`,
      {
        params: query
      }
    )
}

export const getInProgressForm = async (applicationID) => {
  return axios
    .get(
      `${baseEndpoint}in-progress-form`,
      {
        params: {
          applicationID
        }
      }
    )
}

export const getFormAttachments = async (request) => {
  return axios
    .post(
      `${baseEndpoint}form-attachments`,
      request
    )
}

export const downloadFormFile = async (request) => {
  return axios
    .get(
      `${baseEndpoint}download/${request}`,
      {
        responseType: 'blob'
      }
    )
}
