<template>
  <div>
    <div class="my-2 font-weight-bold pa-2">
      Form History <em class="grey--text">
        (All Banners)
      </em>
    </div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col
            cols="4"
            class="px-2">
            <v-select
              v-model="filterType"
              class=""
              :items="[ 'My', 'Branch', 'Regional' ]"
              filled
              hide-details
              single-line
              label="Filter"
              @change="filterChanged($event)">
            </v-select>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search for a form"
              single-line
              hide-details
              filled></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="filterSavedFormsData"
        :footer-props="footerProps"
        :options.sync="paginationOptions"
        :search="search"
        :sort-by="['updatedDate']"
        :sort-desc="true"
        item-key="id"
        :disable-sort="$vuetify.breakpoint.smAndDown"
        class="elevation-1"
        :hide-default-header="$vuetify.breakpoint.mobile">
        <!-- This generates the mobile form table by hidding the default headers so you can control and change the mobile view -->
        <template
          v-if="$vuetify.breakpoint.mobile"
          #body="{ items }">
          <tr
            v-for="item in items"
            :key="item.accountnumber">
            <td>
              <ul>
                <div class="d-flex justify-space-between pt-2">
                  <li style="color: #9E9E9E;">
                    Form
                  </li>
                  <v-menu v-if="canRouteToForm(item) || canDeleteForm(item)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-4"
                        v-bind="attrs"
                        v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="canRouteToForm(item)"
                        @click="$router.push({ path: item.link } )">
                        <v-list-item-title>
                          <v-icon>mdi-arrow-right</v-icon>
                          View
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        v-if="canDeleteForm(item)"
                        @click="showFormDialog(item)">
                        <v-list-item-title>
                          <v-icon>mdi-delete</v-icon>
                          Delete
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <li class="text-subtitle-2 font-weight-bold">
                  {{ item.formName }}
                </li>
              </ul>
              <ul>
                <li style="color: #9E9E9E;">
                  Status
                </li>
                <li
                  class="text-subtitle-2 font-weight-bold"
                  style="font-size: 30%;">
                  {{ item.status }}
                </li>
              </ul>
              <ul>
                <li style="color: #9E9E9E;">
                  Company Name/Bill-To Account
                </li>
                <li class="text-subtitle-2 font-weight-bold">
                  {{ item.accountLabel }}
                </li>
              </ul>
              <ul>
                <li style="color: #9E9E9E;">
                  Date Started
                </li>
                <li class="text-subtitle-2 font-weight-bold text-no-wrap">
                  {{ item.createdDate != null ? formatDate(item.createdDate) : '-' }}{{ item.createdDate != null ? formatTime(item.createdDate) : '-' }}
                </li>
              </ul>
              <ul>
                <li style="color: #9E9E9E;">
                  Last Modified
                </li>
                <li class="text-subtitle-2 font-weight-bold">
                  {{ item.updatedDate != null ? formatDate(item.updatedDate) : '-' }}{{ item.updatedDate != null ? formatTime(item.updatedDate) : '-' }}
                </li>
              </ul>
              <ul>
                <li style="color: #9E9E9E;">
                  Job Name
                </li>
                <li class="text-subtitle-2 font-weight-bold">
                  {{ item.projectName }}
                </li>
              </ul>
              <ul>
                <li style="color: #9E9E9E;">
                  File(s)
                </li>
                <li class="text-subtitle-2 font-weight-bold">
                  <v-icon
                    v-if="item.hasAttachedFiles === true"
                    small
                    class="mr-2"
                    @click="openSavedFilesModal(item)">
                    mdi-file
                  </v-icon>
                </li>
              </ul>
              <v-divider class="mx-3"></v-divider>
            </td>
          </tr>
        </template>

        <template v-slot:item.accountLabel="{ item }">
          <a
            class="text-truncate row-default"
            :title="generateAccountLabelText(item)">
            {{ item.accountLabel }}
          </a>
        </template>

        <template #item.updatedDate="{ value }">
          <span style="font-size: .9em;">
            {{ value != null ? formatDateUtc(value) : '-' }}
          </span>
          <span
            class="text-no-wrap"
            style="font-size: .9em;">
            {{ value != null ? formatTimeUtc(value) : '-' }}
          </span>
        </template>

        <template #item.projectName="{ value }">
          <a
            style="max-width: 140px;"
            class="text-truncate row-default"
            :title="value">
            {{ value }}
          </a>
        </template>

        <template #item.attachmentId="{item}">
          <v-icon
            v-if="item.hasAttachedFiles === true"
            small
            class="mr-2"
            @click="openSavedFilesModal(item)">
            mdi-file
          </v-icon>
        </template>

        <template #[`item.link`]="props">
          <v-menu v-if="canRouteToForm(props.item) || canDeleteForm(props.item)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-if="canRouteToForm(props.item)"
                @click="$router.push({ path: props.item.link} )">
                <v-list-item-title>
                  <v-icon>mdi-arrow-right</v-icon>
                  View
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="canDeleteForm(props.item)"
                @click="showFormDialog(props.item)">
                <v-list-item-title>
                  <v-icon>mdi-delete</v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <SavedFilesModal
        v-if="savedFiles != null"
        :on-close="onSavedFilesModalClose"
        :item="savedFiles"></SavedFilesModal>
    </v-card>
    <v-dialog
      v-if="selectedForm"
      v-model="showDialog"
      max-width="500px">
      <v-card>
        <v-toolbar
          style="background-color: #006330"
          flat
          dark>
          <div class="headline">
            Delete Form?
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="showDialog = !showDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col class="mt-4">
              Do you want to delete your in progress {{ selectedForm.formName }} form for {{ generateAccountLabelText(selectedForm) }}?
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="text-sm-right">
              <v-btn @click="showDialog = !showDialog">
                Cancel
              </v-btn>
              <v-btn
                color="#006330"
                class="ml-2 white--text"
                @click="deleteSelectedForm()">
                Proceed
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getFormHistory, deleteInProgressForm } from '@/api/forms/form-data-storage'
import SavedFilesModal from './SavedFilesModal.vue'

export default {
  name: 'FormsHistoryTable',
  components: {
    SavedFilesModal
  },
  props: {
    tenantId: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      search: '',
      selectOptions: [
        { key: 'mine', value: 'My' },
        { key: 'branch', value: 'Branch' },
        { key: 'regional', value: 'Regional' }
      ],
      footerProps: {
        itemsPerPageOptions: [10, 25, 50, 100]
      },
      paginationOptions: {},
      savedFormsData: [],
      savedFiles: null,
      selectedForm: null,
      showDialog: false,
      headers: [
        {
          text: 'Form',
          align: 'left',
          filterable: false,
          value: 'formName'
        },
        { text: 'Status', value: 'status' },
        { text: 'Company Name/Bill-To Account', value: 'accountLabel' },
        { text: '', value: 'accountName', sortable: false, align: ' d-none' },
        { text: '', value: 'companyName', sortable: false, align: ' d-none' },
        { text: 'Last Modified', value: 'updatedDate' },
        { text: 'Job Name', value: 'projectName' },
        // { text: 'SSO', value: 'createdByUserSSO' },
        // { text: 'Branch', value: 'assignedBranchID' },
        { text: 'File(s)', value: 'attachmentId', sortable: false },
        { text: '', value: 'link', sortable: false }
      ],
      filterType: null,
      loading: false,
      isMobile: true
    }
  },
  computed: {
    ...mapGetters([
      'identity',
      'defaultBranch',
      'isProductionEnvironment'
    ]),
    filterSavedFormsData () {
      // Don't show Credit Apps which don't have a createdByUserSSO
      if (this.isProductionEnvironment) {
        return this.savedFormsData.filter(item =>
          item.formName !== 'Credit Application' ||
          (item.formName === 'Credit Application' && item.createdByUserSSO)
        )
      } else {
        return this.savedFormsData
      }
    },
    filterTypeLabel () {
      return 'Filter'
    },
    filterTypeValidate () {
      return { required: false }
    }
  },
  watch: {
    // leaving this commented out in case we want fetch additional data upon search/filter updates.
    // this would come in handy in case we add a limit to query results.
    /*
    paginationOptions: {
      handler (options, oldval) {
        if (oldval.itemsPerPage !== undefined) {
          console.log("Changed page options", options, typeof oldval)
          //this.loadDataAsync()
        }
      },
      deep: true
    }
    */
  },
  async mounted () {
    await this.loadDataAsync()
  },
  created () {
    this.filterType = this.$store.state.dashboardFormHistoryFilter || 'My'
  },
  methods: {
    onSavedFilesModalClose () {
      this.savedFiles = null
    },

    formatDate (value) {
      const date = new Date(value).toLocaleString()
      const dmy = date.split(',')
      const dayMonthYear = dmy[0]

      const year = dayMonthYear.slice(dayMonthYear.length - 2, dayMonthYear.length)
      const formattedDayMonthYear = dayMonthYear.slice(0, dayMonthYear.length - 4) + year

      return formattedDayMonthYear
    },
    formatDateUtc (valueAsUtc) {
      const utcDate = new Date(`${valueAsUtc}`)
      const date = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset()) * 60 * 1000)
      const dmy = date.toLocaleString().split(',')
      const dayMonthYear = dmy[0]
      const year = dayMonthYear.slice(dayMonthYear.length - 2, dayMonthYear.length)
      const formattedDayMonthYear = dayMonthYear.slice(0, dayMonthYear.length - 4) + year
      return formattedDayMonthYear
    },
    formatTime (value) {
      const date = new Date(value).toLocaleString()
      const dmy = date.split(',')
      const time = dmy[1]

      const amPm = date.slice(date.length - 2, date.length)
      const formattedTime = time.slice(0, time.length - 6) + ' ' + amPm

      return formattedTime
    },
    formatTimeUtc (valueAsUtc) {
      const utcDate = new Date(`${valueAsUtc}`)
      const date = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset()) * 60 * 1000).toLocaleString()
      const dmy = date.split(',')
      const time = dmy[1]
      const amPm = date.slice(date.length - 2, date.length)
      const formattedTime = time.slice(0, time.length - 6) + ' ' + amPm
      return formattedTime
    },

    async loadDataAsync () {
      const currentUser = this.$store.getters.currentUserInfo
      if (currentUser != null) {
        this.loading = true
        let branchId = null
        let regionCode = null
        if (this.filterType === 'Branch') {
          if (this.identity.banner === 'platt') {
            // Platt - ZNode.dbo.CreditApplicationFormData.AssignedBranchId stored as short branchId
            // strip off the 5000
            branchId = (parseInt(this.identity.branchId) - 5000).toString()
          } else {
            branchId = this.identity.branchId
          }
        }
        if (this.filterType === 'Regional') {
          regionCode = this.$store.getters.defaultBranch.regionCode
        }

        await getFormHistory({
          filterType: this.filterType,
          tenantId: this.tenantId,
          incompleteOnly: 0,
          branchId,
          regionCode
        })
          .then((resp) => {
            if (resp != null) {
              const data = resp.data.data
              this.savedFormsData = data
            }
          })
          .catch((error) => {
            console.error('error', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    showFormDialog (item) {
      this.selectedForm = item
      this.showDialog = true
    },

    async deleteSelectedForm () {
      if (this.selectedForm === null) {
        this.showDialog = false
        return
      }
      const savedForms = [this.selectedForm]
      await this.deleteSavedFormData({
        currentUser: this.$store.getters.currentUserInfo !== undefined
          ? this.$store.getters.currentUserInfo
          : '',
        savedForms
      })
        .catch((error) => {
          console.error('error', error)
        })
        .finally(() => {
          this.loadDataAsync()
          this.selectedForm = null
          this.showDialog = false
          this.loading = false
        })
    },

    async deleteSavedFormData (payload) {
      return await deleteInProgressForm(payload)
    },

    openSavedFilesModal (item) {
      this.savedFiles = item
      this.$store.dispatch('modals/open', { name: 'saved-filed-modal' })
    },

    generateAccountLabelText (item) {
      let accountLabel = item.companyName
      if (item.accountNumber) {
        accountLabel += ` (${item.accountNumber})`
      }
      return accountLabel
    },

    async filterChanged (filterValue) {
      this.$store.dispatch('setDashboardFormHistoryFilter', this.filterType)
      this.savedFormsData = []
      await this.loadDataAsync()
    },
    // Determines whether the Delete menu item is visible in table
    canDeleteForm (item) {
      return (
        this.filterType === 'My' &&
        item.status === 'In Progress' &&
        item.canDelete &&
        item.createdByUserSSO &&
        item.createdByUserSSO === this.$store.getters.currentUserInfo.sso
      )
    },
    // Determines whether the View menu item is visible in table
    canRouteToForm (item) {
      if (item.formName === 'Credit Application' && item.status === 'Emailed Customer') {
        return true
      }
      if (this.filterType === 'My') {
        return (item.createdByUserSSO && item.createdByUserSSO === this.$store.getters.currentUserInfo.sso)
      } else {
        // Branch must match and SSO must match, or Not a CreditApp(Approved or Pending status)
        return (
          // this.identity.branchId === item.assignedBranchID &&
          (item.createdByUserSSO && item.createdByUserSSO === this.$store.getters.currentUserInfo.sso) ||
          (item.branchManagerSSO === this.$store.getters.currentUserInfo.sso) ||
          (item.formName !== 'Credit Application' && (item.status === 'Approved' || item.status === 'Pending'))
        )
      }
    }
  }
}
</script>

<style scoped>
.row-default {
  cursor: default!important;
}

</style>
