<template>
  <div
    class="checkbox-multi-select"
    :style="{'z-index': zIndex}">
    <!-- for more details on vue multi select go here https://vue-multiselect.js.org/# -->
    <multiselect
      :class="{ 'has-error': error }"
      track-by="value"
      label="text"
      :max-height="maxHeight"
      :options="copyOfOptions"
      :multiple="true"
      :placeholder="placeholder"
      :show-labels="false"
      :close-on-select="false"
      :reset-after="false"
      :searchable="searchable"
      :clear-on-select="false"
      :disabled="disabled"
      :internal-search="true"
      @select="handleSelect"
      @remove="handleSelect"
      @open="handleOpen"
      @close="handleClose">
      <template
        slot="option"
        slot-scope="props">
        <div class="checkbox-select-item">
          <input
            :id="props.option.value"
            type="checkbox"
            :checked="valueSelected(props.option.value)"
            @click.stop.prevent="handleSelect(props.option)" />
          <span>{{ props.option.text }}</span>
        </div>
      </template>
      <template
        slot="tag">
        <span></span>
      </template>
    </multiselect>
    <div class="row">
      <div
        v-for="(tag, index) in tags"
        :key="tag.value"
        :class="selectedContainerClass">
        <div class="tag-alert fade show">
          <span class="tag-text">
            {{ tag.text }}
          </span>
          <button
            v-if="!disabled"
            type="button"
            class="tag-close"
            aria-label="Close"
            @click="removeOption(tag, index)">
            <span aria-hidden="true">
              &times;
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'CheckboxMultiSelect',
  components: { Multiselect },
  props: {
    options: {
      type: Array,
      required: true,
      validator: function (options) {
        try {
          const obj = options[0]
          return (Object.prototype.hasOwnProperty.call(obj, 'text') && Object.prototype.hasOwnProperty.call(obj, 'value'))
        } catch (e) { return false }
      }
    },
    selected: {
      required: true,
      type: Array
    },
    placeholder: {
      type: String,
      default: '- Please select valid options -'
    },
    selectedContainerClass: {
      type: String,
      default: 'col-sm-6'
    },
    maxHeight: {
      type: Number,
      default: 200
    },
    searchable: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      copyOfOptions: this.options,
      tags: this.loadTags(this.selected),
      dropdownOpen: false,
      zIndex: 100,
      selectedList: []
    }
  },
  beforeMount () {
    this.selectedList = this.selected
  },
  methods: {
    handleSelect (option) {
      const index = this.selectedList.indexOf(option.value)
      if (index !== -1) {
        this.selectedList.splice(index, 1)
      } else {
        this.selectedList.push(option.value)
      }
    },
    handleOpen (option) {
      this.$emit('open')
      this.zIndex = 9999
      this.dropdownOpen = true
    },
    handleClose (option) {
      this.zIndex = 100
      this.tags = this.loadTags(this.selectedList)
    },
    loadTags (selected) {
      if (selected.length === 0) return []
      return this.options.filter(obj => {
        return (selected.indexOf(obj.value) !== -1)
      })
    },
    valueSelected (value) {
      return this.selectedList.indexOf(value) !== -1
    },
    removeOption (tag, index) {
      this.tags.splice(index, 1)
      this.selectedList.splice(this.selectedList.indexOf(tag.value), 1)
    }
  }
}
</script>

<style lang="scss">
  @import '../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';
  @import '../assets/scss/mixins/_flexbox.scss';

  .checkbox-multi-select {
    width: 100%;
    z-index: 10;

    .checkbox-select-item {
      input {
        margin-top: 1px;
      }
      span {
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
      }
    }

    $padding-horizontal: 10px;
    .tag-alert {
      font-size: 12px;
      @include flexbox;
      @include justify-content(space-between);
      @include align-items(center);
      padding: 0 0 0 $padding-horizontal;
      background-color: #e8e8e8;
      margin-top: 5px;
      margin-bottom: 0px;

      .tag-text {
        font-weight: bold;
        line-height: 1.1;
      }

      .tag-close {
        font-size: 24px;
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        min-height: 25px;
        width: 40px;
        outline: none !important;
        -webkit-appearance: none;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          opacity: .5;
          color: #000;
        }

        span {
          display: inline-block;
        }
      }
    }

    /* Overrides of vue-multiselect.min.css */
    .multiselect {
      color: #000;
      margin-bottom: 0px;
    }

    .multiselect__content-wrapper {
      z-index: 1000 !important;
    }

    .multiselect__single {
      color: #000;
    }

    .multiselect__input {
      width: 100% !important;
      position: relative !important;
    }

    .multiselect__option,
    .multiselect__option--selected {
      background: #fff;
      color: #000;
      font-weight: normal;
    }

    .has-error .multiselect__tags {
      border-color: #b40000!important;
    }

    // default behavior is multiselect puts all selected values in the selext box field
    // so we need to just simply hide these because they will be displayed elseware
    .multiselect__tags-wrap {
      display: none;
    }

    .multiselect__option--highlight {
      background: rgb(224, 224, 224) !important;
      color: #000 !important;
    }
    /* End of overrides */
  }
</style>
