<template>
  <div
    id="admin-login-component"
    class="component-container">
    <div class="info-card mx-5">
      <h4>Access Denied:</h4>
      <p>
        You don't have permission to access this feature, please contact your administrator to have them upgrade your permissions.
      </p>
      <button
        type="button"
        class="btn btn-primary"
        style="background-color: #084845"
        @click="goToLogin()">
        <span class="fa fa-refresh"></span> Retry
      </button>
    </div>
  </div>
</template>
<script type="text/javascript">
import { initAuth } from '@/auth'

// NOTE: Since all internal employees have access to admin, and you need to be able to login to be denied, I don't think we will ever reach this page anymore.

export default {
  name: 'AdminAccessDenied',
  methods: {
    async goToLogin () {
      await initAuth()

      this.$router.push({
        name: 'dashboard'
      })
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/scss/bootstrap.scss';
@import '../../assets/scss/mixins/flexbox.scss';
#customer-login-component {
  min-height: calc(100vh - 50px);

  .info-card {
    background-color: #f0fafe;
    margin-bottom: 2em;
    padding: 1em 3em;

    h4 {
      font-weight: bold;
    }

    a.more-info-button {
      font-weight: bold;
      color: #004168;
    }
  }

  .login-card {
    max-width: 600px;
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);

    .login-btns {
      text-align: center;
      margin-bottom: 1rem;
      margin-top: 1rem;

      a {
        width: 50%;
      }
    }

    .btn-rounded {
      min-width: 50%;
      margin: 2rem auto 0;
      display: block;
    }

    .form-group {
      margin-bottom: 1.25rem;

      .form-control-label {
        margin-bottom: 0.25rem;
        color: #777;
        font-size: 90%;
      }
    }

    .input-group {
      @include flex-direction(row-reverse);

      .form-control {
        border-left: 0;
        background: #eceeef;
        &:focus ~ .input-group-addon {
          border-color: #0090ec;
        }
        &.input-validation-error {
          ~ .input-group-addon,
          &:focus ~ .input-group-addon {
            border-color: #b40000;
          }
        }
      }

      .input-group-addon {
        border-right: 0;
        border-left: 1px solid rgba(26, 26, 26, 0.15);

        @include transition($input-transition);
      }
    }
  }

  a:hover {
    cursor: pointer;
  }

  span.website-name {
    display: inline-block;
  }

  .website-name::first-letter {
    text-transform: uppercase;
  }
}
</style>
