<template>
  <fieldset>
    <FormSectionCard title="Accounts Payable">
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-if="renderStep"
            v-model="accountPayableInput.firstName"
            persist-label
            :vuelidate="validations.accountPayable.firstName"
            :input-label="accountPayable.labels.firstName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="accountPayableInput.lastName"
            persist-label
            :vuelidate="validations.accountPayable.lastName"
            :input-label="accountPayable.labels.lastName"
            input-type="text"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="accountPayableInput.phone"
            persist-label
            :vuelidate="validations.accountPayable.phone"
            :input-label="accountPayable.labels.phone"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="pb-4">
          <FormTextInput
            v-model="accountPayableInput.phoneExt"
            persist-label
            :vuelidate="validations.accountPayable.phoneExt"
            :input-label="accountPayable.labels.phoneExt"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="accountPayableInput.fax"
            persist-label
            :vuelidate="validations.accountPayable.fax"
            :input-label="accountPayable.labels.fax"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="accountPayableInput.email"
            persist-label
            :vuelidate="validations.accountPayable.email"
            :input-label="accountPayable.labels.email"
            input-type="email"></FormTextInput>
        </v-col>
      </v-row>
    </FormSectionCard>
    <FormSectionCard
      v-if="!rexelEnergy"
      title="Purchasing Contact">
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="purchasingContactInput.firstName"
            persist-label
            :vuelidate="validations.purchasingContact.firstName"
            :input-label="purchasingContact.labels.firstName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="purchasingContactInput.lastName"
            persist-label
            :vuelidate="validations.purchasingContact.lastName"
            :input-label="purchasingContact.labels.lastName"
            input-type="text"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="purchasingContactInput.email"
            persist-label
            :vuelidate="validations.purchasingContact.email"
            :input-label="purchasingContact.labels.email"
            input-type="email"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="purchasingContactInput.phone"
            persist-label
            :vuelidate="validations.purchasingContact.phone"
            :input-label="purchasingContact.labels.phone"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="pb-4">
          <FormTextInput
            v-model="purchasingContactInput.phoneExt"
            persist-label
            :vuelidate="validations.purchasingContact.phoneExt"
            :input-label="purchasingContact.labels.phoneExt"></FormTextInput>
        </v-col>
      </v-row>
    </FormSectionCard>
    <FormSectionCard title="Preferences">
      <v-row>
        <v-col class="pb-4">
          <div class="pref-title">
            Statements
          </div>
          <v-divider class="mt-1 mb-3"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pt-0 pb-3">
          <FormInputRadioOptions
            v-model="preferencesInput.statementPreferredCommunicationType"
            :vuelidate="validations.preferences.statementPreferredCommunicationType"
            :input-label="preferences.labels.statementPreferredCommunicationType"
            :options="[
              {
                key: 'Email',
                value: '1',
              },
              {
                key: 'Fax',
                value: '2',
              },
              {
                key: 'Mail',
                value: '3',
              }
            ]"
            @input="updateCommunicationType"></FormInputRadioOptions>
        </v-col>
        <v-col
          v-if="['1', '2'].includes(preferencesInput.statementPreferredCommunicationType)"
          cols="12"
          lg="6"
          class="pt-0 pb-3">
          <FormTextInput
            v-show="preferencesInput.statementPreferredCommunicationType === '1'"
            v-model="preferencesInput.statementPreferredEmailAddress"
            persist-label
            :vuelidate="validations.preferences.statementPreferredEmailAddress"
            :input-label="preferences.labels.statementPreferredEmailAddress"
            input-type="email"></FormTextInput>
          <FormTextInput
            v-show="preferencesInput.statementPreferredCommunicationType === '2'"
            v-model="preferencesInput.statementPreferredFax"
            persist-label
            :vuelidate="validations.preferences.statementPreferredFax"
            :input-label="preferences.labels.statementPreferredFax"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          v-if="preferencesInput.statementPreferredCommunicationType === '3'"
          cols="12"
          class="pt-0 pb-2">
          <FormGroupAddressInfo
            v-model="preferencesInput"
            :vuelidate="validations.preferences"
            persist-label
            :form-labels="preferences.labels"
            :form-data="groupData.preferences.data"
            :field-names="{
              country: 'statementPreferredCountry',
              streetAddress: 'statementPreferredStreetAddress',
              addressUnitNumber: 'statementPreferredAddressUnitNumber',
              city: 'statementPreferredCity',
              state: 'statementPreferredState',
              postalCode: 'statementPreferredPostalCode'
            }"></FormGroupAddressInfo>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-4">
          <div class="pref-title">
            Invoices
          </div>
          <v-divider class="mt-1 mb-3"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pt-0 pb-3">
          <FormInputRadioOptions
            v-model="preferencesInput.invoicePreferredCommunicationType"
            :vuelidate="validations.preferences.invoicePreferredCommunicationType"
            :input-label="preferences.labels.invoicePreferredCommunicationType"
            :options="[
              {
                key: 'Email',
                value: '1',
              },
              {
                key: 'Fax',
                value: '2',
              },
              {
                key: 'Mail',
                value: '3',
              }
            ]"></FormInputRadioOptions>
        </v-col>
        <v-col
          v-if="['1', '2'].includes(preferencesInput.invoicePreferredCommunicationType)"
          cols="12"
          lg="6"
          class="pt-0 pb-3">
          <FormTextInput
            v-show="preferencesInput.invoicePreferredCommunicationType === '1'"
            v-model="preferencesInput.invoicePreferredEmailAddress"
            persist-label
            :vuelidate="validations.preferences.invoicePreferredEmailAddress"
            :input-label="preferences.labels.invoicePreferredEmailAddress"
            input-type="email"></FormTextInput>
          <FormTextInput
            v-show="preferencesInput.invoicePreferredCommunicationType === '2'"
            v-model="preferencesInput.invoicePreferredFax"
            persist-label
            :vuelidate="validations.preferences.invoicePreferredFax"
            :input-label="preferences.labels.invoicePreferredFax"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          v-if="preferencesInput.invoicePreferredCommunicationType === '3'"
          cols="12"
          class="pb-4">
          <FormGroupAddressInfo
            v-model="preferencesInput"
            persist-label
            :vuelidate="validations.preferences"
            :form-labels="preferences.labels"
            :form-data="groupData.preferences.data"
            :field-names="{
              country: 'invoicePreferredCountry',
              streetAddress: 'invoicePreferredStreetAddress',
              addressUnitNumber: 'invoicePreferredAddressUnitNumber',
              city: 'invoicePreferredCity',
              state: 'invoicePreferredState',
              postalCode: 'invoicePreferredPostalCode'
            }"></FormGroupAddressInfo>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pb-4">
          <FormInputRadioOptions
            v-model="preferencesInput.isPurchaseOrderRequired"
            :vuelidate="validations.preferences.isPurchaseOrderRequired"
            :input-label="preferences.labels.isPurchaseOrderRequired"
            :options="[
              {
                key: 'Yes',
                value: 'Yes',
              },
              {
                key: 'No',
                value: 'No',
              },
            ]"></FormInputRadioOptions>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pb-4">
          <FormInputRadioOptions
            v-model="preferencesInput.isTaxable"
            :vuelidate="validations.preferences.isTaxable"
            :input-label="preferences.labels.isTaxable"
            :options="[
              {
                key: 'Yes',
                value: 'Yes',
              },
              {
                key: 'No',
                value: 'No',
              },
            ]"></FormInputRadioOptions>
        </v-col>
      </v-row>
    </FormSectionCard>
    <FormSectionCard title="Documents">
      <v-row v-show="taxDocuments.length > 0">
        <v-col class="pb-3">
          <div class="pref-title">
            Tax Documents
          </div>
          <v-divider class="mt-1 mb-3"></v-divider>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="pb-3">
        <v-chip
          v-for="(td, index) in taxDocuments"
          :key="index"
          class="mb-3 mx-2 py-2"
          close
          label
          @click:close="removeDoc('tax', td)">
          <v-icon left>
            {{ `mdi-${fileIcon(td.extension)}` }}
          </v-icon>
          <span
            class="text-truncate file-name"
            :title="friendlyFilename(td.name)">
            {{ friendlyFilename(td.name) }}
          </span>
        </v-chip>
      </v-row>
      <v-row v-show="otherDocuments.length > 0">
        <v-col class="pb-3">
          <div class="pref-title">
            Other Documents
          </div>
          <v-divider class="mt-1 mb-3"></v-divider>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="pb-3">
        <v-chip
          v-for="(td, index) in otherDocuments"
          :key="index"
          class="mb-3 mx-2 py-2"
          close
          label
          @click:close="removeDoc('other', td)">
          <v-icon left>
            {{ `mdi-${fileIcon(td.extension)}` }}
          </v-icon>
          <span
            class="text-truncate file-name"
            :title="friendlyFilename(td.name)">
            {{ friendlyFilename(td.name) }}
          </span>
        </v-chip>
      </v-row>
      <v-row>
        <v-col class="py-4 flex-grow-0">
          <button
            type="button"
            class="btn btn-sm btn-default file-upload"
            @click="uploadDialog('other')">
            <i class="fa fa-cloud-upload"></i>
            Upload Other Documents
          </button>
        </v-col>
        <v-col class="py-4 flex-grow-0">
          <button
            v-show="preferencesInput.isTaxable === 'No'"
            type="button"
            class="btn btn-sm btn-primary file-upload"
            @click="uploadDialog('tax')">
            <i class="fa fa-cloud-upload"></i>
            Upload Tax Documents
          </button>
        </v-col>
      </v-row>
    </FormSectionCard>
    <FileUploadModal
      v-model="showModal"
      :modal-type="modalType"
      :previous-uploaded-documents="uploadedDocuments"
      :application-id="storageGuid"
      @uploaded="onUpload">
    </FileUploadModal>
  </fieldset>
</template>

<script>
import FormSectionCard from '@/components/form/FormSectionCard.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptionsV2.vue'
import FormGroupAddressInfo from '@/components/form/FormGroupAddressInfo.vue'
import FileUploadModal from '@/components/FileUploadModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Step2ContactInformation',
  components: {
    FormSectionCard,
    FormTextInput,
    FormInputRadioOptions,
    FormGroupAddressInfo,
    FileUploadModal
  },
  props: {
    groupData: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      accountPayableInput: new this.groupData.accountPayable.dataClass(), // eslint-disable-line new-cap
      purchasingContactInput: new this.groupData.purchasingContact.dataClass(), // eslint-disable-line new-cap
      preferencesInput: new this.groupData.preferences.dataClass(), // eslint-disable-line new-cap
      applicationInput: new this.groupData.application.dataClass(), // eslint-disable-line new-cap
      accountPayable: this.groupData.accountPayable,
      purchasingContact: this.groupData.purchasingContact,
      preferences: this.groupData.preferences,
      application: this.groupData.application,
      showModal: false,
      modalType: 'tax',
      taxDocuments: [],
      otherDocuments: [],
      renderStep: false
    }
  },
  computed: {
    uploadedDocuments () {
      if (this.modalType === 'tax') {
        return this.taxDocuments || []
      } else {
        return this.otherDocuments || []
      }
    },
    storageGuid () {
      if (this.modalType === 'tax') {
        return this.preferencesInput.storageGuid
      } else {
        return this.applicationInput.storageGuid
      }
    },
    ...mapGetters([
      'company'
    ]),
    rexelEnergy () {
      return this.company === 'RexelEnergy' || this.$route.params.tenant === 'rexelenergy'
    }
  },
  watch: {
    // reset the preferred field if it is not selected
    'preferencesInput.statementPreferredCommunicationType': {
      handler (newVal) {
        if (newVal === '0') {
          this.preferencesInput.statementPreferredCommunicationType = '1'
        }
        if (newVal !== '1') {
          this.preferencesInput.statementPreferredEmailAddress = ''
        }
        if (newVal !== '2') {
          this.preferencesInput.statementPreferredFax = ''
        }
      }
    },
    // reset the preferred field if it is not selected
    'preferencesInput.invoicePreferredCommunicationType': {
      handler (newVal) {
        if (newVal === '0') {
          this.preferencesInput.invoicePreferredCommunicationType = '1'
        }
        if (newVal !== '1') {
          this.preferencesInput.invoicePreferredEmailAddress = ''
        }
        if (newVal !== '2') {
          this.preferencesInput.invoicePreferredFax = ''
        }
      }
    }
  },
  created () {
    this.accountPayableInput = this.accountPayable.data
    this.purchasingContactInput = this.purchasingContact.data
    this.preferencesInput = this.preferences.data
    this.applicationInput = this.application.data
    this.taxDocuments = this.preferences.data.uploadedDocuments
    this.otherDocuments = this.application.data.uploadedDocuments
  },
  mounted () {
    this.renderStep = true
  },
  methods: {
    fileIcon (ext) {
      if (ext.includes('pdf')) {
        return 'file-pdf-outline'
      } else if (['.png', '.jpg', '.jpeg', '.svg'].includes(ext)) {
        return 'file-image-outline'
      } else if (ext.includes('doc')) {
        return 'file-word-outline'
      } else if (ext.includes('zip')) {
        return 'folder-zip-outline'
      } else {
        return 'file-outline'
      }
    },
    friendlyFilename (fileName) {
      const lastIndexOfSlash = fileName.lastIndexOf('/')
      if (lastIndexOfSlash < 0) {
        return fileName
      }
      return fileName.substring(lastIndexOfSlash + 1).replace(/%20/g, ' ')
    },
    removeDoc (type, doc) {
      this.$http.post(`/api/CreditApplication/removeDocument?fullPath=${doc.fullPath}`)
        .then(res => {
          if (res.data.isSuccess) {
            if (type === 'tax') {
              this.taxDocuments.splice(this.taxDocuments.indexOf(doc), 1)
            } else {
              this.otherDocuments.splice(this.otherDocuments.indexOf(doc), 1)
            }
          } else {
            palert({
              title: 'Error',
              text: 'Something went wrong. Could not remove the document.',
              type: 'error'
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    uploadDialog (type) {
      this.modalType = type
      this.showModal = true
    },
    onUpload ({ files, storageGuid, docType }) {
      if (docType === 'tax') {
        this.preferencesInput.storageGuid = storageGuid
        this.preferencesInput.uploadedDocuments = files
        this.taxDocuments = files
      } else {
        this.applicationInput.storageGuid = storageGuid
        this.applicationInput.uploadedDocuments = files
        this.otherDocuments = files
      }
      this.preferencesInput.isUploaded = true
    },
    updateCommunicationType () {
      this.validations.preferences.statementPreferredEmailAddress.$touch()
      this.validations.preferences.statementPreferredFax.$touch()
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0 .25rem;
}
.file-upload {
  border-radius: 100px;
  padding: .5rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
}
.btn-primary {
  background-color: #004168 !important;
}
.file-upload-tag {
  padding: 5px;
}
.doc-title {
  color: rgb(41, 43, 44);
  font-family:"PT Sans", sans-serif;
  font-size:16px;
  font-weight:500;
}
</style>
