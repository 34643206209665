<template>
  <div>
    <div
      :aria-labelledby="`headingOne${order}`"
      data-parent="#accordion">
      <!-- order={{ order }} minimumStepNumberAllowed={{ minimumStepNumberAllowed }} -->
      <div class="component-form card-body">
        <v-row
          no-gutters
          justify="space-between"
          align="center"
          class="mb-5 section-title">
          <v-card
            flat
            tile>
            {{ title }}
            <span>
              <a
                v-if="sectionHeaderToolTip"
                data-toggle="tooltip"
                style="color: #5bc0de;"
                class="fa fa-info-circle"
                role="tooltip"
                :title="sectionHeaderToolTip"></a>
            </span>
          </v-card>
          <span>
            <i class="text-danger">
              *
            </i> Required</span>
        </v-row>

        <!-- TODO: formComponent uses this slot, should probably be a named slot -->
        <slot></slot>
      </div>
    </div>
    <FormActions
      :order="order"
      :is-internal="isInternal"
      :validations="validations"
      :on-submit="onSubmit"
      :on-email="onEmail"
      :on-save="onSave"
      :email-sent="emailSent"
      :is-last-step="isLastStep"
      :sections="sections"
      :title="title"
      :minimum-step-number-allowed="minimumStepNumberAllowed"
      :loading="loading"
      @is-active="setActiveStep"
      @next-step="nextStep"
      @previous-step="previousStep"></FormActions>
  </div>
</template>

<script>
import FormActions from './FormActions.vue'

export default {
  name: 'HorizontalStep',
  components: {
    FormActions
  },
  props: {
    // NOTE: couldn't get vuelidate $each validating on collections, so this is part of an ugly hack to meet reqs
    isCollectionValid: {
      type: Boolean,
      required: false,
      default: null
    },
    onSave: {
      type: Function,
      required: false,
      default: null
    },
    onEmail: {
      type: Function,
      required: false,
      default: null
    },
    onSubmit: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    sections: {
      type: Array,
      required: false,
      default: null
    },
    validations: {
      type: Object,
      required: false,
      default: null
    },
    order: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isLastStep: {
      type: Boolean,
      required: true
    },
    sectionHeaderToolTip: {
      type: String,
      required: false,
      default: ''
    },
    minimumStepNumberAllowed: {
      type: Number,
      required: false,
      default: 1
    },
    isInternal: {
      type: Boolean,
      required: true
    },
    emailSent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isStepInValid () {
      const validations = this.validations
      const multipleSignatories = validations.signatories.$model.signatories.length > 1
      if (this.sections[0] === 'signatories') {
        if (validations.signatories.signatory.$invalid) {
          return true
        } else if (validations.signatories.signatories.$model[1] && (validations.signatories.signatories.$model[0].email === validations.signatories.signatories.$model[1].email)) {
          return true
        } else if (validations.billingInfo.businessType.$model === 'Partnership' && validations.signatoryTwo.$invalid) {
          return true
        } else if (!validations.signatoryTwo.$invalid) {
          return false
        } else {
          return multipleSignatories
        }
      }
      return this.sections.some(function (section) {
        return validations[section].$invalid
      })
    },
    requiresAddressValidation () {
      return (this.title === 'Detail Information' && (this.$route.name === 'application' || this.$route.name === 'adminApplication'))
    }
  },
  watch: {
    isStepInValid: function (val) {
      this.$emit('isSaveValid', !val)
    }
  },
  methods: {
    stepStatusHandler (isActive, order) {
      this.$emit('is-active', {
        isActive,
        order
      })
    },
    nextStep () {
      this.stepStatusHandler()
      this.$emit('next-step')
    },
    previousStep () {
      this.stepStatusHandler(true, this.order - 1)
      this.$emit('previous-step')
    },
    setActiveStep (event) {
      this.$emit('set-active-step')
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  padding: 15px 0 15px 8px;
  border-bottom: 1px solid #999999;
  color: #000000;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  span {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }
}
.component-form  {
  margin: 0 25px;
  background-color: white;
}

.button-row {
  display: flex;
  flex-flow: row-reverse;
  padding-top: 8px;
}
.v-btn {
  color: #fff !important;
  background-color: #004168 !important;
  font-size: 1rem !important;
  letter-spacing: normal !important;
  border-color: #004168;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: .5rem 1rem;
  margin-left: 10px;
  transition: all .2s ease-in-out;
  &.previous  {
    margin-right: 10px;
    color: black !important;
    background-color: #D8D8D8 !important;
  }
  &:hover{
    background-color: #002135 !important;
    border-color: #001b2b;
  }
}
</style>
