<template>
  <div
    id="review-component"
    class="component-container">
    <div
      v-if="loading"
      class="spinner show-spinner"></div>
      <v-row
        v-cloak
        id="credit-review"
        align="center">
        <!-- Search -->
        <v-col cols="auto">
          <div
            id="search-customer">
            <div class="input-group">
              <input
                v-model="searchQuery"
                class="form-control form-control-row"
                type="text"
                placeholder="Search For Customer" />
              <span class="input-group-btn">
                <a class="btn btn-secondary">
                  <i class="fa fa-search"></i>
                </a>
              </span>
            </div>
          </div>
        </v-col>
        <!-- Filter -->
        <v-col cols="auto">
          <v-row
            dense
            no-gutters
            justify="start">
            <v-col
              align-self="center">
              <div class="input-group text-body-2 mr-1">
                Filter By
              </div>
            </v-col>
            <v-col cols="auto">
              <select
                  v-model="filterBy"
                  class="form-control"
                  @change="filterChanged()">
                  <option
                    v-for="(filter, index) in filterOptions"
                    :key="index">
                    {{ filter }}
                  </option>
                </select>
            </v-col>
            <v-col cols="auto">
              <div
                v-if="filterBy != 'All' && filterBy != 'Approved'"
                class="input-group">
                <select
                  v-model="filterByReason"
                  class="form-control"
                  @change="filterChanged()">
                  <option :value="0">
                    All
                  </option>
                  <option
                    v-for="r in filterReasons"
                    :key="`${r.key}-reasons`"
                    :value="r.key">
                    {{ r.value }}
                  </option>
                </select>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <!-- Date Range -->
        <v-col cols="auto">
          <v-menu
            v-model="showDateRangePicker"
            :close-on-click="true"
            :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-row dense>
                <v-text-field
                  v-model="dateRangeText"
                  label="Date Range"
                  background-color="white"
                  append-icon="mdi-calendar"
                  hide-details
                  readonly
                  outlined
                  dense
                  v-on="on">
                </v-text-field>
                <v-btn
                  v-if="dateRangeSelected"
                  icon
                  @click="dates = []">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
            </template>
            <v-date-picker
              v-model="dates"
              range
              no-title>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="auto"
          class="mx-0 px-0">
          <v-btn
            icon
            @click="downloadExcel()">
            <v-icon
              dark
              x-large>
              mdi-download-circle
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <!-- Legend -->
        <v-col
          cols="auto"
          class="mr-5">
          <v-row
            dense
            align-content="center"
            justify="space-around">
            <v-col cols="auto">
              <v-icon color=#005a2c>
                fa-check-circle
              </v-icon>
              <span class="text-body-2">
                Approved
              </span>
            </v-col>
            <v-col cols="auto">
              <v-icon color=#b40000>
                fa-times-circle
              </v-icon>
              <span class="text-body-2">
                Denied
              </span>
            </v-col>
            <v-col cols="auto">
              <v-icon color=#004168>
                fa-minus-circle
              </v-icon>
              <span class="text-body-2">
                Pending
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="review-body">
          <v-client-table
            ref="client-table"
            :name="'reviewTable'"
            :data="filteredApplications"
            :columns="columns"
            :options="options"
            @row-click="rowClicked($event)">
          </v-client-table>
        </v-col>
      </v-row>
  </div>
</template>
<script type="text/javascript">

import axios from 'ca-http-service'
import Vue from 'vue'
import CustomerAccountTagComponent from './components/list/CustomerAccountTag.vue'
import TagComponent from './components/list/approvedTag.vue'
import RapidCreditTagComponent from './components/list/RapidCreditTag.vue'
import TaxExemptTagComponent from './components/list/taxExemptTag.vue'
import DateTag from './components/list/DateTag.vue'
import DateReviewedTag from './components/list/DateReviewedTag.vue'
import DateLastCommentTag from './components/list/DateLastCommentTag.vue'
import TenantsMixin from 'mixins/tenants.mixin'

Vue.component('CustomerAccountTag', CustomerAccountTagComponent)
Vue.component('StatusTag', TagComponent)
Vue.component('TaxExempt', TaxExemptTagComponent)
Vue.component('DateTag', DateTag)
Vue.component('DateReviewedTag', DateReviewedTag)
Vue.component('DateLastCommentTag', DateLastCommentTag)
Vue.component('RapidCreditTag', RapidCreditTagComponent)

export default {
  name: 'CreditReview',
  mixins: [TenantsMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getData()
    })
  },
  data () {
    return {
      loading: true,
      filterOptions: ['All', 'Pending', 'Denied', 'Approved'],
      filterReasons: [],
      filterBy: 'Pending',
      filterByReason: 0,
      searchQuery: '',
      items: [],
      columns: ['customer_Account', 'cust_No', 'creation', 'tax_Exempt', 'state', 'date_Submitted', 'reason_Type', 'D&B_Match', 'last_Comment', 'status'],
      options: {
        sortable: ['customer_Account', 'cust_No', 'creation', 'tax_Exempt', 'state', 'date_Submitted', 'last_Comment'],
        sortIcon: {
          base: 'fa',
          up: 'fa-caret-up',
          down: 'fa-caret-down'
        },
        orderBy: {
          column: 'date_Submitted',
          ascending: false
        },
        filterable: false,
        highlightMatches: false,
        perPage: 250,
        perPageValues: [25, 50, 100, 150, 250],
        templates: {
          customer_Account: 'CustomerAccountTag',
          last_Comment: 'DateLastCommentTag',
          date_Reviewed: 'DateReviewedTag',
          date_Submitted: 'DateTag',
          status: 'StatusTag',
          tax_Exempt: 'TaxExempt',
          'D&B_Match': 'RapidCreditTag'
        },
        saveState: true
      },
      dates: [],
      showDateRangePicker: false
    }
  },
  computed: {
    applications () {
      const self = this
      if (this.filterBy === 'Pending') {
        return this.items.filter((item) => {
          return item.status === 'Pending' && (self.filterByReason === 0 || item.reasonTypeID === self.filterByReason)
        })
      } else if (this.filterBy === 'Denied') {
        return this.items.filter((item) => {
          return item.status === 'Denied' && (self.filterByReason === 0 || item.reasonTypeID === self.filterByReason)
        })
      } else if (this.filterBy === 'Approved') {
        return this.items.filter((item) => {
          return item.approved
        })
      } else {
        return this.items
      }
    },
    filteredApplications () {
      const that = this
      if (that.searchQuery) {
        this.$refs['client-table'].setPage(1)
      }
      const byAccountNumber = parseInt(that.searchQuery)

      const filtered = this.applications.filter((a) => {
        let filterSearch
        if (!isNaN(byAccountNumber) && a.customerAccountId) {
          filterSearch = a.customerAccountId.toString().indexOf(that.searchQuery) !== -1
        } else if (a) {
          filterSearch = a.companyName.toLowerCase().indexOf(that.searchQuery) !== -1 ||
            a.companyName.toUpperCase().indexOf(that.searchQuery) !== -1 ||
            a.companyName.indexOf(that.searchQuery) !== -1
        }
        return filterSearch
      })

      // Apply Date Range Filter
      if (this.dateRangeSelected) {
        const beginDate = new Date(this.dates[0])
        const eDate = new Date(this.dates[1])
        const endDate = new Date(eDate.getFullYear(), eDate.getMonth(), eDate.getDate() + 1, 23, 59, 59)
        return filtered.filter((item) => {
          const dateSubmitted = new Date(item.dateSubmitted)
          return dateSubmitted >= beginDate && dateSubmitted <= endDate
        })
      }
      return filtered
    },
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    dateRangeSelected () {
      return this.dates && this.dates.length === 2
    }
  },
  watch: {
    dates: {
      handler (newVal) {
        if (newVal.length === 0 || newVal.length === 2) {
          this.showDateRangePicker = false
        } else {
          this.showDateRangePicker = true
        }
      }
    }
  },
  mounted () {
    this.$store.dispatch('setAdminHeaderTitle', 'Review Credit Apps')
  },
  created () {
    if (this.$store.state.creditReviewFilters) {
      this.filterBy = this.$store.state.creditReviewFilters.status || 'Pending'
      this.filterByReason = this.$store.state.creditReviewFilters.reason || 0
    }
  },
  methods: {
    getData () {
      this.loading = true
      const self = this

      if (this.$store.getters.filterReasons.length > 0) {
        this.filterReasons = this.$store.getters.filterReasons
      } else {
        axios.get('/generic/getReasonTypesList')
          .then((res) => {
            this.filterReasons = res.data
            this.$store.dispatch('setFilterReasons', res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      }
      axios.get('/api/CreditReview/getListViewModel', { params: { tenantId: this.getBannerIdFromName() } })
        .then((res) => {
          this.items = res.data.map(function (item) {
            item.customer_Account = item.companyName
            item.cust_No = item.customerAccountId
            item.creation = self.creationText(item)
            item.tax_Exempt = !item.taxable
            item.application = item.creditAppId
            item.last_Comment = item.lastCommentDate
            item.date_Submitted = item.dateSubmitted
            item.date_Reviewed = item.dateReviewed
            item.reason_Type = (item.reasonTypeID > 0 && item.approved !== true)
              ? $.grep(self.filterReasons, function (e) { return e.key === item.reasonTypeID })[0].value
              : ''
            item.matches = item.dnbMatchesFound

            return item
          })
          this.loading = false
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    },
    rowClicked (click) {
      this.$router.push({ name: 'creditDetails', params: { tenant: this.$route.params.tenant, id: click.row.creditAppId } })
    },
    creationText (item) {
      if (item.isCreatedFromStorefront) {
        return 'Storefront'
      } else if (item.dateEmailed) {
        return 'Emailed to Customer'
      }
      return item.isInternal ? 'Internal' : 'External'
    },
    async filterChanged () {
      const filters = {
        status: this.filterBy,
        reason: this.filterByReason
      }
      this.$store.dispatch('setCreditReviewFilters', filters)
    },
    downloadExcel () {
      let startDate = null
      let endDate = null
      if (this.dates && this.dates.length === 2) {
        startDate = this.dates[0]
        endDate = this.dates[1]
      }
      const customer = this.searchQuery || null

      axios({
        url: `/api/CreditReview/getExcelDownload?tenantId=${this.getBannerIdFromName()}&customer=${customer}&status=${this.filterBy}&reason=${this.filterByReason}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        const titleCaseBannerName = this.$route.params.tenant.charAt(0).toUpperCase() + this.$route.params.tenant.slice(1)
        link.setAttribute('download', `${titleCaseBannerName} Credit Apps.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_bootstrap-variables.scss";
@import "../../assets/scss/mixins/_flexbox.scss";
body {
  overflow-y: scroll;
}

#review-component {
  #credit-review {
    padding: 0.5em !important;
    .btn-col {
      max-width: 6.25rem;

      text-align: center;
      > a {
        color: #001320;
        &:hover {
          color: darken($blue, 5%);
        }
      }
      .view-app-btn {
        padding: 0 1rem;
        display: block;

        cursor: pointer;
      }
    }
    td:first-child {
      width: 325px;
    }
    td:nth-child(n + 2) {
      text-align: center;
    }
    #review-header {
      .form-group {
        margin-bottom: 1rem;
      }

      #filter-status {
        display: inline-block;
        label {
          color: #999;
          font-size: 0.875rem;
          background-color: transparent;
          border: none;
        }
      }

      .filter-container {
        .form-group {
          display: inline-block;
        }
      }

      #review-legend {
        > div {
          padding: 0 0.5rem;
          font-size: 0.875rem;
          vertical-align: middle;
          white-space: nowrap;
        }
        i {
          padding: 0.25rem;
          font-size: 1rem;
        }
      }
    }

    .legend-approved {
      i:before {
        color: $green;
      }
    }
    .legend-denied {
      i:before {
        color: $red;
      }
    }
    .legend-pending {
      i:before {
        color: $blue;
      }
    }
    .legend-alert {
      i:before {
        color: $orange;
      }
    }

    #review-body {
      position: relative;
      margin-top: 45px;

      .VueTables {
        & > .row:first-of-type {
          height: 54px;
          margin-top: -50px;
        }
        label {
          margin-right: 5px;
        }
      }

      .VuePagination__count {
        position: absolute;
        top: 10px;
      }
    }
  }
}
</style>
