<template>
  <li
    class="step"
    :class="isSubStep ? 'substep' : ''">
    <div
      :id="`headingOne${order}`"
      class="">
      <h5
        v-if="show"
        class="mb-0">
        <FormStepHeader
          :click-handler="stepStatusHandler"
          :mode="mode"
          :hide-circle="hideCircle"
          :is-sub-step="isSubStep"
          :error="vuelidate.$error"
          :pending="vuelidate.$pending"
          :invalid="isInvalid()"
          :dirty="vuelidate.$dirty"
          :active="active"
          :order="order"
          :display-order="displayOrder"
          :title="title"
          :section-header-tool-tip="sectionHeaderToolTip"></FormStepHeader>
      </h5>

      <div
        v-if="$store.getters.showDevFormValidationState === true"
        style="opacity: .7; font-size: .8em; margin-left: 4.2em; margin-top: 1.2em; background-color: rgba(215,215,255); border-radius: .6em;">
        Step validations
        $dirty: <span :style="vuelidate.$dirty === false ? 'color: #b40000' : 'color: green'">
          {{ vuelidate.$dirty }}
        </span> -
        $error: <span :style="vuelidate.$error === false ? 'color: #b40000' : 'color: green'">
          {{ vuelidate.$error }}
        </span> -
        $invalid: <span :style="vuelidate.$invalid === false ? 'color: #b40000' : 'color: green'">
          {{ vuelidate.$invalid }}
        </span>
      </div>
    </div>
    <div
      :id="`collapseOne${order}`"
      class="collapse"
      :aria-labelledby="`headingOne${order}`"
      data-parent="#accordion">
      <div class="component-form card-body">
        <slot></slot>

        <div v-if="showNavButtons()">
          <button
            v-if="isLastStep"
            :disabled="loading"
            class="btn nav-button text-uppercase"
            @click="onSave(null, mode === 'stepped' ? order : undefined)">
            Save
          </button>
          <button
            v-if="isLastStep"
            :disabled="loading"
            class="btn nav-button text-uppercase"
            @click="onSubmit()">
            Save & Submit
          </button>
          <button
            v-if="!isLastStep"
            class="btn nav-button text-uppercase"
            :disabled="isStepInvalid() || loading"
            @click="onSave(stepStatusHandler, order)">
            Next
          </button>
          <button
            v-if="order !== 1"
            class="btn nav-button text-uppercase"
            @click="stepStatusHandler(true, order - 1)">
            Previous
          </button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import FormStepHeader from './FormStepHeader.vue'

export default {
  name: 'FormStep',
  components: {
    FormStepHeader
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: function () {
        return 'stepped'
      },
      validator: function (value) {
        return ['stepped', 'full'].indexOf(value) !== -1
      }
    },
    // NOTE: couldn't get vuelidate $each validating on collections, so this is part of an ugly hack to meet reqs
    isCollectionValid: {
      type: Boolean,
      required: false,
      default: null
    },
    onSave: {
      type: Function,
      required: false,
      default: null
    },
    onSubmit: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    vuelidate: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    order: {
      type: Number,
      required: true
    },
    displayOrder: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isLastStep: {
      type: Boolean,
      required: true
    },
    hideCircle: {
      type: Boolean,
      required: false,
      default: false
    },
    isSubStep: {
      type: Boolean,
      required: false,
      default: false
    },
    sectionHeaderToolTip: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    show: { // NOTE: may have to handle additional cases if show===false used w/ stepped mode
      handler (newVal, oldVal) {
        const collapsingElement = `#collapseOne${this.order}`

        const elem = $(collapsingElement)
        const classList = elem[0].classList
        const isCollapsing = classList.contains('collapsing')

        // this version of bootstrap 4 has an issue where it throws an error if toggling a collapse while collapsing, below delays the call to collapse by longer than the collapsing animation time. collapsing state does not wait on anything, so it should done by the time collapse is called.
        if (isCollapsing) {
          setTimeout(this.collapse, 1200) // collapse once collapsing animation is complete
          return null
        }

        // TODO - Use different technique than JQuery
        if (newVal === false) {
          this.vuelidate.$touch()
          $(collapsingElement).collapse('hide')
        } else {
          $(collapsingElement).collapse('show')
        }
      }
    },
    active: {
      handler: function (newVal, oldVal) {
        if (this.mode === 'stepped') {
          // this.toggleCollapse(!newVal)
          const collapsingElement = `#collapseOne${this.order}`
          // TODO - Use different technique than JQuery
          if (!newVal) {
            this.vuelidate.$touch()
            $(collapsingElement).collapse('hide')
          } else {
            $(collapsingElement).collapse('show')
          }
        }
      }
    },
    mode: {
      handler: function (newVal, oldVal) {
        this.setFormCollapseState()
      }
    }
  },
  mounted () {
    this.setFormCollapseState()
  },
  methods: {
    collapse () {
      // Note: Although `collapsingElement` may be in the process of collapsing, respect the 'show' prop and not hide.
      if (this.show) {
        return
      }
      const collapsingElement = `#collapseOne${this.order}`
      $(collapsingElement).collapse('hide')
    },
    isInvalid () {
      if (this.isCollectionValid != null) {
        return !this.isCollectionValid
      } else {
        return this.vuelidate.$invalid
      }
    },
    showNavButtons () {
      return this.mode === 'stepped' && this.onSave != null
    },
    setFormCollapseState () {
      const collapsingElement = `#collapseOne${this.order}`

      if (this.mode === 'full') {
        $(collapsingElement).collapse('show')
      } else if (this.mode === 'stepped') {
        if (this.active) {
          $(collapsingElement).collapse('show')
        }
      }
    },
    // TODO: need to be able to attempt saving a step if not invalid, should this check isCollectionValid? not sure yet...
    isStepInvalid () {
      if (this.isCollectionValid != null) {
        return !this.isCollectionValid
      } else {
        return !!this.vuelidate.$invalid
      }
    },
    stepStatusHandler (isActive, order) {
      if (this.mode === 'stepped') {
        this.$emit('is-active', {
          isActive,
          order
        })
      }
    }
  }
}
</script>

<style scoped>
.component-form  {
  margin-left: 3.13rem;
  background-color: white;
}

/* Could DRY up the nav-button by making separate component, reused in FormStepper */
.nav-button {
  background-color: #099c98;
  font-size: .64rem;
  border: 0;
  border-radius: .125rem;
  margin: 1.2em 0 2.6em 0;
  padding: .5rem 1.6rem;
  color: white;
  border: 0;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
}

li.step, li.substep {
  position: relative;
}

li:not(:last-of-type).step::after {
  position: absolute;
  top: 2.88em;
  left: 1.9em;
  width: 1px;
  height: calc(100% - 2.9em);
  content: "";
  background-color: rgba(0,0,0,.1);
}

li.substep::after {
  top: 0em !important;
  height: calc(100%) !important;
}
</style>
