<template>
  <div
    class="d-flex flex-column"
    style="height: 100%; margin-top: 56px;">
    <div class="sticky-top">
      <div
        class="navbar"
        style="
          height: 56px;
          flex-direction: row;
          background-color: #032D2C;
        ">
        <button
          class="nav-frame-menu-button navbar-toggler"
          type="button"
          @click="openNav = !openNav">
          <i
            class="fa fa-bars"
            style="color:white;"></i>
        </button>

        <img
          class="img-fluid"
          style="
              width: 148px;
              max-height: 48px;
            "
          src="../../assets/images/app-credit-central-stacked.svg" />
        <v-spacer></v-spacer>
        <v-btn @click="openFeedbackModal()" text dark class="mr-1">
          <v-icon small>
            mdi-message-alert
          </v-icon>
          Feedback
        </v-btn>
        <div>
          <div class="dropdown">
            <button
              data-toggle="dropdown"
              style="
                  margin-top: 5px;
                  border: 0 !important;
                  outline: 0 !important;
                "
              class="navbar-toggler nav-item"
              type="button"
              aria-label="Toggle navigation">
              <i
                class="fa fa-ellipsis-v"
                style="color: white;"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right active"
              aria-labelledby="user-actions">
              <router-link
                v-if="identity.permissions.viewUsers"
                class="dropdown-item"
                :to="{
                  name: 'usersList'
                }"
                exact>
                <span>Manage Users</span>
              </router-link>
              <router-link
                v-if="identity.permissions.editEmailTemplate"
                class="dropdown-item"
                :to="{
                  name: 'editEmailTemplate',
                  params: {
                    tenant: company.name.toLowerCase()
                  }
                }"
                exact>
                <span>Email Template</span>
              </router-link>
              <router-link
                class="dropdown-item"
                :to="{
                  name: 'whatsNew'
                }"
                exact>
                <span>What's New</span>
              </router-link>
              <router-link
                v-if="identity.permissions.viewUsers"
                class="dropdown-item"
                :to="{
                  name: 'instantCreditPermissions'
                }"
                exact>
                <span>Instant Credit Permissions</span>
              </router-link>
              <!-- <a
                class="dropdown-item"
                @click="openFeedbackModal()">Feedback</a> -->
              <a
                class="dropdown-item"
                @click="logoutUser()">Sign Out</a>
              <v-switch
                v-if="isDevelopmentEnvironment"
                dense
                inset
                prepend-icon="mdi-dev-to"
                :v-model="!showDevControls"
                class="pl-5"
                @change="$store.dispatch('toggleDevControls')"></v-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="navAndContentwrapper"
      style="height: 100%"
      class="d-flex flex-row">
      <nav-layout
        :open-nav="openNav"
        :top-position="sidebarTopPosition"
        :tenant-name="currentCompanyBanner"
        @toggleSidebar="openNav = openNav ? !openNav : openNav"></nav-layout>
      <div
        id="page-content"
        class="content-container">
        <div
          id="currentComponent"
          class="container-fluid content">
          <div class="mb-4">
            <h3 class="content-header pl-0 ma-3 ">
              {{ adminHeaderTitle }}
            </h3>
          </div>
          <router-view></router-view>
        </div>
        <feedback :dialog.sync="feedbackDialog"></feedback>
        <v-spacer></v-spacer>
      </div>
    </div>
  </div>
</template>

<script>
import { manager } from '@/auth'
import Feedback from '@/components/Feedback.vue'
import NavLayout from './NavLayout.vue'
import TenantsMixin from '../../mixins/tenants.mixin'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AdminLayout',
  components: {
    Feedback,
    NavLayout
  },
  mixins: [TenantsMixin],
  data () {
    return {
      feedbackDialog: false,
      openNav: this.$vuetify.breakpoint.mdAndUp
    }
  },
  computed: {
    ...mapGetters([
      'identity',
      'company',
      'banners',
      'isDevelopmentEnvironment',
      'showDevControls'
    ]),
    ...mapState([
      'appConfig'
    ]),
    isDemo () {
      return this.appConfig.environment === 'Demo'
    },
    adminHeaderTitle () {
      return this.$store.state.adminHeaderTitle
    },
    currentCompanyBanner () {
      return this.company.name.toLowerCase() || this.$route.params.tenant || ''
    },
    sidebarTopPosition () {
      return this.$vuetify.breakpoint.lgAndUp ? 56 : 0
    }
  },
  methods: {
    logoutUser () {
      manager.signoutRedirect()
    },
    openFeedbackModal () {
      this.feedbackDialog = true
    },
    goToAdminDashBoard () {
      this.$router.push({ name: 'dashboard', params: { tenant: this.currentCompanyBanner } })
    },
    switchTenants (banner) {
      this.$store.dispatch('setCurrentBanner', banner)
      this.$router.push({ tenant: banner })
      this.goToAdminDashBoard()
    }
  }
}
</script>
<style lang="scss" scoped>

.dropdown-item {
  user-select: none;
}

.dropdown-item:active,
.dropdown-item:active:hover {
  color: white;
}

.sticky-top {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  left: 0;
}
.spacer {
  padding-bottom: 94px;
}
/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  margin-top: auto;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 80px;
  }
}

.header-container {
  position: fixed;
  width: 100%;
  z-index: 7;
  padding-top: 20px;
  background-color: #ededed;
}

.banner-dropdown {
  display: inline-block;
}

#bannerSelector {
  background-color: white;
  width: 100%;
}

.content-header {
  font-weight: 600;
  color: black;
  padding-left: 24px;
  width: 100%;
  margin-bottom: -4px;
}
@media (max-width: 1100px) {
  #navAndContentwrapper {
    transform: 0;
    border-right: 16px solid #032D2C;
    border-left: 16px solid #032D2C;
  }
  .header-container {
    width: calc(100% - 32px);
  }
}
@media (max-width: 767px) {
  #navAndContentwrapper {
    border: none;
  }
  .header-container {
    width: 100%;
  }
  .content-container {
    border: none;
  }
}
.content-container {
  background-color: #ededed;
  flex: 1;
  min-height: calc(100vh - 94px);
}
.content {

}

.nav-frame-menu-button {
  margin-top: 8px;
  border: 0 !important;
  outline: 0 !important;
}

@media only screen and (max-width: 960px) {
  .nav-frame-menu-button {
    margin-right: auto;
  }
}

.w-sidebar {
  width: 200px;
  max-width: 200px;
}

.row.collapse {
  margin-left: -200px;
  left: 0;
  transition: margin-left 0.15s linear;
}

.row.collapse.show {
  margin-left: 0 !important;
}

.row.collapsing {
  margin-left: -200px;
  left: -0.05%;
  transition: all 0.15s linear;
}

.fa-arrow-left:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  // Addresses excess padding in mobile
  .container.content {
  padding: 4px !important;
}
  .row.collapse,
  .row.collapsing {
    margin-left: 0 !important;
    left: 0 !important;
    overflow: visible;
  }

  .row > .sidebar.collapse {
    display: flex !important;
    margin-left: -100% !important;
    transition: all 0.25s linear;
    position: fixed;
    z-index: 1050;
    max-width: 0;
    min-width: 0;
    flex-basis: auto;
  }

  .row > .sidebar.collapse.show {
    margin-left: 0 !important;
    width: 100%;
    max-width: 100%;
    min-width: initial;
  }
  .row > .sidebar.collapsing {
    display: flex !important;
    margin-left: -10% !important;
    transition: all 0.2s linear !important;
    position: fixed;
    z-index: 1050;
    min-width: initial;
  }
}

</style>
