import axios from 'ca-http-service'

export const getSalesPersonsAsync = async (searchText, bannerName) => {
  if (searchText.trim().length <= 0) { return [] }
  const setTenant = bannerName === 'rexelenergy' ? 'rexel' : bannerName
  return await axios
    .get(
      `/api/Salespersonsearch/salespersonsearch/${setTenant}/${searchText}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.err(err)
    })
}

// This gets a specific SalesPerson from elastic based on email address
export const getSalesPersonByEmailAsync = async (searchText) => {
  if (searchText.trim().length <= 0) { return [] }
  return await axios
    .get(
      `/api/Salespersonsearch/salespersonsearchemail/${searchText}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.err(err)
    })
}

export const mapSalesPersonsResultsToComboboxOptions = function (items) {
  return items
    .map((item) => ({
      // combobox uses value and text properties
      value: item.eclipseUserLogin,
      text: `${item.eclipseUserLogin} - ${item.displayName}`,
      ...item // spread the rest of the properties into options object to send to BE
    }))
}

export const displaySelectedSalesPersonInCombobox = function (salesperson) {
  salesperson.value = salesperson.eclipseUserLogin
  salesperson.text = `${salesperson.eclipseUserLogin} - ${salesperson.displayName}`
  return salesperson
}

export const shouldShowSalesPersonInputs = function (tenantId) {
  const validTenantIds = [2, 3, 4]
  return validTenantIds.indexOf(tenantId) > -1
}
