import {
  saveInProgressForm,
  getInProgressForm,
  getFormAttachments,
  downloadFormFile
} from '@/api/forms/form-data-storage'

import {
  mapState,
  mapGetters, mapActions
} from 'vuex'

import axios from 'ca-http-service'

export default {
  data () {
    return {
      isDownloadingInProgressFormDataFiles: false,
      inProgressFormDataFilesMappings: [],
      inProgressFormDataFiles: [], // Used to Load Files for an In Progress Form
      SUBMITTING_FORM: 'submitting',
      FORM_IS_COMPLETED: 'complete',
      INSTANT_CREDIT_FORM_KEY: 'instant-credit',
      isSubmittedSuccessfully: false,
      isFormComplete: false,
      hasApplicantSigned: false,
      hasBranchManagerSigned: false,
      submittedOnDate: null,
      submittedBy: null,
      applicationID: null,
      attachmentId: null,
      formTitle: 'Credit Application',
      mode: 'full',
      loading: false,
      validationFailures: [],
      successMessage: '',
      errorMessage: '',
      displayNavWarning: false,
      nextRoute: null
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$isFormDirty && from.name !== 'adminInstantCreditInProgress') {
      this.nextRoute = next
      this.displayNavWarning = true
    } else {
      next()
    }
  },
  watch: {
    inProgressFormDataFiles: {
      handler (newVal) {
        if (this.isDownloadingInProgressFormDataFiles === false) {
          // Should trigger once all In Progress Form Data Files are downloaded
          const formFileMappings = this.inProgressFormDataFilesMappings
          const files = []
          newVal.forEach((fileData) => {
            const mapping = formFileMappings.find(m => m.formFileType === fileData.formFileType)
            if (mapping != null) {
              const blob = fileData.file.data
              if (fileData.formFileType === 'documentDoc') {
                files.push(new File([blob], fileData.fileName, new Date()))
                this.formData[mapping.formSection][mapping.formSectionField] = files
              } else {
                this.formData[mapping.formSection][mapping.formSectionField] = new File([blob], fileData.fileName, new Date())
              }
            }
          })
        }
      }
    },
    overwriteFormWithTestData: {
      handler (newVal) {
        if (newVal) {
          this._overwriteFormWithTestData()
        } else {
          this._resetForm()
        }
      }
    },
    isSubmittedSuccessfully: {
      handler (newVal) {
        this.setIsFormCompleted(!!newVal)
      }
    }
  },
  computed: {
    ...mapState([
      'identity',
      'company',
      'banners'
    ]),
    ...mapGetters({
      overwriteFormWithTestData: 'overwriteFormWithTestData',
      isDevelopmentEnvironment: 'isDevelopmentEnvironment',
      isProductionEnvironment: 'isProductionEnvironment'
    }),
    $isFormDirty () {
      return this.$v ? this.$v.$anyDirty : false
    }
  },
  async mounted () {
    this._redirectIfFormIsInDevelopment()
    this._checkCurrentBannerAgainstRoute()
    this._redirectIfUserDoesNotHavePermission()

    this.$store.dispatch('setAdminHeaderTitle', this.formTitle)

    if (this.$isFormInProgress()) {
      this.loading = true
      await this._loadInProgressForm()
      this.loading = false
    }

    // using formDefinitions, assigns formData to reference objects in formSteps for reactivity
    // this is how data from formSteps is accessible through formData

    if (this.formDefinitions !== undefined) {
      this.formSteps = this._mapFormDefinitionsToFormSteps(this.formDefinitions)
    }
  },
  methods: {
    ...mapActions('applicationForm', ['setIsFormCompleted']),
    $setFormFileMappings (mappings) {
      mappings.forEach(mapping => {
        this.inProgressFormDataFilesMappings.push(mapping)
      })
    },

    $isFormInProgress () {
      // an InProgressForm will have a route w/ an id param value
      return this.$route.params.id != null
    },

    _checkCurrentBannerAgainstRoute () {
      const routeBanner = this.$route.params.tenant
      if (routeBanner != null) {
        // getCurrentCompanyName
        const currentCompany = this.company
        const currentCompanyName = currentCompany != null && currentCompany.name != null ? currentCompany.name.toLowerCase() : null
        if (currentCompanyName != null) {
          const routeBannerName = routeBanner.toLowerCase()
          if (currentCompanyName !== routeBannerName) {
            this.$store.dispatch('setCurrentBanner', routeBannerName)
          }
        }
      }
    },

    // not all forms will be on production, some will be in development and available only in development and staging environment
    _redirectIfFormIsInDevelopment () {
      const isFormInDevelopment = this.$route.meta.inDevelopment === true
      if (isFormInDevelopment && this.isProductionEnvironment) {
        const tenant = this.$route.params.tenant
        this.$router.push({ name: 'dashboard', params: { tenant } })
      }
    },

    _redirectIfUserDoesNotHavePermission () {
      const permissionsRequired = this.$route.meta.permissions

      const userPermissions = this.identity != null ? this.identity.permissions : null

      if (permissionsRequired != null && userPermissions != null) {
        // go thru users permission list and compare against permissions
        const permissionKeys = Object.keys(userPermissions)

        let hasAccess

        permissionsRequired.forEach(req => {
          permissionKeys.forEach(key => {
            if (key === req && userPermissions[key] === true) {
              hasAccess = true
            }
          })
        })

        if (!hasAccess) {
          console.error('User does not have the permissions to access this form.')
          const tenant = this.$route.params.tenant
          this.$router.push({ name: 'dashboard', params: { tenant } })
        }
      }
    },

    $userIsEmployee () {
      return this.$route.meta.auth === true
    },

    $isFormInternal () {
      return this.$route.meta.isInternalForm === true
    },

    _isStepForUserType (step) {
      if (step.access == null) {
        return true
      } else if (step.access.includes('internal')) {
        return this.$userIsEmployee()
      }
      return true
    },

    $isStepForTenant (step) { // returns true if not specified, or if array includes the specified tenant
      const tenant = this.$route.params.tenant
      return step.tenants == null || step.tenants.includes(tenant)
    },

    // filters out steps that the user or tenant should not have access to.0
    // called by all public facing methods on this mixin that has formDefinitions as an arg
    _filterFormDefinitions (formDefinitions) {
      const steps = Object.keys(formDefinitions)

      const filteredFormDefinitions = {}

      for (const step of steps) {
        const isStepForUserType = this._isStepForUserType(formDefinitions[step])
        const isStepForTenant = this.$isStepForTenant(formDefinitions[step])

        if (isStepForUserType && isStepForTenant) {
          filteredFormDefinitions[step] = formDefinitions[step]
        }
      }
      return filteredFormDefinitions
    },

    _mapFormDefinitionsToFormSteps (formDefinitionsToFilter) {
      const formDefinitions = this._filterFormDefinitions(formDefinitionsToFilter)

      const steps = Object.keys(formDefinitions)

      const formSteps = []

      for (const step of steps) {
        const {
          show,
          title,
          component,
          dataClass,
          labels,
          order,
          displayOrder,
          collectionInfo,
          tenants,
          access,
          hideCircle,
          isSubStep,
          sectionHeaderToolTip
        } = formDefinitions[step]

        // avoid spreading step objects into formStep objects, just pass what you need
        formSteps.push({
          data: this.formData[step],
          vuelidate: this.$v.formData[step],
          stepKey: step,
          show: show == null ? true : show,
          title,
          component,
          dataClass,
          labels,
          order,
          displayOrder,
          collectionInfo,
          tenants,
          access,
          hideCircle,
          isSubStep,
          sectionHeaderToolTip,
          formState: {
            // assign data values here to gain reactive access in step component
            isComplete: this.isFormComplete,
            hasApplicantSigned: this.hasApplicantSigned,
            hasBranchManagerSigned: this.hasBranchManagerSigned
          }
        })
      }

      return formSteps
    },

    /// ///////////////////////////
    // Helper Form Methods
    /// ///////////////////////////

    $createGuid () {
      function S4 () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase()
    },

    /// ///////////////////////////
    // Initialization
    /// ///////////////////////////

    $initFormData (formDefinitionsToFilter, loadWithTestData = false) {
      const formDefinitions = this._filterFormDefinitions(formDefinitionsToFilter)
      const steps = Object.keys(formDefinitions)

      const formData = {}

      for (const step of steps) {
        const { collectionInfo } = formDefinitions[step]

        let data = {}

        if (loadWithTestData === true && formDefinitions[step].testData && this.isDevelopmentEnvironment) {
          // eslint-disable-next-line new-cap
          data = new formDefinitions[step].dataClass()
          const dataToInsert = {
            ...formDefinitions[step].testData,
            isStepDataReady: false
          }

          Object.keys(dataToInsert).forEach(key => { data[key] = dataToInsert[key] })
        } else {
          data = {
            // eslint-disable-next-line new-cap
            ...new formDefinitions[step].dataClass(),
            // isStepDataReady is used while form is in 'stepped' mode to determine what steps to validate on Save/Submit
            isStepDataReady: false
          }
        }

        if (collectionInfo != null) {
          const collectionItemName = collectionInfo.instance

          formData[step] = {
            [step]: loadWithTestData === true && formDefinitions[step].testDataCollection && this.isDevelopmentEnvironment
              ? [...formDefinitions[step].testDataCollection]
              : [],
            [collectionItemName]: data
          }
        } else {
          formData[step] = data
        }
      }
      return formData
    },

    _overwriteFormWithTestData () {
      const results = this.$initFormData(this.formDefinitions, true)

      // this.formData should already be referenced by formSteps, $setFormData is used to update formData w/ results
      this.$setFormData(this.formDefinitions, results)
    },

    // dev-environment-only feature - if this is called, we can keep it simple and just reload the page
    _resetForm () {
      location.reload()
    },

    async _loadInProgressForm () {
      const results = await this._getInProgressFormData()
      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''

      const {
        hasAttachedFiles,
        applicationID,
        incompleteFormData,
        attachmentId,
        bannerName,
        appType,
        formName,
        completed,
        updatedByName,
        updatedDate
      } = results

      if (hasAttachedFiles === true) {
        const formAttachmentsRes = await getFormAttachments({
          formType: appType,
          attachmentId,
          bannerCode: bannerName,
          currentUser,
          formName
        })

        if (formAttachmentsRes?.data != null) {
          // Get File Blobs if there are any attachments
          this.isDownloadingInProgressFormDataFiles = true
          for (const formAttachment of formAttachmentsRes.data) {
            const targetPath = `${formAttachment.bannerCode}/${formAttachment.formType}/${formAttachment.attachmentId}/${formAttachment.formFileType}/${formAttachment.fileName}`
            let dlResult = null
            try {
              dlResult = await downloadFormFile(targetPath)
            } catch (err) {
              console.error('Something went wrong. Unable to download.', { err })
            }

            if (dlResult != null) {
              this.inProgressFormDataFiles.push({
                ...formAttachment,
                file: dlResult
              })
            }
          }
          this.isDownloadingInProgressFormDataFiles = false
        }
      }

      this.submittedOnDate = updatedDate

      if (appType === 'instant-credit' && results.instantCreditFormData !== null) {
        const applicant = results.instantCreditFormData.signatories.find(x => x.role === 'Applicant')
        if (applicant.hasSigned) {
          this.hasApplicantSigned = true
          this.submittedOnDate = null
        }
        const branchManager = results.instantCreditFormData.signatories.find(x => x.role === 'Branch Manager')
        if (branchManager.hasSigned) {
          this.hasBranchManagerSigned = true
          this.submittedOnDate = branchManager.signDate
        }
      }

      this.submittedBy = updatedByName
      this.applicationID = applicationID
      this.attachmentId = attachmentId
      this.isFormComplete = completed

      // this should be the in progress form data to load
      const parsedInProgressData = JSON.parse(incompleteFormData)

      // NOTE: this.formData should be initialized here
      //  reassigning a value to this.formData will break reactivity w/ formSteps
      //  to update this.formData use $setFormData()
      this.formData = parsedInProgressData
    },

    /// ///////////////////////////
    // Set Form Data
    /// ///////////////////////////

    // DEPRECATED - see comments near usage for more info
    _setFormDataStepCollection (formDefinitions, formData, step, collection) {
      const collectionKeys = Object.keys(formData[step])

      for (const collectionKey of collectionKeys) {
        if (collectionKey !== 'isStepDataReady') {
          // need to instantiate class before setting keys
          if (this.formData[step][collectionKey] == null) {
            this.formData[step][collection]
              // eslint-disable-next-line new-cap
              .push(new formDefinitions[collection].dataClass())
          }

          // set data
          const formStepKeys = Object.keys(formData[step][collectionKey])
          for (const formStepKey of formStepKeys) {
            this.formData[step][collection][collectionKey][formStepKey] = formData[step][collectionKey][formStepKey]
          }
        }
      }
      // w/ data set, evaluate validations
      this.$v.formData[collection][collection].$touch()
    },

    // Used to set formData w/o breaking reactivity
    $setFormData (formDefinitionsToFilter, formData) {
      const formDefinitions = this._filterFormDefinitions(formDefinitionsToFilter)
      const steps = Object.keys(formData)
      for (const step of steps) {
        if (formData[step] != null) {
          if (step !== 'bannerId') {
            this.formData[step].isStepDataReady = true
          }

          const { collectionInfo } = formDefinitions[step]

          if (collectionInfo != null) {
            // TODO: collectionInfo is only being used by validations in the Credit App Form.
            //  once validation for that form has been updated w/ the approach used in newer forms
            //  we can get rid of _setFormDataStepCollection
            this._setFormDataStepCollection(formDefinitions, formData, step, step)
          } else { // set formData w/ available formData values
            const formStepKeys = Object.keys(formData[step])
            for (const formStepKey of formStepKeys) { // handle setting data here
              this.formData[step][formStepKey] = formData[step][formStepKey]
            }
          }
        }
      }
    },

    /// ///////////////////////////
    // Get Form Data to Submit
    /// ///////////////////////////

    _getCompletedStep (step) {
      const isFormInSteppedMode = this.mode === 'stepped'

      if (isFormInSteppedMode) {
        return this.formData[step].isStepDataReady
          ? this.formData[step]
          : null
      } else { // in 'full' mode return all steps in the whole form for validation
        return this.formData[step]
      }
    },

    $getFormData (formDefinitionsToFilter) {
      const formDefinitions = this._filterFormDefinitions(formDefinitionsToFilter)
      const steps = Object.keys(formDefinitions)

      const formData = {}

      for (const step of steps) {
        formData[step] = this._getCompletedStep(step)
      }

      return formData
    },

    /// ///////////////////////////
    // FormData Helper methods to Submit data w/ files in one call
    /// ///////////////////////////

    $setDocumentValue (documentData) {
      if (documentData instanceof File) return documentData
      if (documentData instanceof Array) return documentData[0]
      return null
    },

    $populateFormDataObject (formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
          this.$populateFormDataObject(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    },

    /// ///////////////////////////
    // Handle Form Errors
    /// ///////////////////////////

    $handleFormErrors (formErrorResponseData) {
      const {
        data, // should be a list of strings containing error messages
        message
      } = formErrorResponseData

      // The FormResponseBlock component (displayed at the bottom of the form)
      // uses successMessage, errorMessage and validationFailures to display messages to the user

      // Append message to display on Form
      console.error(message)
      this.successMessage = ''
      this.errorMessage = message

      // use validationFailures to display on
      // a BE Validation Summary Block component
      if (data != null) {
        this.validationFailures = [...data]
      }

      // done
      this.loading = false
    },

    /// ///////////////////////////
    // FORM EVENTs
    /// ///////////////////////////

    async _handleFormSaveResponse (state = null) {
      const saveResponse = await this.save(state)

      if (saveResponse != null) {
        // Prompts nav confirmation modal to not show if form has data, but has been saved
        this.$v.formData.$reset()
        this.applicationID = saveResponse.applicationID
        return saveResponse
      } else {
        return null
      }
    },

    // component using this mixin must pass $onSave to FormStepper on-save
    async $onSave () {
      if (this.save != null) {
        await this._handleFormSaveResponse()
      }
    },

    // component using this mixin must pass $onSubmit to FormStepper on-submit
    async $onSubmit () {
      let applicationKey = null
      if (this.submit != null) {
        if (this.save != null) {
          const saveResponse = await this._handleFormSaveResponse(this.SUBMITTING_FORM)

          if (saveResponse == null) {
            console.error('Something went wrong while trying to Save the Form')
            return null
          }
          applicationKey = saveResponse.applicationKey
        }

        const assignedAccountNumber = await this.submit(applicationKey)

        if (assignedAccountNumber != null) {
          this.isSubmittedSuccessfully = true
          // save on submit complete for internal forms only
          if (this.$isFormInternal() === true) {
            // save form as complete once successfully submitted
            const saveFormAsCompleteResponse = await this._handleFormSaveResponse(this.FORM_IS_COMPLETED)

            if (saveFormAsCompleteResponse == null) {
              // User is already sent to a complete page on successful submit, on final save only notify the user if something went wrong
              console.error('Something went wrong with Saving the Form as Submitted')
            }
          }
        } else {
          // For Cash this can happen becuase CashAccountForm.Submit() lookForCustomerMatches returns null initally
          console.error('Something went wrong with Submitting the Form')
          // return null
        }
      } else {
        console.error('*Form component must implement async submit() method that should return true on success, null of error')
      }
    },

    /// ///////////////////////////
    // Form Actions
    /// ///////////////////////////

    // These values will be used for both save and submit requests
    $buildFormObject (appType, additionalFormData = {}) {
      return {
        appType,
        bannerId: this.company.tenantId,
        ...this.$getFormData(this.formDefinitions),
        ...additionalFormData
      }
    },

    // build object used by all forms to store in progress form data on BE
    $buildSaveInProgressDataRequest (formObject, isComplete = null) {
      // this object should InProgressFormDataStorageRequest
      return {
        tenantId: this.company.tenantId, // TODO: tenantId should be bannerId on the BE
        markCompleted: isComplete === true ? 1 : null,
        applicationID: this.applicationID,
        incompleteFormData: JSON.stringify(formObject) // BE will map this to the Submit structure for this AppType
      }
    },

    async $saveFormData (obj) {
      const sftoken = this.$route.query.sftoken || null
      const isInternal = this.$route.meta.isInternalForm
      return await saveInProgressForm(obj, isInternal, sftoken)
    },

    // successful response should return a single result
    async _getInProgressFormData () {
      const applicationID = this.$route.params.id
      this.applicationID = applicationID

      return await getInProgressForm(applicationID)
        .then((resp) => {
          return resp.data.data
        })
        .catch((error) => {
          console.error('error', error)
          return error
        })
    },

    async $getFormDataApplicationForSigning (tenant, applicationKey, role) {
      const path = `/api/InstantCredit/${tenant}/${applicationKey}/getApplicationFor/${role}`

      return await axios.get(path).then((res) => {
        return this.$signDocument(res.data.data, tenant, applicationKey)
      }).catch((err) => {
        console.error(err)
        return false
      })
    },

    // signDocument currently called by instant credit application
    async $signDocument (data, tenant, applicationKey) {
      if (data != null) {
        const signature = data.signature
        return await axios.get('/api/HelloSign/getFormDataSignatureUrl/' + tenant + '/' + applicationKey + '/', {
          params: {
            signature
          }
        })
          .then((res) => {
            const self = this
            if (res.data.isSuccess) {
              const url = res.data.data.url
              const key = res.data.data.key
              // https://app.hellosign.com/api/embeddedSigningWalkthroughV1
              HelloSign.init(key)

              const obj = {
                url,
                uxVersion: 2,
                allowCancel: true,
                skipDomainVerification: this.isDevelopmentEnvironment,
                // redirectUrl: 'https://localhost:5100/hellosign/eventhandler',
                debug: false,
                async messageListener (eventData) {
                  if (eventData.event === HelloSign.EVENT_SIGNED) {
                    return await self.$eSignComplete(
                      tenant,
                      applicationKey,
                      data
                    )
                  } else if (eventData.event === HelloSign.EVENT_ERROR) {
                    HelloSign.close()
                    palert({
                      title: 'Your session has timed out, please try again.',
                      type: 'error'
                    })
                    return false
                  }
                }
              }

              HelloSign.open(obj)
            } else {
              palert({
                title: res.data.message,
                type: 'error'
              })
              return false
            }
          })
          .catch((err) => {
            console.error(err)
            return false
          })
      }
    },
    async $eSignComplete (tenant, applicationKey, signatory) {
      return await axios.post(`/api/HelloSign/FormDataSignComplete/${tenant}/${applicationKey}`, signatory)
        .then((res) => {
          if (res.data.isSuccess) {
            // call completeApplication...
            if (signatory.role === 'Branch Manager') {
              this.$onSubmit()
            }

            palert({
              title: 'Application Completed',
              text: res.data.message,
              type: 'success',
              allowOutsideClick: false
            }).then(() => {
              if (signatory.role === 'Applicant') {
                if (res.data.data) {
                  window.location.href = res.data.data
                } else {
                  // this currently does not close signature window open by applicant link from email.
                  // "Scripts may close only the windows that were opened by them."
                  window.close()
                }
              }

              // NOTE: On dev, not sure if redirectUrl this is working or the behavior we want in new design.
              // Here is an example value returned: "https://test.platt.com/Auth.aspx?data=%2BxcesR2SiGRjbkSDvuhZrg%3D%3D%2Clogin%2C%2CPlatt9791"
              // window.location.href = res.data.data // This should bew a redirectUrl value
            })
            return true
          } else {
            return false
          }
        })
        .catch((err) => {
          console.error(err)
          return false
        })
    },

    /// ///////////////////////////
    // NAVIGATION HANDLERS
    /// ///////////////////////////
    saveAndNavigate () {
      // Credit app has its own save method
      if (this.$route.name === 'adminApplication') {
        this.onSave()
      } else {
        this.$onSave()
      }
      this.nextRoute()
    },
    discardAndNavigate () {
      this.nextRoute()
    }
  }
}
