<template>
    <fieldset
        :class="$vuetify.breakpoint.mdAndUp ? 'pa-10' : 'pa-5'">
        <div>
            <p class="font-weight-bold">
                Thank you for your application for credit!
            </p>
        </div>
        <div>
            <p>We will email you with updates throughout the approval process.</p>
        </div>
        <FormSectionCard
        :title="'How can we improve the credit application process?'">
        <v-row>
            <v-col
            class="pb-0">
            <v-textarea
                v-model="feedbackText"
                outlined
                :hide-label="true"
                :rows="4"
                input-label="">
            </v-textarea>
            </v-col>
        </v-row>
        </FormSectionCard>
        <v-row
            dense
            class="ma-0 px-4 py-1"
            justify="end">
            <v-col
                cols="auto">
                <v-btn
                    text
                    class="font-weight-bold zprevious"
                    :small="$vuetify.breakpoint.smAndDown"
                    :href="getRedirectUrl">
                    Skip and go to {{ getLinkName }}
                </v-btn>
            </v-col>
            <v-col
                cols="auto">
                <v-btn
                    tile
                    :small="$vuetify.breakpoint.smAndDown"
                    color="primary"
                    :disabled="!feedbackText"
                    @click="submitFeedBack">
                    <span>
                        Submit Feedback
                    </span>
                </v-btn>
            </v-col>
        </v-row>
    </fieldset>
</template>

<script>
import FormSectionCard from '@/components/form/FormSectionCard.vue'

import { mapGetters } from 'vuex'
import axios from 'ca-http-service'

export default {
  name: 'CreditApplicationFeedbackForm',
  components: {
    FormSectionCard
  },
  props: {
    feedbackFormData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      feedbackText: ''
    }
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    getRedirectUrl () {
      if (this.feedbackFormData.redirectUrl.length > 0) {
        return this.feedbackFormData.redirectUrl
      } else if (this.company != null) {
        return `${this.company.url}`
      } else {
        return ''
      }
    },
    getLinkName () {
      return this.company != null
        ? `${this.company.displayName}`
        : 'Ok'
    }
  },
  methods: {
    async submitFeedBack () {
      if (!this.feedbackText) {
        return
      }
      const redirectUrl = this.getRedirectUrl
      const email = this.feedbackFormData.email
      const fullName = this.feedbackFormData.fullName
      const feedbackRequestFormData = {
        subject: `Credit App Feedback (ApplicationId: ${this.feedbackFormData.applicationId})`,
        url: document.location.href,
        body: this.feedbackText,
        customFields: [],
        uploads: [],
        fullName,
        email,
        uploadFileNames: []
      }
      await axios
        .post('/api/feedback/submit', feedbackRequestFormData)
        .then((res) => {
          palert({
            title: 'Thank you for your feedback!',
            type: 'success'
          }).then(() => {
            window.location.href = redirectUrl
          })
        })
        .catch((err) => {
          console.error(err)
          palert({
            title: 'Error submitting feedback. Please try again in a few minutes.',
            type: 'error'
          })
        })
        .finally(() => {
          this.feedbackText = ''
        })
    }
  }
}
</script>
