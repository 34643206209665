<template>
  <div
    style="display: grid;"
    :class="{'has-danger': $isErrorState()}">
    <span>
      <label
        v-if="!hideLabel && !inline"
        class="form-control-label"
        :class="$isRequired() && !disabled && !hideRequired ? 'required' : ''"
        style="font-size: 12px;">
        {{ inputLabel }}
      </label>
    </span>
    <v-combobox
      v-model="select"
      :outlined="!filled"
      :filled="filled"
      dense
      :loading="loading"
      :disabled="disabled"
      autocomplete="autocomplete_off_hack"
      :items="items"
      :search-input.sync="search"
      :class="{'has-danger': $isErrorState()}"
      hide-no-data
      :hide-selected="hideSelected"
      hide-details
      :label="inline ? inputLabel : null"
      @input="vuelidate != null ? vuelidate.$touch() : null"
      @blur="vuelidate != null ? vuelidate.$touch() : null">
      <template #label>
        <template v-if="inline">
          <div :class="$isRequired() && !disabled && !hideRequired ? 'required' : ''">
            {{ inputLabel }}
          </div>
        </template>
      </template>
    </v-combobox>
    <FormFieldErrorMessages
      v-if="!hideRequired"
      :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from './FormInput.mixin'
import _ from 'lodash'

/**
 * * populating items list: This component can handle both static and dynamic options
 *
 *    * For static list, pass array to the `options` param
 *
 *    * For a dynamic list, pass args to asyncSearchHandler
 *
 *  * Emits 'input' on selection - implement handler in parent component if needed
 */

export default {
  name: 'FormInputCombobox',
  components: {
    FormFieldErrorMessages
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    // NOTE should use mustBeObject (or mustBeObjectOrEmpty if not required) custom validation to fields using this component
    value: {
      type: [String, Object],
      required: false,
      default: null
    },
    // used to handle static lists
    options: {
      /**
       * the expected object = {
       *  value, // the key
       *  text // visible label
       * }
       */
      type: Array,
      required: false,
      default: null
    },
    // used to handle dynamically populated lists
    asyncSearchHandler: {
      // must be an async method that returns a promise
      type: Function,
      required: false,
      default: null
    },
    // only called if asyncSearchHandler is implemented
    // use to map search result objects to  structure expected by this.options
    itemsMapper: {
      type: Function,
      required: false,
      // just pass items along by default
      default: function (items) {
        return items
      }
    },
    inputLabel: {
      type: String,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    hideRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSelected: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      search: null,
      select: null,
      items: [],
      searchHandler: null
    }
  },
  watch: {
    options: {
      handler (newVal) {
        if (newVal != null) {
          // populate items with static options list
          this.items = [...newVal]

          // update field w/ value on mount
          const itemEntry = this.items.find((i) => {
            return i.value === this.select
          })
          if (itemEntry != null) {
            this.select = itemEntry
          }
        }
      }

    },
    value: {
      handler (newVal) {
        if (this.select == null) {
          this.select = newVal
        }
      }
    },
    search: {
      handler (newVal, oldVal) {
        const isSearchAndSelectMatch = newVal &&
          newVal !== this.select

        if (isSearchAndSelectMatch &&
          this.areOptionsDynamicallyLoaded()) {
          this.querySelections(newVal)
        }
      }
    },
    select (value) {
      // prevents null from returning an causing errors
      const emitValue = value === null ? '' : value
      this.$emit('input', emitValue)
    }
  },
  mounted () {
    this.handleLoadingSelectedDataForDynamicItemsOnMount()
    /* Funnel all searches through a single debounce instance */
    this.searchHandler = _.debounce(async function (v) {
      this.loading = true
      await this.asyncSearchHandler(v)
        .then((res) => {
          this.items = this.itemsMapper(res)
          return res
        })
        .catch((err) => {
          return err
        })
        .finally(() => {
          this.loading = false
        })
    }, 500)
  },
  methods: {
    // Handles Loading data into field w/ dynamic items list, by using the saved object to populate items
    handleLoadingSelectedDataForDynamicItemsOnMount () {
      if (this.options == null && this.value != null && this.value.value != null) {
        this.items.push(this.value)
        this.select = this.value
      }
    },
    areOptionsDynamicallyLoaded () {
      // checking if this is null should suffice
      return this.asyncSearchHandler != null
    },
    querySelections (v) {
      if (typeof this.searchHandler === 'function') {
        this.searchHandler(v)
      }
    }
  }
}
</script>

<style lang="scss">
div.required {
  &:after {
    content: "*";
    color: #c00;
    padding-left: 2px;
  }
}
</style>
