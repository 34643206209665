<template>
  <fieldset>
    <div
      v-if="isPlatt()"
      class="form-control-label mb-4"
      style="font-size:12px;color:#4285f4;">
      Please attach any other pertinent documents related to this job account request (ST101's, tract or plot map, etc.).
    </div>
    <FormFileUploaderV2
      v-model="userFormInput.selectedDocuments"
      :disabled="isStepDisabled"
      multiple
      small-chips
      return-file-data></FormFileUploaderV2>
    <div class="row">
      <div class="col-md-12">
        <FormInputTextarea
          v-model="userFormInput.notes"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.notes"
          :input-label="formLabels.notes"></FormInputTextarea>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormInputTextarea from '@/components/form/FormInputTextarea.vue'
import FormFileUploaderV2 from '@/components/form/FormFileUploaderV2.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import TenantsMixin from 'mixins/tenants.mixin'

export default {
  name: 'JobAccountFormStepDocuments',
  components: {
    FormInputTextarea,
    FormFileUploaderV2
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      isLoading: false
    }
  }
}
</script>
