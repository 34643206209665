<template>
  <v-footer
    v-if="footer"
    dark
    padless
    class="d-print-none previous-grid-gutters common-footer"
    height="auto"
    color="grey darken-3"
    style="max-width: 100vw; overflow: hidden; white-space: nowrap!important;">
    <v-container
      v-if="this.$route.name !== 'pageUnavailable'"
      class="footer-container">
      <v-row class="footer-link-groups">
        <v-col
          :cols="12"
          :lg="5"
          class="px-3">
          <v-row no-gutters>
            <template v-for="(linkGroup, index) in footer.linkGroups">
              <v-col
                :key="index"
                :cols="12"
                :lg="6">
                <v-list
                  dense
                  class="py-0"
                  color="transparent">
                  <v-list-group
                    :value="lgAndUp"
                    :append-icon="lgAndUp ? '' : undefined"
                    eager
                    color="white">
                    <template #activator>
                      <v-list-item-content class="text-uppercase">
                        <v-list-item-title class="text-subtitle-1">
                          {{ linkGroup.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <template v-for="(link, index2) in linkGroup.nodes">
                      <v-list-item
                        :key="`linkGroup-${index2}`"
                        :href="link.url"
                        class="extra-dense-list">
                        <v-list-item-content>
                          <v-list-item-subtitle class="font-weight-regular">
                            {{ link.title }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-group>
                </v-list>
              </v-col>
            </template>
          </v-row>
        </v-col>

        <v-divider
          vertical
          inset
          class="d-none d-lg-inline-flex"></v-divider>

        <v-col
          :cols="12"
          :lg="3"
          class="px-3">
          <v-list
            dense
            class="py-0"
            color="transparent">
            <v-list-item>
              <v-list-item-content class="text-uppercase">
                <v-list-item-title class="text-subtitle-1">
                  A smarter way to do business
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row
            no-gutters
            justify="space-between"
            class="px-6">
            <v-col :cols="'auto'">
              <div
                v-for="(maLink) in footer.mobileAppLinks"
                :key="maLink.title">
                <a
                  v-ripple
                  :href="maLink.url"
                  rel="noopener"
                  target="_blank"
                  class="d-block">
                  <img
                    :src="require(`@/assets/images/footer/${maLink.title}-store.svg`)"
                    width="130px"
                    height="44.81px" />
                </a>
              </div>
            </v-col>
            <v-col :cols="'auto'">
              <img :src="require(`@/assets/images/footer/phones-${selectedCompany.toUpperCase()}.svg`)" />
            </v-col>
          </v-row>
        </v-col>

        <v-divider
          vertical
          inset
          class="d-none d-lg-inline-flex"></v-divider>

        <v-col
          :cols="12"
          :lg="4"
          class="px-3">
          <v-list
            dense
            color="transparent"
            class="py-0">
            <v-list-item>
              <v-list-item-content class="text-uppercase">
                <v-list-item-title class="text-subtitle-1">
                  {{ footer.helpLinks.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="(link, index4) in footer.helpLinks.nodes"
              :key="`help-${index4}`"
              :href="link.url ? link.url : undefined"
              @click="routeHelpClick(link)">
              <v-list-item-action>
                <v-icon v-if="link.icon">
                  {{ link.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ link.title }}
                  <span
                    v-if="link.subtitle"
                    class="caption ml-3">
                    {{ link.subtitle }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="link.description">
                  {{ link.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet
      color="grey darken-4"
      style="width: 100%;">
      <v-row
        align="center"
        justify="center">
        <template v-for="(part, pIndex) in footer.bottomFooterLinks">
          <div
            :key="pIndex"
            class="pa-2 d-flex">
            <!-- eslint-disable -->
            <a class="grey--text text-caption pa-2" target="_blank" :href="part.url" style="text-decoration: none;" v-html="part.title"></a>
            <!-- eslint-enable -->
          </div>
        </template>
      </v-row>
    </v-sheet>

    <feedback :dialog.sync="feedbackDialog"></feedback>
    <div
      v-if="isDev"
      class="text--blue-grey"
      style="position: fixed; bottom: 0; left: 0; padding: 6px;">
      {{ $vuetify.breakpoint.name }}
    </div>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
import Feedback from '@/components/Feedback.vue'
import axios from 'ca-http-service'

export default {
  name: 'CommonFooter',
  components: {
    Feedback
  },
  data () {
    return {
      isMounted: false,
      feedbackDialog: false,
      footer: {
        linkGroups: [
          {
            title: 'About Us',
            nodes: [
              { url: 'https://www.rexelusa.com/locations', title: 'Locations' },
              { url: 'https://www.rexelusa.com/corporate', title: 'Who We Are' },
              { url: 'https://jobs.rexel.com/Rexelusa/?locale=en_US', title: 'Careers' },
              { url: 'https://www.rexelusa.com/contact-us', title: 'Contact Us' },
              { url: 'https://www.rexelusa.com/training', title: 'Education & Training' },
              { url: 'https://www.rexelusa.com/brands', title: 'Brands' }
            ]
          },
          {
            title: 'Quick Links',
            nodes: [
              { url: 'https://credit.rexelapps.com/rexel', title: 'Open Line of Credit' },
              { url: 'https://www.rexelusa.com/product-recalls', title: 'Product Recalls' },
              { url: 'https://www.rexelusa.com/resources', title: 'Resources' }
            ]
          }
        ],
        mobileAppLinks: [
          { title: 'apple', url: 'https://apps.apple.com/us/app/rexelusa-new/id1537022597' },
          { title: 'android', url: 'https://play.google.com/store/apps/details?id=com.rexel.storefront.releaserexel' }
        ],
        helpLinks: {
          title: 'NEED HELP?',
          nodes: [
            { icon: 'mdi-phone', title: '(888) RexelPRO', subtitle: '(1-888-739-3577)', description: '7am - 6pm (EST), Monday-Friday', url: 'tel:1-888-739-3577' },
            // { icon: 'mdi-comment-question', title: 'Live Chat' },
            { icon: 'mdi-thumb-up-outline', title: 'Give Feedback' }
          ]
        },
        bottomFooterLinks: [
          { url: 'https://www.rexel.com', title: '© 2022 Rexel' },
          { url: 'https://rexel-cdn.com/assets/storefront/tc-use.pdf', title: 'Terms of Use' },
          { url: 'https://rexel-cdn.com/assets/storefront/privacy-policy.pdf', title: 'Privacy' },
          { url: 'https://www.rexel.com/en/countries-and-banners', title: 'International Sites' }
        ]
      },
      bold360Ids: {
        platt: '687158214408058615',
        rexel: '687158214918081320',
        gexpro: '687158215626375421'
      }

    }
  },
  computed: {
    ...mapState([
      'availableBanners',
      'isDev'
    ]),
    lgAndUp () {
      return this.isMounted ? this.$vuetify.breakpoint.lgAndUp : false
    },
    selectedCompany () {
      switch (this.$route.params.tenant) {
        case 'platt':
          return 'platt'
        case 'rexel':
        case 'rexelenergy':
          return 'rexel'
        case 'gexpro':
          return 'gexpro'
        default:
          return null
      }
    }
  },
  mounted () {
    this.isMounted = true
    this.bold360Setup()
  },
  methods: {
    /**
     * @method formatPhone format vanity phone number
     */
    formatPhone (number) {
      /** Remove all non-digits */
      const s2 = ('' + number).replace(/\D/g, '')
      /** array of matches */
      const m = s2.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
      /** return formatted phone number */
      return !m ? number : m[1] + '-' + m[2] + '-' + m[3] + '-' + m[4]
    },
    routeHelpClick (link) {
      if (link.title === 'Give Feedback') {
        this.feedbackDialog = true
      } // else if (link.title === 'Live Chat') {
      // this.openChatWindow()
      // }
    },
    bold360Setup () {
      window._bcvma = window._bcvma || []
      window._bcvma.push(['setAccountID', '691386927312998525'])
      window._bcvma.push(['setParameter', 'WebsiteID', '687146146601295777'])
      window._bcvma.push(['setParameter', 'VisitName', ''])
      window._bcvma.push(['setParameter', 'VisitPhone', ''])
      window._bcvma.push(['setParameter', 'VisitEmail', ''])
      window._bcvma.push(['setParameter', 'VisitRef', ''])
      window._bcvma.push(['setParameter', 'VisitInfo', ''])
      window._bcvma.push(['setParameter', 'CustomUrl', ''])
      window._bcvma.push(['pageViewed'])
      const boldId = this.bold360Ids[this.selectedCompany]
      window._bcvma.push(['addFloat', { type: 'chat', id: boldId }])

      if (this.$route.query.sftoken != null) {
        const data = this.$route.query.sftoken
        axios.get('/api/creditapplication/storefront-token', {
          params: {
            sftoken: data
          }
        }).then((res) => {
          if (res.data && res.data.email && res.data.firstName && res.data.lastName) {
            const windowParams = `customField_CustomerEmail=${res.data.email}&customField_CustomerName=${res.data.firstName} ${res.data.lastName}`
            window._bcvma.push(['setParameter', 'WindowParameters', windowParams])
          }
        })
      }

      const bold360Script = document.createElement('script')
      bold360Script.setAttribute('src', 'https://vmss.boldchat.com/aid/691386927312998525/bc.vms4/vms.js')
      document.head.appendChild(bold360Script)
    }/*,
    openChatWindow () {
      if (document) {
        const cButton = document.getElementsByClassName('bcFloat')
        const activeChat = document.getElementsByClassName('bc-minimize-state-container')
        if (cButton.length) {
          cButton[0].getElementsByTagName('a')[0].click()
        }
        if (activeChat.length) {
          activeChat[0].click()
        }
      }
    } */
  }
}
</script>

<style lang="scss">

.extra-dense-list {
  min-height: 24px !important;
  height: 24px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px; /* Vertically center the text there */
}

.common-footer,
.common-footer .v-list .text-subtitle-1 {
  font-family: Rubik, sans-serif !important;
}

@media (min-width: 600px) {
  .footer-container {
    max-width: 1440px !important;
  }
}

@media(min-width: 1008px) {
  .footer-link-groups {
    flex-wrap: nowrap !important;
  }
}
</style>
