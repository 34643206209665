<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="upload-modal"
    class="tax-cert-modal"
    @close="closeModal()">
    <template
      slot="header">
      Upload Tax Document
    </template>

    <!-- BODY -->
    <div
      slot="body"
      class="container-fluid">
      <!-- SEARCH FOR ACCOUNT NUMBER -->
      <div class="row">
        <div
          v-if="!currentAccount"
          class="col-md-12">
          <div
            class="form-group"
            :class="{'has-danger': $v.accountId.$dirty && $v.accountId.$error }">
            <label class="form-control-label required">
              Account #:
            </label>
            <div class="row justify-content-between align-items-center">
              <div class="col-8">
                <input
                  ref="accountId"
                  v-model="accountId"
                  type="text"
                  class="form-control"
                  @keyup.enter="findAccount()" />
              </div>
              <div class="col-4">
                <button
                  type="button"
                  class="btn btn-rounded btn-primary pull-right"
                  :disabled="searchingForAccount"
                  @click="findAccount()">
                  <span v-if="searchingForAccount">
                    <img
                      src="../../../assets/images/puff.svg"
                      class="loading-img-btn" />
                  </span>
                  Find Account
                </button>
              </div>
              <div class="col-12">
                <span
                  v-if="invalidAccount"
                  class="field-validation-error mt-1">
                  Unable to find account #. Please enter a valid account
                </span>
                <span
                  v-if="$v.accountId.$dirty && !$v.accountId.minValue && !invalidAccount"
                  class="field-validation-error">
                  Account # must be a number greater than 0
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentAccount">
        <div class="row">
          <div class="col-sm-4">
            <div class="tax-cert-info">
              <label>Account #</label>
              <span>{{ taxCert.accountNumber }}</span>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="tax-cert-info">
              <label>Company</label>
              <span>{{ taxCert.accountName }}</span>
            </div>
          </div>
        </div>

        <!-- SUB ACCOUNTS IF ENTERPRISE ACCOUNT -->
        <div
          v-if="parsedSubAccounts.length > 0"
          class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">
                Select Subsidiary Account:
              </label>
              <div class="input-group">
                <checkbox-multi-select
                  :options="parsedSubAccounts"
                  :selected="taxCert.subAccounts"
                  :placeholder="'- Select Subsidiaries -'"
                  :selected-container-class="'col-sm-12'"></checkbox-multi-select>
              </div>
            </div>
          </div>
        </div>

        <!-- DATE SELECTION -->
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{'has-danger': $v.taxCert.effectiveDate.$error}">
              <label class="form-control-label required">
                Effective Date:
              </label>

              <div class="input-group">
                <input
                  ref="effectiveDate"
                  v-model="taxCert.effectiveDate"
                  type="date"
                  class="form-control form-control-row"
                  :class="{'form-control-danger': $v.taxCert.effectiveDate.$error }"
                  prefix="effectiveDate" />
                <span
                  class="input-group-addon"
                  @click="$refs.effectiveDate.focus()">
                  <i
                    class="fa fa-calendar-o"
                    aria-hidden="true"></i>
                </span>
              </div>

              <span
                v-if="!$v.taxCert.effectiveDate.isDate"
                class="field-validation-error">
                Please enter a valid date (YYYY-MM-DD)
              </span>
              <span
                v-if="$v.taxCert.effectiveDate.isDate && !$v.taxCert.effectiveDate.maxValue"
                class="field-validation-error">
                Effective date must be less than the expiration date
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              :class="{'has-danger': $v.taxCert.expirationDate.$error}">
              <label class="form-control-label">
                Expiration Date:
              </label>

              <div class="input-group">
                <input
                  ref="expirationDate"
                  v-model="taxCert.expirationDate"
                  type="date"
                  class="form-control form-control-row"
                  :class="{'form-control-danger': $v.taxCert.expirationDate.$error }"
                  prefix="expirationDate" />
                <span
                  class="input-group-addon"
                  @click="$refs.expirationDate.focus()">
                  <i
                    class="fa fa-calendar-o"
                    aria-hidden="true"></i>
                </span>
              </div>
              <span
                v-if="!$v.taxCert.expirationDate.isDate"
                class="field-validation-error">
                Please enter a valid date (YYYY-MM-DD)
              </span>
              <span
                v-if="$v.taxCert.expirationDate.isDate && !$v.taxCert.expirationDate.minValue"
                class="field-validation-error">
                Expiration date must be greater than today's date
              </span>
            </div>
          </div>
        </div>

        <!-- MULTIPLE STATES SELECTION -->
        <div class="row">
          <div class="col-md-12">
            <div
              class="form-group"
              :class="{'has-danger': $v.taxCert.$dirty && $v.taxCert.states.$error}">
              <label class="form-control-label required">
                Certificate State(s):
              </label>
              <div class="input-group">
                <checkbox-multi-select
                  :options="parsedStates"
                  :selected="taxCert.states"
                  :placeholder="'- Select Applicable States -'"
                  :error="$v.taxCert.$dirty && $v.taxCert.states.$error"></checkbox-multi-select>
              </div>
            </div>
          </div>
        </div>

        <!-- UPLOAD FILE -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label required">
                Select File
              </label>
              <div
                class="input-group"
                :class="{ 'has-danger': invalidFile || $v.taxCert.taxCertificate.$error}"
                @click="$refs.fileInputBtn.click()">
                <input
                  v-model="fileInputTxt"
                  type="text"
                  class="form-control"
                  :class="{'form-control-danger': invalidFile || $v.taxCert.taxCertificate.$error }"
                  readonly />
                <span class="input-group-btn">
                  <span
                    class="btn btn-secondary"
                    :class="{'error': invalidFile || $v.taxCert.taxCertificate.$error }">
                    <i class="fa fa-folder-open-o"></i> Browse&hellip;
                    <input
                      ref="fileInputBtn"
                      type="file"
                      class="hidden"
                      accept="'.pdf, .doc, .docx, .html, .jpeg, .jpg, .png, .odt, .wpd, .img, .bmp'"
                      @change="handleFileInput($event)" />
                  </span>
                </span>
              </div>
            </div>
            <p
              v-if="invalidFile"
              class="error-message">
              {{ errorMessage }}
            </p>
          </div>
        </div>

        <!-- STATUS CODE -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="input-group">
                <select
                  v-model="taxCert.statusCode"
                  class="form-control">
                  <option
                    v-for="s in statusCodes"
                    :key="s.key"
                    :value="s.key">
                    {{ s.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF BODY -->

    <!-- FOOTER -->
    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="closeModal()">
        Cancel
      </a>
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        :disabled="loading || !currentAccount"
        @click="saveDocument()">
        <span v-if="loading">
          <img
            src="../../../assets/images/puff.svg"
            class="loading-img-btn" />
        </span>
        Upload Tax Document
      </button>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '../../../components/GenericModal.vue'
import { required, minLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import CheckboxMultiSelect from '../../../components/CheckboxMultiSelect.vue'
import { TaxCert } from '../TaxCert.model'
import TaxCertModalMixin from './TaxCertModalMixin'

export default {
  name: 'UploadModal',
  components: {
    GenericModal,
    CheckboxMultiSelect
  },
  mixins: [TaxCertModalMixin],
  props: {
    states: {
      required: true,
      type: Array
    },
    getSubAccounts: {
      required: true,
      type: Function
    },
    statusCodes: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      modalState: false,
      loading: false,
      errorMessage: 'No error',
      invalidFile: false,
      fileInputTxt: '',
      accountId: '',
      invalidAccount: false,
      currentAccount: '',
      subAccounts: [],
      searchingForAccount: false,
      taxCert: new TaxCert()
    }
  },
  watch: {
    modalState (val) {
      if (val) {
        this.$nextTick(function () {
          this.$refs.accountId.focus()
        })
      }
    }
  },
  methods: {
    findAccount () {
      this.invalidAccount = false
      this.$v.accountId.$touch()
      if (!this.$v.accountId.$invalid) {
        this.searchingForAccount = true
        this.getSubAccounts(this.accountId)
          .then((response) => {
            this.searchingForAccount = false
            this.invalidAccount = !response.data.isSuccess
            if (this.invalidAccount) { // invalid account
              this.currentAccount = null
              this.taxCert.accountName = ''
            } else if (response.data.data) { // enterprise account
              const ent = response.data.data
              this.currentAccount = ent.enterpriseNumber
              this.subAccounts = ent.subAccounts
              this.taxCert.isEnterprise = true
              this.taxCert.accountName = ent.name
              this.taxCert.accountNumber = Number(ent.enterpriseNumber)

              if (this.accountId !== this.currentAccount) {
                this.taxCert.subAccounts.push(Number(this.accountId))
              }
            } else { // cash account
              this.currentAccount = Number(this.accountId)
              this.taxCert.accountName = response.data.message
              this.taxCert.accountNumber = Number(this.currentAccount)
            }
          })
          .catch((response) => {
            this.searchingForAccount = false
            this.invalidAccount = true
          })
      }
    },
    handleFileInput (event) {
      const files = event.target.files || event.dataTransfer.files
      const fileName = files[0].name
      const allowedFiletypes = ['pdf', 'doc', 'docx', 'html', 'jpeg', 'jpg', 'png', 'odt', 'wpd', 'img', 'bmp']
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase() || null
      if (allowedFiletypes.indexOf(fileExtension) !== -1 && fileExtension) {
        this.resetFile()
        this.taxCert.taxCertificate = files[0]
        this.fileInputTxt = fileName
      } else {
        this.errorMessage = fileExtension + ' files are not allowed.'
        this.resetFile()
        this.invalidFile = true
      }
    },
    resetFile () {
      this.fileInputTxt = ''
      this.invalidFile = false
      this.taxCert.taxCertificate = null
    },
    saveDocument () {
      this.$v.taxCert.$touch()
      if (!this.$v.taxCert.$invalid) {
        this.$emit('createTaxCertReview', this.taxCert)
      }
    },
    closeModal () {
      this.modalState = false
      this.loading = false
      this.resetForms()
    },
    resetForms () {
      this.fileInputTxt = ''
      this.invalidFile = false
      this.invalidAccount = false
      this.currentAccount = ''
      this.accountId = ''
      this.$v.accountId.$reset()
      this.subAccounts = []
      this.taxCert = new TaxCert()
      this.$v.taxCert.$reset()
    }
  },
  validations () {
    return {
      accountId: {
        required,
        minValue (value) {
          return value > 0
        }
      },
      taxCert: {
        states: {
          required,
          minLength: minLength(1)
        },
        effectiveDate: {
          required,
          isDate (value) {
            return !value || moment(value).isValid()
          },
          maxValue (value) {
            return !value || !this.taxCert.expirationDate || value < this.taxCert.expirationDate
          }
        },
        expirationDate: {
          isDate (value) {
            return !value || moment(value).isValid()
          },
          minValue (value) {
            return !value || !this.taxCert.expirationDate ||
              new Date(this.taxCert.expirationDate) >= new Date()
          }
        },
        taxCertificate: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import './tax-cert-modal.scss';

</style>
