import axios from 'ca-http-service'

// Will handle secured requests(with auth) or public requests(must provide sftoken)
export const getCustomerAccountInfo = async (accountId, tenant, sftoken = null) => {
  tenant = tenant === 'gexpro' ? 'rexel' : tenant
  const url = sftoken ? '/api/AccountNumber/get-external' : `/api/AccountNumber/get/${tenant}`
  const queryParameters = sftoken
    ? {
        sftoken
      }
    : {
        accountId
      }
  return await axios
    .get(url, {
      params: queryParameters
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.error(err)
      return err
    })
}
