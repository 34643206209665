export default {
  name: 'FormStepImplementationMixin',
  props: {
    vuelidate: {
      type: Object,
      required: true
    },
    formClass: {
      type: Function,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isStepDisabled: false,
      // eslint-disable-next-line new-cap
      userFormInput: new this.formClass()
    }
  },
  // mixins are always applied first, implementing component can always override behavior
  mounted () {
    this.userFormInput = this.formData

    this.isStepDisabled = (this.formState.isComplete || this.formState.hasApplicantSigned || this.formState.hasBranchManagerSigned)
  }
}
