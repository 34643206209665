import Vue from 'vue'
import App from './app.vue'
import store from '@/store'
import { router } from './router'
import './filters'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import VuelidateErrorExtractor from 'vuelidate-error-extractor'
import { ClientTable } from 'vue-tables-2'
import axios from 'ca-http-service'
import 'babel-polyfill'
import 'es6-promise/auto'
import vuetify from './vuetify'
import VueAnalytics from 'vue-analytics'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import PortalVue from 'portal-vue'
import { messages } from './utils/errorMessages'

Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VuelidateErrorExtractor, {
  messages,
  attributes: {
    name: 'Name',
    email: 'Email',
    text: 'Text'
  }
})

Vue.use(ClientTable, {}, false, 'bootstrap4')
Vue.use(PortalVue)

require('assets/scss/main.scss') // eslint-disable-line import/extensions
require('assets/scss/palert.scss') // eslint-disable-line import/extensions

require('../node_modules/bootstrap/js/dist/util.js') // eslint-disable-line import/extensions
require('../node_modules/bootstrap/js/dist/alert.js') // eslint-disable-line import/extensions
require('../node_modules/bootstrap/js/dist/dropdown.js') // eslint-disable-line import/extensions
require('../node_modules/bootstrap/js/dist/tab.js') // eslint-disable-line import/extensions
require('../node_modules/bootstrap/js/dist/tooltip.js') // eslint-disable-line import/extensions
require('../node_modules/bootstrap/js/dist/collapse.js') // eslint-disable-line import/extensions

Vue.use(VueAnalytics, {
  id: 'UA-10814830-15',
  router,
  debug: {
    enabled: false // this will add logs to the console for testing.
  },
  autoTracking: {
    pageviewTemplate (route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href
      }
    }
  }
})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

// replaces null values in an object with N/A
Vue.prototype.$nullToDefault = (obj) => {
  let temp = JSON.stringify(obj)
  temp = temp.replace(/null/g, '"N/A"')
  return (JSON.parse(temp))
}

Vue.prototype.$http = axios
Vue.config.productionTip = false

// App startup

async function initData () {
  return await Promise.all([
    axios.get('/generic/config'),
    axios.get('/generic/getCountryList'),
    axios.get('/home/getTenants'),
    axios.get('/generic/getPlattforceUrl')
  ])
}

initData()
  .then((responseData) => {
    const [
      configRes,
      countriesRes,
      bannersRes,
      plattForceUrlRes
    ] = responseData

    store.dispatch('setAppConfig', configRes.data)
    store.dispatch('setCountries', countriesRes.data)
    store.dispatch('setBanners', bannersRes.data)
    store.dispatch('setPlattForceUrl', plattForceUrlRes.data)

    const env = configRes.data.environment
    if (env === 'Development' || env === 'Staging') {
      console.info(`The Credit Application is running in the ${env} Environment`)
    }

    /* eslint-disable no-new */
    new Vue({
      vuetify,
      el: '#app',
      router,
      store,
      components: { App },
      data () {
        return {
          transitionName: 'slide-fade-left'
        }
      },
      watch: {
        '$route' (to, from) {
          const toDepth = to.path.split('/').length
          const fromDepth = from.path.split('/').length
          this.transitionName = toDepth < fromDepth ? 'slide-fade-right' : 'slide-fade-left'
        }
      }, // NOTE: the store does not currently persist on route reload. in other apps we use a sync between router and store to synchronize. We may be able to get away w/ just managing cookies, as this may only matter in the customers section.
      template: '<App :transition-name="transitionName" />'
    })
  })
