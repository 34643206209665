<template>
  <div>
    <!-- Section: Accounts Payable -->
    <h6 class="card-header">
      Accounts Payable
    </h6>
    <div class="card">
      <div class="card-block cols-four">
        <div class="form-group">
          <label>Contact Name:</label>
          <p>{{ company.accountsPayableFirstName }} {{ company.accountsPayableLastName }}</p>
        </div>
        <div class="form-group">
          <label>Contact Phone Number:</label>
          <p>
            {{ company.accountsPayablePhone }}
            <span v-if="company.accountsPayablePhoneExt">
              Ext. {{ company.accountsPayablePhoneExt }}
            </span>
          </p>
        </div>
        <div class="form-group">
          <label>Contact Email Address:</label>
          <p>{{ company.accountsPayableEmail }}</p>
        </div>
        <div class="form-group">
          <label>Contact Fax Number:</label>
          <p>{{ company.accountsPayableFax }}</p>
        </div>
      </div>
    </div>
    <!-- card -->

    <!-- Section: Purchasing Contact -->
    <h6 class="card-header">
      Purchasing Contact
    </h6>
    <div class="card">
      <div class="card-block cols-four">
        <div class="form-group">
          <label>First Name:</label>
          <p>{{ company.purchasingFirstName }}</p>
        </div>
        <div class="form-group">
          <label>Last Name:</label>
          <p>{{ company.purchasingLastName }}</p>
        </div>
        <div class="form-group">
          <label>Contact Email Address:</label>
          <p>{{ company.purchasingEmail }}</p>
        </div>
        <div class="form-group">
          <label>Contact Phone Number:</label>
          <p>
            {{ company.purchasingPhone }}
            <span v-if="company.purchasingPhoneExt">
              Ext. {{ company.purchasingPhoneExt }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- card -->

    <!-- Section: Preferences -->
    <h6 class="card-header">
      Preferences
    </h6>
    <div class="card">
      <!-- Sub-Section: Statements -->
      <div class="card-block cols-one sub-headings">
        <div class="form-group">
          <h6 class="sub-heading">
            Statements
          </h6>
        </div>
      </div>
      <div class="card-block cols-three sub-headings">
        <div class="form-group">
          <label>Preferred Statement Method:</label>
        </div>
        <div class="form-group">
          <label>Statement Destination:</label>
        </div>
      </div>
      <div class="card-block cols-one-third-two-third">
        <!-- statement method -->
        <div class="form-group">
          <div class="card-block cols-three">
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="preferredStatementType"
                  :value="1"
                  :checked="company.preferredStatementType === 1"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Email
                </span>
              </label>
            </div>
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="preferredStatementType"
                  :value="2"
                  :checked="company.preferredStatementType === 2"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Fax
                </span>
              </label>
            </div>
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="preferredStatementType"
                  :value="3"
                  :checked="company.preferredStatementType === 3"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Mail
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-group">
            {{ company.preferredStatementTypeValue }}
          </div>
        </div>
      </div>

      <!-- Sub-Section: Invoices -->
      <div class="card-block cols-one sub-headings">
        <div class="form-group">
          <h6 class="sub-heading">
            Invoices
          </h6>
        </div>
      </div>
      <div class="card-block cols-three sub-headings">
        <div class="form-group">
          <label>Preferred Invoice Method:</label>
        </div>
        <div class="form-group">
          <label>Invoice Destination:</label>
        </div>
      </div>
      <div class="card-block cols-one-third-two-third">
        <!-- statement method -->
        <div class="form-group">
          <div class="card-block cols-three">
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="preferredInvoiceType"
                  :value="1"
                  :checked="company.invoiceStatementType === 1"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Email
                </span>
              </label>
            </div>
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="preferredInvoiceType"
                  :value="2"
                  :checked="company.invoiceStatementType === 2"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Fax
                </span>
              </label>
            </div>
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="preferredInvoiceType"
                  :value="3"
                  :checked="company.invoiceStatementType === 3"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Mail
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-group">
            {{ company.invoiceStatementTypeValue }}
          </div>
        </div>
      </div>

      <div class="card-block sub-headings">
        <div class="form-group">
          <label>Purchase Order Required:</label>
        </div>
      </div>
      <div class="card-block cols-one-third-two-third">
        <div class="form-group">
          <div class="card-block cols-three">
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="purchaseOrder"
                  :checked="company.purchaseOrder"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Yes
                </span>
              </label>
            </div>
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="purchaseOrder"
                  :checked="!company.purchaseOrder"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  No
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="card-block sub-headings">
        <div class="form-group">
          <label>Taxable:</label>
        </div>
      </div>
      <div class="card-block cols-one-third-two-third">
        <div class="form-group">
          <div class="card-block cols-three">
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="taxable"
                  :checked="company.taxable"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Yes
                </span>
              </label>
            </div>
            <div class="form-group">
              <label class="custom-control custom-radio">
                <input
                  type="radio"
                  name="taxable"
                  :checked="!company.taxable"
                  class="custom-control-input" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  No
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- card -->

    <!-- Section: Tax Documents -->
    <template v-if="!company.taxable">
      <h6 class="card-header">
        Tax Documents
      </h6>
      <div class="card">
        <template v-for="(document, index) in company.taxDocuments">
          <div
            :key="index"
            class="col-md-12 row documents">
            <div class="card-block col-md-8">
              <div class="form-group">
                <p class="document">
                  {{ filterFilename(document.name) }}
                </p>
              </div>
            </div>
            <div class="card-block col-md-4">
              <div class="form-group">
                <p class="document download-link">
                  <button
                    type="button"
                    class="download-link"
                    @click="downloadDoc(document.fullPath)">
                    <span>Download</span>
                    <i class="fa fa-download"></i>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- card -->
    </template>

    <!-- Section: Other Documents -->
    <template v-if="company.otherDocuments.length > 0">
      <h6 class="card-header">
        Other Documents
      </h6>
      <div class="card">
        <template v-for="(document, index) in company.otherDocuments">
          <div
            :key="index"
            class="col-md-12 row documents">
            <div class="card-block col-md-8">
              <div class="form-group">
                <p class="document">
                  {{ filterFilename(document.name) }}
                </p>
              </div>
            </div>
            <div class="card-block col-md-4">
              <div class="form-group">
                <p class="document download-link">
                  <button
                    type="button"
                    class="download-link"
                    @click="downloadDoc(document.fullPath)">
                    <span>Download</span>
                    <i class="fa fa-download"></i>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- card -->
    </template>
  </div>
</template>
<script>
export default {
  name: 'ContactInformation',
  props: {
    creditApp: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      company: {}
    }
  },
  created () {
    this.company = this.$nullToDefault(this.creditApp.company)
  },
  methods: {
    filterFilename (fileName) {
      const lastIndexOfSlash = fileName.lastIndexOf('/')
      if (lastIndexOfSlash < 0) {
        return fileName
      }
      return fileName.substring(lastIndexOfSlash + 1)
    },
    downloadDoc: function (fullPath, fileName) {
      this.$emit('downloadDoc_', fullPath, fileName)
    }
  }
}
</script>

<style lang="scss" scoped>
label,
.custom-control-input {
  pointer-events: none;
}
</style>
