<template>
  <action-button
    outlined
    :click-action="open"
    :disabled="disabled">
    <v-icon>mdi-plus-circle</v-icon>
    Add Alternate Ship To Address
    <v-dialog
      v-model="dialogIsOpen"
      width="80%">
      <v-fade-transition>
        <v-card
          v-show="displayCard"
          ref="card"
          class="job-account-add-alternate-job-address__card">
          <v-card-title class="job-account-add-alternate-job-address__card__title">
            Add Alternate Job Address
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <form-group-address-info-v2
              ref="formGroupAddress"
              v-model="formData"
              :form-labels="formLabels"
              :field-names="fieldNames"
              :vuelidate="$v.formData"></form-group-address-info-v2>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div style="margin: 1rem;">
              <v-btn
                tile
                color="transparent"
                elevation="0"
                @click="close">
                Cancel
              </v-btn>
              <form-address-validation
                ref="formAddressValidation"
                :form-data="formData"
                portal-target="dialog"
                overlay-color="transparent"
                :should-use-entered="true"
                @click="formAddressValidationClick"
                @updateFormData="sendAddress"
                @editAddress="editAddress"
                @useEntered="useEntered"
                @close="closeFromValidation"></form-address-validation>
            </div>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
      <portal-target name="dialog"></portal-target>
    </v-dialog>
  </action-button>
</template>

<script>
import ActionButton from '@/components/ActionButton.vue'
import addAddressMixin from '@/mixins/addAddress.mixin'
import FormGroupAddressInfoV2 from '@/components/form/FormGroupAddressInfoV2.vue'
import FormAddressValidation from '@/components/form/FormAddressValidation/index.vue'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'JobAccountAddAlternateJobAddress',
  components: {
    FormAddressValidation,
    FormGroupAddressInfoV2,
    ActionButton
  },
  mixins: [addAddressMixin],
  props: {
    formLabels: {
      type: Object,
      required: true
    },
    modelState: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      displayCard: true,
      formData: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        country: '',
        state: '',
        postalCode: ''
      },
      userFormInput: {
        streetAddress1: '',
        city: '',
        country: '',
        state: '',
        postalCode: ''
      },
      fieldNames: {
        country: 'country',
        streetAddress: 'streetAddress1',
        addressUnitNumber: 'streetAddress2',
        city: 'city',
        state: 'state',
        postalCode: 'postalCode'
      }
    }
  },
  methods: {
    clearFormData () {
      Object.keys(this.formData).map(k => {
        if (k === 'country') return k
        this.formData[k] = ''
        return k
      })
    },
    formAddressValidationClick () {
      this.displayCard = false
    },
    close () {
      this.dialogIsOpen = false
      this.clearFormData()
      this.$v.$reset()
      this.displayCard = true
    },
    closeFromValidation () {
      this.displayCard = true
    },
    useEntered () {
      this.sendAddress({ ...this.formData })
      this.close()
    },
    sendAddress (data) {
      const dataOut = data === null ? this.formData : data
      if (typeof dataOut.zipCode !== 'undefined') {
        dataOut.postalCode = dataOut.zipCode
        delete dataOut.zipCode
      }
      data.id = Date.now()
      this.$emit('updateAlternateAddress', dataOut)
      this.close()
    },
    editAddress () {
      this.displayCard = true
      this.$refs.formAddressValidation.closeModalFromOutside()
      this.focusOnInput()
    },
    focusOnInput () {
      this.$nextTick(() => {
        const vuetifyInputParent = this.$refs.formGroupAddress.$refs.firstInput.$el
        const input = vuetifyInputParent.getElementsByTagName('input')[0]
        setTimeout(() => {
          input.focus()
        }, 0)
      })
    }
  },
  validations () {
    return {
      formData: {
        streetAddress1: {
          required
        },
        streetAddress2: {},
        city: {
          required
        },
        country: {
          required
        },
        state: {
          required
        },
        postalCode: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.job-account-add-alternate-job-address {
  &__card {
    &__title {
      background-color: #EEEEEE;
      color: #505050;
    }
  }
}

</style>
