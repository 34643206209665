import Vue from 'vue'
import Router from 'vue-router'
import VueAnalytics from 'vue-analytics'
import store from '@/store'
import Home from '@/pages/home/home.vue'
import CreditApplicationForm from '@/pages/application/CreditApplicationForm.vue'
import InstantCreditForm from '@/pages/instant-credit/InstantCreditForm.vue'
import InstantCreditViewApplication from '@/pages/instant-credit/InstantCreditViewApplication.vue'
import JobAccountForm from '@/pages/job-account/JobAccountForm.vue'
import CashAccountForm from '@/pages/cash-account/CashAccountForm.vue'
import PersonalInformationRequestForm from '@/pages/personal-information-request/PersonalInformationRequestForm.vue'
import CreditReview from '@/pages/creditreview/list.vue'
import CreditReviewDetails from '@/pages/creditreview/detail.vue'
import CreditApplicationDetails from '@/pages/creditreview/adminApplicationDetails.vue'
import Dashboard from '@/pages/dashboard/dashboard.vue'
import UsersList from '@/pages/users/list.vue'
import UserDetail from '@/pages/users/detail.vue'
import UserCreate from '@/pages/users/create.vue'
import TaxCertList from '@/pages/taxcert/list.vue'
import EditEmailTemplate from '@/pages/admin/emailtemplate/edit.vue'
import InstantCreditPermissions from '@/pages/admin/instantcreditpermissions/list.vue'

import AccessDenied from '@/pages/admin/accessDenied.vue'
import AdminLayout from '@/components/layouts/AdminLayout.vue'
// import LandingPage from '@/pages/Home/LandingPage.vue'
// import ApplicationAccess from '@/pages/application/ApplicationAccess'
import AuthCallback from '@/pages/AuthCallback.vue'
import AuthCallbackSilent from '@/pages/AuthCallbackSilent.vue'
import WhatsNew from '@/pages/admin/whatsnew/index.vue'
import PageUnavailable from '@/pages/PageUnavailable.vue'

import { initAuth } from '@/auth'

Vue.use(Router)

// NOTE: BeforeEnter routeguards below are executed before beforeRouteEnter

const adminAuthBeforeEnter = async (to, from, next) => {
  await initAuth()
    .then((res) => {
      const company = store.state.company

      const identity = store.getters.identity
      const authorizedBannerRoutes = identity.banners !== undefined && identity.banners.length > 0 ? identity.banners : [identity.banner]

      const bannerName = to.params.tenant
      // Let Res user route to main page
      if (authorizedBannerRoutes.length === 1 && authorizedBannerRoutes[0] === 'res') {
        authorizedBannerRoutes.push('rexel')
      }

      const authorizedToProceedToRoute = authorizedBannerRoutes.includes(bannerName)

      if (!authorizedToProceedToRoute) {
        const banner = identity.banner
        next({ name: to.name, params: { tenant: banner } })
        return
      }

      if (to.name === 'dashboardNoTenant') {
        if (company != null && Object.keys(company).length > 0) {
          const banner = company.name.toLowerCase()
          next({ name: 'dashboard', params: { tenant: banner } })
          return
        }
      }

      if (identity != null) {
        // creditAppUser with Admin permissions allowed to dashboard
        next((vm) => {
          vm.$ga.set('Employee', identity.email)
        })
        return
      }

      if (to.name === 'usersDetail') {
        if (identity != null && identity.permissions.editProfile) {
          next()
        } else {
          next({ name: 'accessDenied' })
        }
      }
    })
    .catch(e => {
      // Handle error
    })
}

const externalRouteBeforeEnter = async (to, from, next) => {
  const bannerName = to.params.tenant
  // if company(s) was supplied in route, restrict to current banner
  if (to.meta.company && to.meta.company.length > 0 && to.meta.company.indexOf(bannerName) < 0) {
    next({ name: 'accessDenied' })
  } else {
    next()
  }
}

const inProgressFormAuth = async (to, from, next) => {
  const bannerName = from.params.tenant
  // prevent RES banner from accessing these forms
  if (['adminCashAccountInProgress', 'adminJobAccountInProgress', 'adminInstantCreditInProgress', 'adminInstantCreditForSignature'].includes(to.name)) {
    if (bannerName === 'rexelenergy') {
      next({ name: 'accessDenied', params: { tenant: bannerName } })
    } else {
      next()
    }
  }
}

/** NOTE: Use of this meta object is still implemented throughout FE. I think we are getting rid of this meta object concept, and replacing it w/ a userType property (maybe set on the currentUser object in store?)
 * meta object info
 * @typedef {Object} meta
 * @property {boolean} auth - used on pages requiring admin access
 * @property {boolean} user - added recently to the new form pages to differentiate a user w/o an account from a user w/ admin access
 * @property {Object} company - ???
 */

export const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'AuthCallback',
      path: '/auth-callback',
      component: AuthCallback
    },
    {
      name: 'AuthCallbackSilent',
      path: '/silent-auth-callback',
      component: AuthCallbackSilent
    },
    {
      name: 'pageUnavailable',
      path: '/page-unavailable/:tenant',
      meta: { company: ['platt', 'rexel', 'gexpro'], user: true, isInternalForm: false, auth: false },
      component: PageUnavailable
    },
    {
      name: 'home',
      path: '/',
      component: Home
    },
    {
      name: 'personalInformationRequest',
      path: '/personal-information-request',
      component: PersonalInformationRequestForm
    },
    {
      // name: 'adminRoot',
      path: '/admin',
      component: AdminLayout,
      beforeEnter: adminAuthBeforeEnter,
      children: [ // all admin children routes should require that an internal Employee w/ permissions login to gain access
        {
          name: 'dashboardNoTenant',
          path: '/',
          meta: { auth: true },
          component: Dashboard
        },
        {
          name: 'creditReview',
          path: 'creditapps/:tenant',
          meta: { auth: true },
          component: CreditReview
        },
        {
          name: 'adminCashAccount',
          path: 'cash-account/:tenant',
          meta: { company: {}, auth: true, isInternalForm: true },
          component: CashAccountForm
        },
        {
          name: 'adminCashAccountInProgress',
          path: 'cash-account/:tenant/:id',
          meta: { company: {}, auth: true, isInternalForm: true },
          component: CashAccountForm,
          beforeEnter: inProgressFormAuth
        },
        {
          name: 'adminApplication',
          path: 'application/:tenant/:guid?',
          meta: { company: {}, auth: true, isInternalForm: true },
          component: CreditApplicationForm
        },
        {
          name: 'adminJobAccount',
          path: 'job-account/:tenant',
          meta: { company: {}, auth: true, isInternalForm: true },
          component: JobAccountForm
        },
        {
          name: 'adminJobAccountInProgress',
          path: 'job-account/:tenant/:id',
          meta: { company: {}, auth: true, isInternalForm: true },
          component: JobAccountForm,
          beforeEnter: inProgressFormAuth
        },
        {
          name: 'adminInstantCredit',
          path: 'instant-credit/:tenant',
          meta: {
            company: {},
            auth: true,
            isInternalForm: true,
            permissions: [
              'isInstantCreditLinkViewer'
            ]
          },
          component: InstantCreditForm
        },
        {
          name: 'adminInstantCreditInProgress',
          path: 'instant-credit/:tenant/:id',
          meta: {
            company: {},
            auth: true,
            isInternalForm: true,
            permissions: [
              'isInstantCreditLinkViewer'
            ]
          },
          component: InstantCreditForm,
          beforeEnter: inProgressFormAuth
        },
        {
          name: 'adminInstantCreditForSignature',
          path: 'instant-credit/signature/:tenant/:id',
          meta: {
            company: {},
            auth: true,
            isInternalForm: true
          },
          component: InstantCreditForm,
          beforeEnter: inProgressFormAuth
        },
        {
          name: 'creditDetails',
          path: 'creditapps/:tenant/:id',
          meta: { admin: true, auth: true },
          component: CreditReviewDetails
        },
        {
          name: 'creditAppDetails',
          path: 'creditapps/:tenant/:id/application-details',
          meta: { auth: true },
          component: CreditApplicationDetails
        },
        {
          name: 'usersList',
          path: 'users',
          meta: { admin: true, auth: true },
          component: UsersList
        },
        {
          name: 'usersDetail',
          path: 'users/detail/:id',
          meta: { admin: true, auth: true },
          component: UserDetail
        },
        {
          name: 'usersCreate',
          path: 'users/create',
          meta: { admin: true, auth: true },
          component: UserCreate
        },
        {
          name: 'taxCertList',
          path: 'taxcerts/:tenant',
          meta: { auth: true },
          component: TaxCertList
        },
        {
          name: 'editEmailTemplate',
          path: 'email-template/:tenant',
          meta: { admin: true, auth: true },
          component: EditEmailTemplate
        },
        {
          name: 'instantCreditPermissions',
          path: 'instantCreditPermissions',
          meta: { admin: true, auth: true },
          component: InstantCreditPermissions
        },
        {
          name: 'dashboard',
          path: ':tenant',
          meta: { admin: true, auth: true },
          component: Dashboard
        },
        {
          name: 'whatsNew',
          path: 'whats-new',
          meta: { admin: true, auth: true },
          component: WhatsNew
        }
      ]
    },

    {
      name: 'accessDenied',
      path: '/admin/access-denied',
      meta: { auth: false },
      component: AccessDenied
    },
    {
      name: 'guarantor', // generated email links from outside of this app brings Customers to this route using an encrypted id for guid
      path: '/guarantor/:tenant/:guid?',
      meta: { step: 4 }, // Step 4 is Signatories
      component: CreditApplicationForm
    },
    {
      name: 'jobAccount',
      path: '/job-account/:tenant',
      meta: { company: ['platt', 'rexel', 'gexpro'], user: true, isInternalForm: false },
      component: JobAccountForm,
      beforeEnter: externalRouteBeforeEnter
    },
    {
      name: 'cashAccount',
      path: '/cash-account/:tenant/:guid?',
      meta: { company: {}, user: true, isInternalForm: false },
      component: CashAccountForm
    },
    {
      name: 'instantCreditViewApplication',
      path: '/instant-credit/:tenant/:guid',
      meta: { company: ['rexel', 'gexpro'], user: true, isInternalForm: false },
      component: InstantCreditViewApplication,
      beforeEnter: externalRouteBeforeEnter
    },
    {
      name: 'application',
      path: '/application/:tenant/:guid?', // NOTE: why use same path as the next route object?
      meta: { company: {}, user: true, isInternalForm: false },
      component: CreditApplicationForm
    },
    {
      name: 'applicationGuid',
      path: '/application/:tenant/:guid?',
      alias: '/:tenant/:guid?',
      meta: { company: {}, user: true, isInternalForm: false },
      component: CreditApplicationForm
    },
    {
      path: '*',
      redirect: { name: 'home' }
    }
  ]
})

// this is executed before beforeRouteEnter
router.beforeEach(async (to, from, next) => {
  store.state.applicationForm.isFormCompleted = false
  store.state.stepsVisible = null
  next()
})
router.beforeEach((to, from, next) => {
  // banner favicon toggling for external forms
  let icoLink = document.getElementById('favicon')
  if (to.name === 'jobAccount' || to.name === 'application') {
    let path
    if (!icoLink) {
      icoLink = document.createElement('link')
      icoLink.setAttribute('rel', 'icon')
      icoLink.setAttribute('id', 'favicon')
    }

    switch (to.params.tenant) {
      case 'platt':
        path = require('@/assets/images/platt-favicon.png')
        break
      case 'gexpro':
        path = require('@/assets/images/gexpro-favicon.png')
        break
      case 'rexel':
      case 'rexelenergy':
        path = require('@/assets/images/rexel-favicon.png')
        break
      default:
        path = '/favicon.ico'
    }

    icoLink.setAttribute('href', path)
    document.head.appendChild(icoLink)
  } else if (icoLink) {
    // icoLink.remove() does not work here, since the favicon stays cached
    icoLink.setAttribute('href', '/favicon.ico')
  }

  if (to.params.tenant && !['platt', 'rexel', 'gexpro', 'rexelenergy'].includes(to.params.tenant.toLowerCase())) {
    next('/')
  }
  next()
})

Vue.use(VueAnalytics, {
  id: 'UA-100321334-1',
  router
})
