export default {
  identity (state) {
    return state.identity
  },
  isDevelopmentEnvironment (state) {
    return state.appConfig.environment === 'Development'
  },
  isProductionEnvironment (state) {
    return state.appConfig.environment === 'Production'
  },
  showDevControls (state, getters) {
    return getters.isDevelopmentEnvironment && state.devHelpers.showDevControls === true
  },
  showDevFormValidationState (state, getters) {
    return getters.showDevControls === true &&
      state.devHelpers.showDevFormValidationState === true
  },
  overwriteFormWithTestData (state, getters) {
    return getters.showDevControls === true &&
      state.devHelpers.overwriteFormWithTestData === true
  },
  banners (state) {
    return state.banners
  },
  branches (state) {
    return state.branches
  },
  company (state) {
    return state.company
  },
  countries (state) {
    return state.countries
  },
  filterReasons (state) {
    return state.filterReasons
  },
  statesUS (state) {
    return state.statesUS
  },
  userRoles (state) {
    return state.userRoles
  },
  regions (state) {
    return state.regions
  },
  reviewTable (state) {
    return state.reviewTable
  },
  plattforceRootUrl (state) {
    return state.plattforceRootUrl
  },
  plattforceCustomerRootUrl (state) {
    return state.plattforceRootUrl
  },
  adminHeaderTitle (state) {
    return state.adminHeaderTitle
  },
  webUser (state) {
    return state.webUser
  },
  getDefaultBannerName (state) {
    // // HOLD: 'res'/'rexelenergy' users have 'rexel' as their default banner. set those users default banner to 'rexelenergy'
    // const identity = state.identity
    // if (identity.banners.includes('res') && identity.banner === 'rexel') {
    //   return 'rexelenergy'
    // }
    return state.identity.banner
  },
  getBannerById: (state) => (bannerId) => {
    // TODO: we want b.tenantId to be b.bannerId
    // need to do change on BE first
    return state.banners
      .find(b => b.tenantId === bannerId)
  },
  getBannerByName: (state) => (bannerName) => {
    if (bannerName == null) return null
    return state.banners
      .find(b => b.name.toLowerCase() === bannerName.toLowerCase())
  },
  getBannerIdByName: (state, getters) => (bannerName) => {
    const banner = getters.getBannerByName(bannerName)
    return banner != null ? banner.tenantId : null
  },
  getBannerNameById: (state, getters) => (bannerId) => {
    const banner = getters.getBannerById(bannerId)
    return banner != null ? banner.name.toLowerCase() : null
  },
  isManagedUser: (state) => {
    return state.identity.permissions != null
      ? state.identity.permissions.managedUser
      : false
  },
  currentUserInfo (state) {
    if (state.identity !== null && state.identity !== undefined) {
      const { banner, userId, firstName, lastName, email, displayName, sso, winntId, eclipseUserLogin, impersonatingEmail } = state.identity
      const currentUser = { banner, userId, firstName, lastName, email, displayName, sso, winntId, eclipseUserLogin, impersonatingEmail }
      currentUser.isImpersonating = impersonatingEmail !== undefined

      return currentUser
    }
  },
  stepsVisible: (state) => {
    return state.stepsVisible
  },
  defaultBranch: (state) => {
    return state.defaultBranch
  }
}
