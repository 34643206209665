<template>
  <div
    style="padding-bottom: .4em;"
    class="col-md-12">
    <li
      v-for="(msg, i) in messages"
      :key="i">
      {{ msg }}
    </li>
  </div>
</template>

<script>
export default {
  name: 'FormValidationFailuresSummary',
  props: {
    messages: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
</style>
