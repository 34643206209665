<template>
  <fieldset>
    <div class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.electriciansLicenseNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.electriciansLicenseNumber"
          :input-label="formLabels.electriciansLicenseNumber"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputDropdownStates
          v-model="userFormInput"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate"
          :form-labels="formLabels"
          :form-data="formData"
          :field-names="{
            state: 'electriciansLicenseIssuingState'
          }"></FormInputDropdownStates>
      </div>
      <div class="col-sm-4">
        <FormInputDatePicker
          v-model="userFormInput.electriciansLicenseExpirationDate"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.electriciansLicenseExpirationDate"
          :input-label="formLabels.electriciansLicenseExpirationDate"
          :disabled-dates="{}"
          input-type="text"></FormInputDatePicker>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import FormInputDropdownStates from '@/components/form/FormInputDropdownStates.vue'
import FormInputDatePicker from '@/components/form/FormInputDatePicker.vue'

export default {
  name: 'InstantCreditFormStepElectriciansLicense',
  components: {
    FormInputTextInput,
    FormInputDropdownStates,
    FormInputDatePicker
  },
  mixins: [
    FormStepImplementationMixin
  ]
}
</script>
