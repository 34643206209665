<template>
  <div>
    <h5
      id="sectionTitle"
      class="section-title mb-3">
      Company Information
    </h5>
    <!-- Credit Report Info-->
    <CreditInfo :app="app"></CreditInfo>
    <!-- Company Info -->
    <CompanyInfo
      :app="app"
      :can-edit="identity.permissions.editApplications"
      @editCompanyInfo_="editCompanyInfo"></CompanyInfo>
  </div>
</template>

<script>
import CreditInfo from './CreditInfo.vue'
import CompanyInfo from './CompanyInfo.vue'

export default {
  name: 'CompanySection',
  components: {
    CreditInfo,
    CompanyInfo
  },
  props: {
    app: {
      required: true,
      type: Object
    },
    identity: {
      required: true,
      type: Object
    },
    editCompanyInfo: {
      required: false,
      type: Boolean,
      default: false
    }
  }

}
</script>

<style lang="scss">
#sectionTitle.section-title {
    margin-bottom: 1.5rem !important;
}
</style>
