<template>
  <div
    id="application-component"
    class="component-container credit-application">
  </div>
</template>
<script type="text/javascript">

import TenantsMixin from 'mixins/tenants.mixin'
import FormMixin from 'components/form/Form.mixin'

import { mapGetters } from 'vuex'

export default {
  name: 'InstantCreditViewApplication',
  components: {

  },
  mixins: [
    TenantsMixin,
    FormMixin
  ],
  data () {
    return {
      isLoading: false,
      tenant: null,
      applicationKey: null,
      currentSignatory: null
    }
  },
  computed: {
    ...mapGetters({
      isDevelopmentEnvironment: 'isDevelopmentEnvironment'
    })
  },
  async mounted () {
    await this.getApplication()
  },
  methods: {
    async getApplication () {
      const tenant = this.$route.params.tenant
      const applicationKey = this.$route.params.guid
      const role = 'Applicant'

      await this.$getFormDataApplicationForSigning(tenant, applicationKey, role)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins/_flexbox.scss";
.download-button {
  background-color: #099c98;
  font-size: 0.64rem;
  margin: 0.375rem;
  padding: 0.5rem 1rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
#application-component {
  padding: 1em;
  padding-right: 3em;
}

#accordion {
  padding-inline-start: 0;
}
</style>
