<template>
  <div>
    <span class="hidden-xs-up">
      {{ data.dateReviewed | shortDate }}
    </span>{{ data.dateReviewed | shortDate }}
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'DateReviewedTag',
  props: {
    data: {
      required: true,
      type: Object
    }
  }
}
</script>
