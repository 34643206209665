<template>
  <fieldset>
    <FormInputGroupAddressInfo
      ref="groupAddress"
      v-model="userFormInput"
      :disabled="isStepDisabled"
      :vuelidate="vuelidate"
      :form-labels="formLabels"
      :form-data="formData"
      :field-names="{
        streetAddress: 'streetAddress1',
        addressUnitNumber: 'streetAddress2',
        city: 'city',
        state: 'state',
        postalCode: 'postalCode'
      }"></FormInputGroupAddressInfo>
    <v-row v-if="!isStepDisabled">
      <v-col>
        <form-address-validation
          :form-data="formData"
          :vuelidate="vuelidate"
          :close-modal-from-outside="closeModal"
          @close="closeModal = false"
          @updateFormData="updateAddress"
          @editAddress="editAddress"></form-address-validation>
      </v-col>
    </v-row>
    <form-contact-cards
      :disabled="isStepDisabled"
      card-title="Alt-shipping Address"
      :contacts="shipTosAddressesFormatted"
      main-card-title="Primary Address"
      :main-card-id="addressId"
      @removeContact="warningCheck"></form-contact-cards>
    <v-row v-if="!isStepDisabled">
      <v-col>
        <job-account-add-alternate-job-address
          :form-labels="formLabels"
          :disabled="addAddressIsDisabled"
          @updateAlternateAddress="updateAlternateAddress"></job-account-add-alternate-job-address>
      </v-col>
    </v-row>
    <job-account-alt-ship-questions
      v-if="!isPlatt() && userFormInput.alternateShipTos.length > 0"
      :form-labels="formLabels"
      :user-form-input="userFormInput"
      :is-step-disabled="isStepDisabled">
    </job-account-alt-ship-questions>
    <v-dialog
      v-model="showAltWarning"
      max-width="290">
      <v-card>
        <v-card-title class="headline">
          Warning!
        </v-card-title>
        <v-card-text>
          Removing this alternate shipping address will cause your answers to the alternate shipping address questions below to be lost
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="showAltWarning = false">
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="removeContact">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fieldset>
</template>

<script>

import FormInputGroupAddressInfo from '@/components/form/FormInputGroupAddressInfo.vue'
import FormAddressValidation from '@/components/form/FormAddressValidation/index.vue'
import addressValidationMixin from 'mixins/addressValidation.mixin'
import JobAccountAddAlternateJobAddress from './JobAccountAddAlternateJobAddress.vue'
import FormContactCards from '@/components/form/FormContactCards.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import JobAccountAltShipQuestions from './JobAccountAltShipQuestions.vue'
import TenantsMixin from 'mixins/tenants.mixin'

export default {
  name: 'JobAccountFormStepProjectAddress',
  components: {
    FormContactCards,
    JobAccountAddAlternateJobAddress,
    FormInputGroupAddressInfo,
    FormAddressValidation,
    JobAccountAltShipQuestions
  },
  mixins: [
    addressValidationMixin,
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      addressId: null,
      shipToAddresses: [],
      showAltWarning: false,
      closeModal: false
    }
  },
  computed: {
    shipTosAddressesFormatted () {
      const addresses = this.shipToAddresses.map(a => {
        return {
          id: a.id,
          streetAddress1: a.streetAddress1,
          streetAddress2: a.streetAddress2,
          cityStatePostalCode: `${a.city}, ${a.state} ${a.postalCode} `
        }
      })
      return addresses.sort((a, b) => {
        const compareA = a.id === this.addressId ? 0 : 1
        const compareB = b.id === this.addressId ? 0 : 1
        return compareA - compareB
      })
    },
    addAddressIsDisabled () {
      const altShipToAddresses = this.shipToAddresses.filter(a => a.id !== this.addressId)
      return altShipToAddresses.length >= 2 || this.addressId === null
    }
  },
  watch: {
    'formData.streetAddress1' () {
      this.setAddressFromFormData()
    },
    'formData.streetAddress2' () {
      this.setAddressFromFormData()
    },
    'formData.city' () {
      this.setAddressFromFormData()
    },
    'formData.postalCode' () {
      this.setAddressFromFormData()
    },
    'formData.state' () {
      this.setAddressFromFormData()
    },
    shipToAddresses (newVal) {
      this.formData.alternateShipTos = newVal.filter(address => address.id !== this.addressId)
    }
  },
  mounted () {
    // if the form has an id, update form w/ saved data
    this.setAddressFromFormData()
    this.formData.alternateShipTos.forEach(shipTo => {
      this.updateAlternateAddress(shipTo)
    })

    // KEEP: When Dev Mode "Overwrite Form Data with Test Data" doesn't work
    // this.userFormInput.streetAddress1 = '189 Job Address Way'
    // this.userFormInput.postalCode = '97006'
    // this.userFormInput.city = 'BEAVERTON'
    // this.userFormInput.state = 'OR'
  },
  methods: {
    clearMainGroupAddressForm () {
      this.$root.$emit('jobAccountClearShipTo')
      const payload = {
        streetAddress: '',
        addressUnitNumber: '',
        postalCode: '',
        city: '',
        state: ''
      }

      this.formData.streetAddress1 = ''
      this.formData.streetAddress2 = ''
      this.formData.addressUnitNumber = ''
      this.formData.postalCode = ''
      this.formData.city = ''
      this.formData.state = ''

      this.$refs.groupAddress.updateFields(payload)
    },
    updateAlternateAddress (data) {
      this.shipToAddresses.push(data)
    },
    warningCheck (id) {
      const altShipToAddresses = this.shipToAddresses.filter(a => a.id !== this.addressId)
      if (altShipToAddresses.length === 1) {
        this.showAltWarning = true
      } else {
        this.removeContact(id)
      }
    },
    removeContact (id) {
      if (this.addressId === id) {
        this.addressId = null
        this.clearMainGroupAddressForm()
      }
      const index = this.shipToAddresses.findIndex(data => data.id === id)
      this.shipToAddresses.splice(index, 1)
      this.showAltWarning = false
    },
    setAddressFromFormData () {
      if (this.vuelidate.$invalid && this.addressId === null) return

      const { streetAddress1, streetAddress2, city, postalCode, state } = this.formData
      const address = {
        streetAddress1,
        streetAddress2,
        city,
        postalCode,
        state
      }

      if (!this.vuelidate.$invalid && this.addressId === null) {
        const id = Date.now()
        this.addressId = id
        this.shipToAddresses.push({ ...address, id })
        return
      }
      const index = this.shipToAddresses.findIndex(a => a.id === this.addressId)
      this.shipToAddresses.splice(index, 1, { ...address, id: this.addressId })
    },
    editAddress () {
      this.closeModal = true
    }
  }
}
</script>
<style lang="scss" scoped>
.alt-ship-title {
  color: #000000;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  line-height: 36px;
}

</style>
