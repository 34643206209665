<template>
  <v-container
    id="dashboard-pending-component"
    class="pa-0 h-100">
    <v-card
      class="card ma-0 h-100">
      <div class="card-block">
        <h6 class="font-weight-bold">
          Pending Applications
        </h6>
        <div class="doughnut-chart pending-count">
          <div
            class="viewPending"
            @click="goToPending()">
            <h1 class="chart-count">
              {{ pending != null ? pending.value : 0 }}
            </h1>
            <span class="mx-auto">
              <small class="font-weight-bold">
                View Applications
              </small>
            </span>
          </div>
        </div>

        <!-- Legend -->
        <div
          class="flex-column align-content-start mt-4">
          <div>
            Average time to process: <span class="pull-right font-weight-bold">
              {{ processing != null ? processing.value : 0 }} {{ processing != null ? processing.uom : '' }}
            </span>
          </div>

          Average time to first contact: <span class="pull-right font-weight-bold">
            {{ avgDaysToContact != null ? avgDaysToContact.value : 0 }} {{ avgDaysToContact != null ? avgDaysToContact.uom : "" }}
          </span>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'PendingCount',
  props: {
    pending: {
      type: Object,
      required: false,
      default: function () {
        return {
          value: 0
        }
      }
    },
    processing: {
      type: Object,
      required: false,
      default: function () {
        return {
          value: 0
        }
      }
    },
    avgDaysToContact: {
      type: Object,
      required: false,
      default: function () {
        return {
          value: 0
        }
      }
    }
  },
  methods: {
    goToPending () {
      this.$router.push({
        name: 'creditReview',
        query: { filter: 'Pending' }
      })
    }
  }
}

</script>

<style lang="scss">
#dashboard-pending-component {

  .dashboard-footer {
    span {
      font-weight: bold;
      padding-left: 1em;
    }

    .pull-right {
        float:right;
    }
  }

.pending-count {
    .viewPending {
        vertical-align: middle;
        margin: auto;
        text-align: center;
        color: #0084D8;

        &:hover {
            cursor: pointer;
            color: #005c97;
        }
    }

    h1.chart-count {
        vertical-align: middle;
        text-align: center;
        font-weight: bold;
    }
}
}

</style>
