import InstantCreditFormStepCompanyInformation from './InstantCreditFormStepCompanyInformation.vue'
import InstantCreditFormStepMailingAddress from './InstantCreditFormStepMailingAddress.vue'
import InstantCreditFormStepPhysicalAddress from './InstantCreditFormStepPhysicalAddress.vue'
import InstantCreditFormStepPrincipalInformation from './InstantCreditFormStepPrincipalInformation.vue'
import InstantCreditFormStepContactInformation from './InstantCreditFormStepContactInformation.vue'
import InstantCreditFormStepElectriansLicense from './InstantCreditFormStepElectriciansLicense.vue'
import InstantCreditFormStepSignatoryInformation from './InstantCreditFormStepSignatoryInformation.vue'
import InstantCreditFormStepSalesInformation from './InstantCreditFormStepSalesInformation.vue'
import InstantCreditFormStepNotes from './InstantCreditFormStepNotes.vue'

export const COMPANY_INFORMATION = 'companyInformation'
export const MAILING_ADDRESS = 'mailingAddress'
export const PHYSICAL_ADDRESS = 'physicalAddress'
export const PRINCIPAL_INFORMATION = 'principalInformation'
export const CONTACT_INFORMATION = 'contactInformation'
export const ELECTRICIANS_LICENSE = 'electriciansLicense'
export const SIGNATORY_INFORMATION = 'signatoryInformation'
export const SALES_INFORMATION = 'salesInformation'
export const DOCUMENTS = 'documents' // deprecated - not in proof from #27610
export const NOTES = 'notes'

export const formTitle = 'Instant Credit'

export const formDefinitions = {
  [COMPANY_INFORMATION]: {
    order: 1,
    hideCircle: true,
    title: 'Company Information',
    component: InstantCreditFormStepCompanyInformation,
    dataClass: class CompanyInformation {
      constructor () {
        this.companyName = ''
        this.companyTelephoneNumber = ''
        this.federalTaxIdNumber = ''
        this.companyEmailAddress = ''
      }
    },
    labels: {
      companyName: 'Company Name:',
      companyTelephoneNumber: 'Company Phone Number:',
      federalTaxIdNumber: 'Federal Tax ID Number',
      companyEmailAddress: 'Accounts Payable Email Address:'
    },
    testData: {
      companyName: 'Some Company',
      companyTelephoneNumber: '(408) 867-5309',
      federalTaxIdNumber: '555555555',
      companyEmailAddress: 'ok@ok.com'
    }
  },
  [MAILING_ADDRESS]: {
    order: 2,
    hideCircle: true,
    title: 'Mailing Address',
    component: InstantCreditFormStepMailingAddress,
    dataClass: class MailingAddress {
      constructor () {
        this.streetAddress1 = ''
        this.streetAddress2 = ''
        this.city = ''
        this.state = ''
        this.zipCode = ''
      }
    },
    labels: {
      streetAddress1: 'Street Address:',
      streetAddress2: 'Street Address 2:',
      city: 'City:',
      state: 'State:',
      zipCode: 'Postal Code:'
    },
    testData: {
      streetAddress1: '10605 SW ALLEN BLVD',
      streetAddress2: '',
      city: 'BEAVERTON',
      state: 'OR',
      zipCode: '97005-4828'
    }
  },
  [PHYSICAL_ADDRESS]: {
    order: 3,
    hideCircle: true,
    title: 'Physical Address',
    component: InstantCreditFormStepPhysicalAddress,
    dataClass: class PhysicalAddress {
      constructor () {
        this.sameAsMailingAddress = false
        this.streetAddress1 = ''
        this.streetAddress2 = ''
        this.city = ''
        this.state = ''
        this.zipCode = ''
      }
    },
    labels: {
      sameAsMailingAddress: 'Same as Mailing Address',
      streetAddress1: 'Street Address:',
      streetAddress2: 'Street Address 2:',
      city: 'City:',
      state: 'State:',
      zipCode: 'Postal Code:'
    },
    testData: {
      sameAsMailingAddress: true,
      streetAddress1: '10605 SW ALLEN BLVD',
      streetAddress2: '',
      city: 'BEAVERTON',
      state: 'OR',
      zipCode: '97005-4828'
    }
  },
  [PRINCIPAL_INFORMATION]: {
    order: 4,
    hideCircle: true,
    title: 'Principal Information',
    component: InstantCreditFormStepPrincipalInformation,
    dataClass: class PrincipalInformation {
      constructor () {
        this.principal1FirstName = ''
        this.principal1LastName = ''
        this.principal1Title = ''
        this.use2ndPrincipal = ''
        this.principal2FirstName = ''
        this.principal2LastName = ''
        this.principal2Title = ''
      }
    },
    labels: {
      principal1FirstName: 'First Name:',
      principal1LastName: 'Last Name:',
      principal1Title: 'Title:',
      use2ndPrincipal: 'Is there a second Principal?',
      principal2FirstName: 'First Name:',
      principal2LastName: 'Last Name:',
      principal2Title: 'Title:'
    },
    testData: {
      principal1FirstName: 'test',
      principal1LastName: 'test',
      principal1Title: 'tester',
      use2ndPrincipal: '0',
      principal2FirstName: 'test2',
      principal2LastName: 'test2',
      principal2Title: 'tester2'
    }
  },
  [CONTACT_INFORMATION]: {
    order: 5,
    hideCircle: true,
    title: 'Payable Contact Information',
    component: InstantCreditFormStepContactInformation,
    dataClass: class ContactInformation {
      constructor () {
        this.firstName = ''
        this.lastName = ''
        this.contactPhoneNumber = ''
        this.contactEmailAddress = ''
        this.jobTitle = ''
        this.driversLicenseNumber = ''
        this.driversLicenseIssuingState = ''
        this.driversLicenseExpirationDate = ''
      }
    },
    labels: {
      firstName: 'First Name:',
      lastName: 'Last Name:',
      contactPhoneNumber: 'Contact Phone Number:',
      contactEmailAddress: 'Email Address:',
      jobTitle: 'Position:',
      driversLicenseNumber: 'Driver\'s License Number:',
      driversLicenseIssuingState: 'Issuing State',
      driversLicenseExpirationDate: 'Expires'
    },
    testData: {
      firstName: 'PayFirst',
      lastName: 'PayLast',
      contactPhoneNumber: '(408) 867-5309',
      contactEmailAddress: 'pay@blah.com',
      jobTitle: 'Nope',
      driversLicenseNumber: '583995737272734',
      driversLicenseIssuingState: 'LA',
      driversLicenseExpirationDate: '5/5/2024'
    }
  },
  [ELECTRICIANS_LICENSE]: {
    order: 6,
    hideCircle: true,
    title: 'Electrician\'s License',
    component: InstantCreditFormStepElectriansLicense,
    dataClass: class ElectriciansLicense {
      constructor () {
        this.electriciansLicenseNumber = ''
        this.electriciansLicenseIssuingState = ''
        this.electriciansLicenseExpirationDate = ''
      }
    },
    labels: {
      electriciansLicenseNumber: 'Electrician\'s License Number:',
      electriciansLicenseIssuingState: 'Issuing State',
      electriciansLicenseExpirationDate: 'Expires'
    },
    testData: {
      electriciansLicenseNumber: '583995737272734',
      electriciansLicenseIssuingState: 'LA',
      electriciansLicenseExpirationDate: '5/5/2024'
    }
  },
  [SIGNATORY_INFORMATION]: {
    order: 7,
    hideCircle: true,
    title: 'Signatory Information',
    component: InstantCreditFormStepSignatoryInformation,
    dataClass: class SignatoryInformation {
      constructor () {
        this.sameAsPayableContact = false
        this.firstName = ''
        this.lastName = ''
        this.phoneNumber = ''
        this.emailAddress = ''
        this.jobTitle = ''
      }
    },
    labels: {
      sameAsPayableContact: 'Same as Payable Contact',
      firstName: 'First Name:',
      lastName: 'Last Name:',
      phoneNumber: 'Phone Number:',
      emailAddress: 'Email Address:',
      jobTitle: 'Position:'
    },
    testData: {
      sameAsPayableContact: false,
      firstName: 'SignFirst',
      lastName: 'SignLast',
      phoneNumber: '(408) 867-5309',
      emailAddress: 'sign@blah.com',
      jobTitle: 'Nope'
    }
  },
  [SALES_INFORMATION]: {
    order: 8,
    hideCircle: true,
    title: 'Sales Information',
    component: InstantCreditFormStepSalesInformation,
    dataClass: class SalesInformation {
      constructor () {
        this.storageGuid = ''
        this.uploadedDocuments = ''
        this.isUploaded = ''
        this.branch = ''
        this.approver = ''
        this.branchNumber = ''
        this.customerType = ''
        this.customerTypeCategory = ''
        this.customerType = ''
        this.customerPriceClass = ''
        this.eclipsePriceClass = { title: '' }
        this.insideSalesPerson = ''
        this.outsideSalesPerson = ''
        this.isTaxExempt = ''
        this.optOutElectronicDelivery = false
      }
    },
    labels: {
      uploadedDocuments: '',
      storageGuid: 'This should not be displayed',
      branch: 'Assigned Branch:',
      approver: 'Document Approver:',
      customerType: 'PIC Code:',
      customerTypeCategory: 'PIC Code Category:',
      insideSalesPerson: 'Inside Salesperson:',
      outsideSalesPerson: 'Outside Salesperson:',
      isTaxExempt: 'Tax Exempt:',
      optOutElectronicDelivery: 'Invoices/Statements sent electronically; opt out of electronic delivery?'
    },
    testData: {
      branch: '',
      approver: '',
      customerType: '',
      customerTypeCategory: '',
      insideSalesPerson: '',
      outsideSalesPerson: '',
      isTaxExempt: '',
      optOutElectronicDelivery: false
    }
  },
  [NOTES]: {
    order: 10,
    hideCircle: true,
    title: 'Notes',
    component: InstantCreditFormStepNotes,
    dataClass: class Notes {
      constructor () {
        this.userNotes = ''
      }
    },
    labels: {
      userNotes: 'Notes:'
    },
    testData: {
      userNotes: ''
    }
  }
}
