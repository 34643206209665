<template>
    <div
      v-cloak
      id="users-filter">
      <div class="users-header">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <div class="input-group">
                <input
                  v-model="searchQuery"
                  class="form-control form-control-row"
                  placeholder="Search For User" />
                <span class="input-group-btn">
                  <a class="btn btn-secondary">
                    <i class="fa fa-search"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div class="col-auto ml-2">
            <div class="form-group">
              <div class="input-group">
                <label class="input-group-addon">
                  Filter By Region
                </label>
                <select
                  v-model="filterByRegion"
                  class="form-control">
                  <option value="All" selected>
                    All
                  </option>
                  <option
                    v-for="(option) in regions"
                    :key="option.bannerRegionCode"
                    :value="option.bannerRegionCode">
                    {{ option.bannerRegionCode }} - {{ option.bannerRegionDesc }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-card>
        <v-data-table
          :headers="headers"
          :items="userList"
          :search="search"
          :items-per-page="25">
          <template v-slot:item.permissionEnabled="{item}">
            <v-checkbox
              v-model="item.permissionEnabled"
              @change="enablePermissions($event, item.userId, item.regionCode)"></v-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </div>
</template>

<script>
import axios from 'ca-http-service'
import BannerMixin from 'mixins/banner.mixin'
import { mapState } from 'vuex'

export default {
  name: 'PermissionsList',
  mixins: [BannerMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getData()
    })
  },
  data () {
    return {
      filterByRegion: 'All',
      search: '',
      searchQuery: '',
      users: [],
      addedUsers: [],
      regions: [],
      headers: [
        {
          text: 'Full Name',
          align: 'start',
          sortable: true,
          value: 'fullName'
        },
        { text: 'Email', value: 'email' },
        { text: 'Region', value: 'regionCode' },
        { text: 'Permission Enabled', value: 'permissionEnabled' }
      ],
      columns: ['fullName', 'email', 'regionCode'],
      options: {
        sortable: ['fullName', 'email', 'regionCode'],
        sortIcon: {
          base: 'fa',
          up: 'fa-caret-up',
          down: 'fa-caret-down'
        },
        orderBy: {
          column: 'fullName',
          ascending: true
        },
        filterable: false,
        highlightMatches: false,
        perPage: 250,
        perPageValues: [25, 50, 100, 150, 250]
      }
    }
  },
  computed: {
    ...mapState([
      'identity',
      'company'
    ]),
    tenant () {
      return this.company.name.toLowerCase()
    },
    userList () {
      if (this.filterByRegion === 'All') {
        return this.users
      }

      const selectedRegion = this.filterByRegion.replace(' ', '')
      return this.users.filter((u) => { return selectedRegion === u.regionCode })
    },
    filteredUsers () {
      const input = this.searchQuery.toLowerCase()
      return this.userList.filter((a) => {
        return a.search.indexOf(input) !== -1
      })
    }
  },
  watch: {
    selectedBanner () { // comes from banner mixin
      this.getData()
    }
  },
  mounted () {
    this.$store.dispatch('setAdminHeaderTitle', 'Manage Instant Credit Permissions')
    if (!this.identity.permissions.viewUsers) {
      const tenant = this.identity.banner
      this.$router.push({ name: 'dashboard', tenant })
    }
  },
  methods: {
    async enablePermissions (val, userId, regionCode) {
      console.log('test checkbox click', val, userId, regionCode)

      const data = { userId, regionCode, permissionEnabled: val }

      await axios.post('/api/instantcreditpermissions/updateUser', data)
        .then((res) => {
          console.log('update user', res)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getData () {
      const bannerId = this.company.tenantId || this.identity.bannerId

      if (bannerId != null && this.identity.permissions.viewUsers === true) {
        const regionData = await axios
          .get('/api/instantcreditpermissions/getRegions', { params: { tenantId: bannerId } })
          .then((res) => {
            if (res.status === 200) {
              return res.data
            } else {
              return []
            }
          })
          .catch((err) => {
            console.error(err)
          })

        if (regionData != null) {
          console.log('regions', regionData)
          this.regions = regionData
        }

        const userData = await axios
          .get('/api/instantcreditpermissions/getUsers', { params: { tenantId: bannerId } })
          .then((res) => {
            if (res.status === 200) {
              return res.data
            } else {
              return []
            }
          })
          .catch((err) => {
            console.error(err)
          })

        if (regionData != null) {
          console.log('users', userData)
          this.users = userData
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-group {
      margin-bottom: 0;
  }
</style>
