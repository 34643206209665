const store = {
  namespaced: true,
  state: {
    hasAccount: null,
    isFormCompleted: false,
    createdFromInternal: null
  },
  getters: {
  },
  mutations: {
    HAS_ACCOUNT: function (state, value) {
      state.hasAccount = value
    },
    IS_FORM_COMPLETE: function (state, value) {
      state.isFormCompleted = value
    },
    WAS_CREATED_FROM_INTERNAL: function (state, value) {
      state.createdFromInternal = value
    }
  },
  actions: {
    hasAccount: function ({ commit }, req) {
      commit('HAS_ACCOUNT', req)
    },
    setIsFormCompleted: function ({ commit }, req) {
      commit('IS_FORM_COMPLETE', req)
    },
    setCreatedFromInternal: function ({ commit }, req) {
      commit('WAS_CREATED_FROM_INTERNAL', req)
    }
  }
}

export default store
