/**
 * Returns a function that determines if a given file matches another given file.
 * @param {File} fileToMatch
 * @return {function(String): void}
 */
export function fileMatches (fileToMatch) {
  return (file) => {
    return file.name === fileToMatch.name &&
      file.lastModified === fileToMatch.lastModified &&
      file.size === fileToMatch.size
  }
}
