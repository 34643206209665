var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('FormInputTextInput',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.principal1FirstName,"input-label":_vm.formLabels.principal1FirstName,"input-type":"text"},model:{value:(_vm.userFormInput.principal1FirstName),callback:function ($$v) {_vm.$set(_vm.userFormInput, "principal1FirstName", $$v)},expression:"userFormInput.principal1FirstName"}})],1),_c('div',{staticClass:"col-sm-4"},[_c('FormInputTextInput',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.principal1LastName,"input-label":_vm.formLabels.principal1LastName,"input-type":"text"},model:{value:(_vm.userFormInput.principal1LastName),callback:function ($$v) {_vm.$set(_vm.userFormInput, "principal1LastName", $$v)},expression:"userFormInput.principal1LastName"}})],1),_c('div',{staticClass:"col-sm-4"},[_c('FormInputTextInput',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.principal1Title,"input-label":_vm.formLabels.principal1Title,"input-type":"text"},model:{value:(_vm.userFormInput.principal1Title),callback:function ($$v) {_vm.$set(_vm.userFormInput, "principal1Title", $$v)},expression:"userFormInput.principal1Title"}})],1)]),_c('div',[_c('FormInputRadioOptions',{attrs:{"disabled":_vm.isStepDisabled,"input-label":_vm.formLabels.use2ndPrincipal,"default-value":"0","options":[
          {
            key: 'Yes',
            value: '1',
          },
          {
            key: 'No',
            value: '0',
          }
        ]},model:{value:(_vm.userFormInput.use2ndPrincipal),callback:function ($$v) {_vm.$set(_vm.userFormInput, "use2ndPrincipal", $$v)},expression:"userFormInput.use2ndPrincipal"}})],1),(_vm.userFormInput.use2ndPrincipal === '1')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('FormInputTextInput',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.principal2FirstName,"input-label":_vm.formLabels.principal2FirstName,"input-type":"text"},model:{value:(_vm.userFormInput.principal2FirstName),callback:function ($$v) {_vm.$set(_vm.userFormInput, "principal2FirstName", $$v)},expression:"userFormInput.principal2FirstName"}})],1),_c('div',{staticClass:"col-sm-4"},[_c('FormInputTextInput',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.principal2LastName,"input-label":_vm.formLabels.principal2LastName,"input-type":"text"},model:{value:(_vm.userFormInput.principal2LastName),callback:function ($$v) {_vm.$set(_vm.userFormInput, "principal2LastName", $$v)},expression:"userFormInput.principal2LastName"}})],1),_c('div',{staticClass:"col-sm-4"},[_c('FormInputTextInput',{attrs:{"disabled":_vm.isStepDisabled,"vuelidate":_vm.vuelidate.principal2Title,"input-label":_vm.formLabels.principal2Title,"input-type":"text"},model:{value:(_vm.userFormInput.principal2Title),callback:function ($$v) {_vm.$set(_vm.userFormInput, "principal2Title", $$v)},expression:"userFormInput.principal2Title"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }