<template>
  <v-container class="whats-new">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel
        v-for="(value, key) in groups"
        :key="value[0]"
        v-model="panel"
        flat
        hover
        :value="value[key]">
        <v-expansion-panel-header>{{ value[0] }}</v-expansion-panel-header>
        <v-expansion-panel-content
          class="">
          <v-card
            v-for="v in value[1]"
            :key="v.publishDate">
            <v-card-title class="">
              {{ v.headerText }}
            </v-card-title>
            <v-card-text class="">
              <ul>
                <li
                  v-for="item in v.items"
                  :key="item.id"
                  class="">
                  &cir; {{ item.note }}
                </li>
              </ul>
            </v-card-text>
            <hr />
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import axios from 'ca-http-service'
import _lodash from 'lodash'

export default {
  name: 'WhatsNew',
  data () {
    return {
      panel: 0,
      loading: true,
      groups: []
    }
  },
  created () {
    this.loading = true
    this.$store.dispatch('setAdminHeaderTitle', "What's New")
    axios
      .get(
        '/admin/getWhatsNew')
      .then(res => {
        const groupedByDateObj = _lodash.groupBy(res.data, (a) => { return new Date(a.publishDate).getFullYear() })
        const groupedObjToArray = _lodash.toPairs(groupedByDateObj)
        const sortedArray = _lodash.sortBy(groupedObjToArray, (val) => -val[0])
        this.groups = sortedArray
      })
      .catch(err => {
        this.groups = []
        console.error('err', err)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
<style scoped>
.v-expansion-panel-content__wrap {
  padding:0 !important;
}
</style>
