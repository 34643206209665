import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'identity',
      'company'
    ]),
    selectedBanner () {
      return this.company.tenantId || this.identity.bannerId
    }
  }
}
