<template>
  <div
    id="signatories-info-component"
    class="signatories-container">
    <div
      v-for="(signatory, i) in app.signatories"
      id="signatories-list"
      :key="i">
      <h5
        id="sectionTitle"
        class="section-title mb-3">
        Signatory Information <span v-if="app.signatories.length > 1">
          {{ i + 1 }}
        </span>
      </h5>
      <!-- signatory credit info -->
      <SignatoryCreditInfo :s="signatory"></SignatoryCreditInfo>
      <!-- signatory detail info-->
      <SignatoryInfo :s="signatory"></SignatoryInfo>
    </div>
  </div>
</template>

<script>
import SignatoryInfo from './SignatoryInfo.vue'
import SignatoryCreditInfo from './SignatoryCreditInfo.vue'

export default {
  name: 'CreditReviewSignatories',
  components: {
    SignatoryInfo,
    SignatoryCreditInfo
  },
  props: {
    app: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss">
#sectionTitle.section-title {
    margin-bottom: 1.5rem !important;
}
</style>
