import {
  processIdentity
} from '@/store/identity/identity-processes'
import Vue from 'vue'

export default {
  setIdentity (state, identity) {
    if (identity == null || Object.keys(identity).length === 0) {
      return null
    }

    const {
      sso,
      userId,
      winnt_id: winntId,
      eclipse_user_login: eclipseUserLogin,
      imp_email: impersonatingEmail,
      name,
      role,
      banner, // for 'res'/'rexelenergy' users, this value is set to 'rexel'. Is this expected?
      branch_id: branchId,
      email,
      given_name: firstName,
      family_name: lastName,
      picture
    } = identity // destructure specific identity properties used in app here

    // all users w/ an identity should have role, which should contain a list of json as strings describing each role available to the user.
    if (role == null) {
      return null
    }

    Vue.$ga.set('dimension1', identity.email || 'none') // dimension1 == "User" in Credit App Google Analytics settings.
    Vue.$ga.set('dimension2', identity.sso || 'none') // dimension2 == "Employee SSO"
    Vue.$ga.set('dimension3', identity.branchId || 'none') // dimension3 == "Branch"
    Vue.$ga.set('dimension4', identity.banner || 'none') // dimension4 == "Banner"

    // generate these values from identity values
    const {
      permissions,
      banners,
      bannerId
    } = processIdentity(role, banner, state.banners)

    let updatedBanner = banner
    // the user's "default banner" may not give permissions correctly
    if (banners.length && !banners.includes(banner)) {
      updatedBanner = banners[0]
    }

    const mappedObj = {
      sso,
      userId, // id from the original db, mainly in use in the Manage Users section
      winntId,
      eclipseUserLogin,
      impersonatingEmail,
      name,
      bannerId,
      banner: updatedBanner, // the user's default banner
      banners, // the banners that the user has credit application access with (must exist in the Manage Users list to have this, otherwise this array should only contain banner)
      branchId,
      displayName: name,
      email,
      firstName,
      lastName,
      permissions,
      picture
    }

    state.identity = mappedObj
  },
  setDefaultBranch (state, obj) {
    state.defaultBranch = obj
  },
  setAccessToken (state, token) {
    state.accessToken = token
  },
  setPlattForceUrl (state, data) {
    state.plattforceRootUrl = data
  },
  setAppConfig (state, data) {
    state.appConfig = data
  },
  setBanners (state, array) {
    state.banners = array
  },
  setBranches (state, array) {
    state.branches = array
  },

  // Updates state.company which should hold the User's currentBanner info in context of their view
  // TODO: state.company refers to bannerId as tenantId, this should be updated to be bannerId in the future
  setCurrentBanner (state, bannerName) {
    // HOLD: mapping to handle res/rexelenergy difference
    // // the name for 'rexelenergy' in this app is 'res' in identity
    // if (bannerName === 'res') {
    //   bannerName === 'rexelenergy'
    // }
    const banner = state.banners.find(b => b.name.toLowerCase() === bannerName.toLowerCase())

    if (banner != null) {
      state.company = banner
    }
  },
  setCountries (state, array) {
    state.countries = array
  },
  setFilterReasons (state, array) {
    state.filterReasons = array
  },
  setStatesUS (state, array) {
    state.statesUS = array
  },
  setCustomer (state, obj) {
    state.currentCustomer = obj
  },
  setUserRoles (state, obj) {
    state.userRoles = obj
  },
  setRegions (state, obj) {
    state.regions = obj
  },
  setAdminHeaderTitle (state, title) {
    document.title = title
    state.adminHeaderTitle = title
  },
  setDevControls (state, show) {
    state.devHelpers.showDevControls = show
  },
  setFormValidationHelper (state, show) {
    state.devHelpers.showDevFormValidationState = show
  },
  setOverwriteFormWithTestData (state, show) {
    state.devHelpers.overwriteFormWithTestData = show
  },
  setStepsVisible (state, array) {
    state.stepsVisible = array
  },
  setDashboardFormHistoryFilter (state, filter) {
    state.dashboardFormHistoryFilter = filter
  },
  setWebUser (state, webUser) {
    state.webUser = webUser
  },
  setCreditReviewFilters (state, filters) {
    state.creditReviewFilters = filters
  }
}
