<!--props
  tabs: [{
    title,
    tabId,
    display
  }]

-->

<!--slots
  body
  footer
-->

<!--@returnIndx(obj, index)
-->

<!--@closeModal
-->
<template>
  <div class="classic-modal">
    <div class="classic-modal-wrapper">
      <div class="classic-modal-header">
        <nav class="navbar">
          <ul class="navbar-nav">
            <li
              v-for="(tab, index) in displayTabs"
              :key="index"
              class="nav-item"
              :data-display="tab.display"
              :class="{'active' : tabIndex === index}"
              @click="changeTab(tab, index)">
              <a class="nav-link">
                {{ tab.title }}
              </a>
            </li>
          </ul>
          <a
            class="close-classic-modal"
            @click="close">
            <i class="fa fa-times"></i>
          </a>
        </nav>
      </div>
      <div class="classic-modal-body">
        <slot name="body"></slot>
      </div>
      <div class="classic-modal-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassicModal',
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      tabIndex: 0
    }
  },
  computed: {
    displayTabs () {
      return this.tabs.filter(x => x.display === true)
    }
  },
  methods: {
    changeTab: function (tab, value) {
      this.tabIndex = value
      this.$emit('returnIndex', tab, value)
    },
    close: function () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
div.classic-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  display: flex;
  background-color: rgba(0, 0, 0, .25);
  justify-content: center;
  align-items: center;
  animation: classicModal 300ms ease forwards;
  -webkit-backface-visibility: hidden;
  @keyframes classicModal {
    from {
      background-color: rgba(0, 0, 0, .01);
    }
    to {
      background-color: rgba(0, 0, 0, .25);
    }
  }
  &-wrapper {
    width: calc(100% - 1rem);
    max-width: 48rem;
    position: relative;
    overflow: hidden;
    border-radius: 0.375rem;
    background-color: #fff;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, .5);
    transform: scale3d(.7,.7,1);
    animation: classicWrapper 200ms linear forwards;
    animation-timing-function: cubic-bezier(.19,.67,.04,.99);
    @keyframes classicWrapper {
      from {
        transform: scale3d(.7,.7,1)
      }
      to {
        transform: scale3d(1,1,1)
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #999;

      font-size: 1.5em;
      font-weight: 400;
    }
    p {
      font-size: .9em;
    }
    .input-group {
      margin-bottom: 1rem;
    }
    .checkbox-group {
      display: flex;
      .input-group {
        padding: 0 0.3125rem 0 0;

        flex: 1;
      }
    }
  }
  &-header {
    .navbar {
      width: 100%;
      flex-direction: row;
      padding: 0;
      overflow: hidden;
      background-color: #004168;
      .close-classic-modal {
        width: 3.125rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 200ms;
        background-color: darken(#004168, 10%);

        &:hover {
          cursor: pointer;
          i {
            color: #ff0000;
          }
        }

        i {
          font-size: 1.5em;
          color: #fff;
          transition: all 200ms;
        }
      }
      .navbar-nav {
        width: calc(100% - 3.125rem);
        flex-direction: row;

        justify-content: space-between;
        .nav-item {
          padding: .5rem 1rem;
          transition: all 200ms;
          color: #fff;
          border-right: 0.0625rem solid #9ba3a5;
          text-transform: capitalize;
          text-align: center;
          font-weight: 600;

          flex: 1;
          &:hover,
          &.active {
            border: none;
            box-shadow: 0 0 0.625rem rgba(0, 0, 0, .25);
            cursor: pointer;
          }
          &:last-of-type {
            border: none;
          }

          &:hover {
            background-color: darken(#004168, 10%);
            color: #fff;
          }

          &.active {
            background-color: #fff;
            color: #004168;

            &:hover {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  &-body {
    min-height: 7.5rem;
    padding: 1rem;

    .tab,
    {
      display: none;

      &.active,
      &:first-of-type {
        display: block;
      }
    }
  }
  &-footer {
    padding: 1rem;
    text-align: center;
    .btn,
    .link {
      margin: 0px 1rem;
      display: inline-block;

      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
    .btn {
      min-width: 12.5rem;
      color: #fff;

      &-danger {
        color: #fff !important;
      }
    }
    .link {
      text-decoration: underline;
    }
  }
}
</style>
