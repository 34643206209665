<template>
  <fieldset>
    <FormSectionCard
      v-if="renderStep"
      :title="signatoryTitle + 1">
      <v-row
        no-gutters
        class="mt-n3 pb-3">
        <div
          v-if="!rexelEnergy"
          class="signatory-note">
          A Business requires at least one {{ signatoryTitle }} (two if it is a Partnership), and two {{ signatoriesTitle }} maximum before going forward.
        </div>
      </v-row>
      <v-row
        v-if="guarantorRequested && !guarantorFilled"
        no-gutters
        class="pb-3">
        <v-col cols="12">
          <div class="guarantor-request">
            One or more signatories must sign personal guaranty.
          </div>
        </v-col>
      </v-row>
      <!-- Maximum of 2 -->
      <v-row>
        <v-col
          cols="12"
          lg="4"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatory.firstName"
            persist-label
            :vuelidate="validations.signatories.signatory.firstName"
            :input-label="signatories.labels.firstName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatory.middleInitial"
            persist-label
            :vuelidate="validations.signatories.signatory.middleInitial"
            :input-label="signatories.labels.middleInitial"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatory.lastName"
            persist-label
            :vuelidate="validations.signatories.signatory.lastName"
            :input-label="signatories.labels.lastName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatory.suffix"
            persist-label
            :vuelidate="validations.signatories.signatory.suffix"
            :input-label="signatories.labels.suffix"
            input-type="text"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="2"
          class="pb-4">
          <FormInputRadioOptions
            v-if="signatoriesInput.signatory.guarantor !== 'true'"
            v-model="signatoriesInput.signatory.isSsnOrEin"
            form-group-styles="margin-bottom: 0 !important;"
            :vuelidate="validations.signatories.signatory.isSsnOrEin"
            :input-label="signatories.labels.isSsnOrEin"
            :options="[
              {
                key: 'SSN',
                value: 'ssn',
              },
              {
                key: 'EIN',
                value: 'ein',
              }
            ]"></FormInputRadioOptions>
          <div
            v-if="signatoriesInput.signatory.guarantor === 'true'"
            class="pr-0">
            Social Security Number:
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="pb-2 pr-2">
          <FormTextInput
            v-if="signatoriesInput.signatory.isSsnOrEin === 'ssn'"
            v-model="signatoriesInput.signatory.ssn"
            persist-label
            :vuelidate="validations.signatories.signatory.ssn"
            :input-label="signatories.labels.ssn"
            input-type="ssn"></FormTextInput>
          <FormTextInput
            v-else
            v-model="signatoriesInput.signatory.ssn"
            persist-label
            :vuelidate="validations.signatories.signatory.ssn"
            :input-label="signatories.labels.ssn"
            input-type="ein"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="pb-4">
          <FormInputDropdownContactTitles
            v-if="isRexelOrGexpro()"
            v-model="signatoriesInput.signatory.jobTitle"
            default-value="Owner/President"
            :vuelidate="validations.signatories.signatory.jobTitle"
            :input-label="signatories.labels.jobTitle"
            :inline="true"
            :form-data="signatoriesInput.signatory"
            :field-names="{
            jobTitle: 'jobTitle'
          }"></FormInputDropdownContactTitles>
          <FormTextInput
            v-else
            v-model="signatoriesInput.signatory.jobTitle"
            persist-label
            :vuelidate="validations.signatories.signatory.jobTitle"
            :input-label="signatories.labels.jobTitle"
            input-type="text">
            </FormTextInput>
        </v-col>
      </v-row>
      <v-row class="pb-1">
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatory.email"
            persist-label
            :vuelidate="validations.signatories.signatory.email"
            :input-label="signatories.labels.email"
            input-type="email"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatory.phone"
            persist-label
            :vuelidate="validations.signatories.signatory.phone"
            :input-label="signatories.labels.phone"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatory.phoneExt"
            persist-label
            :vuelidate="validations.signatories.signatory.phoneExt"
            :input-label="signatories.labels.phoneExt"></FormTextInput>
        </v-col>
      </v-row>
      <FormGroupAddressInfo
        v-model="signatoriesInput.signatory"
        persist-label
        :vuelidate="validations.signatories.signatory"
        :form-labels="signatories.labels"
        :form-data="signatories.data.signatory">
        <template v-slot:AddressDropDown>
          <FormDropdown
            v-model="signatoriesInput.signatory.country"
            persist-label
            :vuelidate="validations.signatories.signatory.country"
            :input-label="signatories.labels.country"
            :options="$store.getters.countries"></FormDropdown>
        </template>
      </FormGroupAddressInfo>
      <v-row no-gutters>
        <v-col cols="12">
          Would you like to sign an optional personal guaranty?
          <FormInputRadioOptions
            v-model="signatoriesInput.signatory.guarantor"
            form-group-styles="margin-bottom: 0 !important;"
            :vuelidate="validations.signatories.signatory.guarantor"
            :input-label="signatories.labels.guarantor"
            :default-value="''"
            :options="[
              {
                key: 'Yes',
                value: 'true',
              },
              {
                key: 'No',
                value: '',
              }
            ]"></FormInputRadioOptions>
        </v-col>
      </v-row>
      <div class="button-row">
        <v-btn
          v-show="!multipleSignatories"
          tile
          @click="onAddCb">
          Add 2nd {{ signatoryTitle }}
        </v-btn>
      </div>
    </FormSectionCard>
    <FormSectionCard
      v-if="multipleSignatories"
      :title="signatoryTitle + 2">
      <v-row
        no-gutters
        class="mt-n3 pb-3">
        <div class="signatory-note">
          A Business requires at least one {{ signatoryTitle }} (two if it is a Partnership), and two {{ signatoriesTitle }} maximum before going forward.
        </div>
      </v-row>
      <v-row
        v-if="guarantorRequested && !guarantorFilled"
        no-gutters
        class="pb-3">
        <v-col cols="12">
          <div class="guarantor-request">
            One or more signatories must sign personal guaranty.
          </div>
        </v-col>
      </v-row>
      <!-- Maximum of 2 -->
      <v-row>
        <v-col
          cols="12"
          lg="4"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.firstName"
            persist-label
            :vuelidate="validations.signatoryTwo.firstName"
            :input-label="signatoryTwo.labels.firstName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.middleInitial"
            persist-label
            :vuelidate="validations.signatoryTwo.middleInitial"
            :input-label="signatoryTwo.labels.middleInitial"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.lastName"
            persist-label
            :vuelidate="validations.signatoryTwo.lastName"
            :input-label="signatoryTwo.labels.lastName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.suffix"
            persist-label
            :vuelidate="validations.signatoryTwo.suffix"
            :input-label="signatoryTwo.labels.suffix"
            input-type="text"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="2"
          class="pb-4">
          <FormInputRadioOptions
            v-if="signatoriesInput.signatoryTwo.guarantor !== 'true'"
            v-model="signatoriesInput.signatoryTwo.isSsnOrEin"
            form-group-styles="margin-bottom: 0 !important;"
            :vuelidate="validations.signatoryTwo.isSsnOrEin"
            :input-label="signatoryTwo.labels.isSsnOrEin"
            :options="[
              {
                key: 'SSN',
                value: 'ssn',
              },
              {
                key: 'EIN',
                value: 'ein',
              }
            ]"></FormInputRadioOptions>
          <div
            v-if="signatoriesInput.signatoryTwo.guarantor === 'true'"
            class="pr-0">
            Social Security Number:
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="pb-2 pr-2">
          <FormTextInput
            v-if="signatoriesInput.signatoryTwo.isSsnOrEin === 'ssn'"
            v-model="signatoriesInput.signatoryTwo.ssn"
            persist-label
            :vuelidate="validations.signatoryTwo.ssn"
            :input-label="signatoryTwo.labels.ssn"
            input-type="ssn"></FormTextInput>
          <FormTextInput
            v-else
            v-model="signatoriesInput.signatoryTwo.ssn"
            persist-label
            :vuelidate="validations.signatoryTwo.ssn"
            :input-label="signatoryTwo.labels.ssn"
            input-type="ein"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.jobTitle"
            persist-label
            :vuelidate="validations.signatoryTwo.jobTitle"
            :input-label="signatoryTwo.labels.jobTitle"
            input-type="text"></FormTextInput>
        </v-col>
      </v-row>
      <v-row class="pb-1">
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.email"
            persist-label
            :vuelidate="validations.signatoryTwo.email"
            :input-label="signatoryTwo.labels.email"
            input-type="email"></FormTextInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.phone"
            persist-label
            :vuelidate="validations.signatoryTwo.phone"
            :input-label="signatoryTwo.labels.phone"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="pb-4">
          <FormTextInput
            v-model="signatoriesInput.signatoryTwo.phoneExt"
            persist-label
            :vuelidate="validations.signatoryTwo.phoneExt"
            :input-label="signatoryTwo.labels.phoneExt"></FormTextInput>
        </v-col>
      </v-row>
      <FormGroupAddressInfo
        v-model="signatoriesInput.signatoryTwo"
        persist-label
        :vuelidate="validations.signatoryTwo"
        :form-labels="signatoryTwo.labels"
        :form-data="signatoryTwo.data">
        <template v-slot:AddressDropDown>
          <FormDropdown
            v-model="signatoriesInput.signatoryTwo.country"
            persist-label
            :vuelidate="validations.signatoryTwo.country"
            :input-label="signatoryTwo.labels.country"
            :options="$store.getters.countries"></FormDropdown>
        </template>
      </FormGroupAddressInfo>
      <v-row no-gutters>
        <v-col cols="12">
          Would you like to sign an optional personal guaranty?
          <FormInputRadioOptions
            v-model="signatoriesInput.signatoryTwo.guarantor"
            form-group-styles="margin-bottom: 0 !important;"
            :vuelidate="validations.signatoryTwo.guarantor"
            :input-label="signatoryTwo.labels.guarantor"
            :default-value="''"
            :options="[
              {
                key: 'Yes',
                value: 'true',
              },
              {
                key: 'No',
                value: '',
              }
            ]"></FormInputRadioOptions>
        </v-col>
      </v-row>
      <div class="button-row">
        <v-btn
          tile
          class="remove"
          @click="removeCB">
          Remove 2nd {{ signatoryTitle }}
        </v-btn>
      </div>
      <v-row
        v-if="isSameEmail"
        no-gutters
        class="pt-3">
        <v-col cols="12">
          <div class="same-email">
            *Cannot use same email address for both signatories
          </div>
        </v-col>
      </v-row>
    </FormSectionCard>
    <PersonalGuaranteeModal
      :dialog.sync="openGuaranteeModal"
      :signatory="selectedSignatory"
      @cancel="cancelGuarantee"
      @ssnCheck="ssnCheck">
    </PersonalGuaranteeModal>
  </fieldset>
</template>

<script>
import TenantsMixin from 'mixins/tenants.mixin'
import FormSectionCard from '@/components/form/FormSectionCard.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptionsV2.vue'
import FormGroupAddressInfo from '@/components/form/FormGroupAddressInfo.vue'
import FormDropdown from '@/components/form/FormDropdown.vue'
import FormInputDropdownContactTitles from '@/components/form/FormInputDropdownContactTitles.vue'
import FormItemSignatory from '@/pages/application/formItems/FormItemSignatory.vue'
import PersonalGuaranteeModal from '@/components/PersonalGuaranteeModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Step4Signatory',
  components: {
    FormInputDropdownContactTitles,
    FormSectionCard,
    FormTextInput,
    FormGroupAddressInfo,
    FormInputRadioOptions,
    FormDropdown,
    PersonalGuaranteeModal
  },
  mixins: [
    TenantsMixin
  ],
  props: {
    groupData: {
      type: Object,
      required: true
    },
    allData: {
      type: Array,
      required: false,
      default: {} // eslint-disable-line vue/require-valid-default-prop
    },
    validations: {
      type: Object,
      required: true
    },
    currentStep: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      signatoriesInput: {
        signatories: [],
        signatory: new this.groupData.signatories.dataClass(), // eslint-disable-line new-cap
        signatoryTwo: new this.groupData.signatoryTwo.dataClass() // eslint-disable-line new-cap
      },
      signatories: this.groupData.signatories,
      signatoryTwo: this.groupData.signatoryTwo,
      formItemSignatory: FormItemSignatory,
      businessInfo: this.allData[0].sectionData.businessInfo.data,
      billingInfo: this.allData[0].sectionData.billingInfo.data,
      multipleSignatories: false,
      renderStep: false,
      openGuaranteeModal: false,
      selectedSignatory: ''
    }
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    rexelEnergy () {
      return this.company === 'RexelEnergy' || this.$route.params.tenant === 'rexelenergy'
    },
    verbiageObject () {
      if (this.rexelEnergy) {
        return {
          signatories: {
            ...this.signatories.labels,
            streetAddress: 'Street Address'
          }
        }
      } else {
        return {
          ...this.signatories.labels
        }
      }
    },
    signatoryTitle () {
      if (this.rexelEnergy) {
        return 'Owner/Officer '
      } else {
        return 'Signatory '
      }
    },
    signatoriesTitle () {
      if (this.rexelEnergy) {
        return 'Owners/Officers '
      } else {
        return 'Signatories '
      }
    },
    buttonText () {
      if (this.signatoriesInput.signatories.length > 0) {
        return 'Add 2nd Signatory'
      } else {
        return 'Add Signatory'
      }
    },
    guarantorRequested () {
      return this.$route.name === 'guarantor'
    },
    guarantorFilled () {
      const signatoryOneSignedGuaranty = this.signatoriesInput.signatory.guarantor === 'true'
      const signatoryTwoSignedGuaranty = this.signatoriesInput.signatoryTwo.guarantor === 'true'
      return signatoryOneSignedGuaranty || signatoryTwoSignedGuaranty
    },
    isSameEmail () {
      if (this.signatoriesInput.signatory.email) {
        return this.signatoriesInput.signatory.email === this.signatoriesInput.signatoryTwo.email
      } else {
        return false
      }
    }
  },
  watch: {
    currentStep: function (newVal, oldVal) {
      if (newVal === 4) {
        this.setDefaultFormValues()
      }
    },
    'signatoriesInput.signatory.guarantor': {
      handler (newVal) {
        if (newVal === 'true') {
          this.openGuaranteeModal = true
          this.selectedSignatory = 'signatoryOne'
        }
      }
    },
    'signatoriesInput.signatoryTwo.guarantor': {
      handler (newVal) {
        if (newVal === 'true') {
          this.openGuaranteeModal = true
          this.selectedSignatory = 'signatoryTwo'
        }
      }
    }
  },
  async created () {
    if (this.signatories.data.signatories.length > 0) {
      this.signatories.data.signatory = this.signatories.data.signatories[0]
    }
    if (this.signatories.data.signatories.length > 1) {
      this.signatoryTwo.data = this.signatories.data.signatories[1]
    }

    this.signatoriesInput.signatories = this.signatories.data.signatories
    this.signatoriesInput.signatory = this.signatories.data.signatory
    this.signatoriesInput.signatoryTwo = this.signatoryTwo.data

    if (this.signatoriesInput.signatories.length === 0) {
      this.signatoriesInput.signatories.push(this.signatoriesInput.signatory)
    } else {
      this.signatoriesInput.signatory = this.signatoriesInput.signatories[0]
      if (this.signatoriesInput.signatories[1]) {
        this.signatoriesInput.signatoryTwo = this.signatoriesInput.signatories[1]
      }
    }
    this.setDefaultFormValues()
    this.$nextTick().then(() => {
      setTimeout(() => {
        this.renderStep = true
      }, 1000)
    })
  },
  methods: {
    setDefaultFormValues () {
      // set some defaults if it is an empty form
      if (this.signatoriesInput.signatory.isSsnOrEin.length === 0) {
        this.signatoriesInput.signatory.isSsnOrEin = 'ssn'
      }
      if (this.signatoriesInput.signatories.length === 0) {
        this.signatoriesInput.signatory.phone = this.businessInfo.phone
        this.signatoriesInput.signatory.country = this.billingInfo.country
        this.signatoriesInput.signatory.streetAddress = this.billingInfo.streetAddress
        this.signatoriesInput.signatory.city = this.billingInfo.city
        this.signatoriesInput.signatory.state = this.billingInfo.state
        this.signatoriesInput.signatory.postalCode = this.billingInfo.postalCode
      }
      if (this.signatoriesInput.signatories.length > 1) {
        this.multipleSignatories = true
        this.signatoriesInput.signatories[0].hasMultipleSignatories = this.multipleSignatories
      }
    },
    ssnCheck () {
      if (this.signatoriesInput.signatory.guarantor === 'true' && this.signatoriesInput.signatory.isSsnOrEin !== 'ssn') {
        this.signatoriesInput.signatory.isSsnOrEin = 'ssn'
        this.$nextTick(() => {
          this.signatoriesInput.signatory.ssn = ''
          this.validations.signatories.signatory.ssn.$touch()
        })
      }

      if (this.signatoriesInput.signatoryTwo.guarantor === 'true' && this.signatoriesInput.signatoryTwo.isSsnOrEin !== 'ssn') {
        this.signatoriesInput.signatoryTwo.isSsnOrEin = 'ssn'
        this.$nextTick(() => {
          this.signatoriesInput.signatoryTwo.ssn = ''
          this.validations.signatories.signatoryTwo.ssn.$touch()
        })
      }
    },
    onAddCb () {
      this.signatoriesInput.signatories.push(this.signatoriesInput.signatoryTwo)
      this.signatories.data.signatories = this.signatoriesInput.signatories

      this.multipleSignatories = true
      this.signatoriesInput.signatories[0].hasMultipleSignatories = this.multipleSignatories

      this.validations.signatories.signatory.$touch()
      Object.keys(this.signatoriesInput.signatoryTwo).forEach(key => this.signatoriesInput.signatoryTwo[key] = '') // eslint-disable-line no-return-assign
      this.signatoriesInput.signatoryTwo.isSsnOrEin = 'ssn'
      this.validations.signatoryTwo.$reset()
    },
    removeCB () {
      this.multipleSignatories = false
      this.signatoriesInput.signatories[0].hasMultipleSignatories = this.multipleSignatories
      this.signatoriesInput.signatories.pop()
    },
    cancelGuarantee (signatory) {
      if (signatory === 'signatoryOne') {
        this.signatoriesInput.signatory.guarantor = ''
      } else {
        this.signatoriesInput.signatoryTwo.guarantor = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.signatory-note {
  color: #88CBF5;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 35px;
}
.guarantor-request, .same-email {
  color: #dc1616;
}
.button-row {
  display: flex;
  flex-flow: row-reverse;
  padding-top: 8px;
}
.v-btn {
  color: #fff !important;
  background-color: #004168 !important;
  font-size: 1rem !important;
  letter-spacing: normal !important;
  border-color: #004168;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: .5rem 1rem;
  transition: all .2s ease-in-out;
  &.remove  {
    margin-right: 10px;
    color: white !important;
    background-color: #dc1616 !important;
  }
  &:hover{
    background-color: #002135 !important;
    border-color: #001b2b;
  }
}
</style>
