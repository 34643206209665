export default {
  data () {
    return {
      dialogIsOpen: false,
      contactData: {}
    }
  },
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {

  },
  methods: {
    open () {
      this.dialogIsOpen = true
    }
  }
}
