<template>
  <div
    id="company-info-component"
    class="component-container">
    <div
      v-if="app.erpEntityInfo"
      id="company-info-details-erpcompare"
      class="container">
      <div class="row">
        <div class="card col card-alt">
          <div class="card-block">
            <div class="row">
              <div class="col col-reduced">
                <div class="form-group section-account-num">
                  <label>Account #:</label>
                  <p>
                    <span>{{ app.businessInfo.accountNumber }}</span>
                    <button
                      v-if="canEdit && isAppPending()"
                      type="button"
                      class="btn btn-sm btn-link"
                      @click="editCompanyInfo()">
                      <i
                        v-if="app.businessInfo.accountNumber"
                        class="fa fa-pencil"></i>
                      <i
                        v-else
                        class="fa fa-plus-square"></i>
                    </button>
                  </p>
                </div>
              </div>
              <div class="col col-reduced">
                <div
                  v-if="app.billingInfo.federalTaxId"
                  class="form-group section-tax-id">
                  <label>Tax ID:</label>
                  <p>{{ app.billingInfo.federalTaxId }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-block">
            <div class="row">
              <div class="col col-reduced">
                <div class="form-group section-account-num">
                  <label>Has Any Guaranty:</label>
                  <p v-if="app.hasGuaranty">
                    Yes
                  </p>
                  <p v-else>
                    No
                  </p>
                </div>
              </div>
              <div class="col col-reduced">
                <div
                  v-if="app.creditAmount"
                  class="form-group section-tax-id">
                  <label>Credit Line:</label>
                  <h5>{{ app.creditAmount | currency }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col">
          <div class="section-check">
            <div class="section-use-credit-app">
              <span v-if="model.allowAnySelect">
                <input
                  v-model="model.useAllFromCreditApp"
                  type="checkbox"
                  class="form-check-input use-all-checkbox" />
              </span>
              <span
                v-else
                class="section-form-no-check">
&nbsp;
              </span>
              <span class="section-title">
                Submitted on Credit Application:
              </span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="section-check">
            <div class="section-use-erp">
              <span v-if="app.erpEntityInfo && app.erpEntityInfo.eclipseErrorDescription">
                <span class="section-title">
                  In Eclipse:
                </span>
                <div class="text-muted">
                  {{ app.erpEntityInfo.eclipseErrorDescription }}
                </div>
              </span>
              <div v-else>
                <span v-if="model.allowAnySelect">
                  <input
                    v-model="model.useAllFromErp"
                    type="checkbox"
                    class="form-check-input use-all-checkbox" />
                </span>
                <span
                  v-else
                  class="section-form-no-check">
&nbsp;
                </span>
                <span class="section-title">
                  In Eclipse:
                </span>
                <span class="text-muted">
                  <em>(matched on account #)</em>
                </span>
                <span
                  v-if="nonMatchItems()"
                  class="sm-note">
                  <span
                    v-if="isAppPending()"
                    class="sm-nomatch-indicator">
                    <i class="fa fa-circle"></i>
                  </span>
                  <span
                    v-else
                    class="sm-senttoerp-indicator">
                    <i class="fa fa-check"></i>
                  </span>
                  <span class="sm-note-text">
                    <span v-if="isAppPending()">
                      Does not match
                    </span>
                    <span v-else>
                      Sent to Eclipse
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          id="incompleteBusinessName"
          :class="{ card: true, col: true, 'missing-data-border': model.isIncompleteBusinessName }">
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBusinessNameSelect">
                <input
                  v-model="model.useCreditAppBusinessName"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteBusinessName }">
                  Business Name:
                </span>
              </label>

              <span
                v-if="model.allowBusinessNameSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <span
                v-if="model.sentBusinessNameToErp"
                class="sm-senttoerp-indicator">
                <i class="fa fa-check"></i>
              </span>
              <p>
                <span :class="styleCreditAppBusinessName">
                  {{ app.businessInfo.companyName }}
                </span>
              </p>
            </div>
            <div
              v-if="app.billingInfo.contractorLicenceNumber"
              class="form-group">
              <label><span :class="{ 'form-no-check': true, 'missing-data-text': model.isIncompleteBusinessName }">
                Contractor License #:
              </span></label>
              <p>
                <span :class="{ 'form-no-check': true, 'missing-data-text': model.isIncompleteBusinessName }">
                  {{ app.billingInfo.contractorLicenceNumber }}
                </span>
              </p>
            </div>
            <div
              class="form-group">
              <label><span>
                Preferred Sales Person:
              </span></label>
              <p>
                <span>
                  {{ app.billingInfo.preferredSalesPerson }}
                </span>
              </p>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBusinessNameSelect">
                <input
                  v-model="model.useErpBusinessName"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteBusinessName }">
                  Business Name:
                </span>
                <span v-if="model.sentBusinessNameToErp">
                  <em> (previous)</em>
                </span>
              </label>
              <span
                v-if="model.allowBusinessNameSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <p>
                <span :class="styleErpBusinessName">
                  {{ app.erpEntityInfo.businessName }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          id="incompleteBusinessEmail"
          :class="{ card: true, col: true, 'card-alt': true, 'missing-data-border': model.isIncompleteBusinessEmail }">
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBusinessEmailSelect">
                <input
                  v-model="model.useCreditAppBusinessEmail"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label><span :class="{ 'missing-data-text': model.isIncompleteBusinessEmail }">
                Business Email:
              </span></label>
              <span
                v-if="model.allowBusinessEmailSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <span
                v-if="model.sentBusinessEmailToErp"
                class="sm-senttoerp-indicator">
                <i class="fa fa-check"></i>
              </span>
              <p>
                <span :class="styleCreditAppBusinessEmail">
                  {{ app.businessInfo.email }}
                </span>
              </p>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBusinessEmailSelect">
                <input
                  v-model="model.useErpBusinessEmail"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteBusinessEmail }">
                  Business Email:
                </span>
                <span v-if="model.sentBusinessEmailToErp">
                  <em> (previous)</em>
                </span>
              </label>
              <span
                v-if="model.allowBusinessEmailSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <p>
                <span :class="styleErpBusinessEmail">
                  {{ app.erpEntityInfo.businessEmail }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          id="incompleteBusinessPhone"
          :class="{ card: true, col: true, 'missing-data-border': model.isIncompleteBusinessPhone }">
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBusinessPhoneSelect">
                <input
                  v-model="model.useCreditAppBusinessPhone"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label><span :class="{ 'missing-data-text': model.isIncompleteBusinessPhone }">
                Business Phone:
              </span></label>
              <span
                v-if="model.allowBusinessPhoneSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <span
                v-if="model.sentBusinessPhoneToErp"
                class="sm-senttoerp-indicator">
                <i class="fa fa-check"></i>
              </span>
              <p>
                <span :class="styleCreditAppBusinessPhone">
                  {{ app.businessInfo.phone }}
                </span>
              </p>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBusinessPhoneSelect">
                <input
                  v-model="model.useErpBusinessPhone"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteBusinessPhone }">
                  Business Phone:
                </span>
                <span v-if="model.sentBusinessPhoneToErp">
                  <em> (previous)</em>
                </span>
              </label>
              <span
                v-if="model.allowBusinessPhoneSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <p>
                <span :class="styleErpBusinessPhone">
                  {{ app.erpEntityInfo.businessPhone }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          id="incompleteBillingAddress"
          :class="{ card: true, col: true, 'card-alt': true, 'missing-data-border': model.isIncompleteBillingAddress }">
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBillingAddressSelect">
                <input
                  v-model="model.useCreditAppBillingAddress"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label><span :class="{ 'missing-data-text': model.isIncompleteBillingAddress }">
                Billing Address:
              </span></label>
              <span
                v-if="model.allowBillingAddressSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <span
                v-if="model.sentBillingAddressToErp"
                class="sm-senttoerp-indicator">
                <i class="fa fa-check"></i>
              </span>
              <div :class="styleCreditAppBillingAddress">
                <p>{{ app.billingInfo.billingAddress.streetAddress }}</p>
                <p v-if="app.billingInfo.billingAddress.addressUnitNumber && app.billingInfo.billingAddress.addressUnitNumber != ''">
                  Unit: {{ app.billingInfo.billingAddress.addressUnitNumber }}
                </p>
                <p>{{ app.billingInfo.billingAddress.city }}, {{ app.billingInfo.billingAddress.state }} {{ app.billingInfo.billingAddress.postalCode }}</p>
                <p>{{ app.billingInfo.billingAddress.country }}</p>
              </div>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowBillingAddressSelect">
                <input
                  v-model="model.useErpBillingAddress"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteBillingAddress }">
                  Billing Address:
                </span>
                <span v-if="model.sentBillingAddressToErp">
                  <em> (previous)</em>
                </span>
              </label>
              <span
                v-if="model.allowBillingAddressSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <div
                v-if="app.erpEntityInfo.billingAddress"
                :class="styleErpBillingAddress">
                <p v-if="app.erpEntityInfo.billingAddress.streetLineOne">
                  {{ app.erpEntityInfo.billingAddress.streetLineOne }}
                </p>
                <p v-if="app.erpEntityInfo.billingAddress.streetLineTwo">
                  {{ app.erpEntityInfo.billingAddress.streetLineTwo }}
                </p>
                <p v-if="app.erpEntityInfo.billingAddress.streetLineThree">
                  {{ app.erpEntityInfo.billingAddress.streetLineThree }}
                </p>
                <p>{{ app.erpEntityInfo.billingAddress.city }}, {{ app.erpEntityInfo.billingAddress.state }} {{ app.erpEntityInfo.billingAddress.postalCode }}</p>
                <p>{{ app.erpEntityInfo.billingAddress.country }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          id="incompleteShippingAddress"
          :class="{ card: true, col: true, 'card-alt': true, 'missing-data-border': model.isIncompleteShippingAddress }">
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowShippingAddressSelect">
                <input
                  v-model="model.useCreditAppShippingAddress"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label><span :class="{ 'missing-data-text': model.isIncompleteShippingAddress }">
                Shipping Address:
              </span></label>
              <span
                v-if="model.allowShippingAddressSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <span
                v-if="model.sentShippingAddressToErp"
                class="sm-senttoerp-indicator">
                <i class="fa fa-check"></i>
              </span>
              <div :class="styleCreditAppShippingAddress">
                <p>{{ app.billingInfo.shippingAddress.streetAddress }}</p>
                <p v-if="app.billingInfo.shippingAddress.addressUnitNumber && app.billingInfo.shippingAddress.addressUnitNumber != ''">
                  Unit: {{ app.billingInfo.shippingAddress.addressUnitNumber }}
                </p>
                <p>{{ app.billingInfo.shippingAddress.city }}, {{ app.billingInfo.shippingAddress.state }} {{ app.billingInfo.shippingAddress.postalCode }}</p>
                <p>{{ app.billingInfo.shippingAddress.country }}</p>
              </div>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowShippingAddressSelect">
                <input
                  v-model="model.useErpShippingAddress"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteShippingAddress }">
                  Shipping Address:
                </span>
                <span v-if="model.sentShippingAddressToErp">
                  <em> (previous)</em>
                </span>
              </label>
              <span
                v-if="model.allowShippingAddressSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <div
                v-if="app.erpEntityInfo.shippingAddress"
                :class="styleErpShippingAddress">
                <p v-if="app.erpEntityInfo.shippingAddress.streetLineOne">
                  {{ app.erpEntityInfo.shippingAddress.streetLineOne }}
                </p>
                <p v-if="app.erpEntityInfo.shippingAddress.streetLineTwo">
                  {{ app.erpEntityInfo.shippingAddress.streetLineTwo }}
                </p>
                <p v-if="app.erpEntityInfo.shippingAddress.streetLineThree">
                  {{ app.erpEntityInfo.shippingAddress.streetLineThree }}
                </p>
                <p>{{ app.erpEntityInfo.shippingAddress.city }}, {{ app.erpEntityInfo.shippingAddress.state }} {{ app.erpEntityInfo.shippingAddress.postalCode }}</p>
                <p>{{ app.erpEntityInfo.shippingAddress.country }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          id="incompleteApContactName"
          :class="{ card: true, col: true, 'missing-data-border': model.isIncompleteApContactName }">
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowApContactNameSelect">
                <input
                  v-model="model.useCreditAppApContactName"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label><span :class="{ 'missing-data-text': model.isIncompleteApContactName }">
                Accounts Payable Contact:
              </span></label>
              <span
                v-if="model.allowApContactNameSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <span
                v-if="model.sentApContactNameToErp"
                class="sm-senttoerp-indicator">
                <i class="fa fa-check"></i>
              </span>
              <p>
                <span :class="styleCreditAppApContactName">
                  {{ app.accountPayable.firstName }} {{ app.accountPayable.lastName }}
                </span>
              </p>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowApContactNameSelect">
                <input
                  v-model="model.useErpApContactName"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteApContactName }">
                  Accounts Payable Contact:
                </span>
                <span v-if="model.sentApContactNameToErp">
                  <em> (previous)</em>
                </span>
              </label>
              <span
                v-if="model.allowApContactNameSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <p>
                <span :class="styleErpApContactName">
                  {{ app.erpEntityInfo.acctsPayableContactName }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          id="incompleteApContactPhone"
          :class="{ card: true, col: true, 'card-alt': true, 'missing-data-border': model.isIncompleteApContactPhone }">
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowApContactPhoneSelect">
                <input
                  v-model="model.useCreditAppApContactPhone"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label><span :class="{ 'missing-data-text': model.isIncompleteApContactPhone }">
                Accounts Payable Phone:
              </span></label>
              <span
                v-if="model.allowApContactPhoneSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <span
                v-if="model.sentApContactPhoneToErp"
                class="sm-senttoerp-indicator">
                <i class="fa fa-check"></i>
              </span>
              <p>
                <span :class="styleCreditAppApContactPhone">
                  {{ app.accountPayable.phone }}
                </span>
              </p>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group-2">
              <span v-if="model.allowApContactPhoneSelect">
                <input
                  v-model="model.useErpApContactPhone"
                  type="checkbox"
                  class="form-check-input use-data-checkbox" />
              </span>
              <span
                v-else
                class="form-no-check">
&nbsp;
              </span>
            </div>
            <div class="form-group">
              <label>
                <span :class="{ 'missing-data-text': model.isIncompleteApContactPhone }">
                  Accounts Payable Phone:
                </span>
                <span v-if="model.sentApContactPhoneToErp">
                  <em> (previous)</em>
                </span>
              </label>
              <span
                v-if="model.allowApContactPhoneSelect"
                class="sm-nomatch-indicator">
                <i class="fa fa-circle"></i>
              </span>
              <p>
                <span :class="styleErpApContactPhone">
                  {{ app.erpEntityInfo.acctsPayableContactPhone }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      id="company-info-details-nonerp"
      class="component-container">
      <div class="card">
        <div class="card-block">
          <div class="form-group">
            <label>Business Name:</label>
            <p>{{ app.businessInfo.companyName }}</p>
          </div>
          <div
            v-if="app.billingInfo.federalTaxId"
            class="form-group">
            <label>Tax ID:</label>
            <p>{{ app.billingInfo.federalTaxId }}</p>
          </div>
          <div class="form-group">
            <label>Account #:</label>

            <button
              v-if="canEdit && isAppPending()"
              type="button"
              class="btn btn-sm btn-link"
              @click="editCompanyInfo()">
              <i
                v-if="app.businessInfo.accountNumber"
                class="fa fa-pencil"></i>
              <i
                v-else
                class="fa fa-plus-square"></i>
            </button>

            <p>
              <span
                v-if="possibleWrongAccountNum()"
                class="text-danger">
                {{ app.businessInfo.accountNumber }}
              </span>
              <span v-else>
                {{ app.businessInfo.accountNumber }}
              </span>
</p>
          </div>
          <div class="form-group">
            <label>Primary Address:</label>
            <p>{{ app.billingInfo.billingAddress.streetAddress }}</p>
            <p v-if="app.billingInfo.billingAddress.addressUnitNumber && app.billingInfo.billingAddress.addressUnitNumber != ''">
              Unit: {{ app.billingInfo.billingAddress.addressUnitNumber }}
            </p>
            <p>{{ app.billingInfo.billingAddress.city }}, {{ app.billingInfo.billingAddress.state }} {{ app.billingInfo.billingAddress.postalCode }}</p>
            <p>{{ app.billingInfo.billingAddress.country }} </p>
          </div>
          <div
            v-if="app.billingInfo.contractorLicenceNumber"
            class="form-group">
            <label>Contractor License #:</label>
            <p>{{ app.billingInfo.contractorLicenceNumber }}</p>
          </div>
          <div
            class="form-group">
            <label><span>
              Preferred Sales Person:
            </span></label>
            <p>
              <span>
                {{ app.billingInfo.preferredSalesPerson }}
              </span>
            </p>
          </div>
        </div>
        <div class="card-block">
          <div class="form-group">
            <label>Business Contact:</label>
            <p>{{ app.accountPayable.firstName }} {{ app.accountPayable.lastName }}</p>
          </div>
          <div class="form-group">
            <label>Business Email:</label>
            <p v-if="app.accountPayable.email">
              {{ app.accountPayable.email }}
            </p>
            <p v-else>
              {{ app.businessInfo.email }}
            </p>
          </div>
          <div class="form-group">
            <label>Business Phone:</label>
            <p v-if="app.accountPayable.phone">
              {{ app.accountPayable.phone }}
            </p>
            <p v-else>
              {{ app.businessInfo.phone }}
            </p>
          </div>
          <div class="form-group">
            <label>Has Any Guaranty:</label>
            <p v-if="app.hasGuaranty">
              Yes
            </p>
            <p v-else>
              No
            </p>
          </div>
          <div
            v-if="app.creditAmount"
            class="form-group">
            <label>Credit Line:</label>
            <h5>{{ app.creditAmount | currency }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErpReconcile from '../../../../store/app-review/erp-reconcile'

export default {
  name: 'CompanyInfo',
  props: {
    app: {
      required: true,
      type: Object
    },
    canEdit: {
      required: true,
      type: Boolean
    }
  },
  data: function () {
    let isAppPending = false
    if (this.app.erpEntityInfo) {
      isAppPending = this.app.erpEntityInfo.isPendingApplication
    }
    const result = {
      model: new ErpReconcile(this.app, isAppPending)
    }
    if (this.app.erpEntityInfo) {
      const info = this.app.erpEntityInfo
      result.model.allowBusinessNameSelect = info.isPendingApplication && info.businessNameNoMatch
      result.model.allowBusinessEmailSelect = info.isPendingApplication && info.businessEmailNoMatch
      result.model.allowBusinessPhoneSelect = info.isPendingApplication && info.businessPhoneNoMatch
      result.model.allowBillingAddressSelect = false // info.isPendingApplication && info.billingAddressNoMatch
      result.model.allowShippingAddressSelect = false // info.isPendingApplication && info.shippingAddressNoMatch
      result.model.allowApContactNameSelect = info.isPendingApplication && info.apContactNameNoMatch
      result.model.allowApContactPhoneSelect = info.isPendingApplication && info.apContactPhoneNoMatch

      result.model.sentBusinessNameToErp = (!info.isPendingApplication) && info.businessNameNoMatch
      result.model.sentBusinessEmailToErp = (!info.isPendingApplication) && info.businessEmailNoMatch
      result.model.sentBusinessPhoneToErp = (!info.isPendingApplication) && info.businessPhoneNoMatch
      result.model.sentBillingAddressToErp = (!info.isPendingApplication) && info.billingAddressNoMatch
      result.model.sentShippingAddressToErp = (!info.isPendingApplication) && info.shippingAddressNoMatch
      result.model.sentApContactNameToErp = (!info.isPendingApplication) && info.apContactNameNoMatch
      result.model.sentApContactPhoneToErp = (!info.isPendingApplication) && info.apContactPhoneNoMatch

      if (info.isPendingApplication && info.businessEmailDefaultChecked) {
        result.model.useCreditAppBusinessEmail = true
        info.businessEmailDefaultChecked = false
      }
      if (info.isPendingApplication && info.businessPhoneDefaultChecked) {
        result.model.useCreditAppBusinessPhone = true
        info.businessPhoneDefaultChecked = false
      }
      if (info.isPendingApplication && info.apContactNameDefaultChecked) {
        result.model.useCreditAppApContactName = true
        info.apContactNameDefaultChecked = false
      }
      if (info.isPendingApplication && info.apContactPhoneDefaultChecked) {
        result.model.useCreditAppApContactPhone = true
        info.apContactPhoneDefaultChecked = false
      }

      result.model.checkIncomplete()
    }
    return result
  },
  computed: {
    styleCreditAppBusinessName: function () {
      return {
        'inactive-data': (!this.model.isIncompleteBusinessName) && this.model.useErpBusinessName,
        'missing-data-text': this.model.isIncompleteBusinessName
      }
    },
    styleCreditAppBusinessEmail: function () {
      return {
        'inactive-data': (!this.model.isIncompleteBusinessEmail) && this.model.useErpBusinessEmail,
        'missing-data-text': this.model.isIncompleteBusinessEmail
      }
    },
    styleCreditAppBusinessPhone: function () {
      return {
        'inactive-data': (!this.model.isIncompleteBusinessPhone) && this.model.useErpBusinessPhone,
        'missing-data-text': this.model.isIncompleteBusinessPhone
      }
    },
    styleCreditAppBillingAddress: function () {
      return {
        'inactive-data': (!this.model.isIncompleteBillingAddress) && this.model.useErpBillingAddress,
        'missing-data-text': this.model.isIncompleteBillingAddress
      }
    },
    styleCreditAppShippingAddress: function () {
      return {
        'inactive-data': (!this.model.isIncompleteShippingAddress) && this.model.useErpShippingAddress,
        'missing-data-text': this.model.isIncompleteShippingAddress
      }
    },
    styleCreditAppApContactName: function () {
      return {
        'inactive-data': (!this.model.isIncompleteApContactName) && this.model.useErpApContactName,
        'missing-data-text': this.model.isIncompleteApContactName
      }
    },
    styleCreditAppApContactPhone: function () {
      return {
        'inactive-data': (!this.model.isIncompleteApContactPhone) && this.model.useErpApContactPhone,
        'missing-data-text': this.model.isIncompleteApContactPhone
      }
    },
    styleErpBusinessName: function () {
      return {
        'inactive-data': (!this.model.isAppPending) || ((!this.model.isIncompleteBusinessName) && this.model.useCreditAppBusinessName),
        'missing-data-text': this.model.isIncompleteBusinessName
      }
    },
    styleErpBusinessEmail: function () {
      return {
        'inactive-data': (!this.model.isAppPending) || ((!this.model.isIncompleteBusinessEmail) && this.model.useCreditAppBusinessEmail),
        'missing-data-text': this.model.isIncompleteBusinessEmail
      }
    },
    styleErpBusinessPhone: function () {
      return {
        'inactive-data': (!this.model.isAppPending) || ((!this.model.isIncompleteBusinessPhone) && this.model.useCreditAppBusinessPhone),
        'missing-data-text': this.model.isIncompleteBusinessPhone
      }
    },
    styleErpBillingAddress: function () {
      return {
        'inactive-data': (!this.model.isAppPending) || ((!this.model.isIncompleteBillingAddress) && this.model.useCreditAppBillingAddress),
        'missing-data-text': this.model.isIncompleteBillingAddress
      }
    },
    styleErpShippingAddress: function () {
      return {
        'inactive-data': (!this.model.isAppPending) || ((!this.model.isIncompleteShippingAddress) && this.model.useCreditAppShippingAddress),
        'missing-data-text': this.model.isIncompleteShippingAddress
      }
    },
    styleErpApContactName: function () {
      return {
        'inactive-data': (!this.model.isAppPending) || ((!this.model.isIncompleteApContactName) && this.model.useCreditAppApContactName),
        'missing-data-text': this.model.isIncompleteApContactName
      }
    },
    styleErpApContactPhone: function () {
      return {
        'inactive-data': (!this.model.isAppPending) || ((!this.model.isIncompleteApContactPhone) && this.model.useCreditAppApContactPhone),
        'missing-data-text': this.model.isIncompleteApContactPhone
      }
    }
  },
  methods: {
    isAppPending: function () {
      if (this.app.erpEntityInfo && this.app.erpEntityInfo.entityId) {
        let isPending = this.app.erpEntityInfo.isPendingApplication
        if (isPending &&
          (typeof this.app.approved !== 'undefined') &&
          this.app.approved) {
          this.model.toggleToApproved()
          isPending = false
        }
        this.model.isAppPending = isPending
        return isPending
      } else {
        return (this.app.status === 'Pending') && ((typeof this.app.approved === 'undefined') || (!this.app.approved))
      }
    },
    editCompanyInfo: function () {
      this.$emit('editCompanyInfo_')
    },
    possibleWrongAccountNum: function () {
      if (this.app.erpEntityInfo) {
        return this.app.erpEntityInfo.isPendingApplication && this.app.erpEntityInfo.possibleIncorrectEntityId
      }
      return false
    },
    nonMatchItems: function () {
      if (this.app.erpEntityInfo) {
        const info = this.app.erpEntityInfo
        return info.businessNameNoMatch ||
          info.businessEmailNoMatch ||
          info.billingAddressNoMatch ||
          info.shippingAddressNoMatch ||
          info.businessPhoneNoMatch ||
          info.apContactNameNoMatch ||
          info.apContactPhoneNoMatch
      }
      return false
    }
  }
}
</script>

<style lang="scss">
#company-info-details-erpcompare {
  margin-bottom: 1rem;
  .card-alt {
    background-color: #efefef;
  }
  .card {
    @media (min-width: 768px) {
      flex-direction: row;
    }

    label,
    p{
      margin-bottom: 0;
    }

    label{
      color: #999;
      font-size: .8em;
    }

    p {
      font-size: .875rem;
      font-weight: 700;
      word-break: break-word;
    }

    h5 {
        font-weight: bold;
    }

    &-block {
      display: flex;
      flex-wrap: wrap;
      padding: .25rem .5rem;
      position: relative;
      .form-group {
        padding: .25rem .5rem;
        margin-bottom: 0;

        @media (min-width: 414px){
          width: 50%;
        }
      }
      @media (min-width: 768px) {
        width: 50%;
        &:nth-of-type(1) {
          &::after {
            content: '';
            width: 1px;
            height: calc(100% - 2rem);
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            background-color: #ccc;
          }
        }
      }
      @media (max-width: 768px) {
        padding: 0 .5rem;
        margin: 0;
        .form-group {
          padding: 0 .5rem;
          margin: 0;
        }
      }
    }
    @media (max-width: 768px) {
      padding: 1rem 0;
    }
    @media (max-width: 442px) {
      .form-group {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
  .sm-note {
      float: right;
  }
  .sm-nomatch-indicator {
      color: #d72b44;
      font-size: .7em;
      margin-left: .4rem;
      margin-right: .2rem;
  }
  .sm-senttoerp-indicator {
      color: #0bb724;
      font-size: .9em;
      margin-left: .4rem;
      margin-right: .2rem;
  }
  .sm-note-text {
      color: #999;
      font-size: .8em;
  }
  .use-all-checkbox{
    margin-right: 11px;
  }
  .use-data-checkbox {
    margin-left: .1rem;
    margin-top: 1.2rem;
    margin-right: 8px;
  }
  .section-check {
    margin-left: 15px;
  }
  .section-use-credit-app {
    margin-left: 15px;
  }
  .inactive-data {
    color: #c0c0c0;
  }
  .missing-data-text {
    color: #9d0000;
  }
  .missing-data-border {
    border-style: solid;
    border-color: #9d0000;
    border-width: 2px;
  }
  .section-account-num {
    margin-left: 23px;
  }
  .section-tax-id {
      float: right;
  }
  .col-reduced {
      padding: 2px 15px;
  }
  .form-no-check {
    margin-left: 18px;
  }
  .section-form-no-check {
    margin-left: 2px;
  }
}
#company-info-details-nonerp {
  margin-bottom: 1rem;
  .card-alt {
    background-color: #efefef;
  }
  .card {
    @media (min-width: 768px) {
      flex-direction: row;
    }

    label,
    p{
      margin-bottom: .25rem;
    }

    label{
      color: #999;
      font-size: .8em;
    }

    p {
      font-size: .875rem;
      font-weight: 700;
      word-break: break-word;
    }

    h5 {
        font-weight: bold;
    }

    &-block {
      display: flex;
      flex-wrap: wrap;
      padding: .5rem;
      position: relative;
      .form-group {
        padding: .5rem;
        margin-bottom: 1rem;

        @media (min-width: 414px){
          width: 50%;
        }
      }
      @media (min-width: 768px) {
        width: 50%;
        &:nth-of-type(1) {
          &::after {
            content: '';
            width: 1px;
            height: calc(100% - 2rem);
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            background-color: #ccc;
          }
        }
      }
      @media (max-width: 768px) {
        padding: 0 .5rem;
        margin: 0;
        .form-group {
          padding: 0 .5rem;
          margin: 0;
        }
      }
    }
    @media (max-width: 768px) {
      padding: 1rem 0;
    }
    @media (max-width: 442px) {
      .form-group {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
  .section-check {
    margin-left: 2em;
  }
}
</style>
