<template>
  <v-dialog
    :value="dialog"
    max-width="600px"
    persistent
    @keydown.esc="cancel">
    <v-card>
      <v-toolbar
        style="background-color: rgb(8, 72, 69)"
        flat
        dark>
        <div class="headline">
          Personal Guaranty Acknowledgement
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5">
        <p>
          The undersigned Guarantor(s) in order to induce Rexel USA, Inc. and its subsidiaries, trade name entities, and business units (collectively the Seller) to extend credit to applicant herein does hereby unconditionally personally guarantee all sums which may be owed by applicant to the Seller, whether said indebtedness is due now or hereafter incurred.
        </p>
        <p>
          Guaranty is continuing Guarantor(s). The Seller may joint or independently modify indebtedness, accept or releases collateral, or release the applicant, without releasing the undersigned Guarantor(s), any of all of which actions may be taken without notice to Guarantor(s). If the Guaranty is executed by more than one Guarantor, one or more Guarantors may be released, and such release shall not release the other Guarantor(s), and such release may be done without notice to the other Guarantor(s).
        </p>
        <p>
          The undersigned Guarantor(s) waives notice of execution of this Guaranty. Performance of this Guaranty shall be at Seller's location as stated in Terms and Conditions and the undersigned Guarantor(s) promise to pay the indebtedness and obligators incurred hereunder at the Seller's location as stated in Terms and Conditions. Guarantor(s) grant permission to Seller to obtain personal credit information from personal references furnished and/or from credit bureau reports, as may be deemed advisable, the undersigned individual(s) hereby knowingly consents to the use of such report in any manner consistent with Federal Fair Credit Reporting Act as contained in 15 USC 1681, cf seq. Applicant warrants that the materials to be purchased are not for any personal, family, or household purposes.
        </p>
        <p>
          A faxed copy of this credit application can be considered as the original.
        </p>
      </v-card-text>
      <v-card-text class="pt-0">
        <v-checkbox
          v-model="formRead"
          label="I accept the terms for personal guarantee. (Please Read Whole Form)"
          hide-details
          class="ml-0">
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()">
          Cancel
        </v-btn>
        <v-btn
          style="background-color: rgb(8, 72, 69); color: white;"
          :disabled="!formRead"
          @click="close()">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PersonalGuaranteeModal',
  props: {
    dialog: {
      type: Boolean
    },
    signatory: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      formRead: false
    }
  },
  methods: {
    close () {
      this.formRead = false
      this.$emit('update:dialog', false)
      this.$nextTick(() => {
        this.$emit('ssnCheck', this.signatory)
      })
    },
    cancel () {
      this.formRead = false
      this.$emit('cancel', this.signatory)
      this.close()
    }
  }
}
</script>
