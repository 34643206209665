import Vue from 'vue'

export default [

  Vue.filter('currency', function (value) {
    let number = '0.00'
    if (value != null) {
      number = value.toFixed(2)
      number = number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    }
    return '$' + number
  }),

  Vue.filter('numeric', function (value) {
    const number = value.toString()
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }),

  Vue.filter('shortDate', function (value) {
    if (!value) {
      return '--'
    }
    const d = new Date(value)
    if (d.getFullYear() === 1) { // back-end passed DateTime.MinValue
      return '--'
    }
    return (d.getMonth() + 1) +
      '/' + d.getDate() +
      '/' + d.getFullYear()
  }),

  Vue.filter('shortTime', function (value) {
    if (!value) {
      return null
    }
    const d = new Date(value)
    return d.toLocaleTimeString()
  }),

  /**
  * Vue filter to round the decimal to the given place.
  * http://jsfiddle.net/bryan_k/3ova17y9/
  *
  * @param {String} value    The value string.
  * @param {Number} decimals The number of decimal places.
  */
  Vue.filter('round', function (value, decimals) {
    if (!value) {
      value = 0
    }

    if (!decimals) {
      decimals = 0
    }

    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    return value
  })
]
