export default {
  // new identity stuff
  identity: null,
  accessToken: '',
  banners: [],
  branches: [],
  company: {},
  countries: [],
  defaultBranch: {},
  filterReasons: [],
  statesUS: [],
  currentCustomer: {},
  plattforceRootUrl: '',
  appConfig: {
    identityAuthorityUrl: '',
    environment: ''
  },
  userRoles: [],
  regions: [],
  adminHeaderTitle: '',
  devHelpers: {
    showDevControls: false,
    showDevFormValidationState: false,
    overwriteFormWithTestData: false
  },
  stepsVisible: null,
  dashboardFormHistoryFilter: null,
  webUser: null, // See Branch FraudOptions,
  creditReviewFilters: null
}
