<template>
  <div
    id="comments-component"
    class="comments-list component-container">
    <div class="btn-title">
      <h5 class="section-title">
        Comments
      </h5>
      <div class="btns">
        <button
          type="button"
          class="btn btn-rounded"
          @click="newComment()">
          <i class="fa fa-comments-o"></i>
          <span>Add Comment</span>
        </button>
      </div>
    </div>
    <ul
      v-if="app.comments && app.comments.length > 0"
      class="list-unstyled">
      <li
        v-for="(c, index) in app.comments"
        :key="index"
        class="card">
        <div class="comment-header">
          <h6>{{ c.createdBy }}</h6>
          <p>{{ c.dateCreatedString }}</p>
        </div>
        <div class="comment-body">
          <pre>{{ c.message }}</pre>
        </div>
      </li>
    </ul>
    <div
      v-else
      class="alert alert-info">
      You have no Comments yet.
      <a
        class="alert-link"
        @click="newComment()">
        Click here
      </a> to create one.
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditReviewComments',
  props: {
    app: {
      required: true,
      type: Object
    }
  },
  methods: {
    newComment: function () {
      this.$emit('newComment_')
    }
  }
}
</script>

<style lang="scss">
#comments-component {
  .card {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #EEEEEE;

    .comment-header {
      display: flex;
      justify-content: space-between;

      h6 {
        font-size: .9rem;
      }

      p {
        font-size: .875rem;
        color: #999;
      }
    }

    .comment-body {
      p {
        word-break: break-word;
      }
    }
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    text-align: justify;
    color: #555;
  }
}
</style>
