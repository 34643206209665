const isObject = (value) => typeof value === 'object' && value !== null

export const mustBeObject = (value) => isObject(value)

export const mustBeObjectOrEmpty = (value) => isObject(value) || value === ''

export const filetypeRule = (file) => {
  const acceptedTypes = ['doc', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'pdf', 'bmp', 'jpg', 'png']
  if (file && file.name) {
    return acceptedTypes.includes(file.name.split('.').pop().toLowerCase())
  }
  return true
}

export const bannerEmailRule = (email) => {
  const rejectedDomains = ['rexelusa.com', 'gexpro.com', 'platt.com']
  if (email) {
    return !rejectedDomains.includes(email.split('@').pop())
  }
  return true
}

export const customerNumberExistsRule = (isInternal, customerExists) => {
  return (value) => {
    return isInternal ? customerExists : true
  }
}
