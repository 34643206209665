/**
 * There are 3 ways that a User can be Authorized w/ Permissions to access protected routes/information from CreditApplication

    * Existing on Identity (User is an Employee)

    * Being Added to the ManageUsers section
      (see wiki: https://platt-blackops.visualstudio.com/Credit%20App/_wiki/wikis/Credit-App.wiki/273/Credit-Application's-Manage-Users-section)

    * Having a 'credit' app Role added to your Identity
      (example: the role where name is "instant-credit-link-viewer" gives user the isInstantCreditLinkViewer permission)

    Current list of permissions that can be added to route.meta.permissions

      editApplications
      editEmailTemplate
      editTenant
      editUserTenants
      editUsers
      viewUsers
      editProfile
      isInstantCreditLinkViewer

    Quick Reference of test users
    | Name                 |  Username                    | Role
    | Aaron Glavan         |  Aaron.Glavan@platt.com      | Admin
    | Randy McDonley       |  Randy.McDonley@RexelUSA.com | Approver
    | Ann Torrence         |  Ann.Torrence@platt.com      | ViewOnly
    | Hunter Peil          |  hunter.peil@rexelusa.com    | SystemAdmin

    identity uses a levels enum to make comparisons,
    we have not implemented level enum comparators on the FE, and do not currently have a need as
    access is based on feature specific permissions
    level      | role names
    none = 0   |
    view = 5   | ViewOnly, instant-credit-link-viewer
    edit = 10  | Approver
    admin = 20 | Admin, SystemAdmin

    EXAMPLES

    creditAppRole:
      apps: ["credit"]
      banners: ["platt"]
      level: "view"
      name: "ViewOnly"

    creditAppRole:
      apps: ["credit"]
      banners: ["platt"]
      level: "admin"
      name: "Admin"

    creditAppRole:
      apps: ["credit"]
      banners: ["platt"]
      level: "admin"
      name: "SystemAdmin"

    creditAppRole:
      apps: ["credit"]
    NOTE: does not have banners
      level: "view"
      name: "instant-credit-link-viewer"
 */

const _buildBannersArray = function (defaultBannerName, creditAppRoles) {
  // NOTE: This will just use the first role where app == 'credit' and banners exists
  //  currently only users who are added through Manage Users will have a role with multi banner access

  const creditAppRoleWithBanners = creditAppRoles.find(role => role.banners != null)
  // if the user has a credit app role w/ a banners array, use that; otherwise push the passed banner arg
  let banners = []
  if (creditAppRoleWithBanners != null && creditAppRoleWithBanners.banners != null) {
    banners = [...creditAppRoleWithBanners.banners]
  } else {
    if (defaultBannerName === 'rexel') { banners.push('gexpro') }
    if (defaultBannerName === 'gexpro') { banners.push('rexel') }
    banners.push(defaultBannerName)
  }
  return banners
}

const _buildBannerId = function (defaultBannerName, stateBanners) {
  const bannerObj = stateBanners.find(b => {
    return b.name.toLowerCase() === defaultBannerName
  })
  return bannerObj != null ? bannerObj.tenantId : null
}

const _buildBannerInfo = function (defaultBannerName, creditAppRoles, stateBanners) {
  const banners = _buildBannersArray(defaultBannerName, creditAppRoles)

  let updatedDefault = defaultBannerName
  if (banners.length && !banners.includes(defaultBannerName)) {
    updatedDefault = banners[0]
  }
  const bannerId = _buildBannerId(updatedDefault, stateBanners)
  return {
    banners,
    bannerId
  }
}

// if null passed, returns a default permissions object w/ all values set to false
const _buildCreditAppRolePermissionsObject = function (creditAppRole) {
  let isSystemAdmin = false
  let isAdmin = false
  let isApprover = false
  let isViewOnly = false
  let isInstantCreditLinkViewer = false
  let isResUser = false

  if (creditAppRole != null) {
    // these are the original app role names for users in the manage user section
    isSystemAdmin = creditAppRole.name === 'SystemAdmin'
    isAdmin = creditAppRole.name === 'Admin'
    isApprover = creditAppRole.name === 'Approver'
    isViewOnly = creditAppRole.name === 'ViewOnly'

    // credit roles added since identity update
    isInstantCreditLinkViewer = creditAppRole.name === 'instant-credit-link-viewer'
    // const isInternalUser = creditAppRole.level === 'internal-user' // this is available, role level is not in use at the moment
    isResUser = creditAppRole.banners && creditAppRole.banners.length === 1 && creditAppRole.banners[0] === 'res'
  }

  return {
    managedUser: isViewOnly || isApprover || isAdmin || isSystemAdmin, // a Managed User is a user registered in the original Manage User list (original credit app users)
    editApplications: isApprover || isAdmin || isSystemAdmin,
    editEmailTemplate: isSystemAdmin,
    editTenant: isSystemAdmin,
    editUserTenants: isSystemAdmin,
    editUsers: isAdmin || isSystemAdmin,
    viewUsers: isAdmin || isSystemAdmin, // NOTE: viewUsers is needed to edit a users profile, should a user be able to edit their own profile?
    editProfile: isViewOnly || isApprover || isAdmin || isSystemAdmin, // only users that are in the manage users db will be able to edit their profile
    isInstantCreditLinkViewer,
    isResUser
  }
}

const _processPermissions = function (creditAppRoles) {
  // permissions are built using each credit app role that the user is assigned
  let permissions

  const creditAppRoleWithBanners = creditAppRoles.find(role => role.banners != null)
  if (creditAppRoleWithBanners == null) {
    permissions = _buildCreditAppRolePermissionsObject()
  }

  // iterate over all available credit app roles to assign permission values
  creditAppRoles.forEach(creditAppRole => {
    const creditAppRolePermissions = _buildCreditAppRolePermissionsObject(creditAppRole)
    if (permissions == null) {
      // first permissionObject is assigned
      permissions = creditAppRolePermissions
    } else {
      // values from additional permission objects are merged
      const permissionKeys = Object.keys(creditAppRolePermissions)
      permissionKeys.forEach(key => {
        // assign existing OR current value
        const currentKey = creditAppRolePermissions[key]
        const existingKey = permissions[key]
        permissions[key] = currentKey || existingKey
      })
    }
  })

  return {
    permissions
  }
}

const _buildCreditAppRoles = function (role) {
  const identityRoles = typeof role === 'string' // parse json string to object
    ? [JSON.parse(role)]
    : role.map((r) => JSON.parse(r))

  const findAllRolesByApp = app => identityRoles.filter(r => r.apps != null ? r.apps.find(a => a === app) : false)

  const creditAppRoles = findAllRolesByApp('credit') // NOTE: a user should have just a single app role w/ the value of credit

  return {
    creditAppRoles
  }
}

// using role data from identity, generate permissions, banners, and bannerId values for the user
export function processIdentity (role, defaultBannerName, stateBanners) {
  const {
    creditAppRoles
  } = _buildCreditAppRoles(role)

  const {
    permissions
  } = _processPermissions(creditAppRoles)

  const {
    banners,
    bannerId
  } = _buildBannerInfo(defaultBannerName, creditAppRoles, stateBanners)

  return {
    permissions,
    banners,
    bannerId
  }
}
