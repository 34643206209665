<template>
  <v-app-bar
    :height="72"
    app
    flat
    dark
    :class="[
      $route.meta.user === true ? 'admin-navigation' : '',
      selectedCompany
    ]">
    <!-- NOTE: styles using $route.meta.user is an ugly workaround -->
    <div class="w-100">
      <v-row v-if="plattWebUser">
        <v-col cols="6">
          <tenant-banner
            v-if="$route.meta.user === true"
            class="align-start"></tenant-banner>
        </v-col>
        <v-col cols="6">
          <div
            class="transparent h-100"
            :min-width="400">
            <v-list-item class="h-100 justify-content-end">
              <v-avatar
                left
                :size="40"
                class="ml-n1 mr-2">
                <v-icon
                  :class="`${selectedCompany}--secondary`"
                  dark
                  :size="24">
                  mdi-account
                </v-icon>
              </v-avatar>
              <v-list-item-content
                v-if="$vuetify.breakpoint.mdAndUp"
                class="flex-initial">
                <v-list-item-title>
                  Welcome,
                  <span v-if="plattWebUser.firstName">
                    {{ plattWebUser.firstName }}
                  </span>
                  <span v-if="plattWebUser.lastName">
                    {{ plattWebUser.lastName }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <TenantBanner v-if="$route.meta.user === true"></TenantBanner>
        </v-col>
      </v-row>

      <v-switch
        v-if="isDevelopmentEnvironment && $route.meta.user === true"
        dense
        inset
        :v-model="!showDevControls"
        class="pl-5"
        style="position: absolute; right: 5%; top: 95%; transform: translateY(-50%)"
        @change="$store.dispatch('toggleDevControls')">
        <v-icon
          slot="prepend"
          color="white">
          mdi-dev-to
        </v-icon>
      </v-switch>
    </div>
  </v-app-bar>
</template>
<script type="text/javascript">
import TenantBanner from '@/components/TenantBanner.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
// import { checkAuth } from '@/main'
import axios from 'ca-http-service'

export default {
  name: 'NavBar',
  components: {
    TenantBanner
  },
  data () {
    return {
      plattWebUser: null,
      feedbackDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'isDevelopmentEnvironment',
      'showDevControls',
      'company'
    ]),
    ...mapState([
      'identity'
    ]),
    selectedCompany () {
      switch (this.$route.params.tenant) {
        case 'platt':
          return 'platt'
        case 'rexel':
        case 'rexelenergy':
          return 'rexel'
        case 'gexpro':
          return 'gexpro'
        default:
          return null
      }
    }
  },
  mounted () {
    if (this.$route.name === 'application') {
      this.setCurrentBanner(this.$route.params.tenant)
    }

    if (this.$route.query.sftoken != null) {
      const data = this.$route.query.sftoken
      axios.get('/api/creditapplication/storefront-token', {
        params: {
          sftoken: data
        }
      }).then((res) => {
        this.plattWebUser = res.data
      }).catch((err) => {
        console.error('error retrieving storefront logged in user', err)
      })
    }

    // checkAuth()
  },
  methods: {
    ...mapActions([
      'setCurrentBanner'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/bootstrap-variables.scss';

.platt--secondary {
  background-color: lighten(#003708, 10%);
}

.rexel--secondary {
  background-color: lighten(#06194E, 10%);
}

.gexpro--secondary {
  background-color: lighten(#003B74, 10%);
}

.v-breadcrumbs {
  color: #000000;
  padding-bottom: 6px;
  li {
    font-family: Rubik, sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    font-weight: 500;
    .v-breadcrumbs__item {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  li.v-breadcrumbs__divider {
    padding: 0 20px;
  }
}

.router-link-active:not(.nav-logo) {
  font-weight: bold;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 50%;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #292b2c;
  }
}

.flex-initial {
  flex: initial;
}
</style>
