<template>
  <fieldset>
    <div class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.addEmail"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.addEmail"
          :input-label="formLabels.addEmail"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1',
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
    </div>

    <div v-if="userFormInput.addEmail === '1'">
      <div class="row">
        <div class="col-md-6">
          <FormInputTextInput
            ref="addEmail"
            v-model="userFormInput.email"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.email"
            :input-label="formLabels.email"
            input-type="text"></FormInputTextInput>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptions.vue'
import TenantsMixin from 'mixins/tenants.mixin'

export default {
  name: 'JobAccountFormStepEmail',
  components: {
    FormInputTextInput,
    FormInputRadioOptions
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ],
  data () {
    return {
      isLoading: false
    }
  },
  watch: {
    'userFormInput.addEmail': {
      handler (newValue) {
        if (newValue === '0') {
          this.userFormInput.email = ''
        }
      }
    }
  }
}
</script>
