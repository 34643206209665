<template>
  <fieldset>
    <div
      v-if="$slots.AddressDropDown"
      class="row">
      <div :class="col">
        <slot name="AddressDropDown"></slot>
      </div>
    </div>

    <div class="row">
      <div :class="col">
        <FormInputTextInput
          ref="streetAddress"
          v-model="userFormInput[fieldNames.streetAddress]"
          :vuelidate="vuelidate[fieldNames.streetAddress]"
          :input-label="formLabels[fieldNames.streetAddress]"
          input-type="text"
          :disabled="disabled"></FormInputTextInput>
      </div>
      <div :class="col">
        <FormInputTextInput
          ref="addressUnitNumber"
          v-model="userFormInput[fieldNames.addressUnitNumber]"
          :vuelidate="vuelidate[fieldNames.addressUnitNumber]"
          :input-label="formLabels[fieldNames.addressUnitNumber]"
          input-type="text"
          :disabled="disabled"></FormInputTextInput>
      </div>
      <div
        v-if="isSpreadOut"
        :class="col"></div>
      <div :class="col">
        <FormInputTextInput
          ref="postalCode"
          v-model="userFormInput[fieldNames.postalCode]"
          :vuelidate="vuelidate[fieldNames.postalCode]"
          :input-label="formLabels[fieldNames.postalCode]"
          input-type="text"
          :disabled="disabled"
          @input="onPostalCodeChange($event)"></FormInputTextInput>
      </div>
      <div :class="col">
        <FormInputTextInput
          ref="city"
          v-model="userFormInput[fieldNames.city]"
          :vuelidate="vuelidate[fieldNames.city]"
          :input-label="formLabels[fieldNames.city]"
          input-type="text"
          :disabled="disabled"></FormInputTextInput>
      </div>
      <div :class="col">
        <FormInputDropdown
          v-model="userFormInput[fieldNames.state]"
          :vuelidate="vuelidate[fieldNames.state]"
          :input-label="formLabels[fieldNames.state]"
          :options="states"
          :disabled="disabled"></FormInputDropdown>
      </div>
    </div>
  </fieldset>
</template>

<script>
import axios from 'ca-http-service'
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputDropdown from '@/components/form/FormInputDropdown.vue'

export default {
  name: 'FormInputGroupAddressInfo',
  components: {
    FormInputTextInput,
    FormInputDropdown
  },
  props: {
    vuelidate: {
      type: Object,
      required: true
    },
    // NOTE: make sure to pass the formData reference, instead of the copy of the userFormInput object to this prop
    formData: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    },
    updateByPostalCode: {
      type: Boolean,
      required: false,
      default: true
    },
    fieldNames: {
      type: Object,
      required: false,
      // TODO: this should support streetAddress1 and streetAddress2
      default: () => ({
        streetAddress: 'streetAddress',
        addressUnitNumber: 'addressUnitNumber',
        city: 'city',
        state: 'state',
        postalCode: 'postalCode'
      })
    },
    isSpreadOut: {
      type: Boolean,
      required: false,
      default: true
    },
    col: {
      type: String,
      required: false,
      default: 'col-md-4'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    updateInputMethod: {
      type: [Boolean, Function],
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      userFormInput: this.getFormClass(),
      states: []
    }
  },
  async mounted () {
    this.userFormInput = this.formData
    this.states = await this.getStates()
  },
  methods: {
    getFormClass () {
      return {
        [this.fieldNames.streetAddress]: '',
        [this.fieldNames.addressUnitNumber]: '',
        [this.fieldNames.city]: '',
        [this.fieldNames.state]: '',
        [this.fieldNames.postalCode]: ''
      }
    },
    updateFields (payload) {
      const keys = Object.keys(payload)
      keys.map(k => {
        const value = payload[k]
        if (typeof this.$refs[k] === 'undefined') return k

        if (!this.updateInputMethod) {
          this.defaultUpdateInput(this.$refs[k].$el, value)
          return k
        }
        this.updateInputMethod(this.$refs[k].$el, value)
        return k
      })
    },
    async getStates (country = 'US') {
      return axios.get('/generic/getStateList', {
        params: {
          countryCode: country
        }
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return []
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async onPostalCodeChange (event) {
      if (this.updateByPostalCode) {
        if (event.length === 5) {
          const results = await this.getCityStateByPostalCode(event)
          if (results != null && results.city != null) {
            // set values
            this.userFormInput[this.fieldNames.city] = results.city
            this.userFormInput[this.fieldNames.state] = results.state
          } else {
            // clear values
            this.userFormInput[this.fieldNames.city] = ''
            this.userFormInput[this.fieldNames.state] = ''
          }
          // validate newly set values
          if (this.vuelidate[this.fieldNames.city]) {
            this.vuelidate[this.fieldNames.city].$touch()
          }
          if (this.vuelidate[this.fieldNames.state]) {
            this.vuelidate[this.fieldNames.state].$touch()
          }
        }
      }
    },
    async getCityStateByPostalCode (postalCode) {
      return axios.get('/generic/getCityStateByZip', { params: { postalCode } })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return null
          }
        })
        .catch((err) => {
          const errorMessage = err.response.data
          console.error(errorMessage)
        })
    },
    defaultUpdateInput (inputParentElm, value) {
      const inputs = inputParentElm.getElementsByTagName('INPUT')
      const inputsArray = Array.prototype.slice.call(inputs)
      inputsArray.map((i) => {
        i.value = value
        return i
      })
    }
  }
}
</script>
