<template>
  <fieldset>
    <FormSectionCard title="Banking Information">
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-if="renderStep"
            v-model="bankingInformationInput.bankName"
            persist-label
            :vuelidate="validations.bankingInformation.bankName"
            :input-label="bankingInformation.labels.bankName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormInputRadioOptions
            v-model="bankingInformationInput.accountType"
            :vuelidate="validations.bankingInformation.accountType"
            :input-label="bankingInformation.labels.accountType"
            :options="[
              {
                key: 'Checking',
                value: '1',
              },
              {
                key: 'Saving',
                value: '2',
              }
            ]"></FormInputRadioOptions>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="bankingInformationInput.officerName"
            persist-label
            :vuelidate="validations.bankingInformation.officerName"
            :input-label="bankingInformation.labels.officerName"
            input-type="text"></FormTextInput>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="bankingInformationInput.phoneNumber"
            persist-label
            :vuelidate="validations.bankingInformation.phoneNumber"
            :input-label="bankingInformation.labels.phoneNumber"
            input-type="tel"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="pb-4">
          <FormTextInput
            v-model="bankingInformationInput.phoneNumberExt"
            persist-label
            :vuelidate="validations.bankingInformation.phoneNumberExt"
            :input-label="bankingInformation.labels.phoneNumberExt"></FormTextInput>
        </v-col>
      </v-row>
      <FormGroupAddressInfo
        v-model="bankingInformationInput"
        persist-label
        :vuelidate="validations.bankingInformation"
        :form-labels="bankingInformation.labels"
        :form-data="bankingInformation.data">
        <template #AddressDropDown>
          <FormDropdown
            v-model="bankingInformationInput.country"
            persist-label
            :vuelidate="validations.bankingInformation.country"
            :input-label="bankingInformation.labels.country"
            :options="$store.getters.countries"
            :on-change="getStates"
            @input="onCountryChange"></FormDropdown>
        </template>
      </FormGroupAddressInfo>
    </FormSectionCard>
    <FormSectionCard title="Industry Trade References?">
      <v-row
        v-show="showTradeRefs === '1'"
        no-gutters
        class="mt-n3 pb-3">
        <div class="trade-ref-note">
          To expedite the approval process, please include 3 trade references
        </div>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pt-0 pb-3">
          <FormInputRadioOptions
            v-model="showTradeRefs"
            input-label=""
            :options="[
              {
                key: 'Yes',
                value: '1',
              },
              {
                key: 'No',
                value: '2',
              },
            ]"></FormInputRadioOptions>
        </v-col>
      </v-row>
      <div
        v-show="showTradeRefs === '1'"
        class="trade-references">
        <v-row>
          <v-col class="pt-0">
            <div class="pref-title">
              Documents
            </div>
            <v-divider class="mt-1 mb-3"></v-divider>
          </v-col>
        </v-row>
        <v-row
          v-show="otherDocuments.length > 0"
          no-gutters
          class="pb-3">
          <v-chip
            v-for="(td, index) in otherDocuments"
            :key="index"
            class="mb-3 mx-2 py-2"
            close
            label
            @click:close="removeDoc('other', td)">
            <v-icon left>
              {{ `mdi-${fileIcon(td.extension)}` }}
            </v-icon>
            <span
              class="text-truncate file-name"
              :title="friendlyFilename(td.name)">
              {{ friendlyFilename(td.name) }}
            </span>
          </v-chip>
        </v-row>
        <v-row class="mb-8">
          <v-col
            cols="12"
            class="py-1 flex-grow-0">
            <button
              type="button"
              class="btn btn-sm btn-primary file-upload"
              @click="uploadDialog('other')">
              <i class="fa fa-cloud-upload"></i>
              Upload Documents
            </button>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pt-0">
            <div class="pref-title">
              Add/Edit Reference
            </div>
            <v-divider class="mt-1 mb-3"></v-divider>
          </v-col>
        </v-row>
        <FormItemCollection
          :current-user-input="tradeReferences.data.tradeReference"
          :item-component="formItemTradeReference"
          :vuelidate="validations.tradeReferences.tradeReferences"
          :form-data-collection="tradeReferences.data.tradeReferences"
          :form-data-instance="tradeReferences.data.tradeReference"
          :item-class="groupData.tradeReferences.dataClass"
          :form-labels="tradeReferences.labels"
          :is-item-invalid="!validations.tradeReferences.tradeReference.$anyDirty"
          :show-add-fields="tradeReferences.data.tradeReferences.length < 3"
          title="Reference"
          btn-text="Add Trade Reference"
          :add-cb="onAddCb"
          @form-collection-add="formCollectionAdd"
          @form-collection-remove="formCollectionRemove">
          <v-row>
            <v-col
              cols="12"
              lg="6"
              class="pb-4">
              <FormTextInput
                v-model="tradeReferencesInput.tradeReference.name"
                persist-label
                :vuelidate="validations.tradeReferences.tradeReference.name"
                :input-label="tradeReferences.labels.name"
                input-type="text"></FormTextInput>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="pb-4">
              <FormTextInput
                v-model="tradeReferencesInput.tradeReference.account"
                persist-label
                :vuelidate="validations.tradeReferences.tradeReference.account"
                :input-label="tradeReferences.labels.account"
                input-type="text"></FormTextInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="6"
              class="pb-4">
              <FormTextInput
                v-model="tradeReferencesInput.tradeReference.phone"
                persist-label
                :vuelidate="validations.tradeReferences.tradeReference.phone"
                :input-label="tradeReferences.labels.phone"
                input-type="tel"></FormTextInput>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="pb-4">
              <FormTextInput
                v-model="tradeReferencesInput.tradeReference.fax"
                persist-label
                :vuelidate="validations.tradeReferences.tradeReference.fax"
                :input-label="tradeReferences.labels.fax"
                input-type="tel"></FormTextInput>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col
              cols="12"
              lg="6"
              class="pb-4">
              <FormTextInput
                v-model="tradeReferencesInput.tradeReference.email"
                persist-label
                :vuelidate="validations.tradeReferences.tradeReference.email"
                :input-label="tradeReferences.labels.email"
                input-type="email"></FormTextInput>
            </v-col>
          </v-row>
          <FormGroupAddressInfo
            v-model="tradeReferencesInput.tradeReference"
            persist-label
            :vuelidate="validations.tradeReferences.tradeReference"
            :form-labels="tradeReferences.labels"
            :form-data="tradeReferences.data.tradeReference">
            <template #AddressDropDown>
              <FormDropdown
                v-model="tradeReferencesInput.tradeReference.country"
                persist-label
                :vuelidate="validations.tradeReferences.tradeReference.country"
                :input-label="tradeReferences.labels.country"
                :options="$store.getters.countries"></FormDropdown>
            </template>
          </FormGroupAddressInfo>
        </FormItemCollection>
      </div>
    </FormSectionCard>
    <FormSectionCard title="Bonding Company">
      <v-row class="mb-5">
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="bondingCompanyInput.bondingName"
            persist-label
            :vuelidate="validations.bondingCompany"
            :input-label="bondingCompany.labels.bondingName"
            input-type="text"></FormTextInput>
        </v-col>
      </v-row>
      <FormGroupAddressInfo
        v-model="bondingCompanyInput"
        persist-label
        :vuelidate="validations.bondingCompany"
        :form-labels="bondingCompany.labels"
        :form-data="bondingCompany.data">
        <template #AddressDropDown>
          <FormDropdown
            v-model="bondingCompanyInput.country"
            persist-label
            :vuelidate="validations.bondingCompany.country"
            :input-label="bondingCompany.labels.country"
            :options="$store.getters.countries"></FormDropdown>
        </template>
      </FormGroupAddressInfo>
      <v-row>
        <v-col class="pt-3">
          <div class="pref-title">
            Bonding Agent
          </div>
          <v-divider class="mt-1 mb-3"></v-divider>
        </v-col>
      </v-row>
      <v-row class="row">
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormTextInput
            v-model="bondingCompanyInput.bondingAgentName"
            persist-label
            :vuelidate="validations.bondingCompany.bondingAgentName"
            :input-label="bondingCompany.labels.bondingAgentName"
            input-type="text"></FormTextInput>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="pb-4">
          <FormInputRadioOptions
            v-model="bondingCompanyInput.isAddressSameAsBondingCompany"
            :vuelidate="validations.bondingCompany.isAddressSameAsBondingCompany"
            :input-label="bondingCompany.labels.isAddressSameAsBondingCompany"
            :options="[
              {
                key: 'Yes',
                value: '1',
              },
              {
                key: 'No',
                value: '2',
              }
            ]"></FormInputRadioOptions>
        </v-col>
      </v-row>
      <FormGroupAddressInfo
        v-if="bondingCompanyInput.isAddressSameAsBondingCompany === '2'"
        v-model="bondingCompanyInput"
        persist-label
        :vuelidate="validations.bondingCompany"
        :form-labels="bondingCompany.labels"
        :form-data="bondingCompany.data"
        :field-names="{
          country: 'bondingAgentCountry',
          streetAddress: 'bondingAgentStreetAddress',
          addressUnitNumber: 'bondingAgentAddressUnitNumber',
          city: 'bondingAgentCity',
          state: 'bondingAgentState',
          postalCode: 'bondingAgentPostalCode'
        }">
        <template #AddressDropDown>
          <FormDropdown
            v-model="bondingCompanyInput.bondingAgentCountry"
            persist-label
            :vuelidate="validations.bondingCompany.country"
            :input-label="bondingCompany.labels.country"
            :options="$store.getters.countries"></FormDropdown>
        </template>
      </FormGroupAddressInfo>
    </FormSectionCard>
    <FileUploadModal
      v-model="showModal"
      :modal-type="modalType"
      :previous-uploaded-documents="otherDocuments || []"
      :application-id="applicationInput.storageGuid"
      @uploaded="onUpload">
    </FileUploadModal>
  </fieldset>
</template>

<script>
import axios from 'ca-http-service'
import FormSectionCard from '@/components/form/FormSectionCard.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptionsV2.vue'
import FormGroupAddressInfo from '@/components/form/FormGroupAddressInfo.vue'
import FormDropdown from '@/components/form/FormDropdown.vue'
import FormItemTradeReference from '@/pages/application/formItems/FormItemTradeReference.vue'
import FormItemCollection from '@/components/form/FormItemCollection2.vue'
import FileUploadModal from '@/components/FileUploadModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Step3ThirdParty',
  components: {
    FormSectionCard,
    FormTextInput,
    FormInputRadioOptions,
    FormGroupAddressInfo,
    FormDropdown,
    FormItemCollection,
    FileUploadModal
  },
  props: {
    groupData: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showTradeRefs: '1',
      states: [],
      currentlySelectedCountry: null,
      bankingInformationInput: new this.groupData.bankingInformation.dataClass(), // eslint-disable-line new-cap
      tradeReferencesInput: {
        tradeReferences: [],
        tradeReference: new this.groupData.tradeReferences.dataClass() // eslint-disable-line new-cap
      },
      bondingCompanyInput: new this.groupData.bondingCompany.dataClass(), // eslint-disable-line new-cap
      applicationInput: new this.groupData.application.dataClass(), // eslint-disable-line new-cap
      bankingInformation: this.groupData.bankingInformation,
      tradeReferences: this.groupData.tradeReferences,
      bondingCompany: this.groupData.bondingCompany,
      application: this.groupData.application,
      showModal: false,
      modalType: 'other',
      otherDocuments: [],
      formItemTradeReference: FormItemTradeReference,
      renderStep: false
    }
  },
  computed: {
    ...mapGetters([
      'company'
    ])
  },
  watch: {
    currentlySelectedCountry: {
      async handler (newVal, oldVal) {
        this.states = await this.getStates(this.bankingInformation.country)
      }
    }
  },
  async created () {
    this.states = await this.getStates(this.bankingInformation.country)
    this.bankingInformationInput = this.bankingInformation.data
    this.tradeReferencesInput.tradeReferences = this.tradeReferences.data.tradeReferences
    this.tradeReferencesInput.tradeReference = this.tradeReferences.data.tradeReference
    this.bondingCompanyInput = this.bondingCompany.data
    this.applicationInput = this.application.data
    this.otherDocuments = this.application.data.uploadedDocuments
    if (this.company.name === 'Platt') {
      this.showTradeRefs = '2'
    }
  },
  mounted () {
    this.renderStep = true
  },
  methods: {

    onCountryChange (country) {
      this.validations.bankingInformation.$touch()
      this.currentlySelectedCountry = country
    },
    async getStates (country) {
      return axios.get('/generic/getStateList', {
        params: {
          countryCode: country
        }
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return []
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    friendlyFilename (fileName) {
      const lastIndexOfSlash = fileName.lastIndexOf('/')
      if (lastIndexOfSlash < 0) {
        return fileName
      }
      return fileName.substring(lastIndexOfSlash + 1).replace(/%20/g, ' ')
    },
    removeDoc (type, doc) {
      this.$http.post(`/api/CreditApplication/removeDocument?fullPath=${doc.fullPath}`)
        .then(res => {
          if (res.data.isSuccess) {
            this.otherDocuments.splice(this.otherDocuments.indexOf(doc), 1)
          } else {
            palert({
              title: 'Error',
              text: 'Something went wrong. Could not remove the document.',
              type: 'error'
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    fileIcon (ext) {
      if (ext.includes('pdf')) {
        return 'file-pdf-outline'
      } else if (['.png', '.jpg', '.jpeg', '.svg'].includes(ext)) {
        return 'file-image-outline'
      } else if (ext.includes('doc')) {
        return 'file-word-outline'
      } else if (ext.includes('zip')) {
        return 'folder-zip-outline'
      } else {
        return 'file-outline'
      }
    },
    uploadDialog (type) {
      this.modalType = type
      this.showModal = true
    },
    onUpload ({ files, storageGuid, docType }) {
      this.applicationInput.storageGuid = storageGuid
      this.applicationInput.uploadedDocuments = files
      this.otherDocuments = files
    },
    onAddCb () {
      // on adding, reset the form using $set
      const keys = Object.keys(this.tradeReferencesInput.tradeReference)
      for (const key of keys) {
        if (key !== 'addressInfo') {
          if (key !== 'referenceId') {
            this.$set(this.tradeReferencesInput.tradeReference, key, '')
          } else {
            this.$set(this.tradeReferencesInput.tradeReference, key, 0)
          }
        } else {
          // NOTE: I think this check is useless, test then rm if so
          const addressInfoKeys = Object.keys(this.tradeReferencesInput.tradeReference.addressInfo)
          for (const addressInfoKey of addressInfoKeys) {
            this.$set(this.tradeReferencesInput.tradeReference.addressInfo, addressInfoKey, '')
          }
        }
      }
      for (const tradeReference in this.tradeReferencesInput.tradeReferences) {
        if (tradeReference.referrenceId != null) {
          tradeReference.referrenceId = 0
        }
      }
    },
    formCollectionRemove () {
      this.validations.tradeReferences.tradeReference.$reset()
    },
    formCollectionAdd () {
      this.validations.tradeReferences.tradeReference.$reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.trade-ref-note {
  color: #88CBF5;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 35px;
}
.file-upload {
  border-radius: 100px;
  padding: .5rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #004168 !important;
}
.file-upload-tag {
  padding: 5px;
  margin-left: 10px;
  &:first-of-type {
    margin-left: 0;
  }
}
.doc-title {
  color: rgb(41, 43, 44);
  font-family:"PT Sans", sans-serif;
  font-size:16px;
  font-weight:500;
}
</style>
