<template>
  <div
    id="application-component"
    class="card component-container credit-application">
    <div
      v-if="applicant !== null && applicant.HasSigned"
      class="p-4 mb-4"
      style="background-color: #90C8AC; color: white;">
      Signed by customer on {{ formatDate(applicant.SignDate) }}
    </div>

    <FormStepper
      :steps="formSteps"
      :mode="mode"
      :on-save="$onSave"
      :is-save-valid="isSaveValid"
      :is-submit-valid="isSubmitValid"
      :on-submit="$onSubmit"
      :third-button="submitLabel"
      :third-button-action="getHelloSignApplication"
      hide-submit
      :hide-save="hideSaveButton"
      :success-message="successMessage"
      :error-message="errorMessage"
      :submitted-on-date="submittedOnDate"
      :submitted-by="submittedBy"
      :validation-failures="validationFailures"
      :loading="loading"
      :invalid="$v.$invalid"
      :is-submitted-successfully="isSubmittedSuccessfully"
      :is-form-complete="isFormComplete"
      @check-validations="$v.$touch()">
      <InstantCreditFormComplete :submitted-data="getCompleteData"></InstantCreditFormComplete>
    </FormStepper>

    <form-navigation-confirmation-modal
      :is-dialog-open="displayNavWarning"
      :is-save-valid="isSaveValid"
      @saveAndNavigate="saveAndNavigate"
      @discardAndNavigate="discardAndNavigate"
      @close="displayNavWarning = false">
    </form-navigation-confirmation-modal>

    <email-instant-credit-application-modal
      :show-modal="showEmailInstantCreditApplicationModal"
      :company-name="formData.companyInformation.companyName"
      :identity="identity"
      :signatory-email="signatoryEmail"
      @sendEmail="sendEmail"
      @close="closeEmailModal"></email-instant-credit-application-modal>
  </div>
</template>
<script type="text/javascript">
import {
  maxLength,
  minLength,
  email,
  required,
  requiredIf
} from 'vuelidate/lib/validators'

import {
  mustBeObject,
  mustBeObjectOrEmpty
} from '@/components/form/FormValidationsCustom'

import axios from 'ca-http-service'
import TenantsMixin from 'mixins/tenants.mixin'
import FormMixin from '@/components/form/Form.mixin'
import FormStepper from '@/components/form/FormStepper.vue'
import InstantCreditFormComplete from './InstantCreditFormComplete.vue'
import FormNavigationConfirmationModal from '@/components/FormNavigationConfirmationModal.vue'
import pdf from '@/assets/downloads/Customer Instant Credit Application_ (082017).pdf' // eslint-disable-line import/extensions
import EmailInstantCreditApplicationModal from '@/components/EmailInstantCreditApplicationModal.vue'
import { submitInstantCreditForm } from '@/api/forms/form-data-submit'

import {
  formTitle,
  formDefinitions,
  COMPANY_INFORMATION,
  MAILING_ADDRESS,
  PHYSICAL_ADDRESS,
  PRINCIPAL_INFORMATION,
  CONTACT_INFORMATION,
  ELECTRICIANS_LICENSE,
  SIGNATORY_INFORMATION,
  SALES_INFORMATION,
  NOTES
} from './InstantCreditForm.definitions'

const INSTANT_CREDIT_FORM_KEY = 'instant-credit'

export default {
  name: 'InstantCreditForm',
  components: {
    FormStepper,
    InstantCreditFormComplete,
    FormNavigationConfirmationModal,
    EmailInstantCreditApplicationModal
  },
  mixins: [
    TenantsMixin,
    FormMixin
  ],
  data () {
    return {
      pdf,
      /// ///////////////////////////
      // FormStepper data
      /// ///////////////////////////
      formTitle,
      formDefinitions,
      formSteps: [],
      // formData should hold data needed to make a POST to api
      formData: this.$initFormData(formDefinitions),
      completed: {
        companyName: '',
        contactName: '',
        contactEmail: '',
        customerSegment: '',
        priceClass: '',
        assignedBranch: '',
        accountNumber: ''
      },
      resData: null,
      applicationKey: null,
      /// ///////////////////////////
      // Form usage-specific data
      /// ///////////////////////////
      // attachmentId: null,
      showEmailInstantCreditApplicationModal: false,
      signatoryEmail: '',
      emailSent: false,
      phoneValid: (value) => {
        return !(value.length > 0 && value.length !== 14)
      }
    }
  },
  computed: {
    isSaveValid () {
      // // set logic for is save valid here

      const companyName = this.formData[COMPANY_INFORMATION].companyName
      return companyName != null ? companyName.length > 0 : false
    },
    isSubmitValid () {
      // && user == branchManager.identity?
      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''

      return !this.$v.$invalid &&
        (
          (this.needsBranchManagerSignature && this.branchManager !== null && currentUser.sso === this.branchManager.BranchManagerSSO) ||
          (this.applicant === null || !this.applicant.HasSigned)
        )
    },
    getCompleteData () {
      return {
        ...this.completed
      }
    },
    applicant () {
      let applicant = null
      if (this.formData.signatories !== undefined && this.formData.signatories.length > 0) {
        applicant = this.formData.signatories.find(x => x.Role === 'Applicant')
      }

      return applicant
    },
    branchManager () {
      let branchManager = null
      if (this.formData.signatories !== undefined && this.formData.signatories.length > 1) {
        branchManager = this.formData.signatories.find(x => x.Role === 'Branch Manager')
      }

      console.log('BranchManager', branchManager)
      return branchManager
    },
    submitLabel () {
      let label = 'Email to Customer'

      if (this.applicant !== null && this.applicant.HasSigned) {
        label = 'Review and Sign'
      }

      return label
    },
    needsBranchManagerSignature () {
      if (this.applicant !== null && this.applicant.HasSigned && this.branchManager !== null && !this.branchManager.HasSigned) {
        return true
      }
      return false
    },
    hideSaveButton () {
      return this.applicant !== null && this.applicant.HasSigned
    }
  },
  watch: {
    'formData.physicalAddress.sameAsMailingAddress': {
      handler (newVal) {
        if (this.loading === false) {
          const mailingAddressData = this.getMailingAddressData(newVal)
          this.setAddressData(mailingAddressData)
        }
      }
    },
    'formData.signatoryInformation.sameAsPayableContact': {
      handler (newVal) {
        if (this.loading === false) {
          const data = this.getPayableContactData(newVal)
          this.setSignatoryData(data)
        }
      }
    },
    'formData.mailingAddress': {
      handler (newVal) {
        if (this.loading === false && this.formData[PHYSICAL_ADDRESS].sameAsMailingAddress) {
          const mailingAddressData = this.getMailingAddressData(true)
          this.setAddressData(mailingAddressData)
        }
      },
      deep: true
    },
    'formData.contactInformation': {
      handler (newVal) {
        if (this.loading === false && this.formData[SIGNATORY_INFORMATION].sameAsPayableContact) {
          const data = this.getPayableContactData(true)
          this.setSignatoryData(data)
        }
      },
      deep: true
    },
    'formData.signatoryInformation': {
      immediate: true,
      deep: true,
      handler (newVal, oldVal) {
        if (newVal.emailAddress.length > 0) {
          this.signatoryEmail = newVal.emailAddress
        }
      }
    }
  },
  async mounted () {
    if (!this.$isFormInProgress()) {
      const guid = this.$createGuid()

      this.attachmentId = guid
      this.formData[SALES_INFORMATION].storageGuid = guid
    } else {
      this.$setFormFileMappings([
        {
          formFileType: 'taxDoc',
          formSection: SALES_INFORMATION,
          formSectionField: 'selectedDocuments'
        }
      ])
    }
  },
  methods: {
    /// ///////////////////////////
    // FORM EVENT HANDLERs
    /// ///////////////////////////

    buildFormData (formObject, isSave) {
      const formData = new FormData()
      const taxDoc = this.$setDocumentValue(this.formData[SALES_INFORMATION].selectedDocuments)
      const hasAttachedFiles = (taxDoc != null) ? 1 : 0

      // This is where files are aggregated to send to the BE on Save
      if (isSave) { // formdata code in isSave can be generic/DRY (to reuse in JobAccountform)
        // On Save we need to append an array of files to formData
        const attachments = {}
        if (taxDoc != null) { attachments.taxDoc = taxDoc }

        Object.entries(attachments).forEach(([key, value]) => {
          formData.append('files', value)
          formData.append('filesMetaData', key)
        })

        formData.append('files', attachments)
      }

      // DRY THIS // ================
      const attachmentId = this.attachmentId
      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''

      const bannerName = this.$route.params.tenant

      const banner = this.company != null ? this.company : findBanner(bannerName)
      const bannerId = banner.tenantId

      const accountName = this.formData.companyInformation.companyName

      const accountNumber = this.completed.accountNumber

      const assignedBranchID = this.formData.salesInformation.branch.text

      const finalData = {

        appType: this.INSTANT_CREDIT_FORM_KEY,
        bannerName,
        accountName,
        accountNumber,
        projectName: '',
        hasAttachedFiles,
        bannerId,
        assignedBranchID,

        currentUser,
        attachmentId,
        isInternal: this.$route.meta.isInternalForm,
        ...formObject // merge generated data w/ finalData
      }

      this.$populateFormDataObject(formData, finalData)
      // ==============================

      formData.append('taxDoc', taxDoc)

      return formData
    },

    getAdditionalFormData () {
      const submittersEmail = this.identity != null ? this.identity.email : ''
      return {
        submittersEmail/*,
        applicationId,
        applicationKey */
      }
    },

    // TODO:: DRY AND MOVE THIS TO MIXIN, ALL FORMS WILL DO THE SAME THING
    async save (state = null) {
      this.loading = true
      const isCompleted = state === this.FORM_IS_COMPLETED

      const formObject = this.$buildFormObject(this.INSTANT_CREDIT_FORM_KEY, this.getAdditionalFormData())
      const saveInProgressFormRequestObject = this.$buildSaveInProgressDataRequest(formObject, isCompleted)
      const inProgressFormDataStorageRequest = this.buildFormData(saveInProgressFormRequestObject, true)

      let isSuccess = false

      return await this.$saveFormData(inProgressFormDataStorageRequest)
        .then((response) => {
          if (response.status === 200) {
            isSuccess = true

            // response.data.data.applicationKey
            this.applicationKey = response.data.data.applicationKey

            return response.data.data
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.error('Error saving form data', error.response)
          }
          return null
        })
        .finally(() => {
          if (state == null) {
            this.$store.dispatch('modals/open', {
              name: 'form-save-modal',
              data: { isSuccess }
            })
          }
          this.loading = false
        })
    },

    async getHelloSignApplication () {
      await this.save()

      if (this.applicant === null || !this.applicant.HasSigned) {
        this.showEmailInstantCreditApplicationModal = true
      } else {
        await this.getApplication()
      }
    },

    // TODO:: decompose below into a buildFormData method to be also used by save() // see JA form
    async submit (applicationKey = null) {
      this.loading = true

      const formObject = this.$buildFormObject(INSTANT_CREDIT_FORM_KEY, this.getAdditionalFormData())
      const formData = this.buildFormData(formObject, true)

      return await submitInstantCreditForm(this.$route.params.tenant, applicationKey, formData).then(res => {
        const accountNumber = res.data.data.complete.accountNumber

        const {
          companyInformation,
          contactInformation,
          salesInformation
        } = this.formData

        // set values to be passed to the completed view component
        this.completed.companyName = companyInformation.companyName
        this.completed.contactName = `${contactInformation.firstName} ${contactInformation.lastName}`
        this.completed.contactEmail = contactInformation.contactEmailAddress
        this.completed.customerSegment = salesInformation.customerTypeCategory
        this.completed.priceClass = salesInformation.customerPriceClass
        this.completed.assignedBranch = salesInformation.branch.text
        this.completed.accountNumber = accountNumber

        return accountNumber
      })
        .catch((err) => {
          this.$handleFormErrors(
            err.response.data,
            this.formDefinitions
          )
          return null
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPayableContactData (getPayableContactData) {
      const keys = [
        'firstName',
        'lastName',
        'jobTitle',
        'contactEmailAddress',
        'contactPhoneNumber'
      ]

      const signatoryData = {}
      keys.map(k => {
        signatoryData[k] = ''
        return k
      })

      const payableContactData = this.formData[CONTACT_INFORMATION]
      if (Object.keys(payableContactData).length <= 0 || !getPayableContactData) {
        return signatoryData
      }

      keys.map(k => {
        if (typeof payableContactData[k] === 'undefined') return k
        const addressValue = payableContactData[k]
        signatoryData[k] = addressValue
        return k
      })

      return signatoryData
    },
    setSignatoryData (data) {
      this.formData[SIGNATORY_INFORMATION].firstName = data.firstName
      this.formData[SIGNATORY_INFORMATION].lastName = data.lastName
      this.formData[SIGNATORY_INFORMATION].jobTitle = data.jobTitle
      this.formData[SIGNATORY_INFORMATION].emailAddress = data.contactEmailAddress
      this.formData[SIGNATORY_INFORMATION].phoneNumber = data.contactPhoneNumber
    },
    getMailingAddressData (getAddressData) {
      const keys = [
        'streetAddress1',
        'streetAddress2',
        'city',
        'state',
        'zipCode'
      ]

      const physicalAddressData = {}
      keys.map(k => {
        physicalAddressData[k] = ''
        return k
      })

      const usedAddressData = this.formData[MAILING_ADDRESS]
      if (Object.keys(usedAddressData).length <= 0 || !getAddressData) {
        return physicalAddressData
      }

      keys.map(k => {
        if (typeof usedAddressData[k] === 'undefined') return k
        const addressValue = usedAddressData[k]
        physicalAddressData[k] = addressValue
        return k
      })

      return physicalAddressData
    },
    setAddressData (data) {
      this.formData[PHYSICAL_ADDRESS].streetAddress1 = data.streetAddress1
      this.formData[PHYSICAL_ADDRESS].streetAddress2 = data.streetAddress2
      this.formData[PHYSICAL_ADDRESS].city = data.city
      this.formData[PHYSICAL_ADDRESS].state = data.state
      this.formData[PHYSICAL_ADDRESS].zipCode = data.zipCode
    },
    async sendEmail (emailRecipients, subject, body) {
      body = body.replace(/\n/g, '<br />')

      const companyName = this.formData.companyInformation.companyName

      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''

      const params = JSON.stringify({ emailRecipients, subject, body, companyName, currentUser })

      const tenant = this.$route.params.tenant
      const applicationKey = this.applicationKey

      axios.post(`/api/InstantCredit/${tenant}/${applicationKey}/sendToCustomer`, params, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          this.showEmailInstantCreditApplicationModal = false
          this.emailSent = true
          this.successMessage = 'Email sent'

          window.location.href = '/admin/' + tenant

          // this.$router.push({ name: 'dashboard', params: { tenant } })
        })
        .catch((err) => {
          this.showEmailInstantCreditApplicationModal = false
          this.errorMessage = 'Error sending email'
          console.error('Error sending instant credit application to customer', err)
        })
    },
    closeEmailModal () {
      this.emailPostValidation = false
      this.showEmailInstantCreditApplicationModal = false
      this.successMessage = ''
    },
    formatDate (datestring) {
      if (datestring != null) {
        const date = new Date(datestring).toLocaleString()
        const segments = date.split(',')
        return segments[0] + ' at ' + segments[1]
      }
    },
    async getApplication () {
      const tenant = this.$route.params.tenant
      // const applicationKey = this.formData.applicationInformation.applicationKey
      const role = 'BranchManager'

      return await this.$getFormDataApplicationForSigning(tenant, this.applicationKey, role)
    }
  },
  validations () {
    const use2ndPrincipal = this.formData.principalInformation.use2ndPrincipal
    return {
      formData: {
        [COMPANY_INFORMATION]: {
          companyName: {
            maxLength: maxLength(35),
            required
          },
          companyTelephoneNumber: {
            minLength: minLength(14),
            required
          },
          companyEmailAddress: {
            required,
            email
          },
          federalTaxIdNumber: {
            maxLength: maxLength(35)
          }
        },
        [MAILING_ADDRESS]: {
          streetAddress1: {
            maxLength: maxLength(35),
            required
          },
          streetAddress2: {
            maxLength: maxLength(35)
          },
          city: {
            required,
            maxLength: maxLength(35)
          },
          state: {
            required
          },
          zipCode: {
            required,
            maxLength: maxLength(10)
          }
        },
        [PHYSICAL_ADDRESS]: {
          sameAsMailingAddress: {

          },
          streetAddress1: {
            maxLength: maxLength(35),
            required
          },
          streetAddress2: {
            maxLength: maxLength(35)
          },
          city: {
            required,
            maxLength: maxLength(35)
          },
          state: {
            required
          },
          zipCode: {
            required,
            maxLength: maxLength(10)
          }
        },
        [PRINCIPAL_INFORMATION]: {
          principal1FirstName: {
            required,
            maxLength: maxLength(35)
          },
          principal1LastName: {
            required,
            maxLength: maxLength(35)
          },
          principal1Title: {
            required,
            maxLength: maxLength(35)
          },
          use2ndPrincipal: {
            required
          },
          principal2FirstName: {
            required: requiredIf(function () {
              return use2ndPrincipal === '1'
            }),
            maxLength: maxLength(35)
          },
          principal2LastName: {
            required: requiredIf(function () {
              return use2ndPrincipal === '1'
            }),
            maxLength: maxLength(35)
          },
          principal2Title: {
            required: requiredIf(function () {
              return use2ndPrincipal === '1'
            }),
            maxLength: maxLength(35)
          }
        },
        [CONTACT_INFORMATION]: {
          contactPhoneNumber: {
            minLength: this.phoneValid
          },
          firstName: {
            required,
            maxLength: maxLength(35)
          },
          lastName: {
            required,
            maxLength: maxLength(35)
          },
          jobTitle: {
            maxLength: maxLength(35)
          },
          contactEmailAddress: {
            email,
            required
          },
          driversLicenseNumber: {
            required,
            maxLength: maxLength(35)
          },
          driversLicenseIssuingState: {
            required
          },
          driversLicenseExpirationDate: {
            required
          }
        },
        [ELECTRICIANS_LICENSE]: {
          electriciansLicenseNumber: {
            maxLength: maxLength(35)
          },
          electriciansLicenseIssuingState: {

          },
          electriciansLicenseExpirationDate: {

          }
        },
        [SIGNATORY_INFORMATION]: {
          sameAsPayableContact: {

          },
          phoneNumber: {
            minLength: this.phoneValid
          },
          firstName: {
            required,
            maxLength: maxLength(35)
          },
          lastName: {
            required,
            maxLength: maxLength(35)
          },
          emailAddress: {
            email,
            required
          },
          jobTitle: {
            maxLength: maxLength(35)
          }
        },
        [SALES_INFORMATION]: {
          selectedDocuments: {
            required: requiredIf(function () {
              return this.formData[SALES_INFORMATION].isTaxExempt === '1'
            })
          },
          storageGuid: {
            required: requiredIf(function () {
              const collection = this.formData[SALES_INFORMATION].selectedDocuments
              if (collection instanceof FormData) {
                return true
              } else {
                return collection != null ? collection.length > 0 : false
              }
            })
          },
          branch: {
            required,
            mustBeObject
          },
          approver: {
            required
          },
          isTaxExempt: {
            required
          },
          customerTypeCategory: {
            required
          },
          customerType: {
            required
          },
          insideSalesPerson: {
            mustBeObjectOrEmpty,
            required: requiredIf(function () {
              return !this.formData[SALES_INFORMATION].insideSalesPerson && !this.formData[SALES_INFORMATION].outsideSalesPerson
            })
          },
          outsideSalesPerson: {
            mustBeObjectOrEmpty,
            required: requiredIf(function () {
              return !this.formData[SALES_INFORMATION].insideSalesPerson && !this.formData[SALES_INFORMATION].outsideSalesPerson
            })
          }
        },
        [NOTES]: { // FormStep components still expects step to pass a vuelidate object
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins/_flexbox.scss";
.download-button {
  background-color: #099c98;
  font-size: 0.64rem;
  margin: 0.375rem;
  padding: 0.5rem 1rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
#application-component {
  padding: 1em;
  padding-right: 3em;
}

#accordion {
  padding-inline-start: 0;
}
</style>
