<template>
  <div>
    <div class="row">
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.firstName"
        :value="item.firstName"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.lastName"
        :value="item.lastName"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.middleInitial"
        :value="item.middleInitial"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.suffix"
        :value="item.suffix"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.jobTitle"
        :value="item.jobTitle"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.email"
        :value="item.email"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.phone"
        :value="item.phone"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.streetAddress"
        :value="item.streetAddress"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.addressUnitNumber"
        :value="item.addressUnitNumber"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.city"
        :value="item.city"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.country"
        :value="item.country"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.state"
        :value="item.state"></FormItemCollectionItemLabelAndValue>
      <FormItemCollectionItemLabelAndValue
        :label="formLabels.postalCode"
        :value="item.postalCode"></FormItemCollectionItemLabelAndValue>
    </div>
  </div>
</template>

<script>
import FormItemCollectionItemLabelAndValue from '@/components/form/FormItemCollectionItemLabelAndValue.vue'

export default {
  name: 'FormItemSignatory',
  components: {
    FormItemCollectionItemLabelAndValue
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    formLabels: {
      type: Object,
      required: true
    }
  }
}
</script>
