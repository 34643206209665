<template>
  <DefaultModal
    :title="(item.accountLabel.length > 0 ? item.accountLabel : '-')"
    modal-name="saved-filed-modal"
    max-width="500px"
    @on-close="onClose">
    <v-row class="m-0 mt-6 subtitle-2">
      <div>
        <div class="font-weight-bold">
          Status:
        </div>
        <div>
          {{ item.status }}
        </div>
      </div>

      <div class="pl-14">
        <div class="font-weight-bold">
          Job Name
        </div>
        <div>
          {{ item.projectName.length > 0 ? item.projectName : '-' }}
        </div>
      </div>
    </v-row>

    <v-row v-if="savedAttachments != null && savedAttachments.length > 0">
      <v-card-text class="pb-0">
        <v-list>
          <div class="mb-4 font-weight-bold">
            Attached Files ({{ savedAttachments.length }})
          </div>
          <v-list-item-group
            style="border-top: thin solid rgba(0,0,0,.12)"
            color="primary">
            <template v-for="(attachment, i) in savedAttachments">
              <v-list-item
                :key="i"
                style="border: thin solid rgba(0,0,0,.12); border-top: 0;"
                @click="download(attachment)">
                <template #default="{}">
                  <div
                    style="width: 100%;"
                    class="flex-row p-2 d-flex align-center">
                    <img
                      style="height: 4em;"
                      :src="getFileExtensionImagePath(attachment.fileExtension)" />
                    <div class="p-2 ml-4 d-flex flex-grow-1 font-weight-bold">
                      {{ attachment.fileName }}
                    </div>
                    <div class="justify-end d-flex">
                      <v-icon
                        style="color:#000; font-size:1.6em;">
                        mdi-download
                      </v-icon>
                    </div>
                  </div>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-row>
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/prompts/DefaultModal.vue'
import {
  getFormAttachments,
  downloadFormFile
} from '@/api/forms/form-data-storage'

export default {
  name: 'SavedFilesModal',
  components: {
    DefaultModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      formInformation: null,
      feedbackSubmitting: false,
      savedAttachments: null,
      feedbackFormValid: false
    }
  },
  watch: {
    item: {
      immediate: true,
      async handler (newVal) {
        if (newVal != null) {
          await this.getSavedFiles()
        }
      }
    }
  },
  methods: {
    getFileExtensionImagePath (fileType) {
      console.log('file:', fileType)
      const basePath = '/images/'
      switch (fileType) {
        case '.pdf':
          return `${basePath}pdf.svg`
        case '.jpg':
        case '.jpeg':
          return `${basePath}jpg.svg`
        case '.doc':
        case '.docx':
          return `${basePath}word.svg`
        case '.xls':
        case '.xlsx':
          return `${basePath}excel.svg`
        case '.zip':
          return `${basePath}zip.svg`
        default:
          return `${basePath}file.svg`
      }
    },

    async download (item) {
      const {
        fileName,
        contentType
      } = item

      const targetPath = `${item.bannerCode}/${item.formType}/${item.attachmentId}/${item.formFileType}/${item.fileName}`

      const results = await downloadFormFile(targetPath)
        .then((res) => {
          return res
        })

      const bytes = results.data
      const file = new Blob([bytes], { type: contentType })
      const fileURL = window.URL.createObjectURL(
        file
      )

      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.download = fileName
      document.body.appendChild(fileLink)
      fileLink.click()
    },

    async getSavedFiles () {
      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''

      this.loading = true

      const files = await getFormAttachments({
        formType: this.item.appType,
        attachmentId: this.item.attachmentId,
        bannerCode: this.item.bannerName,
        currentUser,
        formName: this.item.formName
      })
        .then((res) => {
          if (res.data != null) {
            return res.data
          }
        })

      if (files != null) {
        this.savedAttachments = files
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
.file-list{
  border: solid;
  border: black;
}
</style>
