export class TaxCert {
  constructor (taxCert = {}) {
    this.batchId = taxCert.batchId || 0
    this.isEnterprise = taxCert.isEnterprise || false
    this.accountNumber = taxCert.accountNumber || null
    this.accountName = taxCert.accountName || null
    this.taxCertificate = taxCert.taxCertificate || null
    this.submittedByEmail = taxCert.submittedByEmail || null

    if (taxCert.uploadDate) {
      this.uploadDate = taxCert.uploadDate.split('T')[0]
    } else {
      this.uploadDate = null
    }

    if (taxCert.effectiveDate) {
      this.effectiveDate = taxCert.effectiveDate.split('T')[0]
    } else {
      this.effectiveDate = null
    }

    if (taxCert.expirationDate) {
      this.expirationDate = taxCert.expirationDate.split('T')[0]
    } else {
      this.expirationDate = null
    }

    this.states = taxCert.states || []
    this.statusCode = taxCert.statusCode || 1
    this.subAccounts = taxCert.subAccounts || []
    this.reviewerInitials = taxCert.reviewerInitials || null
    this.reviewedDate = taxCert.reviewedDate || null
  }
}

export class TableTaxCert {
  constructor (taxCert = {}) {
    this.batchId = taxCert.batchId
    this.name = taxCert.displayName
    this.account = taxCert.displayNumber

    if (taxCert.uploadDate) {
      this.uploadDate = taxCert.uploadDate.split('T')[0]
    } else {
      this.uploadDate = taxCert.uploadDate
    }

    if (taxCert.effectiveDate) {
      this.effectiveDate = taxCert.effectiveDate.split('T')[0]
    } else {
      this.effectiveDate = taxCert.effectiveDate
    }

    if (taxCert.expirationDate) {
      this.expirationDate = taxCert.expirationDate.split('T')[0]
    } else {
      this.expirationDate = taxCert.expirationDate
    }

    this.states = taxCert.states
    this.status = taxCert.statusCode
    this.subAccounts = taxCert.subAccounts
  }
}
