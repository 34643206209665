<template>
  <v-dialog
    style="border-radius: 0"
    :value="$isOpen"
    :max-width="maxWidth"
    persistent>
    <PageSpinner
      v-if="loading"
      :loading="loading"></PageSpinner>
    <v-card
      v-else
      style="border-radius: 0">
      <v-toolbar
        style="background-color: #006330"
        flat
        dark>
        <div class="ml-2 h5">
          {{ title }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="$close()">
          <v-icon style="font-size: 1.5em">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <!-- Renders Child Component-->
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseModalMixin from '@/components/prompts/BaseModal.mixin.js' // eslint-disable-line import/extensions
import PageSpinner from '@/components/PageSpinner.vue'
export default {
  name: 'DefaultModal',
  components: {
    PageSpinner
  },
  mixins: [
    BaseModalMixin
  ],
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    modalName: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      required: false,
      default: '800px'
    }
  },
  beforeMount () {
    this.name = this.modalName
  }
}
</script>
