<template>
  <nav class="navbar navbar-light bg-white">
    <div
      v-if="$route.params.tenant"
      class="d-flex justify-content-center navbar-brand">
      <img
        :src="require('assets/images/logos/' + $route.params.tenant + '.svg')"
        alt="Logo" />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TenantBanner'
}
</script>
<style lang="scss" scoped>
.navbar-brand {
  margin-right: 0;
  img {
    max-height: 3rem;
  }
}
</style>
