<template>
  <div v-if="show">
    <v-select
      v-model="selectedSwitchBanner"
      :items="userBanners"
      item-text="name"
      hide-details
      filled
      dark
      background-color="#084845"
      color="white"
      class="mb-2 primary text-capitalize rounded-0"
      @change="changeBanner">
      <span
        slot="label"
        class="white--text text-uppercase">
        Banner
      </span>
      <v-list-item
        slot="prepend-item">
        <v-list-item-title class="text-uppercase menu-title">
          Select a Banner
        </v-list-item-title>
      </v-list-item>
      <span
        slot="item"
        :key="props.item.name"
        slot-scope="props"
        class="text-capitalize"
        :class="props.item === company ? 'font-weight-bold' : ''">
        {{ props.item.name }}
      </span>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BannerSwitcher',
  data () {
    return {
      selectedSwitchBanner: null
    }
  },
  computed: {
    ...mapGetters(['banners', 'company', 'identity']),
    show () {
      return this.identity.banners.length > 1
    },
    userBanners () {
      if (this.banners.length === 0 || this.identity.banners === undefined) return []
      const identityBanners = this.identity.banners
      const foundIndex = identityBanners.findIndex(x => x === 'res')
      identityBanners[foundIndex] = 'rexelenergy'
      const results = this.banners.filter(t => {
        return identityBanners.indexOf(t.name.toLowerCase()) !== -1
      })
      if (results.length <= 1) {
        return []
      }
      return results
    }
  },
  watch: {
    'identity.sso': {
      immediate: true,
      handler (val) {
        if (val) this.selectedSwitchBanner = this.company
      }
    },
    company (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedSwitchBanner = newVal
      }
    }
  },
  mounted () {
    this.selectedSwitchBanner = this.banners.find(banner => banner.name.toLowerCase() === this.identity.banner)
  },
  methods: {
    ...mapActions(['setCurrentBanner']),
    changeBanner (banner) {
      const bannerName = banner.toLowerCase()
      this.setCurrentBanner(bannerName)
      this.$router.push({ name: 'dashboard', params: { tenant: bannerName } })
    }
  }
}
</script>

<style lang="scss">
.menu-title {
  font-weight: 600;
  font-size: 13px !important;
  letter-spacing: normal !important;
}
</style>
