<template>
  <div
    v-cloak
    id="users-list">
    <div class="users-header">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="searchQuery"
                class="form-control form-control-row"
                placeholder="Search For User" />
              <span class="input-group-btn">
                <a class="btn btn-secondary">
                  <i class="fa fa-search"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="col-auto ml-2">
          <div class="form-group">
            <div class="input-group">
              <label class="input-group-addon">
                Filter By
              </label>
              <select
                v-model="filterBy"
                class="form-control">
                <option
                  v-for="(option, index) in filterOptions"
                  :key="index + '-filter-by-options'">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="text-right col">
          <button
            v-if="identity.permissions.editUsers"
            type="button"
            class="btn btn-primary btn-rounded"
            @click="createUser()">
            Create User
          </button>
        </div>
      </div>
    </div>
    <!-- Body -->
    <div class="py-3 users-body">
      <v-client-table
        :data="filteredUsers"
        :columns="columns"
        :options="options"
        @row-click="rowClicked($event)"></v-client-table>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from 'ca-http-service'
import BannerMixin from 'mixins/banner.mixin'
import { mapState } from 'vuex'

export default {
  name: 'UsersList',
  mixins: [BannerMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getData()
      vm.getFilterOptions()
    })
  },
  data () {
    return {
      filterOptions: ['All'],
      filterBy: 'All',
      searchQuery: '',
      users: [],
      columns: ['name', 'username', 'role'],
      options: {
        sortable: ['name', 'username', 'role'],
        sortIcon: {
          base: 'fa',
          up: 'fa-caret-up',
          down: 'fa-caret-down'
        },
        orderBy: {
          column: 'name',
          ascending: true
        },
        filterable: false,
        highlightMatches: false,
        perPage: 250,
        perPageValues: [25, 50, 100, 150, 250]
      }
    }
  },
  computed: {
    ...mapState([
      'identity',
      'company'
    ]),
    tenant () {
      return this.company.name.toLowerCase()
    },
    userList () {
      if (this.filterBy === 'All') {
        return this.users
      }

      const selectedRole = this.filterBy.replace(' ', '')
      return this.users.filter((u) => { return selectedRole === u.role })
    },
    filteredUsers () {
      const input = this.searchQuery.toLowerCase()
      return this.userList.filter((a) => {
        return a.search.indexOf(input) !== -1
      })
    }
  },
  watch: {
    selectedBanner () { // comes from banner mixin
      this.getData()
    }
  },
  mounted () {
    this.$store.dispatch('setAdminHeaderTitle', 'Manage Users')
    if (!this.identity.permissions.viewUsers) {
      const tenant = this.identity.banner
      this.$router.push({ name: 'dashboard', tenant })
    }
  },
  methods: {
    async getData () {
      const bannerId = this.company.tenantId || this.identity.bannerId

      if (bannerId != null) {
        if (this.identity.permissions.viewUsers === true && bannerId != null) {
          const response = await axios
            .get('/api/user/getListViewModel', { params: { tenantId: bannerId } })
            .then((res) => {
              if (res.status === 200) {
                return res.data
              } else {
                return []
              }
            })
            .catch((err) => {
              console.error(err)
            })

          if (response != null) {
            this.users = response.map(function (user) {
              user.name = user.fullName ? user.fullName : ''
              user.username = user.email

              return user
            })
          }
        }
      }
    },
    async getFilterOptions () {
      if (this.$store.getters.userRoles.length > 0) {
        this.setFilterOptions(this.$store.getters.userRoles)
      } else {
        const response = await axios
          .get('/api/user/getRoleList')
          .then((res) => {
            if (res.status === 200) {
              return res.data
            } else {
              return []
            }
          })
          .catch((err) => {
            console.error(err)
          })

        if (response != null) {
          this.$store.dispatch('setUserRoles', response)
          this.setFilterOptions(response)
        }
      }
    },
    setFilterOptions (data) {
      this.filterOptions = data.map((i) => { return i.value })
      this.filterOptions.unshift('All')
    },
    rowClicked (event) {
      const row = event.row
      this.$router.push({ name: 'usersDetail', params: { id: row.userId } })
    },
    createUser () {
      this.$router.push({ name: 'usersCreate' })
    }
  }
}
</script>

<style lang="scss" scoped>
    .form-group {
        margin-bottom: 0;
    }
</style>
