<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="comment-modal"
    @close="closeModal()"
    @closeModal="closeModal()">
    <template slot="header">
      Edit Company Information
    </template>
    <div slot="body">
      <div class="form-group">
              <label class="form-control-label">
                Account Number - Please delete the old account if it is no longer valid
              </label>
              <input
                v-model="newAccountNum"
                v-focus
                type="number"
                maxlength="100"
                class="form-control" />
              <span
                v-if="error"
                class="error-message">
                {{ error }}
              </span>
            </div>
      </div>
    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="closeModal()">
        Cancel
      </a>
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        :class="{'disabled': loading}"
        :disabled="loading"
        @click="setCustomerAccount()">
        <span v-if="!loading">
          Save
        </span>
        <span v-else>
          <img
            src="../../../../assets/images/puff.svg"
            class="loading-img-btn" /> Saving...
        </span>
      </button>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '../../../../components/GenericModal.vue'
import axios from 'ca-http-service'

export default {
  name: 'EditCompanyModal',
  components: {
    GenericModal
  },
  props: {
    app: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      appForm: this.app,
      newAccountNum: null,
      error: null,
      modalState: false,
      loading: false,
      states: []
    }
  },
  async mounted () {
    this.states = await this.getStates()
  },
  methods: {
    setCustomerAccount: function () {
      this.loading = true

      axios({
        url: `/api/ErpReconciliation/setCustomerAccount/${this.appForm.guid}/${this.newAccountNum}`,
        method: 'GET'
      }).then((res) => {
        this.loading = false
        if (res.data.isSuccess) {
          this.appForm.businessInfo.accountNumber = this.newAccountNum
          if (res.data.data) {
            this.appForm.erpEntityInfo = res.data.data
          }
          this.closeModal()
        } else {
          this.error = res.data.message
        }
      }).catch(() => {
        this.loading = false
      })
    },
    closeModal: function () {
      this.modalState = false
      this.error = null
      this.loading = false
    },
    async getStates (country = 'US') {
      return axios.get('/generic/getStateList', {
        params: {
          countryCode: country
        }
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return []
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
</script>
