<template>
  <div
    id="review-component"
    class="component-container">
    <ReviewCertModal
      ref="reviewCertModal"
      :can-edit="canEdit"
      :states="states"
      :sub-accounts="subAccounts"
      :status-codes="statusCodes"
      @saveTaxCertReview="saveTaxCertReview"></ReviewCertModal>
    <UploadCertModal
      ref="uploadCertModal"
      :states="states"
      :status-codes="statusCodes"
      :get-sub-accounts="getSubAccounts"
      @createTaxCertReview="createTaxCertReview"></UploadCertModal>

    <div
      v-if="loading"
      class="spinner show-spinner"></div>
    <div
      v-cloak
      id="tax-review">
      <div class="row justify-content-between align-items-end mb-3">
        <div class="col-12 text-center text-sm-right mt-2 mt-sm-0">
          <button
            type="button"
            class="btn btn-primary btn-rounded float-right"
            @click="openUploadCert()">
            <i class="fa fa-plus"></i> New
          </button>
        </div>
      </div>
      <div
        id="review-header"
        class="row flex-wrap align-items-center">
        <div class="col-12 col-lg-4 pr-lg-0">
          <div
            id="search-customer"
            class="form-group">
            <div class="input-group">
              <input
                v-model="searchQuery"
                class="form-control tax-cert-list-search form-control-row"
                type="text"
                placeholder="Search by customer name or account #" />
              <span class="input-group-btn">
                <a class="btn btn-secondary">
                  <i class="fa fa-search"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-4 pr-lg-0">
          <div
            id="filter-status"
            class="form-group">
            <div class="input-group">
              <label class="input-group-addon">
                Filter By
              </label>
              <select
                v-model="filterBy"
                class="form-control">
                <option
                  v-for="(filter, index) in filterOptions"
                  :key="index">
                  {{ filter }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4 justify-content-end pl-md-0">
          <div
            id="review-legend"
            class="d-flex flex-row flex-wrap form-group justify-content-around justify-content-md-end">
            <div class="legend-approved">
              <i class="fa fa-check-circle"></i> Approved
            </div>
            <div class="legend-denied">
              <i class="fa fa-times-circle"></i> Denied
            </div>
            <div class="legend-pending">
              <i class="fa fa-minus-circle"></i> Pending
            </div>
          </div>
        </div>
      </div>
      <div id="review-body">
        <v-client-table
          ref="client-table"
          name="taxTable"
          :data="filteredCertificates"
          :columns="columns"
          :options="options"
          @row-click="rowClicked($event)"></v-client-table>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">

import axios from 'ca-http-service'
import Vue from 'vue'
import ReviewCertModal from './components/ReviewModal.vue'
import UploadCertModal from './components/UploadModal.vue'
import ApprovedTag from './components/ApprovedTag.vue'
import { TableTaxCert, TaxCert } from './TaxCert.model'
import BannerMixin from 'mixins/banner.mixin'
import CompanyMixin from 'mixins/company.mixin'
import { mapGetters } from 'vuex'

Vue.component('ApprovedTag', ApprovedTag)

export default {
  name: 'TaxCertList',
  components: {
    ReviewCertModal,
    UploadCertModal
  },
  mixins: [BannerMixin, CompanyMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getData()
      vm.getStatusCodes()
      vm.getStates()
      vm.validateBanner()
    })
  },
  data () {
    return {
      loading: true,
      filterOptions: ['All', 'Pending', 'Denied', 'Approved'],
      filterBy: 'Pending',
      statusCodesDict: {
        Pending: 1,
        Approved: 2,
        Denied: 3
      },
      statusCodes: [],
      searchQuery: '',
      items: [],
      states: [],
      subAccounts: [],
      columns: ['account', 'name', 'uploadDate', 'effectiveDate', 'expirationDate', 'status'],
      options: {
        sortable: ['account', 'name', 'uploadDate', 'effectiveDate', 'expirationDate'],
        sortIcon: {
          base: 'fa',
          up: 'fa-caret-up',
          down: 'fa-caret-down'
        },
        orderBy: {
          column: 'uploadDate',
          ascending: false
        },
        filterable: false,
        highlightMatches: false,
        perPage: 250,
        perPageValues: [25, 50, 100, 150, 250],
        templates: {
          status: 'approved-tag'
        },
        saveState: true,
        customSorting: {
          uploadDate: function (ascending) {
            return function (a, b) {
              const dateA = new Date(a.uploadDate)
              const dateB = new Date(b.uploadDate)

              if (ascending) { return dateA >= dateB ? 1 : -1 }

              return dateA <= dateB ? 1 : -1
            }
          },
          effectiveDate: function (ascending) {
            return function (a, b) {
              const dateA = new Date(a.effectiveDate)
              const dateB = new Date(b.effectiveDate)

              if (ascending) { return dateA >= dateB ? 1 : -1 }

              return dateA <= dateB ? 1 : -1
            }
          },
          expirationDate: function (ascending) {
            return function (a, b) {
              const dateA = new Date(a.expirationDate)
              const dateB = new Date(b.expirationDate)

              if (ascending) { return dateA >= dateB ? 1 : -1 }

              return dateA <= dateB ? 1 : -1
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'user', // TODO - TAX CERT - PLATT ONLY: update, user no longer exists, should use identity
      'company'
    ]),
    tenant () {
      return this.company.name.toLowerCase()
    },
    canEdit () {
      return this.identity.permissions.editApplications
    },
    certificates () {
      const status = this.statusCodesDict[this.filterBy]
      if (status) {
        return this.items.filter((item) => item.status === status)
      } else {
        return this.items
      }
    },
    filteredCertificates () {
      const that = this
      const byAccountNumber = parseInt(that.searchQuery)

      return this.certificates.filter((a) => {
        let filterSearch
        if (!isNaN(byAccountNumber)) {
          filterSearch = a.account.toString().indexOf(that.searchQuery) !== -1 ||
            a.name.toString().indexOf(that.searchQuery) !== -1 ||
            a.subAccounts.indexOf(that.searchQuery) !== -1
        } else {
          filterSearch = a.name.toLowerCase().indexOf(that.searchQuery.toLowerCase()) !== -1
        }
        return filterSearch
      })
    }
  },
  watch: {
    selectedBanner (val, old) { // comes from banner mixin
      this.validateBanner()
    }
  },
  mounted () {
    this.$store.dispatch('setAdminHeaderTitle', 'Customer Tax Documents')
  },
  methods: {
    updateArray (data) {
      const i = this.items.findIndex((li) => li.batchId === data.batchId)
      if (i > -1) {
        this.$set(this.items, i, new TableTaxCert(data))
      } else {
        const item = new TableTaxCert(data)
        this.items.unshift(item)
      }
    },
    validateBanner () {
      if (!this.isPlatt(this.selectedBanner)) {
        this.$router.push({ name: 'dashboard', params: { tenant: this.tenant } })
      }
    },
    getData () {
      this.loading = true
      const tenant = this.$route.params.tenant
      axios.get(`/api/TaxReview/getListViewModel/${tenant}`)
        .then((res) => {
          if (res.status === 200) {
            this.items = res.data.map((item) => {
              return new TableTaxCert(item)
            })
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    getStates () {
      if (this.$store.getters.statesUS.length > 0) {
        this.states = this.$store.getters.statesUS
      } else {
        axios.get('/generic/getStateList')
          .then((res) => {
            this.states = res.data
            this.$store.dispatch('setStatesUS', res.data)
          })
          .catch((err) => {
            console.err(err)
          })
      }
    },
    getStatusCodes () {
      axios.get('/api/taxReview/getStatusCodes')
        .then((res) => {
          this.statusCodes = res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getSubAccounts (id) {
      return axios.get(`/api/taxReview/getSubAccounts/${id}`)
    },
    openCertDetail (id) {
      this.loading = true
      axios.get(`/api/taxReview/getDetailViewModel/${id}`)
        .then((res) => {
          if (res.data) {
            this.$refs.reviewCertModal.taxCert = new TaxCert(res.data)

            if (res.data.isEnterprise) {
              this.getSubAccounts(res.data.accountNumber)
                .then((response) => {
                  this.subAccounts = response.data.isSuccess ? response.data.data.subAccounts : []
                  this.$refs.reviewCertModal.modalState = true
                  this.loading = false
                })
                .catch((err) => {
                  console.error(err)
                  this.loading = false
                  palert({ type: 'error', title: 'Unable to retrieve sub accounts' })
                })
            } else {
              this.$refs.reviewCertModal.modalState = true
              this.loading = false
            }
          }
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
          palert({ type: 'error', title: 'Unable to retrieve data' })
        })
    },
    openUploadCert () {
      this.$refs.uploadCertModal.modalState = true
    },
    rowClicked (click) {
      this.openCertDetail(click.row.batchId)
    },
    saveTaxCertReview (taxCert) {
      const self = this
      this.$refs.reviewCertModal.loading = true
      axios.post('/api/taxReview/review', taxCert)
        .then((res) => {
          this.$refs.reviewCertModal.loading = false
          this.$refs.reviewCertModal.modalState = false
          palert({ type: 'success', title: 'Success' })
          self.updateArray(res.data)
        })
        .catch((err) => {
          this.$refs.reviewCertModal.loading = false
          const msg = err.response.data || 'Something went wrong. Could not upload the document.'
          palert({ type: 'error', title: msg })
        })
    },
    createTaxCertReview (data) {
      const self = this
      this.$refs.uploadCertModal.loading = true

      const fd = new FormData()
      fd.append('accountName', data.accountName)
      fd.append('effectiveDate', data.effectiveDate)
      fd.append('isEnterprise', data.isEnterprise)
      fd.append('expirationDate', data.expirationDate)
      fd.append('accountNumber', data.accountNumber)
      fd.append('states', data.states)
      fd.append('statusCode', data.statusCode)
      fd.append('subAccounts', data.subAccounts)
      fd.append('file', data.taxCertificate)

      const tenant = this.$route.params.tenant
      axios.post(`/api/taxReview/create/${tenant}`, fd)
        .then((res) => {
          this.$refs.uploadCertModal.closeModal()
          palert({ type: 'success', title: 'Success' })

          self.updateArray(res.data)
        })
        .catch((err) => {
          this.$refs.uploadCertModal.loading = false
          const msg = err.response.data || 'Something went wrong. Could not upload the document.'
          palert({ type: 'error', title: msg })
        })
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_bootstrap-variables.scss";
@import "../../assets/scss/mixins/_flexbox.scss";
body {
  overflow-y: scroll;
}
#review-component {

  .tax-cert-list-search {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  #tax-review {
    padding: 0.5em !important;
    .btn-col {
      max-width: 6.25rem;

      text-align: center;
      > a {
        color: #001320;
        &:hover {
          color: darken($blue, 5%);
        }
      }
      .view-app-btn {
        padding: 0 1rem;
        display: block;

        cursor: pointer;
      }
    }
    #review-header {
      .form-group {
        //width: 100%;
        margin-bottom: 1rem;
      }

      #filter-status {
        label {
          color: #999;
          font-size: 0.875rem;
          background-color: transparent;
          border: none;
        }
      }

      #review-legend {
        > div {
          padding: 0 0.5rem;
          font-size: 0.875rem;
          vertical-align: middle;
        }
        i {
          padding: 0.25rem;

          font-size: 1rem;
        }
      }
    }
    .legend-approved {
      i:before {
        color: $green;
      }
    }
    .legend-denied {
      i:before {
        color: $red;
      }
    }
    .legend-pending {
      i:before {
        color: $blue;
      }
    }
    .VueTables {
      & > .row:first-of-type {
        height: 54px;
        margin-top: -50px;
      }
      label {
        margin-right: 5px;
      }
    }

    #review-body {
      position: relative;
      margin-top: 45px;
      .VuePagination__count {
        position: absolute;
        top: 10px;
      }
    }
  }
}
</style>
