<template>
  <div
    class="form-group"
    :style="formGroupStyles"
    :class="{'has-danger': $isErrorState()}">
    <div
      v-if="inputLabel.length > 0"
      style="font-size: 12px;"
      class="form-control-label radio-lbl"
      :class="$isRequired() && !disabled ? 'required' : ''">
      {{ inputLabel }}
    </div>
    <div
      v-if="inputSublabel && inputSublabel.length > 0"
      style="font-size: 12px;"
      class="form-control-label radio-lbl">
      <span style="color: blue;">
        {{ inputSublabel }}
      </span>
    </div>
    <label
      v-for="option in options"
      :key="option.key"
      class="custom-control custom-radio"
      :style="disabled ? 'cursor: initial;' : ''">
      <input
        :disabled="disabled"
        type="radio"
        :value="option.value"
        :checked="currentSelection===option.value"
        class="custom-control-input"
        @input="updateValue($event.target.value)" />
      <span
        class="custom-control-indicator"
        :style="disabled ? 'background-color:rgba(0,0,0,.26) !important' : ''"></span>
      <span
        style="font-size: 12px;"
        :style="disabled ? 'cursor: initial;' : ''"
        class="custom-control-description">
        {{ option.key }}
      </span>
    </label>
    <label
      v-if="inputLabel.length === 0"
      class="required">
      <div
        class="form-control-label radio-lbl required"
        style="padding: 0; left: -0.95em;"></div>
    </label>

    <FormFieldErrorMessages :vuelidate="vuelidate"></FormFieldErrorMessages>
  </div>
</template>

<script>
import FormFieldErrorMessages from '@/components/form/FormFieldErrorMessages.vue'
import FormInputMixin from './FormInput.mixin'

export default {
  name: 'FormInputRadioOptions',
  components: {
    FormFieldErrorMessages
  },
  mixins: [
    FormInputMixin
  ],
  props: {
    value: {
      type: String, // Technical Debt Todo: accept Object, so we're not limited to only passing strings as values
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    inputSublabel: {
      type: String,
      required: false,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: String, // Technical Debt Todo: accept Object, so we're not limited to only passing strings as values
      required: false,
      default: null
    },
    formGroupStyles: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      currentSelection: null
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        // watcher triggers after mounted
        this.currentSelection = this.value
      }
    }
  },
  mounted () {
    // set default value if empty
    if (this.currentSelection == null && this.defaultValue != null) {
      this.$nextTick(() => { // value dne until $nextTick
        if (this.value === '') {
          return this.updateValue(this.defaultValue)
        }
      })
    }
  },
  methods: {
    updateValue: function (value) {
      this.currentSelection = value
      this.$emit('input', value)
      this.$touch()
    }
  }
}
</script>
