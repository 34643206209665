<template>
  <div
    v-if="files.length > 0"
    class="files"
    column>
    <template v-for="(file, index) in files">
      <v-divider
        v-if="index > 0"
        :key="`d-${index}`">
      </v-divider>
      <v-layout
        :key="`f-${index}`"
        class="ma-0"
        align-center>
        <v-progress-circular
          v-if="isFileProcessing(file)"
          :size="32"
          color="primary"
          indeterminate></v-progress-circular>
        <file-icon
          v-else
          :filename="file.name"></file-icon>
        <span class="pl-1">
          {{ file.name }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          title="Remove file"
          @click="remove(file)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-layout>
    </template>
  </div>
</template>

<script>
import * as _dropzone from 'dropzone'
import FileIcon from './FileIcon.vue'

export default {
  name: 'FileList',
  components: {
    FileIcon
  },
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  methods: {
    isFileProcessing (file) {
      return file.status !== _dropzone.Dropzone.QUEUED && file.status !== _dropzone.Dropzone.SUCCESS
    },
    remove (file) {
      this.$emit('remove', file)
    }
  }
}
</script>

<style scoped>
.files {
  /**
   * Display a maximum of 4 list items before overflowing with a scrollbar, given:
   *   - List item height, 48px
   *   - Item divider height, 1px
   */
  max-height: 48px * 4 + 1px * ($max-items - 1);
  overflow: hidden;
  overflow-y: auto;
}
</style>
