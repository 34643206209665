<template>
  <v-dialog
    v-model="localIsDialogOpen"
    class="form-address-validation-modal"
    max-width="600px"
    @close="closeModal">
    <v-card>
      <v-toolbar
        flat
        color="grey lighten-3"
        class="grey--text text--lighten-1">
        <v-toolbar-title class="form-address-validation-modal__title">
          Email Instant Credit Application to Customer
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-1"
          icon
          @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="subtitle-2 font-weight-bold">
              <span v-if="accountNumber">
                {{ accountNumber }} -
              </span>{{ companyName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0">
              <v-combobox
                v-model="selectedEmails"
                class="email-modal-form-component"
                background-color="rgba(0,0,0,0.06)"
                append-icon=""
                label="To"
                :items="[]"
                multiple
                dense
                chips
                hide-no-data
                clearable>
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    close
                    @click:close="data.parent.selectItem(data.item)">
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div>
                <v-text-field
                  v-model="subject"
                  class="email-modal-form-component"
                  background-color="rgba(0,0,0,0.06)"
                  label="Subject"
                  hide-details></v-text-field>
              </div>
            </v-col>
            <v-col cols="12">
              <v-icon
                class="floating"
                @click="clipboardEmail">
                mdi-content-copy
              </v-icon>
              <v-textarea
                v-model="body"
                filled
                name="input-7-4"
                label="Email Message">
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="form-address-validation-modal__options">
        <v-btn
          class="form-address-validation-modal__button"
          tile
          @click="closeModal">
          Cancel
        </v-btn>
        <v-btn
          class="form-address-validation-modal__button form-address-validation-modal__button--done"
          tile
          :disabled="!fieldsCompleted"
          color="#099c98"
          @click="sendEmail">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'EmailInstantCreditApplicationModal',
  components: {
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    identity: {
      type: Object,
      required: true
    },
    accountNumber: {
      type: String,
      required: false,
      default: null
    },
    companyName: {
      type: String,
      required: true
    },
    signatoryEmail: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      subject: '',
      body: '',
      selectedEmails: [],
      select: ['Vuetify', 'Programming'],
      items: [
        'Programming',
        'Design',
        'Vue',
        'Vuetify'
      ]
    }
  },
  computed: {
    localIsDialogOpen: {
      get () {
        return this.showModal
      },
      set () {
        this.closeModal()
      }
    },
    formattedTenant () {
      const tenant = this.$route.params.tenant
      return tenant.charAt(0).toUpperCase() + tenant.slice(1)
    },
    fieldsCompleted () {
      return !!this.subject.length && !!this.body.length && !!this.selectedEmails.length
    }
  },
  watch: {
    showModal: function () {
      if (this.showModal !== true) {
        this.closeModal()
      } else {
        this.initData()
      }
    }
  },
  methods: {
    sendEmail () {
      this.$emit('sendEmail', this.selectedEmails, this.subject, this.body)
    },
    closeModal () {
      this.$emit('close')
    },
    clipboardEmail () {
      navigator.clipboard.writeText(this.body)
    },
    // Issues rendering data on component creation call for a manual init
    initData () {
      console.log(this.signatoryEmail)
      this.body = this.companyName + `,

Below is a unique link to your Instant credit application. Please click on the link to access and complete your application.

If you have any questions, please call us here at the branch or email me directly at ` + this.identity.email + `

Thank you,

` + this.identity.name
      this.subject = 'Instant Credit application for ' + this.formattedTenant
      this.selectedEmails.push(this.signatoryEmail)
    }
  },
  validations () {
    return {
      toEmail: {
        required
      }
    }
  }
}
</script>

<style lang="scss">
.form-address-validation-modal {
  &__title,
  &__button {
    margin: 0 0 0 1rem;
  }

  &__options {
    justify-content: flex-end;
    padding: 0 2rem 2rem 2rem;
  }

  &__button {
    &.v-btn {
      text-transform: none;
    }

    &--done {
      &.v-btn {
        color: #FFFFFF;
      }
    }
  }
}

.floating {
  position: absolute;
  left: 90%;
  top: 15%;
  cursor: pointer;
  z-index: 10;
}

.email-modal-form-component .v-input__control .v-input__slot {
  padding: 0 12px;
}
</style>
