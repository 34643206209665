<template>
  <div
    id="signatory-credit-info-component"
    class="component-container">
    <div
      v-if="s.transactionDate"
      class="card card-blue">
      <div
        id="credit-check"
        class="card-block">
        <h6 class="review-title">
          Credit Check - EXPERIAN
        </h6>
        <p>
          Employer: {{ s.employer }} <span class="small">
            (Updated {{ s.employerLastUpdatedString }})
          </span>
        </p>
        <p>Ordered: {{ s.transactionDateString }}</p>
      </div>
      <div
        id="risk-limits"
        class="card-block">
        <div class="scores">
          <div class="score">
            <p>Personal Credit Score</p>
            <h5>{{ s.creditScore }}</h5>
          </div>
          <div class="score">
            <p>
              Inquiry Count
              <span class="small">
                (Last 6 months)
              </span>
            </p>
            <h5 :style="{'color': inquiryColor }">
              {{ s.creditInquiryCount }}
            </h5>
          </div>
        </div>
        <div>
          <div class="score">
            <p>Past Due Amount</p>
            <h5>{{ s.pastDueAmount | currency }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignatoryCreditInfo',
  props: {
    s: {
      type: Object,
      required: true
    }
  },
  computed: {
    inquiryColor () {
      if (this.creditInquiryCount > 4) {
        return '#b40000'
      } else if (this.creditInquiryCount > 2) {
        return '#b47500'
      } else {
        return '#000'
      }
    }
  }
}
</script>

<style lang="scss">
#signatory-credit-info-component {
    .card {
    background-color: #F0FAFE;
    border-color: #D3EAF3;
    .review-title {
      color: #00416A;
      font-size: .875rem;
      font-weight: 700;
      span {
        margin-left: 10px;
      }
    }
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    &-block {
      padding: 1.25rem 1.25rem .5rem 1.25rem;
      @media (min-width: 768px) {
        width: calc(50% - .5rem);
      }
      p {
        font-size: .875rem;
        margin-bottom: .25rem
      }
      h5 {
        font-weight: bold;
      }
      .small {
        color: #000;
      }
      .scores {
        display: flex;

        .score {
          width: 50%;
          margin-right: 1rem;
        }
      }
    }
  }
}
</style>
