<template>
  <div class="px-2">
    <PageSpinner :loading="loading"></PageSpinner>

    <div
      v-cloak
      v-if="isManagedUser">
      <!-- Header -->
      <v-row align="center">
        <h5 class=" ma-3">
          Credit Application Stats
        </h5>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="auto">
          <v-btn
            color="#032D2C"
            class="my-2 px-4 white--text"
            :block="$vuetify.breakpoint.mdAndUp ? false : true"
            rounded
            @click="downloadStats()">
            Download excel sheet of stats
            <v-icon right>
              fa fa-download
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- Line Graph -->
      <v-row>
        <v-col
          cols="12"
          class="px-1">
          <submitted-all
            v-if="!loading && totals"
            :chart-data="totals"></submitted-all>
        </v-col>
      </v-row>

      <!-- Donut Charts -->
      <v-row
        v-cloak
        v-if="!loading">
        <v-col
          cols="12"
          lg="4">
          <pending-count
            :pending="pendingCount"
            :processing="processing"
            :avg-days-to-contact="avgDaysToContact"></pending-count>
        </v-col>
        <v-col
          cols="12"
          lg="4">
          <reviewed-type
            v-if="reviewed"
            :chart-data="reviewed"></reviewed-type>
        </v-col>
        <v-col
          cols="12"
          lg="4">
          <denied-type
            v-if="denied"
            :chart-data="denied"></denied-type>
        </v-col>
      </v-row>

      <!-- History Table -->
      <v-row>
        <v-col cols="12">
          <FormsHistoryTable :tenant-id="tenantId"></FormsHistoryTable>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-row>
        <v-card class="pa-2 my-4">
          <v-card-title class="font-weight-bold pb-1">
            Welcome to Credit Central
          </v-card-title>
          <v-card-text>Please select a new form from the menu or an in progress form below.</v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-col cols="12">
          <FormsHistoryTable :tenant-id="tenantId"></FormsHistoryTable>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script type="text/javascript">

import axios from 'ca-http-service'
import BannerMixin from 'mixins/banner.mixin'
import DeniedCard from './components/DeniedCard.vue'
import PendingCard from './components/PendingCountCard.vue'
import ReviewedCard from './components/ReviewedCard.vue'
import SubmittedCard from './components/SubmittedCard.vue'
import FormsHistoryTable from './components/FormsHistoryTable.vue'
import { mapState, mapGetters } from 'vuex'
import PageSpinner from '@/components/PageSpinner.vue'

export default {
  name: 'EmployeeDashboard',
  components: {
    'denied-type': DeniedCard,
    'pending-count': PendingCard,
    'reviewed-type': ReviewedCard,
    'submitted-all': SubmittedCard,
    FormsHistoryTable,
    PageSpinner
  },
  mixins: [BannerMixin],
  data () {
    return {
      loading: false,
      denied: [],
      pendingCount: null,
      processing: null,
      reviewed: [],
      totals: [],
      avgDaysToContact: null
    }
  },
  computed: {
    ...mapGetters([
      'isManagedUser',
      'getBannerByName'
    ]),
    ...mapState([
      'identity'
    ]),
    tenantId () {
      const tenantId = this.getTenantIdfromUrl() || this.identity.bannerId
      return tenantId
    }
  },
  watch: {
    selectedBanner (val, old) { // comes from banner mixin
      this.getData()
    }
  },
  mounted () {
    this.$store.dispatch('setAdminHeaderTitle', 'Dashboard')
    this.getData()
  },
  methods: {
    getTenantIdfromUrl () { // NOTE: duped from AdminLayout
      const urlTenant = this.$route.params.tenant
      return urlTenant === 'platt'
        ? 1
        : urlTenant === 'rexel'
          ? 2
          : urlTenant === 'gexpro'
            ? 3
            : urlTenant === 'rexelenergy'
              ? 4
              : null
    },
    getData () {
      const tenantId = this.getTenantIdfromUrl() || this.identity.bannerId

      if (tenantId != null) {
        this.loading = true
        axios.get('/api/User/getDashboard', { params: { tenantId } })
          .then((res) => {
            this.processing = res.data.averageProcessTime
            this.pendingCount = res.data.pendingCount
            this.totals = res.data.submittedByMonthChart
            this.reviewed = res.data.reviewedChart
            this.denied = res.data.deniedChart
            this.avgDaysToContact = res.data.avgDaysToContact
            this.loading = false
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    downloadStats () {
      const tenantId = this.getTenantIdfromUrl() || this.identity.bannerId
      if (tenantId != null) {
        axios({
          url: '/api/User/downloadDashboard/' + tenantId,
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'file.xlsx')
          document.body.appendChild(link)
          link.click()
        })
      }
    },
    getBannerName () {
      let bannerName = this.getBannerByName(this.$route.params.tenant).name
      if (bannerName === 'RexelEnergy') {
        bannerName = 'Rexel Energy'
      }
      return bannerName
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins/flexbox.scss";

    body {
      overflow-y: scroll;
    }

    div.component-container {
      margin-bottom: 1em;
    }

    .card {
      height: 100%;
      h6 {
        font-weight: bold;
        text-transform: capitalize
      }

      .doughnut-chart {
        margin: 1rem auto;
        width: 50%;
      }

      .chart-legend.bottom{
        position:absolute;
        bottom:2rem;

        .custom-legend li {
          float: left;
        }

      }

      .custom-legend {
        list-style: none;

        li {
          padding: 0 .875rem .5rem 0;
          font-size: 0.8rem;

          span {
            display: block;
            width: 2.25rem;
            height: 0.25rem;
          }
        }
      }
    }
</style>
