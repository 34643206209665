<template>
  <div>
    <v-row v-if="!readOnly">
      <v-col class="py-0">
        Please select a primary branch location:
        <span
          v-if="isRequired"
          style="color:red">
          *
        </span>
      </v-col>
    </v-row>
    <v-row v-if="!selectedBranch">
      <v-col>
        <v-card>
          <v-card-text class="py-1">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="searchTerm"
                  append-icon="mdi-magnify"
                  :label="searchTermLabel"
                  hide-details
                  solo>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <div
                class="mb-3 mt-0 ml-2">
                <v-btn
                  text
                  small
                  color="primary"
                  @click="useCurrentLocation()">
                  <v-icon left>
                    mdi-crosshairs-gps
                  </v-icon>
                  Use my current location
                </v-btn>
              </div>
              <v-col
                v-if="noLocationsMessage"
                md>
                <span style="color:red;">
                  {{ noLocationsMessage }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="showNotFoundMessage">
      <v-col
        cols="1">
        <v-flex ml-1>
          <v-icon
            color="red"
            ml-2>
            mdi-alert
          </v-icon>
        </v-flex>
      </v-col>
      <v-col>
        <div>
          <em>We weren't able to find any matches.</em>
        </div>
        <div>
          <em>{{ notFoundMessage() }}</em>
        </div>
      </v-col>
    </v-row>
    <v-divider v-if="showNotFoundMessage && filteredBranches.length > 0">
    </v-divider>
    <v-row v-if="filteredBranches || selectedBranch">
      <v-col>
        <v-data-iterator
          v-if="!selectedBranch"
          :items="filteredBranches"
          :items-per-page.sync="branchesPerPage"
          :item-key="filteredBranches.branchNumber"
          :hide-default-footer="filteredBranches.length <= branchesPerPage"
          :footer-props="{
            'items-per-page-options': [branchesPerPage],
            'disable-items-per-page': true
          }"
          :no-data-text="myCoordinates ? 'No branches found near your current location' : ''">
          <template
            slot="item"
            slot-scope="props">
            <v-row>
              <v-col
                md
                class="py-0">
                <div class="font-weight-bold">
                  {{ props.item.name }} - {{ props.item.branchNumber }}
                </div>
                <div>
                  {{ props.item.address1 }} {{ props.item.city }} {{ props.item.state }} {{ props.item.zip }}
                </div>
                <div>
                  {{ formatPhoneNumber(props.item.phone) }}
                </div>
                <v-flex mt-3>
                  <v-btn
                    @click="selectBranch(props.item)">
                    Select
                  </v-btn>
                </v-flex>
              </v-col>
              <v-spacer>
              </v-spacer>
              <div
                v-if="props.item.distance"
                cols="2">
                {{ props.item.distance }} miles
              </div>
            </v-row>
            <v-divider
              v-if="filteredBranches.length > 1">
            </v-divider>
          </template>
        </v-data-iterator>
        <v-card
          v-if="selectedBranch"
          color="#f7f7f9">
          <v-card-text>
            <v-row>
              <div
                class="py-0 ml-2">
                <div class="mb-2">
                  Selected Location:
                </div>
                <div>
                  <v-icon
                    left
                    color="blue">
                    mdi-map-marker-check
                  </v-icon>
                  {{ selectedBranch.name }} - {{ selectedBranch.branchNumber }}
                </div>
              </div>
              <v-spacer>
              </v-spacer>
              <div v-if="!readOnly">
                <v-btn
                  text
                  small
                  @click="selectBranch(null)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'ca-http-service'
import { getExternalBranchesAsync } from '@/api/company/branch-info'

export default {
  name: 'BranchSelector',
  props: {
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    passedBranch: {
      type: Object,
      required: false,
      default: null
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      bannerId: null,
      branches: [],
      filteredBranchNumbers: null,
      myCoordinates: null,
      selectedBranch: null,
      searchTerm: null,
      branchesPerPage: 3,
      badUseCurrentLocationMessageText: 'Unable to detect your location.  Please check your browser settings and disable any ad blockers.',
      noLocationsMessage: null
    }
  },
  computed: {
    filteredBranches () {
      // *** USING A SEARCH TERM
      if (!this.filteredBranchNumbers || (this.searchTerm && this.searchTerm.length > 0)) {
        if (!this.searchTerm || this.searchTerm.length === 0) {
          return []
        }
        // Filter is different for Internal or External
        const branches = this.branches.filter(branch => {
          if (this.isInternal()) {
            if (!!branch.name && branch.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 || // eslint-disable-line no-mixed-operators
              !!branch.branchNumber && branch.branchNumber.toLowerCase().indexOf(this.searchTerm) >= 0) { // eslint-disable-line no-mixed-operators
              return branch
            }
          } else {
            if (!!branch.name && branch.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 || // eslint-disable-line no-mixed-operators
                !!branch.branchNumber && branch.branchNumber.toLowerCase().indexOf(this.searchTerm) >= 0 || // eslint-disable-line no-mixed-operators
                !!branch.city && branch.city.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 || // eslint-disable-line no-mixed-operators
                !!branch.state && branch.state.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 || // eslint-disable-line no-mixed-operators
                !!branch.stateName && branch.stateName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0 || // eslint-disable-line no-mixed-operators
                !!branch.zip && branch.zip.toLowerCase().indexOf(this.searchTerm) >= 0) { // eslint-disable-line no-mixed-operators
              return branch
            }
          }
          return false
        })

        // If search found none and platt, show just branchNumber 800
        if (this.$route.params.tenant === 'platt' && (branches && branches.length === 0) && (this.searchTerm && this.searchTerm.length > 0)) {
          return this.branches.filter(branch => branch.branchNumber === '800')
        } else {
          return branches
        }
      } else if (!this.filteredBranchNumbers && (!this.searchTerm || this.searchTerm.length === 0)) {
        return [] // return nothing, coordinates not used, no search term entered
      } else { // *** USING COORDINATES
        // First, limit branches to those returned from  found nearest
        const filteredFromBranches = this.branches.filter(branch => {
          return this.filteredBranchNumbers.find(filteredBranch => filteredBranch.branchNumber === branch.branchNumber)
        })

        // Map to new branches with distance prop
        const filteredBranchesWithDistance = filteredFromBranches.map(branch => {
          return {
            ...branch,
            distance: Number(this.filteredBranchNumbers.find(filteredBranch => filteredBranch.branchNumber === branch.branchNumber).distance).toFixed(1)
          }
        })

        // Sort them by ascending distance
        const sortedBranchesByDistanceAscending = filteredBranchesWithDistance.sort((a, b) => (Number(a.distance) > Number(b.distance)) ? 1 : -1)

        // If Platt, and distance of closest branch is greated than 100 miles, make Platt Headquarters (800) the first on the array
        if (this.$route.params.tenant === 'platt' && sortedBranchesByDistanceAscending.length > 0 && sortedBranchesByDistanceAscending[0].distance > 100) {
          const plattHeadquarters = this.branches.find(branch => branch.branchNumber === '800')
          sortedBranchesByDistanceAscending.unshift(
            {
              ...plattHeadquarters,
              distance: 0.0
            }
          )
        }
        return sortedBranchesByDistanceAscending
      }
    },
    showNotFoundMessage () {
      // Not found message only applies when using search term
      if (this.filteredBranchNumbers || !this.searchTerm || this.searchTerm.length === 0) {
        return false
      }
      if (this.$route.params.tenant === 'platt') {
        return this.filteredBranches.length === 0 || (this.filteredBranches.length === 1 && this.filteredBranches[0].branchNumber === '800')
      } else {
        return this.filteredBranches.length === 0
      }
    },
    searchTermLabel () {
      if (this.isInternal) {
        return 'Enter Branch Name or Branch Number'
      } else {
        return 'Enter City, State or Postal Code'
      }
    }
  },
  watch: {
    passedBranch: {
      handler: function (newValue, oldValue) {
        // console.log(`watch passedBranch newValue=${newValue}, oldValue=${oldValue}`)
        if (oldValue && !newValue) {
          this.selectedBranch = null // User passed null passedBranch prop when previously not null
        } else if (this.$route.meta.isInternalForm && !this.selectedBranch && newValue && !oldValue) {
          this.selectedBranch = this.passedBranch
        } else if (!this.$route.meta.isInternalForm) {
          this.selectedBranch = this.passedBranch
        }
      }
    }
  },
  async mounted () {
    this.renderStep = true

    const bannerName = this.$route.params.tenant
    this.tenant = bannerName
    this.bannerId = this.$store.getters.getBannerIdByName(bannerName)
    this.branches = await getExternalBranchesAsync(this.bannerId)
    if (this.rexelEnergy) {
      this.updateToRexelEnergyLabels()
    }
    if (this.passedBranch) {
      this.selectedBranch = this.passedBranch
    }

    console.log('isInternalForm?', this.$route.meta.isInternalForm)
  },
  methods: {
    selectBranch (selectedBranch) {
      this.selectedBranch = selectedBranch
      this.searchTerm = null
      // this.businessInfoInput.branchNumber = selectedBranch ? selectedBranch.branchNumber : ''
      if (!selectedBranch) {
        this.filteredBranchNumbers = null
      }
      this.$emit('select', selectedBranch ? selectedBranch.branchNumber : '')
    },
    useCurrentLocation () {
      this.myCoordinates = null
      this.searchTerm = null
      this.noLocationsMessage = null
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('Got coordinates', position.coords.latitude, position.coords.longitude)
        if (position.coords) {
          axios.get('/api/creditapplication/nearest-branches', {
            params: {
              bannerId: this.bannerId,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              howMany: null
            }
          })
            .then((res) => {
              if (res.status === 200) {
                this.filteredBranchNumbers = res.data
              } else {
                this.filteredBranchNumbers = null
              }
              if (!this.filteredBranchNumbers || this.filteredBranchNumbers.length === 0) {
                this.noLocationsMessage = this.badUseCurrentLocationMessageText
              }
            })
            .catch((err) => {
              this.noLocationsMessage = this.badUseCurrentLocationMessageText
              console.error(err)
            })
        }
      }, (error) => {
        console.log(`useCurrentLocation() err=${JSON.stringify(error)}`)
        this.noLocationsMessage = this.badUseCurrentLocationMessageText
      })
    },
    async getBranches () {
      return await getExternalBranchesAsync(this.tenantId)
    },
    isInternal () {
      return this.$route.meta.isInternalForm === true
    },
    notFoundMessage () {
      if (this.$route.params.tenant === 'platt') {
        return 'You can try using your current location to improve results, or select our headquarters below.'
      } else {
        return 'You can try using your current location to improve results.'
      }
    },
    plattHeadQuartersBranch () {
      return this.branches.find(branch => branch.branchNumber === '800')
    },
    formatPhoneNumber (phoneNumber) {
      if (!phoneNumber) {
        return ''
      }
      const cleanNum = phoneNumber.toString().replace(/\D/g, '')
      const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/)
      if (match) {
        return '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3]
      }
      return cleanNum
    }
  }
}
</script>
