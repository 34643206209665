<template>
  <div
    id="application-component"
    class="card component-container credit-application">
    <horizontal-stepper
      :steps="groupedSteps"
      :form-data="formData"
      :mode="mode"
      :on-save="onSave"
      :on-submit="onSubmit"
      :on-email="onEmail"
      :email-sent="emailSent"
      :success-message="successMessage"
      :error-message="errorMessage"
      :validation-failures="validationFailures"
      :loading="loading"
      :is-submitted-successfully="isSubmittedSuccessfully"
      :validations="$v.formData"
      :start-at-step="startAtStep"
      :is-internal="isInternal"
      @isSaveValid="updateIsSaveValid"
      @cancelStartAtStep="startAtStep = null"
      @on-comment="isLastStep ? setCustomerComments($event) : null"
      @on-completed="isLastStep ? setCompleted($event) : null">
      <CreditApplicationFeedbackForm :feedback-form-data="completed"></CreditApplicationFeedbackForm>
    </horizontal-stepper>
    <FooterBar :company="company"></FooterBar>
    <form-address-validation-modal
      :is-dialog-open="showModal"
      :validated-address="validatedAddress"
      :form-data-address="preValidatedAddress"
      :use-validated="true"
      :should-use-entered="true"
      @useEntered="useEntered"
      @updateAddress="updateFormData"
      @close="closeFormAddressValidationModal"
      @editAddress="editAddress">
    </form-address-validation-modal>
    <!-- TODO Should it be company-name="formData.businessInfo.internalCompanyName" -->
    <email-credit-application-modal
      v-if="isInternal"
      :show-modal="showEmailCreditApplicationModal"
      :account-number="formData.businessInfo.accountNumber"
      :company-name="formData.businessInfo.internalCompanyName"
      :identity="identity"
      :signatories-emails="[formData.businessInfo.email]"
      @sendEmail="sendEmail"
      @close="closeEmailModal"></email-credit-application-modal>
    <form-navigation-confirmation-modal
      :is-dialog-open="displayNavWarning"
      :is-save-valid="isSaveValid"
      @saveAndNavigate="saveAndNavigate"
      @discardAndNavigate="discardAndNavigate"
      @close="closeFormNavigationConfirmationModal">
    </form-navigation-confirmation-modal>
  </div>
</template>
<script type="text/javascript">
import TenantsMixin from 'mixins/tenants.mixin'
import FormMixin from '@/components/form/Form.mixin'
import HorizontalStepper from '@/components/form/HorizontalStepper.vue'
import FooterBar from '@/pages/layout/footer.vue'
import lodash from 'lodash'
import CreditApplicationFeedbackForm from './CreditApplicationFeedbackForm.vue'
import FormAddressValidationModal from '@/components/form/FormAddressValidation/FormAddressValidationModal.vue'
import EmailCreditApplicationModal from '@/components/EmailCreditApplicationModal.vue'
import FormNavigationConfirmationModal from '@/components/FormNavigationConfirmationModal.vue'
import { getExternalBranchesAsync } from '@/api/company/branch-info'
import { getWebUserFromToken } from '@/api/token/token-info'

import { bannerEmailRule, customerNumberExistsRule } from '@/components/form/FormValidationsCustom'

import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  integer,
  minLength,
  maxLength,
  requiredIf
} from 'vuelidate/lib/validators'

import {
  formTitle,
  formDefinitions,
  formGroupInfo,
  BILLING_INFO,
  BUSINESS_INFO,
  UPLOAD_APPLICATION,
  E_SIGN,
  PREFERENCES,
  ACCOUNT_PAYABLE,
  PURCHASING_CONTACT,
  TRADE_REFERENCES,
  TRADE_REFERENCE,
  BANKING_INFORMATION,
  BONDING_COMPANY,
  SIGNATORIES,
  SIGNATORYTWO
} from './CreditApplicationForm.definitions'
import { mapGetters } from 'vuex'
import axios from 'ca-http-service'

import {
  getCustomerAccountInfo
} from '@/api/company/account-info'

export default {
  name: 'CreditApplicationForm',
  components: {
    HorizontalStepper,
    CreditApplicationFeedbackForm,
    FooterBar,
    FormAddressValidationModal,
    EmailCreditApplicationModal,
    FormNavigationConfirmationModal
  },
  mixins: [
    TenantsMixin,
    FormMixin,
    validationMixin
  ],
  data () {
    return {
      /// ///////////////////////////
      // FormStepper data
      /// ///////////////////////////
      formTitle,
      formDefinitions,
      formGroupInfo,
      formSteps: [],
      groupedSteps: [],
      // formData should hold data needed to make a POST to api
      formData: this.$initFormData(formDefinitions),
      emailSent: false,
      mode: 'stepped',
      completed: {
        // properties to pass to the completed page should go here
        applicationId: '',
        email: '',
        fullName: '',
        redirectUrl: ''
      },
      /// ///////////////////////////
      // Form usage-specific data
      /// ///////////////////////////
      guid: null, // NOTE (workaround for uploads): when assigning, also assign to formData[UPLOAD_APPLICATION].storageId
      isAppCreatedSuccessfully: false,
      accessToken: null,
      successMessage: '',
      errorMessage: '',
      startAtStep: null,
      showModal: false,
      showEmailCreditApplicationModal: false,
      invalidCustomerAccountNumber: false,
      /// ///////////////////////////
      // Address modal-specific data
      /// ///////////////////////////
      sectionValidating: null,
      validatedAddress: {},
      preValidatedAddress: {},
      apiObject: {},
      shippingAddressNeedsValidation: false,
      cb: null,
      order: null,
      signatoriesEmails: [],
      emailPostValidation: false,
      branches: [],
      isSaveValid: false,
      webUser: null,
      isLastStep: false
    }
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    rexelEnergy () {
      return this.company === 'RexelEnergy' || this.$route.params.tenant === 'rexelenergy'
    },
    isCorporation () {
      return this.formData[BILLING_INFO].businessType === 'Corporation'
    },
    isPartnership () {
      return this.formData[BILLING_INFO].businessType === 'Partnership'
    },
    signatoryTitle () {
      if (this.rexelEnergy) {
        return 'Owner/Officers'
      } else {
        return 'Signatory'
      }
    },
    isInternal () {
      return this.$isFormInternal()
    },
    bannerId () {
      const tenant = this.$route.params.tenant
      let bannerId = null
      switch (tenant) {
        case 'platt':
          bannerId = 1
          break
        case 'rexel':
          bannerId = 2
          break
        case 'gexpro':
          bannerId = 3
          break
        case 'rexelenergy':
          bannerId = 4
          break
        default:
          break
      }
      return bannerId
    },
    isValidatedAddressTheSame () {
      return Object.keys(this.validatedAddress).every((addressField) => {
        return this.validatedAddress[addressField] === this.preValidatedAddress[addressField].trim()
      })
    },
    isCompleted () {
      return this.isLastStep && this.formData[E_SIGN].allSignatoriesHaveSigned
    }
  },
  watch: {
    guid: {
      immediate: true,
      // sends the applicationId/guid to other sections
      handler (newVal, oldVal) {
        this.formData[E_SIGN].applicationId = newVal
      }
    },
    'formData.preferences.isUploaded': {
      handler (newVal) {
        if (newVal === true) {
          const preferencesStepOrder = formDefinitions[PREFERENCES].order
          this.onSave(null, preferencesStepOrder)
        }
      }
    },
    'formData.signatories.signatories': {
      immediate: true,
      deep: true,
      handler (newVal, oldVal) {
        if (newVal.length) {
          const emails = newVal.map(signatory => {
            return signatory.email
          })
          this.signatoriesEmails = emails
        }

        // sends value to e-sign step, used to send request to hellosign
        this.formData[E_SIGN].signatories = [...newVal]
      }
    },
    'formData.billingInfo.businessType': {
      immediate: true,
      handler (newVal, oldVal) {
        // sends value to e-sign step, used as bizlogic
        this.formData[E_SIGN].businessType = newVal

        // handles changing minlength for signatories based on businessType
        if (newVal === 'Partnership') {
          this.formDefinitions.signatories.collectionInfo.minLength = 2
        } else {
          this.formDefinitions.signatories.collectionInfo.minLength = 1
        }
      }
    },
    'formData.billingInfo.isBillingAddressSameAsShipping': {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal === '1') { // TODO: prevent or sync changes to fields if true
          this.formData[BILLING_INFO]
            .shippingStreetAddress = this.formData[BILLING_INFO].streetAddress
          this.formData[BILLING_INFO]
            .shippingAddressUnitNumber = this.formData[BILLING_INFO].addressUnitNumber
          this.formData[BILLING_INFO]
            .shippingCity = this.formData[BILLING_INFO].city
          this.formData[BILLING_INFO]
            .shippingCountry = this.formData[BILLING_INFO].country
          this.formData[BILLING_INFO]
            .shippingState = this.formData[BILLING_INFO].state
          this.formData[BILLING_INFO]
            .shippingPostalCode = this.formData[BILLING_INFO].postalCode
        } else {
          this.formData[BILLING_INFO].shippingStreetAddress = ''
          this.formData[BILLING_INFO].shippingAddressUnitNumber = ''
          this.formData[BILLING_INFO].shippingCity = ''
          this.formData[BILLING_INFO].shippingCountry = ''
          this.formData[BILLING_INFO].shippingState = ''
          this.formData[BILLING_INFO].shippingPostalCode = ''
        }
      }
    },
    'formData.businessInfo.accountNumber': {
      handler (newVal, oldVal) {
        // accessToken will be null if we are getting existing data
        if (this.$userIsEmployee() && this.accessToken === null && !this.$route.params.guid && newVal && newVal.length > 4) {
          const dbFunc = lodash.debounce((newVal) => {
            getCustomerAccountInfo(newVal, this.$route.params.tenant).then((results) => {
              // Reset Business and Billing Info
              Object.keys(this.formData[BUSINESS_INFO]).forEach(key => {
                if (key === 'accountNumber' || key === 'hasAccount') {
                  return
                }
                this.formData[BUSINESS_INFO][key] = ''
              })
              Object.keys(this.formData[BILLING_INFO]).forEach(key => {
                this.formData[BILLING_INFO][key] = ''
              })

              this.invalidCustomerAccountNumber = false
              const branch = this.branches.find(branch => branch.branchNumber === results.branchNumber)
              if (results && !branch) {
                // Customer Account Info was returned but results.branchNumber
                // was not found in current banner branches.
                // PROBABLY Account is from a different Banner - NOT ALLOWED
                this.invalidCustomerAccountNumber = true
                this.$v.formData.businessInfo.accountNumber.$touch()
                return
              }
              this.$v.formData.businessInfo.accountNumber.$touch()
              this.formData[BUSINESS_INFO]
                .companyName = results.companyName ? results.companyName : ''
              this.formData[BUSINESS_INFO]
                .email = results.email ? results.email : ''
              this.formData[BUSINESS_INFO]
                .fax = results.fax ? results.fax : ''
              this.formData[BUSINESS_INFO]
                .phone = results.phone ? results.phone : ''
              this.formData[BUSINESS_INFO]
                .picCodeCategory = results.picCodeCategory ? results.picCodeCategory : ''
              this.formData[BUSINESS_INFO]
                .picCode = results.picCode ? results.picCode : ''
              this.formData[BUSINESS_INFO]
                .customerTypeCategory = results.customerTypeCategory ? results.customerTypeCategory : ''
              this.formData[BUSINESS_INFO]
                .customerPriceClass = results.customerPriceClass ? results.customerPriceClass : ''
              this.formData[BUSINESS_INFO]
                .branchNumber = results.branchNumber ? results.branchNumber : ''

              this.formData[BUSINESS_INFO]
                .insideSalesPerson = results.inSideSalesPerson ? results.inSideSalesPerson : ''
              this.formData[BUSINESS_INFO]
                .outsideSalesPerson = results.outSideSalesPerson ? results.outSideSalesPerson : ''

              this.formData[BILLING_INFO]
                .streetAddress = results.streetAddress ? results.streetAddress : ''
              this.formData[BILLING_INFO]
                .state = results.state ? results.state : ''
              this.formData[BILLING_INFO]
                .country = results.country ? results.country : ''
              this.formData[BILLING_INFO]
                .postalCode = results.postalCode ? results.postalCode : ''
              this.formData[BILLING_INFO]
                .city = results.city ? results.city : ''
              this.formData[BILLING_INFO]
                .addressUnitNumber = results.addressUnitNumber ? results.addressUnitNumber : ''

              // See ~\src\CreditApplication\Repository\CreditApplicationRepository\SalesInfoRepository.cs
              // See also ~\src\CreditApplication\Model\Customer\SalesCustomerViewModel.cs
              this.formData[BILLING_INFO]
                .shippingStreetAddress = results.shipTo && results.shipTo[0] ? results.shipTo[0].streetAddress : ''
              this.formData[BILLING_INFO]
                .shippingAddressUnitNumber = '' // Doesn't exist on BE SalesCustomerViewModel
              this.formData[BILLING_INFO]
                .shippingCity = results.shipTo && results.shipTo[0] ? results.shipTo[0].city : ''
              this.formData[BILLING_INFO]
                .shippingState = results.shipTo && results.shipTo[0] ? results.shipTo[0].state : ''
              this.formData[BILLING_INFO]
                .shippingPostalCode = results.shipTo && results.shipTo[0] ? results.shipTo[0].postalCode : ''
              this.formData[BILLING_INFO]
                .shippingCountry = results.country ? results.country : ''
            }).catch((error) => {
              this.invalidCustomerAccountNumber = true
              console.error(error)
            })
          }, 250)
          dbFunc(newVal)
        }
        // if accessToken is not null we skip over getting accountNumberInfo, and then null out the value
        if (this.accessToken != null) {
          this.accessToken = null
        }
      }
    }
  },
  async mounted () {
    this.groupedSteps = this.groupSteps(formGroupInfo, formDefinitions)
    if (this.rexelEnergy) {
      this.groupedSteps[3].title = this.signatoryTitle
    }

    if (this.isInternal) {
      this.groupedSteps = this.groupedSteps.filter(function (obj) { // removes the E-Sign step for internal (email) applications.
        return obj.group !== 5
      })
    }

    this.$set(this.formData, 'helloSignRequestId', '')
    this.branches = await getExternalBranchesAsync(this.bannerId)

    // Got here from storefront link with sftoken
    if (!this.isInternal && this.$route.query.sftoken != null) {
      this.webUser = await getWebUserFromToken(this.$route.query.sftoken)
      if (this.webUser) {
        // This can happen if user changes banner in url and it doesn't match token
        if (this.webUser.bannerId !== this.bannerId) {
          this.formData[BUSINESS_INFO].hasAccount = ''
          this.$router.push({
            name: 'application'
          })
          return
        }
        this.formData[BUSINESS_INFO].hasAccount = 'true'
        this.formData[BUSINESS_INFO].webUserId = this.webUser.webUserId
        this.$store.dispatch('setWebUser', this.webUser)
      }
    }

    // DISABLED - ApplicationAccess is disabled (Uses Token)
    // if (this.$route.params.guid != null) {
    //  this.guid = this.$route.params.guid
    //  this.accessToken = this.$route.params.token
    //  this.formData[PREFERENCES].storageGuid = this.$route.params.guid
    //  this.formData[UPLOAD_APPLICATION].storageGuid = this.$route.params.guid

    //  console.log(`CreditApplicationForm this.$route.params.token(${this.$route.params.token})`)
    //  if (!this.accessToken) {
    //    console.log(`CreditApplicationForm mounted() calling retrieveAccessToken(${this.guid})`)
    //    this.accessToken = await this.retrieveAccessToken(this.guid)
    //  } else {
    //    console.log(`CreditApplicationForm mounted() using this.$route.params.token`)
    //    this.accessToken = this.$route.params.token
    //  }

    //  const result = await this.getApplication(this.guid, this.accessToken)
    //  console.log(`CreditApplicationForm mounted() result=${JSON.stringify(result)}`)

    //  if (result != null) {
    //    this.$setFormData(formDefinitions, result)
    //  }
    // }

    // ApplicationAccess (Does NOT use Token)
    if (this.$route.params.guid != null) {
      this.guid = this.$route.params.guid
      this.formData[PREFERENCES].storageGuid = this.$route.params.guid
      this.formData[UPLOAD_APPLICATION].storageGuid = this.$route.params.guid
      this.startAtStep = this.$route.meta.step // Get Step Number for the route

      const creditApplicationDTO = await this.getApplication(this.guid, this.accessToken)
      this.emailSent = !!creditApplicationDTO.applicationData.dateEmailed
      this.$store.dispatch('applicationForm/setCreatedFromInternal', creditApplicationDTO.applicationData.isInternal)

      if (creditApplicationDTO != null) {
        this.$setFormData(formDefinitions, creditApplicationDTO.formData)
        if (!this.isInternal && creditApplicationDTO.formData.signatories && creditApplicationDTO.formData.signatories[0].hasSigned === 'true') {
          this.isSubmittedSuccessfully = true
        }
      }
    }
  },
  validations () {
    const isRexelEnergy = this.rexelEnergy
    let signatory = {}
    const isInternal = this.$isFormInternal()
    const isCustomerPriceEmpty = this.formData.businessInfo.customerPriceClass === null || this.formData.businessInfo.customerPriceClass === '' || this.formData.businessInfo.customerPriceClass === undefined
    const isCustomerTypeEmpty = this.formData.businessInfo.customerTypeCategory === null || this.formData.businessInfo.customerTypeCategory === '' || this.formData.businessInfo.customerTypeCategory === undefined

    if (isRexelEnergy && this.isCorporation) {
      signatory = {
        firstName: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        lastName: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        middleInitial: {},
        suffix: {},
        jobTitle: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        email: {
          email,
          required: requiredIf(function () {
            return !isInternal
          })
        },
        phone: {
          minLength: minLength(14)
        },
        phoneExt: {
          maxLength: maxLength(10)
        },
        country: {},
        streetAddress: {},
        addressUnitNumber: {},
        city: {},
        state: {},
        postalCode: {},
        isSsnOrEin: {},
        ssn: {},
        guarantor: {
          required: requiredIf(function () {
            if (this.$route.name === 'guarantor') {
              return true
            }
          })
        }
      }
    } else {
      signatory = {
        firstName: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        lastName: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        middleInitial: {},
        suffix: {},
        jobTitle: {},
        email: {
          email,
          required: requiredIf(function () {
            return !isInternal
          })
        },
        phone: {
          required: requiredIf(function () {
            return !isInternal
          }),
          minLength: minLength(14)
        },
        phoneExt: {
          maxLength: maxLength(10)
        },
        country: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        streetAddress: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        addressUnitNumber: {},
        city: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        state: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        postalCode: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        isSsnOrEin: {
          required: requiredIf(function () {
            return !isInternal
          })
        },
        ssn: {
          required: requiredIf(function () {
            if (!isInternal && this.formData.signatories.signatory.guarantor === 'true') {
              return true
            }
            return false
          })
        },
        guarantor: {
          required: requiredIf(function () {
            if (this.$route.name === 'guarantor') {
              return true
            }
          })
        }
      }
    }
    return {
      formData: {
        [BUSINESS_INFO]: {
          hasAccount: {
            required
          },
          accountNumber: {
            integer,
            required: requiredIf(function () {
              return this.formData[BUSINESS_INFO].hasAccount === 'true' && isInternal
            }),
            customerNumberExistsRule: customerNumberExistsRule(isInternal, !this.invalidCustomerAccountNumber)
          },
          companyName: {
            required,
            maxLength: maxLength(35),
            minLength: minLength(1)
          },
          parentCompanyName: {},
          internalCompanyName: {
            maxLength: maxLength(100),
            minLength: minLength(1),
            required: requiredIf(function () {
              return isInternal
            })
          },
          phone: {
            required: requiredIf(function () {
              return !isInternal
            }),
            minLength: minLength(14)
          },
          email: {
            email,
            required,
            bannerEmailRule
          },
          fax: {
            minLength: minLength(14)
          },
          branchNumber: {
            required: requiredIf(function () {
              return isInternal
            })
          },
          insideSalesPerson: {
            required: requiredIf(function () {
              return (this.bannerId !== 1 && isInternal) && (!this.formData[BUSINESS_INFO].insideSalesPerson && !this.formData[BUSINESS_INFO].outsideSalesPerson)
            })
          },
          outsideSalesPerson: {
            required: requiredIf(function () {
              return (this.bannerId !== 1 && isInternal) && (!this.formData[BUSINESS_INFO].insideSalesPerson && !this.formData[BUSINESS_INFO].outsideSalesPerson)
            })
          },
          customerTypeCategory: {
            required: requiredIf(function () {
              return this.bannerId !== 1 && isInternal
            })
          },
          customerPriceClass: {
            required: requiredIf(function () {
              return this.bannerId !== 1 && isInternal
            })
          }
        },
        [BILLING_INFO]: {
          country: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          streetAddress: {
            maxLength: maxLength(35),
            required: requiredIf(function () {
              return !isInternal
            })
          },
          addressUnitNumber: {
            maxLength: maxLength(35)
          },
          city: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          state: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          postalCode: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          businessType: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          businessTypeLabel: {
            required: requiredIf(function () {
              return this.formData[BILLING_INFO].businessType === 'Other'
            })
          },
          contractorLicenceNumber: {},
          dunsNumber: {},
          monthlyIncomeEstimate: {
            // TODO: should this be numeric only?
          },
          monthlyPurchasesEstimate: {
            // TODO: should this be numeric only?
          },
          incorporatedCountry: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          incorporatedState: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          yearEstablished: {
            integer,
            maxLength: maxLength(4)
          },
          preferredSalesPerson: {},
          businessIndustry: {},
          sectorType: {
            required: requiredIf(function () {
              return !isInternal && this.bannerId !== 1 && isCustomerPriceEmpty && isCustomerTypeEmpty
            })
          },
          employeeCount: {
            required: requiredIf(function () {
              return !isInternal && this.bannerId !== 1 && isCustomerPriceEmpty && isCustomerTypeEmpty
            })
          },

          federalTaxId: {
            required: requiredIf(function () {
              return this.formData[BILLING_INFO].isHaveFederalTaxId === 'Yes'
            })
          },
          isHaveFederalTaxId: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          isBillingAddressSameAsShipping: {},
          shippingStreetAddress: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          shippingAddressUnitNumber: {},
          shippingCity: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          shippingCountry: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          shippingState: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          shippingPostalCode: {
            required: requiredIf(function () {
              return !isInternal
            })
          }
        },
        [ACCOUNT_PAYABLE]: {
          firstName: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          lastName: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          phone: {
            required: requiredIf(function () {
              return !isInternal
            }),
            minLength: minLength(14)
          },
          phoneExt: {
            maxLength: maxLength(10)
          },
          fax: {
            minLength: minLength(14)
          },
          email: {
            email
          }
        },
        [PURCHASING_CONTACT]: {
          firstName: {
          },
          lastName: {
          },
          phone: {
            minLength: minLength(14)
          },
          phoneExt: {
            maxLength: maxLength(10)
          },
          fax: {
            minLength: minLength(14)
          },
          email: {
            email
          }
        },
        [PREFERENCES]: {
          selectedDocuments: {
          },
          uploadedDocuments: {
            required: requiredIf(function () {
              return this.formData[PREFERENCES].isTaxable === 'No'
            })
          },
          storageGuid: {
            required: requiredIf(function () {
              return this.formData[PREFERENCES].selectedDocuments.length !== 0
            })
          },
          isUploaded: {
            required: requiredIf(function () {
              return this.formData[PREFERENCES].selectedDocuments.length !== 0
            })
          },
          statementPreferredCommunicationType: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          statementPreferredEmailAddress: {
            required: requiredIf(function () {
              return !isInternal && this.formData[PREFERENCES].statementPreferredCommunicationType === '1'
            }),
            email
          },
          statementPreferredFax: {
            required: requiredIf(function () {
              return !isInternal && this.formData[PREFERENCES].statementPreferredCommunicationType === '2'
            }),
            minLength: minLength(14)
          },
          invoicePreferredCommunicationType: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          invoicePreferredEmailAddress: {
            required: requiredIf(function () {
              return !isInternal && this.formData[PREFERENCES].invoicePreferredCommunicationType === '1'
            }),
            email
          },
          invoicePreferredFax: {
            required: requiredIf(function () {
              return !isInternal && this.formData[PREFERENCES].invoicePreferredCommunicationType === '2'
            }),
            minLength: minLength(14)
          },
          isPurchaseOrderRequired: {},
          isTaxable: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          statementPreferredStreetAddress: {},
          statementPreferredAddressUnitNumber: {},
          statementPreferredCity: {},
          statementPreferredCountry: {},
          statementPreferredState: {},
          statementPreferredPostalCode: {},
          invoicePreferredStreetAddress: {},
          invoicePreferredAddressUnitNumber: {},
          invoicePreferredCity: {},
          invoicePreferredCountry: {},
          invoicePreferredState: {},
          invoicePreferredPostalCode: {}
        },
        [UPLOAD_APPLICATION]: {
          country: {},
          streetAddress: {},
          addressUnitNumber: {},
          city: {},
          state: {},
          postalCode: {},
          accountType: {},
          bankName: {},
          officerName: {},
          phoneNumber: {
            minLength: minLength(14)
          }
        },
        [BANKING_INFORMATION]: {
          country: {},
          streetAddress: {},
          addressUnitNumber: {},
          city: {},
          state: {},
          postalCode: {},
          accountType: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          bankName: {
            required: requiredIf(function () {
              return !isInternal
            })
          },
          officerName: {},
          phoneNumber: {
            minLength: minLength(14)
          },
          phoneNumberExt: {
            maxLength: maxLength(10)
          }
        },
        [BONDING_COMPANY]: {
          country: {},
          streetAddress: {},
          addressUnitNumber: {},
          city: {},
          state: {},
          postalCode: {},
          bondingName: {},
          bondingAgentName: {},
          isAddressSameAsBondingCompany: {},
          bondingAgentStreetAddress: {},
          bondingAgentAddressUnitNumber: {},
          bondingAgentCity: {},
          bondingAgentCountry: {},
          bondingAgentState: {},
          bondingAgentPostalCode: {}
        },
        [TRADE_REFERENCES]: {
          [TRADE_REFERENCES]: {},
          // this section should not need any validation as user may only have partial information
          [TRADE_REFERENCE]: {
            name: {},
            account: {},
            country: {},
            streetAddress: {},
            addressUnitNumber: {},
            city: {},
            state: {},
            postalCode: {},
            fax: {
              minLength: minLength(14)
            },
            email: {
              email
            },
            phone: {
              minLength: minLength(14)
            }
          }
        },
        [SIGNATORIES]: {
          [SIGNATORIES]: {
            required,
            minLength: minLength(1)
          },
          signatory
        },
        [SIGNATORYTWO]: {
          firstName: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          lastName: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          middleInitial: {},
          suffix: {},
          jobTitle: {},
          email: {
            email,
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          phone: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            }),
            minLength: minLength(14)
          },
          phoneExt: {
            maxLength: maxLength(10)
          },
          country: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          streetAddress: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          addressUnitNumber: {},
          city: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          state: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          postalCode: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          isSsnOrEin: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          ssn: {
            required: requiredIf(function () {
              return this.hasMultipleSignatoriesDefined(this.formData.signatories)
            })
          },
          guarantor: { }
        },
        [E_SIGN]: {
          customerComments: '',
          allSignatoriesHaveSigned: {
            required
          }
        }
      }
    }
  },
  methods: {
    groupSteps (groupArray, formDefinitions) {
      const formData = this.formData
      const groupSteps = groupArray.map(function (obj) {
        obj.sectionNames.reduce(function (acc, currentValue) {
          acc.sectionData[currentValue] = formDefinitions[currentValue]
          acc.sectionData[currentValue].data = formData[currentValue]
          return acc
        }, obj)
        return obj
      })
      return groupSteps
    },
    async getApplication (guid, accessToken) {
      const tenant = this.$route.params.tenant
      const applicationId = guid

      return this.$http
        .get(`/api/CreditApplication/get/${tenant}/${applicationId}`, {
          params: {
            accessToken
          }
        })
        .then(res => {
          const creditApplicationDTO = res.data
          if (creditApplicationDTO.formData) {
            const result = { ...creditApplicationDTO.formData }
            const keys = Object.keys(result)
            for (const key of keys) {
              if (result[key] != null) {
                this.$v.formData[key].$touch()
                result[key].isStepDataReady = true
              } else {
                result[key] = ''
              }
            }
            this.isAppCreatedSuccessfully = true
            creditApplicationDTO.formData = result
            return creditApplicationDTO
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    updateIsSaveValid (val) {
      this.isSaveValid = val
    },
    validateBillingAddress () {
      this.sectionValidating = 'billing'
      this.apiAddress = this.buildParams(this.formData.billingInfo, {
        streetAddress: 'street1',
        addressUnitNumber: 'street2',
        city: 'city',
        postalCode: 'postalCode',
        state: 'state'
      })
      this.preValidatedAddress = this.buildParams(this.formData.billingInfo, {
        streetAddress: 'streetAddress1',
        addressUnitNumber: 'streetAddress2',
        city: 'city',
        postalCode: 'postalCode',
        state: 'state'
      })
      const params = this.apiAddress
      this.validateAddress(params)
    },
    validateShippingAddress () {
      this.sectionValidating = 'shipping'
      this.apiAddress = this.buildParams(this.formData.billingInfo, {
        shippingStreetAddress: 'street1',
        shippingAddressUnitNumber: 'street2',
        shippingCity: 'city',
        shippingPostalCode: 'postalCode',
        shippingState: 'state'
      })
      this.preValidatedAddress = this.buildParams(this.formData.billingInfo, {
        shippingStreetAddress: 'streetAddress1',
        shippingAddressUnitNumber: 'streetAddress2',
        shippingCity: 'city',
        shippingPostalCode: 'postalCode',
        shippingState: 'state'
      })
      const params = this.apiAddress
      this.validateAddress(params)
      this.shippingAddressNeedsValidation = false
    },
    async validateAddress (params) {
      axios.get('https://api.platt.com/api/AddressVerification/GetSuggestedAddress/', { params })
        .then((res) => {
          this.validatedAddress = this.buildParams(res.data, {
            street1: 'streetAddress2',
            street2: 'streetAddress1',
            city: 'city',
            zip: 'postalCode',
            state: 'state'
          })
          if (this.isValidatedAddressTheSame) {
            // Trims possible whitespace in address
            this.updateFormData()
          } else {
            this.showModal = true
          }
        })
        .catch(() => {
          this.validatedAddress = {}
          this.showModal = true
        })
    },
    // Returns an object with replaced keys from the value of the replaceObj
    // eg: buildParams({oldKey: 'val'}, {oldKey: 'newKey'}) -> {newKey: 'val'}
    buildParams (object, replaceObj) {
      const clone = { ...object }
      const keys = Object.keys(clone)
      keys.map((k) => {
        if (!replaceObj[k]) {
          delete clone[k]
        } else {
          const newKey = replaceObj[k]
          if (typeof newKey !== 'undefined') {
            clone[newKey] = clone[k] === null ? '' : clone[k]
            if (newKey !== k) delete clone[k]
          }
        }
        return k
      })
      return clone
    },
    hasMultipleSignatoriesDefined (signatories) {
      return signatories.signatories && signatories.signatories.length > 0 && signatories.signatories[0].hasMultipleSignatories
    },

    /// ///////////////////////////
    // ADDRESS MODAL EVENTs
    /// ///////////////////////////
    useEntered () {
      if (this.shippingAddressNeedsValidation) {
        this.validateShippingAddress()
      } else if (this.emailPostValidation) {
        this.emailPostValidation = false
        this.showEmailCreditApplicationModal = true
      } else {
        this.saveApplication(this.cb, this.order)
      }
    },
    updateFormData () {
      if (this.sectionValidating === 'billing') {
        this.formData.billingInfo.streetAddress = this.validatedAddress.streetAddress1
        this.formData.billingInfo.addressUnitNumber = this.validatedAddress.streetAddress2
        this.formData.billingInfo.city = this.validatedAddress.city
        this.formData.billingInfo.state = this.validatedAddress.state
        this.formData.billingInfo.postalCode = this.validatedAddress.postalCode
        if (this.formData.billingInfo.isBillingAddressSameAsShipping === '1') {
          this.formData.billingInfo.shippingStreetAddress = this.validatedAddress.streetAddress1
          this.formData.billingInfo.shippingAddressUnitNumber = this.validatedAddress.streetAddress2
          this.formData.billingInfo.shippingCity = this.validatedAddress.city
          this.formData.billingInfo.shippingState = this.validatedAddress.state
          this.formData.billingInfo.shippingPostalCode = this.validatedAddress.postalCode
        }
      } else if (this.sectionValidating === 'shipping') {
        this.formData.billingInfo.shippingStreetAddress = this.validatedAddress.streetAddress1
        this.formData.billingInfo.shippingAddressUnitNumber = this.validatedAddress.streetAddress2
        this.formData.billingInfo.shippingCity = this.validatedAddress.city
        this.formData.billingInfo.shippingState = this.validatedAddress.state
        this.formData.billingInfo.shippingPostalCode = this.validatedAddress.postalCode
      }
      this.sectionValidating = null

      if (this.shippingAddressNeedsValidation) {
        this.validateShippingAddress()
      } else if (this.emailPostValidation) {
        this.emailPostValidation = false
        this.showEmailCreditApplicationModal = true
      } else {
        this.saveApplication(this.cb, this.order)
      }
    },
    editAddress () {
      this.showModal = false
      this.successMessage = ''
      this.loading = false
    },
    closeEmailModal () {
      this.emailPostValidation = false
      this.showEmailCreditApplicationModal = false
      this.successMessage = ''
      this.loading = false
    },

    /// ///////////////////////////
    // FORM EVENTs
    /// ///////////////////////////

    _validateAddresses (addressesToValidate) {
      if (addressesToValidate.billing) {
        this.shippingAddressNeedsValidation = addressesToValidate.shipping
        this.validateBillingAddress()
      } else if (addressesToValidate.shipping) {
        this.validateShippingAddress()
      }
    },
    _cleanupAddressModal () {
      // Address modal cleanup
      this.cb = null
      this.order = null
      this.validatedAddress = {}
      this.preValidatedAddress = {}
      this.apiObject = {}
    },
    onSave (cb, order, validateFormAddress = false) {
      this.loading = true

      // Check if array isn't false and there is a true value in the object
      if (validateFormAddress && Object.keys(validateFormAddress).some(k => validateFormAddress[k])) {
        this.cb = cb
        this.order = order
        this._validateAddresses(validateFormAddress)
      } else {
        this.saveApplication(cb, order, this.isCompleted)
        this._cleanupAddressModal()
      }
      this.isLastStep = order + 1 === this.groupedSteps.length
    },

    onEmail (cb, currentOrder, validateFormAddress = false) {
      this.loading = true
      this.successMessage = 'Saving...'
      this.cb = cb
      this.order = currentOrder

      // Check if array isn't false and there is a true value in the object
      if (validateFormAddress && Object.keys(validateFormAddress).some(k => validateFormAddress[k])) {
        this.emailPostValidation = true
        this._validateAddresses(validateFormAddress)
      } else {
        this.showEmailCreditApplicationModal = true
      }
    },

    onSubmit (cb, order) {
      const isSubmit = true
      return this.saveApplication(cb, order, isSubmit)
    },

    saveApplication (cb, order, isSubmit = false) {
      this.successMessage = 'Saving...'
      if (!this.isAppCreatedSuccessfully) {
        return this.createApplication(cb, order, isSubmit)
      } else {
        return this.updateApplication(cb, order, isSubmit)
      }
    },

    /// ///////////////////////////
    // FORM EVENT HANDLERs
    /// ///////////////////////////

    createApplication (cb, order, isSubmit = false) {
      // let guid = this.guid
      // if (guid == null) {
      //   guid = '00000000-0000-0000-0000-000000000000'
      // }

      const application = {
        guid: '00000000-0000-0000-0000-000000000000',
        bannerId: this.bannerId,
        notify: this.$route.query.notify,
        [BUSINESS_INFO]: this.formData[BUSINESS_INFO],
        [BILLING_INFO]: this.formData[BILLING_INFO],
        isInternal: this.isInternal,
        isCreatedFromStorefront: this.isInternal === false && this.webUser !== null
      }

      if (this.formData[BUSINESS_INFO].insideSalesPerson === '') {
        this.formData[BUSINESS_INFO].insideSalesPerson = null
      }
      if (this.formData[BUSINESS_INFO].outsideSalesPerson === '') {
        this.formData[BUSINESS_INFO].outsideSalesPerson = null
      }

      this.loading = true
      return this.$http
        .post(
          '/api/CreditApplication/create',
          application
        )
        .then(async (res) => {
          this.guid = res.data.guid

          this.formData[UPLOAD_APPLICATION].storageGuid = res.data.guid
          this.formData[PREFERENCES].storageGuid = res.data.guid

          this.isAppCreatedSuccessfully = true
          this.formData[BUSINESS_INFO].isStepDataReady = true
          this.formData[BILLING_INFO].isStepDataReady = true

          // Scenario: When the applicant is providing their own AccountNumber (HasAccount), it is set on the BE but doesn't get set
          // like when they use the FE Branch Selector.
          // Action: Set branchNumber from result to formdata.businessInfo so it makes it to BE update
          const businessInfo = res.data.missingProperties.application.businessInfo
          if (businessInfo && businessInfo.hasAccount === true && businessInfo.branchNumber) {
            this.formData[BUSINESS_INFO].branchNumber = businessInfo.branchNumber
          }

          if (cb != null) { // will be null in 'full' mode
            // eslint-disable-next-line n/no-callback-literal
            cb(true, order + 1)
          }

          this.successMessage = 'Application Created'
          this.errorMessage = ''

          // create handles values for just the first step. update handles the rest of the form
          if (this.mode === 'full') {
            this.loading = true
            this.successMessage += '. Saving...'
            await this.updateApplication(cb, order, isSubmit)
          }
          this.$v.formData.$reset()
        })
        .catch(err => {
          console.log('create application error', err)
          this.successMessage = ''
          this.errorMessage = 'Failed to create'

          return this.$handleFormErrors(
            err.response.data,
            formDefinitions
          )
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateApplication (cb, order, isSubmit = false) {
      // in full mode data for the entire form is sent over for validation

      // TODO: evaluating the entire form can take some time, there should be some visual indication that the form is being validated

      // in 'stepped' mode, need to set isStepDataReady to determine what steps to validate on POST
      if (this.mode === 'stepped') {
        const keys = Object.keys(formDefinitions)
        for (const key of keys) {
          if (formDefinitions[key].group === order) {
            this.formData[key].isStepDataReady = true
          }
        }
      }

      const application = {
        guid: this.guid,
        helloSignRequestId: this.formData.helloSignRequestId,
        bannerId: this.bannerId,
        ...this.$getFormData(formDefinitions)
      }

      if (application.signatories != null) {
        // Overwrite signatories in signatory section using signatories from esign section
        application.signatories.signatories = [...this.formData.eSign.signatories]
        if (application.signatories.signatories.length > 2) {
          application.signatories.signatories.length = 2
        }
        if (application.signatories.signatories.length === 2) {
          Object.entries(application.signatories.signatories).forEach(([key, value]) => {
            if (key === '1' && (value.firstName === null || value.firstName.length === 0) && (value.lastName === null || value.lastName.length === 0)) {
              application.signatories.signatories.length = 1
            }
          })
        }
      }

      if (application.tradeReferences != null) {
        application.tradeReferences.tradeReferences = [...this.formData.tradeReferences.tradeReferences]
      }

      // Assigning customerComments from E-Sign for final save
      if (application.eSign && application.eSign.customerComments) {
        application.customerComments = application.eSign.customerComments
      }

      // Bypass updating the form if submitting.
      if (isSubmit) {
        this.isSubmittedSuccessfully = true
        return
      }

      // TODO: Clean up remaining code tied to submit
      const endpoint = 'update'

      this.loading = true
      return this.$http
        .post(
          `/api/CreditApplication/${endpoint}`,
          application
        )
        .then(res => {
          // console.log(`updateApplication() res.data.application.signatories=${JSON.stringify(res.data.application.signatories)}`)
          // console.log(`updateApplication() formData.signatories=${JSON.stringify(this.formData.signatories)}`)
          // const returnedApplication = res.data.application
          // Handle Signatories
          if (application.signatories != null) {
            const helloSignRequestId = res.data.application.helloSignRequestId

            if (helloSignRequestId.length > 0) {
              this.formData.helloSignRequestId = helloSignRequestId
            }

            const clientSignatories = application.signatories.signatories
            // console.log(`clientSignatories=${JSON.stringify(clientSignatories)}`)
            const incomingSignatories = res.data.application.signatories
            // console.log(`incomingSignatories=${JSON.stringify(incomingSignatories)}`)

            // get data from hellosign if available
            if (clientSignatories.length > 0 && incomingSignatories.length > 0) {
              // const ogSig = clientSignatories[0]

              incomingSignatories.forEach(s => {
                // console.log(`s=${JSON.stringify(s)}`)
                const signatoryIdx = this.formData.signatories.signatories.findIndex(sig => sig.email === s.email)
                // console.log(`sig forEach this.formData.signatories.signatories[signatoryIdx]=${JSON.stringify(this.formData.signatories.signatories[signatoryIdx])}`)

                if (signatoryIdx != null) {
                  this.formData.signatories.signatories[signatoryIdx].signatoryUrl = s.signatoryUrl
                  this.formData.signatories.signatories[signatoryIdx].signature = s.signature
                  this.formData.signatories.signatories[signatoryIdx].signatoryId = s.signatoryId
                }
              })

              // const afterSig = this.formData.signatories.signatories[0]
            }
          } // TODO: move this if statement handling signatories to a method

          if (application.tradeReferences != null && res.data.application.tradeReferences != null) {
            // Need to assign SQL TradeReference.referenceId for Next Update
            const clientTradeReferences = application.tradeReferences.tradeReferences
            const incomingTradeReferences = res.data.application.tradeReferences

            if (clientTradeReferences.length > 0 && incomingTradeReferences.length > 0) {
              incomingTradeReferences.forEach(tr => {
                const tradeReferenceIdx = this.formData.tradeReferences.tradeReferences.findIndex(t => t.email === tr.email)
                if (tradeReferenceIdx != null && this.formData.tradeReferences.tradeReferences[tradeReferenceIdx].referenceId === 0) {
                  this.formData.tradeReferences.tradeReferences[tradeReferenceIdx].referenceId = tr.referenceId
                }
              })
            }
          }

          if (application.customerComments) {
            this.formData[E_SIGN].customerComments = application.customerComments
          }

          if (cb != null) { // will be null in 'full' mode
            // eslint-disable-next-line n/no-callback-literal
            cb(true, order + 1)
          }

          if (res.data.success) {
            this.successMessage = isSubmit ? 'Application Submitted' : 'Application Saved'
            this.errorMessage = ''
          } else {
            this.successMessage = ''
            this.errorMessage = res.data.message || 'Unknown error'
          }

          // Prompts nav confirmation modal to not show if form has data, but has been saved
          this.$v.formData.$reset()
        })
        .catch(err => this.$handleFormErrors(
          err.response.data,
          formDefinitions
        ))
        .finally(() => {
          this.loading = false
        })
    },

    async sendEmail (emailRecipients, subject, body) {
      await this.saveApplication(this.cb, this.order)

      body = body.replace(/\n/g, '<br />')

      const params = JSON.stringify({ emailRecipients, subject, body })
      const tenant = this.$route.params.tenant
      const guid = this.guid

      this.loading = true
      axios.post(`/api/CreditReview/sendToCustomer/${tenant}/${guid}`, params, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          this.showEmailCreditApplicationModal = false
          this.emailSent = true
          this.successMessage = 'Email sent'

          this.$router.push({ name: 'dashboard', params: { tenant } })
        })
        .catch(() => {
          this.showEmailCreditApplicationModal = false
          this.errorMessage = 'Error sending email'
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeFormAddressValidationModal () {
      this.showModal = false
      this.loading = false
    },
    closeFormNavigationConfirmationModal () {
      this.displayNavWarning = false
      this.loading = false
    },

    setCompleted (obj) {
      this.completed = obj
    },

    setCustomerComments (text) {
      this.formData[E_SIGN].customerComments = text
    }

  }
}
</script>

<style lang="scss" scoped>
#accordion {
  padding-inline-start: 0;
}
</style>
