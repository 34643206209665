import Vue from 'vue'

const store = {
  namespaced: true,
  state: {
    open: [],
    data: null
  },
  getters: {
    active: (state) => (state.open.length > 0 ? state.open[0] : null),
    allOpen: (state) => state.open,
    modalData: (state) => state.data
  },
  mutations: {
    OPEN: (state, name) => state.open.unshift(name),
    CLOSE: (state, name) => (state.open = state.open.filter((e) => e !== name)),
    SET_MODAL_DATA: (state, data) => (state.data = data)
  },
  actions: {
    open ({ commit }, req) {
      if (req.name != null) {
        commit('OPEN', req.name)
      }
      if (req.data != null) {
        commit('SET_MODAL_DATA', req.data)
      }
    },
    close ({ commit }, name) {
      commit('CLOSE', name)

      Vue.nextTick(() => { // wait til nextTick, otherwise defaults on modal may be seen (for example notifications will auto close and set modal data to default before actually fading away)
        commit('SET_MODAL_DATA', null)
      })
    }
  }
}

export default store
