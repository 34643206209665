<template>
  <div
    id="application-component"
    class="card component-container credit-application">
    <FormStepper
      :submit-label="submitLabel()"
      :steps="formSteps"
      :mode="mode"
      :on-save="$onSave"
      :is-save-valid="isSaveValid"
      :on-submit="$onSubmit"
      :success-message="successMessage"
      :error-message="errorMessage"
      :submitted-on-date="submittedOnDate"
      :submitted-by="submittedBy"
      :validation-failures="validationFailures"
      :loading="loading"
      :invalid="$v.$invalid"
      :is-submitted-successfully="isSubmittedSuccessfully"
      :is-form-complete="isFormComplete"
      @check-validations="$v.$touch()">
      <JobAccountFormComplete :submitted-data="getCompleteData"></JobAccountFormComplete>
    </FormStepper>
    <form-navigation-confirmation-modal
      :is-dialog-open="displayNavWarning"
      :is-save-valid="isSaveValid"
      @saveAndNavigate="saveAndNavigate"
      @discardAndNavigate="discardAndNavigate"
      @close="displayNavWarning = false">
    </form-navigation-confirmation-modal>
  </div>
</template>

<script type="text/javascript">
import TenantsMixin from 'mixins/tenants.mixin'
import FormMixin from '@/components/form/Form.mixin'
import FormStepper from '@/components/form/FormStepper.vue'
import JobAccountFormComplete from './JobAccountFormComplete.vue'
import FormNavigationConfirmationModal from '@/components/FormNavigationConfirmationModal.vue'
import lodash from 'lodash'
import { submitJobAccountForm } from '@/api/forms/form-data-submit'
// import { checkAuth } from '@/main'
import axios from 'ca-http-service'

import {
  email,
  maxLength,
  required,
  requiredIf
} from 'vuelidate/lib/validators'

import {
  formTitle,
  formDefinitions,
  ACCOUNT_INFORMATION,
  COMPANY_INFORMATION,
  PROJECT_INFORMATION,
  PROJECT_ADDRESS,
  PROJECT_MATERIAL,
  PROJECT_CONTACTS,
  BRANCH_INFORMATION,
  GENERAL_CONTRACTOR,
  PROPERTY_OWNER_INFORMATION,
  DOCUMENTS,
  EMAIL
} from './JobAccountForm.definitions'

import {
  getCustomerAccountInfo
} from '@/api/company/account-info'

const JOB_ACCOUNTS_FORM_KEY = 'job-account'

export default {
  name: 'JobAccountForm',
  components: {
    FormStepper,
    JobAccountFormComplete,
    FormNavigationConfirmationModal
  },
  mixins: [
    TenantsMixin,
    FormMixin
  ],
  data () {
    return {
      /// ///////////////////////////
      // FormStepper data
      /// ///////////////////////////
      formTitle,
      formDefinitions,
      formSteps: [],
      // formData should hold data needed to make a POST to api
      formData: this.$initFormData(formDefinitions),
      completed: {
        companyAccountNumber: '',
        company: '',
        jobName: '',
        jobLimitRequested: '',
        assignedBranch: '',
        assignedBranchId: '',
        assignedBranchNumber: ''
      },
      /// ///////////////////////////
      // Form usage-specific data
      /// ///////////////////////////
      // attachmentId: null,
      pendingJobAccountNumber: null,
      submissionWarningMessage: null,
      webUser: null,
      existingJobAccounts: []
    }
  },
  computed: {
    isSaveValid () {
      // set logic for is save valid here
      const bannerName = this.$route.params.tenant
      if (bannerName === 'platt') { // platt uses companyInformation section
        return !this.$v.formData.companyInformation.$invalid
      } else { // non-platt uses accountInformation section
        return !this.$v.formData.accountInformation.$invalid
      }
    },
    getCompleteData () {
      return {
        ...this.completed,
        pendingJobAccountNumber: this.pendingJobAccountNumber,
        submissionWarningMessage: this.submissionWarningMessage
      }
    }
  },
  watch: {
    'formData.accountInformation.shipToAccount': {
      async handler (newVal, oldVal) {
        this.formData[ACCOUNT_INFORMATION]
          .accountNumber = newVal
      }
    },
    'formData.accountInformation.billTo.companyName': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.company = newVal
      },
      deep: true
    },
    'formData.accountInformation.billTo.accountNumber': {
      immediate: true,
      handler (newVal, oldVal) {
        if (!newVal) {
          this.setSectionVisibility(false)
          return
        }
        this.completed.companyAccountNumber = newVal
        this.setSectionVisibility(true)
      },
      deep: true
    },
    'formData.accountInformation.billTo.branchId': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.assignedBranch = newVal
      },
      deep: true
    },
    'formData.projectInformation.name': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.jobName = newVal
      }
    },
    'formData.projectMaterial.requestedJobLimit': {
      immediate: true,
      handler (newVal, oldVal) {
        this.completed.jobLimitRequested = newVal
      }
    },
    // COMPANY_INFORMATION (companyInformation ) is Platt only
    'formData.companyInformation.accountNumber': {
      handler (newVal, oldVal) {
        if (!newVal) {
          this.setSectionVisibility(false)
          return
        }
        // Same one previously assigned
        if (newVal && typeof newVal === 'string' && oldVal && newVal === oldVal.toString()) {
          return
        }
        const accountNumber = newVal

        const tenant = this.$route.params.tenant === 'platt' ? 'platt' : 'rexel'
        getCustomerAccountInfo(accountNumber, tenant, this.$route.query.sftoken).then((results) => {
          this.formData[COMPANY_INFORMATION]
            .accountNumber = results.accountNumber != null ? results.accountNumber : ''
          this.formData[COMPANY_INFORMATION]
            .accountName = results.companyName != null ? results.companyName : ''
          this.completed.companyAccountNumber = accountNumber
          this.completed.company = results.companyName != null ? results.companyName : ''
          this.completed.assignedBranch = results.branchName != null ? results.branchName : ''
          this.completed.assignedBranchId = results.branchId != null ? results.branchId : ''
          this.completed.assignedBranchNumber = results.branchNumber != null ? results.branchNumber : ''
          this.setSectionVisibility(true)

          if (results.accountNumber) {
            axios.post(`/api/JobAccount/platt/${results.accountNumber}/existingJobAccounts`, { params: { accountNumber: results.accountNumber } }).then((res) => {
              if (res.data.length) {
                this.existingJobAccounts = res.data.map((ja) => { return ja.number })
              } else {
                this.existingJobAccounts = []
              }
            }).catch((error) => {
              console.error(error)
            })
          }
        }).catch((error) => {
          console.error(error)
        })
      }
    },
    'formData.propertyOwnerInformation.isSameAsProjectInformation': {
      handler (newVal) {
        if (this.loading === false) {
          const jobAddressData = this.getJobAddressData(newVal)

          this.setPropertyOwnerData(jobAddressData)

          this.$root.$emit('updatePropertyOwner', jobAddressData)
        }
      }
    },
    'formData.generalContractor.sameAsCompanyInformation': {
      handler (newVal) {
        if (this.loading === false) {
          const companyAddressData = this.getCompanyAddressData(newVal)

          this.setContractorData('general', companyAddressData)

          this.$root.$emit('updateGeneralContractor', companyAddressData)
        }
      }
    },
    'formData.generalContractor.subSameAsCompanyInformation': {
      handler (newVal) {
        if (this.loading === false) {
          const companyAddressData = this.getCompanyAddressData(newVal)

          this.setContractorData('sub', companyAddressData)

          this.$root.$emit('updateSubContractor', companyAddressData)
        }
      }
    }
  },
  async mounted () {
    if (!this.$isFormInProgress()) {
      if (!this.isInternal() && this.$route.query.sftoken != null) {
        const bannerName = this.$route.params.tenant
        axios.get('/api/creditapplication/storefront-token', {
          params: {
            sftoken: this.$route.query.sftoken
          }
        }).then((res) => {
          this.webUser = res.data

          // Send user to error page if an errorDescription was assigned on the BE
          if (this.webUser.errorDescription) {
            this.$router.push({ name: 'pageUnavailable', tenant: this.$route.tenant })
          }

          if (bannerName === 'platt') {
            this.formData[EMAIL].email = this.webUser.email
            this.formData[COMPANY_INFORMATION].accountNumber = this.webUser.customerNumber
          } else if (bannerName === 'rexel' || bannerName === 'gexpro') {
            this.formData[ACCOUNT_INFORMATION].billToAccount = this.webUser.customerNumber
          }
          this.formData[PROJECT_INFORMATION].confirmationEmail = this.webUser.email
        }).catch((err) => {
          console.error('error retrieving storefront logged in user', err)
        })
      }
      // checkAuth()

      // Initialize a new Create form
      const guid = this.$createGuid()
      this.attachmentId = guid
      this.formData[PROJECT_INFORMATION].storageGuid = guid
      this.formData[DOCUMENTS].storageGuid = guid
    } else {
      this.$setFormFileMappings([
        {
          formFileType: 'bondedDoc',
          formSection: PROJECT_INFORMATION,
          formSectionField: 'bondedDocuments'
        },
        {
          formFileType: 'taxDoc',
          formSection: PROJECT_INFORMATION,
          formSectionField: 'selectedDocuments'
        },
        {
          formFileType: 'documentDoc',
          formSection: DOCUMENTS,
          formSectionField: 'selectedDocuments'
        }
      ])
    }

    this.setSectionVisibility(false)
  },
  methods: {
    /// ///////////////////////////
    // FORM EVENT HANDLERs
    /// ///////////////////////////

    buildFormData (formObject, isSave) {
      const formData = new FormData()
      const bondedDoc = this.$setDocumentValue(this.formData[PROJECT_INFORMATION].bondedDocuments)
      const taxDoc = this.$setDocumentValue(this.formData[PROJECT_INFORMATION].selectedDocuments)
      const documentDoc = this.formData[DOCUMENTS].selectedDocuments instanceof Array ? this.formData[DOCUMENTS].selectedDocuments : [this.formData[DOCUMENTS].selectedDocuments]

      const hasAttachedFiles = (bondedDoc != null || taxDoc != null || documentDoc != null) ? 1 : 0

      // This is where files are aggregated to send to the BE on Save
      if (isSave) {
        // On Save we need to append an array of files to formData
        const attachments = {}
        if (bondedDoc != null) { attachments.bondedDoc = bondedDoc }
        if (taxDoc != null) { attachments.taxDoc = taxDoc }
        if (documentDoc != null) {
          let count = 1
          documentDoc.forEach(doc => {
            attachments[`documentDoc${count}`] = doc
            count++
          })
        }
        Object.entries(attachments).forEach(([key, value]) => {
          if (value) {
            formData.append('files', value)
            formData.append('filesMetaData', key) // DEV NOTE for testing purposes Commenting out this Will trigger an error when attempting to upload a file
          }
        })
        formData.append('files', attachments)
      }

      // DRY THIS // ================
      const attachmentId = this.attachmentId
      const currentUser = this.$store.getters.currentUserInfo !== undefined ? this.$store.getters.currentUserInfo : ''

      const bannerName = this.$route.params.tenant

      // company is for internal users for the currently set banner which isn't set in external mode so use route
      const bannerId = this.isInternal() ? this.company.tenantId : Number(this.getBannerIdFromName())

      /// accountName
      const accountInformation = this.formData[ACCOUNT_INFORMATION]
      const companyInformation = this.formData[COMPANY_INFORMATION]

      const billToAccountName = bannerId === 1
        ? companyInformation.accountName
        : accountInformation.billTo.companyName

      const billToAccountNumber = bannerId === 1
        ? companyInformation.accountNumber
        : accountInformation.billTo.accountNumber

      const projectName = this.formData.projectInformation.name

      const branchInformation = this.formData[BRANCH_INFORMATION]
      const plattBranchId = branchInformation != null
        ? branchInformation.branchId
        : null

      const billTo = bannerId === 1
        ? companyInformation.billTo
        : accountInformation.billTo

      const billToBranchId = billTo != null
        ? billTo.branchId
        : null

      const assignedBranchId = bannerId === 1
        ? plattBranchId
        : billToBranchId

      const jobAccountId = this.pendingJobAccountNumber

      const customerEmail = bannerId === 1 ? this.formData[EMAIL].email : null

      formObject.bannerId = bannerId
      const finalData = {
        appType: JOB_ACCOUNTS_FORM_KEY,
        bannerName,
        accountNumber: billToAccountNumber,
        accountName: billToAccountName,
        projectName,
        hasAttachedFiles,
        // TODO bannerId <- Why can't this be here without assigning formObject.bannerId above
        assignedBranchId,

        currentUser,
        attachmentId,
        jobAccountId,
        isInternal: this.isInternal(),
        ...formObject // merge generated data w/ finalData
      }

      this.$populateFormDataObject(formData, finalData)
      // ==============================

      formData.append('bondedDoc', bondedDoc)
      formData.append('taxDoc', taxDoc)
      documentDoc.forEach(doc => {
        formData.append('documentDoc', doc)
      })

      // NOTE: companyInformation is PLATT only -- accountInformation is NON-PLATT
      if (this.isPlatt()) {
        formData.append('companyInformation', companyInformation)
        formData.append('customerEmail', customerEmail)
      } else {
        formData.append('accountInformation', accountInformation)
      }
      return formData
    },
    async isDataSizeTooLarge (formData) {
      // converting formData to a blob to check the size of the request
      const res = new Response(formData)
      const blob = await res.blob()
      if (blob.size > 29500000) {
        return true
      }
      return false
    },
    getAdditionalFormData () {
      return {
        subContractor: this.getSubContractorInfo(),
        lienAgent: this.getLienAgent(),
        pendingJobAccountId: this.pendingJobAccountNumber
      }
    },

    // TODO:: DRY AND MOVE THIS TO MIXIN, ALL FORMS WILL DO THE SAME THING
    async save (state = null) {
      this.loading = true
      const isCompleted = state === this.FORM_IS_COMPLETED

      const formObject = this.$buildFormObject(JOB_ACCOUNTS_FORM_KEY, this.getAdditionalFormData())

      const saveInProgressFormRequestObject = this.$buildSaveInProgressDataRequest(formObject, isCompleted)

      const inProgressFormDataStorageRequest = this.buildFormData(saveInProgressFormRequestObject, true)

      let isSuccess = false

      if (await this.isDataSizeTooLarge(inProgressFormDataStorageRequest)) {
        palert({
          title: 'Unable to Save Application',
          type: 'warning',
          text: 'The total file size of the attached documents is too large.'
        })
        this.loading = false
      } else {
        return await this.$saveFormData(inProgressFormDataStorageRequest)
          .then((response) => {
            if (response.status === 200) {
              isSuccess = true
              return response.data.data
            }
          })
          .catch((error) => {
            console.error('error', error)
            if (error.response.status === 400) {
              console.error('do something?', error.response)
            }
            return null
          })
          .finally(() => {
            if (state == null) {
              this.$store.dispatch('modals/open', {
                name: 'form-save-modal',
                data: { isSuccess }
              })
            }
            this.loading = false
          })
      }
    },

    async submit (applicationKey = null) {
      this.loading = true
      this.errorMessage = ''

      const formObject = this.$buildFormObject(JOB_ACCOUNTS_FORM_KEY, this.getAdditionalFormData())
      const formData = this.buildFormData(formObject)

      if (await this.isDataSizeTooLarge(formData)) {
        palert({
          title: 'Unable to Submit Application',
          type: 'warning',
          text: 'The total file size of the attached documents is too large.'
        })
        this.loading = false
      } else {
        return await submitJobAccountForm(this.$route.params.tenant, applicationKey, this.$route.query.sftoken, formData)
          .then(res => {
            this.submissionWarningMessage = res.data.warningMessage
            const successful = res.data.isSuccess
            const data = res.data.data

            if (this.isPlatt()) {
              return successful
            } else {
              this.pendingJobAccountNumber = data // if Rexel, data is pendingJobAccountNumber.
              return data
            }
          })
          .catch((err) => {
            this.$handleFormErrors(
              err.response.data,
              formDefinitions
            )
            return null
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    /// ///////////////////////////
    // Utility Methods
    /// ///////////////////////////

    getLienAgent () {
      const lienAgent = lodash.pick(this.formData.propertyOwnerInformation, [
        'lienAgentName',
        'lienAgentContactNumber',
        'lienAgentStreetAddress1',
        'lienAgentStreetAddress2',
        'lienAgentCity',
        'lienAgentState',
        'lienAgentPostalCode'
      ])
      return lienAgent
    },
    getSubContractorInfo () {
      const subContractor = lodash.pick(this.formData.generalContractor, [
        'subContractorName',
        'subContractorContactNumber',
        'subContractorStreetAddress1',
        'subContractorStreetAddress2',
        'subContractorPostalCode',
        'subContractorState',
        'subContractorCity'
      ])
      return subContractor
    },

    getCompanyAddressData (getCompanyData) {
      const companyAddressKeys = [
        'companyName',
        'contactNumber',
        'streetAddress1',
        'streetAddress2',
        'city',
        'state',
        'postalCode'
      ]

      const companyAddressData = {}
      companyAddressKeys.map(k => {
        companyAddressData[k] = ''
        return k
      })

      const usedCompanyData = this.$route.params.tenant === 'platt'
        ? this.formData[COMPANY_INFORMATION]
        : this.formData[ACCOUNT_INFORMATION].billTo

      if (Object.keys(usedCompanyData).length <= 0 || !getCompanyData) {
        return companyAddressData
      }

      const additionalCompanyAddressKeys = companyAddressKeys.concat([
        'accountName', // Platt
        'streetAddress' // Non-Platt
      ]).sort()

      additionalCompanyAddressKeys.map(k => {
        if (typeof usedCompanyData[k] === 'undefined') return k
        const addressValue = usedCompanyData[k]
        switch (k) {
          case 'accountName':
            companyAddressData.companyName = addressValue
            break
          case 'streetAddress':
            companyAddressData.streetAddress1 = addressValue
            break
          default:
            companyAddressData[k] = addressValue
        }
        return k
      })

      return companyAddressData
    },
    getJobAddressData (getJobAddressData) {
      const addressDataKeys = [
        'streetAddress1',
        'streetAddress2',
        'city',
        'state',
        'postalCode'
      ]

      const jobAddressData = {}
      addressDataKeys.map(k => {
        jobAddressData[k] = ''
        return k
      })

      const usedJobAddressData = this.formData[PROJECT_ADDRESS]

      if (Object.keys(usedJobAddressData).length <= 0 || !getJobAddressData) {
        return jobAddressData
      }

      addressDataKeys.map(k => {
        if (typeof usedJobAddressData[k] === 'undefined') return k

        jobAddressData[k] = usedJobAddressData[k]
        return k
      })

      return jobAddressData
    },
    setContractorData (type, addressData) {
      const keys = [
        'ContractorName',
        'ContractorContactNumber',
        'ContractorState',
        'ContractorStreetAddress1',
        'ContractorStreetAddress2',
        'ContractorPostalCode',
        'ContractorCity'
      ]
      const keysWithType = keys.map((k) => `${type}${k}`)

      this.formData[GENERAL_CONTRACTOR][keysWithType[0]] = addressData.companyName
      this.formData[GENERAL_CONTRACTOR][keysWithType[1]] = addressData.contactNumber
      this.formData[GENERAL_CONTRACTOR][keysWithType[2]] = addressData.state
      this.formData[GENERAL_CONTRACTOR][keysWithType[3]] = addressData.streetAddress1
      this.formData[GENERAL_CONTRACTOR][keysWithType[4]] = addressData.streetAddress2
      this.formData[GENERAL_CONTRACTOR][keysWithType[5]] = addressData.postalCode
      this.formData[GENERAL_CONTRACTOR][keysWithType[6]] = addressData.city
    },
    setPropertyOwnerData (jobAddressData) {
      this.formData[PROPERTY_OWNER_INFORMATION].state = jobAddressData.state
      this.formData[PROPERTY_OWNER_INFORMATION].streetAddress1 = jobAddressData.streetAddress1
      this.formData[PROPERTY_OWNER_INFORMATION].streetAddress2 = jobAddressData.streetAddress2
      this.formData[PROPERTY_OWNER_INFORMATION].postalCode = jobAddressData.postalCode
      this.formData[PROPERTY_OWNER_INFORMATION].city = jobAddressData.city
    },
    isInternal () {
      return this.$route.meta.isInternalForm
    },
    filetypeRule (file) {
      const acceptedTypes = ['doc', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'pdf', 'bmp', 'jpg', 'png']
      if (file && file.name) {
        return acceptedTypes.includes(file.name.split('.').pop().toLowerCase())
      }
      return true
    },
    setSectionVisibility (showAll) {
      const sectionToShow = this.isPlatt() ? COMPANY_INFORMATION : ACCOUNT_INFORMATION
      const stepsVisible = Object.keys(this.formData).map(s => {
        return {
          stepKey: s,
          // if showAll is true and isInternal return true, else hide email step. If show all is false show only COMPANY_INFORMATION || ACCOUNT_INFORMATION
          // using this.$route.meta.isInternalForm as Method isInternal is returning a function instead of the boolean value.
          visible: showAll ? this.$route.meta.isInternalForm ? true : s !== 'email' : s === sectionToShow
        }
      })
      this.$store.dispatch('setStepsVisible', stepsVisible)
    },
    submitLabel () {
      return this.isInternal ? 'Submit' : 'Save and Submit'
    }
  },

  validations () {
    return {
      formData: {
        ...this.$isStepForTenant(formDefinitions[ACCOUNT_INFORMATION]) && {
          [ACCOUNT_INFORMATION]: {
            billToAccount: {
              required
            },
            shipToAccount: {
              required
            }
          }
        },
        ...this.$isStepForTenant(formDefinitions[COMPANY_INFORMATION]) && {
          [COMPANY_INFORMATION]: {
            accountNumber: {
              required
            },
            accountName: {
              required
            }
          }
        },
        ...this.$isStepForTenant(formDefinitions[BRANCH_INFORMATION]) && {
          [BRANCH_INFORMATION]: {
            branchId: {
              required
            }
          }
        },
        [PROJECT_INFORMATION]: {
          storageGuid: {
            required: requiredIf(function () {
              const collection = this.formData[PROJECT_INFORMATION].selectedDocuments
              if (collection instanceof FormData) {
                return true
              } else {
                return collection != null ? collection.length > 0 : false
              }
            })
          },
          name: {
            required
          },
          jobType: {
            required
          },
          startDate: {
            required
          },
          endDate: {
            required
          },
          natureOfProject: {
            required
          },
          natureOfProjectDescription: {
            required: requiredIf(function () {
              return this.isPlatt() && this.formData[PROJECT_INFORMATION].natureOfProject === 'Other'
            })
          },
          isEdiCustomer: {
            required: requiredIf(function () {
              return !this.isPlatt()
            })
          },
          nuclearProject: {
            required: requiredIf(function () {
              return !this.isPlatt()
            })
          },
          isJobBonded: {
            required
          },
          isMbeOrDbe: {
            required: requiredIf(function () {
              return !this.isPlatt()
            })
          },
          isTaxExempt: {
            required
          },
          isWantingDualPartyCheckArrangement: {
            required
          },
          isExportJob: {
            required
          },
          // contractNumber doubles as Job ID for Platt
          contractNumber: {
            required: requiredIf(function () {
              return this.isPlatt()
            }),
            maxLength: this.isPlatt() && maxLength(7),
            isUnique (value) {
              if (value === '' || this.isRexelOrGexpro()) {
                return true
              }
              return !this.existingJobAccounts.includes(value)
            }
          },
          jobDescription: {
            required: requiredIf(function () {
              return this.isPlatt()
            })
          },
          confirmationEmail: {
            email,
            required: requiredIf(function () {
              return !this.isInternal()
            })
          }
        },
        [PROJECT_ADDRESS]: {
          streetAddress1: {
            maxLength: this.formData[PROJECT_ADDRESS].streetAddress1.length > 35 && maxLength(35),
            required
          },
          streetAddress2: {
            maxLength: this.formData[PROJECT_ADDRESS].streetAddress2.length > 35 && maxLength(35)
          },
          city: {
            required
          },
          state: {
            required
          },
          postalCode: {
            required
          }
        },
        [PROJECT_MATERIAL]: {
          requestedJobLimit: {
            minValueZero: ((formData) => (value) => parseInt(value, 10) > 0)(this.formData),
            required
          }
        },
        ...this.$isStepForTenant(formDefinitions[PROJECT_CONTACTS]) && {
          [PROJECT_CONTACTS]: {
            firstName: {
              required: requiredIf(function () {
                return this.formData[PROJECT_CONTACTS][PROJECT_CONTACTS].length < 1
              })
            },
            lastName: {
              required: requiredIf(function () {
                return this.formData[PROJECT_CONTACTS][PROJECT_CONTACTS].length < 1
              })
            },
            contactNumber: {
              required: requiredIf(function () {
                return this.formData[PROJECT_CONTACTS][PROJECT_CONTACTS].length < 1
              }),
              minLength: 14
            },
            emailAddress: {
              email
            },
            jobTitle: {
              required: requiredIf(function () {
                return this.isRexelOrGexpro() && this.formData[PROJECT_CONTACTS][PROJECT_CONTACTS].length < 1
              })
            }
          }
        },
        [GENERAL_CONTRACTOR]: {
          generalContractorName: {
            required
          },
          generalContractorContactNumber: {
            required,
            minLength: 14
          },
          generalContractorStreetAddress1: {
            required
          },
          generalContractorStreetAddress2: {
          },
          generalContractorCity: {
            required
          },
          generalContractorState: {
            required
          },
          generalContractorPostalCode: {
            required
          }
        },
        [PROPERTY_OWNER_INFORMATION]: {
          name: {
            required
          },
          contactNumber: {
            required,
            minLength: 14
          },
          streetAddress1: {
            required
          },
          city: {
            required
          },
          state: {
            required
          },
          postalCode: {
            required
          },

          // lien agents
          lienAgentName: {
            required: requiredIf(function () {
              return !this.isPlatt() && this.formData[PROPERTY_OWNER_INFORMATION].doesNotHaveNCOnly === '1'
            })
          },
          lienAgentContactNumber: {
            required: requiredIf(function () {
              return !this.isPlatt() && this.formData[PROPERTY_OWNER_INFORMATION].doesNotHaveNCOnly === '1'
            }),
            minLength: 14
          },
          lienAgentStreetAddress1: {
            required: requiredIf(function () {
              return !this.isPlatt() && this.formData[PROPERTY_OWNER_INFORMATION].doesNotHaveNCOnly === '1'
            })
          },
          lienAgentCity: {
            required: requiredIf(function () {
              return !this.isPlatt() && this.formData[PROPERTY_OWNER_INFORMATION].doesNotHaveNCOnly === '1'
            })
          },
          lienAgentPostalCode: {
            required: requiredIf(function () {
              return !this.isPlatt() && this.formData[PROPERTY_OWNER_INFORMATION].doesNotHaveNCOnly === '1'
            })
          }
        },
        [DOCUMENTS]: {
          storageGuid: {
            required: requiredIf(function () {
              const collection = this.formData[DOCUMENTS].selectedDocuments
              if (collection instanceof FormData) {
                return true
              } else {
                return collection != null ? collection.length > 0 : false
              }
            })
          },
          selectedDocuments: {
            required: this.filetypeRule
          }
        },
        ...this.$isStepForTenant(formDefinitions[EMAIL]) && this.isInternal() && {
          [EMAIL]: {
            email: {
              required: requiredIf(function () {
                return this.formData[EMAIL].addEmail === '1'
              }),
              email
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins/_flexbox.scss";
#application-component {
  padding: 1em;
  padding-right: 3em;
}

#accordion {
  padding-inline-start: 0;
}
</style>
