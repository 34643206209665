<template>
  <div>
    <slot
      :attrs="{
        errorMessages: activeErrorMessages,
        success: isValid
      }"
      :hasErrors="hasErrors"></slot>
  </div>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'
export default {
  name: 'FormGroup',
  extends: singleErrorExtractorMixin
}
</script>
