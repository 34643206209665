<template>
  <v-expansion-panels
    v-model="isExpanded"
    class="form-expansion-panel"
    flat
    readonly>
    <v-expansion-panel>
      <v-expansion-panel-content>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'FormExpansionPanel',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isExpanded () {
      return this.value ? 0 : -1
    }
  }
}
</script>

<style lang="scss" scoped>
.form-expansion-panel {
  &__header {
    display: none !important;
  }
}
::v-deep .v-expansion-panel-content__wrap {
  margin: 0;
}
::v-deep .v-expansion-panel-header {
  border: 1px solid red;
  .v-expansion-panel-header__icon {
    display: none;
  }
}
</style>
