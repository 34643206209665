import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  name: 'SubmittedByType',
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
