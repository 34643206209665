<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="upload-modal"
    @close="closeModal()">
    <template slot="header">
      Upload Document
    </template>
    <div slot="body">
      <div class="form-group">
        <label>Select File</label>
        <div
          class="input-group"
          :class="{ 'has-danger': invalidFile }"
          @click="$refs.fileInputBtn.click()">
          <input
            v-model="fileInputTxt"
            type="text"
            class="form-control"
            :class="{'form-control-danger': invalidFile }"
            readonly />
          <span class="input-group-btn">
            <span
              class="btn btn-secondary"
              :class="{'error': invalidFile }">
              <i class="fa fa-folder-open-o"></i> Browse&hellip;
              <input
                ref="fileInputBtn"
                type="file"
                class="hidden"
                accept="'.pdf, .doc, .docx, .html, .jpeg, .jpg, .png, .odt, .wpd, .img, .bmp'"
                @change="handleFileInput($event)" />
            </span>
          </span>
        </div>
      </div>
      <p
        v-if="invalidFile"
        class="error-message">
        {{ errorMessage }}
      </p>
    </div>
    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="modalState = false">
        Cancel
      </a>
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        @click="saveDocument()">
        Upload Document
      </button>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '../../../../components/GenericModal.vue'

export default {
  name: 'UploadModal',
  components: {
    GenericModal
  },
  data: function () {
    return {
      modalState: false,
      fileInputVal: null,
      fileInputTxt: '',
      invalidFile: false,
      errorMessage: 'No error'
    }
  },
  methods: {
    resetForm () {
      this.fileInputTxt = ''
      this.fileInputVal = null
      this.invalidFile = false
    },
    handleFileInput: function (event) {
      const files = event.target.files || event.dataTransfer.files
      const fileName = files[0].name
      const allowedFiletypes = ['pdf', 'doc', 'docx', 'html', 'jpeg', 'jpg', 'png', 'odt', 'wpd', 'img', 'bmp']
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase() || null
      if (allowedFiletypes.indexOf(fileExtension) !== -1 && fileExtension) {
        this.resetForm()
        this.fileInputVal = files
        this.fileInputTxt = fileName
      } else {
        this.errorMessage = fileExtension + ' files are not allowed.'
        this.resetForm()
        this.invalidFile = true
      }
    },
    saveDocument: function () {
      this.$emit('saveDocument_', this.fileInputVal)
      this.resetForm()
    },
    closeModal () {
      this.resetForm()
      this.modalState = false
    }
  }
}
</script>

<style lang="scss">
.btn.error {
  border-color: #c00 !important;
}

.error-message::first-letter {
  text-transform: uppercase
}
</style>
