<template>
  <div>
    <div class="mb-5">
      <h5
        class="section-title">
        Not Visible to Customer
      </h5>
    </div>
    <div
      v-if="businessInfo && businessInfo.accountNumber !== '0'"
      class="component-container my-8">
      <v-card
        v-if="app.bannerId === 'Platt'">
        <v-row class="ml-2">
          <v-col
            cols="3">
            <label>Acquired Account From:</label>
            <p>{{ businessInfo.acquiredAccountFrom || '_' }}</p>
          </v-col>
          <v-col cols="3">
            <label>PIC Code Category:</label>
            <p>{{ getPicCodeCategoryDescByCode(businessInfo.picCodeCategory) }}</p>
          </v-col>
          <v-col cols="3">
            <label>PIC Code:</label>
            <p>{{ getPicCodeDescByCode(businessInfo.picCode) }}</p>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        v-else>
        <v-row class="ml-2">
          <v-col cols="3">
            <label>Customer Name:</label>
            <p>{{ app.businessInfo.internalCompanyName || app.businessInfo.companyName }}</p>
          </v-col>
          <v-col
            v-if="businessInfo.inSideSalesPerson"
            cols="3">
            <label>Inside Salesperson:</label>
            <p>{{ businessInfo.inSideSalesPerson.displayName }}</p>
          </v-col>
          <v-col
            v-if="businessInfo.outSideSalesPerson"
            cols="3">
            <label>Outside Salesperson:</label>
            <p>{{ businessInfo.outSideSalesPerson.displayName }}</p>
          </v-col>
          <v-col
            cols="3">
            <label>Acquired Account From:</label>
            <p>{{ app.businessInfo.acquiredAccountFrom || '_' }}</p>
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col cols="3">
            <label>Customer Segment:</label>
            <p>{{ getCustClassDescByCode(businessInfo.customerTypeCategory) }}</p>
          </v-col>
          <v-col cols="3">
            <label>Customer Pricing Class:</label>
            <p>{{ businessInfo.customerPriceClass }}</p>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div v-else>
      Customer Account Information Not Found
    </div>
  </div>
</template>

<script>

import {
  getCustomerTypeOptions,
  getCustomerTypeOptionsForRexelGexpro,
  getCustomerClassAndPriceClassCrossReferenceForRexelGexpro
} from '@/api/company/customer-type'

import {
  getCustomerAccountInfo
} from '@/api/company/account-info'

export default {
  name: 'NotVisibleToCustomer',
  components: {
  },
  mixins: [
  ],
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      businessInfo: null,
      allRexelCustomerClassPriceClasses: [],
      rexelCustomerSegmentOptions: [],
      customerTypePICCodeCategories: [],
      customerTypePICCodes: [],
      customerTypeOptions: []
    }
  },
  computed: {
    rexelEnergy () {
      return this.company === 'RexelEnergy' || this.$route.params.tenant === 'rexelenergy'
    }
  },
  async mounted () {
    const bannerName = this.$route.params.tenant
    this.tenant = bannerName
    this.bannerId = this.$store.getters.getBannerIdByName(bannerName)
    await this.initializeCustomerTypeLists()
    this.getBusinessInfo()
  },
  methods: {
    async getBusinessInfo () {
      const tenant = this.$route.params.tenant === 'platt' ? 'platt' : 'rexel'
      this.businessInfo = await getCustomerAccountInfo(this.app.businessInfo.accountNumber, tenant)
    },
    getPicCodeCategoryDescByCode (picCodeCategory) {
      return this.customerTypePICCodeCategories.find(c => c.key === picCodeCategory).value
    },
    getPicCodeDescByCode (picCode) {
      return this.customerTypeOptions.find(c => c.key === picCode).value
    },
    getCustClassDescByCode (custClassCode) {
      if (!custClassCode || !this.allRexelCustomerClassPriceClasses) {
        return ''
      } else {
        const custClass = this.allRexelCustomerClassPriceClasses.find(code => code.custClassCode === custClassCode)
        if (custClass && custClass.priceClassDescription) {
          return custClass.priceClassDescription
        }
        return ''
      }
    },
    async initializeCustomerTypeLists () {
      // reset options before populating w/ retrieved data
      this.customerTypePICCodeCategories.length = 0
      this.customerTypePICCodes.length = 0

      const categories = []
      if (this.bannerId === 1) { // platt banner
        this.customerTypeOptions = await getCustomerTypeOptions(this.bannerId)
        const options = this.customerTypeOptions
        options.forEach(o => {
          // for platt data: categories are mixed in w/ pic codes, and will only have one char
          if (o.key.length === 1) {
            categories.push(o)
          }
        })
        this.customerTypePICCodeCategories = [...categories]
      } else {
        // Rexel, Gexpro: Customer Segment Options for New Customer with default custClass(customerType)
        const customerPriceClassRexel = await getCustomerTypeOptionsForRexelGexpro()
        this.rexelCustomerSegmentOptions = customerPriceClassRexel
          .map(option => {
            return {
              key: option.customerType,
              value: option.title
            }
          })

        //  Rexel, Gexpro: Used for looking up existing Customer Account Customer Class and Price Classes
        this.allRexelCustomerClassPriceClasses = await getCustomerClassAndPriceClassCrossReferenceForRexelGexpro()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .inactive-data {
    color: #c0c0c0;
  }

  label,
    p{
      margin-bottom: 0;
    }

    label{
      color: #999;
      font-size: .8em;
    }

    p {
      font-size: .875rem;
      font-weight: 700;
      word-break: break-word;
    }
</style>
