<template>
  <fieldset>
    <FormInputGroupAddressInfo
      v-model="userFormInput"
      :disabled="isStepDisabled"
      :vuelidate="vuelidate"
      :form-labels="formLabels"
      :form-data="formData"
      :field-names="{
        country: 'country',
        streetAddress: 'streetAddress1',
        addressUnitNumber: 'streetAddress2',
        city: 'city',
        state: 'state',
        postalCode: 'zipCode'
      }"></FormInputGroupAddressInfo>

    <form-address-validation
      v-if="!isStepDisabled"
      :form-data="formData"
      :vuelidate="vuelidate"
      :close-modal-from-outside="closeModal"
      @close="closeModal = false"
      @updateFormData="updateAddress"
      @editAddress="editAddress"></form-address-validation>
  </fieldset>
</template>

<script>
import FormInputGroupAddressInfo from '@/components/form/FormInputGroupAddressInfo.vue'
import FormAddressValidation from '@/components/form/FormAddressValidation/index.vue'
import addressValidationMixin from 'mixins/addressValidation.mixin'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'

export default {
  name: 'InstantCreditFormStepMailingAddress',
  components: {
    FormInputGroupAddressInfo,
    FormAddressValidation
  },
  mixins: [
    addressValidationMixin,
    FormStepImplementationMixin
  ],
  data () {
    return {
      closeModal: false
    }
  },
  methods: {
    editAddress () {
      this.closeModal = true
    }
  }
}
</script>
