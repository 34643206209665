<template>
  <v-row class="form-contact-cards">
    <v-col
      v-for="(contact, index) in contacts"
      :key="index"
      cols="6"
      lg="3">
      <v-card
        flat
        tile
        color="grey lighten-3"
        class="form-contact-cards__card">
        <v-card-title class="form-contact-cards__card__title">
          <v-row
            no-gutters
            align="center">
            <v-col cols="9">
              <span v-if="cardTitle.length > 0">
                {{ setCardTitle(contact.id) }}
              </span>
              <span v-else>
                Contact #{{ index+1 }}
              </span>
            </v-col>
            <v-col
              v-if="!disabled"
              cols="3"
              class="form-contact-cards__card__close-button">
              <v-btn

                icon
                @click="removeContact(contact.id)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div
            v-for="(key, k) in contactKeys"
            :key="k">
            {{ contact[key] }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormContactCards',
  props: {
    contacts: {
      type: Array,
      required: true
    },
    cardTitle: {
      type: String,
      required: false,
      default: ''
    },
    mainCardTitle: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    mainCardId: {
      type: [Boolean, Number, String],
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    contactKeys () {
      return this.contacts.length <= 0
        ? []
        : Object.keys(this.contacts[0]).filter(k => k !== 'id')
    }
  },
  methods: {
    setCardTitle (cardId) {
      if (cardId === false || !this.mainCardTitle) return
      return (cardId === this.mainCardId)
        ? this.mainCardTitle
        : this.cardTitle
    },
    removeContact (index) {
      this.$emit('removeContact', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-contact-cards {
  &__card {
    min-height: 10rem;
    height: 100%;
    &__title {
      font-size: 1rem;
      padding: .5rem .2rem .5rem 1rem;
      word-break: normal;
      .col {
        line-height: 1.5rem;
      }
    }
    &__close-button {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
