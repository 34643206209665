export default class ErpReconcile {
  constructor (appModel, isAppPending) {
    if (!appModel) {
      appModel = {}
    }

    if ((typeof appModel.erpReconciliation === 'undefined') ||
          appModel.erpReconciliation == null) {
      appModel.erpReconciliation = {
        useCreditAppBusinessName: false,
        useErpBusinessName: false,
        useCreditAppBusinessEmail: false,
        useErpBusinessEmail: false,
        useCreditAppBusinessPhone: false,
        useErpBusinessPhone: false,
        useCreditAppBillingAddress: false,
        useErpBillingAddress: false,
        useCreditAppShippingAddress: false,
        useErpShippingAddress: false,
        useCreditAppApContactName: false,
        useErpApContactName: false,
        useCreditAppApContactPhone: false,
        useErpApContactPhone: false,
        isComparisonIncomplete: false,
        flagComparisonIncomplete: false,
        firstIncomplete: '',

        checkComparisonIncomplete: function () {
          this.flagComparisonIncomplete = this.isComparisonIncomplete
          return this.isComparisonIncomplete
        }
      }
    }

    return {
      isAppPending,
      reconcile: appModel.erpReconciliation,

      allowBusinessNameSelect: false,
      allowBusinessEmailSelect: false,
      allowBusinessPhoneSelect: false,
      allowBillingAddressSelect: false,
      allowShippingAddressSelect: false,
      allowApContactNameSelect: false,
      allowApContactPhoneSelect: false,

      sentBusinessNameToErp: false,
      sentBusinessEmailToErp: false,
      sentBusinessPhoneToErp: false,
      sentBillingAddressToErp: false,
      sentShippingAddressToErp: false,
      sentApContactNameToErp: false,
      sentApContactPhoneToErp: false,

      get allowAnySelect () {
        return this.allowBusinessNameSelect ||
                    this.allowBusinessEmailSelect ||
                    this.allowBusinessPhoneSelect ||
                    this.allowBillingAddressSelect ||
                    this.allowShippingAddressSelect ||
                    this.allowApContactNameSelect ||
                    this.allowApContactPhoneSelect
      },

      _useAllFromCreditApp: false,
      set useAllFromCreditApp (toggle) {
        this._useAllFromCreditApp = toggle
        if (toggle) {
          this._useAllFromErp = !toggle
        }
        this.setUseCreditAppBusinessName(toggle, false)
        this.setUseCreditAppBusinessEmail(toggle, false)
        this.setUseCreditAppBusinessPhone(toggle, false)
        this.setUseCreditAppBillingAddress(toggle, false)
        this.setUseCreditAppShippingAddress(toggle, false)
        this.setUseCreditAppApContactName(toggle, false)
        this.setUseCreditAppApContactPhone(toggle, false)
      },
      get useAllFromCreditApp () {
        return this._useAllFromCreditApp
      },

      _useAllFromErp: false,
      set useAllFromErp (toggle) {
        this._useAllFromErp = toggle
        if (toggle) {
          this._useAllFromCreditApp = !toggle
        }
        this.setUseErpBusinessName(toggle, false)
        this.setUseErpBusinessEmail(toggle, false)
        this.setUseErpBusinessPhone(toggle, false)
        this.setUseErpBillingAddress(toggle, false)
        this.setUseErpShippingAddress(toggle, false)
        this.setUseErpApContactName(toggle, false)
        this.setUseErpApContactPhone(toggle, false)
      },
      get useAllFromErp () {
        return this._useAllFromErp
      },

      get isIncompleteBusinessName () {
        return this.isAppPending &&
                this.reconcile.isComparisonIncomplete &&
                this.reconcile.flagComparisonIncomplete &&
                this.allowBusinessNameSelect &&
                (!this.reconcile.useCreditAppBusinessName) &&
                (!this.reconcile.useErpBusinessName)
      },

      set useCreditAppBusinessName (toggle) {
        this.setUseCreditAppBusinessName(toggle, true)
      },
      setUseCreditAppBusinessName: function (toggle, checkUseAll) {
        this.reconcile.useCreditAppBusinessName = toggle
        if (toggle) {
          this.reconcile.useErpBusinessName = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllCreditApp(toggle)
        }
        this.checkIncomplete()
      },
      get useCreditAppBusinessName () {
        return this.allowBusinessNameSelect && this.reconcile.useCreditAppBusinessName
      },

      set useErpBusinessName (toggle) {
        this.setUseErpBusinessName(toggle, true)
      },
      setUseErpBusinessName: function (toggle, checkUseAll) {
        this.reconcile.useErpBusinessName = toggle
        if (toggle) {
          this.reconcile.useCreditAppBusinessName = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllErp(toggle)
        }
        this.checkIncomplete()
      },
      get useErpBusinessName () {
        return this.allowBusinessNameSelect && this.reconcile.useErpBusinessName
      },

      get isIncompleteBusinessEmail () {
        return this.isAppPending &&
                this.reconcile.isComparisonIncomplete &&
                this.reconcile.flagComparisonIncomplete &&
                this.allowBusinessEmailSelect &&
                (!this.reconcile.useCreditAppBusinessEmail) &&
                (!this.reconcile.useErpBusinessEmail)
      },

      set useCreditAppBusinessEmail (toggle) {
        this.setUseCreditAppBusinessEmail(toggle, true)
      },
      setUseCreditAppBusinessEmail: function (toggle, checkUseAll) {
        this.reconcile.useCreditAppBusinessEmail = toggle
        if (toggle) {
          this.reconcile.useErpBusinessEmail = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllCreditApp(toggle)
        }
      },
      get useCreditAppBusinessEmail () {
        return this.allowBusinessEmailSelect && this.reconcile.useCreditAppBusinessEmail
      },

      set useErpBusinessEmail (toggle) {
        this.setUseErpBusinessEmail(toggle, true)
      },
      setUseErpBusinessEmail: function (toggle, checkUseAll) {
        this.reconcile.useErpBusinessEmail = toggle
        if (toggle) {
          this.reconcile.useCreditAppBusinessEmail = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllErp(toggle)
        }
        this.checkIncomplete()
      },
      get useErpBusinessEmail () {
        return this.allowBusinessEmailSelect && this.reconcile.useErpBusinessEmail
      },

      get isIncompleteBusinessPhone () {
        return this.isAppPending &&
                this.reconcile.isComparisonIncomplete &&
                this.reconcile.flagComparisonIncomplete &&
                this.allowBusinessPhoneSelect &&
                (!this.reconcile.useCreditAppBusinessPhone) &&
                (!this.reconcile.useErpBusinessPhone)
      },

      set useCreditAppBusinessPhone (toggle) {
        this.setUseCreditAppBusinessPhone(toggle, true)
      },
      setUseCreditAppBusinessPhone: function (toggle, checkUseAll) {
        this.reconcile.useCreditAppBusinessPhone = toggle
        if (toggle) {
          this.reconcile.useErpBusinessPhone = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllCreditApp(toggle)
        }
        this.checkIncomplete()
      },
      get useCreditAppBusinessPhone () {
        return this.allowBusinessPhoneSelect && this.reconcile.useCreditAppBusinessPhone
      },

      set useErpBusinessPhone (toggle) {
        this.setUseErpBusinessPhone(toggle, true)
      },
      setUseErpBusinessPhone: function (toggle, checkUseAll) {
        this.reconcile.useErpBusinessPhone = toggle
        if (toggle) {
          this.reconcile.useCreditAppBusinessPhone = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllErp(toggle)
        }
        this.checkIncomplete()
      },
      get useErpBusinessPhone () {
        return this.allowBusinessPhoneSelect && this.reconcile.useErpBusinessPhone
      },

      get isIncompleteBillingAddress () {
        return this.isAppPending &&
                this.reconcile.isComparisonIncomplete &&
                this.reconcile.flagComparisonIncomplete &&
                this.allowBillingAddressSelect &&
                (!this.reconcile.useCreditAppBillingAddress) &&
                (!this.reconcile.useErpBillingAddress)
      },
      get isIncompleteShippingAddress () {
        return this.isAppPending &&
                this.reconcile.isComparisonIncomplete &&
                this.reconcile.flagComparisonIncomplete &&
                this.allowShippingAddressSelect &&
                (!this.reconcile.useCreditAppShippingAddress) &&
                (!this.reconcile.useErpShippingAddress)
      },

      set useCreditAppShippingAddress (toggle) {
        this.setUseCreditAppShippingAddress(toggle, true)
      },

      set useCreditAppBillingAddress (toggle) {
        this.setUseCreditAppBillingAddress(toggle, true)
      },
      setUseCreditAppBillingAddress: function (toggle, checkUseAll) {
        this.reconcile.useCreditAppBillingAddress = toggle
        if (toggle) {
          this.reconcile.useErpBillingAddress = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllCreditApp(toggle)
        }
        this.checkIncomplete()
      },
      setUseCreditAppShippingAddress: function (toggle, checkUseAll) {
        this.reconcile.useCreditAppShippingAddress = toggle
        if (toggle) {
          this.reconcile.useErpShippingAddress = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllCreditApp(toggle)
        }
        this.checkIncomplete()
      },
      get useCreditAppShippingAddress () {
        return this.allowShippingAddressSelect && this.reconcile.useCreditAppShippingAddress
      },
      get useCreditAppBillingAddress () {
        return this.allowBillingAddressSelect && this.reconcile.useCreditAppBillingAddress
      },
      set useErpShippingAddress (toggle) {
        this.setUseErpShippingAddress(toggle, true)
      },
      set useErpBillingAddress (toggle) {
        this.setUseErpBillingAddress(toggle, true)
      },
      setUseErpBillingAddress: function (toggle, checkUseAll) {
        this.reconcile.useErpBillingAddress = toggle
        if (toggle) {
          this.reconcile.useCreditAppBillingAddress = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllErp(toggle)
        }
        this.checkIncomplete()
      },
      setUseErpShippingAddress: function (toggle, checkUseAll) {
        this.reconcile.useErpShippingAddress = toggle
        if (toggle) {
          this.reconcile.useCreditAppShippingAddress = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllErp(toggle)
        }
        this.checkIncomplete()
      },
      get useErpBillingAddress () {
        return this.allowBillingAddressSelect && this.reconcile.useErpBillingAddress
      },
      get useErpShippingAddress () {
        return this.allowShippingAddressSelect && this.reconcile.useErpShippingAddress
      },
      get isIncompleteApContactName () {
        return this.isAppPending &&
                this.reconcile.isComparisonIncomplete &&
                this.reconcile.flagComparisonIncomplete &&
                this.allowApContactNameSelect &&
                (!this.reconcile.useCreditAppApContactName) &&
                (!this.reconcile.useErpApContactName)
      },

      set useCreditAppApContactName (toggle) {
        this.setUseCreditAppApContactName(toggle, true)
      },
      setUseCreditAppApContactName: function (toggle, checkUseAll) {
        this.reconcile.useCreditAppApContactName = toggle
        if (toggle) {
          this.reconcile.useErpApContactName = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllCreditApp(toggle)
        }
        this.checkIncomplete()
      },
      get useCreditAppApContactName () {
        return this.allowApContactNameSelect && this.reconcile.useCreditAppApContactName
      },

      set useErpApContactName (toggle) {
        this.setUseErpApContactName(toggle, true)
      },
      setUseErpApContactName: function (toggle, checkUseAll) {
        this.reconcile.useErpApContactName = toggle
        if (toggle) {
          this.reconcile.useCreditAppApContactName = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllErp(toggle)
        }
        this.checkIncomplete()
      },
      get useErpApContactName () {
        return this.allowApContactNameSelect && this.reconcile.useErpApContactName
      },

      get isIncompleteApContactPhone () {
        return this.isAppPending &&
                this.reconcile.isComparisonIncomplete &&
                this.reconcile.flagComparisonIncomplete &&
                this.allowApContactPhoneSelect &&
                (!this.reconcile.useCreditAppApContactPhone) &&
                (!this.reconcile.useErpApContactPhone)
      },

      set useCreditAppApContactPhone (toggle) {
        this.setUseCreditAppApContactPhone(toggle, true)
      },
      setUseCreditAppApContactPhone: function (toggle, checkUseAll) {
        this.reconcile.useCreditAppApContactPhone = toggle
        if (toggle) {
          this.reconcile.useErpApContactPhone = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllCreditApp(toggle)
        }
        this.checkIncomplete()
      },
      get useCreditAppApContactPhone () {
        return this.allowApContactPhoneSelect && this.reconcile.useCreditAppApContactPhone
      },

      set useErpApContactPhone (toggle) {
        this.setUseErpApContactPhone(toggle, true)
      },
      setUseErpApContactPhone: function (toggle, checkUseAll) {
        this.reconcile.useErpApContactPhone = toggle
        if (toggle) {
          this.reconcile.useCreditAppApContactPhone = !toggle
        }
        if (checkUseAll) {
          this.checkUseAllErp(toggle)
        }
        this.checkIncomplete()
      },
      get useErpApContactPhone () {
        return this.allowApContactPhoneSelect && this.reconcile.useErpApContactPhone
      },

      checkUseAllCreditApp: function (boxChecked) {
        const shouldBeChecked = this.allowAnySelect &&
                  ((!this.allowBusinessNameSelect) || (this.useCreditAppBusinessName)) &&
                  ((!this.allowBusinessEmailSelect) || (this.useCreditAppBusinessEmail)) &&
                  ((!this.allowBusinessPhoneSelect) || (this.useCreditAppBusinessPhone)) &&
                  ((!this.allowBillingAddressSelect) || (this.useCreditAppBillingAddress)) &&
                  ((!this.allowShippingAddressSelect) || (this.useCreditAppShippingAddress)) &&
                  ((!this.allowApContactNameSelect) || (this.useCreditAppApContactName)) &&
                  ((!this.allowApContactPhoneSelect) || (this.useCreditAppApContactPhone))

        if (this._useAllFromCreditApp !== shouldBeChecked) {
          this._useAllFromCreditApp = shouldBeChecked
        }
        if (boxChecked) {
          this._useAllFromErp = false
        }
      },
      checkUseAllErp: function (boxChecked) {
        const shouldBeChecked = this.allowAnySelect &&
                ((!this.allowBusinessNameSelect) || (this.useErpBusinessName)) &&
                ((!this.allowBusinessEmailSelect) || (this.useErpBusinessEmail)) &&
                ((!this.allowBusinessPhoneSelect) || (this.useErpBusinessPhone)) &&
                ((!this.allowBillingAddressSelect) || (this.useErpBillingAddress)) &&
                ((!this.allowShippingAddressSelect) || (this.useErpShippingAddress)) &&
                ((!this.allowApContactNameSelect) || (this.useErpApContactName)) &&
                ((!this.allowApContactPhoneSelect) || (this.useErpApContactPhone))

        if (this._useAllFromErp !== shouldBeChecked) {
          this._useAllFromErp = shouldBeChecked
        }
        if (boxChecked) {
          this._useAllFromCreditApp = false
        }
      },
      toggleToApproved: function () {
        this.sentBusinessNameToErp = this.reconcile.useCreditAppBusinessName
        this.sentBusinessEmailToErp = this.reconcile.useCreditAppBusinessEmail
        this.sentBusinessPhoneToErp = this.reconcile.useCreditAppBusinessPhone
        this.sentBillingAddressToErp = this.reconcile.useCreditAppBillingAddress
        this.sentShippingAddressToErp = this.reconcile.useCreditAppShippingAddress
        this.sentApContactNameToErp = this.reconcile.useCreditAppApContactName
        this.sentApContactPhoneToErp = this.reconcile.useCreditAppApContactPhone

        this.allowBusinessNameSelect = false
        this.allowBusinessEmailSelect = false
        this.allowBusinessPhoneSelect = false
        this.allowBillingAddressSelect = false
        this.allowShippingAddressSelect = false
        this.allowApContactNameSelect = false
        this.allowApContactPhoneSelect = false

        this.reconcile.isComparisonIncomplete = false
      },
      checkIncomplete: function () {
        let incomplete = false

        if (!incomplete) {
          if ((this.allowBusinessNameSelect) && (!this.useCreditAppBusinessName) && (!this.useErpBusinessName)) {
            incomplete = true
            this.reconcile.firstIncomplete = 'incompleteBusinessName'
          }
        }

        if (!incomplete) {
          if ((this.allowBusinessEmailSelect) && (!this.useCreditAppBusinessEmail) && (!this.useErpBusinessEmail)) {
            incomplete = true
            this.reconcile.firstIncomplete = 'incompleteBusinessEmail'
          }
        }

        if (!incomplete) {
          if ((this.allowBusinessPhoneSelect) && (!this.useCreditAppBusinessPhone) && (!this.useErpBusinessPhone)) {
            incomplete = true
            this.reconcile.firstIncomplete = 'incompleteBusinessPhone'
          }
        }

        if (!incomplete) {
          if ((this.allowBillingAddressSelect) && (!this.useCreditAppBillingAddress) && (!this.useErpBillingAddress)) {
            incomplete = true
            this.reconcile.firstIncomplete = 'incompleteBillingAddress'
          }
        }

        if (!incomplete) {
          if ((this.allowShippingAddressSelect) && (!this.useCreditAppShippingAddress) && (!this.useErpShippingAddress)) {
            incomplete = true
            this.reconcile.firstIncomplete = 'incompleteShippingAddress'
          }
        }

        if (!incomplete) {
          if ((this.allowApContactNameSelect) && (!this.useCreditAppApContactName) && (!this.useErpApContactName)) {
            incomplete = true
            this.reconcile.firstIncomplete = 'incompleteApContactName'
          }
        }

        if (!incomplete) {
          if ((this.allowApContactPhoneSelect) && (!this.useCreditAppApContactPhone) && (!this.useErpApContactPhone)) {
            incomplete = true
            this.reconcile.firstIncomplete = 'incompleteApContactPhone'
          }
        }

        if (!incomplete) {
          this.reconcile.firstIncomplete = ''
        }

        this.reconcile.isComparisonIncomplete = incomplete
      }
    }
  }
}
