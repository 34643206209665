import { UserManager } from 'oidc-client'
import axios from 'ca-http-service'
import store from '@/store'

import {
  REDIRECT_URI,
  SILENT_REDIRECT_URI,
  OIDC_DEFAULT_SETTINGS,
  OIDC_DEFAULT_SESSION_SETTINGS
} from './auth-settings'

/** Global singleton */
let _manager = null
let _settings = null
let _callbackSettings = null

class Auth {
  constructor (settings = {}) {
    _settings = Object.assign(
      {},
      OIDC_DEFAULT_SETTINGS,
      OIDC_DEFAULT_SESSION_SETTINGS,
      settings
    )
    _callbackSettings = Object.assign({}, OIDC_DEFAULT_SETTINGS, settings)
  }

  get manager () {
    return _manager
  }

  get settings () {
    return _settings
  }

  get authStateJSON () {
    const { pathname, search, hash } = window.location
    return JSON.stringify({ path: pathname + search + hash })
  }

  get isSilentRenew () {
    return RegExp(SILENT_REDIRECT_URI).test(window.location.href)
  }

  get isAuthCallback () {
    return RegExp(REDIRECT_URI).test(window.location.href)
  }

  /**
   * Initializes the system and takes a callback to run after authentication is successful
   * @param {Func} callback
   */
  async init (callback = () => {}) {
    if (_manager) {
      const user = await _manager.getUser()
      callback(user)
    }
    if (this.isSilentRenew) {
      // silent renew, we're loading in an iframe
      const tempManager = new UserManager(_callbackSettings)
      tempManager.signinSilentCallback()
    } else if (this.isAuthCallback) {
      // auth callback, need to process tokens
      const tempManager = new UserManager(_callbackSettings)
      let state = { path: '/admin' }
      try {
        const res = await tempManager.signinRedirectCallback()
        // replace path history
        state = JSON.parse(res.state)
        state.path = state.path || '/admin'
        window.location.assign(state.path)
      } catch (err) {
        console.warn('Error processing signin redirect', err)
        window.sessionStorage.clear()
        window.localStorage.clear()
        tempManager.signinRedirect({ state: JSON.stringify(state) })
      }
    } else {
      // not handling a sign-in situation
      _manager = new UserManager(_settings)
      try {
        const user = await _manager.getUser()
        if (!user || user.expired) {
          _manager.signinRedirect({ state: this.authStateJSON })
        } else {
          callback(user)
        }
      } catch (err) {
        console.error('Auth Failure\n', err)
        _manager.signinRedirect({ state: this.authStateJSON })
      }
    }
  }

  async status (callback = () => {}) {
    _manager = new UserManager(_settings)
    try {
      const user = await _manager.getUser()
      if (!user || user.expired) {
        callback(null)
      } else {
        callback(user)
      }
    } catch (err) {
      console.error('Auth Failure\n', err)
    }
  }
}

// Auth stuff

const initVueUser = async (data) => {
  try {
    const accessToken = data.access_token

    store.dispatch('setAccessToken', accessToken)
    // Set bearer token
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

    // Set user data
    Promise.all([
      store.dispatch('setIdentity', data.profile)
    ])
  } catch (err) {
    console.error(err)
  }
}

export let manager

export const initAuth = async () => {
  try {
    const appSettings = await axios.get('/generic/config')
    const auth = new Auth({
      authority: appSettings.data.identityAuthorityUrl
    })

    await auth.init(async (u) => {
      await initVueUser(u)
      auth.manager.events.addUserLoaded(u => initVueUser(u)) // <<< fired during user reload (~15 minutes)
      auth.manager.events.addUserSignedOut(data => {
        auth.manager.stopSilentRenew()
        auth.manager.removeUser()
        auth.manager.clearStaleState()
        auth.manager.signinRedirect({ state: JSON.stringify({ path: window.location.pathname + window.location.hash }) })
      })

      manager = auth.manager
    })
  } catch (err) {
    console.error(err)
  }
}
