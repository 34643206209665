<template>
  <fieldset>
    <div class="row">
      <div class="col-md-6">
        <!-- NOTE: This field will need a new component that does some calls/validations w/ BE -->
        <FormInputTextInput
          v-model="userFormInput.companyName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.companyName"
          :input-label="formLabels.companyName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-md-6">
        <FormInputTextInput
          v-model="userFormInput.phoneNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.phoneNumber"
          :input-label="formLabels.phoneNumber"
          input-type="tel"
          placeholder="(xxx) xxx-xxxx"></FormInputTextInput>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.firstName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.firstName"
          :input-label="formLabels.firstName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.lastName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.lastName"
          :input-label="formLabels.lastName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-md-4">
        <FormInputTextInput
          v-model="userFormInput.emailAddress"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.emailAddress"
          :input-label="formLabels.emailAddress"
          input-type="email"></FormInputTextInput>
      </div>
    </div>

    <FormInputGroupAddressInfo
      v-model="userFormInput"
      :disabled="isStepDisabled"
      :vuelidate="vuelidate"
      :form-labels="formLabels"
      :form-data="formData"
      :field-names="{
        streetAddress: 'streetAddress1',
        addressUnitNumber: 'streetAddress2',
        city: 'city',
        state: 'state',
        postalCode: 'postalCode'
      }"></FormInputGroupAddressInfo>
    <form-address-validation
      v-if="!isStepDisabled"
      :form-data="formData"
      :vuelidate="vuelidate"
      :close-modal-from-outside="closeModal"
      @close="closeModal = false"
      @updateFormData="updateAddress"
      @editAddress="editAddress"></form-address-validation>
    <branch-selector
      v-if="!isInternal() && $route.params.tenant !== 'rexelenergy'"
      class="mt-3"
      :is-required="!isInternal()"
      @select="setBranch">
    </branch-selector>
    <div
      v-if="$route.params.tenant != 'platt'"
      class="user-note">
      If you are looking to set up an International Cash Account, please email
      <a href="mailto:export.team@gexpro.com">
        export.team@gexpro.com
      </a>
    </div>
  </fieldset>
</template>

<script>

import { mapGetters } from 'vuex'
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputGroupAddressInfo from '@/components/form/FormInputGroupAddressInfo.vue'
import FormAddressValidation from '@/components/form/FormAddressValidation/index.vue'
import addressValidationMixin from 'mixins/addressValidation.mixin'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import BranchSelector from '@/components/BranchSelector.vue'

export default {
  name: 'CashAccountStep1CompanyInformation',
  components: {
    FormInputGroupAddressInfo,
    FormInputTextInput,
    FormAddressValidation,
    BranchSelector
  },
  mixins: [
    addressValidationMixin,
    FormStepImplementationMixin
  ],
  data () {
    return {
      closeModal: false
    }
  },
  computed: {
    ...mapGetters([
      'isProductionEnvironment'
    ])
  },
  methods: {
    editAddress () {
      this.closeModal = true
    },
    isInternal () {
      return this.$route.meta.auth === true
    },
    setBranch (branchNumber) {
      // this.$emit('selectBranch', branchNumber ? branchNumber : '')
      this.userFormInput.branchNumber = branchNumber
    }
  }
}
</script>

<style lang="scss" scoped>
.user-note {
  color: #4285f4;
  font-size: 14px;
  margin: .7rem 0;
}
</style>
