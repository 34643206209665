<template>
  <div
    id="app-details-component"
    class="component-container">
    <template v-if="application.creditAppId">
      <section class="page-header">
        <div class="row">
          <div class="col">
            <h4>
              <router-link
                :to="{name: 'creditDetails', params: { tenant: tenant, id: application.creditAppKey } }"
                class="go-back-arrow mr-3">
                <i class="fa fa-arrow-left"></i>
              </router-link>
              {{ application.company.companyName }}
            </h4>
          </div>
        </div>
      </section>
      <ul class="nav nav-tabs">
        <li
          v-for="item in steps"
          :key="item.stepNumber"
          class="nav-item">
          <a
            class="nav-link"
            :class="{'active': step === item.stepNumber}"
            href="#"
            @click.prevent="changeStep(item.stepNumber)">
            {{ item.stepTitle }}
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <company-information
          v-if="step === 1"
          :credit-app="application"></company-information>
        <contact-information
          v-if="step === 2"
          :credit-app="application"
          @downloadDoc_="downloadDoc"></contact-information>
        <third-party-information
          v-if="step === 3"
          :credit-app="application"></third-party-information>
        <signatory-information
          v-if="step === 4"
          :credit-app="application"></signatory-information>
        <map-information
          v-if="step === 5"
          :credit-app="application"></map-information>
      </div>
    </template>
  </div>
</template>
<script>
import axios from 'ca-http-service'
import CompanyInformation from './components/admin-application-details/CompanyInformation.vue'
import ContactInformation from './components/admin-application-details/ContactInformation.vue'
import ThirdPartyInformation from './components/admin-application-details/ThirdPartyInformation.vue'
import SignatoryInformation from './components/admin-application-details/SignatoryInformation.vue'
import MapInformation from './components/admin-application-details/MapInformation.vue'
import BannerMixin from 'mixins/banner.mixin'

export default {
  name: 'CreditApplicationDetails',
  components: {
    'company-information': CompanyInformation,
    'contact-information': ContactInformation,
    'third-party-information': ThirdPartyInformation,
    'signatory-information': SignatoryInformation,
    'map-information': MapInformation
  },
  mixins: [BannerMixin],
  beforeRouteEnter (to, from, next) {
    const applicationId = to.params.id
    const tenant = to.params.tenant
    axios
      .get('/api/CreditReview/GetFullApplication/' + tenant + '/' + applicationId)
      .then(res => {
        next(vm => {
          if (res.status === 200) {
            vm.tenant = tenant
            vm.application = res.data.data
          } else {
            alert('error')
            vm.goBack()
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  },
  props: {},
  data () {
    return {
      application: {},
      step: 1,
      steps: [
        { stepTitle: 'Company Information', stepNumber: 1 },
        { stepTitle: 'Contact Information', stepNumber: 2 },
        { stepTitle: '3rd Party Information', stepNumber: 3 },
        { stepTitle: 'Signatory', stepNumber: 4 },
        { stepTitle: 'Maps', stepNumber: 5 }
      ],
      tenant: null
    }
  },
  watch: {
    selectedBanner () { // comes from banner mixin
      this.goBackToCreditReview()
    }
  },
  methods: {
    goBack () {
      const appId = this.application.creditAppId
      this.$router.push({
        path: 'creditApplication',
        params: { appId }
      })
    },
    goBackToCreditReview () {
      this.$router.push({ name: 'creditReview' })
    },
    changeStep (step) {
      this.step = step
    },
    async downloadDoc (fullPath, useThisFileNameInstead = null) {
      axios({
        url: `/api/CreditReview/Download?fullPath=${fullPath}&creditAppId=${this.application.creditAppKey}`,
        method: 'GET',
        responseType: 'blob'
      }).then((res) => {
        const bytes = res.data
        const file = new Blob([bytes], { type: res.headers['content-type'] })
        const fileURL = window.URL.createObjectURL(file)
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        const [, contentDisposition] = res.headers['content-disposition'].split('filename=')
        if (useThisFileNameInstead) {
          fileLink.download = useThisFileNameInstead
        } else {
          const returnedFileName = contentDisposition.substring(0, contentDisposition.indexOf(';'))
          fileLink.download = returnedFileName
        }
        document.body.appendChild(fileLink)
        fileLink.click()
      })
        .catch((err) => {
          console.error(err)
          this.loading = false
          palert({ type: 'error', title: 'Unable to download Document' })
        })
    }
  }
}

</script>
<style lang="scss">
#app-details-component {
  margin-bottom: 1rem;
  .tab-content {
    position: relative;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-top: none;
  }
  .page-header {
    margin-bottom: 2rem;
  }
  .nav-tabs {
    .nav-link {
      position: relative;
      top: 1px;
      color: #777;
      border: 1px solid #ddd;
      border-left: none;
      transition: all 0.2s ease;
      &:hover {
        color: #000;
        background: #eee;
      }
      &.active {
        color: #000;
        background: #fff;
        border-bottom-color: #fff;
        border-top: 2px solid #004168;
      }
    }
    .nav-item {
      background: #f7f7f9;
      border-color: #ccc;
      &:first-child {
        .nav-link {
          border-left: 1px solid #ddd;
        }
      }
    }
  }
  .card-header {
    border: 1px solid #e2e2e2;
    border-bottom: 0;
  }
  .card {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    label,
    p {
      margin-bottom: 0.25rem;
    }
    label {
      color: #999;
      font-size: 0.8em;
    }
    p {
      font-size: 0.875rem;
      font-weight: 700;
      word-break: break-word;
    }
    h5 {
      font-weight: bold;
    }
    .row {
      flex-basis: 100%;
    }
    .documents {
      border-bottom: 1px solid #e2e2e2;
      margin-left: 0px;
      &:last-of-type {
        border: none;
      }
    }
    &-block {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem;
      position: relative;
      flex-basis: 100%;
      .document {
        font-weight: normal !important;
        &.download-link {
          text-align: right;
        }
      }
      &.sub-headings {
        padding-bottom: 0;
        .form-group {
          padding-bottom: 0;
          .sub-heading {
            margin-bottom: 0;
            padding-bottom: 5px;
            border-bottom: 1px solid #dcdcdc;
          }
        }
        & + .card-block {
          padding-top: 0;
        }
      }
      &.no-flex {
         display: block !important;
         .form-group {
           width: unset;
           float: left;
         }
      }
      .form-group {
        padding: 0.5rem;
        width: 100%;
        margin-bottom: 0;
        .card-block {
          padding: 0;
          .form-group {
            padding: 0;
          }
        }
        @media (min-width: 414px) {
          width: 50%;
        }
      }
      .custom-control {
        &-description {
          color: black;
          font-weight: bold;
          line-height: 1.5rem;
        }
      }
      &.cols {
        &-one {
          .form-group {
            @media (min-width: 768px) {
              width: 100%;
            }
          }
        }
        &-two {
          .form-group {
            @media (min-width: 768px) {
              width: 50%;
            }
          }
        }
        &-three {
          .form-group {
            @media (min-width: 768px) {
              width: 33.3333%;
            }
          }
        }
        &-four {
          .form-group {
            @media (min-width: 768px) {
              width: 25%;
            }
          }
        }
        &-five {
          .form-group {
            @media (min-width: 768px) {
              width: 20%;
            }
          }
        }
        &-six {
          .form-group {
            @media (min-width: 768px) {
              width: 16.6667%;
            }
          }
        }
        &-one-third-two-third {
          > .form-group:nth-of-type(1) {
            @media (min-width: 768px) {
              width: 33.3333%;
            }
          }
          > .form-group:nth-of-type(2) {
            @media (min-width: 768px) {
              width: 66.6667%;
            }
          }
          > .form-group {
            @media (min-width: 768px) {
              > .form-group {
                width: 100%;
                padding: 0;
              }
            }
          }
        }
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  [class*='col-md-'] > .form-group {
    width: 100%;
  }
}

</style>
