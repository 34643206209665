<template>
  <!-- NOT CURRENTLY IN USE - THIS ENDED UP ON PLATT.COM - NOT SURE IF THIS WILL STILL BE USED -->
  <div>
    <!-- <h2>Form Data</h2> <pre>{{formData}}</pre> -->

    <!-- <h2>Vuelidate Object</h2> <pre>{{$v.formData}}</pre> -->

    <h4 style="font-weight: 900; margin-bottom: 1.6em;">
      Personal Information Request
    </h4>
    <div
      id="application-component"
      style="padding: 2em 2.4em 1em 2.4em;"
      class="card component-container personal-information-request-form">
      <h6 style="font-weight: 900;">
        Privacy Form
      </h6>
      <div v-if="!isSubmitted">
        <div style="margin-top: 1em; margin-bottom: .4em; font-size: 14px;">
          Please select one:
        </div>
        <div class="align-to-form">
          <div class="form-check">
            <input
              id="discloseData"
              v-model="formData.privacySelection"
              class="form-check-input"
              type="radio"
              name="selectPrivacyOption"
              value="disclose"
              checked />
            <label
              class="form-check-label"
              for="discloseData">
              Would you like us to disclose to you your personal information that we have on file?
            </label>
          </div>
        </div>

        <div class="align-to-form">
          <div class="form-check">
            <input
              id="deleteData"
              v-model="formData.privacySelection"
              class="form-check-input"
              type="radio"
              name="selectPrivacyOption"
              value="delete" />
            <label
              class="form-check-label"
              for="deleteData">
              Would you like us to delete your personal information that we have on file?
            </label>
          </div>
        </div>

        <div
          style="margin-top: 1em;"
          class="row">
          <div
            class="form-input-text col-md-4"
            style="
            display: grid;
            margin-bottom: .6em;
          "
            :class="{'has-danger': $v.$error}">
            <label
              class="form-control-label"
              :class="$v.formData.firstName.required != null ? 'required' : ''"
              style="font-size: 12px;">
              First Name:
            </label>
            <input
              v-model="formData.firstName"
              type="text"
              :class="{'has-danger': $v.$error}"
              @input="updateValue($event.target.value)" />
          </div>

          <div
            class="form-input-text col-md-4"
            style="
            display: grid;
            margin-bottom: .6em;
          "
            :class="{'has-danger': $v.$error}">
            <label
              class="form-control-label"
              :class="$v.formData.lastName.required != null ? 'required' : ''"
              style="font-size: 12px;">
              Last Name:
            </label>
            <input
              v-model="formData.lastName"
              type="text"
              :class="{'has-danger': $v.$error}"
              @input="updateValue($event.target.value)" />
          </div>
        </div>

        <div
          style="margin-top: .4em;"
          class="row">
          <div
            class="form-input-text col-md-4"
            style="
            display: grid;
            margin-bottom: .6em;
          "
            :class="{'has-danger': $v.$error}">
            <label
              class="form-control-label"
              :class="$v.formData.emailAddress.required != null ? 'required' : ''"
              style="font-size: 12px;">
              Email Address:
            </label>
            <input
              v-model="formData.emailAddress"
              type="email"
              :class="{'has-danger': $v.$error}"
              @input="updateValue($event.target.value)" />
          </div>

          <div
            class="form-input-text col-md-4"
            style="
            display: grid;
            margin-bottom: .6em;
          "
            :class="{'has-danger': $v.$error}">
            <label
              class="form-control-label"
              :class="$v.formData.phoneNumber.required != null ? 'required' : ''"
              style="font-size: 12px;">
              Phone Number:
            </label>
            <input
              v-model="formData.phoneNumber"
              v-mask="'(###) ###-####'"
              type="tel"
              :class="{'has-danger': $v.$error}"
              @input="updateValue($event.target.value)" />
          </div>
        </div>

        <button
          :disabled="$v.$invalid"
          class="btn nav-button text-uppercase"
          @click="onSubmit()">
          Submit
        </button>
      </div>
      <div v-else>
        <div class="gray-box">
          We will review your request and respond to you soon. Thank you.
        </div>

        <button
          class="btn nav-button text-uppercase"
          @click="onReturnToCompanySite()">
          Return to {{ companyName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import TenantsMixin from 'mixins/tenants.mixin'
import { mapGetters } from 'vuex'
import { required, email, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'PersonalInformationRequestForm',
  components: {
  },
  mixins: [TenantsMixin],
  data () {
    return {
      companyName: 'Platt',
      isSubmitted: false,
      formData: {
        privacySelection: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company'
    })
  },
  async mounted () {
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
    onSubmit () {
      this.isSubmitted = true // just to test FE
    },
    onReturnToCompanySite () {
      this.isSubmitted = false // just to test FE
    }
  },
  validations () {
    return {
      formData: {
        privacySelection: {
          required
        },
        firstName: {
          required
        },
        lastName: {
          required
        },
        emailAddress: {
          email,
          required
        },
        phoneNumber: {
          minLength: minLength(14),
          required
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input-checkbox .form-check-label {
  left: -0.3em;
}

.form-check-label {
  padding-left: 0.2em !important;
  font-size: 14px;
}

.align-to-form {
  margin-left: 1.4em;
}

/* Could DRY up the nav-button by making separate component, reused in FormStepper */
.nav-button {
  background-color: #099c98;
  font-size: 0.64rem;
  border: 0;
  border-radius: 0.125rem;
  margin: 1.2em 0 2.6em 0;
  padding: 0.5rem 1.6rem;
  color: white;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  width: 18em;
}

.nav-button:hover:enabled {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.gray-box {
  // DRY ME, could maybe be a component? repeated
  font-size: 14px;
  width: 100%;
  padding: 1em;
  margin: 1.2em 0;
  background-color: #eeeeee;
}
</style>
