<template>
  <fieldset>
    <div class="row">
      <div class="col-md-4">
        <FormInputCombobox
          v-model="userFormInput.branch"
          :disabled="isStepDisabled"
          :input-label="formLabels.branch"
          :options="branchesList"
          :vuelidate="vuelidate.branch"></FormInputCombobox>

        <FormInputCombobox
          v-model="userFormInput.approver"
          :disabled="isStepDisabled"
          :input-label="formLabels.approver"
          :options="approverList"
          :vuelidate="vuelidate.approver"></FormInputCombobox>
      </div>

      <div class="col-md-8">
        <FormInputDropdownCustomerType
          v-model="userFormInput"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate"
          :form-labels="formLabels"
          :form-data="formData"
          :form-state="formState"></FormInputDropdownCustomerType>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h6>Salespeople</h6>
      </div>
    </div>
    <hr />
    <div
      v-if="isInternalUser() && shouldShowSalesPersonInputs(tenantId)"
      class="row ml-0">
      <span class="mr-2">
        At least one is required
      </span> <span style="color:red;">
        *
      </span>
    </div>
    <div
      v-if="isInternalUser() && shouldShowSalesPersonInputs(tenantId)"
      class="row">
      <div class="col-md-4">
        <FormInputCombobox
          v-model="userFormInput.insideSalesPerson"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.insideSalesPerson"
          :input-label="formLabels.insideSalesPerson"
          :async-search-handler="getSalesPersons"
          :items-mapper="mapSalesPersonsResultsToComboboxOptions"
          :hide-required="true"></FormInputCombobox>
      </div>
      <div class="col-md-4">
        <FormInputCombobox
          v-model="userFormInput.outsideSalesPerson"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.outsideSalesPerson"
          :input-label="formLabels.outsideSalesPerson"
          :async-search-handler="getSalesPersons"
          :items-mapper="mapSalesPersonsResultsToComboboxOptions"
          :hide-required="true"></FormInputCombobox>
      </div>
    </div>
    <!-- isTaxExempt field COPIED from JobAccountFormStepProjectInformation, could make a component -->
    <div class="row">
      <div class="col-md-4">
        <FormInputRadioOptions
          v-model="userFormInput.isTaxExempt"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.isTaxExempt"
          :input-label="formLabels.isTaxExempt"
          default-value="0"
          :options="[
            {
              key: 'Yes',
              value: '1'
            },
            {
              key: 'No',
              value: '0'
            }
          ]"></FormInputRadioOptions>
      </div>
      <hr
        v-if="userFormInput.isTaxExempt === '1'"
        style="width: 100%; margin-left: 15px;" />
      <div
        v-if="userFormInput.isTaxExempt === '1'"
        class="col-md-12"
        style="margin-bottom: 1em;">
        Upload Tax Documents
      </div>
      <div
        v-if="userFormInput.isTaxExempt === '1'"
        class="col-sm-12">
        <FormFileUploaderV2
          v-model="userFormInput.selectedDocuments"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.selectedDocuments"
          return-file-data></FormFileUploaderV2>
      </div>
    </div>

    <v-checkbox
      v-model="userFormInput.optOutElectronicDelivery"
      :disabled="isStepDisabled"
      color="#004168"
      class="opt-out-electronic-delivery-checkbox">
      <span
        slot="label"
        class="checkbox-label">
        {{ formLabels.optOutElectronicDelivery }}
      </span>
    </v-checkbox>
  </fieldset>
</template>

<style scoped>
  .checkbox-label {
    margin-top:10px;
  }
</style>

<script>
import FormInputDropdownCustomerType from '@/components/form/FormInputDropdownCustomerType.vue'
import FormInputRadioOptions from '@/components/form/FormInputRadioOptions.vue'
import FormFileUploaderV2 from '@/components/form/FormFileUploaderV2.vue'
import FormInputCombobox from '@/components/form/FormInputCombobox.vue'
import { getBranchesAsync } from '@/api/company/branch-info'
import {
  getSalesPersonsAsync,
  mapSalesPersonsResultsToComboboxOptions,
  shouldShowSalesPersonInputs
} from '@/api/company/sales-person-search'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import axios from 'ca-http-service'

export default {
  name: 'InstantCreditFormStepSalesInformation',
  components: {
    FormInputCombobox,
    FormInputDropdownCustomerType,
    FormInputRadioOptions,
    FormFileUploaderV2
  },
  mixins: [
    FormStepImplementationMixin
  ],
  data () {
    return {
      mapSalesPersonsResultsToComboboxOptions,
      shouldShowSalesPersonInputs,
      branchesList: [],
      tenant: '',
      tenantId: '',
      isLoading: false,
      approverList: []
    }
  },
  watch: {
    'userFormInput.branch': {
      handler (newVal) {
        if (newVal && newVal.text) {
          const branchNumber = newVal.value
          this.userFormInput.branchNumber = branchNumber
          this.getApprovedUsers(branchNumber)
        }
      }
    },
    'userFormInput.approver': {
      handler (newVal) {
        if (newVal && newVal.text) {
          this.userFormInput.branchManagerSSO = newVal.value
        }
      }
    }
  },
  async mounted () {
    this.tenant = this.$route.params.tenant
    this.tenantId = this.$store.getters.getBannerIdByName(this.$route.params.tenant)

    await this.initializeBranchesList()
  },
  methods: {
    async getSalesPersons (value) {
      return await getSalesPersonsAsync(value, this.tenant)
    },
    async initializeBranchesList () {
      const branches = await this.getBranches()

      if (branches == null) {
        return null
      }

      this.branchesList.length = 0

      branches.forEach(branch => {
        this.branchesList.push({
          text: `${branch.branchNumber} - ${branch.name}` + (branch.branchManager === null ? ' (No Branch Manager)' : ''),
          value: branch.branchNumber,
          disabled: false // branch.branchManager === null
        })
      })
    },
    async getApprovedUsers (branchNum) {
      this.approverList.splice(0)
      // this.userFormInput.approver = null

      const obj = {
        branchId: branchNum
      }

      if (obj.branchId) {
        const userData = await axios
          .get('/api/instantcreditpermissions/getApprovedUsersByBranch', { params: obj })
          .then((res) => {
            if (res.status === 200) {
              return res.data
            } else {
              return null
            }
          })
          .catch((err) => {
            console.error(err)
          })

        if (userData != null) {
          userData.forEach(user => {
            this.approverList.push({
              text: user.fullName + (user.isBranchManager ? ' (Branch Manager)' : ''),
              value: user.empSSO,
              disabled: false
            })
          })

          if (this.formData.branchManagerSSO != null) {
            this.approverList.forEach(approver => {
              if (approver.value === this.formData.branchManagerSSO) {
                this.userFormInput.approver = approver
              }
            })
          }
        }
      }
    },
    async getBranches () {
      return await getBranchesAsync(this.tenantId)
    },
    isInternalUser () {
      // NOTE: This is currently checking hardcoded data (meta.auth), which is not actually telling us whether the User is an authenticated Internal non-Admin User
      return this.$route.meta.auth === true
    }
  }
}
</script>
