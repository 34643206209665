<template>
  <div>
    <!-- Section: Business Information -->
    <h6 class="card-header">
      Business Information
    </h6>
    <div class="card">
      <div class="card-block cols-five">
        <div class="form-group">
          <label>Name of Company:</label>
          <p>{{ company.companyName }}</p>
        </div>
        <div class="form-group">
          <label>Subsidiary/Division of:</label>
          <p>{{ company.subsidiary }}</p>
        </div>
        <div class="form-group">
          <label>Company Phone Number:</label>
          <p>{{ company.phone }}</p>
        </div>
        <div class="form-group">
          <label>Your Email Address:</label>
          <p>{{ company.email }}</p>
        </div>
        <div class="form-group">
          <label>Company Fax Number:</label>
          <p>{{ company.fax }}</p>
        </div>
      </div>
    </div>

    <!-- Section: Billing Address -->
    <h6 class="card-header">
      Billing Address
    </h6>
    <div class="card">
      <div class="card-block cols-six">
        <div class="form-group">
          <label>Country:</label>
          <p>{{ company.countryCode }}</p>
        </div>
        <div class="form-group">
          <label>Billing Address:</label>
          <p>{{ company.street }}</p>
        </div>
        <div class="form-group">
          <label>Unit:</label>
          <p>{{ company.unit }}</p>
        </div>
        <div class="form-group">
          <label>City:</label>
          <p>{{ company.city }}</p>
        </div>
        <div class="form-group">
          <label>State:</label>
          <p>{{ company.state }}</p>
        </div>
        <div class="form-group">
          <label>Postal Code:</label>
          <p>{{ company.zip }}</p>
        </div>
      </div>
    </div>

    <!-- Section: Shipping Address -->
    <template v-if="company.mailingIsDifferent">
      <h6 class="card-header">
        Shipping Address
      </h6>
      <div class="card">
        <div class="card-block cols-six">
          <div class="form-group">
            <label>Country:</label>
            <p>{{ company.mailingCountryCode }}</p>
          </div>
          <div class="form-group">
            <label>Billing Address:</label>
            <p>{{ company.mailingStreet }}</p>
          </div>
          <div class="form-group">
            <label>Unit:</label>
            <p>{{ company.mailingUnit }}</p>
          </div>
          <div class="form-group">
            <label>City:</label>
            <p>{{ company.mailingCity }}</p>
          </div>
          <div class="form-group">
            <label>State:</label>
            <p>{{ company.mailingState }}</p>
          </div>
          <div class="form-group">
            <label>Postal Code:</label>
            <p>{{ company.mailingZip }}</p>
          </div>
        </div>
      </div>
      <!-- card -->
    </template>

    <!-- Section: Business Type -->
    <h6 class="card-header">
      Business Type
    </h6>
    <div class="card">
      <div class="card-block col-md-12 cols-six">
        <div class="form-group">
          <label class="custom-control custom-radio">
            <input
              type="radio"
              name="businessTypeId"
              :value="1"
              :checked="company.businessTypeId === 1"
              class="custom-control-input" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Proprietorship
            </span>
          </label>
        </div>
        <div class="form-group">
          <label class="custom-control custom-radio">
            <input
              type="radio"
              name="businessTypeId"
              :value="2"
              :checked="company.businessTypeId === 2"
              class="custom-control-input" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Partnership
            </span>
          </label>
        </div>
        <div class="form-group">
          <label class="custom-control custom-radio">
            <input
              type="radio"
              name="businessTypeId"
              :value="3"
              :checked="company.businessTypeId === 3"
              class="custom-control-input" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Corporation
            </span>
          </label>
        </div>
        <div class="form-group">
          <label class="custom-control custom-radio">
            <input
              type="radio"
              name="businessTypeId"
              :value="4"
              :checked="company.businessTypeId === 4"
              class="custom-control-input" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Government
            </span>
          </label>
        </div>
        <div class="form-group">
          <label class="custom-control custom-radio">
            <input
              type="radio"
              name="businessTypeId"
              :value="5"
              :checked="company.businessTypeId === 5"
              class="custom-control-input" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Other
            </span>
          </label>
        </div>
        <div
          v-if="company.businessTypeId === 5"
          class="form-group">
          <p>{{ company.businessTypeLabel }}</p>
        </div>
      </div>
    </div>
    <!-- card -->

    <h6 class="card-header">
      Additional Information
    </h6>
    <div class="card">
      <div class="card-block cols-three">
        <div class="form-group">
          <label>Account Number:</label>
          <p>{{ company.customerAccountId }}</p>
        </div>
        <div class="form-group">
          <label>Contractor License Number:</label>
          <p>{{ company.contractorLicense }}</p>
        </div>
        <div class="form-group">
          <label>DUNS Number:</label>
          <p>{{ company.dunsNumber }}</p>
        </div>
        <div class="form-group">
          <label>Estimated Monthly Income:</label>
          <p>{{ company.estMonthlyIncome }}</p>
        </div>
        <div class="form-group">
          <label>Anticipated Monthly Purchases:</label>
          <p>{{ company.estMonthlyPurchases }}</p>
        </div>
        <div class="form-group">
          <label>Incorporated Country:</label>
          <p>{{ company.incorporatedCountryCode }}</p>
        </div>
        <div class="form-group">
          <label>Incorporated State:</label>
          <p>{{ company.incorporatedState }}</p>
        </div>
        <div class="form-group">
          <label>Year Business Was Established:</label>
          <p>{{ company.businessEstablished }}</p>
        </div>
        <div class="form-group">
          <label>Business Industry:</label>
          <p>{{ company.businessIndustry }}</p>
        </div>
        <div class="form-group">
          <label>Federal Tax ID:</label>
          <p>{{ company.taxId }}</p>
        </div>
      </div>
    </div>
    <!-- card -->
  </div>
</template>
<script>
export default {
  name: 'CompanyInformation',
  props: {
    creditApp: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      company: {}
    }
  },
  created () {
    this.company = this.$nullToDefault(this.creditApp.company)
  }
}
</script>

<style lang="scss" scoped>
  label, .custom-control-input {
    pointer-events: none
  }
</style>
