<template>
  <span v-if="data.tax_Exempt">
    <i class="fa fa-check"></i>
  </span>
</template>
<script type="text/javascript">
const taxExemptTagComponent = {
  props: ['data'],
  name: 'tax_Exempt'
}
export default taxExemptTagComponent
</script>
