<template>
  <fieldset>
    <div class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.firstName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.firstName"
          :input-label="formLabels.firstName"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.lastName"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.lastName"
          :input-label="formLabels.lastName"
          input-type="text"></FormInputTextInput>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <FormInputDropdownContactTitles
            v-if="isRexelOrGexpro()"
            v-model="userFormInput.jobTitle"
            default-value="Accounting Personnel"
            :disabled="isStepDisabled"
            :vuelidate="vuelidate.jobTitle"
            :input-label="formLabels.jobTitle"
            :form-labels="formLabels"
            :form-data="formData"
            :field-names="{
              jobTitle: 'jobTitle'
            }"></FormInputDropdownContactTitles>
        <FormInputTextInput
          v-else
          v-model="userFormInput.jobTitle"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.jobTitle"
          :input-label="formLabels.jobTitle"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.contactEmailAddress"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.contactEmailAddress"
          :input-label="formLabels.contactEmailAddress"
          input-type="email"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.contactPhoneNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.contactPhoneNumber"
          :input-label="formLabels.contactPhoneNumber"
          input-type="tel"></FormInputTextInput>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <FormInputTextInput
          v-model="userFormInput.driversLicenseNumber"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.driversLicenseNumber"
          :input-label="formLabels.driversLicenseNumber"
          input-type="text"></FormInputTextInput>
      </div>
      <div class="col-sm-4">
        <FormInputDropdownStates
          v-model="userFormInput"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate"
          :form-labels="formLabels"
          :form-data="formData"
          :field-names="{
            state: 'driversLicenseIssuingState'
          }"></FormInputDropdownStates>
      </div>
      <div class="col-sm-4">
        <FormInputDatePicker
          v-model="userFormInput.driversLicenseExpirationDate"
          :disabled="isStepDisabled"
          :vuelidate="vuelidate.driversLicenseExpirationDate"
          :input-label="formLabels.driversLicenseExpirationDate"
          :disabled-dates="{}"
          input-type="text"></FormInputDatePicker>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormInputTextInput from '@/components/form/FormInputTextInput.vue'
import FormInputDropdownContactTitles from '@/components/form/FormInputDropdownContactTitles.vue'
import FormStepImplementationMixin from '@/components/form/FormStepImplementation.mixin'
import FormInputDropdownStates from '@/components/form/FormInputDropdownStates.vue'
import FormInputDatePicker from '@/components/form/FormInputDatePicker.vue'
import TenantsMixin from 'mixins/tenants.mixin'

export default {
  name: 'InstantCreditFormStepContactInformation',
  components: {
    FormInputTextInput,
    FormInputDropdownContactTitles,
    FormInputDropdownStates,
    FormInputDatePicker
  },
  mixins: [
    FormStepImplementationMixin,
    TenantsMixin
  ]
}
</script>
