<template>
  <v-btn
    class="action-button"
    :class="{ 'action-button__primary': isPrimary }"
    tile
    elevation="0"
    :outlined="outlined"
    :color="buttonColor"
    :disabled="disabled"
    :loading="loading"
    @click="doClick">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    outlined: {
      type: Boolean,
      required: false,
      default: () => false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    isPrimary: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    clickAction: {
      type: [Function, Boolean],
      required: false,
      default: false
    }
  },
  computed: {
    buttonColor () {
      if (this.outlined) return
      if (!this.color && !this.isPrimary) return 'transparent'
      if (this.isPrimary) return '#099c98'
      return this.color
    }
  },
  methods: {
    doClick () {
      if (typeof this.clickAction !== 'function') {
        return
      }
      this.clickAction()
    }
  }
}
</script>

<style lang="scss" scoped>
$root: 'action-button';

.#{$root} {
  width: unset;
  text-transform: none;
  &.#{$root}__primary {
    color: #ffffff;
  }
}
</style>
