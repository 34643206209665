<template>
  <v-overlay :value="loading">
    <v-progress-circular
      indeterminate
      size="50"
      color="primary">
    </v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: 'PageSpinner',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>
