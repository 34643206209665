<template>
  <GenericModal
    v-if="modalState"
    v-cloak
    id="dnb-address-modal"
    @close="closeModal()"
    @closeModal="closeModal()">
    <template slot="header">
      Try Rapid Credit again
    </template>
    <div slot="body">
      <div class="form-group">
        <FormTextInput
          ref="customerName"
          v-model="newBusinessName"
          :persist-label="true"
          :vuelidate="$v.newBusinessName"
          input-label="Customer Name"
          input-type="text"></FormTextInput>

        <span
          v-if="error"
          class="error-message">
          {{ error }}
        </span>
      </div>

      <div class="form-group">
        <FormTextInput
          ref="streetAddress"
          v-model="newBillingAddress.streetAddress"
          :vuelidate="$v.newBillingAddress.streetAddress"
          :persist-label="true"
          input-label="Street Address"
          input-type="text"></FormTextInput>
      </div>

      <div class="form-group">
        <FormTextInput
          ref="unitNumber"
          v-model="newBillingAddress.unitNumber"
          :vuelidate="$v.newBillingAddress.unitNumber"
          :persist-label="true"
          input-label="Address Unit Number"
          input-type="text"></FormTextInput>
      </div>

      <div class="form-group">
        <template>
          <fieldset class="pt-3">
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="pb-4">
                <FormTextInput
                  ref="postalCode"
                  v-model="newBillingAddress.postalCode"
                  :vuelidate="$v.newBillingAddress.postalCode"
                  :persist-label="true"
                  input-label="Postal Code"
                  input-type="text"
                  @input="onPostalCodeChange($event)"></FormTextInput>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pb-4">
                <FormTextInput
                  ref="city"
                  v-model="newBillingAddress.city"
                  :vuelidate="$v.newBillingAddress.city"
                  :persist-label="true"
                  input-label="City"
                  input-type="text"></FormTextInput>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pb-4">
                <FormDropdown
                  v-if="states.length >= 2"
                  v-model="newBillingAddress.state"
                  :vuelidate="$v.newBillingAddress.state"
                  :persist-label="true"
                  input-label="State"
                  :show-value="true"
                  :options="states"></FormDropdown>
                  <FormTextInput
                    v-else
                    v-model="newBillingAddress.state"
                    :vuelidate="$v.newBillingAddress.state"
                    :persist-label="true"
                    input-label="State"
                    input-type="text"></FormTextInput>
              </v-col>
            </v-row>
          </fieldset>
        </template>
      </div>
</div>
    <div slot="footer">
      <a
        class="btn btn-rounded btn-link btn-danger"
        @click="closeModal()">
        Cancel
      </a>
      <button
        type="button"
        class="btn btn-rounded btn-primary"
        :class="{'disabled': loading}"
        :disabled="loading"
        @click="setCustomerAccount()">
        <span v-if="!loading">
          Update
        </span>
        <span v-else>
          <img
            src="../../../../assets/images/puff.svg"
            class="loading-img-btn" /> Saving...
        </span>
      </button>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '../../../../components/GenericModal.vue'
import FormTextInput from '../../../../components/form/FormTextInput.vue'
import FormDropdown from '../../../../components/form/FormDropdown.vue'
import axios from 'ca-http-service'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'EditDnbAddressModal',
  components: {
    GenericModal,
    FormTextInput,
    FormDropdown
  },
  props: {
    app: {
      required: true,
      type: Object
    }
  },
  data: function () {
    return {
      appForm: this.app,
      newBusinessName: null,
      newBillingAddress: {
        streetAddress: null,
        unitNumber: null,
        city: null,
        state: null,
        postalCode: null,
        country: null
      },
      addressLabels: {
        streetAddress: 'Street Address',
        unitNumber: 'Unit Number',
        city: 'City',
        state: 'State',
        postalCode: 'Postal Code',
        country: 'Country'
      },
      error: null,
      modalState: false,
      loading: false,
      states: []
    }
  },
  validations () {
    return {
      newBusinessName: { required },
      newBillingAddress: {
        streetAddress: { required },
        unitNumber: { },
        city: { required },
        state: { required },
        postalCode: { required }
      }
    }
  },
  async mounted () {
    this.states = await this.getStates()
  },
  methods: {
    setCustomerAccount () {
      this.loading = true

      const data = {
        companyName: this.newBusinessName,
        billingAddress: {
          Country: this.newBillingAddress.country,
          StreetAddress: this.newBillingAddress.streetAddress,
          AddressUnitNumber: this.newBillingAddress.unitNumber,
          City: this.newBillingAddress.city,
          State: this.newBillingAddress.state,
          PostalCode: this.newBillingAddress.postalCode
        }
      }

      this.$http.post(`/api/ErpReconciliation/saveCompanyInformation/${this.appForm.guid}`,
        data
      )
        .then(async (res) => {
          this.loading = false
          console.log('save Company Information', res)

          if (res.data.isSuccess) {
          /* this.appForm.businessInfo.accountNumber = this.newAccountNum
          if (res.data.data) {
            this.appForm.erpEntityInfo = res.data.data
          } */

            // emit get customer matches
            this.$emit('getCustomerMatches')

            this.closeModal()
          } else {
            this.error = res.data.message
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeModal () {
      this.modalState = false
      this.error = null
      this.loading = false
    },
    async getStates (country = 'US') {
      return axios.get('/generic/getStateList', {
        params: {
          countryCode: country
        }
      })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return []
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async onPostalCodeChange (event) {
      if (event.length === 5) {
        const results = await this.getCityStateByPostalCode(event)
        if (results != null && results.city != null) {
          // set values
          this.newBillingAddress.city = results.city
          this.newBillingAddress.state = results.state
        } else {
          // clear values
          this.newBillingAddress.city = ''
          this.newBillingAddress.state = ''
        }
      }
    },
    async getCityStateByPostalCode (postalCode) {
      return axios.get('/generic/getCityStateByZip', { params: { postalCode } })
        .then((res) => {
          if (res.status === 200) {
            return res.data
          } else {
            return null
          }
        })
        .catch((err) => {
          const errorMessage = err.response.data
          console.error(errorMessage)
        })
    }
  }
}
</script>
