<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="{ 'is-error': isError }">
          <div
            class="modal-close-btn"
            @click="$emit('close')">
            <i class="fa fa-close"></i>
          </div>
          <div
            v-if="showHeader"
            class="modal-header"
            :class="{ 'has-subheader': !showSubheader }">
            <slot name="header">
              Default Header
            </slot>
          </div>
          <div
            v-if="showSubheader"
            class="modal-subheader">
            <slot name="subheader">
              Subheader
            </slot>
          </div>
          <div
            class="modal-body clearfix"
            :class="{ 'is-error': isError }">
            <slot name="body">
              Default Body
            </slot>
          </div>
          <div class="modal-footer clearfix">
            <slot name="footer">
              <button
                type="button"
                class="btn btn-outline-danger"
                @click="$emit('close')">
                Cancel
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script type="text/javascript">
export default {
  name: 'GenericModal',
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    showSubheader: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode === 27) {
        this.$emit('close')
      }
    })
  }
}

</script>

<style lang="scss">
.modal {
    &-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    &-mask {
        position: fixed;
        z-index: 1002;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(230, 230, 230, .75);
        display: table;
        transition: opacity .3s ease;
    }
    &-container {
        position: relative;
        padding: 0 1.25rem 0;
        width: 38rem;
        max-height: 100%;
        max-width: 80%;
        margin: 0 auto;
        background-color: #fff;
        border-top: 2px solid #00416A;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .15), 0 6px 50px rgba(0, 0, 0, .2);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
        &.is-error {
            border-top: 2px solid #c00;
        }
    }
    &-header {
        display: block !important;
        pointer-events: none;
        width: 100%;
        padding: 1.25rem 0 1em !important;
        display: block;
        font-size: 1em;
        text-shadow: 0px 0px 0px rgba(0,0,0,.5);
        border-bottom: 2px solid #c9c9c9 !important;margin-bottom: .75em;
        &.has-subheader {
            margin-bottom: 0;
        }
        h3 {
            margin-top: 0;
            color: #000;
        }
    }
    &-subheader {
        color: #999;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
    &-body {
        padding: 1em 0 !important;
        &.is-error {
            padding: 4em 0 3em 0!important;
            text-align: center
        }
    }
    &-close-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
        z-index: 10;
        color: #d00;
        transition: color .2s ease;
        &:hover {
            color: #800
        }
    }
    &-default-button {
        float: right;
        margin: 0 .25rem;
    }
    &-footer {
        border-top: 2px solid #c9c9c9 !important;
        padding: 1em 0 !important;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
    @media (max-width: 600px) {
        &-container {
            width: calc(100% - 15px);
            max-width: 100%;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
