<template>
  <div
    id="credit-info-component"
    class="component-container">
    <div
      v-if="app.creditReport != null"
      class="card card-blue">
      <div
        id="credit-check"
        class="card-block">
        <h6 class="review-title">
          Credit Check - EXPERIAN
        </h6>
        <p>Transaction #: {{ app.creditReport.transactionNumber }}</p>
        <p>Ordered: {{ app.creditReport.transactionDateString }}</p>
      </div>
      <div
        id="risk-limits"
        class="card-block">
        <h6 class="review-title">
          Risk & Credit Limits
          <span class="small">
            (Score range: 1-100 percentile)
          </span>
        </h6>
        <div class="scores">
          <div class="score">
            <p>Intelliscore Plus</p>
            <h5>{{ app.creditReport.intelliscore }}</h5>
          </div>
          <div class="score">
            <p>Financial Stability</p>
            <h5>{{ app.creditReport.financialStability }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditInfo',
  props: {
    app: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss">
#credit-info-component {
  .card {
    background-color: #F0FAFE;
    border-color: #D3EAF3;
    .review-title {
      color: #00416A;
      font-size: .875rem;
      font-weight: 700;
      span {
        margin-left: 10px;
      }
    }
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    &-block {
      padding: 1.25rem 1.25rem .5rem 1.25rem;
      @media (min-width: 768px) {
        width: calc(50% - .5rem);
      }
      p {
        font-size: .875rem;
        margin-bottom: .25rem
      }
      h5 {
        font-weight: bold;
      }
      .small {
        color: #000;
      }
      .scores {
        display: flex;

        .score {
          width: 50%;
          margin-right: 1rem;
        }
      }
    }
  }
  #sectionTitle.section-title {
    margin-bottom: 1.5rem !important;
  }
}
</style>
