import { mapState, mapActions } from 'vuex'

export default { // DEPRECATED - this whole mixin should be replaced someday
  computed: {
    ...mapState([
      'identity',
      'company',
      'banners'
    ])
  },
  methods: {
    ...mapActions(['setBanners']),
    async getTenants () {
      if (this.banners && this.banners.length > 0) {
        return new Promise((resolve, reject) => {
          resolve(this.banners)
        })
      } else {
        console.warn('banners not set, getting data') // NOTE: this is unlikely to be hit, data should be available on startup
        return this.$http
          .get('/home/getTenants')
          .then((res) => {
            this.setBanners(res.data)
            return res.data
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    invalidTenantGoHome () {
      this.$router.push({ name: 'home' })
    },
    evalRouteParamTenant (tenants) {
      if (this.routeParamTenantEqualsCompany()) {
        return this.company
      }
      return this.routeParamTenant(tenants) || null
    },
    routeParamTenantEqualsCompany () { // TODO: update to select currentCompany selected
      const companyName = this.company != null ? this.company.name : ''
      const routeTenant = this.$route.params.tenant
      return (
        companyName &&
        routeTenant &&
        companyName.toLowerCase() === routeTenant.toLowerCase()
      )
    },
    routeParamTenant (tenants) {
      let tenant
      if (this.$route.params.tenant) {
        tenant = this.findTenantBy('name', this.$route.params.tenant, tenants)
      }
      return tenant || null
    },
    findTenantBy (keyName, value, tenants) {
      if (!tenants) {
        tenants = this.banners != null ? this.banners : []
      }
      if (!tenants.length) return null
      return tenants.find((tenant) => {
        if (typeof value === 'string' && typeof tenant[keyName] === 'string') {
          return value.toLowerCase() === tenant[keyName].toLowerCase()
        } else {
          return value === tenant[keyName]
        }
      })
    },
    getPlattUser () {
      this.$http
        .get('/application/getcompanyinfo', {
          params: {
            accountId: encodeURI(this.$route.query.accountId)
          }
        })
        .then((res) => {
          if (res.data.isSuccess) {
            this.appObj = res.data.data
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getRexelCustomer (tenantId) {
      this.$http
        .get('/application/getrexelcompanyinfo', {
          params: {
            apiKey: this.$route.query.apiKey,
            entityId: this.$route.query.id,
            tenantId
          }
        })
        .then((res) => {
          if (res.data.isSuccess) {
            this.appObj = res.data.data
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    changeTenant (tenantId) {
      if (this.banners.length > 0) {
        const tenant = this.findTenantBy('tenantId', tenantId)
        this.$store.dispatch('setCurrentBanner', tenant)
        const user = this.identity
        user.tenantId = tenantId
        this.$store.dispatch('setIdentity', user)
      }
    },
    isPlatt () {
      return this.$route.params.tenant === 'platt'
    },
    isRexelOrGexpro () {
      return this.$route.params.tenant === 'rexel' || this.$route.params.tenant === 'gexpro'
    },
    getBannerIdFromName () {
      switch (this.$route.params.tenant) {
        case 'platt':
          return 1
        case 'rexel':
          return 2
        case 'gexpro':
          return 3
        case 'rexelenergy':
          return 4
        default:
          return null
      }
    }
  }
}
