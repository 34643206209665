<template>
  <div
    v-cloak
    id="manage-user-create">
    <section class="users-header">
      <div class="row justify-content-between">
        <h4 class="col-auto ml-3">
          <a
            class="go-back-arrow mr-3"
            @click="goBack()">
            <i class="fa fa-arrow-left"></i>
          </a>
          <span>Create User</span>
        </h4>
        <small class="required-txt pull-right">
          <i class="text-danger">
            *
          </i> Required
        </small>
      </div>
    </section>
    <section class="users-body">
      <div class="row no-gutters mb-4">
        <div class="col-12 col-md-6">
          <div class="card card-first">
            <div class="card-block">
              <div class="row">
                <div class="col">
                  <div
                    class="form-group"
                    :class="{'has-danger': $v.userObj.email.$error}">
                    <label class="form-control-label required">
                      Email:
                    </label>
                    <input
                      v-model="userObj.email"
                      class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div
                    class="form-group"
                    :class="{'has-danger': $v.userObj.roleId.$error}">
                    <label class="form-control-label required">
                      Role:
                    </label>
                    <select
                      v-model="userObj.roleId"
                      class="form-control">
                      <option :value="null">
                        Select
                      </option>
                      <option
                        v-for="r in filteredRoles"
                        :key="r.key"
                        :value="r.key">
                        {{ r.value }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-6" v-if="userObj.roleId == 5">
                  <div class="form-group">
                    <label class="form-control-label">
                      Region:
                    </label>
                    <select
                      v-model="userObj.regionCode"
                      class="form-control">
                      <option
                        v-for="r in filteredRegions"
                        :key="r.bannerRegionCode"
                        :value="r.bannerRegionDesc">
                        {{ r.bannerRegionCode }} - {{ r.bannerRegionDesc }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-control-label">
                      Banner:
                    </label>
                    <div class="form-check">
                      <label
                        v-for="(all, i) in allBanners"
                        :key="i"
                        class="custom-control custom-radio">
                        <input
                          id="all.id"
                          v-model="userObj.tenants"
                          type="checkbox"
                          class="custom-control-input"
                          :value="all.id"
                          :disabled="!identity.permissions.editUserTenants" />
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description">
                          {{ all.name }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card card-second">
            <div class="card-block">
              <div class="row">
                <div class="col">
                  <div
                    class="form-group"
                    :class="{'has-danger': $v.userObj.firstName.$error}">
                    <label class="form-control-label">
                      First Name:
                    </label>
                    <input
                      v-model="userObj.firstName"
                      class="form-control" />
                    <span
                      v-if="!$v.userObj.firstName.maxLength"
                      class="field-validation-error">Field must be less than {{ $v.userObj.firstName.$params.maxLength.max }} letters.</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="form-group"
                    :class="{'has-danger': $v.userObj.lastName.$error}">
                    <label class="form-control-label">
                      Last Name:
                    </label>
                    <input
                      v-model="userObj.lastName"
                      class="form-control" />
                    <span
                      v-if="!$v.userObj.lastName.maxLength"
                      class="field-validation-error">Field must be less than {{ $v.userObj.lastName.$params.maxLength.max }} letters.</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div
                    class="form-group"
                    :class="{'has-danger': $v.userObj.initials.$error}">
                    <label class="form-control-label">
                      Initials:
                    </label>
                    <input
                      v-model="userObj.initials"
                      class="form-control" />
                    <span
                      v-if="!$v.userObj.initials.maxLength"
                      class="field-validation-error">Field must be less than {{ $v.userObj.initials.$params.maxLength.max }} letters.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-action">
        <button
          type="button"
          class="btn btn-primary"
          @click="saveUserChanges()">
          Save Changes
        </button>
      </div>
    </section>
  </div>
</template>
<script type="text/javascript">
import axios from 'ca-http-service'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import BannerMixin from 'mixins/banner.mixin'
import TenantsMixin from 'mixins/tenants.mixin'
import { mapState } from 'vuex'

const userCreate = {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.userObj.tenantId = vm.selectedBanner
      vm.userObj.tenants.push(vm.selectedBanner)
      vm.getRoles()
      vm.getRegions()
      vm.getTenants().then(res => {
        vm.allBanners = res.map(t => {
          return { id: t.tenantId, name: t.name }
        })
      })
    })
  },
  mixins: [BannerMixin, TenantsMixin],
  computed: {
    ...mapState(['identity']),
    filteredRoles () {
      const isSysAdmin = this.identity.permissions.editTenant
      console.log(this.roles)
      return this.roles.filter(r => {
        return isSysAdmin || r.key !== 4
      })
    },
    filteredRegions () {
      return this.regions
    }
  },
  watch: {
    selectedBanner (val) {
      // comes from banner mixin
      this.userObj.tenantId = val
      this.userObj.tenants.push(val)
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    saveUserChanges () {
      this.$v.userObj.$touch()
      if (this.$v.userObj.$invalid) {
        return false
      }

      console.log('user/save', this.userObj)

      axios.post('/api/user/save', this.userObj).then(response => {
        if (!response.data.isSuccess) {
          palert({
            title: 'Error',
            type: 'error',
            text: response.data.message
          })
        } else {
          palert({
            title: 'Success',
            type: 'success',
            text: response.data.message
          })
          this.userObj.userId = response.data.id
          this.$router.push({
            name: 'usersDetail',
            params: { id: response.data.data.id }
          })
          const tenantId = this.userObj.tenantId
          this.userObj = {
            tenantId,
            userId: 0,
            firstName: null,
            lastName: null,
            userName: null,
            email: null,
            roleId: null,
            active: true
          }
        }
      })
    },
    getRoles () {
      if (this.$store.getters.userRoles.length > 0) {
        this.roles = this.$store.getters.userRoles
      } else {
        axios.get('/api/user/getRoleList').then(response => {
          this.$store.dispatch('setUserRoles', response.data)
          this.roles = response.data
        })
      }
    },
    getRegions () {
      if (this.$store.getters.regions.length > 0) {
        this.regions = this.$store.getters.regions
      } else {
        axios.get('/api/user/getRegionList/2').then(response => {
          this.$store.dispatch('setRegions', response.data)
          this.regions = response.data

          console.log('this.regions', this.regions)
        })
      }
    }
  },
  data () {
    return {
      roles: [],
      regions: [],
      allBanners: [],
      userObj: {
        tenantId: 0,
        userId: 0,
        firstName: null,
        lastName: null,
        initials: null,
        userName: null,
        email: null,
        roleId: null,
        active: true,
        tenants: []
      }
    }
  },
  validations () {
    return {
      userObj: {
        email: {
          required,
          email
        },
        roleId: {
          required
        },
        firstName: {
          maxLength: maxLength(100)
        },
        lastName: {
          maxLength: maxLength(100)
        },
        initials: {
          maxLength: maxLength(3)
        }
      }
    }
  }
}
export default userCreate
</script>
<style lang="scss" scoped>
@import "../../assets/scss/mixins/flexbox.scss";
.required-txt {
  position: relative;
  right: 20px;
}

@media (max-width: 768px) {
  .card-first {
    border-bottom: none;
    .card-block {
      padding-bottom: 0;
    }
  }

  .card-second {
    border-top: none;

    .card-block {
      padding-top: 0;
    }
  }
}

.users-header {
  margin-bottom: 1rem;
}

.user-action {
  text-align: right;
}

.form-control-label {
  font-size: 90%;
  color: #999;
  margin-bottom: 0.125rem;
}

.switch-group {
  height: 1.5rem;
  @include flexbox;
  @include align-items(center);
  .switch-label {
    font-size: 1.125rem;
    color: #999;
    margin: 0 1rem 0 0;
    line-height: 1.75;
    @include align-self(center);
  }
}
</style>
