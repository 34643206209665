<template>
  <v-app>
    <div
      v-if="hasCookie"
      v-cloak
      :id="`${$route.meta.auth ? '' : 'main-wrapper'}`"
      class="component-container"
      :class="{ 'no-user-account': $route.meta.user }">
      <transition
        :name="transitionName"
        mode="out-in">
        <section
          v-if="isExternalForm"
          id="top-nav-layout">
          <navbar
            v-if="!$route.meta.auth"
            :user="currentUser">
          </navbar>
        </section>
        <div v-else></div>
      </transition>

      <v-main app>
        <div
          v-if="$route.meta.auth"
          class="px-0 py-0 container-fluid h-100">
          <transition
            :name="transitionName"
            mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
        <div
          v-else
          class="container px-0 px-sm-4"
          :style="$route.name !== 'jobAccount' ? 'margin-top: 48px;' : ''">
          <h3
            v-if="isExternalForm && $route.name === 'jobAccount'"
            class="my-5 ml-2">
            Job Account Application
          </h3>
          <transition
            :name="transitionName"
            mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </v-main>
    </div>

    <transition
      :name="transitionName"
      mode="out-in">
      <CommonFooter v-if="isExternalForm"></CommonFooter>
    </transition>
  </v-app>
</template>

<script type="text/javascript">
import TenantsMixin from './mixins/tenants.mixin'
import NavBarComponent from './pages/layout/navbar.vue'
import CommonFooter from './pages/layout/CommonFooter.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    CommonFooter,
    navbar: NavBarComponent
  },
  mixins: [TenantsMixin],
  props: {
    transitionName: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      hasCookie: true
    }
  },
  computed: {
    ...mapState([
      'identity'
    ]),
    ...mapGetters([
      'isProductionEnvironment'
    ]),
    currentUser () {
      return this.identity
    },
    isExternalForm () {
      const routeInfo = this.$route.meta

      // Cash account for test envs only currently
      const allowedRoutes = ['application', 'pageUnavailable']
      if (!(this.isProductionEnvironment)) {
        allowedRoutes.push('cashAccount')
        allowedRoutes.push('jobAccount')
      }

      return Object.keys(routeInfo).length && !routeInfo.isInternalForm && allowedRoutes.includes(this.$route.name)
    }
  },
  watch: {
    currentUser (identity) {
      this.getTenants()
        .then((response) => {
          const tenant = this.evalRouteParamTenant() || this.findTenantBy('tenantId', identity.bannerId)
          if (tenant) {
            this.$store.dispatch('setCurrentBanner', tenant.name.toLowerCase())
          }
        })
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/_bootstrap-variables.scss';
body {
  background-color: #efefef !important;
  min-height: 100vh;
  overflow: hidden !important;
}
#main-wrapper {
  flex-grow: 1;
  padding-bottom: 2rem;

  &.no-user-account {
    background-color: rgb(238, 238, 238);
  }

  .admin-navigation {
    min-height: 3.375rem;
    &.platt {
      background: #003708;
    }
    &.rexel {
      background: #06184E;
    }
    &.gexpro {
      background: #003B74;
    }
    .navbar-brand>span {
      height: 1.5rem;
      color: #fff;
      text-transform: capitalize;
      font-weight: bold;
    }
  }
  .user-navigation {
    padding: .5rem;
    .container {
      padding: 0 1.75rem;
    }
    .navbar-text {
      padding: 0;
      color: #999;

      font-size: .875rem;
      line-height: 1.85;
    }
  }
  .app-btn {
    display: block;
  }
  .btn-rounded {
    text-transform: uppercase;
    font-size: 0.875rem;
  }
  .checkbox-group {
    .form-control {
      border-right: none;
    }
    .input-group-addon {
      background-color: transparent;
    }
  }
  .VueTables__sortable {
    cursor: pointer;
    &:hover {
      background: lighten($gray-lighter, 5%);
    }
  }
  #main {
    margin-top: 2rem;
  }
  .vue-tooltip {
    text-align: center;
    font-size: 14px;
  }
  .table-bordered thead th,
  .table-bordered tbody td {
    padding: .5rem .75rem;
    font-size: 0.875rem;
  }
  .table-bordered thead th {
    position: relative;
    .fa-caret-up,
    .fa-caret-down {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: .75rem;
      transform: translate(-50%, 0%);
    }
  }
}
</style>
